import { ActionTypes } from 'constants/index';

const initialState = {
  data: null,
  loading: false,
  error: {
    name: false,
    errorMessage: '',
    status: '',
  },
  saveSuccess: false,
  updateSuccess: false,
};

const groupReducer = function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_GROUP_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        saveSuccess: false,
        updateSuccess: false,
      };
    }
    case ActionTypes.SAVE_GROUP_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        saveSuccess: true,
      };
    }
    case ActionTypes.UPDATE_GROUP_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        updateSuccess: true,
      };
    }
    case ActionTypes.UPDATE_GROUP_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default groupReducer;
