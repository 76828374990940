import React from 'react'
import {
	FormControl, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography,
	Card, CardContent,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import classNames from 'classnames'

const ExpansionPanels = (props) => {
	let { item, classes } = props
	let selectedvalue = props.filledData[item.name]
	return (<>
		<FormControl
			style={{
				"paddingBottom": "10px", "paddingTop": "10px",
				visibility: props.visibility,
				...JSON.parse(item.style ? item.style : "{}")
			}}
			className="native-select-dropdown">
			{item.data && item.data.values && item.data.values.map((el, indexOuter) => {
				return (<ExpansionPanel disabled={item.disabled} key={indexOuter}
					defaultExpanded={item.showExpandPanel}>
					<ExpansionPanelSummary
						expandIcon={<ExpandMore />}
						aria-controls="panel1a-content"
						id="panel1a-header" >
						<Typography className={classes.heading}>{el.label}</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						{item.innerForms && item.innerForms.map((item, index) => {
							return (<Card key={index}
								style={index == indexOuter ? {} : { display: "none" }}
								className={classNames(classes.card)} >
								<CardContent>
									<InnerForm design={item.innerForm}
										viewOnly={props.viewOnly}
										setData={(name, val, item) => { props.setData(name, val, item) }}
										innerFormUpdate={(jsonCopy, modalData) => {
											props.innerFormUpdateMultiple(props.itemPosition, jsonCopy, modalData, index);
										}}
										modalData={item.innerFormModalData}
										filledData={props.filledData}
									/>
								</CardContent>
							</Card>)
						})}
					</ExpansionPanelDetails>
				</ExpansionPanel>)
			})}
		</FormControl>
	</>)
}

export default ExpansionPanels

import InnerForm from '../InnerForm'