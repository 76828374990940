import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { withRouter } from 'react-router-dom'
import View from './view'
import Redux from './redux'

const Container = (props) => {
	console.log('Form Container')

	useEffect(() => {
		return () => props.newEntryForm()
	}, [])

	return (
		<View
			{...props}
			getForm={() => props.getForm()}
			updateForm={() => props.updateForm()}
		/>
	)
}

export default hot(withRouter(Redux(Container)))