import { ActionTypes } from 'constants/index'

const initialState = {
    data: [],
    searchText: "",
    error: "",
    loading: false,
    network: {},
    deleteSuccess: false
}

const networksReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_NETWORKS: {
            return {
                ...state,
                loading: true,
                deleteSuccess: false
            }
        }
        case ActionTypes.GET_NETWORKS_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                deleteSuccess: false
            }
        }
        case ActionTypes.GET_NETWORKS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.GET_NETWORK_SUCCESS: {
            return {
                ...state,
                loading: false,
                dataModel: action.payload,
            }
        }
        case ActionTypes.GET_NETWORK_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.SAVE_NETWORK_SUCCESS: {
            return {
                ...state,
                loading: false,
                dataModel: action.payload,
            }
        }
        case ActionTypes.SAVE_NETWORK_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.UPDATE_NETWORK_SUCCESS: {
            return {
                ...state,
                loading: false,
                dataModel: action.payload,
            }
        }
        case ActionTypes.UPDATE_NETWORK_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.DELETE_NETWORK_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: [...state.data.filter(item => item._id !== action.payload)],
                deleteSuccess: true 
            }
        }
        case ActionTypes.DELETE_NETWORK_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error,
                deleteSuccess: false
            }
        }

        default: {
            return state;
        }
    }
};

export default networksReducer;
