import { ActionTypes } from 'constants/index'

export const getPipelines = (appId) => dispatch => {
	return dispatch({ type: ActionTypes.GET_PIPELINES, payload: appId })
}

export const setPipelinesSearchText = (value) => dispatch => {
	return dispatch({ type: SET_PIPELINES_SEARCH_TEXT, searchText: value })
}

export const getPipeline = (params) => dispatch => {
	return dispatch({ type: ActionTypes.GET_PIPELINE, payload: params })
}

export const savePipeline = (data) => dispatch => {
	return dispatch({ type: ActionTypes.SAVE_PIPELINE, payload: data })
}

export const updatePipeline = (data) => dispatch => {
	return dispatch({ type: ActionTypes.UPDATE_PIPELINE, payload: data })
}

export const deletePipeline = (data) => dispatch => {
	return dispatch({ type: ActionTypes.DELETE_PIPELINE, payload: data })
}

export const newPipeline = () => dispatch => {
	const data = {
		name: '',
		description: '',
		dataModels: [],
		dataCleaningTools: [],
		dataQualityTools: [],
		active: true,
		diagram: {}
	};
	return dispatch({ type: ActionTypes.GET_PIPELINE_SUCCESS, payload: data })
}