import React from 'react'
import { Box } from '@material-ui/core'
import EntryForm from './form/EntryForm'

const View = (props) => {
	console.log('Form View')
	return (<div className="fullHeight">
		<Box className="abcCompany-page">
			<EntryForm />
		</Box>
	</div>)
}

export default View