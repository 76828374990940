import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button, TextField, FormControl, FormLabel } from '@material-ui/core'

const botView = (props) => {
	let { form, classes, handleChange } = props
	return (<>
		<br />
		<FormLabel component="legend">{"Bot Name"}</FormLabel>
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Bot Name"}</FormLabel>
			<TextField
				id="botName"
				name="botName"
				placeholder="Bot Name"
				className={classes.textField}
				value={form.botName}
				onChange={handleChange}
				margin="normal"
				variant="outlined"
			/>
		</FormControl>
		<br />
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Bot Name"}</FormLabel>
			<TextField
				id="bothUserId"
				placeholder="User Id"
				className={classes.textField}
				value={form.botUserId}
				onChange={handleChange}
				margin="normal"
				variant="outlined"
			/>
		</FormControl>
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Bot Name"}</FormLabel>
			<TextField
				id="botPassword"
				placeholder="Password"
				className={classes.textField}
				value={form.botPassword}
				onChange={handleChange}
				margin="normal"
				variant="outlined"
			/>
		</FormControl>
		<br />
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Bot Name"}</FormLabel>
			<TextField
				id="botPostEndPoint"
				placeholder="Use bot to post data to the following end point"
				className="mt-8 mb-16"
				value={form.botPostEndPoint}
				onChange={handleChange}
				margin="normal"
				fullWidth
				variant="outlined"
			/>
		</FormControl>
	</>)
}

export default withRouter(botView)