import 'styles/page-designer.css';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@material-ui/core';

const APPRENDERER_URL = process.env.REACT_APP_APPRENDERER_URL

const styles = () => ({
    linksTitle: {
        fontSize: '18px !important',
        fontWeight: 'bold'
    },
    lineView: {
        width: '100%',
        height: '2px',
        marginTop: '30px',
        backgroundColor: '#cecece'
    },
    itemBorder: {
        width: '100%',
        height: '2px',
        backgroundColor: '#cecece'
    },
    linkCon: {
        paddingLeft: '16px',
        width: 'fit-content',
        '& a': {
            fontWeight: 'bold',
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }
});
class PageDesignerLinks extends Component {
    render() {
        const ListItemLink = (props) => <ListItem button component="a" {...props} />
        const { classes, match, element } = this.props;
        let { data, width, height, error, name } = element.chartData;
        console.log('datadata::', element, data);
        if (error) {
            return <div className="errorDisplayCenter">{error}</div>
        } else {
            return (
                <div
                    id={`LinksContainer${this.props.element.chartUniqueId}`}
                    style={{ height: height, width: '100%' }}
                >
                    <span className={classes.linksTitle}>{name}</span>
                    <div className={classes.lineView} />
                    <List component="nav" className="p-0" aria-label="secondary mailbox folders">
                        {data && data.length > 0 ?
                            data.map((d, i) => {
                                const { title } = d;
                                let url
                                if (d.LinkType === 'Internal') {
                                    url = d.InternalLinkType === 'Form'
                                        ? `${APPRENDERER_URL}/apps/${match.params.dappId}/${d.LinkFormURL}/new`
                                        : `${APPRENDERER_URL}/apps/${match.params.dappId}/${d.LinkPageURL}`

                                } else {
                                    url = d.ExternalLink
                                }
                                return (
                                    <div className={classes.linkCon} key={i}>
                                        <ListItemLink href={url}>
                                            <ListItemText primary={d.Title} />
                                        </ListItemLink>
                                        <div className={classes.itemBorder} />
                                    </div>
                                )
                            })
                            : "Please wait data has been loading"
                        }
                    </List>
                </div>
            );
        }
    }
}

export default withStyles(styles, { withTheme: true })(
    withRouter(
        connect(
            null,
            null
        )(PageDesignerLinks)
    )
)
