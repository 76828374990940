import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

const initialState = {
	alerts: [],
}

const alerts = function (state = initialState, action) {

	switch (action.type) {
		case ActionTypes.HIDE_ALERT: {
			let alerts = state.alerts.filter(d => d.id !== action.payload.id)
			return {
				...state,
				alerts
			};
		}
		case ActionTypes.SHOW_ALERT: {
			let alerts = [...state.alerts, action.payload]
			return {
				...state,
				alerts
			};
		}
		default: {
			return state
		}
	}
};

export default alerts;