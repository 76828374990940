import React, { useState, useEffect } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import {
	Stepper, Step, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Paper, Grid, List, ListItem, ListItemText, TextField, FormControlLabel, FormLabel,
	FormControl, Select, StepLabel, Button, Typography, Checkbox, Dialog, IconButton
} from '@material-ui/core'

import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'

import Close from '@material-ui/icons/Close'

import photoone from 'images/photoone.svg'
import oracleimg from 'images/oracleimg.svg'
import microsoftsqlserver from 'images/microsoftsqlserver.svg'
import netsuitelogo from 'images/netsuitelogo.png'
import addnewimg from 'images/addnewimg.svg'
import rightarrow from 'images/rightarrow.svg'
import pencileditbutton from 'images/pencil-edit-button.svg'
import deleteblackicon from 'images/deleteblack-icon.svg'
import eyeicon from 'images/eyeicon.svg'
import addicon from 'images/addicon.svg'
import NoPath from 'images/NoPath.png'

import Loading from 'components/Loading'
import PagesTable from './PagesTable'
import NewPageDialog from './new/NewPageDialog'
// import 'styles/styles.scss'
import CustomTable from '../@table/Table'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}))

const styles = (theme) => ({
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
})

const exportArray = [{ key: 'xls', name: "Export as Excel", isVisible: true },
{ key: 'csv', name: "Export as csv", isVisible: true },
{ key: 'txt', name: "Export as text", isVisible: true },
/**{ key: 'image', name: "Export as image", isVisible: true }**/];

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	)
})

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions)

const View = (props) => {
	const classes = useStyles()
	const [spacing, setSpacing] = React.useState(2)
	const [activeStep, setActiveStep] = React.useState(0)
	const [open, setOpen] = React.useState(true)

	const handleClickOpen = () => {
		setOpen(true)
	}
	const handleClose = () => {
		setOpen(false)
	}

	const handleReset = () => {
		setActiveStep(0)
	}

	useEffect(() => {
		console.log("props.pageDialog", props.pageDialog);
	}, [])

	useEffect(() => {
		console.log("props.state ===<>===", props.state);
	}, [props.state])

	useEffect(() => {
		console.log("props...... ===<>===", props);
	}, [props])

	return (
		<Box className="abcCompany-page">
			<Box className="pxy20">
				<Grid container>
					<Grid item xs={12} md={6}>
						<div className="backbtn">
							<div className="backbtn">
								{/* <Link to="/builder/dapps"> */}
								<img src={rightarrow} alt="backicon" className="backicon" />
								<Box className="companyheadingtext">
									<Typography variant="h4">Pages List</Typography>
								</Box>
								{/* </Link> */}
							</div>
						</div>
					</Grid>
					<Grid item xs={12} md={6} className="savebtn-sec">
						<Button variant="contained" color="primary" className="addnewentrybtn mb5"
							onClick={props.addNewPageClick}>
							ADD NEW Page
      					</Button>
					</Grid>
					<Box className="bg-white pxy20 width-100" component="div">
						<Grid container spacing={2}>
							<Grid item xs={12} md={12}>
								{/* <PagesTable {...props} /> */}
								<TableContainer component={Paper}>
									<CustomTable onRowClick={props.onRowClick}
										name={"Pages"}
										{...props}
										{...props.state}
										searchHandler={props.searchHandler}
										isExport={true}
										isViewCoulmns={true}
										isSearch={true}
										isFilter={true}
										hasCheckbox={false}
										actions={{ edit: true, view: true, delete: true, copy: true }}
										isTablePagination={true}
										isTableHead={true}
										isTableSubHeader={false}
										handleInputChange={props.handleInputChange}
										resetFilters={props.resetFilters}
										handleDropdownChange={props.handleDropdownChange}
										handleRequestSort={props.handleRequestSort}
										handleSelectAllClick={props.handleSelectAllClick}
										tableCrudOperations={props.tableCrudOperations}
										handleCheck={props.handleCheck}
										handleChangePage={props.handleChangePage}
										handleChangeRowsPerPage={props.handleChangeRowsPerPage}
										handleExoprtItemClick={props.handleExoprtItemClick}
										exportArray={exportArray}
										headerstyle={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.87)", backgroundColor: "#FFF", textAlign: "center" }}
										subHeaderstyle={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.87)", backgroundColor: "#FFF", textAlign: "center" }}
										rowStyle={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.87)", backgroundColor: "#FFF", row1BackgroundColor: "#FFF", row2BackgroundColor: "#FFF" }} />
								</TableContainer>
							</Grid>
							{/* <Grid item xs={12} md={6}>
								<img src={NoPath} alt="side img" />
							</Grid> */}
						</Grid>
					</Box>
					<NewPageDialog {...props} />
				</Grid>
			</Box>
		</Box>
	)
}

export default View