import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Line } from 'react-chartjs-2';
import 'styles/page-designer.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'

const styles = theme => ({});

class PageDesignerLineChart extends Component {
	render() {
		let { data, width, height, error } = this.props.element.chartData;

		const d1 = (canvas) => {
			// TO DO -  Need to make dynamic
			const ctx = canvas.getContext("2d")
			var gradient = ctx.createLinearGradient(450, 0, 0, 0);
			gradient.addColorStop(0, "rgb(0, 99, 132)");
			gradient.addColorStop(1, "rgb(255, 99, 132)");
			var gradient1 = ctx.createLinearGradient(250, 0, 0, 0);
			gradient1.addColorStop(0, "rgb(0, 109, 2)");
			gradient1.addColorStop(1, "rgb(255, 109, 2)");
			let d2 = { ...data };
			d2.datasets[0].backgroundColor = gradient;
			d2.datasets[1].backgroundColor = gradient1;
			// d
			return d2;
			// return {
			//     backgroundColor: gradient
			// }
		}
		if (error) {
			return (
				<div className="errorDisplayCenter">{error}</div>
			);
		} else {
			return (
				<div
					id={`LineChartContainer${this.props.element.chartUniqueId}`}
					style={{ height: "inherit", width: "inherit" }}
				>
					<Line
						id={`LineChartElement${this.props.element.chartUniqueId}`}
						className="text"
						width={"inherit"}
						height={"inherit"}
						data={data}
						options={{
							maintainAspectRatio: false
						}}
					/>
				</div>
			);
		}
	}
}

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(null, null)(PageDesignerLineChart)
	)
);