import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logoutUser, getDapp, getCategories } from 'actions';


const HeaderRedux = (PassedComponent) => {
    const mapStateToProps = ({ user, navigation, dapp, dapps }) => {
        return {
            user,
            navigation,
            dapp,
            dapps
        }
    }

    const mapDispatchToProps = {
        logout: logoutUser,
        getDapp: getDapp,
       
    }

    return connect(mapStateToProps, mapDispatchToProps)(
        ({ ...props }) =>
            <PassedComponent {...props} />
    )
}

export default HeaderRedux