import React from 'react';
import bindme from 'bindme';
import DeleteIcon from './delete.js';
import ArrowIcon from './right-arrow.js';
import '../style/EditElement.css';
import StartIcon from './start-button.js';
import UserTask from './UserTask';
import BusinessTask from './BusinessTask';
import SystemTask from './SystemTask';
import SendTask from './SendTask';
import WorkflowTask from './WorkflowTask';
import DatapipelineTask from './DatapipelineTask';

export default class SuggestionRect extends React.Component {
  constructor(props) {
    bindme(super(props), 'onMouseLeave', 'onMouseOver');
    this.state = {
      focused: false,
    };
  }

  onMouseLeave(event) {
    this.props.setMouseOverConnection(event, false);
    this.setState({ focused: false });
    // this.props.onMouseLeave();
  }

  onMouseOver(event) {
    this.props.setMouseOverConnection(event, true);
    this.setState({ focused: true });
  }

  render() {
    const { id, x, y, height, width, type } = this.props;

    const halfHeight = height / 2;
    const halfWidth = width / 2;

    if (type === 'circle') {
      return (
        <>
          <rect
            // onMouseDown={createArrow}
            x={x + 35}
            y={y + 10}
            fill="white"
            height={60}
            // width={100}
            width={70}
            stroke="#000"
            strokeWidth={1}
            rx="6"
            strokeDasharray="5,5"
          ></rect>
          <StartIcon
            x="50"
            y="5"
            height="22"
            width="22"
            onClick={() => this.props.onClickSugessionElement('circle')}
            style={{ zIndex: 2000 }}
          />
          <circle
            onClick={() => this.props.onClickSugessionElement('twocircle')}
            stroke="#000"
            strokeWidth={3}
            fill="white"
            x={x + width}
            y={y}
            r={9}
            cx={x + 80}
            cy={y + 26}
          />
          <rect
            // onMouseDown={createArrow}
            onClick={() => this.props.onClickSugessionElement('rect')}
            x={x + 43}
            y={y + 45}
            fill="white"
            height={20}
            width={20}
            stroke="#000"
            strokeWidth={1}
            rx="4"
          ></rect>
          <DeleteIcon
            x={x + 70}
            y={y + 46}
            onClick={() => this.props.onClickSugessionElement('delete')}
          />
         
        </>
      );
    } else if (type === 'twocircle') {
      return (
        <>
          {/* <img src={deleteIcon} alt="delete-icon" className="icon"></img> */}
          <rect
            // onMouseDown={createArrow}
            x={x}
            y={y + 10}
            fill="white"
            height={60}
            // width={100}
            width={70}
            stroke="#000"
            strokeWidth={1}
            rx="6"
            strokeDasharray="5,5"
          ></rect>
          <StartIcon
            x="85"
            y="5"
            height="22"
            width="22"
            onClick={() => this.props.onClickSugessionElement('circle')}
          />
          <circle
            onClick={() => this.props.onClickSugessionElement('twocircle')}
            stroke="#000"
            strokeWidth={3}
            fill="white"
            x={x + width}
            y={y}
            r={9}
            cx={x + 42}
            cy={y + 26}
          />
          <rect
            // onMouseDown={createArrow}
            onClick={() => this.props.onClickSugessionElement('rect')}
            x={x + 6}
            y={y + 45}
            fill="white"
            height={20}
            width={20}
            stroke="#000"
            strokeWidth={1}
            rx="4"
          ></rect>
          <DeleteIcon
            x={x + 34}
            y={y + 46}
            onClick={() => this.props.onClickSugessionElement('delete')}
          />
        </>
      );
    } else {
      return (
        <>
          <rect
            x={x}
            y={y + 10}
            fill="white"
            height={60}
            width={70}
            stroke="#000"
            strokeWidth={1}
            rx="6"
            strokeDasharray="5,5"
          ></rect>

          <StartIcon
            x="235"
            y="5"
            height="22"
            width="22"
            onClick={() => this.props.onClickSugessionElement('circle')}
          />
          <circle
            onClick={() => this.props.onClickSugessionElement('twocircle')}
            stroke="#000"
            strokeWidth={3}
            fill="white"
            x={x + width}
            y={y}
            r={9}
            cx={x + 42}
            cy={y + 26}
          />
          <rect
            onClick={() => this.props.onClickSugessionElement('rect')}
            x={x + 6}
            y={y + 45}
            fill="white"
            height={20}
            width={20}
            stroke="#000"
            strokeWidth={1}
            rx="4"
          ></rect>
          <DeleteIcon
            x={x + 32}
            y={y + 46}
            onClick={() => this.props.onClickSugessionElement('delete')}
          />
        </>
      );
    }
  }
}

SuggestionRect.defaultProps = {
  r: 5,
};
