import React from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

    const mapStateToProps = ({ user, navigation }) => {
        return {
            user,
            navigation
        }
    }

    const mapDispatchToProps = {
        showAlert: Actions.showAlert,
        getDappFiles: Actions.getDappFiles
    }

    return connect(mapStateToProps, mapDispatchToProps)(
        ({ ...props }) =>
            <PassedComponent {...props} />
    )
}

export default Redux