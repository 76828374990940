import { Button, CircularProgress, FormControl, FormLabel, Grid, Paper, TextField, Typography } from '@material-ui/core'
import { emptyPrevPath, getMemberByUserId, hideAlert, isUserExist, showAlert, submitLogin } from 'actions'
import Loading from 'components/Loading'
import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import 'styles/styles.scss'
import { validateEmail } from '../../modules/common'

const PingLogin = (props) => {

	const canSubmit = ({ email, password }) => {
		if (validateEmail(email)) {
			return !email //|| !password
		} else {
			return true;
		}
	}

	const handleSubmit = (e, credentials) => {
        e.preventDefault()
        if (credentials.email) {
            props.isUserExist({ email: credentials.email })
        }
	}

	const loginHandler = () => {
		if (props.login.error && (props.login.error.email || props.login.error.password)) {
			// disableButton()
		}

		if (props.login && props.login.success) {
			// props.showAlert('Hello! And welcome!', { id: "login", variant: 'success', icon: 'bell' });
			if (props.navigation.prevPath) {
				props.history.push({
					pathname: props.navigation.prevPath,
					search: props.navigation.prevSearch
				});
				props.emptyPrevPath()
			} else {
				// props.hideAlert('login')
				props.history.push({
					pathname: '/builder/dapps'
				})
			}
		}
		return null
	}

    const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [error, setError] = useState({ email: false, password: false })

	useEffect(() => {
		console.log("props.login.error", props.login.error);
		let error = props.login.error;
		if (error) {
			error.email && error.password
				&& props.showAlert("NETWORK ERROR: Please verify your internet connection", {
					id: "login",
					variant: 'danger',
					icon: 'times-circle'
				})
			if (error.email) {
				setError({ ...error, email: error.email })
			} else if (error.password) {
				setError({ ...error, password: error.password })
			} else if (error.message) {
				setError({ ...error, email: error.message })
			}
		}
	}, [props.login.error])

	const handleBlur = (event) => {
		if (!validateEmail(event.target.value)) {
			setError({ ...error, email: event.target.value && event.target.value.length > 0 ? "Invalid Email" : "Enter Email" });
		} else {
			setError({ ...error, email: false });
		}
	}


	useEffect(() => {
		if (props.login && props.login.isAuthenticated) {
			props.history.push({
				pathname: '/builder/dapps'
			});
		}
	}, [props.login]);

	useEffect(() => {
        console.log('props.login', props.login)
        if (props.login.orgNotFound && !props.login.orgNotFound.externalAuthentication) {
            setError({ ...error, email: props.login.orgNotFound.message });
        }
        if (props.login.isUserExist && props.login.isUserExist.externalAuthentication) {
            let redirectionInfo = props.login.isUserExist.connectionParameter
            let url = redirectionInfo.url + '?PartnerSpId=' + redirectionInfo.PartnerSpId + '&username=' + email;
            window.location.replace(url);
        }

    }, [props.login])


	useEffect(() => {
		loginHandler()
	}, [props.login])

	return (
		<div className="login-sec">
			<Grid container>
				<Grid item md={6} lg={8}>
					{/* <img src={Dsilologosmall} alt="" width="200px" /> */}
					<Typography className="login-title" variant="h3">
						Driving accelerated value creation and cost savings at enterprises through
						<Typography variant="inherit" className="login-title textbold"> Automation, intelligence </Typography> and
						<Typography variant="inherit" className="login-title textbold"> Trust</Typography>
					</Typography>
				</Grid>
				<Grid item md={6} lg={4}>
					<Paper className="login-frm">
						<Typography component="h1" variant="h5">
							SIGN IN
							{/* <Typography className="r-align" component="label">
								New User? <Typography component="label">Sign Up</Typography>
							</Typography> */}
						</Typography>
						<form noValidate
							onSubmit={(e) => handleSubmit(e, { email, password })}>
							<FormControl>
								<FormLabel variant="h6" className="label-headings" htmlFor="email">
									Email
								</FormLabel>
								<TextField
									variant="outlined"
									required
									fullWidth
									type="email"
									id="email"
									name="email"
									onBlur={handleBlur}
									placeholder="Enter username or email"
									autoComplete="email"
									autoFocus
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									helperText={error.email && error.email}
									error={Boolean(error.email)}
								/>
							</FormControl>
							{/* <FormControl>
								<FormLabel variant="h6" className="label-headings" htmlFor="password">
									Password
								</FormLabel>
								<TextField
									variant="outlined"
									required
									fullWidth
									name="password"
									type="password"
									placeholder="enter password"
									id="password"
									autoComplete="current-password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									helperText={error.password && error.password}
									error={Boolean(error.password)}
								/>
							</FormControl> */}
							{/* <Grid container>
								<Grid sm={6} item={true}>
									<FormControlLabel className="remember-me"
										control={<Checkbox value="remember" color="primary" />}
										label="Remember Me"
									/>
								</Grid>
								<Grid sm={6} item={true}>
									<Link href="#" variant="body2" className="float-right">
										Forgot Password?
	                				</Link>
								</Grid>
							</Grid> */}
							<Button
								type="submit"
								fullWidth
								variant="contained"
								disabled={canSubmit({ email, password })}
							>
								{(props.login && props.login.isLoading) ?
									<CircularProgress className='circular-bar' size={24} /> :
									"Sign In"
								}
							</Button>
						</form>
					</Paper>
				</Grid>
			</Grid>
			{props.isLoading && <Loading />}
		</div>
	)
}


const mapStateToProps = ({ user, login, navigation, member }) => {
    return {
        login,
        user,
        navigation,
        memberByUserId: member.memberByUserId
    }
}

const mapDispatchToProps = {
    submitLogin: submitLogin,
    emptyPrevPath: emptyPrevPath,
    showAlert: showAlert,
    hideAlert: hideAlert,
    getMemberByUserId: getMemberByUserId,
    isUserExist,
}


export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(PingLogin)))