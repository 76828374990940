export default {

	GET_ENTITIES: undefined,
	GET_ENTITIES_SUCCESS: undefined,
	GET_ENTITIES_FAILURE: undefined,

	GET_ENTITY: undefined,
	GET_ENTITY_SUCCESS: undefined,
	GET_ENTITY_FAILURE: undefined,

	SAVE_ENTITY: undefined,
	SAVE_ENTITY_SUCCESS: undefined,
	SAVE_ENTITY_FAILURE: undefined,

	UPDATE_ENTITY: undefined,
	UPDATE_ENTITY_SUCCESS: undefined,
	UPDATE_ENTITY_FAILURE: undefined,

	DELETE_ENTITY: undefined,
	DELETE_ENTITY_SUCCESS: undefined,
	DELETE_ENTITY_FAILURE: undefined,
}