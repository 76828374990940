import React from 'react';
import ReactDOM from 'react-dom';
import Canvas from './components/Canvas';
import ErrorBoundary from './components/ErrorBoundary';
import Step from './components/Step';
import Toolbar from './components/Toolbar';
import randomString from '../../utils/randomString';
import './style/button.css';
import './style/EditElement.css';
import Redux from './Redux';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
// import Modal from './components/Modal';
import PropertiesDialog from './Properties/PropertiesDialog';
import _ from 'modules/lodash'

import { Grid, NativeSelect, TextField } from '@material-ui/core';

const initialDiagram = {
  steps: [],
  style: { backgroundColor: '#eee' },
  height: window.innerHeight - 100,
  width: '100%',
};
class Pipeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // diagram: props.workflow ? JSON.parse(props.workflow.diagram) : initialDiagram,
      diagram: props.state ? props.state.diagram : initialDiagram,

      dragging: null,
      isMouseDown: false,
      isMouseMoving: false,
      isMouseOver: false,
      isMouseMove: false,
      offset: { x: 0, y: 0 },
      scroll: { x: 0, y: 0 },
      rectangularSelection: null,
      selected: {},
      mouseOverConnection: {},
      clickStep: {},
      mouseOverStep: {},
      shiftPressed: false,
      toolbarHeight: 50,
      show: false,

      ids: [],
      selectedStep: {},
    };
  }

  componentDidUpdate(prevProps) {
    if(!_.isEqual(prevProps.state.diagram, this.props.state.diagram))
      this.setState({diagram: this.props.state.diagram})
  }

  componentDidMount() {
    const container = ReactDOM.findDOMNode(this).parentNode;

    // document.addEventListener('keydown', this.onDocumentKeydown)
    // document.addEventListener('keyup', this.onDocumentKeyup)

    window.addEventListener('scroll', this.onWindowScroll);
    window.addEventListener('resize', this.onWindowResize(container));

    const offset = {
      x: container.offsetLeft,
      y: container.offsetTop,
    };

    const scroll = {
      x: window.scrollX,
      y: window.scrollY,
    };

    this.setState({
      offset,
      scroll,
    });
  }

  dragToolbarIcon = StepIcon => {
    return event => {
      const { diagram, toolbarHeight } = this.state;
      // console.log(diagram.steps)
      // const coordinates = this.getCoordinates(event);
      // if(coordinates.x > this.state.poolCoordinateX1 && coordinates.y > this.state.poolCoordinateY1 && coordinates.x < this.state.poolCoordinateX2 && coordinates.y < this.state.poolCoordinateY2){
      //   console.log("insidePool")
      // } else {
      //   console.log("outsidePool")
      // }
    };
  };

  dropToolbarIcon = StepIcon => {
    return event => {
      const { diagram, toolbarHeight } = this.state;

      const coordinates = this.getCoordinates(event);
      console.log('dropToolbarIcon', coordinates);

      // Create item if dropped inside Workflow.
      // console.log(this.isInsideWorkflow(coordinates))
      if (this.isInsideWorkflow(coordinates)) {
        const id = this.generateId(4);
        // const parentId = this.generateId(4);

        const type = StepIcon.name.toLowerCase();
        console.log("dropToolbarIcon - daigram", diagram);

        const x = coordinates.x - Step.defaultProps.width;
        const y = coordinates.y - Step.defaultProps.height / 2;
        // const x = coordinates.x;
        // const y = coordinates.y;
        const step = {
          id,
          type,
          x,
          y,
          inputConnectors: [],
          outputConnectors: [],
          height: 40,
          width: 100,
        };
        if (type === 'line' || type === 'optionalline') {
          step.startX = step.startY = 0;
          step.endX = step.endY = 80;
        }
        if (type === 'pool') {
          step.d = 'M35,0 L35 290';
          step.height += 250;
          step.width += 450;
          step.x1 = coordinates.x;
          step.y1 = coordinates.y;
          step.x2 = coordinates.x + step.width;
          step.y2 = coordinates.y + step.height;
          this.setState(
            {
              diagram: Object.assign({}, diagram, {
                steps: [...diagram.steps, step],
              }),
              clickStep: {
                [id]: true,
              },
            },
            () => {
              this.props.diagramChange(this.state.diagram);
            },
          );
        }
        if (type === 'process') {
          step.height = 80;
          step.rx = 10;
          step.x1 = coordinates.x;
          step.y1 = coordinates.y;
          step.x2 = coordinates.x + step.width;
          step.y2 = coordinates.y + step.height;
          for (let i = 0; i < diagram.steps.length; i++) {
            if (diagram.steps[i].type === 'pool') {
              if (
                coordinates.x > diagram.steps[i].x1 &&
                coordinates.y > diagram.steps[i].y1 &&
                coordinates.x < diagram.steps[i].x2 &&
                coordinates.y < diagram.steps[i].y2
              ) {
                step.parentId = diagram.steps[i].id;
                this.setState(
                  {
                    diagram: Object.assign({}, diagram, {
                      steps: [...diagram.steps, step],
                    }),
                    clickStep: {
                      [id]: true,
                    },
                  },
                  () => {
                    this.props.diagramChange(this.state.diagram);
                  },
                );
              }
            }
          }
        }
        if (type === 'start') {
          step.x1 = coordinates.x;
          step.y1 = coordinates.y;
          step.x2 = coordinates.x + step.width;
          step.y2 = coordinates.y + step.height;
          for (let i = 0; i < diagram.steps.length; i++) {
            if (diagram.steps[i].type === 'pool') {
              if (
                coordinates.x > diagram.steps[i].x1 &&
                coordinates.y > diagram.steps[i].y1 &&
                coordinates.x < diagram.steps[i].x2 &&
                coordinates.y < diagram.steps[i].y2
              ) {
                step.parentId = diagram.steps[i].id;
                this.setState(
                  {
                    diagram: Object.assign({}, diagram, {
                      steps: [...diagram.steps, step],
                    }),
                    clickStep: {
                      [id]: true,
                    },
                  },
                  () => {
                    this.props.diagramChange(this.state.diagram);
                  },
                );
              }
            }
          }
        }
        if (type === 'end') {
          step.x1 = coordinates.x;
          step.y1 = coordinates.y;
          step.x2 = coordinates.x + step.width;
          step.y2 = coordinates.y + step.height;
          for (let i = 0; i < diagram.steps.length; i++) {
            if (diagram.steps[i].type === 'pool') {
              if (
                coordinates.x > diagram.steps[i].x1 &&
                coordinates.y > diagram.steps[i].y1 &&
                coordinates.x < diagram.steps[i].x2 &&
                coordinates.y < diagram.steps[i].y2
              ) {
                step.parentId = diagram.steps[i].id;
                this.setState(
                  {
                    diagram: Object.assign({}, diagram, {
                      steps: [...diagram.steps, step],
                    }),
                    clickStep: {
                      [id]: true,
                    },
                  },
                  () => {
                    this.props.diagramChange(this.state.diagram);
                  },
                );
              }
            }
          }
        }
        if (type === 'decision') {
          step.height = 55;
          step.width = 60;
          step.x1 = coordinates.x;
          step.y1 = coordinates.y;
          step.x2 = coordinates.x + step.width;
          step.y2 = coordinates.y + step.height;
          for (let i = 0; i < diagram.steps.length; i++) {
            if (diagram.steps[i].type === 'pool') {
              if (
                coordinates.x > diagram.steps[i].x1 &&
                coordinates.y > diagram.steps[i].y1 &&
                coordinates.x < diagram.steps[i].x2 &&
                coordinates.y < diagram.steps[i].y2
              ) {
                step.parentId = diagram.steps[i].id;
                this.setState(
                  {
                    diagram: Object.assign({}, diagram, {
                      steps: [...diagram.steps, step],
                    }),
                    clickStep: {
                      [id]: true,
                    },
                  },
                  () => {
                    this.props.diagramChange(this.state.diagram);
                  },
                );
              }
            }
          }
        }
        if (type === 'terminator') {
          step.x1 = coordinates.x;
          step.y1 = coordinates.y;
          step.x2 = coordinates.x + step.width;
          step.y2 = coordinates.y + step.height;
          for (let i = 0; i < diagram.steps.length; i++) {
            if (diagram.steps[i].type === 'pool') {
              if (
                coordinates.x > diagram.steps[i].x1 &&
                coordinates.y > diagram.steps[i].y1 &&
                coordinates.x < diagram.steps[i].x2 &&
                coordinates.y < diagram.steps[i].y2
              ) {
                step.parentId = diagram.steps[i].id;
                this.setState(
                  {
                    diagram: Object.assign({}, diagram, {
                      steps: [...diagram.steps, step],
                    }),
                    clickStep: {
                      [id]: true,
                    },
                  },
                  () => {
                    this.props.diagramChange(this.state.diagram);
                  },
                );
              }
            }
          }
        }
      }
    };
  };

  generateId = l => {
    const newId = randomString(l);

    const idExists = this.state.diagram.steps.find(({ id }) => id === newId);

    // If new random id was found, try again with a longer random string.
    return idExists ? this.generateId(l + 1) : newId;
  };

  getCoordinates = event => {
    const { offset, scroll } = this.state;

    return {
      x: event.clientX - offset.x + scroll.x,
      y: event.clientY - offset.y + scroll.y,
      // x: event.clientX,
      // y: event.clientY,
    };
  };

  isInsideWorkflow = coordinates => {
    const { diagram, offset, scroll, toolbarHeight } = this.state;

    const { height, width } = diagram;

    return (
      coordinates.x > offset.x + scroll.x - window.innerWidth &&
      coordinates.x < offset.x + scroll.x + window.innerWidth &&
      coordinates.y > offset.y + scroll.y + toolbarHeight - toolbarHeight * 7 &&
      coordinates.y < offset.y + scroll.y + height
    );
  };

  onMouseDown = event => {
    const { mouseOverConnection, toolbarHeight, diagram } = this.state;
    const startId = Object.keys(mouseOverConnection).find(p => mouseOverConnection[p]);
    if (startId) {
      console.log(startId);
      const step = diagram.steps.find(s => s.id === startId);
      const coordinates = this.getCoordinates(event);
      if (step.type === 'line' || step.type === 'optionalline') {
        const connection = mouseOverConnection[startId];
        this.setState({
          connection,
          dragging: coordinates,
        });
      } else {
        // this.setState({
        //   arrow: {
        //     startId,
        //     startX: coordinates.x,
        //     startY: coordinates.y - toolbarHeight,
        //     endX: coordinates.x,
        //     endY: coordinates.y - toolbarHeight,
        //   },
        // });
      }
    }
    if (event.target.id === 'svg') this.setState({ selected: {} });
  };

  onMouseEnter = () => {
    this.setState({
      isMouseOver: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      isMouseOver: false,
    });
  };

  onMouseMove = event => {
    const {
      diagram,
      dragging,
      isMouseDown,
      isMouseMove,
      selected,
      arrow,
      toolbarHeight,
      connection,
    } = this.state;

    const steps = [...diagram.steps];

    const coordinates = this.getCoordinates(event);

    const deltaX = dragging ? coordinates.x - dragging.x : 0;
    const deltaY = dragging ? coordinates.y - dragging.y : 0;

    if (isMouseDown) {
      // console.log(coordinates)

      if (arrow) {
        this.setState(s => ({
          arrow: {
            ...s.arrow,
            endX: coordinates.x,
            endY: coordinates.y - toolbarHeight,
          },
        }));
        return;
      }
      if (Object.keys(selected).length) {
        console.log("onMouseMove", steps);

        const ids = Object.keys(selected);

        steps
          .filter(el => {
            return el.parentId === ids[0] || el.id === ids[0];
          })
          .forEach(step => {
            if (step.type === 'pool') {
              step.x += deltaX;
              step.y += deltaY;
              step.x1 = step.x;
              step.y1 = step.y;
              step.x2 = step.x + step.width;
              step.y2 = step.y + step.height;
              //====================================== TODO: for drag line ================
              // if (connection) {
              //   console.log(step);
              //   step[connection + "X"] += deltaX;
              //   step[connection + "Y"] += deltaY;
              //   console.log(step);
              // } else {
              //   step.x += deltaX;
              //   step.y += deltaY;
              // }
              steps.filter(({ id }) => selected[id]);
              // console.log(steps)
              this.setState(
                {
                  diagram: Object.assign({}, diagram, { steps }),
                  dragging: coordinates,
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
            } else {
              const { inputConnectors, outputConnectors } = step;
              if (inputConnectors && inputConnectors.length > 0) {
                for (let i = 0; i < inputConnectors.length; i++) {
                  steps
                    .filter(({ id }) => {
                      return inputConnectors[i].id === id;
                    })
                    .forEach(step => {
                      step.endX += deltaX;
                      step.endY += deltaY;
                      step.endX2 += deltaX / 2;
                      step.endX3 = step.endX2;
                      step.endY3 += deltaY;
                    });
                }
              }

              if (outputConnectors && outputConnectors.length > 0) {
                for (let i = 0; i < outputConnectors.length; i++) {
                  steps
                    .filter(({ id }) => {
                      return outputConnectors[i].id === id;
                    })
                    .forEach(step => {
                      step.startX += deltaX;
                      step.startY += deltaY;
                      step.endX2 += deltaX / 2;
                      step.endY2 += deltaY;
                      step.endX3 = step.endX2;
                    });
                }
              }

              step.x += deltaX;
              step.y += deltaY;
              step.x1 = step.x;
              step.y1 = step.y;
              step.x2 = step.x + step.width;
              step.y2 = step.y + step.height;
              //====================================== TODO for drag line ================
              // if (connection) {
              //   console.log(step);
              //   step[connection + "X"] += deltaX;
              //   step[connection + "Y"] += deltaY;
              //   console.log(step);
              // } else {
              //   step.x += deltaX;
              //   step.y += deltaY;
              // }
              steps.filter(({ id }) => selected[id]);
              // console.log(steps)
              this.setState(
                {
                  diagram: Object.assign({}, diagram, { steps }),
                  dragging: coordinates,
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
            }
          });
      }
    } else {
      steps.forEach(step => {
        if (isMouseMove === false) {
          if (step.type === 'line') {
            if (!step.connected === true) {
              step.endY = coordinates.y - step.y;
              step.endX += deltaX;
              step.endY += deltaY;
              step.endX2 += deltaX / 2;
              step.endX3 = step.endX2;
              step.endY3 = step.endY;
            }
          }
        }
      });

      this.setState(
        {
          diagram: Object.assign({}, diagram, { steps }),
          dragging: coordinates,
        },
        () => {
          this.props.diagramChange(this.state.diagram);
        },
      );
    }
  };

  moveArrow = (event, type) => {
    // console.log(type)
    // if (type === 'process') {
    //   this.setState({ isMouseMove: true });
    // }
    // // if(type === 'decision'){
    // //   this.setState({isMouseMove: true})
    // // }
    // // if(type === 'terminator'){
    // //   this.setState({isMouseMove: true})
    // // }
    // // if(type === 'start'){
    // //   this.setState({isMouseMove: true})
    // // }
    // if (type === 'pool') {
    //   this.setState({ isMouseMove: false });
    // }
  };

  onDragConnection = (event, type, id) => {
    // let { diagram, dragging, isMouseDown, selected } = this.state;
    // if (!isMouseDown) return;
    // const steps = [...diagram.steps];
    // console.log('steps====', type);
    // const coordinates = this.getCoordinates(event);
    // console.log('coordinates====', coordinates);
    // const deltaX = dragging ? coordinates.x : 0;
    // const deltaY = dragging ? coordinates.y : 0;
    // if (type === 'right') {
    //   Step.defaultProps.width += 1;
    // }
    // if (type === 'bottom') {
    //   Step.defaultProps.height += 1;
    // }
    // // console.log(diagram.steps)
    // console.log('drag====', diagram);
  };

  onMouseUp = event => {
    const { diagram, arrow, mouseOverConnection } = this.state;
    const end = Object.keys(mouseOverConnection).find(p => mouseOverConnection[p]);
    if (arrow && end) {
      const steps = [...diagram.steps];
      const step = steps.find(s => s.id === arrow.startId);
      if (step.id !== end) {
        step.inputConnectors.push(arrow);
        this.setState({ diagram: Object.assign({}, diagram, { steps }) }, () => {
          this.props.diagramChange(this.state.diagram);
        });
      }
    }
    this.setState({
      dragging: null,
      isMouseDown: false,
      arrow: null,
    });
  };

  onWindowResize = container => {
    return () => {
      const rect = container.getBoundingClientRect();

      const dynamicView = {
        height: rect.height,
        width: rect.width,
      };
      this.setState({ dynamicView });
    };
  };

  onWindowScroll = () => {
    const scroll = {
      x: window.scrollX,
      y: window.scrollY,
    };
    this.setState({ scroll });
  };

  setMouseOverConnection = id => (event, data) => {
    const { mouseOverConnection } = this.state;
    mouseOverConnection[id] = event.type === 'mouseover' ? data || true : null;
    this.setState({
      mouseOverConnection: Object.assign({}, mouseOverConnection),
    });
  };

  setMouseOverStep = id => event => {
    const { mouseOverStep } = this.state;
    mouseOverStep[id] = event.type === 'mouseover';
    // console.log(mouseOverStep)
    this.setState({ mouseOverStep: Object.assign({}, mouseOverStep) });
  };
  selectStep = (id, parentId, type) => {
    const { diagram } = this.state;
    return event => {
      const { selected, shiftPressed } = this.state;
      let selectedStep = shiftPressed ? Object.assign({}, selected) : {};

      selectedStep[id] = true;
      selectedStep[parentId] = true;
      selectedStep[type] = true;

      this.setState({
        isMouseDown: event.type === 'mousedown',
        selected: selectedStep,
      });
    };
  };

  stopDragging = event => {
    this.setState({
      dragging: null,
      isMouseDown: false,
      selected: {},
    });
  };

  setClickStep = (event, id, type) => {
    let { clickStep, diagram, selected, offset } = this.state;
    const steps = [...diagram.steps];
    selected[id] = event.type === 'click';
    console.log("setClickStep", JSON.stringify(steps));

    steps
      .filter(el => {
        return el.id === id;
      })
      .forEach(step => {
        if (step.type === 'pool') {
          for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === 'line') {
              if (!steps[i].connected === true) {
                this.deleteById(steps, 'id', steps[i].id);
                console.log("setClickStep - map", steps);
              }
            }
          }
        } else if (step.type === 'process') {
          for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === 'line') {
              if (!steps[i].connected === true) {
                steps[i].connected = true;
                step.inputConnectors.push({ id: steps[i].id, point: 'end' });
              }
            }
          }
        } else if (step.type === 'start') {
          for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === 'line') {
              if (!steps[i].connected === true) {
                steps[i].connected = true;
                step.inputConnectors.push({ id: steps[i].id, point: 'end' });
              }
            }
          }
        } else if (step.type === 'decision') {
          for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === 'line') {
              if (!steps[i].connected === true) {
                steps[i].connected = true;
                step.inputConnectors.push({ id: steps[i].id, point: 'end' });
              }
            }
          }
        } else if (step.type === 'terminator') {
          for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === 'line') {
              if (!steps[i].connected === true) {
                steps[i].connected = true;
                step.inputConnectors.push({ id: steps[i].id, point: 'end' });
              }
            }
          }
        } else if (step.type === 'end') {
          for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === 'line') {
              if (!steps[i].connected === true) {
                steps[i].connected = true;
                step.inputConnectors.push({ id: steps[i].id, point: 'end' });
              }
            }
          }
        }
      });
    this.setState(
      {
        diagram: Object.assign({}, diagram, { steps }),
        clickStep: Object.assign({}, selected),
      },
      () => {
        this.props.diagramChange(this.state.diagram);
      },
    );
  };

  editElementStep = id => {
    const { diagram } = this.state;
    let step = diagram.steps.find(prod => {
      return prod.id === id;
    });
    this.setState({
      show: true,
      id: id,
      selectedStep: step,
    });
  };

  onChangeValue = (key, value) => {
    this.setState(
      prevState => ({
        ...prevState,
        selectedStep: {
          ...prevState.selectedStep,
          [key]: value,
        },
      }),
      () => this.updateProperty(true),
    );
  };
  updateProperty = show => {
    const { diagram, selectedStep } = this.state;
    let steps = diagram.steps;
    let indexOf = steps.findIndex(step => step.id === selectedStep.id);
    steps[indexOf] = selectedStep;
    this.setState(
      prevState => ({
        ...prevState,
        diagram: {
          ...prevState.diagram,
          steps: steps,
        },
        show,
      }),
      () => {
        this.props.diagramChange(this.state.diagram);
      },
    );
  };

  renderEditElement() {
    const { selectedStep } = this.state;
    // console.log('Modal', this.state);
    if (selectedStep.type === 'line') {
      return (
        <Grid container>
          <Grid container>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Property ID:</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="propertyId"
                label=""
                margin="dense"
                variant="filled"
                value={this.state.id}
                onChange={e => this.onChangeValue('propertyId', e.target.value)}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Value:</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                name="values"
                label=""
                margin="dense"
                variant="filled"
                value={selectedStep.values}
                onChange={e => this.onChangeValue('values', e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container>
          <Grid container>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Property ID:</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="propertyId"
                label=""
                margin="dense"
                variant="filled"
                value={this.state.id}
                onChange={e => this.onChangeValue('propertyId', e.target.value)}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Text:</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                name="name"
                label=""
                margin="dense"
                variant="filled"
                value={selectedStep.name}
                onChange={e => this.onChangeValue('name', e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Background Color:</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                name="bgColor"
                label=""
                margin="dense"
                variant="filled"
                value={selectedStep.bgColor}
                onChange={e => this.onChangeValue('bgColor', e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>

          {selectedStep.type === 'decision' ? (
            <Grid container>
              <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                <label>Value:</label>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="values"
                  label=""
                  margin="dense"
                  variant="filled"
                  value={selectedStep.values}
                  onChange={e => this.onChangeValue('values', e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          ) : (
              <>
                <Grid container>
                  <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <label>Respondent Field:</label>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      name="respondentfield"
                      label=""
                      margin="dense"
                      variant="filled"
                      value={selectedStep.respondentfield}
                      onChange={e => this.onChangeValue('respondentfield', e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <label>Status:</label>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      label=""
                      margin="dense"
                      variant="filled"
                      name="status"
                      value={selectedStep.status}
                      onChange={e => this.onChangeValue('status', e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <label>Action:</label>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      label=""
                      margin="dense"
                      variant="filled"
                      name="action"
                      value={selectedStep.action}
                      onChange={e => this.onChangeValue('action', e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <label>Notification:</label>
                  </Grid>
                  <Grid item xs={8}>
                    <NativeSelect
                      fullWidth
                      defaultValue="0"
                      value={selectedStep.notification}
                      onChange={e => this.onChangeValue('notification', e.target.value)}
                    >
                      <option value="0" disabled>
                        Select a Notification
                    </option>

                      <option value="welcome">Welcome Notification</option>
                      <option value="task">Task assign</option>
                    </NativeSelect>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <label>Bussiness Rules:</label>
                  </Grid>
                  <Grid item xs={8}>
                    <NativeSelect
                      fullWidth
                      defaultValue="0"
                      value={selectedStep.bussinessrule}
                      onChange={e => this.onChangeValue('bussinessrule', e.target.value)}
                    >
                      <option value="0" disabled>
                        Select a Business Rule
                    </option>

                      <option value="rule1">Rule 1</option>
                      <option value="rule2">Rule 2</option>
                    </NativeSelect>
                  </Grid>
                </Grid>
              </>
            )}
        </Grid>
      );
    }
  }

  onClickSugessionElement = (_step, id, type) => {
    const { diagram } = this.state;
    const randomId1 = this.generateId(4);
    const randomId2 = this.generateId(4);
    let x = _step.x + 150;
    let y = _step.y;

    const step2 = {
      id: randomId2,
      type: 'line',
      x: _step.x + 100,
      y: _step.y + 20,
      inputConnectors: [],
      outputConnectors: [],
      connected: true,
      startX: 0,
      startY: 0,
      endX2: 0,
      endY2: 0,
      endX3: 0,
      endY3: 0,
      endX: 78,
      endY: 0,
    };

    if (type === 'circle') {
      if (_step.type === 'start') {
        step2.x = _step.x + 70;
        x = _step.x + 120;
      }
      if (_step.type === 'end') {
        step2.x = _step.x + 70;
        x = _step.x + 120;
      }
      if (_step.type === 'process') {
        y = _step.y + 20;
        step2.y = _step.y + 40;
      }
      if (_step.type === 'decision') {
        step2.x = _step.x + 60;
        x = _step.x + 109;
        y = _step.y + 8;
        step2.y = _step.y + 27.5;
      }
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (_step.parentId === diagram.steps[i].id) {
            // step2.parentId = _step.parentId;
            if (x + 100 < diagram.steps[i].x2) {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'start',
                x,
                y,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log('step1====', step1);

              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            } else {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'start',
                x,
                y,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log(diagram.steps[i].id);
              diagram.steps[i].width += 200;
              // this.increasePoolWidth(diagram.steps, "id", diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            }
          }
        }
      }
    }
    if (type === 'twocircle') {
      if (_step.type === 'start') {
        step2.x = _step.x + 70;
        x = _step.x + 120;
      }
      if (_step.type === 'end') {
        step2.x = _step.x + 70;
        x = _step.x + 120;
      }
      if (_step.type === 'process') {
        y = _step.y + 20;
        step2.y = _step.y + 40;
      }
      if (_step.type === 'decision') {
        step2.x = _step.x + 60;
        x = _step.x + 111;
        y = _step.y + 8;
        step2.y = _step.y + 27.5;
      }
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (_step.parentId === diagram.steps[i].id) {
            if (x + 100 < diagram.steps[i].x2) {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'end',
                x,
                y,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log('End step1====', step1);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            } else {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'end',
                x,
                y,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log(diagram.steps[i].id);
              diagram.steps[i].width += 200;
              // this.increasePoolWidth(diagram.steps, "id", diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            }
          }
        }
      }
    }
    if (type === 'rect') {
      // console.log("step2====", step2);
      if (_step.type === 'start') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
        y = _step.y - 20;
      }
      if (_step.type === 'end') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
        y = _step.y - 20;
      }
      if (_step.type === 'process') {
        step2.x = _step.x + 100;
        x = _step.x + 180;

        step2.y = _step.y + 40;
      }
      if (_step.type === 'decision') {
        if (_step.outputConnectors.length > 0) {
          step2.x = _step.x + 30;
          step2.y = _step.y + 55;
          step2.endX2 = 0;
          step2.endY2 = 90;
          step2.endX3 = 90;
          step2.endY3 = 90;
          step2.endX = 110;
          step2.endY = 90;
          x = _step.x + 142;
          y = _step.y + 110;
        } else {
          step2.x = _step.x + 60;
          x = _step.x + 140;
          y = _step.y - 10;
          step2.y = _step.y + 27.5;
        }
      }
      if (_step.type === 'terminator') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
      }
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (_step.parentId === diagram.steps[i].id) {
            if (x + 100 < diagram.steps[i].x2) {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'process',
                x,
                y,
                height: 80,
                width: 100,
                rx: 10,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log('Process step1====', step1);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            } else {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'process',
                x,
                y,
                height: 80,
                width: 100,
                rx: 10,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log(diagram.steps[i].id);
              diagram.steps[i].width += 200;
              // this.increasePoolWidth(diagram.steps, "id", diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            }
          }
        }
      }
    }
    if (type === 'diamond') {
      console.log('step2====', step2);
      if (_step.type === 'start') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
        y = _step.y - 8;
      }
      if (_step.type === 'end') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
        y = _step.y - 8;
      }
      if (_step.type === 'process') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
        y = _step.y + 12;
        step2.y = _step.y + 40;
      }
      if (_step.type === 'decision') {
        step2.x = _step.x + 60;
        x = _step.x + 139;
        step2.y = _step.y + 27.5;
      }
      if (_step.type === 'terminator') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
      }
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (_step.parentId === diagram.steps[i].id) {
            if (x + 100 < diagram.steps[i].x2) {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'decision',
                x,
                y,
                height: 55,
                width: 60,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log('Decision step1====', step1);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            } else {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'decision',
                x,
                y,
                height: 55,
                width: 60,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log(diagram.steps[i].id);
              diagram.steps[i].width += 200;
              // this.increasePoolWidth(diagram.steps, "id", diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            }
          }
        }
      }
    }
    if (type === 'ellipse') {
      console.log('step2====', step2);
      if (_step.type === 'start') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
      }
      if (_step.type === 'end') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
      }
      if (_step.type === 'process') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
      }
      if (_step.type === 'decision') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
      }
      if (_step.type === 'terminator') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
      }
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (_step.parentId === diagram.steps[i].id) {
            if (x + 100 < diagram.steps[i].x2) {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'terminator',
                x,
                y,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log('Ellipse step1====', step1);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            } else {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'terminator',
                x,
                y,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log(diagram.steps[i].id);
              diagram.steps[i].width += 200;
              // this.increasePoolWidth(diagram.steps, "id", diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            }
          }
        }
      }
    }
    if (type === 'flow') {
      const {
        diagram,
        dragging,
        isMouseDown,
        selected,
        arrow,
        toolbarHeight,
        connection,
      } = this.state;

      if (_step.type === 'start' || _step.type === 'end') {
        let step3 = {
          id: randomId2,
          type: 'line',
          x: _step.x + 70,
          y: _step.y + 20,
          inputConnectors: [],
          outputConnectors: [],
          startX: 0,
          startY: 0,
          endX2: 0,
          endY2: 0,
          endX3: 0,
          endY3: 0,
          endX: 80,
          endY: 0,
        };
        this.setState(
          {
            diagram: Object.assign({}, diagram, {
              steps: [...diagram.steps, step3],
            }),
            clickStep: {},
          },
          () => {
            this.props.diagramChange(this.state.diagram);
          },
        );
        const step = diagram.steps.find(s => s.id === id);
        step.outputConnectors.push({
          id: randomId2,
          point: 'start',
        });
      } else if (_step.type === 'process') {
        let step3 = {
          id: randomId2,
          type: 'line',
          x: _step.x + 100,
          y: _step.y + 40,
          inputConnectors: [],
          outputConnectors: [],
          startX: 0,
          startY: 0,
          endX2: 0,
          endY2: 0,
          endX3: 0,
          endY3: 0,
          endX: 80,
          endY: 0,
        };
        this.setState(
          {
            diagram: Object.assign({}, diagram, {
              steps: [...diagram.steps, step3],
            }),
            clickStep: {},
          },
          () => {
            this.props.diagramChange(this.state.diagram);
          },
        );
        const step = diagram.steps.find(s => s.id === id);
        step.outputConnectors.push({
          id: randomId2,
          point: 'start',
        });
      } else if (_step.type === 'decision') {
        let step3 = {
          id: randomId2,
          type: 'line',
          x: _step.x + 60,
          y: _step.y + 28,
          inputConnectors: [],
          outputConnectors: [],
          startX: 0,
          startY: 0,
          endX2: 0,
          endY2: 0,
          endX3: 0,
          endY3: 0,
          endX: 80,
          endY: 0,
        };
        this.setState(
          {
            diagram: Object.assign({}, diagram, {
              steps: [...diagram.steps, step3],
            }),
            clickStep: {},
          },
          () => {
            this.props.diagramChange(this.state.diagram);
          },
        );
        const step = diagram.steps.find(s => s.id === id);
        step.outputConnectors.push({
          id: randomId2,
          point: 'start',
        });
      } else {
        let step3 = {
          id: randomId2,
          type: 'line',
          x: _step.x + 100,
          y: _step.y + 20,
          inputConnectors: [],
          outputConnectors: [],
          startX: 0,
          startY: 0,
          endX2: 0,
          endY2: 0,
          endX3: 0,
          endY3: 0,
          endX: 80,
          endY: 0,
        };
        this.setState(
          {
            diagram: Object.assign({}, diagram, {
              steps: [...diagram.steps, step3],
            }),
            clickStep: {},
          },
          () => {
            this.props.diagramChange(this.state.diagram);
          },
        );
        const step = diagram.steps.find(s => s.id === id);
        step.outputConnectors.push({
          id: randomId2,
          point: 'start',
        });
      }
    }
    if (type === 'delete') {
      const { diagram } = this.state;
      console.log('delete', id);
      console.log(diagram.steps);
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (diagram.steps[i].id === id) {
            this.deleteById(diagram.steps, 'parentId', diagram.steps[i].id);
            // this.deleteById(diagram.steps, 'type', 'line');
          }
        }
        if (diagram.steps[i].id === id) {
          if (
            diagram.steps[i].inputConnectors.length > 0 &&
            diagram.steps[i].outputConnectors.length > 0
          ) {
            for (let j = 0; j < diagram.steps.length; j++) {
              if (diagram.steps[j].outputConnectors.length > 0) {
                if (
                  diagram.steps[j].outputConnectors[0].id === diagram.steps[i].inputConnectors[0].id
                ) {
                  this.deleteById(
                    diagram.steps[j].outputConnectors,
                    'id',
                    diagram.steps[i].inputConnectors[0].id,
                  );
                }
              }
              if (diagram.steps[j].inputConnectors.length > 0) {
                if (
                  diagram.steps[j].inputConnectors[0].id === diagram.steps[i].outputConnectors[0].id
                ) {
                  this.deleteById(
                    diagram.steps[j].inputConnectors,
                    'id',
                    diagram.steps[i].outputConnectors[0].id,
                  );
                }
              }
            }
            this.deleteById(diagram.steps, 'id', diagram.steps[i].inputConnectors[0].id);
            this.deleteById(diagram.steps, 'id', diagram.steps[i].outputConnectors[0].id);
            this.deleteById(diagram.steps, 'id', diagram.steps[i].id);
            this.setState(
              {
                diagram: Object.assign({}, diagram, {
                  steps: [...diagram.steps],
                }),
                clickStep: {},
              },
              () => {
                this.props.diagramChange(this.state.diagram);
              },
            );
            return;
          } else if (
            diagram.steps[i].inputConnectors.length > 0 &&
            diagram.steps[i].outputConnectors.length < 1
          ) {
            for (let j = 0; j < diagram.steps.length; j++) {

              if (diagram.steps[j].outputConnectors.length > 0) {
                for (let k = 0; k < diagram.steps[j].outputConnectors.length; k++) {
                  if (
                    diagram.steps[j].outputConnectors[k].id === diagram.steps[i].inputConnectors[0].id
                  ) {
                    this.deleteById(
                      diagram.steps[j].outputConnectors,
                      'id',
                      diagram.steps[i].inputConnectors[0].id,
                    );
                  }
                }

              }
            }
            this.deleteById(diagram.steps, 'id', diagram.steps[i].inputConnectors[0].id);
            this.deleteById(diagram.steps, 'id', diagram.steps[i].id);
            this.setState(
              {
                diagram: Object.assign({}, diagram, {
                  steps: [...diagram.steps],
                }),
                clickStep: {},
              },
              () => {
                this.props.diagramChange(this.state.diagram);
              },
            );
            return;
          } else if (
            diagram.steps[i].inputConnectors.length < 1 &&
            diagram.steps[i].outputConnectors.length > 0
          ) {
            for (let j = 0; j < diagram.steps.length; j++) {
              if (diagram.steps[j].inputConnectors.length > 0) {
                if (
                  diagram.steps[j].inputConnectors[0].id === diagram.steps[i].outputConnectors[0].id
                ) {
                  this.deleteById(
                    diagram.steps[j].inputConnectors,
                    'id',
                    diagram.steps[i].outputConnectors[0].id,
                  );
                }
              }
            }
            this.deleteById(diagram.steps, 'id', diagram.steps[i].outputConnectors[0].id);
            this.deleteById(diagram.steps, 'id', diagram.steps[i].id);

            this.setState(
              {
                diagram: Object.assign({}, diagram, {
                  steps: [...diagram.steps],
                }),
                clickStep: {},
              },
              () => {
                this.props.diagramChange(this.state.diagram);
              },
            );
            return;
          } else {
            this.deleteById(diagram.steps, 'id', id);
            this.setState(
              {
                diagram: Object.assign({}, diagram, {
                  steps: [...diagram.steps],
                }),
                clickStep: {},
              },
              () => {
                this.props.diagramChange(this.state.diagram);
              },
            );
            return;
          }
        }
      }
      // this.deleteById(diagram.steps, 'id', id);
    }
  };

  deleteById = (arr, attr, value) => {
    let i = arr.length;
    while (i--) {
      if (arr[i] && arr[i].hasOwnProperty(attr) && arr[i][attr] === value) {
        arr.splice(i, 1);
      }
    }
    return arr;
  };

  increasePoolWidth = (arr, attr, value) => {
    let i = arr.length;
    while (i--) {
      if (arr[i] && arr[i].hasOwnProperty(attr) && arr[i][attr] === value) {
        arr[i].width += 200;
      }
    }
    return arr;
  };

  onCanvasClick = () => {
    this.setState({
      clickStep: {},
      selectStep: {},
      show: false,
    });
  };

  saveWorkflow = () => {
    const { diagram } = this.state;
    this.props.onSaveWorkflow({
      name: 'test',
      description: 'test',
      appId: '5f995ad426063f37f0ff604d',
      diagram: JSON.stringify(diagram),
    });
  };
  handleClickOpen = () => {
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };
  
  onUpdate = (show, currentStep) => {
    const { diagram, selectedStep } = this.state;
    let steps = diagram.steps;
    let indexOf = steps.findIndex(step => step.id === currentStep.id);
    steps[indexOf] = currentStep;
    this.setState(
      prevState => ({
        ...prevState,
        diagram: {
          ...prevState.diagram,
          steps: steps,
        },
        show,
      }),
      () => {
        this.props.diagramChange(this.state.diagram);
      },
    );
  };
  render() {
    // console.log("Props Data:",this.props)
    // console.log("This State:",this.state)

    const { editable } = this.props;

    const {
      diagram,
      isMouseOver,
      rectangularSelection,
      selected,
      toolbarHeight,
      arrow,
      mouseOverConnection,
      mouseOverStep,
      clickStep,
    } = this.state;
    // console.log("diagram====",diagram)
    const { height, width } = diagram;
    const containerStyle = {
      height: toolbarHeight + height,
      width,
      // width: '100%',
      display: 'flex',
    };

    return editable ? (
      <g
        onMouseDown={this.onMouseDown}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseMove={this.onMouseMove}
        onMouseUp={this.onMouseUp}
        style={containerStyle}
      >
        <Toolbar dropToolbarIcon={this.dropToolbarIcon} dragToolbarIcon={this.dragToolbarIcon} />
        <ErrorBoundary>
          <Canvas
            arrow={arrow}
            diagram={diagram}
            clickStep={clickStep}
            setClickStep={this.setClickStep}
            moveArrow={this.moveArrow}
            onDragConnection={this.onDragConnection}
            editElementStep={id => this.editElementStep(id)}
            mouseOverConnection={mouseOverConnection}
            setMouseOverConnection={this.setMouseOverConnection}
            mouseOverStep={mouseOverStep}
            setMouseOverStep={this.setMouseOverStep}
            rectangularSelection={rectangularSelection}
            selected={selected}
            selectStep={this.selectStep}
            stopDragging={this.stopDragging}
            onClickSugessionElement={this.onClickSugessionElement}
            onCanvasClick={() => this.onCanvasClick()}
          />
        </ErrorBoundary>

        {/* {this.state.show && (
          <Modal open={this.state.show} onClose={this.handleClose}>
            {this.renderEditElement()}
          </Modal>
        )} */}

        {this.state.show && (
          <PropertiesDialog open={this.state.show} handleClose={this.handleClose}
          onUpdate={this.onUpdate}
          selectedStep={this.state.selectedStep}>
          </PropertiesDialog>
        )}

        {/* <button className="btn" onClick={() => this.saveWorkflow()}>
          Save
        </button> */}
      </g>
    ) : (
        <Canvas diagram={diagram} />
      );
  }
}

export default hot(withRouter(Redux(Pipeline)));
