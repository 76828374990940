import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { withRouter } from 'react-router-dom'
import View from './view'
import Redux from './redux'
import { updateTableData, setDatafromApi } from 'packages/common'
import sharedJSON from 'packages/sharedJSON'
import ConfirmDialog from '../confirmDialog'
import CopyFormDialog from './CopyFormDialog'
import {
	handleSelectedRow,
	requestSort,
	handleTableSelectAllClick,
} from 'modules/common';
const APPRENDERER_URL = process.env.REACT_APP_APPRENDERER_URL
let keysArr = [
	'_id',
	'createdDateTime',
	'date',
	'appId',
	'__v',
	'dataQualityTools',
	'createdBy',
	'design',
	'modalData',
	'orgId',
	'updatedBy',
	'updatedDateTime',
	'workflowId',
	'type'
]

const Container = (props) => {
	const [state, setState] = useState({ ...sharedJSON })
	const [open, setOpen] = React.useState(false)
	const [form, setForm] = React.useState({})
	const [itemToCopy, setItemToCopy] = React.useState({})
	const [copyDialog, setCopyDialog] = useState(false)

	const copyForm = (item) => {
		console.log("item", item);
		setItemToCopy(item)
		setCopyDialog(true)
	}

	const createCopy = (name) => {
		let item = { ...itemToCopy, name }
		setItemToCopy(item)
		setCopyDialog(false)
		props.saveEntryForm(item)
	}

	const tableCrudOperations = (item, type, eve) => {
		if (type === "edit") {
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/forms/${item._id}` })
		} else if (type === "delete") {
			setForm(item)
			setOpen(true)
		} else if (type === "view") {
			eve.preventDefault()
			console.log(`${APPRENDERER_URL}/apps/${props.match.params.dappId}/form/${item.name}/new`);
			window.open(`${APPRENDERER_URL}/apps/${props.match.params.dappId}/form/${item.name}/new`, "_blank")
		} else if (type === "copy") {
			copyForm(item)
		}
	}

	const handleChangePage = (event, page) => setState({ ...state, page: page })

	const handleChangeRowsPerPage = event => setState({ ...state, rowsPerPage: event.target.value, page: 0 })

	const onRowClick = (rowData, rowMeta) => console.log('onRowClick')

	const searchHandler = (e) => setState({ ...state, searchText: e ? e.target.value : "" })

	const handleInputChange = (event) => {
		let obj = state.filterTableData
		let list = obj.columns
		list.map((item, index) => {
			if (item.name === event.target.value) list[index].checked = event.target.checked
		})
		obj.columns = [...list]
		setState({ ...state, filterTableData: obj })
		setState({ ...updateTableData(state) })
	}

	const resetFilters = (event) => setState({ ...state, filterList: [] })

	const handleDropdownChange = (event, index, column) => {
		const value = event.target.value === "All" ? [] : [event.target.value];
		// filterUpdate(index, value, column, 'dropdown')
		let list = state.filterList
		list[index] = event.target.value
		setState({ ...state, filterList: [...list] })
		setState({ ...updateTableData(state) })
	}

	const handleRequestSort = (event, property) => setState({ ...state, ...(requestSort(event, property, state)) })

	const handleSelectAllClick = event => setState({ ...state, selected: handleTableSelectAllClick(event, state.filterTableData.datasets) })

	const handleAddClick = () => {
		console.log('handleAddClick =+=')
		props.history.push({ pathname: `/builder/${props.match.params.dappId}/forms/new` })
	}

	const handleCheck = (event, id) => setState({ ...state, selected: handleSelectedRow(event, id, state.selected) })

	useEffect(() => {
		setState({ ...updateTableData(state) })
	}, [state.searchText, state.filterList])

	useEffect(() => {
		props.getForms(props.match.params.dappId)
	}, [])

	useEffect(() => {
		props.formSuccess && props.getForms(props.match.params.dappId)
	}, [props.formSuccess])

	useEffect(() => {
		const rows = [
			{
				id: 'name',
				numeric: false,
				disablePadding: false,
				label: 'Name',
				sort: true
			},
			/*{
				id: 'useLedgerTable',
				numeric: false,
				disablePadding: false,
				label: 'Use Ledger Table',
				sort: true
			},*/
			{
				id: 'description',
				numeric: false,
				disablePadding: false,
				label: 'Description',
				sort: true
			}
		];
		setState({ ...setDatafromApi(props.forms.data, keysArr, state, rows) })
	}, [props.forms.data])

	return (<>
		<View
			{...props}
			state={state}
			handleChangePage={handleChangePage}
			handleChangeRowsPerPage={handleChangeRowsPerPage}
			onRowClick={onRowClick}
			searchHandler={searchHandler}
			handleCheck={handleCheck}
			handleInputChange={handleInputChange}
			resetFilters={resetFilters}
			handleDropdownChange={handleDropdownChange}
			handleRequestSort={handleRequestSort}
			handleSelectAllClick={handleSelectAllClick}
			tableCrudOperations={tableCrudOperations}
			handleAddClick={handleAddClick}
		/>
		<ConfirmDialog
			title="Delete"
			open={open}
			setOpen={setOpen}
			onConfirm={() => props.deleteEntryForm({ _id: form._id, appId: props.match.params.dappId })}
		> {"form "} {form.name} </ConfirmDialog>
		<CopyFormDialog
			open={copyDialog}
			createCopy={createCopy}
			close={(close) => setCopyDialog(close)}
			msg={""}
		/>
	</>)
}

export default hot(withRouter(Redux(Container)))