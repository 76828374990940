import React, { Component } from 'react'
import {
	TextField, FormControl, Select, Grid,
	MenuItem, FormGroup, FormLabel, Button
} from '@material-ui/core'
import 'styles/page-designer.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'modules/lodash'
import { withRouter } from 'react-router-dom'

const dataTypeNumList = [{ "value": "is_equal_to", "name": "Is equal to" }, { "value": "is_greater_than", "name": "Is greater than" }, { "value": "is_less_than", "name": "Is less than" }, , { "value": "in_between", "name": "In between" }]
const dataTypeStringList = [{ "value": "starts_with", "name": "Starts with" }, { "value": "ends_with", "name": "Ends with" }, , { "value": "matches", "name": "Matches" }, { "value": "includes", "name": "Includes" }, { "value": "is_equal_to", "name": "Is equal to" }, { "value": "is_greater_than", "name": "Is greater than" }, { "value": "is_less_than", "name": "Is less than" }, , { "value": "in_between", "name": "In between" }]

const Conditions = (props) => {
	let { classes, values, selectedComparatorList, dataModels } = props;

	console.log("value.dataElementss", values.dataElements);
	console.log("values.condition", values.condition);


	return (<>
		<FormGroup row className="items-center">
			<FormLabel component="legend">
				Conditions
            </FormLabel>
		</FormGroup>

		<FormGroup row className="items-center  max-w-full">
			<Grid container>
				<Grid xs={2} style={{ "padding": "5px" }}>
					<FormControl fullWidth variant="outlined" className={classes.formControl}>
						<Select
							value={values.condition.selectedConditionDataElement ? values.condition.selectedConditionDataElement : ''}
							onChange={props.onConditionChangeHandler}
							inputProps={{
								name: 'selectedConditionDataElement',
								id: 'selectedConditionDataElement',
							}}>
							<MenuItem value="">
								<em>Select a column</em>
							</MenuItem>
							{values.dataElements.map((item, i) => {
								return <MenuItem key={i} value={item} >{item.description ? item.description : item.name}</MenuItem>
							})}
						</Select>
					</FormControl>
				</Grid>
				<Grid xs={2} style={{ "padding": "5px" }}>
					<FormControl fullWidth variant="outlined" className={classes.formControl}>
						<Select
							value={values.condition.selectedConditionOperator ? values.condition.selectedConditionOperator : ''}
							onChange={props.onConditionChangeHandler}
							inputProps={{
								name: 'selectedConditionOperator',
								id: 'selectedConditionOperator',
							}}>
							<MenuItem value="">
								<em>Select Operator</em>
							</MenuItem>
							{values.condition.selectedConditionDataElement && values.condition.selectedConditionDataElement !== "" &&
								(values.condition.selectedConditionDataElement.type === "Integer"
									? dataTypeNumList
									: dataTypeStringList).map((element, i) => {
										return <MenuItem key={i} value={element.value}>{element.name}</MenuItem>
									})}
						</Select>
					</FormControl>
				</Grid>
				<Grid xs={3} style={{ "padding": "5px" }}>
					<TextField
						label="Enter Value"
						autoFocus
						id="value"
						name="value"
						value={values.condition.value}
						onChange={props.onConditionChangeHandler}
						variant="outlined"
						fullWidth
					/>
				</Grid>
				<Grid xs={2} style={{ "padding": "5px" }}>
					<FormControl fullWidth variant="outlined" className={classes.formControl}>
						<Select
							value={values.condition.selectedConditionClause ? values.condition.selectedConditionClause : ''}
							onChange={props.onConditionChangeHandler}
							inputProps={{
								name: 'selectedConditionClause',
								id: 'selectedConditionClause',
							}}>
							<MenuItem value="">
								<em>Select a clause</em>
							</MenuItem>
							<MenuItem value={"and"}>{"AND"}</MenuItem>
							<MenuItem value={"andGroup"}>{"AND ("}</MenuItem>
							<MenuItem value={"or"}>{"OR"}</MenuItem>
							<MenuItem value={"orGroup"}>{"OR ("}</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid xs={2} style={{ "padding": "5px 20px" }}>
					<Button
						color="secondary"
						variant="outlined"
						className={classes.formControl}
						onClick={() => {
							props.addCondition(values.condition);
						}}
						fullWidth
					// disabled={!this.canBeSubmitted()}
					>
						{"Add"}
            </Button>
				</Grid>
			</Grid>
		</FormGroup>
	</>)
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}

function mapStateToProps({ dataModels }) {
	return {
		dataModels: dataModels,
	}
}

export default withRouter(
		connect(mapStateToProps, mapDispatchToProps)(Conditions)
	)