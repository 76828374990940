export default {
	OPEN_NEW_PAGE_DIALOG: undefined,
	CLOSE_NEW_PAGE_DIALOG: undefined,

	GET_PAGES: undefined,
	GET_PAGES_SUCCESS: undefined,
	GET_PAGES_FAILURE: undefined,

	EMPTY_PAGE: undefined,

	GET_PAGE: undefined,
	GET_PAGE_SUCCESS: undefined,
	GET_PAGE_FAILURE: undefined,

	SAVE_PAGE: undefined,
	SAVE_PAGE_SUCCESS: undefined,
	SAVE_PAGE_FAILURE: undefined,

	UPDATE_PAGE: undefined,
	UPDATE_PAGE_SUCCESS: undefined,
	UPDATE_PAGE_FAILURE: undefined,

	DELETE_PAGE: undefined,
	DELETE_PAGE_SUCCESS: undefined,
	DELETE_PAGE_FAILURE: undefined,

	// Charts

	TOGGLE_CHART_LIST_COMPONENT: undefined,
	SAVE_RESIZED_CHART_DATA: undefined,

	ADD_BAR_CHART_DATA: undefined,
	ADD_STACKED_BAR_CHART_DATA: undefined,
	ADD_PIE_CHART_DATA: undefined,
	ADD_MIXED_CHART_DATA: undefined,
	ADD_DOUGHNUT_CHART_DATA: undefined,
	ADD_RADAR_CHART_DATA: undefined,
	ADD_POLAR_CHART_DATA: undefined,
	ADD_BULLET_CHART_DATA: undefined,
	ADD_LIST_CHART_DATA: undefined,
	ADD_SIMPLE_TABLE_DATA: undefined,
	ADD_LINE_CHART_DATA: undefined,
	ADD_BUBBLE_CHART_DATA: undefined,
	ADD_SUMMARYTABLE_DATA: undefined,
	ADD_CARD_DATA: undefined,
	ADD_HTML_DATA: undefined,
	ADD_SMIPLELIST_DATA: undefined,

	CURRENT_CHART_TO_EDIT: undefined,
	CLOSE_CHART_TO_EDIT: undefined,
	DELETE_CHART_DATA: undefined,
	SAVE_CHART_DATA: undefined,
	ADD_LINKS_DATA: undefined,

}
