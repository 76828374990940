import React, { Component } from "react"
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import "styles/page-designer.css"

const styles = theme => ({
	closeIconContainer: {
		display: 'flex',
		flexDirection: 'row'
	}
});

class ImageButtonElement extends Component {

	onDragOver = (e) => {
		console.log("onDragOver", e, this.props.id)
		e.preventDefault()
		e.dataTransfer.setData("text/plain", "")
	};

	onDragStart = (e, id) => {
		console.log("onDragStart", e, id)
		// this.drop(id);
	};

	onDragEnd = (e, id) => {
		console.log("onDragEnd", e, id)
	}

	render() {
		const { buttonContainerStyle, src, imageStyle, buttonTextStyle, buttonText, id } = this.props
		return (
			<div
				className={buttonTextStyle + " droppable-element"}
				draggable={true}
				unselectable="on"
				id={id}
				// this is a hack for firefox
				// Firefox requires some kind of initialization
				// which we can do by adding this attribute
				// @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
				onDragStart={e => {
					e.dataTransfer.setData("text/plain", "")
					e.dataTransfer.setData("id", id)
					this.onDragStart(e, id)
				}}
				onDragEnd={this.onDragEnd}
			>
				<div className={buttonContainerStyle}>
					<img src={src} alt="" className={imageStyle} />
					<div>{buttonText}</div>
				</div>
			</div>

		)
	}
}

export default withStyles(styles, { withTheme: true })(
	withRouter(ImageButtonElement)
)