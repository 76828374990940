import { Grid, TextField, NativeSelect, Switch, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import React from 'react';

const Basicinfo = props => {
  const { selectedstep, notifications } = props;
  console.log('selectedstep.enableEmail =', selectedstep.enableEmail);
  return (
    <>
      {props.value === props.index && (
        <Grid container className="formPmodel">
          <Grid container>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Label</label>
            </Grid>
            <Grid item xs={5}>
              <TextField
                id="name"
                margin="dense"
                name="label"
                variant="filled"
                value={selectedstep.label}
                onChange={e => {
                  props.onChangeValue(e.target.name, e.target.value);
                  props.onChangeValue('name', e.target.value);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <label>TaskID</label>
            </Grid>
            <Grid item xs={5}>
              <TextField
                name="taskId"
                margin="dense"
                variant="filled"
                value={selectedstep.id}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Status</label>
            </Grid>
            <Grid item xs={5}>
              <TextField
                id="status"
                margin="dense"
                name="status"
                variant="filled"
                value={selectedstep.status}
                onChange={e => props.onChangeValue(e.target.name, e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Action</label>
            </Grid>
            <Grid item xs={5}>
              <TextField
                id="action"
                margin="dense"
                name="action"
                variant="filled"
                value={selectedstep.action}
                onChange={e => props.onChangeValue(e.target.name, e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Respondent Field:</label>
            </Grid>
            <Grid item xs={5}>
              <TextField
                name="respondentfield"
                margin="dense"
                variant="filled"
                value={selectedstep.respondentfield}
                onChange={e => props.onChangeValue(e.target.name, e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Enable Email</label>
            </Grid>
            <Grid item xs={5}>
              {/* <Switch
                checked={selectedstep.enableEmail}
                onChange={e => props.onChangeValue(e.target.name, e.target.checked)}
                color="primary"
                name="enableEmail"
                // inputProps={{ 'aria-label': 'primary checkbox' }}
                fullWidth
              /> */}
               <RadioGroup
                name="enableEmail"
                value={selectedstep.enableEmail}
                onChange={e => props.onChangeValue(e.target.name, e.target.value)}
                row
              >
                <FormControlLabel value={"true"} control={<Radio />} label="Yes" />
                <FormControlLabel value={"false"} control={<Radio />} label="No" />

              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <NativeSelect
                fullWidth
                defaultValue="0"
                name="notification"
                value={selectedstep.notification}
                onChange={e => props.onChangeValue(e.target.name, e.target.value)}
              >
                <option value="0" disabled>
                  Notification: Loan Application Reviewer Template
                </option>
                {notifications &&
                  notifications.data.map(item => {
                    return <option value={item.name}>{item.name}</option>;
                  })}
              </NativeSelect>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Basicinfo;
