import React from 'react'
import {
	TextField, FormControl, FormGroup, RadioGroup, FormControlLabel, Radio, FormLabel, Select, MenuItem
} from '@material-ui/core'
import NosqlView from './nosqlView'
import RdbmsView from './rdbmsView'
import FileView from './fileView'
import BigqueryView from './bigqueryView'
import QldbView from './qldbView'
import BotView from './botView'
import ApiView from './apiView'
import S3View from './s3View'

let types = [
	{ value: "RDBMS", label: "Relational Database" },
	{ value: "QLDB", label: "Quantum Ledger Database" },
	{ value: "NOSQL", label: "NoSQL Database" },
	{ value: "FILE", label: "File/Folder" },
	{ value: "BOT", label: "Bot (RPA Bot)" },
	{ value: "API", label: "API" },
	{ value: "BIGQUERY", label: "Google BigQuery Database" },
	{ value: "GCS", label: "Google Cloud Storage" },
	{ value: "S3", label: "Amazon S3" }
]
export function DatasourceDetails(props) {
	const { form } = props.state;
	const { classes, testDatasource, testS3Connection, testQLDBConnection, error } = props;
	return (form && <div className="p-16 sm:p-24 max-w-2xl">
		<form className="form" noValidate autoComplete="off" >
			<FormControl row className="flex items-center  max-w-full">
				<FormLabel variant="h6" className="flex-1">{"Name *"}</FormLabel>
				<TextField
					className="mt-8 mb-16"
					style={{ flex: 3 }}
					error={form.name === ''}
					required
					placeholder="Name"
					autoFocus
					id="name"
					name="name"
					value={form.name}
					onChange={props.handleChange}
					variant="outlined"
					fullWidth
					helperText={error?.name && error?.name}
					error={Boolean(error?.name)}
				/>
			</FormControl>
			<FormControl row className="flex items-center  max-w-full">
				<FormLabel variant="h6" className="flex-1">{"Description"}</FormLabel>
				<TextField
					className="mt-8 mb-16"
					style={{ flex: 3 }}
					required
					placeholder="Description"
					id="description"
					name="description"
					value={form.description}
					onChange={props.handleChange}
					variant="outlined"
					fullWidth
				/>
			</FormControl>

			<FormControl>
				<FormLabel variant="h6" className="label-headings">{"Datasource Type *"}</FormLabel>
				<FormControl fullWidth style={{ marginBottom: 10 }}>
					<Select
						value={form.dsType || ''}
						onChange={props.handleChange}
						inputProps={{
							name: 'dsType',
							id: 'dsType',
						}}>
						<MenuItem value={form.dsType === undefined ? undefined : ""} >
							<em>{"None"}</em>
						</MenuItem>
						{types.map(type => {
							return (
								<MenuItem key={type} value={type.value}>
									{type.label}
								</MenuItem>
							)
						})}
					</Select>
				</FormControl>
			</FormControl>

			{/* {(form.dsType || props.match.params.datasourceId === 'new') &&
				<FormControl row className="flex items-center max-w-full" component="fieldset">
					<FormLabel variant="h6">{"Datasource Type *"}</FormLabel>
					<RadioGroup
						aria-label="Datasource Type"
						name="dsType"
						className={classes.group}
						value={form.dsType}
						onChange={props.handleChange}
						row>
						<FormControlLabel value="RDBMS" control={<Radio />} label="Relational Database" />
						<FormControlLabel value="QLDB" control={<Radio />} label="Quantum Ledger Database" />
						<FormControlLabel value="NOSQL" control={<Radio />} label="NoSQL Database" />
						<FormControlLabel value="FILE" control={<Radio />} label="File/Folder" />
						<FormControlLabel value="BOT" control={<Radio />} label="Bot (RPA Bot)" />
						<FormControlLabel value="API" control={<Radio />} label="API" />
						<FormControlLabel value="BIGQUERY" control={<Radio />} label="Google BigQuery Database" />
						<FormControlLabel value="GCS" control={<Radio />} label="Google Cloud Storage" />
						<FormControlLabel value="S3" control={<Radio />} label="Amazon S3" />
					</RadioGroup>
				</FormControl>} */}

			{form.dsType == 'RDBMS' && <RdbmsView
				handleChange={props.handleChange}
				form={props.state.form}
				classes={props.classes}
				testDatasource={testDatasource}
			/>}
			{form.dsType == 'NOSQL' && <NosqlView
				handleChange={props.handleChange}
				form={props.state.form}
				classes={props.classes}
				testDatasource={testDatasource}
			/>}
			{form.dsType == 'FILE' && <FileView
				handleFileChange={props.handleFileChange}
				handleChange={props.handleChange}
				form={props.state.form}
				classes={props.classes}
				testDatasource={testDatasource}
			/>}
			{form.dsType == 'BIGQUERY' && <BigqueryView
				handleFileChange={props.handleFileChange}
				handleChange={props.handleChange}
				form={props.state.form}
				classes={props.classes}
				testDatasource={testDatasource}
			/>}
			{form.dsType == 'QLDB' && <QldbView
				handleFileChange={props.handleFileChange}
				handleChange={props.handleChange}
				form={props.state.form}
				classes={props.classes}
				testQLDBConnection={testQLDBConnection}
			/>}
			{form.dsType == 'API' && <ApiView
				handleChange={props.handleChange}
				form={props.state.form}
				classes={props.classes}
				testDatasource={testDatasource}
			/>}
			{form.dsType == 'BOT' && <BotView
				handleChange={props.handleChange}
				form={props.state.form}
				classes={props.classes}
				testDatasource={testDatasource}
			/>}
			{form.dsType == 'S3' && <S3View
				handleChange={props.handleChange}
				form={props.state.form}
				classes={props.classes}
				testS3Connection={testS3Connection}
			/>}
			<br />
			{/* {form.dsType != null && <FormControl row className="flex items-center  max-w-full">
				<FormLabel variant="h6">{"Connection Type"}</FormLabel>
				<RadioGroup
					aria-label="Connection Type"
					name="connectionType"
					className={classes.group}
					value={form.connectionType}
					onChange={props.handleChange}
					row>
					<FormControlLabel value="PULL" control={<Radio />} label="Pull from datasource" />
					<FormControlLabel value="PUSH" control={<Radio />} label="Push into network" />
				</RadioGroup>
			</FormControl>} */}
		</form>
	</div>)
}
