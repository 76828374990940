import { ActionTypes } from 'constants/index'

export const getNetworks = (appId) => dispatch => {
    return dispatch({ type: ActionTypes.GET_NETWORKS, payload: appId })
}

export const setNetworksSearchText = (value) => dispatch => {
    return dispatch({ type: SET_NETWORKS_SEARCH_TEXT, searchText: value })
}

export const getNetwork = (params) => dispatch => {
    return dispatch({ type: ActionTypes.GET_NETWORK, payload: params })
}

export const saveNetwork = (data) => dispatch => {
    return dispatch({ type: ActionTypes.SAVE_NETWORK, payload: data })
}

export const updateNetwork = (data) => dispatch => {
    return dispatch({ type: ActionTypes.UPDATE_NETWORK, payload: data })
}

export const deleteNetwork = (data) => dispatch => {
    return dispatch({ type: ActionTypes.DELETE_NETWORK, payload: data })
}

export const newNetwork = () => dispatch => {
    const data = {
        name: '',
        description: '',
        networkType: '',
        participants: [],
        dateCreated: '',
        activatedDate: '',
        active: true
    };
    return dispatch({ type: ActionTypes.GET_NETWORK_SUCCESS, payload: data })
}