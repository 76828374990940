import axios from 'axios/index';
import _ from 'modules/lodash';
import * as Actions from 'actions';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export function dynamicColors() {
	var r = Math.floor(Math.random() * 255);
	var g = Math.floor(Math.random() * 255);
	var b = Math.floor(Math.random() * 255);
	return "rgb(" + r + "," + g + "," + b + ")";
};

export function generateURL(chartData, chartType, conditions) {
	let targetURL = '';
	const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
	targetURL = API_BASE_URL + "/dataconnect/" + chartData.selectedDataModel;
	if (chartType === "list" || chartType === "simplelist" || chartType === "summaryTable") {
		targetURL = `${API_BASE_URL}/dataconnect/table/${chartData.selectedDataModel}`;
		targetURL += `?type=list&`;
		if (chartData.loadChildData) {
			if (chartData.parentDataElement)
				targetURL += `&parentDataElement=${chartData.parentDataElement}`;
			else
				targetURL += '&fields=';
		} else
			targetURL += chartData.selectedDataElement.reduce(reducer, '&fields=');
	} else if (chartType === "card") {
		targetURL += `?type=card`;
		if (chartData.isCardCategories) {
			if (chartData.categories && chartData.categories.length > 0) {
				let cate = JSON.stringify(chartData.categories);
				targetURL += "&categories=" + cate;
			} else {
				console.error("Categories are madatory")
			}
		} else {
			targetURL += `&field=${chartData.selectedDataElement[0].value}`;
			targetURL += "&metric=" + chartData.metric;
			if (chartData.selectedGroupingDataElement && chartData.selectedGroupingDataElement.length > 0)
				targetURL += "&groupBy=" + chartData.selectedGroupingDataElement[0].value;
		}
	} else if (chartType === "pie" || chartType === "doughnut"
		|| chartType === "bar" || chartType === "line" || chartType === "mixed") {
		if (chartType === "bar")
			targetURL = API_BASE_URL + "/dataconnect/bar/" + chartData.selectedDataModel
		else if (chartType === "line")
			targetURL = API_BASE_URL + "/dataconnect/line/" + chartData.selectedDataModel
		else if (chartType === "mixed")
			targetURL = API_BASE_URL + "/dataconnect/mixed/" + chartData.selectedDataModel
		else
			targetURL = API_BASE_URL + "/dataconnect/chart/" + chartData.selectedDataModel;
		targetURL += `?type=${chartType}`;
		if (chartType === "bar") {
			targetURL += `&barXAxis=${chartData.barXAxis.value}`
			targetURL += `&barYAxis=${chartData.barYAxis.value}`
			if (chartData.metric === 'count' && chartData.groupByXAxis) {
				targetURL += `&groupByXAxis=${chartData.groupByXAxis}`
			}
		}
		if (chartData.datelabeltype) {
			targetURL += '&labeltype=date'
		}
		targetURL += chartData && chartData.selectedDataLabels && chartData.selectedDataLabels.reduce(reducer, '&label=');
		targetURL += chartData.selectedDataElement.reduce(reducer, '&measure=');
		if (chartData.metric)
			targetURL += "&metric=" + chartData.metric;
		targetURL += chartData.labelformat ? `&format=${chartData.labelformat}` : '&format=%Y-%m-%d';
	} else {
		targetURL += chartData.selectedDataElement.reduce(reducer, '?q=');
	}
	if (chartData.conditions && chartData.conditions.length > 0) {
		let cond = JSON.stringify(chartData.conditions);
		targetURL += "&conditions=" + cond;
	}
	if (conditions && conditions.length > 0) {
		let cond = JSON.stringify(conditions);
		targetURL += "&pConditions=" + cond;
	}
	return targetURL;
};

export function pageRequest(data, save) {
	let url = save ? `${API_BASE_URL}/pages` : `${API_BASE_URL}/pages/${data._id}`;
	let body = {
		name: data.name,
		title: data.title,
		pageLevelDataModel: data.pageLevelDataModel,
		conditions: data.conditions,
		header: data.header,
		footer: data.footer,
		body: data.body,
		description: data.description,
		appId: data.appId,
		type: data.type,
		chart: data.chart,
		default: data.default,
		url: data.url,
		isActive: data.isActive,
		hide: data.hide,
		rank: data.rank,
		selectedRoles: data.selectedRoles
	};
	let options = {
		headers: {
			appId: data.appId
		}
	}
	return save ? axios.post(url, body, options) : axios.put(url, body, options);
}

export function generateCardBody(metric, data) {
	if (data && metric)
		if (metric === "count") {
			return data.length;
		} else if (metric === "total") {
			return data.reduce((acc, item) => {
				return acc + item;
			}, 0);
		} else if (metric === "average") {
			let sum = data.reduce((acc, item) => {
				return acc + item;
			}, 0);
			return sum / data.length;
		} else if (metric === "max") {
			return Math.max(...data.map(Number));
		} else if (metric === "min") {
			return Math.min(...data.map(Number));
		} else {
			return data[0];
		}
}

export function updateBarAndPieChartData(page, item) {
	let chartData = page.chartData;

	var backgroundColor = [];
	var hoverBackgroundColor = [];

	if (chartData.selectedDataModel && chartData.selectedDataElement) {
		if (chartData.groupByXAxis) {
			page.chartData.data.labels = item.data.labels;
			page.chartData.data.datasets = item.data.datasets;

		} else {
			page.chartData.data.labels = item.data.labels;
			page.chartData.data.datasets[0].data = item.data.data;
			page.chartData.data.datasets[0].label = item.data.label;
			item.data.data.map((i) => {
				backgroundColor.push(dynamicColors());
				hoverBackgroundColor.push(dynamicColors());
			});
			page.chartData.data.datasets[0].backgroundColor = item.data.backgroundColor ?
				item.data.backgroundColor : backgroundColor;
			page.chartData.data.datasets[0].hoverBackgroundColor = item.data.hoverBackgroundColor ?
				item.data.hoverBackgroundColor : hoverBackgroundColor;
		}
	} else {
		page.chartData.data.labels = item.data.labels;
		page.chartData.data.datasets[0].data = item.data.datasets[0].data;
		page.chartData.data.datasets[0].label = item.data.datasets[0].label;
	}
}

export function updateLineChartData(page, item) {
	let labels = [], d = [], label = '';
	let chartData = page.chartData;
	var backgroundColor = [];
	var hoverBackgroundColor = [];

	if (chartData.selectedDataModel && chartData.selectedDataElement) {
		page.chartData.data.labels = item.data.labels;
		page.chartData.data.datasets = item.data.datasets.map((ds, i) => {
			if (!page.chartData.data.datasets)
				page.chartData.data.datasets = []
			if (!page.chartData.data.datasets[i])
				page.chartData.data.datasets[i] = {};

			return {
				...page.chartData.data.datasets[i], ...ds,
				borderColor: dynamicColors()
			}
		})
	} else {
		page.chartData.data.labels = item.data.labels;
		page.chartData.data.datasets = item.data.datasets;
	}
}

export function updateCardData(page, item) {
	let labels = [], d = [], label = '';
	let chartData = page.chartData;
	if (chartData.selectedDataModel && chartData.selectedDataElement) {
		if (chartData.isCardCategories
			&& chartData.categories && chartData.categories.length) {
			page.chartData.categoriesData = item.data;
		}
		if (chartData.selectedGroupingDataElement.length > 0
			|| chartData.selectedGroupingDataElement.value) {
			page.chartData.groupingData = item.data.data;
		} else {
			page.chartData.body = item.data[chartData.metric];
		}
	} else if (page.chartData.dataUrl) {
		page.chartData.data = item.data.data;
		if (page.chartData.data) {
			page.chartData.body = generateCardBody(page.chartData.metric, page.chartData.data);
		}
		page.chartData.title = item.data.title;
	}
}

export function updateSimpleListData(page, item) {
	let labels = [], d = [], label = '';
	let chartData = page.chartData;
	if (chartData.selectedDataModel && chartData.selectedDataElement) {
		item.data.map((o) => {
			d.push(o[chartData.selectedDataElement[0].value])
		})
		page.chartData.title = chartData.selectedDataElement[0].label;
		page.chartData.data = d;
	} else if (page.chartData.dataUrl) {
		page.chartData.data = item.data.data;
		page.chartData.title = item.data.title;
	}
}

export function updateMixedChartData(page, item) {
	let labels = [], d = [], label = '';
	let chartData = page.chartData;
	if (chartData.selectedDataModel && chartData.selectedDataElement) {
		page.chartData.data.labels = item.data.labels;
		page.chartData.data.datasets = item.data.datasets;
	}
}

export function updateDoughnutChartData(page, item) {
	updateBarAndPieChartData(page, item);
}

export function updatePolarChartData(page, item) {
	updateBarAndPieChartData(page, item);
}

export function updateRadarChartData(page, item) {
	updateLineChartData(page, item);
}

export function updateChartData(data, response) {
	if (data.chartType === Actions.barChart || data.chartType === "bar") {
		updateBarAndPieChartData(data, response);
	} else if (data.chartType === Actions.pieChart || data.chartType === "pie") {
		updateBarAndPieChartData(data, response);
	} else if (data.chartType === Actions.mixedChart || data.chartType === "mixed") {
		updateMixedChartData(data, response);
	} else if (data.chartType === Actions.doughnutChart || data.chartType === "doughnut") {
		updateDoughnutChartData(data, response);
	} else if (data.chartType === Actions.radarChart || data.chartType === "radar") {
		updateRadarChartData(data, response);
	} else if (data.chartType === Actions.polarChart || data.chartType === "polar") {
		updatePolarChartData(data, response);
	} else if (data.chartType === Actions.listChart || data.chartType === "list") {
		data.chartData.data = response.data;
	} else if (data.chartType === Actions.simpleTable || data.chartType === "simpletable") {
		data.chartData.data = response.data;
	} else if (data.chartType === Actions.lineChart || data.chartType === "line") {
		updateLineChartData(data, response);
	} else if (data.chartType === Actions.card || data.chartType === "card") {
		updateCardData(data, response);
	} else if (data.chartType === Actions.html || data.chartType === "html") {
	} else if (data.chartType === Actions.simpleList || data.chartType === "simplelist") {
		updateSimpleListData(data, response);
	} else if (data.chartType === Actions.links || data.chartType === "links") {
		// Need to implement API support
		// updateSimpleListData(data, response);
	}
}

export function generateDataElements(chartData, dataModels, chartType) {
	let dataElements;
	dataModels.map((dataModel) => {
		if (dataModel._id === chartData.selectedDataModel) {
			dataElements = dataModel.dataElements.map(item => ({
				value: item.name,
				label: item.description,
				type: item.dataElementType,
			}))
		}
	});
	return dataElements
}

export const rows = [
	{
		id: 'name',
		align: 'left',
		disablePadding: false,
		label: 'Name',
		sort: true
	},
	{
		id: 'description',
		align: 'left',
		disablePadding: false,
		label: 'Description',
		sort: true
	}
];
