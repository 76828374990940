const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
import { request } from 'modules/client'

export class datasourceTypesService {
	
	static getDatasourceTypes() {
		const uri = API_BASE_URL + "/datasourcetypes"
		return request(uri, {
			method: 'GET'
		})
	}

	static getDatasourceType(params) {
		const uri = API_BASE_URL + "/member/" + params.memberId
		return request(uri, {
			method: 'GET',
			headers: { 'appId': params.dappId }
		})
	}

	static saveDatasourceType(data) {
		const uri = API_BASE_URL + "/member"
		return request(uri, {
			method: 'POST',
			headers: { 'appId': data.appId },
			payload: data
		})
	}

	static updateDatasourceType(data) {
		const uri = API_BASE_URL + "/member/" + data._id
		return request(uri, {
			method: 'PATCH',
			headers: { 'appId': data.appId },
			payload: data
		})
	}

	static deleteDatasourceType(data) {
		const uri = API_BASE_URL + "/member/" + data._id
		return request(uri, {
			method: 'DELETE',
			headers: { 'appId': data.appId },
		})
	}
}