import React, { Component } from 'react';
import {
	TextField, FormControl, Select, Grid, Typography,
	MenuItem, FormGroup, FormLabel, Button
} from '@material-ui/core';
import 'styles/page-designer.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import _ from 'modules/lodash';
import { withRouter } from 'react-router-dom';

const dataTypeNumList = [
	{ "value": "is_empty", "name": "is empty" },
	{ "value": "is_not_empty", "name": "is not empty" },
	{ "value": "is_equal_to", "name": "is equal to" },
	{ "value": "is_not_equal_to", "name": "is not equal to" },
	{ "value": "is_greater_than", "name": "is greater than" },
	{ "value": "is_less_than", "name": "is less than" },
	{ "value": "in_between", "name": "in between" }]

const dataTypeStringList = [
	{ "value": "contains", "name": "contains" },
	{ "value": "starts_with", "name": "starts with" },
	{ "value": "ends_with", "name": "ends with" },
	{ "value": "matches", "name": "matches" },
	{ "value": "not_matches", "name": "doesn't match" },
	{ "value": "includes", "name": "includes" },
	{ "value": "is_empty", "name": "is empty" },
	{ "value": "is_not_empty", "name": "is not empty" },
	{ "value": "is_equal_to", "name": "is equal to" },
	{ "value": "is_not_equal_to", "name": "is not equal to" },
	{ "value": "is_greater_than", "name": "is greater than" },
	{ "value": "is_less_than", "name": "is less than" },
	{ "value": "in_between", "name": "in between" }]

const Categories = (props) => {
	let { classes, values, selectedComparatorList, dataModels } = props;

	console.log("value.dataElementss", values.dataElements);
	console.log("values.category", values.category);


	return (<>
		<FormGroup row className="items-center">
			<FormLabel component="legend">
				Categories
            </FormLabel>
		</FormGroup>

		<FormGroup row className="items-center  max-w-full">

			<Grid container>
				<Grid xs={4} style={{ "padding": "5px" }}>
					<Typography className="text-16 sm:text-20 truncate">Category data element</Typography>
					<FormControl fullWidth variant="outlined" className={classes.formControl}>
						<Select
							value={values.category.selectedCategoryDataElement}
							onChange={props.onCategoryChangeHandler}
							inputProps={{
								name: 'selectedCategoryDataElement',
								id: 'selectedCategoryDataElement',
							}}>
							<MenuItem value="">
								<em>Select a column</em>
							</MenuItem>
							{values.dataElements.map((item, i) => {
								return <MenuItem key={i} value={item.value} >{item.label}</MenuItem>
							})}
						</Select>
					</FormControl>
				</Grid>
				<Grid xs={4} style={{ "padding": "5px" }}>
					<FormControl fullWidth variant="outlined" className={classes.formControl}>
						<TextField
							label="Enter Title"
							autoFocus
							id="title"
							name="title"
							value={values.category.title}
							onChange={props.onCategoryChangeHandler}
							variant="outlined"
							fullWidth
						/>
					</FormControl>
				</Grid>
				<Grid xs={4} style={{ "padding": "5px" }}>
					<Typography className="text-16 sm:text-20 truncate">Metric</Typography>
					<FormControl fullWidth className="mt-8 mb-16">
						<Select
							value={values.category.metric}
							onChange={props.onCategoryChangeHandler}
							inputProps={{
								name: 'metric',
								id: 'metric',
							}}>
							<MenuItem value={""}>
								{"Please select a Metric"}
							</MenuItem>
							<MenuItem value={"count"}>
								{"Count"}
							</MenuItem>
							<MenuItem value={"total"}>
								{"Total"}
							</MenuItem>
							<MenuItem value={"average"}>
								{"Average"}
							</MenuItem>
							<MenuItem value={"max"}>
								{"Max"}
							</MenuItem>
							<MenuItem value={"min"}>
								{"Min"}
							</MenuItem>
							<MenuItem value={"percentage"}>
								{"Percentage"}
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			<Typography className="text-16 sm:text-20 truncate">
				Condition
       		</Typography>
			<Grid container>
				<Grid xs={3} style={{ "padding": "5px" }}>
					<FormControl fullWidth variant="outlined" className={classes.formControl}>
						<Select
							value={values.category.selectedCategoryCondDataElement}
							onChange={props.onCategoryChangeHandler}
							inputProps={{
								name: 'selectedCategoryCondDataElement',
								id: 'selectedCategoryCondDataElement',
							}}>
							<MenuItem value="">
								<em>Select a column</em>
							</MenuItem>
							{values.dataElements.map((item, i) => {
								return <MenuItem key={i} value={item.value} >{item.label}</MenuItem>
							})}
						</Select>
					</FormControl>
				</Grid>
				<Grid xs={3} style={{ "padding": "5px" }}>
					<FormControl fullWidth variant="outlined" className={classes.formControl}>
						<Select
							value={values.category.selectedCategoryOperator}
							onChange={props.onCategoryChangeHandler}
							inputProps={{
								name: 'selectedCategoryOperator',
								id: 'selectedCategoryOperator',
							}}>
							<MenuItem value="">
								<em>Select Operator</em>
							</MenuItem>
							{values.category.selectedCategoryDataElement !== "" &&
								(values.category.selectedCategoryDataElement === "Integer"
									? dataTypeNumList
									: dataTypeStringList).map((element, i) => {
										return <MenuItem key={i} value={element.value}>{element.name}</MenuItem>
									})}
						</Select>
					</FormControl>
				</Grid>
				<Grid xs={3} style={{ "padding": "5px" }}>
					<TextField
						label="Enter Value"
						autoFocus
						id="value"
						name="value"
						value={values.category.value}
						onChange={props.onCategoryChangeHandler}
						variant="outlined"
						fullWidth
					/>
				</Grid>
				<Grid xs={1} style={{ "padding": "5px" }}>
				</Grid>
				<Grid xs={2} style={{ "padding": "5px 20px" }}>
					<Button
						color="secondary"
						variant="outlined"
						className={classes.formControl}
						onClick={() => {
							props.addCategory(values.category);
						}}
						fullWidth
					// disabled={!this.canBeSubmitted()}
					>
						Add
            </Button>
				</Grid>
			</Grid>
		</FormGroup>
	</>)
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}

function mapStateToProps({ dataModels }) {
	return {
		dataModels: dataModels,
	}
}

export default withRouter(
		connect(mapStateToProps, mapDispatchToProps)(Categories)
	)