import { store, persistor } from './createStore'

const injectReducer = (key, reducer) => {
	if (store.asyncReducers[key]) {
		return
	}
	store.asyncReducers[key] = reducer
	store.replaceReducer(createReducer(store.asyncReducers))
	return store
}

export { store, persistor, injectReducer }