import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Polar } from 'react-chartjs-2';
import 'styles/page-designer.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'

const styles = theme => ({});

class PageDesignerPolarChart extends Component {
	render() {
		let { data, width, height, error } = this.props.element.chartData;

		if (error) {
				return <div className="errorDisplayCenter">{error}</div>
		} else {
				return( <div
					id={`PolarChartContainer${this.props.element.chartUniqueId}`}
					style={{ height: 'inherit', width: 'inherit' }}
				>
					<Polar
						id={`PolarChartElement${this.props.element.chartUniqueId}`}
						className="text"
						width={width}
						height={height}
						data={data}
						options={{
							maintainAspectRatio: false
						}}
					/>
				</div>)
		}
	}
}

function collect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource()
	};
}

const cardSource = {
	beginDrag(props) {
		const item = { id: props.element.chartUniqueId };
		return item;
	}
};

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(null, null)(PageDesignerPolarChart)
	)
);
