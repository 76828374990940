import React from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, dapp, role, privileges, pages, forms, dataModels }) => {
		console.log("pages, forms", pages.data, forms.data);
		return {
			user: user,
			navigation: navigation,
			roleSuccess: role.roleSuccess,
			role: role,
			privileges: privileges.data,
			pages: pages.data,
			forms: forms.data,
			dataModels: dataModels,
			dapp,
		}
	}

	const mapDispatchToProps = {
		getPages: Actions.getPages,
		getEntryForms: Actions.getEntryForms,
		getDataModels: Actions.getDataModels,
		getRole: Actions.getRole,
		saveRole: Actions.saveRole,
		newRole: Actions.newRole,
		updateRole: Actions.updateRole,
		getPrivileges: Actions.getPrivileges,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert,
		getDapp: Actions.getDapp,
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux