import React, { useState, useEffect } from 'react'
import {
	Button, FormControl, FormHelperText, TextField, FormLabel, Dialog, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core'

const CopyFormDialog = (props) => {
	const [name, setName] = useState()
	const [err, setErr] = useState(false)

	useEffect(() => {
		setErr(false)
	}, [])
	
	return (<Dialog
		maxWidth={'lg'}
		fullWidth
		open={props.open}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
	>
		<DialogTitle id="alert-dialog-title">{props.msg}</DialogTitle>
		<DialogContent>
			<form className="form dataModelList" noValidate autoComplete="off" >
				<FormControl>
					<FormLabel variant="h6" className="label-headings">
						{"Enter name for new form *"}
					</FormLabel>
					<TextField
						className="mt-8 mb-16"
						aria-describedby="name-error-text"
						error={err && (!name || name.length === 0)}
						required
						placeholder="Name"
						autoFocus
						id="name"
						name="name"
						value={name}
						onChange={(e) => setName(e.target.value)}
						variant="outlined"
						color="secondary"
						fullWidth
					/>
					{err && (!name || name.length === 0)
						&& <FormHelperText id="name-error-text" style={{ color: "red" }}>
							{"Invalid form name"}
						</FormHelperText>}
				</FormControl>
			</form>
		</DialogContent>
		<DialogActions>
			<Button color="primary" onClick={() => !name || name.length === 0 ? setErr(true) : props.createCopy(name)}>
				{"OK"}
			</Button>
			<Button color="primary" onClick={() => props.close(false)}>
				{"Cancel"}
			</Button>
		</DialogActions>
	</Dialog >)
}

export default CopyFormDialog