import axios from 'axios';
import jwtDecode from 'jwt-decode';
import EventEmitter from './EventEmitter';
import { apiBaseAddress } from 'services/index';

class jwtService extends EventEmitter {

	init() {
		this.setInterceptors()
		this.handleAuthentication()
	}

	setInterceptors = () => {
		axios.interceptors.response.use(response => {
			return response
		}, err => {
			return new Promise((resolve, reject) => {
				if (err.response?.status === 401 && err.config && !err.config.__isRetryRequest) {
					// if you ever get an unauthorized response, logout the user
					this.emit('onAutoLogout', 'Invalid access_token')
					this.setSession(null)
				}
				throw err
			})
		})
	}

	handleAuthentication = () => {

		let access_token = this.getAccessToken()

		if (!access_token) {
			return
		}

		if (this.isAuthTokenValid(access_token)) {
			this.setSession(access_token)
			this.emit('onAutoLogin', true)
		}
		else {
			this.setSession(null)
			this.emit('onAutoLogout', 'access_token expired')
		}
	}

	createUser = (data) => {
		return new Promise((resolve, reject) => {
			axios.post(apiBaseAddress() + '/api/auth/register', data)
				.then(response => {
					if (response.data.user) {
						this.setSession(response.data.access_token);
						resolve(response.data.user)
					}
					else {
						reject(response.data.error)
					}
				})
		})
	}

	signInWithEmailAndPassword = ({ email, password }) => {
		return new Promise((resolve, reject) => {
			axios.post(apiBaseAddress() + '/auth/login', {
				email,
				password
			}).then(response => {
				const { user, token } = response.data
				if (user && token) {
					user.token = token
					user.isAuthenticated = true
					this.setSession(token)
					resolve(user)
				}
				else {
					reject(response.data.error)
				}
			}).catch(err => {
				console.error("err ==> ", err);
				reject(err.response ? err.response.data : err.response);
			})
		})
	}

	resetPassword = (data) => {
		return new Promise((resolve, reject) => {
			axios.post(apiBaseAddress() + `/auth/reset/${data.token}`, {
				...data
			}).then(response => {
				const user = response.data
				if (user) {
					resolve(user)
				}
				else {
					reject(response.data.error)
				}
			})
		})
	}

	isUserExist = (data) => {
        return new Promise((resolve, reject) => {
            const uri = apiBaseAddress() + '/auth/email.org.exist';
            axios.post(uri, data).then(response => {
                resolve(response.data)
            }).catch(err => {
                console.error("err ==> ", err);
                reject(err.response ? err.response.data : err.response);
            });
        });
    }

	signInWithToken = () => {
		return new Promise((resolve, reject) => {
			let accessToken = this.getAccessToken()
			if (this.isAuthTokenValid(accessToken)) {
				this.setSession(accessToken)
				const decoded = jwtDecode(accessToken)
				let user = {
					isAuthenticated: true,
					"id": decoded.id,
					"displayName": decoded.displayName,
					"role": decoded.role,
					"roles": decoded.roles,
					"orgId": decoded.orgId,
					"orgName": decoded.orgName,
					"email": decoded.email
				};
				resolve(user)
			} else {
				reject("error")
			}

		})
		/* return new Promise((resolve, reject) => {
			axios.get(apiBaseAddress() + '/api/auth/access-token', {
				data: {
					access_token: this.getAccessToken()
				}
			})
				.then(response => {
					if ( response.data.user )
					{
						this.setSession(response.data.access_token)
						resolve(response.data.user)
					}
					else
					{
						reject(response.data.error)
					}
				})
		}) */
	}

	updateUserData = (user) => {
		return axios.post(apiBaseAddress() + '/api/auth/user/update', {
			user: user
		});
	};

	setSession = access_token => {
		if (access_token) {
			localStorage.setItem('jwt_access_token', access_token)
			axios.defaults.headers.common['Authorization'] = access_token
		}
		else {
			localStorage.removeItem('jwt_access_token')
			delete axios.defaults.headers.common['Authorization']
		}
	}

	logout = () => {
		this.setSession(null)
	}

	isAuthTokenValid = access_token => {
		if (!access_token) {
			return false
		}
		const decoded = jwtDecode(access_token)
		const currentTime = Date.now() / 1000
		if (decoded.exp < currentTime) {
			console.warn('access token expired')
			return false
		}
		else {
			return true
		}
	};

	getAccessToken = () => {
		return window.localStorage.getItem('jwt_access_token')
	}
}

const instance = new jwtService()

export default instance
