import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { FormService } from 'services/index'
import API from 'services/apiServices'
import { ActionTypes } from 'constants/index'

const api = API.apiServices()

async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func()
    return response
}

function* getEntryForms(action) {
    try {
        const forms = yield call(fetchAsync, FormService.getEntryForms, action.payload)
        yield put({ type: ActionTypes.GET_ENTRYFORMS_SUCCESS, payload: forms })
    } catch (e) {
        yield put({ type: ActionTypes.GET_ENTRYFORMS_FAILURE, error: e.message })
    }
}

function* getEntryForm(action) {
    try {
        console.log("action.payload", action.payload)
        const form = yield call(fetchAsync, FormService.getEntryForm, action.payload)
        yield put({ type: ActionTypes.GET_ENTRYFORM_SUCCESS, payload: form })
        yield put({ type: ActionTypes.EDIT_FORM, payload: form })
    } catch (e) {
        yield put({ type: ActionTypes.GET_ENTRYFORM_FAILURE, error: e.message })
    }
}

function* saveEntryForm(action) {
    try {
        const entryForm = yield call(fetchAsync, FormService.saveEntryForm, action.payload)
        yield put({ type: ActionTypes.SAVE_ENTRYFORM_SUCCESS, payload: entryForm })
    } catch (e) {
        yield put({ type: ActionTypes.SAVE_ENTRYFORM_FAILURE, error: e.message })
    }
}

function* updateEntryForm(action) {
    try {
        console.log("updateEntryForm == action.payload ==> ", action.payload);
        const entryForm = yield call(fetchAsync, FormService.updateEntryForm, action.payload)
        console.log("updateEntryForm == entryForm == ", entryForm);
        yield put({ type: ActionTypes.UPDATE_ENTRYFORM_SUCCESS, payload: entryForm })
    } catch (e) {
        yield put({ type: ActionTypes.UPDATE_ENTRYFORM_FAILURE, error: e.message })
    }
}

function* deleteEntryForm(action) {
    try {
        console.log("action.payload ===> ", action.payload);
        const form = yield call(fetchAsync, FormService.deleteEntryForm, action.payload)
        yield put({ type: ActionTypes.DELETE_ENTRYFORM_SUCCESS, payload: form })
    } catch (e) {
        yield put({ type: ActionTypes.DELETE_ENTRYFORM_FAILURE, error: e.message })
    }
}

function* getTemplates(action) {
    try {
        const templates = yield call(fetchAsync, FormService.getTemplates, action.payload.appId)
        yield put({ type: ActionTypes.FORM_TEMPLATES_SUCCESS, payload: templates })
    } catch (e) {
        yield put({ type: ActionTypes.FORM_TEMPLATES_FAILURE, error: e.message })
    }
}

export function* watchEntryFormsSaga() {
    yield takeEvery(ActionTypes.GET_ENTRYFORMS, getEntryForms);
    yield takeEvery(ActionTypes.GET_ENTRYFORM, getEntryForm)
    yield takeEvery(ActionTypes.SAVE_ENTRYFORM, saveEntryForm)
    yield takeEvery(ActionTypes.UPDATE_ENTRYFORM, updateEntryForm)
    yield takeEvery(ActionTypes.DELETE_ENTRYFORM, deleteEntryForm)
    yield takeEvery(ActionTypes.FORM_TEMPLATES, getTemplates)
}

export default watchEntryFormsSaga;
