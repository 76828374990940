import React, { Component } from 'react';
import {
	TextField, FormControl, Select, Typography,
	MenuItem, FormGroup, FormLabel, Button
} from '@material-ui/core';
import 'styles/page-designer.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import _ from 'modules/lodash';
import { withRouter } from 'react-router-dom';
import ChipSelect from "components/@custom/ChipSelect"

const BarChartConfig = (props) => {
	let { classes, values } = props;
	let dataModels = props.dataModels.data;

	return (<><FormControl>
		<FormLabel component="legend">{"Select column for X-Axis"}</FormLabel>
		<ChipSelect
			className="w-full my-16"
			value={values.barXAxis}
			onChange={(v) => props.handleChipDataChange(v, 'barXAxis')}
			placeholder="Select column for X-Axis"
			textFieldProps={{
				InputLabelProps: {
					shrink: true
				},
				variant: 'standard'
			}}
			options={values.dataElements}
		/></FormControl>
		<FormControl>
			<FormLabel component="legend">{"Select column for Y-Axis"}</FormLabel>

			<ChipSelect
				className="w-full my-16"
				value={values.barYAxis}
				onChange={(v) => props.handleChipDataChange(v, 'barYAxis')}
				placeholder="Select column for Y-Axis"
				textFieldProps={{
					InputLabelProps: {
						shrink: true
					},
					variant: 'standard'
				}}
				options={values.dataElements}
			/>
		</FormControl>
	</>)
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}

function mapStateToProps({ dataModels, pages, entryForms }) {
	return {
		dataModels: dataModels,
		pages: pages.data,
		// entryForms: entryForms.data,
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BarChartConfig)
)