import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './view'
import Redux from './redux'
import { withRouter } from 'react-router-dom'
import { validateEmail } from '../../modules/common'

const Container = (props) => {

	const canSubmit = ({ email, password }) => {
		if (validateEmail(email)) {
			return !email || !password
		} else {
			return true;
		}
	}

	const handleSubmit = (e, credentials) => {
		e.preventDefault()
		credentials.email && credentials.password && props.submitLogin(credentials)
	}

	const loginHandler = () => {
		if (props.login.error && (props.login.error.email || props.login.error.password)) {
			// disableButton()
		}

		if (props.login && props.login.success) {
			// props.showAlert('Hello! And welcome!', { id: "login", variant: 'success', icon: 'bell' });
			if (props.navigation.prevPath) {
				props.history.push({
					pathname: props.navigation.prevPath,
					search: props.navigation.prevSearch
				});
				props.emptyPrevPath()
			} else {
				// props.hideAlert('login')
				props.history.push({
					pathname: '/builder/dapps'
				})
			}
		}
		return null
	}


	useEffect(() => {
		if (props.login && props.login.isAuthenticated) {
			props.history.push({
				pathname: '/builder/dapps'
			});
		}
	}, [props.login]);

	useEffect(() => {
		loginHandler()
	}, [props.login])

	return (
		<View
			canSubmit={canSubmit}
			handleSubmit={handleSubmit}
			{...props}
		/>
	)
}

export default hot(withRouter(Redux(Container)))