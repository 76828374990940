export default [
    'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/*',
	'application/x-csv',
	'application/csv',
	'text/x-csv',
	'text/csv',
	'.csv',
	'text/x-comma-separated-values',
	'text/comma-separated-values',
	'text/tab-separated-values',
	'text/plain',
	'.xslx',
	'',
	'application/octet-stream'
]