import { ActionTypes } from 'constants/index'

const initialState = {
	dapps: [],
	searchText: '',
	loading: false,
	error: '',
	categories: [],
	appsLoading: false,
};

const dappsReducer = function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_DAPPS:
			{
				return {
					...state,
					appsLoading: true,
				};
			}
		case ActionTypes.GET_DAPPS_SUCCESS:
			{
				return {
					...state,
					appsLoading: false,
					dapps: action.payload
				};
			}
		case ActionTypes.GET_DAPPS_FAILURE: {
			return {
				...state,
				appsLoading: false,
				error: action.error
			};
		}
		case ActionTypes.DELETE_DAPPS_SUCCESS:
			{
				return {
					...state,
					loading: false,
					dapps: [...state.dapps.filter(dapp => dapp._id !== action.payload)]
				};
			}
		case ActionTypes.DELETE_DAPPS_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error
			};
		}
		case ActionTypes.GET_CATEGORIES_SUCCESS:
			{
				return {
					...state,
					loading: false,
					categories: action.payload
				};
			}
		case ActionTypes.GET_CATEGORIES_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error
			};
		}
		case ActionTypes.SET_DAPPS_SEARCH_TEXT:
			{
				return {
					...state,
					searchText: action.searchText
				};
			}
		case ActionTypes.SET_DAPPS_CATEGORY_FILTER:
			{
				return {
					...state,
					categoryFilter: action.category
				};
			}
		default:
			{
				return state;
			}
	}
};

export default dappsReducer;
