import React from 'react'
import {
	Button, Checkbox, FormControl, FormControlLabel, FormLabel, NativeSelect, Radio,
	RadioGroup, Tab, Tabs, TextField, Typography, withStyles, Select, MenuItem, Input,
	AppBar, Dialog, DialogActions, DialogTitle, Grid
} from '@material-ui/core'
import Conditions from './Conditions'
import ConditionRequired from './ConditionRequired'
import Headers from './Headers'

const Validation = (props) => {
	const { classes, elementType, elementsData, textField, pushCondition } = props
	return (<>
		{(elementType !== 'button' && elementType !== 'HTML' && elementType !== 'header'
			&& elementType !== 'label' && elementType !== 'tab' && elementType !== 'multiLine'
			&& elementType !== 'multiStepForm') && <div >
				<Grid container spacing={8} className="formPmodel">

					<Grid item xs={4}>
						<label>Required</label>
					</Grid>
					<Grid item xs={8}>
						<Checkbox checked={elementsData.validation.required}
							onClick={(e) => { pushCondition(e) }}
						/>
						<label>Required</label>
					</Grid>
					{(textField) && <>
						{(elementType == 'textBox' || elementType == 'tel')
							&& <Grid container spacing={8}>
								<Grid item xs={4}>
									<label className="allowspace" >Allowed pattern:</label>
								</Grid>
								<Grid item xs={4}>
									<TextField
										// label="Allowed pattern"
										name="allowed"
										margin="dense"
										variant="filled"
										onChange={(e) => { pushCondition(e) }}
									/>
								</Grid>
							</Grid>}
						<Grid xs={12}>
							<FormLabel variant="h6" className="label-headings textblack" >
								{"Add Validations"}
							</FormLabel>
							{/* <Typography className="allowspace" variant={'h6'}>Add Validations</Typography> */}
						</Grid>
						<Grid item xs={8}>
							<FormControl>
								<NativeSelect fullWidth className={classes.selectCondition}
									onChange={props.changeCondition}
									inputProps={{
										name: 'age',
										id: 'age-native-helper',
									}}
								>
									<option value="" selected disabled>Select a validation</option>
									{elementType == 'number' || elementType == 'currency'
										|| elementType == 'date' ?
										(<>
											<option value="defaultValue" >Is equal to</option>
											<option value="min">Is greater than</option>
											<option value="max">Is less than</option>
										</>) : (
											<>
												<option value="minLength"  >Min Length</option>
												<option value="maxLength">Max Length</option>
											</>
										)}

								</NativeSelect>
							</FormControl>
						</Grid>
						<Grid item xs={2}>
							<TextField
								type={elementType == 'date' ? "date" : "number"}
								margin="dense"
								variant="filled"
								onChange={props.changeConditionValue}
								defaultValue="0" />
						</Grid>
						<Grid item xs={2}>
							<Button variant="contained" color="secondary"
								onClick={() => { pushCondition() }} className={classes.button}>
								{"+"}
							</Button>
						</Grid>
						<Grid container spacing={8}>
							<Grid item xs={12}>
								<FormLabel variant="h6" className="label-headings textblack" >
									{"Current validations"}
								</FormLabel>
								{/* <Typography className="allowspace" variant={'h6'}>Current validations:</Typography> */}
							</Grid>
							<Conditions className="allowspaceopt"
								elementsData={elementsData}
								removeCondition={props.removeCondition}
							/>
							{/* {this.generateConditions()} */}
						</Grid>
					</>}
				</Grid>

				<Grid xs={12}>
					<FormLabel variant="h6" className="label-headings textblack" >
						{"Conditional Required"}
					</FormLabel>
					{/* <Typography variant={'h6'}>Conditional Required</Typography> */}
				</Grid>
				<Grid container>
					<Grid item xs={4}>
						<NativeSelect
							onChange={props.changeSelectElement}
						>
							<option value="" selected disabled>Select Elements</option>
							{props.formElements && props.formElements.map((element) => {
								return <option value={element.item} >{element.item}</option>
							})}
						</NativeSelect>
					</Grid>
					<Grid item xs={4}>
						<NativeSelect
							onChange={props.changeOperator}>
							<option value="" selected disabled>Select Operator</option>
							<option value=">">{">"}</option>
							<option value="<">{"<"}</option>
							<option value="==">{"=="}</option>
							<option value="!=">{"!="}</option>
						</NativeSelect>
					</Grid>
					<Grid item xs={2}>
						<FormControl>
							<TextField margin="dense"
								variant="filled"
								onChange={props.changeConditionalRequiredValue}
								className={classes.textConditionalField}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={2}>
						<Button variant="contained" color="secondary"
							onClick={(e) => { props.pushConditionalRequired() }}
							className={classes.button}>
							{"+"}
						</Button>
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<FormLabel variant="h6" className="label-headings textblack" >
							{"Current Conditions Required"}
						</FormLabel>
						{/* <Typography className="allowspace" variant={'h6'}>Current Conditions Required :</Typography> */}
					</Grid>
					<ConditionRequired
						conditions={props.elementsData.conditionalRequired}
						variableName={"conditionalRequired"}
						removeConditionalRequired={props.removeConditionalRequired}
					/>
					{/* {this.generateConditionRequired(this.state.elementsData.conditionalRequired, "conditionalRequired")} */}
				</Grid>

			</div>}
		{elementType == "button" && <div >
			<Grid container spacing={8}>
				<Grid item xs={4}>
					<FormLabel>Action</FormLabel>
				</Grid>
				<Grid item xs={8}>
					<FormControl className="native-select-dropdown">
						<NativeSelect fullWidth
							value={props.elementsData.data.data.selectedFunction}
							onChange={props.changeSelectedFunction}
						>
							<option value="" selected disabled>{"Select an action"}</option>
							<option value="save" >Save</option>
							<option value="function" >Function</option>
							<option value="api" >Api</option>

						</NativeSelect>
					</FormControl>
				</Grid>
				{props.elementsData.data.data.selectedFunction == 'function' && <>
					<Grid item xs={4}>
						<FormLabel>Enter Function Name (required)</FormLabel>
					</Grid>
					<Grid item xs={8}>
						<TextField
							margin="dense"
							variant="filled"
							fullWidth
							value={props.elementsData.data.data.functionName}
							onChange={(e) => { props.setData("functionName", e) }}
						/>

					</Grid>
				</>}

				{props.elementsData.data.data.selectedFunction == 'api' && <>
					<Grid item xs={4}>
						<FormLabel>Enter URL (required)</FormLabel>
					</Grid>
					<Grid item xs={8}>
						<TextField
							margin="dense"
							variant="filled"
							fullWidth
							value={props.elementsData.data.data.url}
							onChange={(e) => { props.setData("url", e) }}
						/>

					</Grid>
					<>
						<Grid item xs={4}>
							<TextField
								label=""
								margin="dense"
								variant="filled"
								placeholder="key"
								fullWidth
								value={props.headerKey}
								onChange={props.setHeaderKey}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								label=""
								margin="dense"
								variant="filled"
								fullWidth

								placeholder="value"
								value={props.headerValue}
								onChange={props.setHeaderValue}
							/>
						</Grid>

						<Grid item xs={4}>

							<Button variant="contained" color="secondary"
								onClick={() => { props.pushValue("headers") }} className={classes.button}>
								{"+"}
							</Button>
						</Grid>
					</>

					<Grid container spacing={8}>
						<Grid item xs={12}>
							<Typography variant={'h6'}>Current Header Key/Value Pairs:</Typography>
						</Grid>
						<Headers
							removeKeyValuePair={props.removeKeyValuePair}
							elementsData={props.elementsData}
						/>
						{/* {this.generateHeaders()} */}
					</Grid>

				</>}
			</Grid>
		</div>}
	</>)
}

export default Validation