import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Actions from 'actions'


const NewAppRedux = (PassedComponent) => {
	const mapStateToProps = ({ entity, user, roles }) => {
		return {
			entity: entity,
			user: user,
			roles: roles,
		}
	}

	const mapDispatchToProps = {
		getEntity: Actions.getEntity,
		saveEntity: Actions.saveEntity,
		newEntity: Actions.newEntity,
		updateEntity: Actions.updateEntity,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default NewAppRedux;