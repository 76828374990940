import React from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, datasource, roles }) => {
		console.log("datasource", datasource);
		return {
			user: user,
			navigation: navigation,
			datasource: datasource,
			roles: roles
		}
	}

	const mapDispatchToProps = {
		getDatasource: Actions.getDatasource,
		saveDatasource: Actions.saveDatasource,
		newDatasource: Actions.newDatasource,
		updateDatasource: Actions.updateDatasource,
		testDatasource: Actions.testDatasource,
		testS3Connection: Actions.testS3Connection,
		testQLDBConnection: Actions.testQLDBConnection,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert,
		getRoles: Actions.getRoles,
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux