import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
	Button, Select, Input, MenuItem, Chip, AppBar, Tabs, Tab, Typography,
	Box, Divider, FormLabel, FormControl, Grid, FormControlLabel, Checkbox,
	Radio, RadioGroup
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import { TabPanel } from '../@tabpanel/tabpanel'
import DappDetails from './DappDetails'
import UI from './UI'
import Upload from './Upload'
import rightarrow from 'images/rightarrow.svg'

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	tabPanelStyle: {
		'& .MuiFormControl-root': {
			display: 'block',
			marginBottom: '20px'
		},
		'& .MuiFormGroup-root': {
			marginTop: '10px',
			flexDirection: 'row'
		}
	}
}))

let acceptedFiles = ['image/svg', 'image/gif', 'image/jpg', 'image/jpeg', 'image/png', '.svg']

const View = (props) => {
	const classes = useStyles()
	const [value, setValue] = React.useState(0)

	const { form, roles } = props

	const handleChange = (event, newValue) => setValue(newValue)

	return (<Box className="quickwizard-page" >
		<Grid container>
			<Grid item xs={12} md={6}>
				<div className="backbtn">
					<div className="backbtn ptl-20">
						<Link to={`/builder/dapps`}
							style={{ textDecoration: 'none', color: "#1B2A47" }}>
							<img src={rightarrow} alt="backicon" className="backicon" />
							<Box component="h3" className="companyheadingtext">
								<Typography variant="h4">{"HOME"}</Typography>
							</Box>
						</Link>
					</div>
				</div>
			</Grid>
			<Grid item xs={12} md={6} className="savebtn-sec">
				{props.match.params.dappId !== "new" && <Button
					variant="contained" color="primary" className="addnewentrybtn mb5"
					onClick={() => props.deleteDapp()}>
					Delete
				</Button>}
				<Button
					variant="contained" color="primary" className="addnewentrybtn mb5"
					disabled={!props.canBeSubmitted()}
					onClick={() => props.match.params.dappId === "new" ? props.saveDapp() : props.updateDapp()}>
					{props.match.params.dappId === "new" ? "Save" : "Update"}
				</Button>
			</Grid>
		</Grid>
		<Box className="pxy20">
			<div className={classes.root} >
				<Box px="1.5rem">
					<AppBar position="static" className="bg-white">
						<Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className="quicktabs-sec">
							<Tab label="Details" {...a11yProps(0)} />
							<Tab label="Theme" {...a11yProps(1)} />
							{/* <Tab label="Access Control" {...a11yProps(2)} /> */}
						</Tabs>
						<Divider />
					</AppBar>
					<TabPanel value={value} index={0}>
						<DappDetails {...props} classes={classes} />
					</TabPanel>
					<TabPanel value={value} index={1} className={classes.tabPanelStyle}>
						<FormControl>
							<FormControlLabel control={
								<Checkbox
									checked={form.poweredBy}
									onChange={props.handleChange}
									value="poweredBy"
									color="primary"
									id="poweredBy"
									name="poweredBy"
								/>}
								label="Display 'poweredBy' text with platform logo"
							/>
						</FormControl>
						<FormControl>
							<FormControlLabel control={
								<Checkbox
									checked={form.displayTitle}
									onChange={props.handleChange}
									value="displayTitle"
									color="primary"
									id="displayTitle"
									name="displayTitle"
								/>}
								label="Display application title in header"
							/>
						</FormControl>
						{/* <UI
							values={form}
							handleUIChange={props.handleUIChange}
							classes={classes}
						/> */}
						<FormControl component="div">
							<FormLabel component="legend">Navigation Bar Position </FormLabel>
							<RadioGroup name="navbarPosition" value={form.navbarPosition ?? ''} onChange={props.handleChange}>
								<FormControlLabel value="top" control={<Radio color='primary' />} label="Top" />
								<FormControlLabel value="left" control={<Radio color='primary' />} label="Left" />
							</RadioGroup>
						</FormControl>
						<Typography className="text-16 sm:text-20 truncate fw-600">{"Upload Logo"}</Typography><br />
						<Upload {...props} handleFileChange={props.handleFileChange} type="logo"
							acceptedFiles={acceptedFiles}
							fileId={form.logoId}
						/>
					</TabPanel>
					<TabPanel value={value} index={2}>
						<FormControl>
							<FormLabel variant="h6" className="label-headings">{"Access Control"}</FormLabel>
							<Select name="Roles" displayEmpty className="form-control" id="grouped-native-select" variant="outlined" multiple
								onChange={(e) => props.handleChipChange(e, 'selectedRoles')} value={form && form.selectedRoles ? form.selectedRoles : []} input={<Input />}
								renderValue={(selected) => (
									<div className={classes.chips}>
										{selected && selected.map((item) => (
											<Chip key={item.value} label={item.label} className={classes.chip} />
										))}
									</div>
								)}>
								{roles && roles.map((item, i) => (
									<MenuItem key={i} value={item}>
										{item.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</TabPanel>
				</Box>
			</div>
		</Box>
	</Box >)
}

export default withRouter(View)
