import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { withRouter } from 'react-router-dom'
import View from './View'
import Redux from './Redux'
import ConfirmDialog from '../confirmDialog'
import sharedJSON from 'packages/sharedJSON'
import { updateTableData, setDatafromApi } from 'packages/common'
import { requestSort } from 'modules/common';

const Container = (props) => {
	const [state, setState] = useState({
		...sharedJSON
	})
	const [open, setOpen] = React.useState(false);
	const [member, setMember] = React.useState({});

	const handleRequestSort = (event, property) => {
		let obj = requestSort(event, property, state);
		setState({ ...state, ...obj });
	};

	const onRowClick = (rowData, rowMeta) => {
		console.log('onRowClick');
	}

	const searchHandler = (e) => setState({ ...state, searchText: e ? e.target.value : "" });

	const handleSelectAllClick = event => {
		if (event.target.checked) {
			setState(state => ({ selected: state.data.map(n => n._id) }));
			return;
		}
		setState({ selected: [] });
	};

	const handleInputChange = (event) => {
		let obj = state.filterTableData;
		let list = obj.columns;
		list.map((item, index) => {
			if (item.name === event.target.value) list[index].checked = event.target.checked
		})
		obj.columns = [...list]
		setState({ ...state, filterTableData: obj });
		setState({ ...updateTableData(state) });
	}

	const handleCheck = (event, id) => {
		const { selected } = state;
		let newSelected = handleSelectedRow(event, id, selected)
		setState({ ...state, selected: newSelected });
	}

	const handleChangePage = (event, page) => setState({ ...state, page: page })

	const handleChangeRowsPerPage = event => setState({ ...state, rowsPerPage: event.target.value, page: 0 })

	const resetFilters = (event) => setState({ ...state, filterList: [] })

	const handleDropdownChange = (event, index, column) => {
		const value = event.target.value === "All" ? [] : [event.target.value];
		// filterUpdate(index, value, column, 'dropdown');
		let list = state.filterList;
		list[index] = event.target.value;
		setState({ ...state, filterList: [...list] });
		setState({ ...updateTableData(state) });
	};

	const tableCrudOperations = (item, type) => {
		if (type === "edit") {
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/members/${item._id}` });
		}
		if (type === "add") {
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/members/new` });
		}
		if (type === "delete") {
			setMember(item);
			setOpen(true);
		}
	}

	useEffect(() => {
		setState({ ...updateTableData(state) });
	}, [state.searchText, state.filterList]);

	useEffect(() => {
		if (props.members.deleteSuccess) {
			props.showAlert('Member deleted successfully', { id: "member", variant: 'success', icon: 'bell' });
		}
	}, [props.members.deleteSuccess])

	useEffect(() => {
		const params = props.match.params;
		const { dappId } = params;
		props.getMembers(dappId);
	}, []);

	useEffect(() => {
		const rows = [
			//{
			// 	id: 'firstName',
			// 	numeric: false,
			// 	disablePadding: false,
			// 	label: 'First Name',
			// 	sort: true
			// },
			// {
			// 	id: 'lastName',
			// 	numeric: false,
			// 	disablePadding: false,
			// 	label: 'Last Name',
			// 	sort: true
			// },
			{
				id: 'email',
				numeric: false,
				disablePadding: false,
				label: 'Email',
				sort: true
			}
		];
		let keysArr = ['_id', 'handle', 'updatedDate', 'appId', '__v', 'apps', 'createdBy', 'createdDate', 'entityId', 'selectedRoles', 'updatedBy', 'userId', 'company'];
		setState({ ...setDatafromApi(props.members.data, keysArr, state, rows) });
	}, [props.members.data]);

	return (<>
		<View
			{...props}
			state={state}
			handleChangePage={handleChangePage}
			handleChangeRowsPerPage={handleChangeRowsPerPage}
			onRowClick={onRowClick}
			searchHandler={searchHandler}
			handleCheck={handleCheck}
			handleInputChange={handleInputChange}
			resetFilters={resetFilters}
			handleDropdownChange={handleDropdownChange}
			handleRequestSort={handleRequestSort}
			handleSelectAllClick={handleSelectAllClick}
			tableCrudOperations={tableCrudOperations}
		/>
		<ConfirmDialog
			title="Delete"
			open={open}
			setOpen={setOpen}
			onConfirm={() => props.deleteMember({ _id: member._id, appId: props.match.params.dappId })}
		>
			member {member.name}
		</ConfirmDialog>
	</>)
}
export default hot(withRouter(Redux(Container)))