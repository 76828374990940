import React from 'react';

const BusinessTask = props => {
  return (
    <svg
      id="data_models"
      data-name="data models"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      x={props.x}
      y={props.y}
      onClick={props.onClick}
      viewBox="0 0 41 41"
    >
      <rect
        id="Rectangle_593"
        data-name="Rectangle 593"
        width="41"
        height="41"
        fill="#fff"
        opacity="0"
      />
      <g id="Group_307" data-name="Group 307" transform="translate(-53 -513.553)">
        <path
          id="Path_472"
          data-name="Path 472"
          d="M355.536,624.729a6.588,6.588,0,0,1-1.624.629,22.652,22.652,0,0,1-9.877,0,6.575,6.575,0,0,1-1.624-.629.489.489,0,0,0-.033.175.475.475,0,0,0,0,.05h0v3.169c0,.912,2.666,1.661,6.057,1.733v.979a.534.534,0,0,0-.09.023l-3.942,1.386a.539.539,0,1,0,.357,1.017l3.675-1.292v1.838a.539.539,0,0,0,1.078,0v-1.838l3.675,1.292a.539.539,0,0,0,.357-1.017l-3.942-1.386a.539.539,0,0,0-.091-.023v-.978c3.391-.072,6.057-.82,6.057-1.733v-3.169h0a.482.482,0,0,0,0-.05A.488.488,0,0,0,355.536,624.729Zm-6.563,3.644a.539.539,0,1,1,.539-.539A.539.539,0,0,1,348.973,628.373Z"
          transform="translate(-276.179 -85.806)"
          fill="#000"
        />
        <path
          id="Path_473"
          data-name="Path 473"
          d="M160.105,153.524a1.293,1.293,0,0,0-1.293-1.293h-5.287a1.293,1.293,0,0,0-1.293,1.293v.191h7.873Z"
          transform="translate(-94.232 366.322)"
          fill="#000"
        />
        <path
          id="Path_474"
          data-name="Path 474"
          d="M174.35,214.679a1.293,1.293,0,0,0,1.293,1.293h1.863v4.181a.755.755,0,0,1-.754.755h-4.225a1.835,1.835,0,0,0-1.833,1.833v.428a20.768,20.768,0,0,0-3.126-.287q-.266-.006-.539-.006t-.539.006a20.768,20.768,0,0,0-3.126.287v-.428a1.834,1.834,0,0,0-1.832-1.833h-4.225a.756.756,0,0,1-.755-.755v-4.181h2.263a1.293,1.293,0,0,0,1.293-1.293v-3.011h-7.873v3.011a1.293,1.293,0,0,0,1.293,1.293h1.946v4.181a1.835,1.835,0,0,0,1.833,1.833h4.225a.755.755,0,0,1,.754.755v.665c-1.148.313-1.853.739-1.853,1.208a.49.49,0,0,0,0,.05h0v3.168c0,.961,2.953,1.739,6.6,1.739s6.6-.779,6.6-1.739v-3.168h0a.486.486,0,0,0,0-.05c0-.469-.706-.9-1.854-1.209v-.665a.756.756,0,0,1,.755-.755h4.225a1.834,1.834,0,0,0,1.832-1.833v-4.181h2.345a1.293,1.293,0,0,0,1.293-1.293v-3.011H174.35Zm-19.678-.783a.538.538,0,0,1-.381.158h-.546a.539.539,0,1,1,0-1.078h.546a.539.539,0,0,1,.381.92Zm.7-.381a.539.539,0,0,1,.539-.539H158.6a.539.539,0,1,1,0,1.078h-2.687a.539.539,0,0,1-.539-.539ZM167.03,228.38a.539.539,0,1,1,.539-.539A.539.539,0,0,1,167.03,228.38Zm13.139-15.4h.546a.539.539,0,0,1,0,1.078h-.546a.539.539,0,1,1,0-1.078Zm-4.311,0h2.687a.539.539,0,0,1,.039,1.076l-.039,0h-2.687a.539.539,0,0,1,0-1.078Z"
          transform="translate(-94.237 309.446)"
          fill="#000"
        />
        <path
          id="Path_475"
          data-name="Path 475"
          d="M671.723,152.231h-5.287a1.293,1.293,0,0,0-1.293,1.293v.191h7.873v-.191A1.293,1.293,0,0,0,671.723,152.231Z"
          transform="translate(-585.03 366.322)"
          fill="#000"
        />
        <path
          id="Path_476"
          data-name="Path 476"
          d="M409.98,215.973h1.9V221.8q.268-.006.539-.006t.539.006v-5.832h2.3a1.294,1.294,0,0,0,1.293-1.293v-3.011h-7.874v3.011A1.293,1.293,0,0,0,409.98,215.973Zm.919-2.838a.537.537,0,0,1,.381-.158h2.687a.539.539,0,1,1,0,1.078H411.28a.539.539,0,0,1-.381-.92Z"
          transform="translate(-339.631 309.446)"
          fill="#000"
        />
        <path
          id="Path_477"
          data-name="Path 477"
          d="M416.561,153.524a1.293,1.293,0,0,0-1.293-1.293h-5.287a1.293,1.293,0,0,0-1.293,1.293v.191h7.873Z"
          transform="translate(-339.631 366.322)"
          fill="#000"
        />
      </g>
    </svg>
  );
};

export default BusinessTask;
