import React from 'react';
import { TextField, FormControl, FormLabel } from '@material-ui/core';
import ChipSelect from 'components/@custom/ChipSelect';

export default function GroupDetails(props) {
  console.log(props, 'heree');
  const { form, privileges } = props.state;
  const { classes, error, members, roles } = props;

  return (
    form && (
      <div className="p-16 sm:p-24 max-w-2xl">
        <form className="form" noValidate autoComplete="off">
          <FormControl>
            <FormLabel variant="h6" className="label-headings">
              {'Name *'}
            </FormLabel>
            <TextField
              className="form-control"
              error={form.name === ''}
              id="name"
              name="name"
              value={form.name ?? ''}
              onChange={props.handleChange}
              variant="outlined"
              color="secondary"
              placeholder="Enter Name"
              required
              fullWidth
            // helperText={error.name && error.name}
            />
          </FormControl>
          <FormControl>
            <FormLabel variant="h6" className="label-headings">
              {'Description *'}
            </FormLabel>
            <TextField
              className="form-control"
              id="description"
              name="description"
              value={form.description ?? ''}
              onChange={props.handleChange}
              variant="outlined"
              placeholder="Enter Description"
              fullWidth
            />
          </FormControl>
          <FormControl>
            <FormLabel component="legend">{'Select Members *'}</FormLabel>
            <ChipSelect
              className="w-full my-16"
              value={form.members?.map(member => ({
                value: member.userId,
                label: member.email || member.userName,
              })) ?? []}
              onChange={(value) => props.handleChipChange('members', value.map(item => { return { userId: item.value, email: item.label } }))}
              textFieldProps={{
                InputLabelProps: {
                  shrink: true,
                },
                variant: 'standard',
              }}
              options={members?.data.map(item => { return { value: item._id, label: item.email } }) ?? []}
              isMulti
            />
          </FormControl>
          <FormControl>
            <FormLabel component="legend">{'Select Roles'}</FormLabel>
            <ChipSelect
              className="w-full my-16"
              value={form.assignedRoles?.map(role => ({
                value: role.roleId,
                label: role.roleName,
              })) ?? []}
              onChange={(value) => props.handleChipChange('assignedRoles', value.map(item => { return { roleId: item.value, roleName: item.label } }))}
              textFieldProps={{
                InputLabelProps: {
                  shrink: true,
                },
                variant: 'standard',
              }}
              options={roles?.data.map(item => { return { value: item._id, label: item.name } }) ?? []}
              isMulti
            />
          </FormControl>
        </form>
      </div>
    )
  );
}
