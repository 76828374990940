import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { DatasourcesService } from 'services/index'
import { ActionTypes } from 'constants/index'


async function fetchAsync(func, arg) {
	const response = arg ? await func(arg) : await func()
	return response
}

function* getDatasources(action) {
	try {
		const datasources = yield call(fetchAsync, DatasourcesService.getDatasources, action.payload)
		yield put({ type: ActionTypes.GET_DATASOURCES_SUCCESS, payload: datasources })
	} catch (e) {
		yield put({ type: ActionTypes.GET_DATASOURCES_FAILURE, error: e.data })
	}
}

function* getDatasource(action) {
	try {
		const datasource = yield call(fetchAsync, DatasourcesService.getDatasource, action.payload)
		yield put({ type: ActionTypes.GET_DATASOURCE_SUCCESS, payload: datasource })
	} catch (e) {
		yield put({ type: ActionTypes.GET_DATASOURCE_FAILURE, error: e.data })
	}
}

function* getDatasourceByName(action) {
	try {
		const datasource = yield call(fetchAsync, DatasourcesService.getDatasourceByName, action.payload)
		yield put({ type: ActionTypes.GET_DATASOURCE_SUCCESS, payload: datasource })
	} catch (e) {
		yield put({ type: ActionTypes.GET_DATASOURCE_FAILURE, error: e.data })
	}
}

function* saveDatasource(action) {
	try {
		const datasource = yield call(fetchAsync, DatasourcesService.saveDatasource, action.payload)
		yield put({ type: ActionTypes.SAVE_DATASOURCE_SUCCESS, payload: datasource })
	} catch (e) {
		yield put({ type: ActionTypes.SAVE_DATASOURCE_FAILURE, error: e.data })
	}
}

function* updateDatasource(action) {
	try {
		const datasource = yield call(fetchAsync, DatasourcesService.updateDatasource, action.payload)
		yield put({ type: ActionTypes.UPDATE_DATASOURCE_SUCCESS, payload: datasource })
	} catch (e) {
		yield put({ type: ActionTypes.UPDATE_DATASOURCE_FAILURE, error: e.data })
	}
}

function* deleteDatasource(action) {
	try {
		const datasource = yield call(fetchAsync, DatasourcesService.deleteDatasource, action.payload)
		yield put({ type: ActionTypes.DELETE_DATASOURCE_SUCCESS, payload: datasource })
	} catch (e) {
		yield put({ type: ActionTypes.DELETE_DATASOURCE_FAILURE, error: e.data })
	}
}

function* testDatasource(action) {
	try {
		const conn = yield call(fetchAsync, DatasourcesService.testDatasource, action.payload)
		yield put({ type: ActionTypes.TEST_DATASOURCE_SUCCESS, payload: conn })
	} catch (e) {
		yield put({ type: ActionTypes.TEST_DATASOURCE_FAILURE, error: e.data })
	}
}

function* testQLDBConnection(action) {
	try {
		const conn = yield call(fetchAsync, DatasourcesService.testQLDBConnection, action.payload)
		yield put({ type: ActionTypes.TEST_QLDB_CONNECTION_SUCCESS, payload: conn })
	} catch (e) {
		yield put({ type: ActionTypes.TEST_QLDB_CONNECTION_FAILURE, error: e.data })
	}
}

function* testS3Connection(action) {
	try {
		const conn = yield call(fetchAsync, DatasourcesService.testS3Connection, action.payload)
		yield put({ type: ActionTypes.TEST_S3_CONNECTION_SUCCESS, payload: conn })
	} catch (e) {
		yield put({ type: ActionTypes.TEST_S3_CONNECTION_FAILURE, error: e.data })
	}
}

function* getDataElements(action) {
	try {
		const dataElements = yield call(fetchAsync, DatasourcesService.getDataElements, action.payload)
		yield put({ type: ActionTypes.GET_DATAELEMENTS_SUCCESS, payload: dataElements })
	} catch (e) {
		yield put({ type: ActionTypes.GET_DATAELEMENTS_FAILURE, error: e.data })
	}
}

export function* watchDatasourcesSaga() {
	yield takeEvery(ActionTypes.GET_DATASOURCES, getDatasources)
	yield takeEvery(ActionTypes.GET_DATASOURCE, getDatasource)
	yield takeEvery(ActionTypes.GET_DATASOURCE_BY_NAME, getDatasourceByName)
	yield takeEvery(ActionTypes.SAVE_DATASOURCE, saveDatasource)
	yield takeEvery(ActionTypes.UPDATE_DATASOURCE, updateDatasource)
	yield takeEvery(ActionTypes.DELETE_DATASOURCE, deleteDatasource)
	yield takeEvery(ActionTypes.TEST_DATASOURCE, testDatasource)
	yield takeEvery(ActionTypes.TEST_QLDB_CONNECTION, testQLDBConnection)
	yield takeEvery(ActionTypes.TEST_S3_CONNECTION, testS3Connection)
	yield takeEvery(ActionTypes.GET_DATAELEMENTS, getDataElements)
}

export default watchDatasourcesSaga