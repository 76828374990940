import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { PrivilegesService } from 'services/index'
import { ActionTypes } from 'constants/index';


async function fetchAsync(func, arg) {
	const response = arg ? await func(arg) : await func();
	return response;
}

function* getPrivileges(action) {
	try {
		const privileges = yield call(fetchAsync, PrivilegesService.getPrivileges, action.payload);
		yield put({ type: ActionTypes.GET_PRIVILEGES_SUCCESS, payload: privileges });
	} catch (e) {
		yield put({ type: ActionTypes.GET_PRIVILEGES_FAILURE, error: e.message });
	}
}

export function* watchPrivilegesSaga() {
	yield takeEvery(ActionTypes.GET_PRIVILEGES, getPrivileges);
}

export default watchPrivilegesSaga;