import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { FormControl } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import photoone from '../../images/photoone.svg';
import oracleimg from '../../images/oracleimg.svg';
import microsoftsqlserver from '../../images/microsoftsqlserver.svg';
import netsuitelogo from '../../images/netsuitelogo.png';
import addnewimg from '../../images/addnewimg.svg';
import rightarrow from '../../images/rightarrow.svg';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function SelectTableCollection(props) {
    const classes = useStyles();

    return (
        <Box className="pxy20">
            <Grid container>
                <Box className="processtab-sec">
                    <Grid item xs={12} className="selecteddata-grid">
                        <Typography variant="h5" className="mb3 d-block font-bold datrowdow">Select Data Source</Typography>
                        <Box className="selecteddatasource-head" component="div">
                            <img src={microsoftsqlserver} alt="sql server logo" className="serverlogo" />
                            <Typography component="div" className="data-block">
                                <Grid container className="mb2">
                                    <Grid item xs={12} md={2} >
                                        <Box component="label" className="connectionlabel">
                                            Connection Name:
						      </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3} >
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="email"
                                            name="email"
                                            className="drmstextfield"
                                            autoComplete="email"
                                            placeholder="DRMS Server Connection"
                                            autoFocus
                                        />
                                    </Grid>
                                </Grid>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="my5">

                        <Typography variant="h5" className="mb3 d-block font-bold">Select Table</Typography>
                        <Box className="enterpriseblock" component="div">
                            <Paper onClick={props.handleNext}>
                                <Box component="label">
                                    Order Table
	    			</Box>
                                <img src={photoone} alt="temp image" />
                            </Paper>
                            <Paper>
                                <Box component="label">
                                    Invoices Table
	    			</Box>
                                <img src={photoone} alt="temp image" />
                            </Paper>
                            <Paper>
                                <Box component="label">
                                    Vendor Table
	    			</Box>
                                <img src={photoone} alt="temp image" />
                            </Paper>
                            <Paper>
                                <Box component="label">
                                    Inventory Table
	    			</Box>
                                <img src={photoone} alt="temp image" />
                            </Paper>
                            <Paper>
                                <Box component="label">
                                    Spend Categories
	    			</Box>
                                <img src={photoone} alt="temp image" />
                            </Paper>
                            <Paper>
                                <Box component="label">
                                    Contracts
	    			</Box>
                                <img src={photoone} alt="temp image" />
                            </Paper>
                            <Paper>
                                <Box component="label">
                                    Logo
	    			</Box>
                                <img src={photoone} alt="temp image" />
                            </Paper>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    );
}
