import React, { Component } from 'react'
import axios from 'axios'
import {
	Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,
	withStyles
} from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as ExternalScripts from '../../custom/index'
import * as Actions from 'actions'
import { ApiResponseModal } from '../ApiResponseModal'
// import FormElements from '../FormElements'
import { getJson } from '../getJson'
import PropertiesModal from '../Properties/index';
import styles from './../styles/FormBuilder'
import Elements from './Elements'
import GenerateForm from './GenerateForm'
import Redux from '../../redux'

class FormBuilder extends Component {
	constructor(props) {
		super(props);
		let design = (this.props.selectedForm && this.props.selectedForm
			&& this.props.selectedForm.design)
			? this.props.selectedForm.design
			: { rows: [] }
		this.state = {
			design: design,
			dragging: false,
			elementsData: {},
			elementType: "",
			open: false,
			modalData: (this.props.selectedForm && this.props.selectedForm
				&& this.props.selectedForm.modalData)
				? this.props.selectedForm.modalData : { rows: [] },
			editElement: null,
			dataFilled: {},
			showApiResponse: false,
			apiResponse: {},
			saveMessage: null,
			emptyGridToHighlight: null,
			templateId: ""
		}
	}

	getDynamicDataFromFunction = (functionName) => {
		try {
			var dynamicOptions = ExternalScripts[functionName]();
			return dynamicOptions;
		}
		catch (exp) {
			alert("Function :" + functionName + "doesnt exist");
		}
	}

	setDynamicData = (item, options) => {

		if (item.type && item.type.includes("select")) {
			item.values = options ? options.map((el) => {
				return el.value;
			}) : [];
		}
		else {
			item.data.values = options ? options : [];
		}
		if (item.innerForms) {
			item.innerForms = item.data.values.map((value) => {
				return {
					innerForm: {
						"rows": []
					},
					innerFormModalData: {
						"rows": []
					},
					value: value.value
				}
			})
		}
		return item;
	}

	componentDidMount() {
		if (this.props.formState) {
			let formState = JSON.parse(JSON.stringify(this.props.formState));
			this.setState({ ...formState });
		}
		this.props.onRef(this)
		this.props.changeDesignAndModalData(this.state.design, this.state.modalData);
	}

	componentWillUnmount() {
		this.props.onRef(undefined)
	}

	static getDerivedStateFromProps(props, state) {
		let mergeState = { saveMessage: props.saveFormStatus.msg };
		if (props.selectedTemplate && props.selectedTemplate.design && state.templateId != props.selectedTemplate._id) {
			mergeState.design = props.selectedTemplate.design;
			mergeState.modalData = props.selectedTemplate.modalData;
			mergeState.templateId = props.selectedTemplate._id
		}
		return mergeState;
	}

	deleteElement = (position) => {
		let elementNameTobeDeleted = null;
		let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
		let modalJsonCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));
		let transformedRow = jsonCopy[position.split("-")[0]].columns.filter((el, index) => {
			return index != position.split("-")[1];
		});
		let transformedRowModalData;
		if (modalJsonCopy[position.split("-")[0]]) {
			transformedRowModalData = modalJsonCopy[position.split("-")[0]].columns.filter((el, index) => {
				if (index == position.split("-")[1]) {
					elementNameTobeDeleted = el.data.basic.name;
				}
				return index != position.split("-")[1];
			});
		}

		jsonCopy[position.split("-")[0]].columns = transformedRow;
		if (modalJsonCopy[position.split("-")[0]]) {
			modalJsonCopy[position.split("-")[0]].columns = transformedRowModalData
		}
		jsonCopy = jsonCopy.filter((el) => {
			return el.columns.length > 0
		})
		modalJsonCopy = modalJsonCopy.filter((el) => {
			return el.columns.length > 0
		})
		this.setState({ design: { rows: jsonCopy } }, () => {
			const { design, modalData } = this.state
			this.props.changeDesignAndModalData(design, modalData);
		})
		this.deleteValidations(elementNameTobeDeleted, modalJsonCopy);
		this.props.changeSaveState();
	}

	editElement = (item, index) => {
		let editElement = { index: index, data: this.state.modalData.rows[index.split("-")[0]].columns[index.split("-")[1]] }
		this.setState({ open: true, editElement: editElement });
		this.props.changeSaveState();
	}

	removeEmptyRows = (variable) => {
		let jsonCopy = JSON.parse(JSON.stringify(variable));
		let filteredRows = jsonCopy.filter((item) => {
			return item.columns.length > 0;
		})
		return filteredRows;
	}

	innerFormUpdateMultiple = (itemPosition, jsonCopy, modalDataCopy, innerFormPosition) => {
		let rowOfElement = itemPosition.split("-")[0];
		let columnOfElement = itemPosition.split("-")[1];
		let jsonCopyOuter = JSON.parse(JSON.stringify(this.state.design));
		jsonCopyOuter.rows[rowOfElement].columns[columnOfElement].innerForms[innerFormPosition].innerFormModalData.rows = modalDataCopy;
		jsonCopyOuter.rows[rowOfElement].columns[columnOfElement].innerForms[innerFormPosition].innerForm.rows = jsonCopy;
		this.setState({ design: jsonCopyOuter }, () => {
			const { design, modalData } = this.state
			this.props.changeDesignAndModalData(design, modalData);
		})
		this.props.changeSaveState();
	}

	innerFormUpdate = (itemPosition, jsonCopy, modalDataCopy) => {
		let rowOfElement = itemPosition.split("-")[0];
		let columnOfElement = itemPosition.split("-")[1];
		let jsonCopyOuter = JSON.parse(JSON.stringify(this.state.design));
		jsonCopyOuter.rows[rowOfElement].columns[columnOfElement].innerFormModalData.rows = modalDataCopy;
		jsonCopyOuter.rows[rowOfElement].columns[columnOfElement].innerForm.rows = jsonCopy;
		this.setState({ design: jsonCopyOuter }, () => {
			const { design, modalData } = this.state
			this.props.changeDesignAndModalData(design, modalData);
		})
		this.props.changeSaveState();
	}

	createNewRowByRearrage(rowIndex) {
		let newrow = {
			columns: []
		}

		let newmodalDataRow = {
			columns: []
		}
		let rowDragged = this.props.draggedElement.data.split("-")[0];
		let columnDragged = this.props.draggedElement.data.split("-")[1];
		let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
		if (jsonCopy.length <= rowDragged || jsonCopy[rowDragged].columns.length <= columnDragged) {
			return;
		}
		let selectedElementJson = JSON.parse(JSON.stringify(jsonCopy[rowDragged].columns[columnDragged]));
		let transformedRow = jsonCopy[rowDragged].columns.filter((el, index) => {
			return index != columnDragged;
		});

		jsonCopy[rowDragged].columns = transformedRow;

		let modalDataCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));
		let modalDataElement = JSON.parse(JSON.stringify(modalDataCopy[rowDragged].columns[columnDragged]));

		let transformedRowModalData = modalDataCopy[rowDragged].columns.filter((el, index) => {
			return index != columnDragged;
		});

		modalDataCopy[rowDragged].columns = transformedRowModalData;

		if (rowDragged < rowIndex && jsonCopy[rowDragged].columns.length == 0) {
			rowIndex = rowIndex - 1;
		}
		jsonCopy = this.removeEmptyRows(jsonCopy);
		modalDataCopy = this.removeEmptyRows(modalDataCopy);

		newrow.columns.push(selectedElementJson);
		newmodalDataRow.columns.push(modalDataElement);
		jsonCopy = [...jsonCopy.slice(0, rowIndex), newrow, ...jsonCopy.slice(rowIndex)];
		modalDataCopy = [...modalDataCopy.slice(0, rowIndex), newmodalDataRow, ...modalDataCopy.slice(rowIndex)];
		this.setState({ design: { rows: jsonCopy }, modalData: { rows: modalDataCopy }, emptyGridToHighlight: null }, () => {
			const { design, modalData } = this.state
			this.outerIndex = null
			this.props.changeDesignAndModalData(design, modalData);
		})
		this.props.changeSaveState();

	}

	addElementAtDesiredPosition = (selectedPosition, data) => {
		if ((selectedPosition + "").includes("-")) {
			let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
			let rowDropped = selectedPosition.split("-")[0];
			let columnDropped = selectedPosition.split("-")[1];
			if (jsonCopy.length <= rowDropped || jsonCopy[rowDropped].columns.length <= columnDropped) {
				return;
			}
			let droppedRowCopy = JSON.parse(JSON.stringify(jsonCopy[rowDropped].columns))


			let transformedDropRow = [...droppedRowCopy.slice(0, columnDropped), this.getJson(this.state.elementType, data), ...droppedRowCopy.slice(columnDropped)]
			jsonCopy[rowDropped].columns = transformedDropRow;

			let modalDataCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));

			let modalDataDroppedRowCopy = JSON.parse(JSON.stringify(modalDataCopy[rowDropped].columns));

			let transformedDropModalRow = [...modalDataDroppedRowCopy.slice(0, columnDropped), { data: data, type: this.state.elementType }, ...modalDataDroppedRowCopy.slice(columnDropped)]
			modalDataCopy[rowDropped].columns = transformedDropModalRow;
			jsonCopy = this.removeEmptyRows(jsonCopy);
			modalDataCopy = this.removeEmptyRows(modalDataCopy);


			this.setState({
				design: { rows: jsonCopy }, modalData: { rows: modalDataCopy }, emptyGridToHighlight: null
				, newElementPosition: null
			})
			this.outerIndex = null
			this.props.changeSaveState();
		}
		else {
			let newrow = {
				columns: []
			}

			let newmodalDataRow = {
				columns: []
			}
			let rowIndex = selectedPosition;
			let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
			let modalDataCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));
			newrow.columns.push(this.getJson(this.state.elementType, data));
			newmodalDataRow.columns.push({ data: data, type: this.state.elementType });
			jsonCopy = [...jsonCopy.slice(0, rowIndex), newrow, ...jsonCopy.slice(rowIndex)];
			modalDataCopy = [...modalDataCopy.slice(0, rowIndex), newmodalDataRow, ...modalDataCopy.slice(rowIndex)];
			this.setState({ design: { rows: jsonCopy }, modalData: { rows: modalDataCopy }, emptyGridToHighlight: null, newElementPosition: null }, () => {
				const { design, modalData } = this.state
				this.props.changeDesignAndModalData(design, modalData);
			})
			this.props.changeSaveState();
		}
	}

	rearrangeElements(selectedElement, selectedPosition) {
		if (!this.props.draggedElement || !this.props.draggedElement.data || Object.getOwnPropertyNames(this.props.draggedElement.data).length == 0) {
			this.setState({ dragging: false, open: true })
			return;
		}
		let rowDragged = this.props.draggedElement.data.split("-")[0];
		let columnDragged = this.props.draggedElement.data.split("-")[1];
		let rowDropped = selectedPosition.split("-")[0];
		let columnDropped = selectedPosition.split("-")[1];
		let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
		let selectedElementJson = JSON.parse(JSON.stringify(jsonCopy[rowDragged].columns[columnDragged]));
		let transformedRow = jsonCopy[rowDragged].columns.filter((el, index) => {
			return index != columnDragged;
		});

		jsonCopy[rowDragged].columns = transformedRow;
		let droppedRowCopy = JSON.parse(JSON.stringify(jsonCopy[rowDropped].columns))


		let transformedDropRow = [...droppedRowCopy.slice(0, columnDropped), selectedElementJson, ...droppedRowCopy.slice(columnDropped)]
		jsonCopy[rowDropped].columns = transformedDropRow;
		this.props.clearDraggedElement();

		let modalDataCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));
		let modalDataElement = JSON.parse(JSON.stringify(modalDataCopy[rowDragged].columns[columnDragged]));

		let transformedRowModalData = modalDataCopy[rowDragged].columns.filter((el, index) => {
			return index != columnDragged;
		});

		modalDataCopy[rowDragged].columns = transformedRowModalData;

		let droppedRowCopyModalData = JSON.parse(JSON.stringify(modalDataCopy[rowDropped].columns))
		let transformedDropRowModalData = [...droppedRowCopyModalData.slice(0, columnDropped), modalDataElement, ...droppedRowCopyModalData.slice(columnDropped)]
		modalDataCopy[rowDropped].columns = transformedDropRowModalData;

		jsonCopy = this.removeEmptyRows(jsonCopy);
		modalDataCopy = this.removeEmptyRows(modalDataCopy);


		this.setState({ design: { rows: jsonCopy }, modalData: { rows: modalDataCopy }, emptyGridToHighlight: null }, () => {
			const { design, modalData } = this.state
			this.outerIndex = null
			this.props.changeDesignAndModalData(design, modalData);
		})
		this.props.changeSaveState();
	}

	useExistingRow(data) {
		let currentRow = this.state.design.rows[this.state.design.rows.length - 1];
		let draggedElement = this.state.elementType
		currentRow.columns.push(this.getJson(draggedElement, data));
		let Rows = this.state.design.rows;
		Rows = Rows.slice(0, Rows.length - 1);
		Rows.push(currentRow);


		let modalData = JSON.parse(JSON.stringify(this.state.modalData));
		let modalDataRow = modalData.rows[this.state.design.rows.length - 1]
		modalDataRow.columns.push({ data: data, type: draggedElement });
		this.setState({ design: { rows: Rows }, modalData: modalData }, () => {
			const { design, modalData } = this.state
			this.props.changeDesignAndModalData(design, modalData);
		});
	}

	buttonAction = (position) => {
		let row = position.split("-")[0];
		let column = position.split("-")[1];
		let buttonJson = JSON.parse(JSON.stringify(this.state.design.rows[row].columns[column]));
		let body = this.state.dataFilled;
		let headers = { 'appId': this.props.match.params.dappId };
		buttonJson.headers.map((el) => {
			headers[el.label] = el.value;
		});
		if (buttonJson.function == 'api') {
			axios.post(buttonJson.url, this.state.dataFilled, { headers: headers }).then(response => {
				this.setState({
					showApiResponse: true, apiResponse: {
						body: body,
						headers: headers,
						response: response,
						url: buttonJson.url,
						buttonType: buttonJson.function
					}
				});
			}).catch((exp) => { console.error(exp) })

		}
		else if (buttonJson.function == 'save') {
			this.setState({
				showApiResponse: true, apiResponse: {
					body: body,
					buttonType: buttonJson.function
				}
			});
		}
	}

	setData = (name, val, item) => {
		let clone = JSON.parse(JSON.stringify(this.state.dataFilled));
		if (item != 'checkbox') {
			clone[name] = val;
			this.setState({
				dataFilled: clone
			})
		}
		else {
			let selectedValues = clone[name];
			if (Array.isArray(selectedValues)) {
				if (selectedValues.includes(val)) {
					let filteredArr = selectedValues.filter((el) => {
						return el != val
					});
					clone[name] = filteredArr;
				}
				else {
					clone[name].push(val);
				}
			}
			else {
				clone[name] = [val];
			}

			this.setState({
				dataFilled: clone
			})
		}
	}

	dropBetweenRows = (e, outerIndex) => {
		this.setState({ emptyGridToHighlight: null });
		this.outerIndex = null
		if (!this.props.draggedElement.data) {
			this.setState({ newElementPosition: outerIndex });
			return;
		}
		if (!this.state.dragging) { e.stopPropagation() };
		this.createNewRowByRearrage(outerIndex);
	}

	dragStart = (event) => {
		event.dataTransfer.setData("inputType", event.target.id);
		this.props.clearDraggedElement();
		this.props.draggedNewInputType(event.dataTransfer.getData("inputType"));
		this.setState({ elementType: event.dataTransfer.getData("inputType"), dragging: true })
	}

	elementDragStart = (event, selectedElement) => {
	}

	getJson(draggedElement, data) {
		let json = getJson(draggedElement, data);
		if (json.dynamicDataFunction) {
			return this.setDynamicData(json, this.getDynamicDataFromFunction(json.dynamicDataFunction))
		}
		else {
			return json;
		}
	}

	createNewRow(data) {
		let row = {
			columns: []
		}
		let modalDataRow = {
			columns: []
		}
		let draggedElement = this.state.elementType
		row.columns.push(this.getJson(draggedElement, data));
		let rows = [...this.state.design.rows];
		rows.push(row);

		let modalData = JSON.parse(JSON.stringify(this.state.modalData));
		modalDataRow.columns.push({ data: data, type: draggedElement });
		let ModalDatarows = modalData.rows;
		ModalDatarows.push(modalDataRow);

		this.setState({ design: { rows: rows }, modalData: { rows: ModalDatarows } }, () => {
			const { design, modalData } = this.state
			this.props.changeDesignAndModalData(design, modalData);
		});

	}

	drop = (e) => {
		if (e.dataTransfer.getData("inputType")) {
			this.setState({ elementType: e.dataTransfer.getData("inputType"), dragging: false, open: true })
		}
	}

	elementDropped = (e, selectedElement) => {
	}

	dragEnd = (event, inputType) => {
		this.setState({ dragging: false });
	}

	handleCancel = () => {
		this.setState({ open: false, editElement: null });
	}

	handleClose = (data) => {
		this.setState({ open: false, elementsData: data, editElement: null });
		this.props.changeSaveState();
		if (this.state.newElementPosition) {
			this.addElementAtDesiredPosition(this.state.newElementPosition, data);
		} else if (this.outerIndex) {
			this.addElementAtDesiredPosition(this.outerIndex, data);
		}
		else if (this.state.design.rows.length == 0) {
			this.createNewRow(data);
		}
		else if (!data.basic.sameRow) {
			this.createNewRow(data);
		}
		else {
			this.useExistingRow(data);
		}
	}

	editElementProperties = (data) => {
		let edittedJson = this.getJson(this.state.editElement.data.type, data);
		let rowToBeEditted = this.state.editElement.index.split("-")[0];
		let columnToBeEditted = parseInt(this.state.editElement.index.split("-")[1]);
		let currentRows = JSON.parse(JSON.stringify(this.state.design.rows));
		let rowToTransform = JSON.parse(JSON.stringify(this.state.design.rows[rowToBeEditted].columns));
		if (rowToTransform[columnToBeEditted].innerForm != null) {
			edittedJson.innerForm = rowToTransform[columnToBeEditted].innerForm;
			edittedJson.innerFormModalData = rowToTransform[columnToBeEditted].innerFormModalData;
		}
		let rowAfterTrasformation = [...rowToTransform.slice(0, columnToBeEditted), edittedJson, ...rowToTransform.slice(columnToBeEditted + 1)]
		currentRows[rowToBeEditted].columns = rowAfterTrasformation;

		let currentRowsModalData = JSON.parse(JSON.stringify(this.state.modalData.rows));
		let rowToTransformModalData = JSON.parse(JSON.stringify(this.state.modalData.rows[rowToBeEditted].columns));
		let rowAfterTrasformationModalData = [...rowToTransformModalData.slice(0, columnToBeEditted), { data: data, type: this.state.editElement.data.type }, ...rowToTransformModalData.slice(columnToBeEditted + 1)]
		currentRowsModalData[rowToBeEditted].columns = rowAfterTrasformationModalData;

		this.setState({
			open: false, elementsData: data, editElement: null,
			design: { rows: currentRows },
			modalData: { rows: currentRowsModalData }
		}, () => {
			this.props.changeDesignAndModalData(this.state.design, this.state.modalData)
		});
	}

	hideDialog = () => {
		this.props.clrMessage();
	}

	modifyValidations = (previousName, newName) => {
		let stateCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));
		stateCopy.map((item, index) => {
			item.columns.map((column, columnIndex) => {
				let conditionalRequiredcopy = column.data.conditionalRequired && column.data.conditionalRequired.map((item) => {
					if (item.element == previousName) {
						return { ...item, element: newName };
					}
					else {
						return item;
					}
				});

				let visibilitycopy = column.data.visibility && column.data.visibility.map((item) => {
					if (item.element == previousName) {
						return { ...item, element: newName };
					}
					else {
						return item;
					}
				});
				stateCopy[index].columns[columnIndex].data = { ...stateCopy[index].columns[columnIndex].data, visibility: visibilitycopy, conditionalRequired: conditionalRequiredcopy };
			})
		})
		this.setState({ modalData: { ...this.state.modalData, rows: stateCopy } })
	}

	deleteValidations = (elementName, jsonRows) => {
		let stateCopy = JSON.parse(JSON.stringify(jsonRows));
		stateCopy.map((item, index) => {
			item.columns.map((column, columnIndex) => {
				let conditionalRequiredcopy = column.data.conditionalRequired.filter((item) => {
					if (item.element == elementName) {
						return false;
					}
					else {
						return true;
					}
				});

				let visibilitycopy = column.data.visibility.filter((item) => {
					if (item.element == elementName) {
						return false;
					}
					else {
						return true;
					}
				});
				stateCopy[index].columns[columnIndex].data = { ...stateCopy[index].columns[columnIndex].data, visibility: visibilitycopy, conditionalRequired: conditionalRequiredcopy };
			})
		})
		this.setState({ modalData: { ...this.state.modalData, rows: stateCopy } })
	}

	render() {
		const { classes, user } = this.props;
		return (
			<div >
				<Dialog
					disableBackdropClick
					disableEscapeKeyDown
					aria-labelledby="confirmation-dialog-title"
					open={this.state.open}
					maxWidth={'md'}
					classes={{ paper: classes.dialogPaper }}>
					<DialogTitle id="confirmation-dialog-title"></DialogTitle>
					<DialogContent >
						{this.state.open && <PropertiesModal
							handleClose={(data) => {
								this.handleClose(data)
							}}
							handleCancel={() => { this.handleCancel() }}
							editElement={this.state.editElement}
							elementType={this.state.elementType}
							design={this.state.design.rows}
							modifyValidations={(previousName, newName) => { this.modifyValidations(previousName, newName) }}
							editElementProperties={(data) => { this.editElementProperties(data) }}
							basicInfo={this.props.basicInfo}
						/>}
					</DialogContent>
				</Dialog>
				<Dialog
					open={this.state.showApiResponse}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					{ApiResponseModal(this.state)}
					<DialogActions>
						<Button color="primary" onClick={() => { this.setState({ showApiResponse: false }) }}>
							{"Ok"}
						</Button>
					</DialogActions>
				</Dialog>
				{/* {this.state.saveMessage &&
                    MessageDialog(this.state.saveMessage, () => { this.hideDialog() })} */}
				<Grid container className="testScroll">
					<Grid item xs={2} style={{ position: 'fixed' }}>
						<Elements
							dragStart={this.dragStart}
						/>
					</Grid>
					<Grid item xs={12} style={{ paddingLeft: '180px' }}>
						<div className="form-design-pane"
							onDrop={(e) => { this.drop(e) }}
							onDragOver={(event) => event.preventDefault()}
						>
							{!this.state.design.rows && <h1 align="center">Drag and Drop a Form Element</h1>}
							<Grid container  >
								<GenerateForm
									rows={this.state.design.rows}
									dropBetweenRows={this.dropBetweenRows}
									// generateRow={this.generateRow}


									filledData={this.state.dataFilled}
									deleteElement={this.deleteElement}
									innerFormUpdateMultiple={this.innerFormUpdateMultiple}
									innerFormUpdate={this.innerFormUpdate}
									editElement={this.editElement}
									buttonAction={this.buttonAction}
									setData={this.setData}

									rearrangeElements={(selectedElement, selectedPosition) => {
										if (selectedElement == null) {
											this.setState({ newElementPosition: selectedPosition })
										}
										; this.rearrangeElements(selectedElement, selectedPosition)
									}}

									onDragOver={(event, outerIndex) => {//if(!this.props.draggedElement.data){return};
										// this.setState({ emptyGridToHighlight: outerIndex });
										this.outerIndex = outerIndex;
										event.preventDefault()
									}}
								/>
								{/* {this.generateFormFromJson()} */}
							</Grid>
						</div>
					</Grid>
				</Grid>
			</div>
		)
	}
}

export default withStyles(styles, { withTheme: true })(
	withRouter(Redux(FormBuilder))
)
