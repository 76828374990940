import React, { Component } from 'react';
import { Grid, Card, CardContent, CardHeader, Typography, List, ListItem, ListItemIcon,
	ListItemLink, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import 'styles/page-designer.css';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'

const styles = theme => ({});

class PageDesignerSimpleList extends Component {
	render() {
		const ListItemLink = (props) => <ListItem button component="a" {...props} />
		let { title, data, width, height, error } = this.props.element.chartData;
		console.log('datadata::', this.props.element);
		if (error) {
			return <div className="errorDisplayCenter">{error}</div>
		} else {
			return (
				<div
					id={`SimpleListContainer${this.props.element.chartUniqueId}`}
					style={{ height: height, width: width }}
				>
					{title ? <CardHeader className='' title={title} /> : ''}
					<List component="nav" className="p-0" aria-label="secondary mailbox folders">
						{data && data.length > 0 ?
							data.map((d, i) => (
								typeof d === 'object' ?
									<ListItemLink key={i} href={d.URL}>
										<ListItemText primary={d.grade} />
									</ListItemLink>
									: <ListItemText key={i} primary={d} />
							))
							: "Please wait data has been loading"
						}
					</List>
				</div>
			);
		}
	}
}

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(
			null,
			null
		)(PageDesignerSimpleList)
	)
)
