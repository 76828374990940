import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button, TextField, FormControl, FormLabel } from '@material-ui/core'

const apiView = (props) => {
	let { form, classes, testDatasource, handleChange } = props
	return (<><br />
		<FormLabel component="legend">{"API End Point"}</FormLabel>
		<br />
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"GET End Point"}</FormLabel>
			<TextField
				id="apiGetEndPoint"
				name="apiGetEndPoint"
				placeholder="GET End Point"
				className="mt-6 mb-10"
				value={form.apiGetEndPoint}
				onChange={handleChange}
				margin="normal"
				fullWidth
				variant="outlined"
			/>
		</FormControl>
		<br />
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Access Token"}</FormLabel>
			<TextField
				id="apiAccessToken"
				name="apiAccessToken"
				placeholder="Access Token"
				className={classes.textField}
				value={form.apiAccessToken}
				onChange={handleChange}
				margin="normal"
				variant="outlined"
			/>
		</FormControl>
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Application Id"}</FormLabel>
			<TextField
				id="apiAppId"
				name="apiAppId"
				placeholder="Application Id"
				className={classes.textField}
				value={form.apiAppId}
				onChange={handleChange}
				margin="normal"
				variant="outlined"
			/>
		</FormControl>
		<Button
			className="whitespace-no-wrap"
			variant="contained"
			onClick={() => testDatasource(form, props.match.params.dappId)}>
			{"Test"}
		</Button>
		<br />
	</>)
}

export default withRouter(apiView)