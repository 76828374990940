import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './View'
import Redux from './Redux'
import { withRouter } from 'react-router-dom'

const Container = (props) => {

	const [state, setState] = useState({
		tabValue: 0,
		form: { participants: [] },
		isSubmitButton: true
	});
	const [error, setError] = useState({ name: false })

	const handleChange = (event) => setState({
		...state,
		form: _.set({ ...state.form }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value)
	})

	const updateNetworkState = () => {
		const params = props.match.params;
		const { networkId, dappId } = params;

		if (networkId === 'new') {
			props.newNetwork();
		}
		else {
			props.getNetwork(props.match.params);
		}
		props.getEntities(props.match.params.dappId);
	};

	const updateFormState = () => setState({ ...state, form: { ...props.network.data } })

	const handleChipChange = (event, name) => setState({ ...state, form: _.set({ ...state.form }, name, event.target.value) })

	const canBeSubmitted = () => {
		// const { name } = state.form;
		// if (name && name.length === 0) {
		// 	setError({ ...error, name: "Please enter name" });
		// }
		// return (
		// 	name && name.length > 0 &&
		// 	!_.isEqual(props.network.data, state.form) && state.isSubmitButton
		// );
		return true;
	}

	useEffect(() => {
		if (!_.isEqual(props.network.data, state.form)) {
			setState({ ...state, isSubmitButton: true })
		}
	}, [state.form])

	useEffect(() => {
		updateNetworkState();
	}, [props.location])

	useEffect(() => {
		updateFormState();
	}, [props.network.data])

	useEffect(() => {
		if (props.network.saveSuccess) {
			props.showAlert('Network saved successfully', { id: "network", variant: 'success', icon: 'bell' });
			props.history.push({
				pathname: `/builder/${props.match.params.dappId}/networks/${props.network.data._id}`
			});
		}
		if (props.network.updateSuccess) {
			props.showAlert('Network updated successfully', { id: "network", variant: 'success', icon: 'bell' });
			props.history.push({
				pathname: `/builder/${props.match.params.dappId}/networks/${props.network.data._id}`
			});
		}
	}, [props.network.saveSuccess, props.network.updateSuccess])


	const saveNetworkData = () => {
		const { name } = state.form;
		if (!name || name === "") {
			setError({ ...props.network.error, name: "Please enter name" })
			return;
		}
		setState({ ...state, isSubmitButton: false });
		props.saveNetwork({ ...state.form, appId: props.match.params.dappId });
	}


	const updateNetworkData = () => {
		setState({ ...state, isSubmitButton: false });
		props.updateNetwork({ ...state.form, appId: props.match.params.dappId });
	}

	useEffect(() => {
		let error = props.network.error;
		if (error) {
			error.name && props.showAlert("NETWORK ERROR: Please verify your internet connection", {
				id: "network",
				variant: 'danger',
				icon: 'times-circle'
			})
			if (error.name) {
				setError({ ...error, name: error.name });
			}
		}
	}, [props.network.error])

	useEffect(() => {
		if (typeof props.network.error === "object" && props.network.error.status) {
			if (props.network.error.status == 401) {
				props.history.push({ pathname: "/login" });
				props.showAlert(props.network.error.errorMessage, { id: "network", variant: 'error' });
			} else {
				if (props.network.error.errorMessage) {
					this.setState({ ...state, isSubmitButton: true });
					if (props.network.error.errorMessage) {
						props.showAlert(props.network.error.errorMessage, { id: "network", variant: 'error' });
					}
				}
			}
		}
	}, [props.network.error])

	return (
		<View
			{...props}
			state={state}
			error={error}
			handleChange={handleChange}
			handleChipChange={handleChipChange}
			canBeSubmitted={canBeSubmitted}
			saveNetwork={() => saveNetworkData()}
			updateNetwork={() => updateNetworkData()}
		/>
	)
}

export default hot(withRouter(Redux(Container)))