import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { RolesService } from 'services/index'
import { ActionTypes } from 'constants/index'

async function fetchAsync(func, arg) {
	const response = arg ? await func(arg) : await func()
	return response
}

function* getRoles(action) {
	try {
		const roles = yield call(fetchAsync, RolesService.getRoles, action.payload)
		yield put({ type: ActionTypes.GET_ROLES_SUCCESS, payload: roles })
	} catch (e) {
		yield put({ type: ActionTypes.GET_ROLES_FAILURE, error: e.response })
	}
}

function* getRole(action) {
	try {
		const role = yield call(fetchAsync, RolesService.getRole, action.payload)
		yield put({ type: ActionTypes.GET_ROLE_SUCCESS, payload: role })
	} catch (e) {
		yield put({ type: ActionTypes.GET_ROLE_FAILURE, error: e.response })
	}
}

function* saveRole(action) {
	try {
		const role = yield call(fetchAsync, RolesService.saveRole, action.payload)
		yield put({ type: ActionTypes.SAVE_ROLE_SUCCESS, payload: role })
	} catch (e) {
		let errObj = { name: false };
		if (e.response) {
			errObj = e.response.fields;
			errObj.errorMessage = e.response.message ? e.response.message : "";
			errObj.status = e.response.status ? e.response.status : ""
		}
		yield put({ type: ActionTypes.SAVE_ROLE_FAILURE, error: errObj })
	}
}

function* updateRole(action) {
	try {
		const role = yield call(fetchAsync, RolesService.updateRole, action.payload)
		yield put({ type: ActionTypes.UPDATE_ROLE_SUCCESS, payload: role })
	} catch (e) {
		let errObj = { name: false };
		if (e.response) {
			errObj = e.response.fields;
			errObj.errorMessage = e.response.message ? e.response.message : "";
			errObj.status = e.response.status ? e.response.status : ""
		}
		yield put({ type: ActionTypes.UPDATE_ROLE_FAILURE, error: errObj })
	}
}

function* deleteRole(action) {
	try {
		const role = yield call(fetchAsync, RolesService.deleteRole, action.payload)
		yield put({ type: ActionTypes.DELETE_ROLE_SUCCESS, payload: role })
	} catch (e) {
		yield put({ type: ActionTypes.DELETE_ROLE_FAILURE, error: e.response })
	}
}

export function* watchRolesSaga() {
	yield takeEvery(ActionTypes.GET_ROLES, getRoles);
	yield takeEvery(ActionTypes.GET_ROLE, getRole)
	yield takeEvery(ActionTypes.SAVE_ROLE, saveRole)
	yield takeEvery(ActionTypes.UPDATE_ROLE, updateRole)
	yield takeEvery(ActionTypes.DELETE_ROLE, deleteRole)
}

export default watchRolesSaga;