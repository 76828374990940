import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import photoone from '../../images/photoone.svg';
import oracleimg from '../../images/oracleimg.svg';
import microsoftsqlserver from '../../images/microsoftsqlserver.svg';
import netsuitelogo from '../../images/netsuitelogo.png';
import addnewimg from '../../images/addnewimg.svg';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
import { request } from 'modules/client'

const DCard = ({ name, imgURL, titlePosition, isImage, isText, direction, width, handleNext, text }) => {
    const [imglogo, setImgLogo] = React.useState();

    const loadImage = async (url) => {
        try {
            let data = await request(url, { method: 'GET' });
            console.log("data", data);
            if (data) {
                if (data.substring(0, 4) === "<svg") {
                    console.log("svg", <div dangerouslySetInnerHTML={{ __html: data }}></div>);
                    setImgLogo(<div dangerouslySetInnerHTML={{ __html: data }}></div>);
                } 
                // else {
                //     console.log("png", "fire");
                //     setImgLogo(<img src={(url) ? url : ""} />)
                // }
            }
        } catch (e) {
            console.error("e");
        }
    }

    useEffect(() => {
        loadImage(`${API_BASE_URL}/logo/${imgURL}`);
    }, [])
    return (<Paper className="crsr-pntr" onClick={handleNext} style={{ maxWidth: width }}>
        {(titlePosition == 'top' || titlePosition == null) && <Box component="label" style={{ textAlign: "center", margin: '0' }}>{name}</Box>}
        <div style={{ display: 'table', direction: direction }}>
            <div style={{ display: 'table-row' }}>
                <div style={{ display: "table-cell", padding: '5px', verticalAlign: "middle" }}>
                    {console.log("img data", imglogo)}
                    {isImage && imglogo}
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="78.534" height="56.095" viewBox="0 0 78.534 56.095"><defs></defs><g transform="translate(0 -68.267)"><g transform="translate(0 68.267)"><g transform="translate(0 0)"><path class="a" d="M70.119,68.267H8.414A8.414,8.414,0,0,0,0,76.681v39.267a8.414,8.414,0,0,0,8.414,8.414h61.7a8.414,8.414,0,0,0,8.414-8.414V76.681A8.414,8.414,0,0,0,70.119,68.267Zm2.8,32.5-17.65-17.65a2.8,2.8,0,0,0-3.966,0l-23.26,23.26-9.236-9.236a2.8,2.8,0,0,0-3.966,0l-9.236,9.236V76.681a2.8,2.8,0,0,1,2.8-2.8h61.7a2.8,2.8,0,0,1,2.8,2.8v24.082Z" transform="translate(0 -68.267)"/></g></g><g transform="translate(16.829 79.486)"><g transform="translate(0 0)"><circle class="a" cx="8.414" cy="8.414" r="8.414"/></g></g></g></svg> */}
                    {/* <div dangerouslySetInnerHTML={{ __html: '<svg xmlns="http://www.w3.org/2000/svg" width="78.534" height="56.095" viewBox="0 0 78.534 56.095"><defs><style>.a{fill:#cacaca;}</style></defs><g transform="translate(0 -68.267)"><g transform="translate(0 68.267)"><g transform="translate(0 0)"><path class="a" d="M70.119,68.267H8.414A8.414,8.414,0,0,0,0,76.681v39.267a8.414,8.414,0,0,0,8.414,8.414h61.7a8.414,8.414,0,0,0,8.414-8.414V76.681A8.414,8.414,0,0,0,70.119,68.267Zm2.8,32.5-17.65-17.65a2.8,2.8,0,0,0-3.966,0l-23.26,23.26-9.236-9.236a2.8,2.8,0,0,0-3.966,0l-9.236,9.236V76.681a2.8,2.8,0,0,1,2.8-2.8h61.7a2.8,2.8,0,0,1,2.8,2.8v24.082Z" transform="translate(0 -68.267)"/></g></g><g transform="translate(16.829 79.486)"><g transform="translate(0 0)"><circle class="a" cx="8.414" cy="8.414" r="8.414"/></g></g></g></svg>' }}></div> */}
                </div>
                <div style={{ display: "table-cell", padding: '0', verticalAlign: "middle", textAlign: 'left' }}>
                    {isText && <text>{text}</text>}
                </div>
            </div>
        </div>
        {(titlePosition == 'bottom') && <Box component="label" style={{ textAlign: "center" }}>{name}</Box>}
    </Paper>)
}
export default DCard;