import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Grid, Box, Typography } from '@material-ui/core'
import advanced from "images/advanced.png"
import Quickwizard from "images/quick-wizard.png"
import Templatepic from "images/template-pic.png"
import Uploadfiles from "images/upload-files.png"
import coming from "images/coming_soon.png"
import 'styles/styles.scss'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}))

let dappConfig = [{
	icon: Templatepic,
	title: "TEMPLATES",
	hide: true
}, {
	icon: Quickwizard,
	title: "QUICK WIZARD",
	hide: true
}, {
	icon: advanced,
	title: "ADVANCED",
	hide: false
}, {
	icon: Uploadfiles,
	title: "UPLOAD A FILE",
	hide: false
}]

const View = (props) => {
	const classes = useStyles()

	return (<Box>
		<div className="recent-apps pxy20" >
			<Grid container className={classes.root}>
				<Grid item xs={12} className="recent-apps-bannerbg">
					<Grid item lg={6} md={6} sm={12}>
						<Typography variant="h3">
							{"Driving accelerated value creation and cost savings at enterprises through Automation, Intelligence and Trust."}
						</Typography>
					</Grid>
				</Grid>
				<div className="container">
					<Grid item xs={12} className="">
						<Typography variant="h4">{"Let's Create New Application"}</Typography>
					</Grid>
					<div className="create-app-card">
						<Grid container spacing={2}>
							{dappConfig.map((conf, i) => <Grid key={i} item xs={12} sm={6} md={4} lg={3} className="crsr-pntr"
								onClick={conf.hide ? null : (e) => props.handleClick(e, conf.title)} >
								<Paper>
									<Grid style={{position: 'relative'}}>
										<img src={conf.icon} />
										<Box className="app-title"
										// onClick={(e) => props.handleClick(e, conf.title)}
										>
											{conf.title}
										</Box>
										{conf.hide ? <div className="coming-soon"></div> : null}
									</Grid>
								</Paper>
							</Grid>)}
						</Grid>
					</div>
				</div>
			</Grid>
		</div>
	</Box>)
}

export default View