import React from 'react';
import bindme from 'bindme';

export default class ConnectionPoint extends React.Component {
  constructor(props) {
    bindme(super(props), 'onMouseLeave', 'onMouseOver', 'onMouseDown');
    this.state = {
      focused: false,
    };
  }

  onMouseLeave(event) {
    this.props.setMouseOverConnection(event, false);
    this.setState({ focused: false });
    // this.props.onMouseLeave();
  }

  onMouseOver(event) {
    this.props.setMouseOverConnection(event, true);
    this.setState({ focused: true });
  }

  onMouseDown(event) {
    this.props.onDragConnection(event);
    // console.log('drag====', event);
  }

  render() {
    const { cx, cy, r } = this.props;

    const { focused } = this.state;

    return (
      <circle
        // onMouseMove={this.onMouseDown}
        // onMouseDown={createArrow}
        onMouseLeave={this.onMouseLeave}
        onMouseOver={this.onMouseOver}
        fill={focused ? '#32C2FC' : 'white'}
        stroke="#32C2FC"
        strokeWidth={1}
        cx={cx}
        cy={cy}
        r={r}
      />
    );
  }
}

ConnectionPoint.defaultProps = {
  r: 5,
};
