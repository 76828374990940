import Utils from 'packages/page/utils';

export default (form, dappId, selectedDataElement, dataModalId) => {
    return {
        "title": form.name,
        "name": form.name,
        "description": form.description,
        "type": "dashboard",
        "chart": [
            {
                "chartData": {
                    "selectedDataElement": selectedDataElement,
                    "selectedDataPages": [],
                    "selectedDataColumns": [],
                    "width": 500,
                    "height": 500,
                    "layout": {
                        "w": 12,
                        "h": 12,
                        "x": 0,
                        "y": 0,
                        "moved": false,
                        "static": false,
                        "i": "0"
                    },
                    "name": "Table",
                    "rowsPerPage": 10,
                    "selectedDataModel": dataModalId,
                    "dataUrl": "",
                    "dataHandler": "data_models",
                    "associatedFormName": form.name,
                    "pageLinking": "form_page"
                },
                "chartUniqueId": Utils.generateGUID(),
                "chartType": "list"
            }
        ],
        "url": form.name.replace(/[^A-Z0-9]/ig, ""),
        "default": false,
        "isActive": true,
        "rank": 0,
        "appId": dappId,
        "selectedRoles": []
    }
};