import { ActionTypes } from 'constants/index'
import Utils from 'packages/page/utils'

export const getNotifications = (appId) => dispatch => {
	return dispatch({ type: ActionTypes.GET_NOTIFICATIONS, payload: appId })
}

export const setNotificationsSearchText = (value) => dispatch => {
	return dispatch({ type: SET_NOTIFICATIONS_SEARCH_TEXT, searchText: value })
}

export const getNotification = (params) => dispatch => {
	return dispatch({ type: ActionTypes.GET_NOTIFICATION, payload: params })
}

export const saveNotification = (data) => dispatch => {
	return dispatch({ type: ActionTypes.SAVE_NOTIFICATION, payload: data })
}

export const updateNotification = (data) => dispatch => {
	return dispatch({ type: ActionTypes.UPDATE_NOTIFICATION, payload: data })
}

export const deleteNotification = (data) => dispatch => {
	return dispatch({ type: ActionTypes.DELETE_NOTIFICATION, payload: data })
}

export const newNotification = () => dispatch => {
	const data = {
		id: Utils.generateGUID(),
		name: '',
		handle: '',
		description: '',
		notificationType: '',
		categories: [],
		tags: [],
		primaryContactName: '',
		primaryContactEmail: '',
		primaryContactPhone: '',
		secContactName: '',
		secContactEmail: '',
		secContactPhone: '',
		natureofBusiness: '',
		services: '',
		active: true
	}
	return dispatch({ type: ActionTypes.GET_NOTIFICATION_SUCCESS, payload: data })
}
