import { Grid, NativeSelect, TextField } from '@material-ui/core';
import React from 'react';

const Datapipeline = props => {
  const { selectedstep } = props;
  return (
    <>
      {props.value === props.index && (
        <Grid container className="formPmodel">
          <Grid container>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Label</label>
            </Grid>
            <Grid item xs={5}>
              <TextField
                id="propertyId"
                label=""
                margin="dense"
                variant="filled"
                // value={this.state.id}
                // onChange={e => this.onChangeValue('propertyId', e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Select data pipeline to trigger</label>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              <NativeSelect
                fullWidth
                defaultValue="0"
                // value={selectedStep.bussinessrule}
                // onChange={e => this.onChangeValue('bussinessrule', e.target.value)}
              >
                <option value="0" disabled>
                  Data Pipeline: Loan Payment Reconciliation
                </option>

                <option value="casemanager">qqq</option>
                <option value="analyst">A</option>
                <option value="caseprocessor">qqwwr</option>
                <option value="auditor">Asd</option>
              </NativeSelect>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Datapipeline;
