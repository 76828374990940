import React from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, dataModel, workflows, datasources, datasource, formulas }) => {
		return {
			user: user,
			navigation: navigation,
			datamodel: dataModel,
			workflows: workflows,
			datasources: datasources.data,
			dataElements: datasource.dataElements,
			collections: datasource.collections,
			datasource: datasource.data,
			formulas: formulas,
		}
	}

	const mapDispatchToProps = {
		newDataModel: Actions.newDataModel,
		getDataModel: Actions.getDataModel,
		openNewDataModelDialog: Actions.openNewDataModelDialog,
		closeNewDataModelDialog: Actions.closeNewDataModelDialog,
		getDataModelsByOrgId: Actions.getDataModelsByOrgId,
		emptyDataModel: Actions.emptyDataModel,
		emptyDataSourceCollections: Actions.emptyDataSourceCollections,
		saveDatamodel: Actions.saveDataModel,
		updateDatamodel: Actions.updateDataModel,
		addDataElement: Actions.addDataElement,
		getDatasources: Actions.getDatasources,
		getCollectionsFromDatasource: Actions.getCollectionsFromDatasource,
		getDataElements: Actions.getDataElements,
		getDatasource: Actions.getDatasource,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert,
		getFormulas: Actions.getFormulas
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux
