import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { hot } from 'react-hot-loader/root'
import View from './View'
import Redux from './Redux'
import ConfirmDialog from '../confirmDialog'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	loaderContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 'calc(100vh - 75px)',
	},
}));

const Container = (props) => {
	const [open, setOpen] = React.useState(false)
	const [dapp, setDapp] = React.useState({})
	const [searchKeyword, setSearchKeyword] = React.useState("")
	const [categoryFilterData, setCategoryFilterData] = React.useState('');

	const classes = useStyles();

	useEffect(() => {
		props.getDapps()
		props.getCategories()
		props.currentDappId(null)
	}, [])

	const editDapps = (id) => {
		props.hideAlert("dappList")
		props.history.push({ pathname: '/builder/dapps/' + id })
	}

	const handleCategoryFilter = (event) => {
		setCategoryFilterData(event.target.value);
	}

	useEffect(() => {
		if (!props.dapps.error && props.dapps.error !== "") {
			props.showAlert("Network Error", { id: "dappList", variant: 'danger', icon: 'times-circle' })
		}
	}, [props.dapps.error])

	const deleteDappData = (dapp) => {
		props.hideAlert("dappList")
		setOpen(true)
		setDapp(dapp)
	}

	const handleAddNewAppClick = () => props.history.push({ pathname: '/builder/dapps/addappconfig' })

	const updateDappData = (dapp) => {
		let obj = { ...dapp };
		obj.status = "Published"
		setDapp(obj);
		props.updateDapp(obj);
	}

	useEffect(() => {
		if (props.dapp.updateSuccess) {
			props.getDapps();
			props.getDapp({ dappId: dapp._id });
		}
	}, [props.dapp.updateSuccess])

	useEffect(() => {
		props.newDapp()
	}, [])

	if (props.dapps.appsLoading) {
		return <div className={classes.loaderContainer}><CircularProgress /></div>
	}

	return (<>
		<View
			handleAddNewAppClick={handleAddNewAppClick}
			dapps={props.dapps}
			deleteDapps={props.deleteDapps}
			deleteDappData={deleteDappData}
			editDapps={editDapps}
			setSearchKeyword={setSearchKeyword}
			searchKeyword={searchKeyword}
			categoryFilterData={categoryFilterData}
			handleCategoryFilter={handleCategoryFilter}
			updateDappData={updateDappData}
		/>
		<ConfirmDialog
			title="Delete"
			open={open}
			setOpen={setOpen}
			onConfirm={() => props.deleteDapp(dapp._id)}>
			{"application"} {dapp.title}
		</ConfirmDialog>
	</>)
}

export default hot(withRouter(Redux(Container)))