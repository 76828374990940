import { keyMirror } from 'modules/helpers'

import dapps from './dapps'
import entities from './entities'
import formulas from './formulas'
import members from './members'
import roles from './roles'
import users from './users'
import dataModels from './dataModels'
import datasources from './datasources'
import forms from './forms'
import pages from './pages'
import businessRules from './businessRules'
import jobs from './jobs'
import networks from './networks'
import notifications from './notifications'
import pipelines from './pipelines'
import workflows from './workflows'
import flowchart from "./flowchart"
import datasourceTypes from './datasourceTypes'
import formulaTypes from './formulaTypes';
import groups from './groups';
/**
 * @namespace Constants
 * @desc App constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({

	SWITCH_MENU: undefined,
	EXCEPTION: undefined,

	SHOW_ALERT: undefined,
	HIDE_ALERT: undefined,

	HIDE_MESSAGE: undefined,
	SHOW_MESSAGE: undefined,

	SET_PREV_PATH: undefined,
	EMPTY_PREV_PATH: undefined,

	GET_NAVIGATION: undefined,
	SET_NAVIGATION: undefined,
	RESET_NAVIGATION: undefined,

	...dapps,
	...entities,
	...formulas,
	...members,
	...roles,
	...users,
	...dataModels,
	...datasources,
	...pages,
	...forms,
	...businessRules,
	...jobs,
	...networks,
	...notifications,
	...pipelines,
	...workflows,
	...flowchart,
	...datasourceTypes,
	...formulaTypes,
	...groups
})

/**
 * @constant {Object} STATUS
 * @memberof Constants
 */
export const STATUS = {
	IDLE: 'idle',
	RUNNING: 'running',
	READY: 'ready',
	SUCCESS: 'success',
	ERROR: 'error',
}
