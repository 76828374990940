import { ActionTypes } from 'constants/index'

const initialState = {
    data: null,
    searchText: "",
    error: {
        name: false, industry: false, primaryContactName: false, primaryContactEmail: false, primaryContactPhone: false,
        errorMessage: "",
        status: ""
    },
    loading: false,
    saveSuccess: false,
    updateSuccess: false
}

const entityReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_ENTITY_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                saveSuccess: false,
                updateSuccess: false
            }
        }
        case ActionTypes.GET_ENTITY_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.SAVE_ENTITY_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload,
                saveSuccess: true
            }
        }
        case ActionTypes.SAVE_ENTITY_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.UPDATE_ENTITY_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload,
                updateSuccess: true
            }
        }
        case ActionTypes.UPDATE_ENTITY_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        default: {
            return state;
        }
    }
};

export default entityReducer;
