import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { NetworksService } from 'services/index'
import { ActionTypes } from 'constants/index'

async function fetchAsync(func, arg) {
	const response = arg ? await func(arg) : await func()
	return response
}

function* getNetworks(action) {
	try {
		const networks = yield call(fetchAsync, NetworksService.getNetworks, action.payload)
		yield put({ type: ActionTypes.GET_NETWORKS_SUCCESS, payload: networks })
	} catch (e) {
		yield put({ type: ActionTypes.GET_NETWORKS_FAILURE, error: e.response })
	}
}

function* getNetwork(action) {
	try {
		const network = yield call(fetchAsync, NetworksService.getNetwork, action.payload)
		yield put({ type: ActionTypes.GET_NETWORK_SUCCESS, payload: network })
	} catch (e) {
		yield put({ type: ActionTypes.GET_NETWORK_FAILURE, error: e.response })
	}
}

function* saveNetwork(action) {
	try {
		const network = yield call(fetchAsync, NetworksService.saveNetwork, action.payload)
		yield put({ type: ActionTypes.SAVE_NETWORK_SUCCESS, payload: network })
	} catch (e) {
		let errObj = { name: false };
		if (e.response) {
			errObj = e.response.fields;
			errObj.errorMessage = e.response.message ? e.response.message : "";
			errObj.status = e.response.status ? e.response.status : ""
		}
		yield put({ type: ActionTypes.SAVE_NETWORK_FAILURE, error: errObj })
	}
}

function* updateNetwork(action) {
	try {
		const network = yield call(fetchAsync, NetworksService.updateNetwork, action.payload)
		yield put({ type: ActionTypes.UPDATE_NETWORK_SUCCESS, payload: network })
	} catch (e) {
		let errObj = { name: false };
		if (e.response) {
			errObj = e.response.fields;
			errObj.errorMessage = e.response.message ? e.response.message : "";
			errObj.status = e.response.status ? e.response.status : ""
		}
		yield put({ type: ActionTypes.UPDATE_NETWORK_FAILURE, error: errObj })
	}
}

function* deleteNetwork(action) {
	try {
		const network = yield call(fetchAsync, NetworksService.deleteNetwork, action.payload)
		yield put({ type: ActionTypes.DELETE_NETWORK_SUCCESS, payload: network })
	} catch (e) {
		yield put({ type: ActionTypes.DELETE_NETWORK_FAILURE, error: e.response })
	}
}

export function* watchNetworksSaga() {
	yield takeEvery(ActionTypes.GET_NETWORKS, getNetworks);
	yield takeEvery(ActionTypes.GET_NETWORK, getNetwork)
	yield takeEvery(ActionTypes.SAVE_NETWORK, saveNetwork)
	yield takeEvery(ActionTypes.UPDATE_NETWORK, updateNetwork)
	yield takeEvery(ActionTypes.DELETE_NETWORK, deleteNetwork)
}

export default watchNetworksSaga;