import React from 'react';
import { withStyles, Button, Tab, Tabs, TableHead, TableRow, TextField, InputAdornment, Icon, Select, MenuItem, Typography, FormControl, FormGroup, Checkbox, RadioGroup, FormControlLabel, Radio, FormLabel, Table, TableCell, TableBody, Input, Chip } from '@material-ui/core';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
export default function MessageTab(props) {
    const { form } = props.state;
    const { classes } = props;
    console.log("MessageTab", props);
    return (
        form && (
            <div className="p-16 sm:p-24 max-w-2xl">
                <form className="form" noValidate autoComplete="off">
                    <FormControl>
                        <FormLabel variant="h6">Message context</FormLabel>
                        <RadioGroup
                            aria-label="Context"
                            name="gender1"
                            className={classes.group}
                            value={form.value}
                            onChange={props.handleChange}
                            row
                        >
                            <FormControlLabel value="current" control={<Radio />} label="Current Context" />
                            <FormControlLabel value="workflow" control={<Radio />} label="Workflow Step" />
                            <FormControlLabel value="systems" control={<Radio />} label="System" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel variant="h6">Message Subject</FormLabel>
                        <TextField
                            className="mt-8 mb-16"
                            id="emailSubject"
                            name="emailSubject"
                            onChange={props.handleChange}
                            placeholder="Message Subject"
                            type="text"
                            value={form.emailSubject}
                            rows={2}
                            variant="outlined"
                            fullWidth
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel variant="h6">Message Body</FormLabel>
                        <Editor
                            editorState={props.state.editorState}
                            wrapperClassName="html-wrapper"
                            editorClassName="html-editor"
                            onEditorStateChange={props.onEditorStateChange}
                            toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                            }}
                        />
                    </FormControl>
                </form>
            </div>
        )
    )
}