import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PageDesignerBarChart from './PageDesignerBarChart';
import PageDesignerPieChart from './PageDesignerPieChart';
import PageDesignerMixedChart from './PageDesignerMixedChart';
import PageDesignerTableChart from '../common/PageDesignerTableChart';
import PageDesignerLineChart from './PageDesignerLineChart';
import PageDesignerBubbleChart from './PageDesignerBubbleChart';
import PageDesignerDoughnutChart from './PageDesignerDoughnutChart';
import PageDesignerRadarChart from './PageDesignerRadarChart';
import PageDesignerCardComponent from './../common/PageDesignerCardComponent';
import PageDesignerHTMLComponent from './../common/PageDesignerHTMLComponent';
import PageDesignerPolarChart from './PageDesignerPolarChart';
import PageDesignerBulletChart from './../common/bulletChart/PageDesignerBulletChart';
import PageDesignerSimpleList from '../common/PageDesignerSimpleList';
import PageDesignerSummaryTable from '../common/PageDesignerSummaryTable';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import * as Actions from 'actions';
import { bindActionCreators } from 'redux';
import _ from 'modules/lodash';
import Icon from '@material-ui/core/Icon';
import ClearIcon from '@material-ui/icons/Clear';
import PageDesignerLinks from '../common/PageDesignerLinks';

const styles = {
	closeIconContainer: {
		display: 'flex',
		flexDirection: 'row'
	}
};

class PageDesignerCard1 extends Component {
	onChartResize = (chartType, chartUniqueId, event, { element, size }) => {
		if (this.props.readOnly) {
			return;
		}
		this.props.saveResizedChartData({
			chartType,
			chartData: _.merge(
				{ chartUniqueId, chartData: {} },
				{ chartData: size }
			),
			showDataToEditComponent: false
		});
	};

	onChartClick = (chartType, chartUniqueId, event) => {
		if (this.props.readOnly) {
			return;
		}
		console.log("chartType, chartUniqueId", chartType, chartUniqueId);
		this.props.currentChartToEdit({
			chartType,
			currentChartDataToEdit: _.find(this.props.page.chart, {
				chartUniqueId
			})
		});
	};

	onChartClose = chartUniqueId => {
		this.props.deleteChartData(chartUniqueId);
	};

	render() {
		const element = JSON.parse(JSON.stringify(this.props.element));
		const { width, height, name, description } = this.props.element.chartData;
		let { chartUniqueId } = this.props.element;
		let chartComponent = null;
		let chartType = null;
		let ResizeElementClass =
			'box barChartContainer box3' +
			(this.props.readOnly ? ' removeResizeImage' : '');

		console.log("element.chartType", element.chartType);

		switch (element.chartType) {
			case 'bar':
				chartComponent = <PageDesignerBarChart element={element} />;
				chartType = Actions.barChart;
				break;
			case 'stackedbar':
				chartComponent = <PageDesignerBarChart element={element} stacked={true} />;
				chartType = Actions.stackedBarChart;
				break;
			case 'pie':
				chartComponent = <PageDesignerPieChart element={element} />;
				chartType = Actions.pieChart;
				break;
			case 'mixed':
				chartComponent = <PageDesignerMixedChart element={element} />;
				chartType = Actions.mixedChart;
				break;
			case 'list':
				chartComponent = (
					<PageDesignerTableChart
						readOnly={this.props.readOnly}
						element={element}
					/>
				);
				chartType = Actions.listChart;
				break;
			case 'simpletable':
				chartComponent = (
					<PageDesignerTableChart
						readOnly={this.props.readOnly}
						element={element}
					/>
				);
				chartType = Actions.simpleTable;
				break;
			case 'line':
				chartComponent = <PageDesignerLineChart element={element} />;
				chartType = Actions.lineChart;
				break;
			case 'card':
				chartComponent = <PageDesignerCardComponent element={element} />;
				chartType = Actions.card;
				break;
			case 'html':
				chartComponent = <PageDesignerHTMLComponent element={element} />;
				chartType = Actions.html;
				break;
			case 'bubble':
				chartComponent = <PageDesignerBubbleChart element={element} />;
				chartType = Actions.bubbleChart;
				break;
			case 'doughnut':
				chartComponent = <PageDesignerDoughnutChart element={element} />;
				chartType = Actions.doughnutChart;
				break;
			case 'radar':
				chartComponent = <PageDesignerRadarChart element={element} />;
				chartType = Actions.radarChart;
				break;
			case 'polar':
				chartComponent = <PageDesignerPolarChart element={element} />;
				chartType = Actions.polarChart;
				break;
			case 'bullet':
				chartComponent = <PageDesignerBulletChart element={element} />;
				chartType = Actions.bulletChart;
				break;
			case 'simplelist':
				chartComponent = <PageDesignerSimpleList element={element} />;
				chartType = Actions.simpleList;
				break;
			case 'summaryTable':
				chartComponent = <PageDesignerSummaryTable element={element} />;
				chartType = Actions.summaryTable;
				break;
			case 'links':
				chartComponent = <PageDesignerLinks element={element} />;
				chartType = Actions.links;
				break;
			default:
				chartComponent = null;
				break;
		}

		return (
			<div
				id={chartType + chartUniqueId}
				key={chartType + chartUniqueId}
				className={this.props.classes.closeIconContainer}
				style={{ height: "inherit", width: "inherit", "overflowX": "scroll" }}
				id="PageDesignerCard1-container"
			>
				<Card
					id={'Card' + chartType + chartUniqueId}
					style={{ width: '100%', height: '100%', "overflowX": "scroll" }}
				>
					{(chartType != Actions.listChart && chartType !== Actions.links) ?
						<CardHeader
							title={name}
							style={{ padding: '0px 16px' }}
						/>
						: ''
					}
					<CardContent
						id={'CardContent' + chartType + chartUniqueId}
						style={{ width: 'inherit', height: 'inherit', "overflowX": "scroll" }}
					>
						<div
							id={
								'CardContentContainer' +
								chartType +
								chartUniqueId
							}
							className="flex items-center w-full box"
							style={{ width: 'inherit', height: 'inherit' }}
							onDoubleClick={this.onChartClick.bind(
								this,
								chartType,
								chartUniqueId
							)}
						>
							{chartComponent}
						</div>
					</CardContent>
				</Card>
				{!this.props.readOnly && (
					<ClearIcon
						id={'CloseIconContainer' + chartType + chartUniqueId}
						className="closeIconContainer"
						onClick={this.onChartClose.bind(this, chartUniqueId)}
					>
						close
					</ClearIcon>
				)}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			deleteChartData: Actions.deleteChartData,
			// saveResizedChartData: Actions.saveResizedChartData,
			currentChartToEdit: Actions.currentChartToEdit,
			// openDataToEditComponent: Actions.openDataToEditComponent,
			// closeChartToEdit: Actions.closeChartToEdit,
			// sortChartData: Actions.sortChartData
		},
		dispatch
	);
}

function mapStateToProps({ pages }) {
	return {
		page: pages.page
	};
}

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(
			mapStateToProps,
			mapDispatchToProps
		)(PageDesignerCard1)
	)
)
