import React from 'react'
import FormElements from './FormElements'
import FormComplexElements from './FormComplexElements'

const GenerateRow = (props) => {
    let {columns, outerIndex} = props
		return columns.map((item, index) => {
            let gridSize = 12 / columns.length;
			return item.type !== 'expansionPanels' && item.type !== 'tab' && item.type !== 'card' && item.type !== 'multiStepForm'
            ? <FormElements gridSize={gridSize} item={item} key={index}
				deleteElement={(position) => { props.deleteElement(position) }}
				innerFormUpdateMultiple={(itemPosition, jsonCopy, modalData, innerIndex) => {
					props.innerFormUpdateMultiple(itemPosition, jsonCopy, modalData, innerIndex);
				}}
				innerFormUpdate={(itemPosition, jsonCopy, modalData) => {
					props.innerFormUpdate(itemPosition, jsonCopy, modalData);
				}}
				editElement={(item, position) => { props.editElement(item, position) }}
				buttonAction={(position) => { props.buttonAction(position) }}
				setData={(name, val, item) => { props.setData(name, val, item) }}
				onChange={(name, val, item) => { props.setData(name, val, item) }}
				// filledData={this.state.dataFilled}
				filledData={props.filledData}
                outerIndex={outerIndex} index={index}
                rearrangeElements={props.rearrangeElements}
                // rearrangeElements={(selectedElement, selectedPosition) => {
				// 	if (selectedElement == null) {
				// 		this.setState({ newElementPosition: selectedPosition })
				// 	}
				// 	; this.rearrangeElements(selectedElement, selectedPosition)
				// }}
                />
            : <FormComplexElements gridSize={gridSize} item={item} key={index}
            deleteElement={(position) => { props.deleteElement(position) }}
            innerFormUpdateMultiple={(itemPosition, jsonCopy, modalData, innerIndex) => {
                props.innerFormUpdateMultiple(itemPosition, jsonCopy, modalData, innerIndex);
            }}
            innerFormUpdate={(itemPosition, jsonCopy, modalData) => {
                props.innerFormUpdate(itemPosition, jsonCopy, modalData);
            }}
            editElement={(item, position) => { props.editElement(item, position) }}
            buttonAction={(position) => { props.buttonAction(position) }}
            setData={(name, val, item) => { props.setData(name, val, item) }}
            onChange={(name, val, item) => { props.setData(name, val, item) }}
            // filledData={this.state.dataFilled}
            filledData={props.filledData}
            outerIndex={outerIndex} index={index}
            rearrangeElements={props.rearrangeElements}
            // rearrangeElements={(selectedElement, selectedPosition) => {
            // 	if (selectedElement == null) {
            // 		this.setState({ newElementPosition: selectedPosition })
            // 	}
            // 	; this.rearrangeElements(selectedElement, selectedPosition)
            // }}
            />
		})
    }
    
    export default GenerateRow