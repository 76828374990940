import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { withRouter } from 'react-router-dom';
import View from './View'
import Redux from './Redux'
import { updateTableData, setDatafromApi } from 'packages/common'
import sharedJSON from 'packages/sharedJSON'
import {
	getFilteredArray,
	handleSelectedRow,
	isRowselected,
	requestSort,
	handleTableSelectAllClick,
} from 'modules/common';
import ConfirmDialog from '../confirmDialog'


const Container = (props) => {
	const [state, setState] = useState({
		...sharedJSON
	})
	const [open, setOpen] = React.useState(false);
	const [role, setRole] = React.useState({});

	const handleChangePage = (event, page) => {
		setState({ ...state, page: page });
	};

	const handleChangeRowsPerPage = event => {
		setState({ ...state, rowsPerPage: event.target.value, page: 0 });
	};

	const onRowClick = (rowData, rowMeta) => {
		console.log('onRowClick');
	}

	const searchHandler = (e) => {
		setState({ ...state, searchText: e ? e.target.value : "" });
	}

	const handleInputChange = (event) => {
		let obj = state.filterTableData;
		let list = obj.columns;
		list.map((item, index) => {
			if (item.name === event.target.value) list[index].checked = event.target.checked
		})
		obj.columns = [...list]
		setState({ ...state, filterTableData: obj });
		setState({ ...updateTableData(state) });
	}

	const resetFilters = (event) => {
		setState({ ...state, filterList: [] });
	}

	const handleDropdownChange = (event, index, column) => {
		const value = event.target.value === "All" ? [] : [event.target.value];
		// filterUpdate(index, value, column, 'dropdown');
		let list = state.filterList;
		list[index] = event.target.value;
		setState({ ...state, filterList: [...list] });
		setState({ ...updateTableData(state) });
	};

	const handleRequestSort = (event, property) => {
		let obj = requestSort(event, property, state);
		setState({ ...state, ...obj });
	};

	const handleSelectAllClick = event => {
		setState({ ...state, selected: handleTableSelectAllClick(event, state.filterTableData.datasets) });
	};


	const tableCrudOperations = (item, type) => {
		if (type === "edit") {
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/roles/${item._id}` });
		} else if (type === "delete") {
			setRole(item);
			setOpen(true);
		}
	}

	const handleAddRoleClick = () => {
		props.history.push({ pathname: `/builder/${props.match.params.dappId}/roles/new` });
	}

	const handleCheck = (event, id) => {
		const { selected } = state;
		let newSelected = handleSelectedRow(event, id, selected)
		setState({ ...state, selected: newSelected });
	};

	useEffect(() => {
		setState({ ...updateTableData(state) });
	}, [state.searchText, state.filterList]);

	useEffect(() => {
		if (props.roles.deleteSuccess) {
			props.showAlert('Role deleted successfully', { id: "role", variant: 'success', icon: 'bell' });
		}
	}, [props.roles.deleteSuccess])

	useEffect(() => {
		const params = props.match.params;
		const { dappId } = params;
		props.getRoles(dappId)
	}, [])

	useEffect(() => {
		const rows = [
			{
				id: 'description',
				numeric: false,
				disablePadding: false,
				label: 'Description',
				sort: true
			},
			{
				id: 'name',
				numeric: false,
				disablePadding: false,
				label: 'Name',
				sort: true
			}
		];
		let keysArr = ['_id', 'privileges', 'entityId', 'appId', '__v'];
		setState({ ...setDatafromApi(props.roles.data, keysArr, state, rows) });
	}, [props.roles.data]);

	return (
		<>
			<View
				{...props}
				state={state}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				onRowClick={onRowClick}
				searchHandler={searchHandler}
				handleCheck={handleCheck}
				handleInputChange={handleInputChange}
				resetFilters={resetFilters}
				handleDropdownChange={handleDropdownChange}
				handleRequestSort={handleRequestSort}
				handleSelectAllClick={handleSelectAllClick}
				tableCrudOperations={tableCrudOperations}
				handleAddRoleClick={handleAddRoleClick}
			/>
			<ConfirmDialog
				title="Delete"
				open={open}
				setOpen={setOpen}
				onConfirm={() => props.deleteRole({ _id: role._id, appId: props.match.params.dappId })}>
				role {role.name}
			</ConfirmDialog>
		</>
	)
}

export default hot(withRouter(Redux(Container)));
