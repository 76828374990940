import { ActionTypes } from 'constants/index';

const initialState = {
  data: [],
  searchText: '',
  error: '',
  deleteSuccess: false,
};

const groupsReducer = function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_GROUPS_SUCCESS: {
      return {
        ...state,
        data: action.payload?.data,
        deleteSuccess: false,
      };
    }

    case ActionTypes.GET_GROUPS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case ActionTypes.DELETE_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: [...state.data.filter(item => item._id !== action.payload._id)],
        deleteSuccess: true,
      };
    }

    case ActionTypes.DELETE_GROUP_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default groupsReducer;
