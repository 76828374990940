import React from 'react';
import { Button, Grid, FormLabel } from '@material-ui/core';

const LinksTable = (props) => {
	console.log("props", props, props.links)
	console.log("props", Array.isArray(props.links));
	if (props.links
		&& Array.isArray(props.links)) {
		return props.links.map((l, index) => {
			if (!l.editMode) {
				console.log("l", l);
				let page = props.pages.find(p => {
					return p._id === l.selectedLinkValue
				})
				return (
					<Grid container key={index}>
						<Grid style={{ "padding": "10px" }} xs={3}>
							<FormLabel >{l.selectedLinkDataElement}</FormLabel>
						</Grid>
						<Grid style={{ "padding": "10px" }} xs={2}>
							<FormLabel >{l.selectedLinkType}</FormLabel>
						</Grid>
						<Grid style={{ "padding": "10px" }} xs={3}>
							<FormLabel >{l.selectedLinkType === "page"
								? (page ? page.name : <b style={{ color: "red" }}>Page may got deleted</b>)
								: l.selectedLinkValue}
							</FormLabel>
						</Grid>
						<Grid style={{ "padding": "10px" }} xs={3}>
							<Button variant="contained" color="secondary" onClick={() => {
								props.editLink(l, index);
							}} >Edit</Button>
							<Button variant="contained" color="secondary" onClick={() => {
								props.deleteLink(l, index);
							}} >-</Button>
						</Grid>
					</Grid>
				)
			}
			else return ''
		})
	} else return ''
}
export default LinksTable;