import React from 'react';
// import ConnectionPoint from "./ConnectionPoint";
import Step from './Step';
import '../style/EditElement.css';
import ConnectionPoint from './ConnectionPoint';
import SuggestionRect from './SuggestionRect';

export default class Pool extends Step {
  render() {
    const {
      d = 'M8,0 L8 25',
      height,
      width,
      x,
      y,
      selectStep,
      stopDragging,
      setMouseOverStep,
      setClickStep,
      clickStep,
      editElementStep,
      onClickSugessionElement,
      moveArrow,
      name,
      setOnClickConnection,
      setMouseOverConnection,
      onDragConnection,
    } = this.props;
    const showConnectionPoints = clickStep;
    const halfHeight = height / 2;
    const halfWidth = width / 2;
    return (
      <g
        onMouseOver={setMouseOverStep}
        onMouseLeave={setMouseOverStep}
        onMouseDown={selectStep}
        onMouseUp={stopDragging}
        transform={`translate(${x},${y})`}
      >
        <rect
          onClick={setClickStep}
          onDoubleClick={editElementStep}
          // onMouseMove={moveArrow}
          height={height}
          style={{
            fill: 'white',
            fontSize: 14,
            stroke: '#000',
            strokeWidth: 2,
          }}
          width={width}
          // rx="6"
        />

        <path
          d={d}
          // style={style}
          style={{
            fill: 'white',
            fontSize: 14,
            stroke: '#000',
            strokeWidth: 2,
          }}
        />
        <text className="pool-text">{name}</text>
        {showConnectionPoints && (
          <SuggestionRect
            type={'pool'}
            x={width - 20}
            y={-10}
            height={100}
            width={50}
            onClickSugessionElement={onClickSugessionElement}
          />
        )}
        {showConnectionPoints ? (
          <ConnectionPoint
            onDragConnection={e => onDragConnection(e, 'left')}
            setMouseOverConnection={setMouseOverConnection}
            cx={0}
            cy={halfHeight}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            onDragConnection={e => onDragConnection(e, 'top')}
            setMouseOverConnection={setMouseOverConnection}
            cx={halfWidth}
            cy={0}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            onDragConnection={e => onDragConnection(e, 'right')}
            setMouseOverConnection={setMouseOverConnection}
            cx={width}
            cy={halfHeight}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            onDragConnection={e => onDragConnection(e, 'bottom')}
            setMouseOverConnection={setMouseOverConnection}
            onClick={setClickStep}
            cx={halfWidth}
            cy={height}
            setOnClickConnection={setOnClickConnection}
          />
        ) : null}
      </g>
    );
  }
}

Pool.defaultProps = Step.defaultProps;
