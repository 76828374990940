export default {

	GET_FORMULAS: undefined,
	GET_FORMULAS_SUCCESS: undefined,
	GET_FORMULAS_FAILURE: undefined,

	GET_FORMULA: undefined,
	GET_FORMULA_SUCCESS: undefined,
	GET_FORMULA_FAILURE: undefined,

	SAVE_FORMULA: undefined,
	SAVE_FORMULA_SUCCESS: undefined,
	SAVE_FORMULA_FAILURE: undefined,

	UPDATE_FORMULA: undefined,
	UPDATE_FORMULA_SUCCESS: undefined,
	UPDATE_FORMULA_FAILURE: undefined,

	DELETE_FORMULA: undefined,
	DELETE_FORMULA_SUCCESS: undefined,
	DELETE_FORMULA_FAILURE: undefined,
}