/**
 * @module Sagas/Entities
 * @desc Entities
 */

import { ActionTypes } from 'constants/index';
import { FormulaService } from 'services/index';
import { put, takeEvery, call } from 'redux-saga/effects';

/**
 * Entities
 *
 * @param {Object} action
 *
 */

async function fetchAsync(func, arg) {
  const response = arg ? await func(arg) : await func();
  return response;
}

function* getFormula(action) {
	try {
		const formulas = yield fetchAsync(FormulaService.getFormula, action.payload)
		yield put({ type: ActionTypes.GET_FORMULA_SUCCESS, payload: formulas })
	} catch (e) {
		yield put({ type: ActionTypes.GET_FORMULA_FAILURE, error: e.response })
	}
}

function* getFormulas(action) {
  try {
    const formulasResp = yield call(fetchAsync, FormulaService.getFormulas, action.payload);
    yield put({ type: ActionTypes.GET_FORMULAS_SUCCESS, payload: formulasResp.result.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_FORMULAS_FAILURE, error: e.message })
  }
}


function* saveFormula(action) {
  try {
    const entities = yield fetchAsync(FormulaService.saveFormula, action.payload)
    yield put({ type: ActionTypes.SAVE_FORMULA_SUCCESS, payload: entities })
  } catch (e) {
    let errObj = { name: false, formula: false };
    if (e.response && errObj) {
      errObj.errorMessage = e.response.message ? e.response.message : "";
      errObj.status = e.response.status ? e.response.status : ""
    }
    yield put({ type: ActionTypes.SAVE_FORMULA_FAILURE, error: errObj })
  }
}

function* updateFormula(action) {
  try {
    const formula = yield call(fetchAsync, FormulaService.updateFormula, action.payload)
    yield put({ type: ActionTypes.UPDATE_FORMULA_SUCCESS, payload: formula })
  } catch (e) {
    let errObj = { name: false, formula: false };
    if (e.response) {
      errObj.errorMessage = e.response.message ? e.response.message : "";
      errObj.status = e.response.status ? e.response.status : ""
    }
    yield put({ type: ActionTypes.UPDATE_FORMULA_FAILURE, error: errObj })
  }
}

function* deleteFormula(action) {
  try {
    const formula = yield call(fetchAsync, FormulaService.deleteFormula, action.payload)
    yield put({ type: ActionTypes.DELETE_FORMULA_SUCCESS, payload: formula })
  } catch (e) {
    yield put({ type: ActionTypes.DELETE_FORMULA_FAILURE, error: e.message })
  }
}


export function* watchFormulasSaga() {
  yield takeEvery(ActionTypes.GET_FORMULA, getFormula);
  yield takeEvery(ActionTypes.GET_FORMULAS, getFormulas);
  yield takeEvery(ActionTypes.SAVE_FORMULA, saveFormula);
  yield takeEvery(ActionTypes.UPDATE_FORMULA, updateFormula);
  yield takeEvery(ActionTypes.DELETE_FORMULA, deleteFormula);
}

export default watchFormulasSaga;
