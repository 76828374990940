import React from 'react'
import {
	TableContainer, Button, Paper, Grid, Typography, Box, makeStyles
} from '@material-ui/core'
import 'styles/styles.scss'
import rightarrow from '../../images/rightarrow.svg'
import CustomTable from '../@table/Table'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}))

const exportArray = [{ key: 'xls', name: "Export as Excel", isVisible: true },
{ key: 'csv', name: "Export as csv", isVisible: true },
{ key: 'txt', name: "Export as text", isVisible: true },
/**{ key: 'image', name: "Export as image", isVisible: true }**/]

const View = (props) => {
	const classes = useStyles()
	return (<Box className="abcCompany-page">
		<Box className="pxy20">
			<Grid container>
				<Grid item xs={12} md={6}>
					<div className="backbtn">
						<div className="backbtn">
							<img src={rightarrow} alt="backicon" className="backicon" />
							<Box component="h3" className="companyheadingtext">
								<Typography variant="h4">Datasources</Typography>
							</Box>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} md={6} className="savebtn-sec">
					<Button variant="contained" color="primary" className="addnewentrybtn mb5"
						onClick={() => props.handleAddClick()}>
						{"ADD DATASOURCE"}
					</Button>
				</Grid>
				<TableContainer component={Paper}>
					<CustomTable onRowClick={props.onRowClick}
						name={"Roles"}
						{...props}
						{...props.state}
						searchHandler={props.searchHandler}
						isExport={true}
						isViewCoulmns={true}
						isSearch={true}
						isFilter={true}
						hasCheckbox={false}
						actions={{ edit: true, view: true, delete: true, copy: true }}
						isTablePagination={true}
						isTableHead={true}
						isTableSubHeader={false}
						handleInputChange={props.handleInputChange}
						resetFilters={props.resetFilters}
						handleDropdownChange={props.handleDropdownChange}
						handleRequestSort={props.handleRequestSort}
						handleSelectAllClick={props.handleSelectAllClick}
						tableCrudOperations={props.tableCrudOperations}
						handleCheck={props.handleCheck}
						handleChangePage={props.handleChangePage}
						handleChangeRowsPerPage={props.handleChangeRowsPerPage}
						handleExoprtItemClick={props.handleExoprtItemClick}
						exportArray={exportArray}
						headerstyle={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.87)", backgroundColor: "#FFF", textAlign: "center" }}
						subHeaderstyle={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.87)", backgroundColor: "#FFF", textAlign: "center" }}
						rowStyle={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.87)", backgroundColor: "#FFF", row1BackgroundColor: "#FFF", row2BackgroundColor: "#FFF" }} />
				</TableContainer>
			</Grid>
		</Box>
	</Box>)
}

export default View
