import { ActionTypes } from 'constants/index'

const initialState = {
	data: null,
	loading: false,
	error: '',
	saveSuccess: false,
	updateSuccess: false
};

const jobReducer = function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_JOB_SUCCESS: {
			return {
				...state,
				data: action.payload,
				saveSuccess: false,
				updateSuccess: false
			};
		}
		case ActionTypes.GET_JOB_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error
				};
			}
		case ActionTypes.SAVE_JOB_SUCCESS: {
			return {
				...state,
				data: action.payload,
				saveSuccess: true
			};
		}
		case ActionTypes.SAVE_JOB_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error
				};
			}
		case ActionTypes.UPDATE_JOB_SUCCESS: {
			return {
				...state,
				data: action.payload,
				updateSuccess: true
			};
		}
		case ActionTypes.UPDATE_JOB_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error
				};
			}
		default: {
			return state;
		}
	}
};

export default jobReducer;
