import { ActionTypes } from 'constants/index'

const initialState = {
    data: null,
    searchText: "",
    error: {
        name: false, formula: false,
        errorMessage: "",
        status: ""
    },
    loading: false,
    saveSuccess: false,
    updateSuccess: false
}

const formulaReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_FORMULA_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                saveSuccess: false,
                updateSuccess: false
            }
        }
        case ActionTypes.GET_FORMULA_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.SAVE_FORMULA_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload,
                saveSuccess: true,
            }
        }
        case ActionTypes.SAVE_FORMULA_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }
        case ActionTypes.UPDATE_FORMULA_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload,
                updateSuccess: true,
            }
        }
        case ActionTypes.UPDATE_FORMULA_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }
        default: {
            return state;
        }
    }
};

export default formulaReducer;
