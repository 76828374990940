import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import {
	Button, Dialog, AppBar, Tab, Tabs, DialogTitle, Grid, TextField, FormLabel, makeStyles, LinearProgress
} from '@material-ui/core'
import BasicInfo from './BasicInfo'
import Logic from './Logic'
import Redux from '../Redux'
import {
	addCondAndLink,
	editCondAndLink,
	deleteCondAndLink
} from 'packages/page/component.module';
const useStyles = makeStyles((theme) => ({
	arrowIcon: {
		height: 20,
		width: 14
	},
	arrowColor: {
		color: '#ffd700'
	},
	tab: {
		"width": "20%"
	},
	dialogPaper: {
		minHeight: '80vh',
		maxHeight: '80vh',
	},
	formControl: {
		margin: 8,
		minWidth: 120,
		padding: '10px',
	},
	formControl1: {
		margin: 8,
		minWidth: 200,
		padding: '10px',
	},
}))

const PropertiesDialog = (props) => {
	const [tabValue, setTabValue] = useState(0)
	const [state, setState] = useState({ dataElements: [], selectedDataElements: [], condition: {}, conditions: [] })
	const classes = useStyles()

	const handleChangeTab = (event, tabValue) => {
		setTabValue(tabValue)
	}

	const canBeUpdated = (event) => {
		// console.log('state.taskName', state.taskName, state.taskType);
		if (!state.taskName || !state.taskType)
			return false
		return true
	}

	const handleElements = elements => {
		setState({ ...state, selectedDataElements: elements })
	}

	const onChangeHandler = event => {
		console.log('event.target.name', event.target.name, event.target.value, event.target.type);
		let dataElements = []
		if ("dataModelId" === event.target.name) {
			props.dataModels.map((dataModel) => {
				if (dataModel._id === event.target.value) {
					dataElements = [...dataModel.dataElements]
				}
			});
			setState({ ...state, [event.target.name]: event.target.value, dataElements })
		} else {
			let s = { ...state, [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value }
			if (event.target.name === 'taskName')
				s.name = event.target.value
			setState({ ...s })
		}
	}

	const onConditionChangeHandler = event => {
		let condition = state.condition;
		condition[event.target.name] = event.target.value
		setState({ ...state, condition });
	}

	const addCondition = (condition) => {
		let conditions = addCondAndLink(condition, state.conditions);
		setState({ ...state, conditions, condition: { value: "", selectedConditionDataElement: {}, selectedConditionOperator: '', selectedConditionClause: '' } });
	}

	const editCondition = (condition, index) => {
		let cond = editCondAndLink(condition, index, state.conditions);
		setState({ ...state, conditions: cond.ary, condition: cond.obj });
	}

	const deleteCondition = (condition, index) => {
		let conditions = deleteCondAndLink(condition, index, state.conditions);
		setState({ ...state, conditions });
	}

	useEffect(() => {
		props.getDataModels(props.match.params.dappId)
		props.getBusinessRules(props.match.params.dappId)
	}, [])

	useEffect(() => {
		console.log('props.selectedStep', props.selectedStep);
		setState({ ...state, ...props.selectedStep })
	}, [props.selectedStep])

	return (
		<Dialog
			open={props.open}
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			classes={{ paper: classes.dialogPaper }}
			fullWidth={true}
			maxWidth={'md'}>
			<DialogTitle id="alert-dialog-title" position="static">
				{"Properties"}
			</DialogTitle>
			<AppBar position="static"
				className="tabPropertiesPanel">
				<Tabs
					aria-label="wrapped label tabs example"
					value={tabValue}
					onChange={handleChangeTab}>
					<Tab className="h-64 normal-case" label="Basic Info" />
					{/* <Tab className="h-64 normal-case" label="Data" /> */}
					<Tab className="h-64 normal-case" label="Logic" />
					{/* <Tab className="h-64 normal-case" label="Links" />
					<Tab className="h-64 normal-case" label="UI" />
					<Tab className="h-64 normal-case" label="Security & Access" /> */}
				</Tabs>
			</AppBar>
			<div
				id="componentDescriptionPane" >
				<div className="p-16 sm:p-24 max-w-2xl" >
					<form className="form" noValidate autoComplete="off" >
						{tabValue === 0 &&
							(<>
								<BasicInfo
									{...props}
									values={state}
									onChangeHandler={onChangeHandler}
									classes={classes}
									handleElements={handleElements}
								/>
							</>)
						}
						{tabValue === 1 &&
							(<>
								<Logic
									{...props}
									values={state}
									onChangeHandler={onChangeHandler}
									classes={classes}
									editCondition={editCondition}
									deleteCondition={deleteCondition}
									onConditionChangeHandler={onConditionChangeHandler}
									addCondition={addCondition}
								/>
							</>)
						}
						{tabValue === 2 &&
							(<>
							</>)
						}
						{tabValue === 3 &&
							(<></>
							)
						}
						{tabValue === 4 &&
							(<>
							</>)
						}
						{tabValue === 5 &&
							(<>
							</>
							)
						}
					</form>
				</div>
			</div>
			<Grid container spacing={8}>
				<Grid xs={8}></Grid>
				<Grid xs={4}>
					<Button variant="contained"
						color="primary"
						onClick={(e) => props.onUpdate(false, state)}
						disabled={!canBeUpdated()}>
						{"Update"}
					</Button>
					<Button variant="contained"
						style={{ marginLeft: "20px" }}
						color="primary"
						onClick={props.handleClose}>
						{"Cancel"}
					</Button>
				</Grid>
			</Grid>
		</Dialog>
	);
}

export default withRouter(Redux(PropertiesDialog))
