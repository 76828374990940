import React from 'react';
import { FormControl, TextField, FormLabel } from '@material-ui/core';

export default function FormulaBasicInfo(props) {
    const { name, description, handleChange } = props;
    const { error } = props;

    return (
        <div className="p-16 sm:p-24 max-w-2xl">
            <form className="form" noValidate autoComplete="off">
                <FormControl>
                    <FormLabel variant="h6" className="label-headings">
                        {"Name *"}
                    </FormLabel>
                    <TextField
                        required
                        fullWidth
                        id="name"
                        name="name"
                        value={name}
                        color="secondary"
                        variant="outlined"
                        className="form-control"
                        autoFocus
                        placeholder="Enter Formula Name"
                        error={name === ''}
                        error={Boolean(error.name)}
                        helperText={error.name && error.name}
                        onChange={({ target }) => handleChange('name', target.value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel variant="h6" className="label-headings">
                        {"Description"}
                    </FormLabel>
                    <TextField
                        required
                        fullWidth
                        id="description"
                        color="secondary"
                        name="description"
                        variant="outlined"
                        value={description}
                        className="form-control"
                        placeholder="Enter Formula Description"
                        onChange={({ target }) => handleChange('description', target.value)}
                    />
                </FormControl>
            </form>
        </div>
    );
}