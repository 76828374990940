import React from 'react'
import {
	Button, Checkbox, FormControl, FormControlLabel, FormLabel, NativeSelect, Radio,
	RadioGroup, Tab, Tabs, TextField, Typography, withStyles, Select, MenuItem, Input,
	AppBar, Dialog, DialogActions, DialogTitle, Grid
} from '@material-ui/core'

const UI = (props) => {
	return (<>
		<Grid container spacing={8} className="formPmodel">
			<Grid item xs={4} md={3}>
				<label>ClassName</label>
			</Grid>
			<Grid item xs={8}>
				<TextField
					label=""
					margin="dense"
					variant="filled"
					fullWidth
					value={props.elementsData.basic.className}
					onChange={(e) => { props.setBasic("className", e) }}
				/>
			</Grid>
			<Grid item xs={4} md={3}>
				<label>Custom Style</label>
			</Grid>
			<Grid item xs={8}>
				<TextField
					label=""
					margin="dense"
					variant="filled"
					fullWidth
					multiline
					inputProps={{ rows: 5 }}
					value={props.elementsData.basic.style}
					onChange={(e) => { props.setBasic("style", e) }}
				/>
			</Grid>
			<Grid item xs={4} md={3}>
				<label>Background Color</label>
			</Grid>

			<Grid item xs={8}>

				<input type="color" id="head" name="head"
					value={props.elementsData.ui.backgroundColor}
					onChange={(e) => { props.setUi('backgroundColor', e) }} />
			</Grid>
			{props.elementType == "expansionPanels" && (<>
				<Grid item xs={4} md={3}>
					<label>Show expand panel by default.</label>
				</Grid>

				<Grid item xs={8}>
					<Checkbox checked={props.elementsData.basic.showExpandPanel}
						value={true}
						onClick={(e) => { props.setBasic('showExpandPanel', e.target.checked) }}
					/>
				</Grid>
			</>)}

			{props.showKeyValueOptions && <>
				<Grid item xs={4}  md={3}>
					<label>Alignment</label>
				</Grid>
				<Grid item xs={8}>
					<RadioGroup name="align" row>
						<FormControlLabel control={
							<Radio value={"normal"}
								checked={props.elementsData.ui.alignment == 'normal'} />}
							onClick={(e) => { props.setUi('alignment', e) }} label="normal" />
						<FormControlLabel control={
							<Radio value={"inline"}
								checked={props.elementsData.ui.alignment == 'inline'}
								onClick={(e) => { props.setUi('alignment', e) }} />} label="inline" />
					</RadioGroup>
				</Grid>
			</>}
			{props.textField && <>
				<Grid item xs={4} md={3}>
					<label>Variant</label>
				</Grid>
				<Grid item xs={8}>
					<FormControl >
						<RadioGroup
							required={true}
							onChange={(e) => { props.setUi('variant', e) }} row>
							{["standard", "filled", "outlined"].map((el) => {
								return <FormControlLabel value={el} control={<Radio
									checked={props.elementsData.ui.variant == el} required />}
									label={el} />
							})}
						</RadioGroup>
					</FormControl>
				</Grid>
				<Grid item xs={4}  md={3}>
					<label>Label Position</label>
				</Grid>
				<Grid item xs={8}>
					<FormControl >
						<RadioGroup
							required={true}
							onChange={(e) => { props.setUi('labelPosition', e) }} row>
							{["start", "end", "top"].map((el) => {
								return <FormControlLabel value={el}
									control={<Radio
										checked={props.elementsData.ui.labelPosition == el}
										required />}
									label={el} />
							})}
						</RadioGroup>
					</FormControl>
				</Grid>
			</>}
			<Grid item xs={4} md={3}>
				<label>Navigation Style</label>
			</Grid>
			<Grid item xs={8}>
				<RadioGroup name="align" row>
					<FormControlLabel control={
						<Radio value={"Default"}
							checked={props.elementsData.ui.navigationStyle == 'Default'}
							onClick={(e) => { props.setUi('navigationStyle', e) }} />}
						label="Default (Next/Back)" />
					<FormControlLabel control={
						<Radio value={"Click"}
							checked={props.elementsData.ui.navigationStyle == 'Click'} />}
						onClick={(e) => { props.setUi('navigationStyle', e) }} label="Click" />
				</RadioGroup>
			</Grid>
		</Grid>
	</>)
}

export default UI