import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { AppBar, DialogTitle, Tabs, Tab } from '@material-ui/core';
import '../style/modal.scss';
import Basicinfo from '../Properties/Basicinfo';
import Process from '../Properties/Process';
import UI from '../Properties/UI';
import Logic from '../Properties/Logic';
import Notification from '../Properties/Notification';
import Extension from '../Properties/Extensions';
import Access from '../Properties/Access';
import Datapipeline from '../Properties/Datapipeline';
import Workflow from '../Properties/Workflow';
import BusinessRule from '../Properties/BussinessRule';

const Modal = props => {
  const [value, setValue] = useState(0);
  const { selectedStep, classes } = props;
  const { subType, type } = props.selectedstep;

  return (
    <Dialog open={props.open} onClose={props.onClose} {...props} className="workflow">
      <DialogTitle className="title">Properties</DialogTitle>
      <AppBar position="static" className="tabPropertiesPanel">
        <Tabs value={value ? value : 0} onChange={(e, val) => setValue(val)}>
          <Tab label="Basic Info" />

          {(subType === 'usertask' || subType === 'systemtask' || type === 'pool') && [
            <Tab label="Process" />,
            <Tab label="UI" />,
            <Tab label="Logic" />,
            <Tab label="Notification" />,
            <Tab label="Extensions" />,
            <Tab label="Access" />,
          ]}

          {subType === 'sendtask' && [
            <Tab label="Notification" key={`${subType}-notification`} key="notification" />,
            <Tab label="Extensions" key="extensions" />,
            <Tab label="Access" key="access" />,
          ]}

          {subType === 'workflowtask' && [
            <Tab label="Workflow" />,
            <Tab label="Extensions" />,
            <Tab label="Access" />,
          ]}
          {subType === 'businesstask' && [<Tab label="Business Rule" />]}
          {subType === 'datapipelinetask' && [
            <Tab label="DataPipeline" />,
            <Tab label="Extensions" />,
            <Tab label="Access" />,
          ]}
        </Tabs>
      </AppBar>
      <DialogContent>
        <Basicinfo value={value} index={0} key={`${subType}-basicinfo`} {...props} />

        {(subType === 'usertask' || subType === 'systemtask' || type === 'pool') && [
          <Process value={value} index={1} key={`${subType}-process`} {...props} />,
          <UI value={value} index={2} key={`${subType}-ui`} {...props} />,
          <Logic value={value} index={3} key={`${subType}-logic`} {...props} />,
          <Notification
            value={value}
            index={4}
            key={`${subType || type}-notification`}
            {...props}
          />,
          <Extension value={value} index={5} key={`${subType}-extension`} {...props} {...props} />,
          <Access value={value} index={6} key={`${subType}-access`} {...props} {...props} />,
        ]}

        {subType === 'sendtask' && [
          <Notification value={value} index={1} key={`${subType}-notification`} {...props} />,
          <Extension value={value} index={2} key={`${subType}-extension`} {...props} />,
          <Access value={value} index={3} key={`${subType}-access`} {...props} />,
        ]}

        {subType === 'workflowtask' && [
          <Workflow value={value} index={1} key={`${subType}-workflow`} {...props} />,
          <Extension value={value} index={2} key={`${subType}-extension`} {...props} />,
          <Access value={value} index={3} key={`${subType}-access`} {...props} />,
        ]}

        {subType === 'businesstask' && [
          <BusinessRule value={value} index={1} key={`${subType}-businessrule`} {...props} />,
        ]}
        {subType === 'datapipelinetask' && [
          <Datapipeline value={value} index={1} key={`${subType}-datapipeline`} {...props} />,
          <Access value={value} index={3} key={`${subType}-access`} {...props} />,
        ]}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained"  onClick={props.onClose}>
          OK
        </Button>
        <Button color="primary"  variant="contained" onClick={props.onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
