import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, businessRule, dataModels }) => {
		console.log("businessRule", businessRule);
		return {
			user: user,
			navigation: navigation,
			businessRule: businessRule,
			dataModels: dataModels
		}
	}

	const mapDispatchToProps = {
		getBusinessRule: Actions.getBusinessRule,
		saveBusinessRule: Actions.saveBusinessRule,
		newBusinessRule: Actions.newBusinessRule,
		updateBusinessRule: Actions.updateBusinessRule,
		getDataModels: Actions.getDataModels,
		showAlert: Actions.showAlert
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux