import React from 'react';
import bindme from 'bindme';
import DeleteIcon from './delete.js';
import ArrowIcon from './right-arrow.js';
import '../style/EditElement.css';

export default class SuggestionRect extends React.Component {
  constructor(props) {
    bindme(super(props), 'onMouseLeave', 'onMouseOver');
    this.state = {
      focused: false,
    };
  }

  onMouseLeave(event) {
    this.props.setMouseOverConnection(event, false);
    this.setState({ focused: false });
    // this.props.onMouseLeave();
  }

  onMouseOver(event) {
    this.props.setMouseOverConnection(event, true);
    this.setState({ focused: true });
  }

  render() {
    const { id, x, y, height, width, type } = this.props;

    const halfHeight = height / 2;
    const halfWidth = width / 2;

    if (type === 'circle') {
      return (
        <>
          {/* <img src={deleteIcon} alt="delete-icon" className="icon"></img> */}
          <rect
            // onMouseDown={createArrow}
            x={x}
            y={y + 10}
            fill="white"
            height={60}
            // width={100}
            width={80}
            stroke="#000"
            strokeWidth={1}
            rx="6"
            stroke-dasharray="5,5"
          ></rect>

          <circle
            onClick={() => this.props.onClickSugessionElement('circle')}
            stroke="#000"
            strokeWidth={1}
            fill="white"
            x={x + width}
            y={y}
            r={10}
            cx={x + 15}
            cy={y + 25}
          />
          <circle
            onClick={() => this.props.onClickSugessionElement('twocircle')}
            stroke="#000"
            strokeWidth={1}
            fill="white"
            x={x + width}
            y={y}
            r={10}
            cx={x + 40}
            cy={y + 55}
          />
          <circle
            onClick={() => this.props.onClickSugessionElement('twocircle')}
            stroke="#000"
            strokeWidth={1}
            fill="white"
            x={x + width}
            y={y}
            r={8}
            cx={x + 40}
            cy={y + 55}
          />
          <rect
            // onMouseDown={createArrow}
            onClick={() => this.props.onClickSugessionElement('rect')}
            x={x + 6}
            y={y + 45}
            fill="white"
            height={20}
            width={20}
            stroke="#000"
            strokeWidth={1}
            rx="4"
          ></rect>
          <path
            onClick={() => this.props.onClickSugessionElement('diamond')}
            fill="white"
            height={30}
            width={30}
            stroke="#000"
            strokeWidth={1}
            d="M110,15 L122,4 L134,15 L122,26 Z"
          />
          {/* <ellipse
              onClick={() => this.props.onClickSugessionElement('ellipse')}
              cx="151"
              cy="15"
              rx="12"
              ry="8"
              fill="white"
              stroke="#000"
              strokeWidth={1}
            /> */}

          {/* <ArrowIcon x="150" y="35" onClick={() => this.props.onClickSugessionElement('flow')} /> */}
          <ArrowIcon x="137" y="5" onClick={() => this.props.onClickSugessionElement('flow')} />
          <DeleteIcon
            x={x + 52}
            y={y + 46}
            onClick={() => this.props.onClickSugessionElement('delete')}
          />
        </>
      );
    } else if (type === 'decision') {
      return (
        <>
          <rect
            // onMouseDown={createArrow}
            x={x}
            y={y + 10}
            fill="white"
            height={60}
            // width={100}
            width={80}
            stroke="#000"
            strokeWidth={1}
            rx="6"
            stroke-dasharray="5,5"
          ></rect>

          <circle
            onClick={() => this.props.onClickSugessionElement('circle')}
            stroke="#000"
            strokeWidth={1}
            fill="white"
            x={x + width}
            y={y}
            r={10}
            cx={x + 15}
            cy={y + 25}
          />
          <circle
            onClick={() => this.props.onClickSugessionElement('twocircle')}
            stroke="#000"
            strokeWidth={1}
            fill="white"
            x={x + width}
            y={y}
            r={10}
            cx={x + 40}
            cy={y + 55}
          />
          <circle
            onClick={() => this.props.onClickSugessionElement('twocircle')}
            stroke="#000"
            strokeWidth={1}
            fill="white"
            x={x + width}
            y={y}
            r={8}
            cx={x + 40}
            cy={y + 55}
          />
          <rect
            // onMouseDown={createArrow}
            onClick={() => this.props.onClickSugessionElement('rect')}
            x={x + 6}
            y={y + 45}
            fill="white"
            height={20}
            width={20}
            stroke="#000"
            strokeWidth={1}
            rx="4"
          ></rect>
          <path
            onClick={() => this.props.onClickSugessionElement('diamond')}
            fill="white"
            height={30}
            width={30}
            stroke="#000"
            strokeWidth={1}
            d="M100,15 L112,4 L124,15 L112,26 Z"
          />
          {/* <ellipse
              onClick={() => this.props.onClickSugessionElement('ellipse')}
              cx="151"
              cy="15"
              rx="12"
              ry="8"
              fill="white"
              stroke="#000"
              strokeWidth={1}
            /> */}

          {/* <ArrowIcon x="150" y="35" onClick={() => this.props.onClickSugessionElement('flow')} /> */}
          <ArrowIcon x="127" y="5" onClick={() => this.props.onClickSugessionElement('flow')} />
          <DeleteIcon
            x={x + 52}
            y={y + 46}
            onClick={() => this.props.onClickSugessionElement('delete')}
          />
        </>
      );
    } else if (type === 'pool') {
      return (
        <>
          <DeleteIcon
            x={x + 20}
            y={y + 10}
            onClick={() => this.props.onClickSugessionElement('delete')}
          />
        </>
      );
    } else {
      return (
        <>
          <rect
            // onMouseDown={createArrow}
            x={x}
            y={y + 10}
            fill="white"
            height={60}
            width={80}
            stroke="#000"
            strokeWidth={1}
            rx="6"
            stroke-dasharray="5,5"
          ></rect>
          <circle
            onClick={() => this.props.onClickSugessionElement('circle')}
            stroke="#000"
            strokeWidth={1}
            fill="white"
            x={x + width}
            y={y}
            r={10}
            cx={x + 15}
            cy={y + 25}
          />
          <circle
            onClick={() => this.props.onClickSugessionElement('twocircle')}
            stroke="#000"
            strokeWidth={1}
            fill="white"
            x={x + width}
            y={y}
            r={10}
            cx={x + 40}
            cy={y + 55}
          />
          <circle
            onClick={() => this.props.onClickSugessionElement('twocircle')}
            stroke="#000"
            strokeWidth={1}
            fill="white"
            x={x + width}
            y={y}
            r={8}
            cx={x + 40}
            cy={y + 55}
          />
          <rect
            // onMouseDown={createArrow}
            onClick={() => this.props.onClickSugessionElement('rect')}
            x={x + 6}
            y={y + 45}
            fill="white"
            height={20}
            width={20}
            stroke="#000"
            strokeWidth={1}
            rx="4"
          ></rect>
          <path
            onClick={() => this.props.onClickSugessionElement('diamond')}
            fill="white"
            height={30}
            width={30}
            stroke="#000"
            strokeWidth={1}
            d={`M${x + 30},${y + 25} L${x + 40},${y + 15} L${x + 50},${y + 25} L${x + 40},${y +
              35} Z`}
          />
          {/* <ellipse
          onClick={() => this.props.onClickSugessionElement('ellipse')}
          cx={x + 66}
          cy={y + 25}
          rx="12"
          ry="8"
          fill="white"
          stroke="#000"
          strokeWidth={1}
        /> */}
          <ArrowIcon x="165" y="5" onClick={() => this.props.onClickSugessionElement('flow')} />
          <DeleteIcon
            x={x + 52}
            y={y + 46}
            onClick={() => this.props.onClickSugessionElement('delete')}
          />
          {/* <ArrowIcon x="180" y="35" onClick={() => this.props.onClickSugessionElement('flow')} /> */}
        </>
      );
    }
  }
}

SuggestionRect.defaultProps = {
  r: 5,
};
