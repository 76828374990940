import React, { Component } from 'react'
import {
	FormControl, AppBar, Tabs, Tab, Typography,
	Card, CardContent, CardHeader
} from '@material-ui/core'
import InnerForm from '../InnerForm'
import classNames from 'classnames'

const CardElement = (props) => {
	let { item, visibility, classes } = props
	let selectedvalue = props.filledData[item.name];
	return (<>
		<Card className={classNames(classes.card)}
			style={{ color: item.color, visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}  >
			<CardHeader
				title={item.label}
				style={{ backgroundColor: '#000080', color: '#fff' }}
				classes={{
					title: classes.title,
				}}
			/>
			<CardContent>
				{visibility != 'hidden' && <InnerForm design={item.innerForm}
					viewOnly={props.viewOnly}
					setData={(name, val, item) => { props.setData(name, val, item) }}
					innerFormUpdate={(jsonCopy, modalData) => {
						props.innerFormUpdate(props.itemPosition, jsonCopy, modalData);
					}}
					modalData={item.innerFormModalData}
					filledData={props.filledData}
				/>}
			</CardContent>
		</Card>
	</>)
}

export default CardElement