import React, { Component } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const StyledDiv = styled('div')({
  '& .MuiFormLabel-root': {
    padding: 0,
    margin: 0,
    top: '-5px',
  },
});
class BasicInfo extends Component {
	render() {
		let { classes, values, handleChange, handleUIChange } = this.props;
		let s = {
			width: "30ch",
			margin: "8px"
		};
		return (<StyledDiv>
			<Typography className="text-16 sm:text-20 truncate Tbold">Header Configuration</Typography>
			<TextField
				className="mt-8 mb-16"
				label="Style Object"
				id="style"
				name="style"
				value={values.header.style}
				onChange={(e) => handleUIChange(e, "header")}
				variant="outlined"
				style={s}
			/>
			<TextField
				className="mt-8 mb-16"
				label="Class Name"
				id="className"
				name="className"
				value={values.header.className}
				onChange={(e) => handleUIChange(e, "header")}
				variant="outlined"
				style={s}
			/>
			<TextField
				className="mt-8 mb-16"
				label="BackGround Color"
				id="backgroundColor"
				name="backgroundColor"
				value={values.header.backgroundColor}
				onChange={(e) => handleUIChange(e, "header")}
				variant="outlined"
				style={s}
			/>
			<TextField
				id="fontColor"
				name="fontColor"
				label="Font Color"
				type="text"
				className={classes.formControl}
				value={values.header.fontColor}
				onChange={(e) => handleUIChange(e, "header")}
				variant="outlined"
				style={s}
			/>
			<Typography className="text-16 sm:text-20 truncate Tbold">Page Body Configuration</Typography>
			<TextField
				className="mt-8 mb-16"
				label="Style Object"
				id="style"
				name="style"
				value={values.body.style}
				onChange={(e) => handleUIChange(e, "body")}
				variant="outlined"
				style={s}
			/>
			<TextField
				className="mt-8 mb-16"
				label="Class Name"
				id="className"
				name="className"
				value={values.body.className}
				onChange={(e) => handleUIChange(e, "body")}
				variant="outlined"
				style={s}
			/>
			<TextField
				className="mt-8 mb-16"
				label="BackGround Color"
				id="backgroundColor"
				name="backgroundColor"
				value={values.body.backgroundColor}
				onChange={(e) => handleUIChange(e, "body")}
				variant="outlined"
				style={s}
			/>
			<TextField
				id="fontColor"
				name="fontColor"
				label="Font Color"
				type="text"
				className={classes.formControl}
				value={values.body.fontColor}
				onChange={(e) => handleUIChange(e, "body")}
				variant="outlined"
				style={s}
			/>
			<Typography className="text-16 sm:text-20 truncate Tbold">Footer Configuration</Typography>
			<TextField
				className="mt-8 mb-16"
				label="Style Object"
				id="style"
				name="style"
				value={values.footer.style}
				onChange={(e) => handleUIChange(e, "footer")}
				variant="outlined"
				style={s}
			/>
			<TextField
				className="mt-8 mb-16"
				label="Class Name"
				id="className"
				name="className"
				value={values.footer.className}
				onChange={(e) => handleUIChange(e, "footer")}
				variant="outlined"
				style={s}
			/>
			<TextField
				className="mt-8 mb-16"
				label="BackGround Color"
				id="backgroundColor"
				name="backgroundColor"
				value={values.footer.backgroundColor}
				onChange={(e) => handleUIChange(e, "footer")}
				variant="outlined"
				style={s}
			/>
			<TextField
				id="fontColor"
				name="fontColor"
				label="Font Color"
				type="text"
				className={classes.formControl}
				value={values.footer.fontColor}
				onChange={(e) => handleUIChange(e, "footer")}
				variant="outlined"
				style={s}
			/>
		</StyledDiv>
		)
	}
}
export default BasicInfo;
