/**
 * @module Actions/Application
 * @desc Application Actions
 */
import { ActionTypes } from 'constants/index';

export const getFormula = params => dispatch => {
  dispatch({
    type: ActionTypes.GET_FORMULA,
    payload: params,
  });
};

export const getFormulas = (appId) => dispatch => {
  return dispatch({ type: ActionTypes.GET_FORMULAS, payload: appId });
}

export const deleteFormula = (formula, appId) => dispatch => {
  return dispatch({ type: ActionTypes.DELETE_FORMULA, payload: { formula, appId } })
}

export const saveFormula = (data, user, appId) => dispatch => {
  let formula = data;
  formula.createdBy = user.id;
  dispatch({
    type: ActionTypes.SAVE_FORMULA,
    payload: { formula, appId },
  });
};

export const updateFormula = (data, user, appId) => dispatch => {
  let formula = data;
  formula.updatedBy = user.id;
  dispatch({
    type: ActionTypes.UPDATE_FORMULA,
    payload: { formula, appId },
  });
};

export const newFormula = () => dispatch => {
  const data = {
    name: '',
    description: '',
    formulaType: 'excel',
    formulaEval: '',
    formulaValue: ''
  };

  dispatch({
    type: ActionTypes.GET_FORMULA_SUCCESS,
    payload: data,
  });
};
