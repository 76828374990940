import expansion from 'images/forms-expansion.svg'
import header from 'images/forms-header.svg'
import textbox from 'images/forms-textbox.svg'
import text_box from 'images/forms-text-box.svg'
import textarea from 'images/forms-textarea.svg'
import addhtml from 'images/forms-addhtml.svg'
import label from 'images/forms-label.svg'
import radio from 'images/forms-radio.svg'
import checkbox from 'images/forms-checkbox.svg'
import select from 'images/forms-select.svg'
import multiselect from 'images/forms-multiselect.svg'
import switch_box from 'images/forms-switch.svg'
import date from 'images/forms-date.svg'
import button from 'images/forms-button.svg'
import dateandtime from 'images/forms-dateandtime.svg'
import number from 'images/forms-number.svg'

const elements = [{
	name: "basicElements",
	id: "basicElements",
	label: "Basic Elements",
	childrens: [{
		name: "header",
		id: "header",
		label: "Header Text",
		icon: header
	}, {
		name: "label",
		id: "label",
		label: "Label",
		icon: label
	}, {
		name: "textBox",
		id: "textBox",
		label: "Textbox",
		icon: [textbox, text_box]
	}, {
		name: "textArea",
		id: "textArea",
		label: "Textarea",
		icon: textarea
	}, {
		name: "radio",
		id: "radio",
		label: "Radio",
		icon: radio
	}, {
		name: "checkbox",
		id: "checkbox",
		label: "Checkbox",
		icon: checkbox
	}, {
		name: "select",
		id: "select",
		label: "Select",
		icon: select
	}, {
		name: "multiselect",
		id: "multiselect",
		label: "Multi Select",
		icon: multiselect
	}, {
		name: "password",
		id: "password",
		label: "Password",
		icon: addhtml
	}, {
		name: "email",
		id: "email",
		label: "Email",
		icon: addhtml
	}, {
		name: "tel",
		id: "tel",
		label: "Phone Number",
		icon: addhtml
	}, {
		name: "currency",
		id: "currency",
		label: "Currency",
		icon: addhtml
	}, {
		name: "number",
		id: "number",
		label: "Number",
		icon: number
	}, {
		name: "date",
		id: "date",
		label: "Date (mm/dd/yyyy)",
		icon: date
	}, {
		name: "time",
		id: "time",
		label: "Time",
		icon: dateandtime
	}, {
		name: "datetime-local",
		id: "datetime-local",
		label: "Date Time",
		icon: dateandtime
	}, {
		id: "button",
		label: "Button",
		icon: button
	}]
}, {
	name: "layout",
	id: "layout",
	label: "Layout Elements",
	childrens: [{
		name: "expansionPanels",
		id: "expansionPanels",
		label: "Expansion Panels",
		icon: expansion
	}, {
		name: "multiStepForm",
		id: "multiStepForm",
		label: "Multi Step Form",
		icon: addhtml
	}, {
		name: "tab",
		id: "tab",
		label: "Tab",
		icon: addhtml
	}, {
		name: "card",
		id: "card",
		label: "Card",
		icon: addhtml
	}]
}, {
	name: "advancedElements",
	id: "advancedElements",
	label: "Advanced Elements",
	childrens: [{
		name: "HTML",
		id: "HTML",
		label: "Add HTML Content",
		icon: addhtml
	}, {
		name: "switch",
		id: "switch",
		label: "Switch",
		icon: switch_box
	}, {
		name: "dataTable",
		id: "dataTable",
		label: "Data Table",
		icon: addhtml
	}, {
		name: "reportConfigurator",
		id: "reportConfigurator",
		label: "Report Configurator",
		icon: addhtml
	}, {
		name: "pageOrFormPicker",
		id: "pageOrFormPicker",
		label: "Page/Form List",
		icon: addhtml
	}, {
		name: "namePicker",
		id: "namePicker",
		label: "Name Pick List",
		icon: addhtml
	}, {
		name: "dataSelector",
		id: "dataSelector",
		label: "Data Selector",
		icon: addhtml
	}, {
		name: "file",
		id: "file",
		label: "File",
		icon: addhtml
	}, {
		name: "chip",
		id: "chip",
		label: "Chip",
		icon: addhtml
	}, {
		name: "multiLine",
		id: "multiLine",
		label: "Multi Line",
		icon: addhtml
	}, {
		name: "table",
		id: "table",
		label: "Table",
		icon: addhtml
	}, {
		name: "worksheet",
		id: "worksheet",
		label: "Worksheet",
		icon: addhtml
	}]
}]

export default elements