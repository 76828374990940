import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import 'styles/page-designer.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Highcharts from 'highcharts';
import Utils from 'packages/page/utils';
import _ from 'modules/lodash'
import {
	getMixedChartConfig,
	getDefaultMixedChart,
	generateMixedData
} from 'packages/page/highcharts/mixed.module';

const styles = theme => ({});

class PageDesignerMixedChart extends Component {
	constructor(props) {
		super(props);
		this.state = getDefaultMixedChart();
		this.state.id = `mixedchart-${Utils.generateGUID()}`;
	}


	highChartsRender() {
		let mixedConfig = getMixedChartConfig(this.state, this.state.id);
		Highcharts.chart(mixedConfig);
	}

	componentDidMount() {
		this.highChartsSeries();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(this.props.element, prevProps.element)) {
			this.highChartsSeries();
		}
	}

	highChartsSeries() {
		let updatedData = generateMixedData(this.props.element, this.state);
		this.setState({ ...updatedData }, () => {
			this.highChartsRender();
		})
	}

	render() {
		return (
			<div id={this.state.id} style={{ height: "inherit", width: "inherit" }}></div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(
			null,
			null
		)(PageDesignerMixedChart)
	)
);