/**
 * @module Sagas/Login
 * @desc App
 */
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { AppService } from 'services/index'
import { ActionTypes } from 'constants/index';
import jwtService from 'jwtService';

/**
 * Application
 *
 * @param {Object} action
 *
 */

async function fetchAsync(func, arg) {
	const response = await func(arg);
	return response;
}

function* getAppLogin(action) {
	try {
		const user = yield call(fetchAsync, jwtService.signInWithEmailAndPassword, action.payload);
		yield put({ type: ActionTypes.USER_LOGIN_SUCCESS });
		yield put({ type: ActionTypes.SET_USER_DATA, payload: user });
	} catch (e) {
		console.log("e", e);
		yield put({ type: ActionTypes.USER_LOGIN_FAILURE, error: e });
	}
}

function* resetPassword(action) {
	try {
		const user = yield call(fetchAsync, jwtService.resetPassword, action.payload);
		yield put({ type: ActionTypes.RESET_PASSWORD_SUCCESS, payload: user });
	} catch (e) {
		yield put({ type: ActionTypes.RESET_PASSWORD_FAILURE, error: e.message });
	}
}

function* isUserExist(action) {
    try {
        const userExist = yield call(fetchAsync, jwtService.isUserExist, action.payload);
        yield put({ type: ActionTypes.IS_USER_EXIST_SUCCESS, payload: userExist });
    } catch (e) {
        yield put({ type: ActionTypes.IS_USER_EXIST_FAILURE, error: e });
    }
}


export function* watchLoginSaga() {
	yield takeEvery(ActionTypes.USER_LOGIN, getAppLogin);
	yield takeEvery(ActionTypes.RESET_PASSWORD, resetPassword);
	yield takeEvery(ActionTypes.IS_USER_EXIST, isUserExist);
}

export default watchLoginSaga;
