import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography, Button, FormLabel, TextField } from '@material-ui/core'
import microsoftsqlserver from '../../images/microsoftsqlserver.svg';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function ConnectionInfo(props) {
    const classes = useStyles();

    return (
        <Box className="pxy20">
            <Grid container>
                <Box className="processtab-sec">
                    <Grid container>
                        <Grid item xs={12} className="savebtn-sec">
                            <Button variant="contained" color="primary" className="savebtn btnwidth" onClick={props.handleNext}>
                                SAVE
                                </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="selecteddata-grid">
                        <Box className="selecteddatasource-head" component="div">
                            <img src={microsoftsqlserver} alt="sql server logo" className="serverlogo" />
                            <Typography component="div" className="data-block">
                                <Box component="label" m={1}>
                                    <Typography variant="h5">Selected Data Source: </Typography>
                                </Box>
                                <Box component="h4" m={1}>
                                    <Typography variant="h5">My SQL Server</Typography>
                                </Box>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} className="connectionfeilds-sec">
                        <Typography component="div">
                            <form noValidate className="connectionfeilds">
                                <Grid container>
                                    <Grid item xs={12} md={3}>
                                        <FormLabel variant="h6" className="label-headings">
                                            Connection Name:
			          </FormLabel>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="email"
                                            name="email"
                                            autoComplete="email"
                                            placeholder="enter your connection name"
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormLabel variant="h6" className="label-headings">
                                            Server Ip:
			          </FormLabel>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="email"
                                            name="email"
                                            placeholder="enter your server ip adress"
                                            autoComplete="email"
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormLabel variant="h6" className="label-headings">
                                            Server Port:
			          </FormLabel>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="email"
                                            name="email"
                                            placeholder="enter your server port"
                                            autoComplete="email"
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormLabel variant="h6" className="label-headings">
                                            User Name:
			          </FormLabel>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="email"
                                            name="email"
                                            placeholder="enter your name"
                                            autoComplete="email"
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormLabel variant="h6" className="label-headings">
                                            Password:
			          </FormLabel>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="email"
                                            name="email"
                                            placeholder="enter your password"
                                            autoComplete="email"
                                            autoFocus
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </Typography>
                    </Grid>
                </Box>
            </Grid>
        </Box>

    );
}
