import View from './view';
import Redux from './redux';
import { hot } from 'react-hot-loader/root';
import sharedJSON from 'packages/sharedJSON';
import { withRouter } from 'react-router-dom';
import { setDatafromApi } from 'packages/common';
import ConfirmDialog from '../confirmDialog';
import React, { useEffect, useState } from 'react';

const Container = (props) => {
	const { history, match } = props;
	const [state, setState] = useState({
		...sharedJSON
	});

	const [open, setOpen] = React.useState(false);
	const [formula, setFormula] = React.useState({});

	const handleRequestSort = (event, property) => {
		let obj = requestSort(event, property, state);
		setState({ ...state, ...obj });
	};

	const onRowClick = (rowData, rowMeta) => {
	}

	const searchHandler = (e) => setState({ ...state, searchText: e ? e.target.value : "" });

	const handleSelectAllClick = event => {
		if (event.target.checked) {
			setState(state => ({ selected: state.data.map(n => n._id) }));
			return;
		}
		setState({ selected: [] });
	};

	const handleInputChange = (event) => {
		let obj = state.filterTableData;
		let list = obj.columns;
		list.map((item, index) => {
			if (item.name === event.target.value) list[index].checked = event.target.checked
		})
		obj.columns = [...list]
		setState({ ...state, filterTableData: obj });
		setState({ ...updateTableData(state) });
	}

	const handleCheck = (event, id) => {
		const { selected } = state;
		let newSelected = handleSelectedRow(event, id, selected)
		setState({ ...state, selected: newSelected });
	}

	const handleChangePage = (event, page) => setState({ ...state, page: page })

	const handleChangeRowsPerPage = event => setState({ ...state, rowsPerPage: event.target.value, page: 0 })

	const resetFilters = (event) => setState({ ...state, filterList: [] })

	const handleDropdownChange = (event, index, column) => {
		const value = event.target.value === "All" ? [] : [event.target.value];
		// filterUpdate(index, value, column, 'dropdown');
		let list = state.filterList;
		list[index] = event.target.value;
		setState({ ...state, filterList: [...list] });
		setState({ ...updateTableData(state) });
	};

	const tableCrudOperations = (formula, type) => {
		if (type === "edit") {
			history.push({ pathname: `/builder/${match.params.dappId}/formula/${formula._id}` });
		}
		if (type === "add") {
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/formula/new` });
		}
		if (type === "delete") {
			setFormula(formula);
			setOpen(true);
		}
	}

	useEffect(() => {
		const params = match.params;
		const { dappId } = params;
		props.getFormulas(dappId);
	}, []);

	useEffect(() => {
		if (props.formulas.deleteSuccess) {
			props.showAlert('Formula deleted successfully', { id: "formula", variant: 'success', icon: 'bell' });
		}
	}, [props.formulas.deleteSuccess])

	useEffect(() => {
		const rows = [
			{
				id: 'name',
				numeric: false,
				disablePadding: false,
				label: 'Formula Name',
				sort: true
			},
			{
				id: 'description',
				numeric: false,
				disablePadding: false,
				label: 'Description',
				sort: false
			},
			{
				id: 'formulaType',
				numeric: false,
				disablePadding: false,
				label: 'Formula Type',
				sort: true
			},
		];
		let keysArr = ['_id', '__v', 'updatedBy', 'updatedAt', 'isActive', 'formulaValue', 'formulaEval', 'createdBy', 'createdAt'];
		setState({ ...setDatafromApi(props.formulas.data, keysArr, state, rows) });
	}, [props.formulas.data]);

	const handleAddClick = () => history.push({ pathname: `/builder/${match.params.dappId}/formula/new` });

	return (
		<React.Fragment>
			<View
				{...props}
				state={state}
				onRowClick={onRowClick}
				handleCheck={handleCheck}
				resetFilters={resetFilters}
				searchHandler={searchHandler}
				handleAddClick={handleAddClick}
				handleChangePage={handleChangePage}
				handleInputChange={handleInputChange}
				handleRequestSort={handleRequestSort}
				tableCrudOperations={tableCrudOperations}
				handleDropdownChange={handleDropdownChange}
				handleSelectAllClick={handleSelectAllClick}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
			/>
			<ConfirmDialog
				title="Delete"
				open={open}
				setOpen={setOpen}
				onConfirm={() => props.deleteFormula(formula, match.params.dappId)}>
				formula {formula.name}
			</ConfirmDialog>
		</React.Fragment>
	)
}

export default hot(withRouter(Redux(Container)));