import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { withRouter } from 'react-router-dom';
import View from './view'
import Redux from './redux'
import { updateTableData, setDatafromApi } from 'packages/common'
import sharedJSON from 'packages/sharedJSON'
import ConfirmDialog from '../confirmDialog'
import { requestSort } from 'modules/common';


const Container = (props) => {
	const [state, setState] = useState({ ...sharedJSON })
	const [open, setOpen] = React.useState(false);
	const [datasource, setDatasource] = React.useState({});

	const handleChangePage = (event, page) => setState({ ...state, page: page })

	const handleChangeRowsPerPage = event => setState({ ...state, rowsPerPage: event.target.value, page: 0 })

	const searchHandler = (e) => setState({ ...state, searchText: e ? e.target.value : "" })

	const handleInputChange = (event) => {
		let obj = state.filterTableData
		let list = obj.columns
		list.map((item, index) => {
			if (item.name === event.target.value) list[index].checked = event.target.checked
		})
		obj.columns = [...list]
		setState({ ...state, filterTableData: obj })
		setState({ ...updateTableData(state) })
	}

	const handleAddClick = () => props.history.push({ pathname: `/builder/${props.match.params.dappId}/datasources/new` })

	const handleCheck = (event, id) => setState({ ...state, selected: handleSelectedRow(event, id, state.selected) })

	const resetFilters = (event) => setState({ ...state, filterList: [] })

	const handleDropdownChange = (event, index, column) => {
		let list = state.filterList
		list[index] = event.target.value
		setState({ ...state, filterList: [...list] })
		setState({ ...updateTableData(state) })
	}

	const handleRequestSort = (event, property) => setState({ ...state, ...(requestSort(event, property, state)) })

	const handleSelectAllClick = event => setState({ ...state, selected: handleTableSelectAllClick(event, state.filterTableData.datasets) })

	const tableCrudOperations = (item, type) => {
		if (type === "edit") {
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/datasources/${item._id}` })
		}
		if (type === "delete") {
			setDatasource(item);
			setOpen(true);
		}
	}

	useEffect(() => {
		if (props.datasources.deleteSuccess) {
			props.showAlert('Datasource deleted successfully', { id: "datasource", variant: 'success', icon: 'bell' });
		}
	}, [props.datasources.deleteSuccess])

	useEffect(() => {
		setState({ ...updateTableData(state) })
	}, [state.searchText, state.filterList])


	useEffect(() => {
		props.getDatasources(props.match.params.dappId)
	}, [])

	useEffect(() => {
		const rows = [
			{
				id: 'name',
				numeric: false,
				disablePadding: false,
				label: 'Name',
				sort: true
			},
			{
				id: 'description',
				numeric: false,
				disablePadding: false,
				label: 'Description',
				sort: true
			},
			{
				id: 'dsType',
				numeric: false,
				disablePadding: false,
				label: 'Datasource type',
				sort: true
			}
		];
		let keysArr = ['_id', 'noSQLServerConnection', 'noSQLServerDb', 'appId', '__v', 'prevFilePaths', 'sampledata']
		setState({ ...setDatafromApi(props.datasources.data, keysArr, state, rows) })
	}, [props.datasources.data])

	const onRowClick = (rowData, rowMeta) => {
		//TODO: need to remove
	}

	return (<>
		<View
			{...props}
			state={state}
			handleChangePage={handleChangePage}
			handleChangeRowsPerPage={handleChangeRowsPerPage}
			searchHandler={searchHandler}
			handleCheck={handleCheck}
			handleInputChange={handleInputChange}
			resetFilters={resetFilters}
			handleDropdownChange={handleDropdownChange}
			handleRequestSort={handleRequestSort}
			handleSelectAllClick={handleSelectAllClick}
			tableCrudOperations={tableCrudOperations}
			handleAddClick={handleAddClick}
			onRowClick={onRowClick} //TODO: need to remove
		/>
		<ConfirmDialog
			title="Delete"
			open={open}
			setOpen={setOpen}
			onConfirm={() => props.deleteDatasource({ _id: datasource._id, appId: props.match.params.dappId })}
		>
			datasource {datasource.name}
		</ConfirmDialog>
	</>)
}

export default hot(withRouter(Redux(Container)))