import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './View'
import Redux from './Redux'
import { withRouter } from 'react-router-dom'
import ConfirmDialog from '../confirmDialog'
import sharedJSON from 'packages/sharedJSON'
import { updateTableData, setDatafromApi } from 'packages/common'
import { requestSort } from 'modules/common';

const APPRENDERER_URL = process.env.REACT_APP_APPRENDERER_URL

const Container = (props) => {

	const [state, setState] = useState({
		...sharedJSON
	})
	const [open, setOpen] = React.useState(false);
	const [page, setPage] = React.useState({});

	useEffect(() => {
		props.getPages(props.match.params.dappId)
	}, [])

	const handleRequestSort = (event, property) => {
		let obj = requestSort(event, property, state);
		setState({ ...state, ...obj });
	};

	const addNewPageClick = (e) => props.openNewPageDialog()

	const handleSelectAllClick = event => {
		if (event.target.checked) {
			setState(state => ({ selected: state.data.map(n => n._id) }));
			return;
		}
		setState({ selected: [] });
	};

	const handleChangePage = (event, page) => setState({ ...state, page: page })

	const handleChangeRowsPerPage = event => setState({ ...state, rowsPerPage: event.target.value, page: 0 })

	const onRowClick = (rowData, rowMeta) => {
		console.log('onRowClick');
	}

	const searchHandler = (e) => setState({ ...state, searchText: e ? e.target.value : "" })

	const handleCheck = (event, id) => {
		const { selected } = state;
		let newSelected = handleSelectedRow(event, id, selected)
		setState({ ...state, selected: newSelected });
	};

	const resetFilters = (event) => setState({ ...state, filterList: [] })

	const handleInputChange = (event) => {
		let obj = state.filterTableData;
		let list = obj.columns;
		list.map((item, index) => {
			if (item.name === event.target.value) list[index].checked = event.target.checked
		})
		obj.columns = [...list]
		setState({ ...state, filterTableData: obj });
		setState({ ...updateTableData(state) });
	}

	const handleDropdownChange = (event, index, column) => {
		const value = event.target.value === "All" ? [] : [event.target.value];
		// filterUpdate(index, value, column, 'dropdown');
		let list = state.filterList;
		list[index] = event.target.value;
		setState({ ...state, filterList: [...list] });
		setState({ ...updateTableData(state) });
	};

	const tableCrudOperations = (item, type, event) => {
		if (type === "edit") {
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/pages/${item._id}` });
		}
		if (type === "add") {
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/pages/new` });
		}
		if (type === "view") {
			event && event.preventDefault()
			console.log(`${APPRENDERER_URL}/apps/${props.match.params.dappId}/${item.url}`);
			window.open(`${APPRENDERER_URL}/apps/${props.match.params.dappId}/${item.url}`, "_blank")
		}
		if (type === "delete") {
			setPage(item);
			setOpen(true);
		}
		console.log("item, type", item, type);
	}

	useEffect(() => {
		setState({ ...updateTableData(state) });
	}, [state.searchText, state.filterList]);

	useEffect(() => {
		const rows = [
			{
				id: 'name',
				numeric: false,
				disablePadding: false,
				label: 'Name',
				sort: true
			},
			{
				id: 'description',
				numeric: false,
				disablePadding: false,
				label: 'Description',
				sort: true
			}
		];
		let keysArr = ['_id', 'url', 'type', 'rank', '__v', 'pageLevelDataModel', 'isActive', 'hide', 'title', 'default', 'conditions', 'chart', 'appId', 'selectedRoles'];
		setState({ ...setDatafromApi(props.pages.data, keysArr, state, rows) });
	}, [props.pages.data]);

	console.log('setDatafromApi ===== >< =====');

	useEffect(() => {
		console.log('state === <> ===');
	}, [state])

	return (<>
		<View
			{...props}
			state={state}
			addNewPageClick={addNewPageClick}
			handleChangePage={handleChangePage}
			handleChangeRowsPerPage={handleChangeRowsPerPage}
			onRowClick={onRowClick}
			searchHandler={searchHandler}
			handleCheck={handleCheck}
			handleInputChange={handleInputChange}
			resetFilters={resetFilters}
			handleDropdownChange={handleDropdownChange}
			handleRequestSort={handleRequestSort}
			handleSelectAllClick={handleSelectAllClick}
			tableCrudOperations={tableCrudOperations}
		/>
		<ConfirmDialog
			title="Delete"
			open={open}
			setOpen={setOpen}
			onConfirm={() => props.deletePage({ appId: props.match.params.dappId, pageId: page._id })}
		>
			page {page.name}
		</ConfirmDialog>
	</>)
}

export default hot(withRouter(Redux(Container)))