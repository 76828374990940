import { request } from 'modules/client'
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export class FormulaService {
	static getFormula(data) {
		const uri = API_BASE_URL + "/formula/" + data.id;
		return request(uri, {
			method: 'GET',
			headers: {
				appId: data.appId
			}
		})
	}

	static getFormulas(appId) {
		const uri = API_BASE_URL + "/formula";
		return request(uri, {
			method: 'GET',
			headers: { 'appId': appId }
		});
	}
	static deleteFormula(data) {
		const uri = API_BASE_URL + "/formula/" + data.formula._id
		return request(uri, {
			method: 'DELETE',
			headers: { appId: data.appId }
		})
	}
	static saveFormula(data) {
		const uri = API_BASE_URL + "/formula";
		return request(uri, {
			method: 'POST',
			payload: data.formula,
			headers: { appId: data.appId }
		});
	}

	static updateFormula(data) {
		const uri = API_BASE_URL + "/formula/" + data.formula._id;
		return request(uri, {
			method: 'PUT',
			payload: data.formula,
			headers: { appId: data.appId }
		});
	}
}