import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import "styles/page-designer.css";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import _ from 'modules/lodash';
import { Divider, Typography } from '@material-ui/core';

import CollageType1 from './cardCollage/CollageType1'
import CollageType2 from './cardCollage/CollageType2'
import CollageType3 from './cardCollage/CollageType3'
import CollageType4 from './cardCollage/CollageType4'
import { generateCardBody } from 'packages/page/page.module';

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    closeIconContainer: {
        display: "flex",
        flexDirection: "row"
    },
    card: {
        maxWidth: 345,
        backgroundColor: '#cccccc'
    },
    media: {
        height: 140,
    },
});

class PageDesignerCardComponent extends Component {
    state = {
        body: 0
    };

    // componentDidUpdate(prevProps, prevState) {
    //     console.log('CARD this.props.element.chartData', JSON.stringify(this.props.element.chartData));
    //     console.log("his.props.element.chartData.metric", this.props.element.chartData.metric);
    //     if (!_.isEqual(this.props.element.chartData, prevProps.element.chartData) && this.props.element.chartData.data) {
    //         let { data, metric } = this.props.element.chartData
    //         this.setState({ body:  })
    //     }
    // }

    render() {
        let { error } = this.props.element.chartData;
        if (error) {
            return (
                <div className="errorDisplayCenter">{error}</div>
            );
        }
        else {
            console.log('====================================');
            console.log("this.props.element.chartData", this.props.element.chartData);
            console.log('====================================');
            let { selectedGroupingDataElement, groupingData, selectedDataElement, categoriesData,
                dataOrHTML, dataHandler, description, isCardCategories, categories,
                cardTemplateUI, metric, data } = this.props.element.chartData;
            console.log('====================================');
            console.log(this.props.element.chartData);
            console.log('====================================');
            console.log("body >>>???", body);

            let body = generateCardBody(metric ? metric : 'count', data);
            return (
                <div id={`CardContainer${this.props.element.chartUniqueId}`}
                    style={{ height: "inherit", width: "inherit", backgroundColor: "rgba(69, 127, 187, 0.3)" }}>
                    {
                        isCardCategories && categories && categories.length > 0 && categoriesData && categoriesData.length > 0
                            ? <> {categoriesData.length === 1
                                ?
                                <Typography gutterBottom variant="h5" component="h2"
                                    style={{ textAlign: "center", verticalAlign: "center" }}>
                                    <p >{categories[0].title}</p>
                                    <p > <b>{categoriesData[0].value}</b></p>
                                </Typography>
                                : <><Typography gutterBottom variant="h5"
                                    style={{
                                        textAlign: "center",
                                        fontSize: "2.0em",
                                        fontWeight: "bold"
                                    }}>
                                    <p >{categories[0].title}</p>
                                    <p >{categoriesData[0].value}</p>
                                </Typography>
                                    <Divider variant="middle" />
                                    <Typography gutterBottom variant="h5"
                                        style={{
                                            textAlign: "center",
                                            fontSize: "2.0em",
                                            fontWeight: "bold"
                                        }}>
                                        <p >{categories[1].title}</p>
                                        <p >{categoriesData[1].value}</p>
                                    </Typography></>
                            }</>
                            : <>

                                {((selectedGroupingDataElement && (selectedGroupingDataElement.length > 0
                                    || selectedGroupingDataElement.value))
                                    && (groupingData && (groupingData
                                        || groupingData.length > 0)))
                                    ? <>
                                        {
                                            cardTemplateUI === "collageType1"
                                            && <CollageType1 classes={this.props.classes}
                                                groupingData={groupingData}
                                                selectedGroupingDataElement={selectedGroupingDataElement}
                                                selectedDataElement={selectedDataElement} />
                                        }
                                        {
                                            cardTemplateUI === "collageType2"
                                            && <CollageType2 classes={this.props.classes}
                                                groupingData={groupingData}
                                                selectedGroupingDataElement={selectedGroupingDataElement}
                                                selectedDataElement={selectedDataElement} />
                                        }
                                        {
                                            cardTemplateUI === "collageType3"
                                            && <CollageType3 classes={this.props.classes}
                                                groupingData={groupingData}
                                                selectedGroupingDataElement={selectedGroupingDataElement}
                                                selectedDataElement={selectedDataElement} />
                                        }
                                        {
                                            cardTemplateUI === "collageType4"
                                            && <CollageType4 classes={this.props.classes}
                                                groupingData={groupingData}
                                                selectedGroupingDataElement={selectedGroupingDataElement}
                                                selectedDataElement={selectedDataElement} />
                                        }
                                    </>
                                    : <>{dataOrHTML
                                        && dataHandler === "data_html"
                                        ? <div dangerouslySetInnerHTML={{
                                            __html: dataOrHTML
                                        }} />
                                        : <Typography gutterBottom variant="h5" component="h2"
                                            style={{ textAlign: "center", fontSize: 100 }}>
                                            {body}
                                        </Typography>
                                    }
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {description}
                                        </Typography>
                                    </>
                                }</>
                    }
                </div>
            );
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // changeRowSizeForTableChartComponent: Actions.changeRowSizeForTableChartComponent
    }, dispatch);
}

export default withStyles(styles, { withTheme: true })(
    withRouter(connect(null, mapDispatchToProps)(PageDesignerCardComponent)));
