import React from 'react';
import { FormControl, FormLabel, Select, MenuItem } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import ChipSelect from 'components/@custom/ChipSelect';

const StyledDiv = styled('div')({
  '& .MuiInput-underline': {
    width: '100%',
    '&:after': {
      borderBottom: 'none',
    },
  },
});

function Filters(props) {
  const { dataModels, onChangeHandler,values,handleChipChange } = props;
  return (
    <div>
      {/* <FormControl>
        <FormLabel component="legend">{'Select Data Model'}</FormLabel>
        <StyledDiv>
          <Select
            value={values.selectedFilterDataModel}
            onChange={onChangeHandler()}
            inputProps={{
              name: 'selectedFilterDataModel',
              id: 'selectedFilterDataModel',
            }}
          >
        
            {dataModels !== null &&
              dataModels.map(dataModel => (
                <MenuItem key={dataModel._id} value={dataModel._id}>
                  {dataModel.name}
                </MenuItem>
              ))}
          </Select>
        </StyledDiv>
      </FormControl> */}

      <FormControl>
        <FormLabel component="legend">{'Select data Elements'}</FormLabel>
        <ChipSelect
          className="w-full my-16"
          value={values.selectedFilterDataElements}
          onChange={handleChipChange}
          textFieldProps={{
            InputLabelProps: {
              shrink: true,
            },
            variant: 'standard',
          }}
          options={values.filtersDataElements}
          isMulti
        />
      </FormControl>

      <FormControl>
        <FormLabel component="legend">{'Filter Position'}</FormLabel>
        <StyledDiv>
          <Select
            value={values.filterPosition}
            onChange={onChangeHandler()}
            inputProps={{
              name: 'filterPosition',
              id: 'filterPosition',
            }}
          >
            <MenuItem value="top">
              <em>Top</em>
            </MenuItem>
            <MenuItem value="right">
              <em>Right</em>
            </MenuItem>
            <MenuItem value="left">
              <em>Left</em>
            </MenuItem>
          </Select>
        </StyledDiv>
      </FormControl>
    </div>
  );
}

export default Filters;
