// @flow
/**
 * @module Actions/Alerts
 * @desc Alerts Actions
 */

import uid from 'nanoid';

import { ActionTypes } from 'constants/index';

export const switchMenu = (query) => dispatch => {
	dispatch({ type: ActionTypes.SWITCH_MENU, payload: { query } })
}

export const hideAlert = (id) => dispatch => {
	dispatch({ type: ActionTypes.HIDE_ALERT, payload: { id } })
}

export const showAlert = (message, options) => dispatch => {
	const timeout = options.variant === 'danger' ? 0 : 5
	let payload = {
		id: options.id || uid(),
		icon: options.icon,
		message,
		position: options.position || 'top-center',
		variant: options.variant || 'dark',
		timeout: typeof options.timeout === 'number' ? options.timeout : timeout,
	}
	dispatch({ type: ActionTypes.SHOW_ALERT, payload })
}