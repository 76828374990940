import React from 'react';
import { Button, Grid, FormLabel } from '@material-ui/core';

const ConditionsTable = (props) => {
	console.log("props", props, props.conditions)
	console.log("props", Array.isArray(props.conditions));
	if (props.conditions
		&& Array.isArray(props.conditions)) {
		return props.conditions.map((cond, index) => {
			if (!cond.editMode)
				return (
					<Grid container key={index}>
						<Grid style={{ "padding": "10px" }} xs={3}>
							<FormLabel >{cond.selectedConditionDataElement}</FormLabel>
						</Grid>
						<Grid style={{ "padding": "10px" }} xs={3}>
							<FormLabel >{cond.selectedConditionOperator}</FormLabel>
						</Grid>
						<Grid style={{ "padding": "10px" }} xs={2}>
							<FormLabel >{cond.value}</FormLabel>
						</Grid>
						<Grid style={{ "padding": "10px" }} xs={2}>
							<FormLabel >{cond.selectedConditionClause}</FormLabel>
						</Grid>
						<Grid style={{ "padding": "10px" }} xs={2}>
							<Button variant="contained" color="secondary" onClick={() => {
								props.editCondition(cond, index);
							}} >Edit</Button>
							<Button variant="contained" color="secondary" onClick={() => {
								props.deleteCondition(cond, index);
							}} >-</Button>
						</Grid>
					</Grid>
				)
			else return ''
		})
	} else return ''
}
export default ConditionsTable;