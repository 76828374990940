import React from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {
	const mapStateToProps = ({ dapp, user, roles, member }) => {
		return {
			dapp,
			user,
			roles,
			memberByUserId: member.memberByUserId,
			member,
		}
	}

	const mapDispatchToProps = {
		getDapp: Actions.getDapp,
		saveDapp: Actions.saveDapp,
		newDapp: Actions.newDapp,
		updateDapp: Actions.updateDapp,
		deleteDapp: Actions.deleteDapp,
		getRoles: Actions.getRoles,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert,
		currentDappId: Actions.currentDappId,
		currentDapp: Actions.currentDapp,
		getMemberByUserId: Actions.getMemberByUserId
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux