import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import 'styles/page-designer.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Highcharts from 'highcharts';
import Utils from 'packages/page/utils';
import _ from 'modules/lodash'
import {
	getApiDataFromDataUrl
} from 'services/page.service';
import {
	getBarChartConfig,
	getDefaultBarChart,
	generateBarData
} from 'packages/page/highcharts/bar.module';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const styles = theme => ({});

class PageDesignerBarChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...(getDefaultBarChart()),
			id: `bar-${Utils.generateGUID()}`,
			orientation: this.props.element.chartData.orientation
		}
	}

	highChartsRender() {
		const { chartData: { data } } = this.props.element;
		let barConfig = getBarChartConfig(this.state, this.state.id, this.state.orientation, data && data.stacked);
		Highcharts.chart(barConfig);
	}

	componentDidMount() {
		this.initiateGetApi();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(this.props.element, prevProps.element)
			&& JSON.stringify(this.props.element) !== JSON.stringify(prevProps.element)) {
			// this.highChartsSeries();
			this.initiateGetApi();
		}
	}

	highChartsSeries() {
		let s = generateBarData(this.props.element.chartData, this.state)
		this.setState({ ...s, orientation: this.props.element.chartData.orientation }, () => {
			this.highChartsRender();
		})
	}

	setDatafromApi(res, data) {
		let d = { ...data };
		let chartData = { ...this.props.element.chartData };
		let { data: { stacked } } = chartData;
		if (chartData.selectedDataModel && chartData.barXAxis && chartData.barYAxis) {
			d.labels = res.labels;
			if (chartData.groupByXAxis) {
				d.datasets = res.datasets;
			} else {
				if (!d.datasets)
					d.datasets = []
				if (!d.datasets[0])
					d.datasets[0] = {};
				if (stacked && !res.data) {
					d.datasets = res.datasets;
					d.stacked = true;
				} else {
					d.datasets[0].data = res.data;
					d.datasets[0].label = res.label;
				}
			}
		} else {
			d.labels = res.data.labels;
			d.datasets[0].data = res.data.datasets[0].data;
			d.datasets[0].label = res.data.datasets[0].label;
			d.datasets[0].backgroundColor = 'rgb(255, 99, 132)';
			d.datasets[0].fillColor = ["rgba(0,10,220,0.5)", "rgba(220,0,10,0.5)", "rgba(220,0,0,0.5)", "rgba(120,250,120,0.5)"];
			d.datasets[0].strokeColor = "rgba(220,220,220,0.8)";
			d.datasets[0].highlightFill = "rgba(220,220,220,0.75)";
			d.datasets[0].highlightStroke = "rgba(220,220,220,1)";
		}
		chartData.data = d;
		let s = generateBarData(chartData, this.state)
		this.setState({ ...s, orientation: this.props.element.chartData.orientation }, () => {
			this.highChartsRender();
		})
	}

	getApiDataFromDataUrl(url, data) {
		getApiDataFromDataUrl(url, this.props.match.params.dappId)
			.then(res => {
				this.setDatafromApi(res.data, data)
			})
	}

	initiateGetApi() {
		let { chartData } = this.props.element;
		let props = this.props;
		let { data: { stacked } } = chartData;
		if (chartData.selectedDataModel) {
			let targetURL = API_BASE_URL + `/dataconnect/${stacked ? 'line' : 'bar'}/` + chartData.selectedDataModel + "?";

			if (stacked) {
				targetURL += `&label=${chartData.barXAxis.value}`;
				targetURL += `&measure=${chartData.barYAxis.value}`;
			} else {
				targetURL += `&barXAxis=${chartData.barXAxis.value}`
				targetURL += `&barYAxis=${chartData.barYAxis.value}`
			}

			if (chartData.metric === 'count' && chartData.groupByXAxis) {
				targetURL += `&groupByXAxis=${chartData.groupByXAxis}`
			}

			targetURL += '&metric=' + chartData.metric;
			if (props.queryString) {
				targetURL += '&' + props.queryString;
			}
			if (chartData.datelabeltype) {
				targetURL += '&labeltype=date'
				targetURL += chartData.labelformat ? `&format=${chartData.labelformat}` : '&format=%Y-%m-%d';
			}

			if (chartData.conditions && chartData.conditions.length > 0) {
				let cond = JSON.stringify(chartData.conditions);
				targetURL += "&conditions=" + cond;
			}
			if (props.page && props.page.conditions && props.page.conditions.length > 0) {
				let cond = JSON.stringify(props.page.conditions);
				targetURL += "&pConditions=" + cond;
			}
			this.getApiDataFromDataUrl(targetURL, props.data);
		} else if (props.dataUrl) {
			this.getApiDataFromDataUrl(props.dataUrl, props.data);
		} else {
			let s = generateBarData(props.element.chartData, this.state)
			this.setState({ ...s, orientation: this.props.element.chartData.orientation }, () => {
				this.highChartsRender();
			})
		}
	}

	render() {
		return (
			<div id={this.state.id} style={{ height: "inherit", width: "inherit" }}></div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(
	withRouter(connect(null, null)(PageDesignerBarChart))
);
