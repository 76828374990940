import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { hot } from 'react-hot-loader/root'
import View from './View'
import Redux from './Redux'

const Container = (props) => {

	useEffect(() => {
		props.currentDappId(null)
	}, [])

	const handleClick = (e, title) => {
		if (title === 'TEMPLATES')
			props.history.push({ pathname: '/builder/dapps/addapptemplates' })
		else if (title === 'QUICK WIZARD')
			props.history.push({ pathname: '/builder/dapps/addappquickwizard' })
		else if (title === 'ADVANCED')
			props.history.push({ pathname: '/builder/dapps/new' })
		else if (title === 'UPLOAD A FILE')
			props.history.push({ pathname: '/builder/dapps/addappfiles' })
	}

	return <View
		{...props} handleClick={handleClick} />
}

export default hot(withRouter(Redux(Container)))