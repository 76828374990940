import React from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {
	const mapStateToProps = ({ dapps, user, dapp }) => {
		return {
			dapps: dapps,
			user: user,
			dapp: dapp
		}
	}

	const mapDispatchToProps = {
		getDapps: Actions.getDapps,
		deleteDapp: Actions.deleteDapp,
		currentDappId: Actions.currentDappId,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert,
		getCategories: Actions.getCategories,
		updateDapp: Actions.updateDapp,
		getDapp: Actions.getDapp,
		newDapp: Actions.newDapp
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux