/**
 * @module Sagas/Entities
 * @desc Entities
 */

import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { EntityService } from 'services/index';
import { ActionTypes } from 'constants/index';

/**
 * Entities
 *
 * @param {Object} action
 *
 */

async function fetchAsync(func, arg) {
  const response = arg ? await func(arg) : await func();
  return response;
}

function* getEntities(action) {
	try {
		const entities = yield fetchAsync(EntityService.getEntities, action.payload)
		yield put({ type: ActionTypes.GET_ENTITIES_SUCCESS, payload: entities })
	} catch (e) {
		console.error("e", e);
		yield put({ type: ActionTypes.GET_ENTITIES_FAILURE, error: e.response })
	}
}

function* getEntity(action) {
	try {
		console.log("fetchEntities saga", action);
		const entities = yield fetchAsync(EntityService.getEntity, action.payload)
		yield put({ type: ActionTypes.GET_ENTITY_SUCCESS, payload: entities })
	} catch (e) {
		yield put({ type: ActionTypes.GET_ENTITY_FAILURE, error: e.response })
	}
}

function* saveEntity(action) {
	try {
		const entities = yield fetchAsync(EntityService.saveEntity, action.payload)
		yield put({ type: ActionTypes.SAVE_ENTITY_SUCCESS, payload: entities })
	} catch (e) {
		let errObj = { name: false, industry: false, primaryContactName: false, primaryContactEmail: false, primaryContactPhone: false };
		if (e.response) {
			errObj = e.response.fields;
			errObj.errorMessage = e.response.message ? e.response.message : "";
			errObj.status = e.response.status ? e.response.status : ""
		}
		yield put({ type: ActionTypes.SAVE_ENTITY_FAILURE, error: errObj })
	}
}

function* updateEntity(action) {
	try {
		const entities = yield fetchAsync(EntityService.updateEntity, action.payload)
		yield put({ type: ActionTypes.UPDATE_ENTITY_SUCCESS, payload: entities })
	} catch (e) {
		let errObj = { name: false, industry: false, primaryContactName: false, primaryContactEmail: false, primaryContactPhone: false };
		if (e.response) {
			errObj = e.response.fields;
			errObj.errorMessage = e.response.message ? e.response.message : "";
			errObj.status = e.response.status ? e.response.status : ""
		}
		yield put({ type: ActionTypes.UPDATE_ENTITY_FAILURE, error: errObj })
	}
}

function* deleteEntity(action) {
  try {
    const entities = yield fetchAsync(EntityService.deleteEntity, action.payload);
    yield put({ type: ActionTypes.DELETE_ENTITY_SUCCESS, payload: entities });
  } catch (e) {
    yield put({ type: ActionTypes.DELETE_ENTITY_FAILURE, error: e.data });
  }
}

export function* watchEntitiesSaga() {
  yield takeEvery(ActionTypes.GET_ENTITIES, getEntities);
  yield takeEvery(ActionTypes.GET_ENTITY, getEntity);
  yield takeEvery(ActionTypes.SAVE_ENTITY, saveEntity);
  yield takeEvery(ActionTypes.UPDATE_ENTITY, updateEntity);
  yield takeEvery(ActionTypes.DELETE_ENTITY, deleteEntity);
}

export default watchEntitiesSaga;
