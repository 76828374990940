import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, dataModels }) => {
		return {
			user: user,
			navigation: navigation,
			dataModels: dataModels
		}
	}

	const mapDispatchToProps = {
		getDataModels: Actions.getDataModels,
		deleteDatamodel: Actions.deleteDataModel,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux;