import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Grid, Box, Typography } from '@material-ui/core'
import Visibilityicon from 'images/visibility-icon.svg'
import Rubbishicon from 'images/rubbish-icon.svg'
import PublishIcon from '@material-ui/icons/Publish';
import Editicon from 'images/edit-icon.svg'
import reappicon from 'images/company.svg'
import Loading from 'components/Loading'
import DappsHeader from './Header'
import 'styles/styles.scss'
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const APPRENDERER_URL = process.env.REACT_APP_APPRENDERER_URL

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	iconBox: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		height: 40,
		width: 40,
		borderRadius: '50%',
	}
}))

const DappsView = (props) => {
	const classes = useStyles()
	return (
		<Box>
			<div className="recent-apps" >
				<Grid container className={classes.root} >
					<DappsHeader {...props}></DappsHeader>
					<Box className="pxy20">
						<Grid item xs={12} className="recent-apps-bannerbg" style={{ marginTop: "70px" }}>
							<Grid item lg={6} md={6}>
								<Typography variant="h3">
									{"Driving accelerated value creation and cost savings at enterprises through Automation, Intelligence and Trust."}
								</Typography>
							</Grid>
						</Grid>
						<Grid item xs={12} className="">
							<Typography variant="h4">{"Applications"}</Typography>
						</Grid>
						<div className="recent-apps-card">
							{props?.dapps?.dapps?.length === 0 ? <Typography variant="h6" style={{ textAlign: 'center', color: '#999' }}>{"No applications found"}</Typography> : null}
							<Grid container spacing={2}>
								{props.dapps && props.dapps.dapps.length > 0 && props.dapps.dapps.map((dapp, index) => {
									let appURL = typeof (dapp.appViewURL) !== 'undefined'
										? dapp.appViewURL
										: `${APPRENDERER_URL}/apps/${dapp._id}`
									let isFilter = false;
									if (props.categoryFilterData.length === 0 && props.searchKeyword.length === 0) {
										isFilter = true;
									}

									if ((props.searchKeyword && props.searchKeyword.length > 0 && (dapp.title && (dapp.title.toLowerCase()).includes(props.searchKeyword.toLowerCase()) ||
										(dapp.subTitle && (dapp.subTitle.toLowerCase()).includes(props.searchKeyword.toLowerCase())) || (dapp.description && (dapp.description.toLowerCase()).includes(props.searchKeyword.toLowerCase())) ||
										(dapp.footer && (dapp.footer.toLowerCase()).includes(props.searchKeyword.toLowerCase()))))) {
										isFilter = true
									}

									if (props.categoryFilterData.length > 0) {
										let category = props.categoryFilterData;
										if ((dapp.footer && (dapp.footer.toLowerCase()).includes(category.toLowerCase()))
											|| (dapp.description && (dapp.description.toLowerCase()).includes(category.toLowerCase()))
											|| (dapp.subTitle && (dapp.subTitle.toLowerCase()).includes(category.toLowerCase()))
											|| (dapp.title && (dapp.title.toLowerCase()).includes(category.toLowerCase()))) {
											isFilter = true
										}
									}

									if (isFilter) {
										return (<Grid key={index} item sm={6} md={4} lg={3} xl={2}>
											<Paper>
												<Typography 
												style={{
													borderBottom: '1px solid #EEEEEE',
													// marginBottom: '15px'
												}}
												variant="h6">{dapp.title}<Typography variant="caption" style={{ float: "right" }}>{dapp.status}</Typography></Typography>

												<Typography variant="h6" style={{ fontSize: "16px !important" }}>{dapp.subTitle}</Typography>
												<Grid container>
													<Grid item xs={3}>
														<Box className={(dapp.logoId) ? "reapp-bg" : ""}>
															<img src={(dapp.logoId) ? `${API_BASE_URL}/logo/${dapp.logoId}` : reappicon} alt="" width="100px" />
														</Box>
													</Grid>
													<Grid item xs={9}><Typography>{dapp.description}</Typography></Grid>
												</Grid>
												<Grid container className="recent-app-icons">
													<Grid item xs={4}>
														<Paper className={classes.iconBox} onClick={(event) => {
															event.preventDefault()
															window.open(appURL, "_blank")
														}}>
															{typeof (appURL) && (
																<img src={Visibilityicon} alt="viewimg" />)}
														</Paper>
													</Grid>
													<Grid item xs={4}>
														<Paper className={classes.iconBox} onClick={() => props.editDapps(dapp._id)}>
															<img src={Editicon} alt="editimg" />
														</Paper>
													</Grid>
													<Grid item xs={4}>
														<Paper className={classes.iconBox} onClick={() => props.updateDappData(dapp)}>
															<PublishIcon style={{ maxWidth: "50px" }} />
														</Paper>
														{/* <Paper onClick={() => props.deleteDappData(dapp)}>
														<img src={Rubbishicon} alt="deleteimg" />
													</Paper> */}
													</Grid>
												</Grid>
											</Paper>
										</Grid>)
									}
								})}
							</Grid>
						</div>
					</Box>
				</Grid>
				{props.isLoading && <Loading />}
			</div>
		</Box>
	)
}

export default DappsView
