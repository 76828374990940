import React, { Component } from 'react';
import {
    TextField, FormControl, Select, FormControlLabel, Divider, FormGroup,
    MenuItem, Checkbox, Radio, Typography, RadioGroup, FormLabel, InputLabel, Grid
} from '@material-ui/core';
import 'styles/page-designer.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as Actions from 'actions';
import _ from 'modules/lodash';
import MixedChart from "./MixedChart";
import Categories from "./Categories";
import CategoriesTable from "./CategoriesTable";
import BarChartConfig from "./charts/BarChartConfig";
import ChipSelect from "components/@custom/ChipSelect"
import { styled } from '@material-ui/core/styles';
import StaticLinks from './StaticLinks';
import StaticLinksTable from './StaticLinksTable';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { LookupHandler } from './lookupHandler';
const StyledDiv = styled('div')({
    '& .MuiInput-underline': {
        width: '100%',
        '&:after': {
            borderBottom: 'none',
        },
    },
});

const linkOpenTypeOptions = [
    {
        _id: "1",
        value: "newTab",
        label: "New Tab",
    },
    {
        _id: "2",
        value: "sameTab",
        label: "Same Tab",
    },
    {
        _id: "3",
        value: "modal",
        label: "Modal",
    }
]
class Data extends Component {
    render() {
        let props = this.props;
        const { dataModels, classes, values, businessRules } = this.props;

        return (<>
            <div className="pagesModelData">
                <FormControl>
                    <FormLabel component="legend">Data Handler</FormLabel>
                    <RadioGroup
                        aria-label="Data Handler"
                        name="dataHandler"
                        className={classes.group}
                        label="Data Handler"
                        value={values.dataHandler ? values.dataHandler : ''}
                        onChange={props.onChangeHandler()}
                        row>
                        {/* <FormControlLabel value="data" control={<Radio color="primary" />} label="Data" labelPlacement="end" /> */}
                        {/* <FormControlLabel value="data_url" control={<Radio color="primary" />} label="Data URL" labelPlacement="end" /> */}
                        <FormControlLabel
                            value="data_models"
                            control={<Radio color="primary" />}
                            label="Data Models"
                            labelPlacement="end" />
                        <FormControlLabel
                            value="data_business_rules"
                            control={<Radio color="primary" />}
                            label="Business Rules"
                            labelPlacement="end" />
                        {this.props.chartType === Actions.card
                            && <FormControlLabel
                                value="data_html"
                                control={<Radio color="primary" />}
                                label="Data or HTML"
                                labelPlacement="end" />
                        }
                        <FormControlLabel
                            value="query"
                            control={<Radio color="primary" />}
                            label="Query"
                            labelPlacement="end" />
                        <FormControlLabel
                            value="json"
                            control={<Radio color="primary" />}
                            label="JSON"
                            labelPlacement="end" />
                        <FormControlLabel
                            value="lookup"
                            control={<Radio color="primary" />}
                            label="Lookup/Join"
                            labelPlacement="end" />
                        {this.props.chartType === Actions.links
                            && <FormControlLabel
                                value="static_links"
                                control={<Radio color="primary" />}
                                label="Static Links"
                                labelPlacement="end" />
                        }
                    </RadioGroup>
                </FormControl>
                {!props.checkForNullOrUndefined(dataModels)
                    && values.dataHandler === "data_models" && (<FormControl>
                        <FormLabel component="legend">{"Select Data Model"}</FormLabel>
                        <StyledDiv>
                            <Select
                                value={values.selectedDataModel ? values.selectedDataModel : ''}
                                onChange={props.onChangeHandler()}
                                inputProps={{
                                    name: 'selectedDataModel',
                                    id: 'selectedDataModel',
                                }}>
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {dataModels !== null && dataModels.map(dataModel => (
                                    <MenuItem key={dataModel._id} value={dataModel._id}>
                                        {dataModel.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </StyledDiv>
                    </FormControl>)}
                {/* {!props.checkForNullOrUndefined(dataModels)
                    && values.selectedDataModel
                    && values.dataHandler === "data_models" && (<>
                        <Conditions
                            values={values}
                            classes={classes}
                            onConditionChangeHandler={props.onConditionChangeHandler}
                            addCondition={props.addCondition}
                        />
                        <FormLabel component="legend">
                            Conditions Table
                    </FormLabel>
                        <ConditionsTable
                            conditions={values.conditions}
                            editCondition={props.editCondition}
                            deleteCondition={props.deleteCondition}
                        />
                    </>)} */}
                {(values.dataHandler === "data_html"
                    && this.props.chartType === Actions.card) && (
                        <FormControl>
                            <FormLabel component="legend">{"Data or HTML"}</FormLabel>
                            <TextField
                                className={classes.formControl + " mt-8 mb-16"}
                                id="dataOrHTML"
                                type="text"
                                name="dataOrHTML"
                                value={props.values.dataOrHTML}
                                onChange={props.onChangeHandler()}
                                multiline
                                rows={5}
                                variant="outlined"
                                fullWidth
                            />
                        </FormControl>
                    )}
                {(values.dataHandler === "query") && (
                    <FormControl>
                        <FormLabel component="legend">{"Query"}</FormLabel>
                        <TextField
                            className={classes.formControl + " mt-8 mb-16"}
                            id="query"
                            type="text"
                            name="query"
                            value={props.values.query}
                            onChange={props.onChangeHandler()}
                            multiline
                            rows={5}
                            variant="outlined"
                            fullWidth
                        /></FormControl>
                )}
                {(values.dataHandler === "json") && (
                    <FormControl>
                        <FormLabel component="legend">{"JSON"}</FormLabel>
                        <TextField
                            className={classes.formControl + " mt-8 mb-16"}
                            id="json"
                            type="text"
                            name="json"
                            value={JSON.stringify(props.values.json)}
                            onChange={props.onChangeHandler()}
                            multiline
                            rows={5}
                            variant="outlined"
                            fullWidth
                        /></FormControl>
                )}
                {!props.checkForNullOrUndefined(dataModels)
                    && values.dataHandler === "lookup" && (
                        <LookupHandler values={values} dataModels={dataModels} onChangeLookUpField={props.onChangeLookUpField}
                            allDataModels={props.allDataModels}
                            checkForNullOrUndefined={props.checkForNullOrUndefined} />
                    )}
                {(!props.checkForNullOrUndefined(dataModels)
                    && values.selectedDataModel
                    && values.dataHandler === "data_models"
                    && this.props.chartType !== Actions.card
                    && this.props.chartType !== Actions.barChart
                    && this.props.chartType !== Actions.listChart
                    && this.props.chartType !== Actions.simpleTable
                    && this.props.chartType !== Actions.summaryTable
                    && this.props.chartType !== Actions.links) && (
                        <FormControl>
                            <FormLabel component="legend">{"Select column for labels"}</FormLabel>
                            <ChipSelect
                                className="w-full my-16"
                                value={values.selectedDataLabels}
                                onChange={props.handleLabelsChipChange}
                                placeholder="Select column for labels"
                                textFieldProps={{
                                    InputLabelProps: {
                                        shrink: true
                                    },
                                    variant: 'standard'
                                }}
                                options={values.dataElements}
                                isMulti
                            /></FormControl>
                    )}

                {(!props.checkForNullOrUndefined(dataModels)
                    && values.selectedDataModel
                    && values.dataHandler === "data_models"
                    && this.props.chartType === Actions.barChart) && (
                        <>
                            <BarChartConfig
                                values={values}
                                classes={classes}
                                handleChipDataChange={props.handleChipDataChange}
                            />
                        </>
                    )}
                {(!props.checkForNullOrUndefined(dataModels)
                    && values.selectedDataModel
                    && values.dataHandler === "data_models"
                    && this.props.chartType !== Actions.card
                    && this.props.chartType !== Actions.barChart
                    && this.props.chartType !== Actions.links
                    && !values.loadChildData) && (
                        <FormControl>
                            <FormLabel component="legend">{"Select column for data"}</FormLabel>
                            <ChipSelect
                                className="w-full my-16"
                                value={values.selectedDataElement}
                                onChange={props.handleChipChange}
                                textFieldProps={{
                                    InputLabelProps: {
                                        shrink: true
                                    },
                                    variant: 'standard'
                                }}
                                options={values.dataElements}
                                isMulti
                            />
                        </FormControl>
                    )}
                {(!props.checkForNullOrUndefined(dataModels)
                    && values.selectedDataModel
                    && values.dataHandler === "data_models"
                    && this.props.chartType === Actions.listChart
                    && <><FormControl><FormControlLabel
                        className="my-16"
                        control={
                            <Checkbox
                                checked={values.loadChildData}
                                onChange={props.handleCheckboxChange('loadChildData')}
                                value="loadChildData"
                                color="primary"
                            />
                        }
                        label="Load child data of selected data element"
                    /></FormControl>
                        {values.loadChildData &&
                            <FormControl>
                                <FormLabel component="legend">{"please enter parent data element"}</FormLabel>
                                <TextField
                                    className="mt-8 mb-16"
                                    id="parentDataElement"
                                    name="parentDataElement"
                                    value={props.values.parentDataElement}
                                    onChange={props.onChangeHandler()}
                                    variant="outlined"
                                    fullWidth
                                /></FormControl>}
                    </>)}
                {(!props.checkForNullOrUndefined(dataModels)
                    && values.selectedDataModel
                    && values.dataHandler === "data_models"
                    && this.props.chartType === Actions.summaryTable)
                    && (<><FormControl>
                        <FormControlLabel
                            className="my-16"
                            control={
                                <Checkbox
                                    checked={values.isScore}
                                    onChange={props.handleCheckboxChange('isScore')}
                                    value="isScore"
                                    color="primary"
                                />
                            }
                            label="Score component in table"
                        /></FormControl>
                        {
                            values.isScore &&
                            <FormControl>
                                <FormLabel component="legend">{"Select score board column"}</FormLabel>
                                <ChipSelect
                                    className="my-16"
                                    value={values.scoreDataElement}
                                    onChange={props.handleScoreElementChange}
                                    placeholder=""
                                    textFieldProps={{
                                        label: 'Score Elements',
                                        InputLabelProps: {
                                            shrink: true
                                        },
                                        variant: 'standard'
                                    }}
                                    options={values.dataElements}
                                /></FormControl>

                        }
                    </>)
                }
                {(!props.checkForNullOrUndefined(dataModels)
                    && values.selectedDataModel
                    && values.dataHandler === "data_models"
                    && this.props.chartType === Actions.card) && (
                        <>
                            <br />
                            <Divider />
                            <br />
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.isCardCategories}
                                            onChange={props.handleCheckboxChange('isCardCategories')}
                                            value="isCardCategories"
                                            color="primary"
                                        />
                                    }
                                    label="Category based card loading"
                                /></FormControl>
                        </>
                    )
                }
                {(!props.checkForNullOrUndefined(dataModels)
                    && values.selectedDataModel
                    && values.dataHandler === "data_models"
                    && this.props.chartType === Actions.card
                    && values.isCardCategories) && (<div>
                        <FormLabel component="legend">{"Select categories based card loading"}</FormLabel>
                        <Categories
                            values={values}
                            classes={classes}
                            onCategoryChangeHandler={props.onCategoryChangeHandler}
                            addCategory={props.addCategory}
                        />
                        <FormLabel component="legend">{"Categories Table"}</FormLabel>
                        <CategoriesTable
                            categories={values.categories}
                            editCategory={props.editCategory}
                            deleteCategory={props.deleteCategory}
                        />
                    </div>)
                }
                {(!props.checkForNullOrUndefined(dataModels)
                    && values.selectedDataModel
                    && values.dataHandler === "data_models"
                    && this.props.chartType === Actions.card
                    && !values.isCardCategories) && (
                        <div>
                            <FormControl>
                                <FormLabel component="legend">{"Select a column for card data"}</FormLabel>
                                <ChipSelect
                                    className="w-full my-16"
                                    value={values.selectedDataElement}
                                    onChange={props.handleChipChange}
                                    placeholder="Select Numeric data element"
                                    textFieldProps={{
                                        InputLabelProps: {
                                            shrink: true
                                        },
                                        variant: 'standard'
                                    }}
                                    options={values.dataElements}
                                /></FormControl><FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.useGrouping}
                                            onChange={props.handleCheckboxChange('useGrouping')}
                                            value="useGrouping"
                                            color="primary"
                                        />
                                    }
                                    label="Use Grouping"
                                /></FormControl>
                            {values.useGrouping && (<>
                                <FormControl>
                                    <FormLabel component="legend">{"Select a column for grouping"}</FormLabel>
                                    <ChipSelect
                                        className="w-full my-16"
                                        value={values.selectedGroupingDataElement}
                                        onChange={props.handleGroupingChange}
                                        placeholder="Select Numeric data element"
                                        textFieldProps={{
                                            InputLabelProps: {
                                                shrink: true
                                            },
                                            variant: 'standard'
                                        }}
                                        options={values.dataElements}
                                    /></FormControl>
                            </>)}
                            {values.selectedGroupingDataElement
                                && <p style={{
                                    color: "rgba(0, 0, 0, 0.54)",
                                    margin: 0,
                                    fontSize: "1.1rem",
                                    marginTop: "3px",
                                    textAlign: "left",
                                    fontFamily: 'Muli,Roboto,"Helvetica",Arial,sans-serif',
                                    fontWeight: "400",
                                    lineHeight: "1.66"
                                }}
                                    id="filled-helperText-helper-text">
                                    {"Please select card template format in UI tab"}
                                </p>
                            }
                            {values.selectedGroupingDataElement === values.selectedDataElement
                                && <p
                                    id="outlined-error-helper-text-helper-text" style={{
                                        color: "red",
                                        margin: 0,
                                        fontSize: "1.2rem",
                                        marginTop: "3px",
                                        textAlign: "left",
                                        fontFamily: 'Muli,Roboto,"Helvetica",Arial,sans-serif',
                                        fontWeight: "400",
                                        lineHeight: "1.66"
                                    }}>
                                    {"Grouping data element and selected data element should not be same"}
                                </p>
                            }
                        </div>
                    )}
                {values.dataHandler === "data_business_rules" && (
                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                        <FormLabel component="legend">{"Select Business Rule"}</FormLabel>
                        <Select
                            id="demo-simple-select-outlined"
                            value={values.businessRules}
                            onChange={props.onChangeHandler()}
                            name="businessRules">
                            <MenuItem value="">
                                <em>Select Business Rule</em>
                            </MenuItem>
                            {businessRules && businessRules.length > 0
                                && businessRules.map(rule => (
                                    <MenuItem value={rule._id}>
                                        <em>{rule.name}</em>
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                )}
                {!values.isCardCategories
                    && !props.checkForNullOrUndefined(dataModels)
                    && values.selectedDataModel
                    && values.dataHandler === "data_models"
                    && this.props.chartType !== Actions.links
                    && <>
                        <FormControl fullWidth className="mt-8 mb-16">
                            <FormLabel component="legend">{"Metric"}</FormLabel>
                            {/* <InputLabel id="metric-label"></InputLabel> */}
                            <StyledDiv>
                                <Select
                                    labelId="metric-label"
                                    value={values.metric}
                                    onChange={props.onChangeHandler()}
                                    inputProps={{
                                        name: 'metric',
                                        id: 'metric',
                                    }}>
                                    <MenuItem value={""}>
                                        {"Please select a metric"}
                                    </MenuItem>
                                    <MenuItem value={"count"}>
                                        {"Count"}
                                    </MenuItem>
                                    <MenuItem value={"total"}>
                                        {"Total"}
                                    </MenuItem>
                                    <MenuItem value={"average"}>
                                        {"Average"}
                                    </MenuItem>
                                    <MenuItem value={"max"}>
                                        {"Max"}
                                    </MenuItem>
                                    <MenuItem value={"min"}>
                                        {"Min"}
                                    </MenuItem>
                                    <MenuItem value={"percentage"}>
                                        {"Percentage"}
                                    </MenuItem>
                                </Select>
                            </StyledDiv>
                        </FormControl>
                        {values.metric === "count" && <FormControl >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.groupByXAxis}
                                        onChange={props.handleCheckboxChange('groupByXAxis')}
                                        value="groupByXAxis"
                                        color="primary"
                                    />
                                }
                                label="group by X - axis value"
                            /></FormControl>
                        }
                    </>
                }
                {(this.props.chartType === Actions.pieChart
                    || this.props.chartType === Actions.barChart
                    || this.props.chartType === Actions.lineChart) && (<><FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.datelabeltype}
                                    onChange={props.handleCheckboxChange('datelabeltype')}
                                    value="datelabeltype"
                                    color="primary"
                                />
                            }
                            label="Labels are date type"
                        /></FormControl>
                        {values.datelabeltype && (<>
                            <FormControl>
                                <FormLabel component="legend">{"Date format"}</FormLabel>
                                <Select
                                    value={values.labelformat}
                                    onChange={props.onChangeHandler()}
                                    inputProps={{
                                        name: 'labelformat',
                                        id: 'labelformat',
                                    }}>
                                    <MenuItem value={"%m-%d-%Y"}>
                                        {"MM-DD-YYYY"}
                                    </MenuItem>
                                    <MenuItem value={"%M-%d-%Y"}>
                                        {"MMM-DD-YYYY"}
                                    </MenuItem>
                                    <MenuItem value={"%m-%Y"}>
                                        {"MM-YYYY"}
                                    </MenuItem>
                                    <MenuItem value={"%M-%Y"}>
                                        {"MMM-YYYY"}
                                    </MenuItem>
                                    <MenuItem value={"%m-%d"}>
                                        {"MM-DD"}
                                    </MenuItem>
                                    <MenuItem value={"%M-%d"}>
                                        {"MMM-DD"}
                                    </MenuItem>
                                    <MenuItem value={"%m"}>
                                        {"Month"}
                                    </MenuItem>
                                    <MenuItem value={"%Y"}>
                                        {"Year"}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </>)}
                    </>)}
                {values.dataHandler === "data"
                    && (
                        <>
                            {!props.checkForNullOrUndefined(values.rowsPerPage)
                                && this.props.chartType === Actions.simpleTable && (
                                    <FormControl>
                                        <FormLabel component="legend">{"No. of Rows"}</FormLabel>
                                        <TextField
                                            className="mt-8 mb-16"
                                            id="rowsPerPage"
                                            name="rowsPerPage"
                                            value={values.rowsPerPage}
                                            onChange={props.onChangeHandler()}
                                            variant="outlined"
                                            fullWidth
                                        /></FormControl>
                                )}
                            {!props.checkForNullOrUndefined(values.columns) && (
                                <FormControl>
                                    <FormLabel component="legend">{"Columns"}</FormLabel>
                                    <TextField
                                        className="mt-8 mb-16"
                                        id="columns"
                                        name="columns"
                                        value={values.columns}
                                        onChange={props.onChangeHandler()}
                                        variant="outlined"
                                        fullWidth
                                        disabled={values.dataUrl.trim() ? true : false}
                                    /></FormControl>
                            )}
                            {!props.checkForNullOrUndefined(values.labels) && (
                                <FormControl>
                                    <FormLabel component="legend">{"Labels"}</FormLabel>
                                    <TextField
                                        className="mt-8 mb-16"
                                        id="labels"
                                        name="labels"
                                        value={values.labels}
                                        onChange={props.onChangeHandler()}
                                        variant="outlined"
                                        fullWidth
                                        disabled={values.dataUrl.trim() ? true : false}
                                    /></FormControl>
                            )}

                            {!props.checkForNullOrUndefined(values.label) && (
                                <FormControl>
                                    <FormLabel component="legend">{"Label"}</FormLabel>
                                    <TextField
                                        className="mt-8 mb-16"
                                        id="label"
                                        name="label"
                                        value={values.label}
                                        onChange={props.onChangeHandler()}
                                        variant="outlined"
                                        fullWidth
                                        disabled={values.dataUrl.trim() ? true : false}
                                    /></FormControl>
                            )
                            }
                            <FormControl>
                                <FormLabel component="legend">{"Data"}</FormLabel>
                                <TextField
                                    className="mt-8 mb-16"
                                    id="data"
                                    name="data"
                                    value={values.data}
                                    onChange={props.onChangeHandler()}
                                    variant="outlined"
                                    fullWidth
                                    disabled={values.dataUrl.trim() ? true : false}
                                /></FormControl>
                        </>
                    )
                }
                {values.dataHandler === "data_url" &&
                    <FormControl>
                        <FormLabel component="legend">{"Data URL"}</FormLabel>
                        <TextField
                            className="mt-8 mb-16"
                            id="dataUrl"
                            name="dataUrl"
                            value={values.dataUrl}
                            onChange={props.onChangeHandler()}
                            variant="outlined"
                            fullWidth
                        /></FormControl>
                }
                {this.props.chartType === Actions.mixedChart
                    && !props.checkForNullOrUndefined(dataModels)
                    && values.selectedDataModel
                    && values.dataHandler === "data_models"
                    && <MixedChart
                        values={values}
                        onChangeHandler={props.onChangeHandler}
                        handleCheckboxChange={props.handleCheckboxChange}
                        checkForNullOrUndefined={props.checkForNullOrUndefined}
                        handleChange={props.onChangeHandler}
                        classes={classes}
                    />
                }
                {
                    this.props.chartType === Actions.lineChart &&
                    <FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.values.isGrouping}
                                    onChange={props.onChangeHandler()}
                                    name="isGrouping"
                                    value='true'
                                />
                            }
                            label="No Grouping"
                        />
                    </FormControl>
                }
                {(!props.checkForNullOrUndefined(dataModels)
                    && values.selectedDataModel
                    && values.dataHandler === "data_models"
                    && this.props.chartType === Actions.links
                    && !values.loadChildData) && (
                        <FormControl>
                            <FormLabel component="legend">{"Select column for title"}</FormLabel>
                            <ChipSelect
                                className="w-full my-16"
                                value={values.selectedTitleColumn}
                                onChange={(val) => props.handleLinksFieldChange("selectedTitleColumn", val)}
                                textFieldProps={{
                                    InputLabelProps: {
                                        shrink: true
                                    },
                                    variant: 'standard'
                                }}
                                options={values.dataElements}
                            />
                        </FormControl>
                    )}
                {
                    (!props.checkForNullOrUndefined(dataModels)
                        && values.selectedDataModel
                        && this.props.chartType === Actions.links
                        && values.dataHandler === "data_models") &&
                    <FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.values.enableLinking}
                                    onChange={props.onChangeHandler()}
                                    name="enableLinking"
                                    value='true'
                                />
                            }
                            label="Enable Linking"
                        />
                    </FormControl>
                }
                {(!props.checkForNullOrUndefined(dataModels)
                    && values.selectedDataModel
                    && this.props.chartType === Actions.links
                    && values.dataHandler === "data_models"
                    && props.values.enableLinking) && (
                        <FormControl>
                            <FormLabel component="legend">{"Select Type Key"}</FormLabel>
                            <ChipSelect
                                className="w-full my-16"
                                name="selectedTitleColumn"
                                value={values.selectedTypeKey}
                                onChange={(val) => props.handleLinksFieldChange("selectedTypeKey", val)}
                                textFieldProps={{
                                    InputLabelProps: {
                                        shrink: true
                                    },
                                    variant: 'standard'
                                }}
                                options={values.dataElements}
                            />
                        </FormControl>
                    )}
                {(!props.checkForNullOrUndefined(dataModels)
                    && values.selectedDataModel
                    && this.props.chartType === Actions.links
                    && values.dataHandler === "data_models"
                    && props.values.enableLinking) && (
                        <FormControl>
                            <FormLabel component="legend">{"Select Link Value"}</FormLabel>
                            <ChipSelect
                                className="w-full my-16"
                                value={values.selectedLinkValue}
                                onChange={(val) => props.handleLinksFieldChange("selectedLinkValue", val)}
                                textFieldProps={{
                                    InputLabelProps: {
                                        shrink: true
                                    },
                                    variant: 'standard'
                                }}
                                options={values.dataElements}
                            />
                        </FormControl>
                    )}
                {(!props.checkForNullOrUndefined(dataModels)
                    && values.selectedDataModel
                    && this.props.chartType === Actions.links
                    && values.dataHandler === "data_models") && (
                        <FormControl>
                            <FormLabel component="legend">{"Select Open Link Type *"}</FormLabel>
                            <ChipSelect
                                className="w-full my-16"
                                value={values.openLinkType}
                                onChange={(val) => {
                                    console.log("openLinkType", val);
                                    props.handleLinksFieldChange("openLinkType", val)
                                }}
                                textFieldProps={{
                                    InputLabelProps: {
                                        shrink: true
                                    },
                                    variant: 'standard'
                                }}
                                options={linkOpenTypeOptions}
                            />
                        </FormControl>
                    )}
                {(this.props.chartType === Actions.links
                    && values.dataHandler === "static_links") && (
                        <React.Fragment>
                            <StaticLinks
                                {...this.props}
                                values={values}
                                classes={classes}
                            />
                            <FormLabel component="legend">
                                {"Static Links Table"}
                            </FormLabel>
                            <StaticLinksTable
                                {...this.props}
                            />
                        </React.Fragment>
                    )}
            </div>
        </>
        )
    }
}

function mapStateToProps({ pages, dataModels, businessRules }) {
    return {
        chartType: pages.page.chartType,
        dataModels: dataModels.data,
        // businessRules: businessRules.data,
    };
}

export default connect(
    mapStateToProps,
    null
)(Data);
