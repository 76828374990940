export function generatePieData(data, series) {
	// let { data } = element;
	console.log("data.labels", data)
	let d = data.labels.map((l, i) => {
		return {
			name: l,
			y: data.datasets[0].data[i],
			// color: data.datasets[0].backgroundColor[i]
		}
	})
	series[0].name = data.datasets[0].label;
	series[0].data = d;
	return series;
}

export function getDefaultPieChart() {
	return {
		series: [{
			name: 'Gases',
			data: [
				{
					name: 'Argon',
					y: 0.9,
					color: '#3498db'
				},
				{
					name: 'Nitrogen',
					y: 78.1,
					color: '#9b59b6'
				},
				{
					name: 'Oxygen',
					y: 20.9,
					color: '#2ecc71'
				},
				{
					name: 'Trace Gases',
					y: 0.1,
					color: '#f1c40f'
				}
			]
		}]
	}
}

export function getPieChartConfig(series, renderTo) {
	return {
		chart: {
			type: 'pie',
			renderTo: renderTo
		},
		title: {
			text: ''
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: false
				},
				showInLegend: true,
				// innerSize: '70%'
			}
		},
		series: series
	};
}

export function getDoughnutChartConfig(series, renderTo) {
	let pieConfig = getPieChartConfig(series, renderTo);
	pieConfig.plotOptions.pie.innerSize = '70%';
	return pieConfig;
}