export default {

	GET_MEMBERS: undefined,
	GET_MEMBERS_SUCCESS: undefined,
	GET_MEMBERS_FAILURE: undefined,

	GET_MEMBER: undefined,
	GET_MEMBER_SUCCESS: undefined,
	GET_MEMBER_FAILURE: undefined,

	GET_MEMBER_BY_USERID: undefined,
	GET_MEMBER_BY_USERID_SUCCESS: undefined,
	GET_MEMBER_BY_USERID_FAILURE: undefined,

	SAVE_MEMBER: undefined,
	SAVE_MEMBER_SUCCESS: undefined,
	SAVE_MEMBER_FAILURE: undefined,

	UPDATE_MEMBER: undefined,
	UPDATE_MEMBER_SUCCESS: undefined,
	UPDATE_MEMBER_FAILURE: undefined,

	DELETE_MEMBER: undefined,
	DELETE_MEMBER_SUCCESS: undefined,
	DELETE_MEMBER_FAILURE: undefined,

	DISABLE_MEMBER: undefined,
	DISABLE_MEMBER_SUCCESS: undefined,
	DISABLE_MEMBER_FAILURE: undefined,

	RESET_MEMBER_BY_USERID: undefined

}