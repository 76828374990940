import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import "styles/page-designer.css";
import { withRouter } from 'react-router-dom';
import * as Actions from 'actions';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import _ from 'modules/lodash';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    closeIconContainer: {
        display: "flex",
        flexDirection: "row"
    },
    card: {
        // maxWidth: 345,
        backgroundColor: '#cccccc'
    },
    media: {
        height: 140,
    },
});

class PageDesignerHTMLComponent extends Component {

    render() {
        let { data, width, height, pageSize, error } = this.props.element.chartData;
        if (error) {
            return (
                <div className="errorDisplayCenter">{error}</div>
            );
        }
        else {
            console.log('this.props.element.chartData:::', this.props.element.chartData);
            return (
                <div id={`CardContainer${this.props.element.chartUniqueId}`}
                    style={{
                        height: "inherit",
                        width: "inherit",
                        backgroundColor: "rgba(69, 127, 187, 0.3)"
                    }}>
                    <div dangerouslySetInnerHTML={{
                        __html: this.props.element.chartData.html
                    }} />
                </div>
            );
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // changeRowSizeForTableChartComponent: Actions.changeRowSizeForTableChartComponent
    }, dispatch);
}

export default withStyles(styles, { withTheme: true })(
    withRouter(connect(null, mapDispatchToProps)(PageDesignerHTMLComponent))
);
