import React from 'react';
import { Button, Grid, FormLabel } from '@material-ui/core';

const BackgroundsTable = (props) => {
	console.log("props", props, props.backgrounds)
	console.log("props", Array.isArray(props.backgrounds));
	if (props.backgrounds
		&& Array.isArray(props.backgrounds)) {
		return props.backgrounds.map((l, index) => {
			if (!l.editMode) {
				console.log("l", l);
				return (
					<Grid container key={index}>
						<Grid style={{ "padding": "10px" }} xs={3}>
							<FormLabel >{l.selectedBackgroundDataElement}</FormLabel>
						</Grid>
						<Grid style={{ "padding": "10px" }} xs={2}>
							<FormLabel >{l.selectedBackgroundFieldValue}</FormLabel>
						</Grid>
						<Grid style={{ "padding": "10px" }} xs={3}>
							<FormLabel >{l.selectedBackgroundValue}</FormLabel>
						</Grid>
						<Grid style={{ "padding": "10px" }} xs={3}>
							<Button variant="contained" color="secondary" onClick={() => {
								props.editBackground(l, index);
							}} >Edit</Button>
							<Button variant="contained" color="secondary" onClick={() => {
								props.deleteBackground(l, index);
							}} >-</Button>
						</Grid>
					</Grid>
				)
			}
			else return ''
		})
	} else return ''
}
export default BackgroundsTable;