import axios from 'axios/index';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
	baseURL: API_BASE_URL,
	timeout: 10000,
	// headers: { 'X-Custom-Header': 'foobar' }
});
const apiServices = () => {

	// Form Builder formBuilder.actions.js
	const saveForm = (data, appId) => {
		return api.post('formBuilder/addForm', data[data.length - 1], { headers: { 'appId': appId } })
	}
	const getForms = (appId) => {
		return api.get(`formBuilder/getForms?appId=${appId}`, { headers: { 'appId': appId } })
	}
	const getEntryFormById = (id) => {
		return api.get(`formBuilder/getForm/${id}`)
	}
	const getTemplates = (appId) => {
		return api.get(`formBuilder/getTemplates`, { headers: { 'appId': appId } })
	}
	const getSubmittedData = () => {
		return api.get(`submittedData`)
	}
	const editForm = (data) => {
		return api.put('formBuilder/updateForm', data, { headers: { 'appId': data.appId } })
	}
	const saveSubmittedData = (data) => {
		return api.post('submittedData', data)
	}
	const submittedDataUpdate = (formData) => {
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}
		return api.post('submittedData', formData, config)

	}
	const updateDataModalAndPageId = (data, appId) => {
		return api.put('formBuilder/updateDataModalAndPageId', data, { headers: { 'appId': appId } })
	}
	const deleteForm = (id, name) => {
		return api.delete(`formBuilder/deleteForm?id=${id}&name=${name}`)
	}
	const deleteFormData = (id) => {
		return api.delete(`submittedData?id=${id}`)
	}
	const deleteTemplate = (id) => {
		return api.delete(`formBuilder/deleteTemplate?id=${id}`)
	}

	// EntryForm entryForm.actions.js
	const getEntryForm = (id, appId) => {
		return api.get(`forms/${id}`, { headers: { 'appId': appId } })
	}
	const saveEntryForm = (data) => {
		return api.post('forms/add', data)
	}
	const updateEntryForm = (data) => {
		return api.post('forms/update/' + data._id, data)
	}
	const getEntryForms = (appId) => {
		return api.get(`forms`, { headers: { 'appId': appId } })
	}
	const getEntryFormsByAppId = (id) => {
		return api.get(`forms`, { headers: { 'appId': id } })
	}

	// Flow chart
	const saveFlowchart = (diagram) => {
		return api.post('flowcharts/add/', diagram)
	}

	// Workflow 
	const getWorkflow = (id, appId) => {
		return api.get('workflows/' + id, { headers: { 'appId': appId } })
	}
	const getWorkflows = (appId, type) => {
		return api.get(`workflows?appId=${appId}&type=${type}`, { headers: { 'appId': appId } })
	}

	const saveWorkflow = (data) => {
		return api.post('workflows/add/', data, { headers: { 'appId': data.appId } })
	}
	const updateWorkflow = (data) => {
		return api.post('workflows/update/' + data._id, data, { headers: { 'appId': data.appId } })
	}

	const getWorkFlowTasks = (workflowId, appId) => {
		return api.post(`workflows/execute/${workflowId}/tasks`, { headers: { 'appId': appId } })
	}

	const getWorkFlowEvents = (workflowId, appId) => {
		return api.post(`workflows/execute/${workflowId}/events`, { headers: { 'appId': appId } })
	}



	return {
		saveForm,
		getForms,
		getEntryFormById,
		getTemplates,
		getSubmittedData,
		editForm,
		saveSubmittedData,
		submittedDataUpdate,
		updateDataModalAndPageId,
		deleteForm,
		deleteFormData,
		deleteTemplate,
		getEntryForm,
		saveEntryForm,
		updateEntryForm,
		getEntryForms,
		getEntryFormsByAppId,
		saveFlowchart,
		getWorkflow,
		getWorkflows,
		saveWorkflow,
		updateWorkflow,
		getWorkFlowTasks,
		getWorkFlowEvents
	}
}

export default {
	apiServices
}