
import React from 'react'
import {
    Grid, Box, Typography, AppBar, List, ListItem, ListItemText, ListItemIcon, ListItemAvatar,
    TextField, Select, Toolbar, CssBaseline, Avatar, Button, Popover, MenuItem, Icon, Paper
} from '@material-ui/core'
import { Link } from 'react-router-dom';
import {
    Notifications, ExpandLess, ExpandMore, Home, Search
} from '@material-ui/icons';

const DappsHeader = (props) => {
    return (
        <Paper style={{ width: "100%", position: "fixed" }}>
            <Grid container className="bdr-t" style={{ alignItems: "center", paddingLeft: "24px" }}>
                <Grid item xs={12} sm={3} md={5} className="flex headerbtm-sec pl-24">
                    <Box px={2} style={{ paddingLeft: '0px' }}>
                        {<Link
                            to={`/builder/dapps`
												/*props.dapp.data && props.dapp.appId === props.dapp.data._id
												? `/builder/dapps/${props.dapp.appId}`
												: `/builder/dapps`*/}
                            style={{ cursor: "pointer" }}>
                            <Home className="houseicon" />
                        </Link>}

                    </Box>
                    {/* {props.dapp.appId && props.dapp.data && props.dapp.data.title &&
                    <Box component="label" className="keywordtxt">
                        <Link to={`/builder/dapps/${props.dapp.appId}`}
                            style={{ cursor: "pointer", color: "#000" }}>
                            <Typography variant="h5">{props.dapp.data.title}</Typography>
                        </Link>
                    </Box>
                } */}
                </Grid>
                <Grid item xs={12} sm={9} md={7} className="downmenu navmenu-bar pr-24">
                    <Grid container direction="row" className="menuleft">
                        <List component="nav" className="menu-nav">
                            <ListItem className="menulists">
                                <Box>
                                    <TextField
                                        id="outlined-basic"
                                        type="text"
                                        className="Addapp-txtfield"
                                        variant="outlined"
                                        placeholder="Enter a key Word"
                                        onChange={(e) => props.setSearchKeyword(e.target.value)}
                                    />
                                </Box>
                            </ListItem>
                            <ListItem className="menulists">
                                <Select
                                    native
                                    defaultValue=""
                                    name="categories"
                                    className="Addapp-txtfield"
                                    id="grouped-native-select"
                                    variant="outlined"
                                    value={props.categoryFilterData}
                                    onChange={(data) => props.handleCategoryFilter(data)}
                                >
                                    <option value="">Select Categories</option>
                                    {props.dapps && props.dapps.categories &&
                                        props.dapps.categories.map((item, index) => {
                                            return (
                                                <option key={index} value={item.Name}>
                                                    {item.Name}
                                                </option>
                                            );
                                        })}
                                </Select>
                            </ListItem>
                            {console.log("location", props)}
                            {window.location && window.location.pathname === "/builder/dapps"
                                && <ListItem className="menulists">
                                    <Button variant="contained" color="primary"
                                        className="addnewappbtn"
                                        onClick={props.handleAddNewAppClick}>
                                        {"ADD NEW APP"}
                                    </Button>
                                </ListItem>}
                        </List>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}
export default DappsHeader;