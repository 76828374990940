import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
// import View from './view'
import Redux from './Redux'
import { withRouter } from 'react-router-dom'
import PageDesigner from 'components/Page/page/PageDesigner'

const Container = (props) => {

	useEffect(() => {
		props.getPages(props.match.params.dappId)
	}, [])

	return (
		<PageDesigner
			{...props}
		/>
	)
}

export default hot(withRouter(Redux(Container)))