import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { FormControl } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import photoone from '../../images/photoone.svg';
import microsoftsqlserver from '../../images/microsoftsqlserver.svg';
import SelectTablePipeline from './selecttablepipeline'



const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	control: {
		padding: theme.spacing(2),
	},
}));

export default function SelectPipelines(props) {
	const classes = useStyles();
	const [spacing, setSpacing] = React.useState(2);
	const [isShowTablePipeline, setShowTablePipeline] = React.useState(false);

	return (
		<>
			{!isShowTablePipeline ?
				<Box Box className="pxy20">
					<Grid container>
						<Box className="processtab-sec">
							<Grid container>
								<Grid item xs={12} className="savebtn-sec">
									<Button variant="contained" color="primary" className="savebtn btnwidth" onClick={props.handleNext}>
										SAVE
        						</Button>
								</Grid>
							</Grid>

							<Grid item xs={12} className="selecteddata-grid">
								<Typography variant="h5" className="mb3 d-block font-bold">Select Data Source</Typography>
								<Box className="selecteddatasource-head" component="div">
									<img src={microsoftsqlserver} alt="sql server logo" className="serverlogo" />
									<Typography component="div" className="data-block">
										<Grid container className="mb2">
											<Grid item xs={12} md={2} >
												<Box component="label" className="connectionlabel">
													Connection Name:
						      </Box>
											</Grid>
											<Grid item xs={12} md={3} >
												<TextField
													variant="outlined"
													required
													fullWidth
													id="email"
													name="email"
													className="drmstextfield"
													autoComplete="email"
													placeholder="DRMS Server Connection"
													autoFocus
												/>
											</Grid>
										</Grid>
									</Typography>
								</Box>
							</Grid>
							<Grid className="my5" container>
								<Typography variant="h5" className="mb3 d-block font-bold">Order Table</Typography>
								<Grid item xs={12} className="selecteddata-grid">
									<Box className="selecteddatasource-head" component="div">
										<img src={photoone} alt="selected logo" className="serverlogo" />
										<Typography component="div" className="data-block">
											<Box component="label" m={1}>
												Order data model
				      </Box>
										</Typography>
									</Box>
								</Grid>
							</Grid>
							<Grid item xs={12} className="my5">

								<Typography variant="h5" className="mb3 d-block font-bold">Select Table</Typography>
								<Box className="enterpriseblock" component="div">
									{props.pipelines && props.pipelines.data.map((item) => {
										return <Paper onClick={() => setShowTablePipeline(true)}>
											<Box component="label">
												{item.name}
											</Box>
											<img src={photoone} alt="temp image" />
										</Paper>
									})}
								</Box>
							</Grid>
							<Grid item xs={12} className="my5">
                    	</Grid>
						</Box>
					</Grid>
				</Box> : <SelectTablePipeline></SelectTablePipeline>}
		</>
	);
}
