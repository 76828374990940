import React, { Component } from "react";
import { withStyles, createMuiTheme, MuiThemeProvider  } from '@material-ui/core/styles';
import "styles/page-designer.css";
import { withRouter } from 'react-router-dom';
import * as Actions from 'actions';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import _ from 'modules/lodash';
import MUIDataTable from "mui-datatables";
import {
	getApiDataFromDataUrl
} from 'services/page.service';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const styles = theme => ({
	closeIconContainer: {
		display: "flex",
		flexDirection: "row"
	}
});

const options = {
	filterType: 'dropdown',
	responsive: "scrollMaxHeight"
};

class PageDesignerTableChart extends Component {
	state = {
		page: 0,
		count: 1,
		rowsPerPage: 10,
		data: [["Loading Data..."]],
		isLoading: false,
		searchText: ""
	};

	componentDidMount() {
		this.getApiData(this.state.page, this.state.rowsPerPage);
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(this.props.element, prevProps.element)) {
			this.getApiData(this.state.page, this.state.rowsPerPage);
		}
	}

	getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableHeadCell: {
          root: {
            fontWeight: 'bold',
          },
        },
      },
    });

	getTableHeader = (data) => {
		let tableHeader = [];
		let { chartData } = this.props.element;
		if (chartData.selectedDataModel && chartData.selectedDataElement
			&& !chartData.loadChildData) {
			chartData.selectedDataElement.map(element => {
				let { label, value } = element;
				if (value != '_id') {
					let headerItem = {
						name: value,
						label: typeof label === 'object' && label !== null ? label.labelText : label
					}
					tableHeader.push(headerItem)
				}
			})
		} else {
			if (data.length > 0) {
				for (let key in data[0]) {
					if (key != '_id') {
						let headerItem = {
							name: key,
							label: key
						}
						tableHeader.push(headerItem)
					}
				}
			}
		}
		return tableHeader;
	};

	setDatafromApi = (res) => {
		if (res && Array.isArray(res.data) && !res.data.columns && !res.data.datasets) {
			let tableHeader = this.getTableHeader(res.data);
			let r = {
				columns: tableHeader,
				datasets: res.data
			}
			this.setState({
				resData: r, data: r, isLoading: false,
				count: res.total ? res.total : res.data.length
			});
		} else if (res.columns && res.datasets) {
			this.setState({
				data: res, isLoading: false,
				count: res.total ? res.total : res.datasets.length
			});
		} else {
			throw new Error('Expect data is object with columns and datasets keys');
		}
	}

	getApiDataFromDataUrl = (url) => {
		getApiDataFromDataUrl(url, this.props.match.params.dappId)
			.then(res => {
				this.setDatafromApi(res.data)
			})
	}

	getApiData = (page, rowsPerPage) => {
		this.setState({ isLoading: true });
		let { chartData } = this.props.element;
		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			let targetURL = API_BASE_URL + "/dataconnect/table/" + chartData.selectedDataModel;
			const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
			targetURL += `?type=list&`;
			targetURL += chartData.selectedDataElement.reduce(reducer, '&fields=');
			targetURL += '&page=' + page + '&rowsPerPage=' + rowsPerPage;
			if (this.props.queryString) {
				targetURL += '&' + this.props.queryString;
			}

			if (chartData.conditions && chartData.conditions.length > 0) {
				let cond = JSON.stringify(chartData.conditions);
				targetURL += "&conditions=" + cond;
			}
			if (this.props.page.conditions && this.props.page.conditions.length > 0) {
				let cond = JSON.stringify(this.props.page.conditions);
				targetURL += "&pConditions=" + cond;
			}
			this.getApiDataFromDataUrl(targetURL);
		} else if (this.props.dataUrl) {
			this.getApiDataFromDataUrl(props.dataUrl);
		} else {
			this.setState({
				data: this.props.element.chartData.data, isLoading: false,
				count: this.props.element.chartData.data.length
			});
		}
	}

	render() {
		let {
			// data,
			width, height, pageSize, error, columns } = this.props.element.chartData;
		let { data } = this.state;
		let chartType = this.props.element.chartType;
		if (chartType === "simpletable") {
			options.selectableRows = 'none';
			options.viewColumns = false;
			options.download = false;
			options.filter = false;
			options.search = false;
			options.print = false;
			options.sort = false;
		}
		if (data && data.data && Array.isArray(data.data) && !data.data.columns && !data.data.datasets) {
			let tableHeader = this.getTableHeader(data.data);
			console.log("tableHeader", tableHeader);
			data = {
				columns: tableHeader,
				datasets: data.data
			}
		}

		console.log("data", JSON.stringify(data.datasets));
		console.log("columns", JSON.stringify(data.columns));

		if (error) {
			return (
				<div className="errorDisplayCenter">{error}</div>
			);
		}
		else {
			return (
				<div id={`TableContainer${this.props.element.chartUniqueId}`} style={{ height: "inherit", width: "inherit" }}>
					<MuiThemeProvider theme={this.getMuiTheme()}>
					<MUIDataTable
						data={data.datasets}
						columns={data.columns}
						options={options}
					/>
					</MuiThemeProvider>
				</div>
			);
		}
	}
}

function mapStateToProps({ pages }) {
	return {
		page: pages.page
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		// changeRowSizeForTableChartComponent: Actions.changeRowSizeForTableChartComponent
	}, dispatch);
}

export default withStyles(styles, { withTheme: true })(
	withRouter(connect(mapStateToProps, mapDispatchToProps)(PageDesignerTableChart))
);
