import React, { Component } from "react";
import PageRenderer from "./PageRenderer";
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
	Box, Grid, Button, Typography
} from '@material-ui/core'
import rightarrow from 'images/rightarrow.svg'
const APPRENDERER_URL = process.env.REACT_APP_APPRENDERER_URL

const styles = theme => ({
})

class PageDesigner extends Component {
	constructor() {
		super();
		this.state = {
			readOnly: false,
		};
	}

	componentDidMount() {
		console.log("this.props.match", this.props.match);
		console.log("this.props.location", this.props.location);
		this.setState({
			readOnly: this.props.location.query && this.props.location.query.readonly === "true"
		})
		this.props.clearPage()
	}

	savePage() {
		console.log("savePage = this.props.page ===", this)
		console.log("savePage = this.props.page", this.props.page)
		this.props.updatePage(this.props.page)
	}

	render() {
		const { match, page, openNewPageDialog, location } = this.props,
			{ params } = match, { dappId } = params,
			{ url, name } = page;
		return (<div className="fullHeight">
			<Box className="abcCompany-page">
				<Box className="pxy20">
					<Grid container>
						<Grid item xs={12} md={6}>
							<div className="backbtn">
								<div className="backbtn">
									<Link to={`/builder/${dappId}/pages`}
										style={{ textDecoration: 'none', color: "#1B2A47", display: 'flex', alignItems: 'center' }}>
										<img src={rightarrow} alt="backicon" className="backicon" />
										<Box className="companyheadingtext">
											<Typography variant="h4">{name}</Typography>
										</Box>
									</Link>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} md={6} className="savebtn-sec">
							{url &&
								<Button variant="contained" color="primary" className="addnewentrybtn mb5"
									onClick={(event) => {
										event.preventDefault()
										console.log(`${APPRENDERER_URL}/apps/${dappId}/${url}`);
										window.open(`${APPRENDERER_URL}/apps/${dappId}/${url}`, "_blank")
									}}>
									{"VIEW PAGE"}
								</Button>
							}
							<Button variant="contained" color="primary" className="addnewentrybtn mb5"
								onClick={openNewPageDialog}>
								{"EDIT PAGE DETAILS"}
							</Button>
							<Button variant="contained" color="primary" className="addnewentrybtn mb5"
								onClick={() => this.savePage()}>
								{"UPDATE"}
							</Button>
						</Grid>
						<Grid item xs={12} md={6} className="savebtn-sec">
						</Grid>
						<Box className="bg-white pxy20 width-100" component="div">
							<Grid container spacing={2}>
								<PageRenderer readOnly={this.state.readOnly} location={location} />
							</Grid>
						</Box>
					</Grid>
				</Box>
			</Box>
		</div>)
	}
}

// export default withRouter(PageDesigner);
export default withStyles(styles, { withTheme: true })(
	withRouter(PageDesigner)
)
