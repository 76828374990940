export default {

	GET_JOBS: undefined,
	GET_JOBS_SUCCESS: undefined,
	GET_JOBS_FAILURE: undefined,

	GET_JOB: undefined,
	GET_JOB_SUCCESS: undefined,
	GET_JOB_FAILURE: undefined,

	SAVE_JOB: undefined,
	SAVE_JOB_SUCCESS: undefined,
	SAVE_JOB_FAILURE: undefined,

	UPDATE_JOB: undefined,
	UPDATE_JOB_SUCCESS: undefined,
	UPDATE_JOB_FAILURE: undefined,

	DELETE_JOB: undefined,
	DELETE_JOB_SUCCESS: undefined,
	DELETE_JOB_FAILURE: undefined,

	TEST_JOB: undefined,
	TEST_JOB_SUCCESS: undefined,
	TEST_JOB_FAILURE: undefined,

}