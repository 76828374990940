import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button, TextField, FormControl, FormLabel } from '@material-ui/core'

const rdbmsView = (props) => {
	let { form, classes, testDatasource, handleChange } = props
	return (<><br />
		<FormLabel variant="h6">{"Database Info"}</FormLabel>
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6">{"Server"}</FormLabel>
			<TextField
				id="rdbmsServer"
				name="rdbmsServer"
				placeholder="Server"
				className={classes.textField}
				value={form.rdbmsServer}
				onChange={handleChange}
				margin="normal"
				variant="outlined"
			/>
		</FormControl>
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6">{"Port"}</FormLabel>
			<TextField
				id="rdbmsServerPort"
				name="rdbmsServerPort"
				placeholder="Port"
				className={classes.textField}
				value={form.rdbmsServerPort}
				onChange={handleChange}
				variant="outlined"
				margin="normal"
			/>
		</FormControl>
		<br />
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6">{"User Id"}</FormLabel>
			<TextField
				id="rdbmsServerUserId"
				name="rdbmsServerUserId"
				placeholder="User Id"
				className={classes.textField}
				value={form.rdbmsServerUserId}
				onChange={handleChange}
				variant="outlined"
				margin="normal"
			/>
		</FormControl>
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6">{"Password"}</FormLabel>
			<TextField
				id="rdbmsServerPassword"
				name="rdbmsServerPassword"
				placeholder="Password"
				className={classes.textField}
				value={form.rdbmsServerPassword}
				onChange={handleChange}
				variant="outlined"
				margin="normal"
			/>
		</FormControl>
		<br />
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6">{"Database"}</FormLabel>
			<TextField
				id="rdbmsServerDatabase"
				name="rdbmsServerDatabase"
				placeholder="Database"
				className={classes.textField}
				value={form.rdbmsServerDatabase}
				onChange={handleChange}
				margin="normal"
				variant="outlined"
			/>
		</FormControl>
		<Button
			className="whitespace-no-wrap"
			variant="contained"
			onClick={() => testDatasource(form)}>
			{"Test"}
		</Button>
		<br />
	</>)
}

export default withRouter(rdbmsView)