import { ActionTypes } from 'constants/index'

const initialState = {
	data: [],
	searchText: "",
	error: "",
	deleteSuccess: false,
	disableSuccess: false,
};

const membersReducer = function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_MEMBERS_SUCCESS: {
			return {
				...state,
				data: action.payload,
				deleteSuccess: false,
				disableSuccess: false,
			};
		}
		case ActionTypes.GET_MEMBERS_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error
				};
			}
		case ActionTypes.SET_MEMBERS_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		case ActionTypes.DELETE_MEMBER_SUCCESS: {
			return { ...state,
				loading: false,
				data: [...state.data.filter(item => item._id !== action.payload.id)],
				deleteSuccess: true
			}
		}
		case ActionTypes.DELETE_MEMBER_FAILURE: {
			return {
				...state,
				error: action.error,
				loading: false
			}
		}
		case ActionTypes.DISABLE_MEMBER_SUCCESS: {
			return {
				...state,
				disableSuccess: true,
			}
		}
		case ActionTypes.DISABLE_MEMBER_FAILURE: {
			return {
				...state,
				error: action.error,
			}
		}
		default: {
			return state;
		}
	}
};

export default membersReducer;
