import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button, TextField, FormControl, FormLabel } from '@material-ui/core'

const qldbView = (props) => {
	let { form, classes, testQLDBConnection, handleChange } = props
	return (<><br />
		<FormLabel component="legend">{"Quantum Ledger Database"}</FormLabel>
		<br />
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Ledger Name"}</FormLabel>
			<TextField
				id="qldbLedger"
				name="qldbLedger"
				placeholder="Ledger Name"
				className="mt-6 mb-10"
				value={form.qldbLedger}
				onChange={handleChange}
				margin="normal"
				fullWidth
				variant="outlined"
			/>
		</FormControl>
		<br />
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"AWS Access Token"}</FormLabel>
			<TextField
				id="qldbAccessToken"
				name="qldbAccessToken"
				placeholder="AWS Access Token"
				className={classes.textField}
				value={form.qldbAccessToken}
				onChange={handleChange}
				margin="normal"
				variant="outlined"
			/>
		</FormControl>
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Security Token"}</FormLabel>
			<TextField
				id="qldbSecretToken"
				name="qldbSecretToken"
				placeholder="Security Token"
				className={classes.textField}
				value={form.qldbSecretToken}
				onChange={handleChange}
				margin="normal"
				variant="outlined"
			/>
		</FormControl>
		<Button
			className="whitespace-no-wrap"
			variant="contained"
			onClick={() => testQLDBConnection(form, props.match.params.dappId)}>
			{"Test"}
		</Button>
		<br />
	</>)
}

export default withRouter(qldbView)