import { put, call, takeLatest } from 'redux-saga/effects';
import { GroupsService } from 'services/index';
import { ActionTypes } from 'constants/index';

async function fetchAsync(func, arg) {
  const response = arg ? await func(arg) : await func();
  return response;
}

function* getGroups(action) {
  try {
    const groups = yield call(fetchAsync, GroupsService.getGroups, action.payload);
    yield put({ type: ActionTypes.GET_GROUPS_SUCCESS, payload: groups });
  } catch (e) {
    yield put({ type: ActionTypes.GET_GROUPS_FAILURE, error: e.message });
  }
}

function* getGroup(action) {
  try {
    const group = yield call(fetchAsync, GroupsService.getGroup, action.payload);
    yield put({ type: ActionTypes.GET_GROUP_SUCCESS, payload: group.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_GROUP_FAILURE, error: e.message });
  }
}

function* saveGroup(action) {
  try {
    const group = yield call(fetchAsync, GroupsService.saveGroup, action.payload);
    yield put({ type: ActionTypes.SAVE_GROUP_SUCCESS, payload: group.data });
  } catch (e) {
    let errObj = {};
    if (e.response) {
      errObj = e.response.fields;
      errObj.errorMessage = e.response.message ? e.response.message : '';
      errObj.status = e.response.status ? e.response.status : '';
    }
    yield put({ type: ActionTypes.SAVE_GROUP_FAILURE, error: errObj });
  }
}

function* deleteGroup(action) {
  try {
    const group = yield call(fetchAsync, GroupsService.deleteGroup, action.payload);
    yield put({ type: ActionTypes.DELETE_GROUP_SUCCESS, payload: group.data });
  } catch (e) {
    yield put({ type: ActionTypes.DELETE_GROUP_FAILURE, error: e.message });
  }
}

function* updateGroup(action) {
	try {
		const group = yield call(fetchAsync, GroupsService.updateGroup, action.payload)
		yield put({ type: ActionTypes.UPDATE_GROUP_SUCCESS, payload: group.data })
	} catch (e) {
		let errObj = { };
		if (e.response) {
			errObj = e.response.fields;
			errObj.errorMessage = e.response.message ? e.response.message : "";
			errObj.status = e.response.status ? e.response.status : ""
		}
		yield put({ type: ActionTypes.UPDATE_GROUP_FAILURE, error: errObj })
	}
}
export function* watchMembersSaga() {
  yield takeLatest(ActionTypes.GET_GROUPS, getGroups);
  yield takeLatest(ActionTypes.GET_GROUP, getGroup);
  yield takeLatest(ActionTypes.SAVE_GROUP, saveGroup);
  yield takeLatest(ActionTypes.DELETE_GROUP, deleteGroup);
  yield takeLatest(ActionTypes.UPDATE_GROUP, updateGroup)
}

export default watchMembersSaga;
