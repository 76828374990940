/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable prettier/prettier */
import React from 'react';
import ReactDOM from 'react-dom';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';

import Modal from './components/Modal';

// import { Grid, NativeSelect, TextField } from '@material-ui/core';
import Canvas from './components/Canvas';
import ErrorBoundary from './components/ErrorBoundary';
import Step from './components/Step';
import Toolbar from './components/Toolbar';
import randomString from '../../utils/randomString';
import './style/button.css';
import './style/EditElement.css';
import Redux from './redux';

import Basicinfo from './Properties/Basicinfo';
import Process from './Properties/Process';

const initialDiagram = {
  steps: [
    {
      height: 28,
      id: 'kuywrbbgvanjnbzz',
      inputConnectors: [],
      outputConnectors: [],
      type: 'start',
      subType: 'start',
      name: 'start',
      width: 28,
      x: 798,
      x1: 798,
      x2: 826,
      y: 14,
      y1: 14,
      y2: 42,
    },
  ],
  style: { backgroundColor: '#eee' },
  height: window.innerHeight - 100,
  width: '100%',
};
class Workflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      diagram: props.workflow ? JSON.parse(props.workflow.diagram) : initialDiagram,
      dragging: null,
      isMouseDown: false,
      isMouseMoving: false,
      isMouseOver: false,
      isMouseMove: false,
      offset: { x: 0, y: 0 },
      scroll: { x: 0, y: 0 },
      rectangularSelection: null,
      selected: {},
      mouseOverConnection: {},
      clickStep: {},
      mouseOverStep: {},
      shiftPressed: false,
      toolbarHeight: 50,
      show: false,
      ids: [],
      selectedStep: {enableEmail: false},
      value: 0,
      isVertical: true,
      isHorizontal: false,
      direction: 'vertical'
    };
  }

  componentDidMount() {
    const container = ReactDOM.findDOMNode(this).parentNode;

    // document.addEventListener('keydown', this.onDocumentKeydown)
    // document.addEventListener('keyup', this.onDocumentKeyup)

    window.addEventListener('scroll', this.onWindowScroll);
    window.addEventListener('resize', this.onWindowResize(container));

    const offset = {
      x: container.offsetLeft,
      y: container.offsetTop,
    };

    const scroll = {
      x: window.scrollX,
      y: window.scrollY,
    };

    this.setState({
      offset,
      scroll,
    });
  }

  generateId = l => {
    const newId = randomString(l);

    const idExists = this.state.diagram.steps.find(({ id }) => id === newId);

    // If new random id was found, try again with a longer random string.
    return idExists ? this.generateId(l + 1) : newId;
  };

  getCoordinates = event => {
    const { offset, scroll } = this.state;

    return {
      x: event.clientX - offset.x + scroll.x,
      y: event.clientY - offset.y + scroll.y,
      // x: event.clientX,
      // y: event.clientY,
    };
  };

  isInsideWorkflow = coordinates => {
    const { diagram, offset, scroll, toolbarHeight } = this.state;

    const { height, width } = diagram;
    console.log('Width', width);
    return (
      coordinates.x > offset.x + scroll.x - window.innerWidth &&
      coordinates.x < offset.x + scroll.x + window.innerWidth &&
      coordinates.y > offset.y + scroll.y + toolbarHeight - toolbarHeight * 7 &&
      coordinates.y < offset.y + scroll.y + height
    );
  };

  onMouseDown = event => {
    const { mouseOverConnection, toolbarHeight, diagram } = this.state;
    const startId = Object.keys(mouseOverConnection).find(p => mouseOverConnection[p]);
    if (startId) {
      console.log(startId);
      const step = diagram.steps.find(s => s.id === startId);
      const coordinates = this.getCoordinates(event);
      if (step.type === 'line' || step.type === 'optionalline') {
        const connection = mouseOverConnection[startId];
        this.setState({
          connection,
          dragging: coordinates,
        });
      } else {
        // this.setState({
        //   arrow: {
        //     startId,
        //     startX: coordinates.x,
        //     startY: coordinates.y - toolbarHeight,
        //     endX: coordinates.x,
        //     endY: coordinates.y - toolbarHeight,
        //   },
        // });
      }
    }
    if (event.target.id === 'svg') this.setState({ selected: {} });
  };

  onMouseEnter = () => {
    this.setState({
      isMouseOver: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      isMouseOver: false,
    });
  };

  onMouseMove = event => {
    const {
      diagram,
      dragging,
      isMouseDown,
      isMouseMove,
      selected,
      arrow,
      toolbarHeight,
      connection,
      direction
    } = this.state;

    const steps = [...diagram.steps];

    const coordinates = this.getCoordinates(event);

    const deltaX = dragging ? coordinates.x - dragging.x : 0;
    const deltaY = dragging ? coordinates.y - dragging.y : 0;

    // if (isMouseDown) {
    //   // console.log(coordinates)

    //   if (arrow) {
    //     this.setState(s => ({
    //       arrow: {
    //         ...s.arrow,
    //         endX: coordinates.x,
    //         endY: coordinates.y - toolbarHeight,
    //       },
    //     }));
    //     return;
    //   }
    //   if (Object.keys(selected).length) {
    //     console.log(steps);

    //     const ids = Object.keys(selected);

    //     steps
    //       .filter(el => {
    //         // if (el.type === 'line' || el.type === 'optionalline') {
    //         //   return;
    //         // } else {
    //           return (el.parentId === ids[0] || el.id === ids[0]) && el.type !== 'line';
    //         // }
    //       })
    //       .forEach(step => {
    //         if (step.type === 'pool') {
    //           console.log("inside")
    //           step.x += deltaX;
    //           step.y += deltaY;
    //           step.x1 = step.x;
    //           step.y1 = step.y;
    //           step.x2 = step.x + step.width;
    //           step.y2 = step.y + step.height;
    //           //====================================== TODO for drag line ================
    //           // if (connection) {
    //           //   console.log(step);
    //           //   step[connection + "X"] += deltaX;
    //           //   step[connection + "Y"] += deltaY;
    //           //   console.log(step);
    //           // } else {
    //           //   step.x += deltaX;
    //           //   step.y += deltaY;
    //           // }
    //           steps.filter(({ id }) => selected[id]);
    //           // console.log(steps)
    //           this.setState(
    //             {
    //               diagram: Object.assign({}, diagram, { steps }),
    //               dragging: coordinates,
    //             },
    //             () => {
    //               this.props.diagramChange(this.state.diagram);
    //             },
    //           );
    //         } else {

    //           const { inputConnectors, outputConnectors } = step;
    //           if (inputConnectors && inputConnectors.length > 0) {
    //             for (let i = 0; i < inputConnectors.length; i++) {
    //               steps
    //                 .filter(({ id }) => {
    //                   return inputConnectors[i].id === id;
    //                 })

    //                 .forEach(step => {
    //                   if(direction === 'vertical') {
    //                     step.endX += deltaX;
    //                     step.endY += deltaY;
    //                     step.endX2 = step.startX;
    //                     step.endX3 = step.startX;
    //                     step.endY3 = step.startY;
    //                     step.endY2 = step.startY;
    //                   }
    //                   else if (step.name === 'secondLine') {
    //                     step.endX += deltaX;
    //                     step.endY += deltaY;
    //                     step.endY2 += deltaY / 2;
    //                     step.endY3 = step.endY2;
    //                     step.endX3 += deltaX;
    //                   } else {
    //                       // step.endX += deltaX;
    //                       // step.endY += deltaY;
    //                       // step.endX2 += deltaX / 2;
    //                       // step.endX3 = step.endX2;
    //                       // step.endY3 += deltaY;
    //                     // if(step.endX < 50 ) {

    //                   }
    //                 });
    //             }
    //           }

    //           if (outputConnectors && outputConnectors.length > 0) {
    //             for (let i = 0; i < outputConnectors.length; i++) {
    //               steps
    //                 .filter(({ id }) => {
    //                   return outputConnectors[i].id === id;
    //                 })
    //                 .forEach(step => {
    //                   if (step.name === 'secondLine') {
    //                     step.startX += deltaX;
    //                     step.startY += deltaY;
    //                     step.endY2 += deltaY / 2;
    //                     step.endY3 = step.endY2;
    //                     step.endX2 += deltaX;
    //                   } else {                  
    //                     step.startX += deltaX;
    //                     step.startY += deltaY;
    //                     step.endX2 += deltaX / 2;
    //                     step.endY2 += deltaY;
    //                     step.endX3 = step.endX2;

    //                   }
    //                 });
    //             }
    //           }

    //           step.x += deltaX;
    //           step.y += deltaY;
    //           step.x1 = step.x;
    //           step.y1 = step.y;
    //           step.x2 = step.x + step.width;
    //           step.y2 = step.y + step.height;
    //           //====================================== TODO for drag line ================
    //           // if (connection) {
    //           //   console.log(step);
    //           //   step[connection + "X"] += deltaX;
    //           //   step[connection + "Y"] += deltaY;
    //           //   console.log(step);
    //           // } else {
    //           //   step.x += deltaX;
    //           //   step.y += deltaY;
    //           // }
    //           steps.filter(({ id }) => selected[id]);
    //           // console.log(steps)
    //           this.setState(
    //             {
    //               diagram: Object.assign({}, diagram, { steps }),
    //               dragging: coordinates,
    //             },
    //             () => {
    //               this.props.diagramChange(this.state.diagram);
    //             },
    //           );
    //         }
    //       });
    //   }
    // } else {
    //   steps.forEach(step => {
    //     if (isMouseMove === false) {
    //       if (step.type === 'line') {
    //         if (!step.connected === true) {
    //           step.endY = coordinates.y - step.y;
    //           step.endX += deltaX;
    //           step.endY += deltaY;
    //           step.endX2 += deltaX / 2;
    //           step.endX3 = step.endX2;
    //           step.endY3 = step.endY;
    //         }
    //       }
    //       if (step.type === 'optionalline') {
    //         if (!step.connected === true) {
    //           step.endY = coordinates.y - step.y;
    //           step.endX += deltaX;
    //           step.endY += deltaY;
    //           step.endX2 += deltaX / 2;
    //           step.endX3 = step.endX2;
    //           step.endY3 = step.endY;
    //         }
    //       }
    //     }
    //   });

    //   this.setState(
    //     {
    //       diagram: Object.assign({}, diagram, { steps }),
    //       dragging: coordinates,
    //     },
    //     () => {
    //       this.props.diagramChange(this.state.diagram);
    //     },
    //   );
    // }
  };

  moveArrow = (event, type) => {
    // console.log(type)
    // if (type === 'process') {
    //   this.setState({ isMouseMove: true });
    // }
    // // if(type === 'decision'){
    // //   this.setState({isMouseMove: true})
    // // }
    // // if(type === 'terminator'){
    // //   this.setState({isMouseMove: true})
    // // }
    // // if(type === 'start'){
    // //   this.setState({isMouseMove: true})
    // // }
    // if (type === 'pool') {
    //   this.setState({ isMouseMove: false });
    // }
  };

  onDragConnection = (event, type, id) => {
    const {
      diagram,
      dragging,
      isMouseDown,
      isMouseMove,
      selected,
      arrow,
      toolbarHeight,
      connection,
    } = this.state;
    const steps = [...diagram.steps];
    console.log('steps====', type);
    const coordinates = this.getCoordinates(event);
    console.log('coordinates====', dragging);

    const deltaX = dragging ? coordinates.x - dragging.x : 0;
    const deltaY = dragging ? coordinates.y - dragging.y : 0;
    if (isMouseDown) {
      steps.forEach(step => {
        if (step.type === 'pool') {
          if (type === 'bottom' || type === 'top') {
            if (deltaY > 0) {
              step.height += coordinates.y - step.height;
              step.d = `M35,0 L35 ${step.height}`;
              // step.height += deltaY;
            } else {
              step.height -= coordinates.y - step.height;
              step.d = `M35,0 L35 ${step.height}`;
              // step.height += deltaY;
            }
          }
        }
      });
    }
    this.setState(
      {
        diagram: Object.assign({}, diagram, { steps }),
        dragging: coordinates,
      },
      () => {
        this.props.diagramChange(this.state.diagram);
      },
    );
  };

  onMouseUp = event => {
    const { diagram, arrow, mouseOverConnection } = this.state;
    const end = Object.keys(mouseOverConnection).find(p => mouseOverConnection[p]);
    if (arrow && end) {
      const steps = [...diagram.steps];
      const step = steps.find(s => s.id === arrow.startId);
      if (step.id !== end) {
        step.inputConnectors.push(arrow);
        this.setState({ diagram: Object.assign({}, diagram, { steps }) }, () => {
          this.props.diagramChange(this.state.diagram);
        });
      }
    }
    this.setState({
      dragging: null,
      isMouseDown: false,
      arrow: null,
    });
  };

  onWindowResize = container => {
    return () => {
      const rect = container.getBoundingClientRect();

      const dynamicView = {
        height: rect.height,
        width: rect.width,
      };
      this.setState({ dynamicView });
    };
  };

  onWindowScroll = () => {
    const scroll = {
      x: window.scrollX,
      y: window.scrollY,
    };
    this.setState({ scroll });
  };

  setMouseOverConnection = id => (event, data) => {
    const { mouseOverConnection } = this.state;
    mouseOverConnection[id] = event.type === 'mouseover' ? data || true : null;
    this.setState({
      mouseOverConnection: Object.assign({}, mouseOverConnection),
    });
  };

  setMouseOverStep = id => event => {
    const { mouseOverStep } = this.state;
    mouseOverStep[id] = event.type === 'mouseover';
    // console.log(mouseOverStep)
    this.setState({ mouseOverStep: Object.assign({}, mouseOverStep) });
  };
  selectStep = (id, parentId, type) => {
    const { diagram } = this.state;
    return event => {
      const { selected, shiftPressed } = this.state;
      let selectedStep = shiftPressed ? Object.assign({}, selected) : {enableEmail: false};

      selectedStep[id] = true;
      selectedStep[parentId] = true;
      selectedStep[type] = true;

      this.setState({
        isMouseDown: event.type === 'mousedown',
        selected: selectedStep,
      });
    };
  };

  stopDragging = event => {
    this.setState({
      dragging: null,
      isMouseDown: false,
      selected: {},
    });
  };

  setClickStep = (event, id, type) => {
    console.log("stepId====",id)
    let { clickStep, diagram, selected, offset } = this.state;
    const steps = [...diagram.steps];
    selected[id] = event.type === 'click';
    console.log('Clicked', steps);
    steps
      .filter(el => {
        return el.id === id;
      })
      .forEach(step => {
        if (step.type === 'pool') {
          for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === 'line') {
              if (!steps[i].connected === true) {
                this.deleteById(steps, 'id', steps[i].id);
                console.log(steps);
              }
            }
          }
        } else if (step.type === 'process') {
          for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === 'line') {
              if (!steps[i].connected === true) {
                steps[i].connected = true;
                if (steps[i].parentId != step.parentId) {
                  steps[i].strokedash = 5;
                  steps[i].type = 'optionalline';
                }

                let dataOfSource;
                for (let x = 0; x < steps.length; x++) {
                  if (steps[x].outputConnectors.length > 0) {
                    steps[x].outputConnectors.forEach(item => {
                      if (item.id === steps[i].id) {
                        dataOfSource = steps[x];
                        console.log('Connceted line Source', dataOfSource.x2);
                      }
                    });
                  }
                }
                if (dataOfSource.type === 'decision') {
                  console.log('input from decision');
                  steps[i].endX = step.x - dataOfSource.x2 - 3;
                  steps[i].endY = step.y - dataOfSource.y + 32;
                  steps[i].endY3 = step.y - dataOfSource.y + 32;
                } else if (dataOfSource.type === 'end') {
                  console.log('from end');
                  steps[i].endX = step.x - dataOfSource.x2 - 3 + 30;
                  steps[i].endY = step.y - dataOfSource.y + 39;
                  steps[i].endY3 = step.y - dataOfSource.y + 39;
                } else if (dataOfSource.type === 'start') {
                  console.log('from start');
                  steps[i].endX = step.x - dataOfSource.x2 - 3 + 74;
                  steps[i].endY = step.y - dataOfSource.y + 45;
                  steps[i].endY3 = step.y - dataOfSource.y + 45;
                } else {
                  steps[i].endX = step.x - dataOfSource.x2 - 3;
                  steps[i].endY = step.y - dataOfSource.y;
                  steps[i].endY3 = step.y - dataOfSource.y;
                }
                step.inputConnectors.push({ id: steps[i].id, point: 'end' });
              }
            }
          }
        } else if (step.type === 'start') {
          for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === 'line') {
              if (!steps[i].connected === true) {
                steps[i].connected = true;
                if (steps[i].parentId != step.parentId) {
                  steps[i].strokedash = 5;
                  steps[i].type = 'optionalline';
                }

                let dataOfSource;
                for (let x = 0; x < steps.length; x++) {
                  if (steps[x].outputConnectors.length > 0) {
                    steps[x].outputConnectors.forEach(item => {
                      if (item.id === steps[i].id) {
                        dataOfSource = steps[x];
                        console.log('Connceted line Source', dataOfSource);
                      }
                    });
                  }
                }
                if (dataOfSource.type === 'start') {
                  steps[i].endX = step.x - dataOfSource.x2 + 70;
                  steps[i].endY = step.y - dataOfSource.y;
                  steps[i].endY3 = step.y - dataOfSource.y;
                } else if (dataOfSource.type === 'end') {
                  steps[i].endX = step.x - dataOfSource.x2 + 28;
                  steps[i].endY = step.y - dataOfSource.y - 5;
                  steps[i].endY3 = step.y - dataOfSource.y - 5;
                } else if (dataOfSource.type === 'decision') {
                  steps[i].endX = step.x - dataOfSource.x2;
                  steps[i].endY = step.y - dataOfSource.y - 14;
                  steps[i].endY3 = step.y - dataOfSource.y - 14;
                } else if (dataOfSource.type === 'process') {
                  steps[i].endX = step.x - dataOfSource.x2;
                  steps[i].endY = step.y - dataOfSource.y - 45;
                  steps[i].endY3 = step.y - dataOfSource.y - 45;
                } else {
                  steps[i].endX = step.x - dataOfSource.x2 - 3;
                  steps[i].endY = step.y - dataOfSource.y;
                  steps[i].endY3 = step.y - dataOfSource.y;
                }
                step.inputConnectors.push({ id: steps[i].id, point: 'end' });
              }
            }
          }
        } else if (step.type === 'decision') {
          for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === 'line') {
              if (!steps[i].connected === true) {
                steps[i].connected = true;
                if (steps[i].parentId != step.parentId) {
                  steps[i].strokedash = 5;
                  steps[i].type = 'optionalline';
                }
                let dataOfSource;
                for (let x = 0; x < steps.length; x++) {
                  if (steps[x].outputConnectors.length > 0) {
                    steps[x].outputConnectors.forEach(item => {
                      if (item.id === steps[i].id) {
                        dataOfSource = steps[x];
                        console.log('Connceted line Source', dataOfSource);
                      }
                    });
                  }
                }
                if (dataOfSource.type === 'process') {
                  steps[i].endX = step.x - dataOfSource.x2 - 3;
                  steps[i].endY = step.y - dataOfSource.y - step.height / 2 - 5;
                  steps[i].endY3 = step.y - dataOfSource.y - step.height / 2 - 5;
                } else if (dataOfSource.type === 'start') {
                  steps[i].endX = step.x - dataOfSource.x2 + 70;
                  steps[i].endY = step.y - dataOfSource.y + 12;
                  steps[i].endY3 = step.y - dataOfSource.y + 12;
                } else if (dataOfSource.type === 'end') {
                  steps[i].endX = step.x - dataOfSource.x2 + 27;
                  steps[i].endY = step.y - dataOfSource.y + 7;
                  steps[i].endY3 = step.y - dataOfSource.y + 7;
                } else {
                  steps[i].endX = step.x - dataOfSource.x2 - 3;
                  steps[i].endY = step.y - dataOfSource.y;
                  steps[i].endY3 = step.y - dataOfSource.y;
                }
                step.inputConnectors.push({ id: steps[i].id, point: 'end' });
              }
            }
          }
        } else if (step.type === 'end') {
          for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === 'line') {
              if (!steps[i].connected === true) {
                steps[i].connected = true;
                if (steps[i].parentId != step.parentId) {
                  steps[i].strokedash = 5;
                  steps[i].type = 'optionalline';
                }
                let dataOfSource;
                for (let x = 0; x < steps.length; x++) {
                  if (steps[x].outputConnectors.length > 0) {
                    steps[x].outputConnectors.forEach(item => {
                      if (item.id === steps[i].id) {
                        dataOfSource = steps[x];
                        console.log('Connceted line Source', dataOfSource);
                      }
                    });
                  }
                }
                if (dataOfSource.type === 'start') {
                  steps[i].endX = step.x - dataOfSource.x2 + 100;
                  steps[i].endY = step.y - dataOfSource.y + 4;
                  steps[i].endY3 = step.y - dataOfSource.y + 4;
                } else if (dataOfSource.type === 'end') {
                  steps[i].endX = step.x - dataOfSource.x2 + 55;
                  steps[i].endY = step.y - dataOfSource.y;
                  steps[i].endY3 = step.y - dataOfSource.y;
                } else if (dataOfSource.type === 'decision') {
                  steps[i].endX = step.x - dataOfSource.x2 + 27;
                  steps[i].endY = step.y - dataOfSource.y - 8;
                  steps[i].endY3 = step.y - dataOfSource.y - 8;
                } else if (dataOfSource.type === 'process') {
                  steps[i].endX = step.x - dataOfSource.x2 + 27;
                  steps[i].endY = step.y - dataOfSource.y - 40;
                  steps[i].endY3 = step.y - dataOfSource.y - 40;
                } else {
                  steps[i].endX = step.x - dataOfSource.x2 - 3;
                  steps[i].endY = step.y - dataOfSource.y;
                  steps[i].endY3 = step.y - dataOfSource.y;
                }
                step.inputConnectors.push({ id: steps[i].id, point: 'end' });
              }
            }
          }
        }
        //  else if (step.type === 'terminator') {
        //   for (let i = 0; i < steps.length; i++) {
        //     if (steps[i].type === 'line') {
        //       if (!steps[i].connected === true) {
        //         steps[i].connected = true;
        //         if (steps[i].parentId != step.parentId) {
        //           steps[i].strokedash = 5;
        //           steps[i].type = 'optionalline';
        //         }
        //         let dataOfSource;
        //         for (let x = 0; x < steps.length; x++) {
        //           if (steps[x].outputConnectors.length > 0) {
        //             steps[x].outputConnectors.forEach(item => {
        //               if (item.id === steps[i].id) {
        //                 dataOfSource = steps[x];
        //                 console.log('Connceted line Source', dataOfSource);
        //               }
        //             });
        //           }
        //         }

        //         step.inputConnectors.push({ id: steps[i].id, point: 'end' });
        //       }
        //     }
        //   }
        // }
      });
    this.setState(
      {
        diagram: Object.assign({}, diagram, { steps }),
        clickStep: Object.assign({}, selected),
      },
      () => {
        this.props.diagramChange(this.state.diagram);
      },
    );
  };

  editElementStep = id => {
    const { diagram } = this.state;
    let step = diagram.steps.find(prod => {
      return prod.id === id;
    });
    this.setState({
      show: true,
      id: id,
      selectedStep: step,
    });
  };

  onChangeValue = (key, value) => {
    this.setState(
      prevState => ({
        ...prevState,
        selectedStep: {
          ...prevState.selectedStep,
          [key]: value,
        },
      }),
      () => this.updateProperty(true),
    );
  };
  onChangeCheck = (key, value) => {
    console.log(key, value, this.state.selectedStep);
    this.setState({selectedStep:{...this.state.selectedStep, [key]: value}}, () => console.log('rhisvv', this.state.selectedStep));
  };
  updateProperty = show => {
    const { diagram, selectedStep } = this.state;
    let steps = diagram.steps;
    let indexOf = steps.findIndex(step => step.id === selectedStep.id);
    steps[indexOf] = selectedStep;
    this.setState(
      prevState => ({
        ...prevState,
        diagram: {
          ...prevState.diagram,
          steps: steps,
        },
        show,
      }),
      () => {
        this.props.diagramChange(this.state.diagram);
      },
    );
  };

  renderEditElement() {
    const { selectedStep, value } = this.state;
    console.log('selected stepvalue', selectedStep);
    return (
      <>
        <Basicinfo value={value} index={0} />
        <Process value={0} index={1} />
      </>
    );
  }

  onClickSugessionElement = (_step, id, type) => {
    const { diagram, direction} = this.state;
    const randomId1 = this.generateId(4);
    const randomId2 = this.generateId(4);
    let x = _step.x + 150;
    let y = _step.y;

    if(direction === 'vertical') {
      const step2 = {
        id: randomId2,
        type: 'line',
        x: _step.x + 100,
        y: _step.y + 20,
        inputConnectors: [],
        outputConnectors: [],
        connected: true,
        startX: 0,
        startY: 0,
        endX2: 0,
        endY2: 0,
        endX3: 0,
        endY3: 0,
        endX: 15,
        endY: 60,
      };
  
      if (type === 'circle') {
        if (_step.type === 'start') {
          step2.x = _step.x + 26;
          step2.y = _step.y + 15;
          x = _step.x + 106;
          // y = _step.y + 108;
        }
        if (_step.type === 'end') {
          step2.x = _step.x + 70;
          x = _step.x + 150;
        }
        if (_step.type === 'process') {
          x = _step.x + 100;
          y = _step.y + 40;
          step2.x = _step.x + 220;
          step2.y = _step.y + 55;
        }
          
                const step1 = {
                  id: randomId1,
                  // parentId: _step.parentId,
                  type: 'start',
                  x,
                  y,
                  height: 28,
                  width: 28,
                  x1: x,
                  y1: y,
                  x2: x + 100,
                  y2: y + 40,
                  inputConnectors: [
                    {
                      id: randomId2,
                      // parentId: _step.parentId,
                      point: 'end',
                    },
                  ],
                  outputConnectors: [],
                };
                console.log('step1====', step1);
  
                this.setState(
                  {
                    diagram: Object.assign({}, diagram, {
                      steps: [...diagram.steps, step1, step2],
                    }),
                    clickStep: {},
                  },
                  () => {
                    this.props.diagramChange(this.state.diagram);
                  },
                );
                const step = diagram.steps.find(s => s.id === id);
                step.outputConnectors.push({
                  id: randomId2,
                  // parentId: _step.parentId,
                  point: 'start',
                });
            
        
      }
      if (type === 'twocircle') {
        if (_step.type === 'start') {
          step2.x = _step.x + 26;
          step2.y = _step.y + 15;
          x = _step.x + 76;
          y = _step.y - 8;
        }
        if (_step.type === 'end') {
          step2.x = _step.x + 70;
          x = _step.x + 120;
        }
        if (_step.type === 'process') {
          x = _step.x + 57;
          y = _step.y + 197;
          step2.x = _step.x + 107;
          step2.y = _step.y + 120;
        }
                const step1 = {
                  id: randomId1,
                  parentId: _step.parentId,
                  type: 'end',
                  x,
                  y,
                  height: 40,
                  width: 100,
                  x1: x,
                  y1: y,
                  x2: x + 100,
                  y2: y + 40,
                  inputConnectors: [
                    {
                      id: randomId2,
                      point: 'end',
                    },
                  ],
                  outputConnectors: [],
                };
                console.log('End step1====', step1);
                this.setState(
                  {
                    diagram: Object.assign({}, diagram, {
                      steps: [...diagram.steps, step1, step2],
                    }),
                    clickStep: {},
                  },
                  () => {
                    this.props.diagramChange(this.state.diagram);
                  },
                );
                const step = diagram.steps.find(s => s.id === id);
                step.outputConnectors.push({
                  id: randomId2,
                  point: 'start',
                });
      }
      if (type === 'rect') {
        // console.log("step2====", step2);
        if (_step.type === 'start') {
          step2.x = _step.x + 15;
          step2.y = _step.y + 20;
          x = _step.x - 90;
          y = _step.y + 98;
        }
        if (_step.type === 'end') {
          step2.x = _step.x + 70;
          x = _step.x + 150;
          y = _step.y - 40;
        }
        if (_step.type === 'process') {
          step2.x = _step.x + 107;
          step2.y = _step.y + 120;
          x = _step.x - 0;
          y = _step.y + 200;

          if(_step.outputConnectors.length >= 1) {
            console.log("working....");
            const step1 = {
              id: randomId1,
              type: 'process',
              x,
              y,
              height: 120,
              width: 220,
              innerRectHeight: 20,
              innerRectWidth: 20,
              // d: 'M0,30 L220 30',
              rx: 6,
              x1: x,
              y1: y,
              x2: x + 220,
              y2: y + 120,
              inputConnectors: [
                {
                  id: randomId2,
                  point: 'end',
                },
              ],
              outputConnectors: [],
            };
            console.log('Process step1====', step1);
            this.setState(
              {
                diagram: Object.assign({}, diagram, {
                  steps: [...diagram.steps, step2, step1],
                }),
                clickStep: {},
              },
              () => {
                console.log("1")
                console.log("firststate====", diagram.steps)

                for(let i = 0; i < diagram.steps.length; i++) {
                  if(diagram.steps[i].id === id) {
                    console.log("2")
                    for(let j = i + 1; j < diagram.steps.length; j++){
                      console.log("diagramsteps====", diagram.steps[j].id, diagram.steps[j].y)
                          diagram.steps[j].y += 200
                    }
                  }
                }
                this.props.diagramChange(this.state.diagram);
              },
            );

            this.setState(
              {
                diagram: diagram,
                clickStep: {},
              },
              () => {
                console.log("4")
                console.log("secondstate====", diagram.steps)
                for(let i = 0; i < diagram.steps.length; i++) {
                  if(diagram.steps[i].id === id) {
                    console.log("3")
                    diagram.steps.splice(i + 1, 0, step2);
                    diagram.steps.splice(i + 2, 0, step1);
                  }
                }
                this.props.diagramChange(this.state.diagram);
              },
            );

            const step = diagram.steps.find(s => s.id === id);
            step.outputConnectors.push({
              id: randomId2,
              point: 'start',
            });
            step1.outputConnectors.push({id: _step.outputConnectors[0].id, point: 'start'})
                              this.deleteById(
                    _step.outputConnectors,
                    'id',
                    _step.outputConnectors[0].id,
                  );
            return;
          }
        }


                const step1 = {
                  id: randomId1,
                  type: 'process',
                  x,
                  y,
                  height: 120,
                  width: 220,
                  innerRectHeight: 20,
                  innerRectWidth: 20,
                  // d: 'M0,30 L220 30',
                  rx: 6,
                  x1: x,
                  y1: y,
                  x2: x + 220,
                  y2: y + 120,
                  inputConnectors: [
                    {
                      id: randomId2,
                      point: 'end',
                    },
                  ],
                  outputConnectors: [],
                };
                console.log('Process step1====', step1);
                this.setState(
                  {
                    diagram: Object.assign({}, diagram, {
                      steps: [...diagram.steps, step2, step1],
                    }),
                    clickStep: {},
                  },
                  () => {
                    this.props.diagramChange(this.state.diagram);
                  },
                );
                const step = diagram.steps.find(s => s.id === id);
                step.outputConnectors.push({
                  id: randomId2,
                  point: 'start',
                });

      }
      if (type === 'delete') {
        const { diagram } = this.state;
        console.log('delete', id);
        console.log(diagram.steps);
        for (let i = 0; i < diagram.steps.length; i++) {
          if (diagram.steps[i].id === id) {
            if (
              diagram.steps[i].inputConnectors.length > 0 &&
              diagram.steps[i].outputConnectors.length > 0
            ) {
              console.log("sdafsfsfasf")
              for (let j = 0; j < diagram.steps.length; j++) {
                // if (diagram.steps[j].outputConnectors.length > 0) {
                //   if (
                //     diagram.steps[j].outputConnectors[0].id === diagram.steps[i].inputConnectors[0].id
                //   ) {
                //     this.deleteById(
                //       diagram.steps[j].outputConnectors,
                //       'id',
                //       diagram.steps[i].inputConnectors[0].id,
                //     );
                //   }
                // }
                if (diagram.steps[j].inputConnectors.length > 0) {
                  if (
                    diagram.steps[j].inputConnectors[0].id === diagram.steps[i].outputConnectors[0].id
                  ) {
                    this.deleteById(
                      diagram.steps[j].inputConnectors,
                      'id',
                      diagram.steps[i].outputConnectors[0].id,
                    );
                  }
                }
                // if(diagram.steps[j].id === diagram.steps[i].inputConnectors[0].id){
                  for(let k = 0; k < diagram.steps.length; k++) {
                      if(diagram.steps[k].type === 'process') {
                        if(diagram.steps[k].inputConnectors.length < 1) 
                        diagram.steps[k].inputConnectors.push({id: diagram.steps[i].inputConnectors[0].id , point: 'end'})
                        // diagram.steps[j].endY = diagram.steps[k].y2 - diagram.steps[k].y1 + diagram.steps[k].height
                        for(let l = 0; l < diagram.steps.length; l++) {
                          if(diagram.steps[l].outputConnectors.length > 0){
                            if(diagram.steps[l].outputConnectors[0].id === diagram.steps[i].inputConnectors[0].id){
                              for(let m = 0; m < diagram.steps.length; m++) {
                                if(diagram.steps[m].id === diagram.steps[i].inputConnectors[0].id){
                                  diagram.steps[m].endY = diagram.steps[k].y1 - diagram.steps[l].y2 - 18;
                                }
                              }
                            }
                          }

                        }

                        
                      }
                  }
                // }

              }
              // this.deleteById(diagram.steps, 'id', diagram.steps[i].inputConnectors[0].id);
              this.deleteById(diagram.steps, 'id', diagram.steps[i].outputConnectors[0].id);
              this.deleteById(diagram.steps, 'id', diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              return;
            } else if (
              diagram.steps[i].inputConnectors.length > 0 &&
              diagram.steps[i].outputConnectors.length < 1
            ) {
              for (let j = 0; j < diagram.steps.length; j++) {
                if (diagram.steps[j].outputConnectors.length > 0) {
                    if (
                      diagram.steps[j].outputConnectors[0].id === diagram.steps[i].inputConnectors[0].id
                    ) {
                      this.deleteById(
                        diagram.steps[j].outputConnectors,
                        'id',
                        diagram.steps[i].inputConnectors[0].id,
                      );
                      this.deleteById(diagram.steps, 'id', diagram.steps[i].inputConnectors[0].id);
                      this.deleteById(diagram.steps, 'id', id);
                    }
                }
              }

              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              return;
            } else if (
              diagram.steps[i].inputConnectors.length < 1 &&
              diagram.steps[i].outputConnectors.length > 0
            ) {
              for (let j = 0; j < diagram.steps.length; j++) {
                if (diagram.steps[j].inputConnectors.length > 0) {
                  if (
                    diagram.steps[j].inputConnectors[0].id === diagram.steps[i].outputConnectors[0].id
                  ) {
                    this.deleteById(
                      diagram.steps[j].inputConnectors,
                      'id',
                      diagram.steps[i].outputConnectors[0].id,
                    );
                  }
                }
              }
              this.deleteById(diagram.steps, 'id', diagram.steps[i].outputConnectors[0].id);
              this.deleteById(diagram.steps, 'id', diagram.steps[i].id);
  
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              return;
            } else {
              this.deleteById(diagram.steps, 'id', id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              return;
            }
          }
        }
      }
    }


  };
  deleteById = (arr, attr, value) => {
    let i = arr.length;
    while (i--) {
      if (arr[i] && arr[i].hasOwnProperty(attr) && arr[i][attr] === value) {
        arr.splice(i, 1);
      }
    }
    return arr;
  };
  increasePoolWidth = (arr, attr, value) => {
    let i = arr.length;
    while (i--) {
      if (arr[i] && arr[i].hasOwnProperty(attr) && arr[i][attr] === value) {
        arr[i].width += 200;
      }
    }
    return arr;
  };
  onCanvasClick = () => {
    this.setState({
      clickStep: {},
      selectStep: {},
      show: false,
    });
  };
  saveWorkflow = () => {
    const { diagram } = this.state;
    this.props.onSaveWorkflow({
      name: 'test',
      description: 'test',
      appId: '5f995ad426063f37f0ff604d',
      diagram: JSON.stringify(diagram),
    });
  };
  handleClickOpen = () => {
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  onChangeProperty = (name, value) => {
    console.log('======name', name);
    console.log('======value', value);
    /// TODO save to digram
    console.log('====state', this.state.diagram);
  };

  render() {
    const { editable, subType } = this.props;
    const {
      diagram,
      isMouseOver,
      rectangularSelection,
      selected,
      toolbarHeight,
      arrow,
      mouseOverConnection,
      mouseOverStep,
      clickStep,
      selectedStep,
      isVertical,
      isHorizontal,
    } = this.state;
    // console.log('diagram====', this.state);
    // console.log('Props ====', this.props);
    const { height, width } = diagram;
    const containerStyle = {
      height: toolbarHeight + height,
      width,
      display: 'flex',
    };
    console.log('selectedStep>>>', selectedStep);

    return editable ? (
      <>
      <g
        onMouseDown={this.onMouseDown}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseMove={this.onMouseMove}
        onMouseUp={this.onMouseUp}
        style={containerStyle}
      >
        {/* <Toolbar dropToolbarIcon={this.dropToolbarIcon} dragToolbarIcon={this.dragToolbarIcon} /> */}
        <ErrorBoundary>
          <Canvas
            arrow={arrow}
            diagram={diagram}
            clickStep={clickStep}
            setClickStep={this.setClickStep}
            moveArrow={this.moveArrow}
            onDragConnection={this.onDragConnection}
            editElementStep={id => this.editElementStep(id)}
            mouseOverConnection={mouseOverConnection}
            setMouseOverConnection={this.setMouseOverConnection}
            mouseOverStep={mouseOverStep}
            setMouseOverStep={this.setMouseOverStep}
            rectangularSelection={rectangularSelection}
            selected={selected}
            selectStep={this.selectStep}
            stopDragging={this.stopDragging}
            onClickSugessionElement={this.onClickSugessionElement}
            onCanvasClick={() => this.onCanvasClick()}
          />
        </ErrorBoundary>
        {this.state.show && (
          <Modal
            {...this.props}
            open={this.state.show}
            onClose={this.handleClose}
            // {...this.state.diagram[id]}
            selectedstep={selectedStep}
            subtype={subType}
            onChange={e => this.onChangeValue(e.target.name, e.target.value)}
            onChangeValue={(name, value) => this.onChangeProperty(name, value)}
            onChangeCheck={this.onChangeCheck}
            // onchangevalue={(name, value) => this.onChangeProperty(name, value)}
          />
        )}
        {/* <button className="btn" onClick={() => this.saveWorkflow()}>
          Save
        </button> */}
      </g>
      </>
    ) : (
      <>
      <Canvas diagram={diagram} />
      </>
    );
  }
}

export default hot(withRouter(Redux(Workflow)));
