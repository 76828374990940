import { ActionTypes } from 'constants/index';

export const getGroups = appId => dispatch => {
  return dispatch({ type: ActionTypes.GET_GROUPS, payload: appId });
};

export const saveGroup = data => dispatch => {
  return dispatch({ type: ActionTypes.SAVE_GROUP, payload: data });
};

export const getGroup = params => dispatch => {
  return dispatch({ type: ActionTypes.GET_GROUP, payload: params });
};

export const deleteGroup = id => dispatch => {
  return dispatch({ type: ActionTypes.DELETE_GROUP, payload: id });
};

export const updateGroup = data => dispatch => {
  return dispatch({ type: ActionTypes.UPDATE_GROUP, payload: data });
};

export const newGroup = () => dispatch => {
  const data = {
  };
  return dispatch({ type: ActionTypes.GET_GROUP_SUCCESS, payload: data });
};
