import React from 'react';
import ConnectionPoint from './ConnectionPoint';
import Step from './Step';
import SuggestionRect from "./SuggestionRect";

export default class Terminator extends Step {
  render() {
    const {
      createArrow,
      height,
      width,
      x,
      y,
      multipleSelection,
      selected,
      mouseOverConnection,
      mouseOverStep,
      setMouseOverStep,
      setMouseOverConnection,
      selectStep,
      stopDragging,
      onClickSugessionElement,
      clickStep,
      setClickStep,
      editElementStep,
      name,
      bgColor,
      moveArrow,
    } = this.props;

    // const showConnectionPoints =
    //   (selected && !multipleSelection) || mouseOverStep;
    const showConnectionPoints = clickStep;

    const style = this.getStyle();

    const rectStyle = Object.assign({}, style, {
      strokeDasharray: `${width - height} ${height}`
    });

    const halfHeight = height / 2;
    const halfWidth = width / 2;

    return (
      <g
        onMouseOver={setMouseOverStep}
        onMouseLeave={setMouseOverStep}
        onMouseDown={selectStep}
        onMouseUp={stopDragging}
        transform={`translate(${x},${y})`}
      >
        <rect
        onClick={setClickStep}
          onDoubleClick={editElementStep}
          x={halfHeight}
          height={height}
          onMouseMove={moveArrow}
          // style={rectStyle}
          style={{
            fill: bgColor === undefined ? "white" : bgColor,
            fontSize: 14,
            stroke: "#000",
            strokeWidth: 2,
            strokeDasharray: `${width - height} ${height}`
          }}
          width={width - height}
        />
        <path
          d={`M${halfHeight},0 A${halfHeight},${halfHeight} 0 0,0 ${halfHeight},${height}`}
          // style={style}
          style={{
            fill: bgColor === undefined ? "white" : bgColor,
            fontSize: 14,
            stroke: "#000",
            strokeWidth: 2,
          }}
        />
        <path
          d={`M${width -
            halfHeight},0 A${halfHeight},${halfHeight} 0 0,1 ${width -
            halfHeight},${height}`}
          // style={style}
          style={{
            fill: bgColor === undefined ? "white" : bgColor,
            fontSize: 14,
            stroke: "#000",
            strokeWidth: 2,
          }}
        />
        <text class="cls-18" transform="translate(35,25)">
          {name}
        </text>

        {showConnectionPoints ? (
          <ConnectionPoint
            setMouseOverConnection={setMouseOverConnection}
            cx={0}
            cy={halfHeight}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            setMouseOverConnection={setMouseOverConnection}
            cx={halfWidth}
            cy={0}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            setMouseOverConnection={setMouseOverConnection}
            cx={width}
            cy={halfHeight}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            setMouseOverConnection={setMouseOverConnection}
            cx={halfWidth}
            cy={height}
          />
        ) : null}
        {showConnectionPoints && (
          <SuggestionRect
            x={width + 10}
            y={-10}
            height={100}
            width={50}
            onClickSugessionElement={onClickSugessionElement}
          />
        )}
      </g>
    );
  }
}

Terminator.defaultProps = Step.defaultProps;
