import React from 'react'
import { Button, FormLabel, Grid } from '@material-ui/core'

const Headers = (props) => {
	if (props.elementsData.data && props.elementsData.data.data
		&& Array.isArray(props.elementsData.data.data.headers)) {
		return props.elementsData.data.data.headers.map((el, index) => {
			return (
				<React.Fragment key={index} >
					<Grid style={{ "padding": "10px" }} item xs={4}>
						<FormLabel >{el.label}</FormLabel>
					</Grid>
					<Grid style={{ "padding": "10px" }} item xs={4}>
						<FormLabel >{el.value}</FormLabel>
					</Grid>
					<Grid style={{ "padding": "10px" }} item xs={4}>
						<Button variant="contained" color="secondary" onClick={() => {
							props.removeKeyValuePair(el, "values");
						}} >-</Button>
					</Grid>
				</React.Fragment>
			)
		})
	}
	return <></>
}
export default Headers