import React from 'react'

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		"paddingRight": "10px",
	},
	staticText: {
		"width": "45%",
		"top": "50%"
	},
	radioStyle: {

		left: `19%`,
	}
	,
	checkStyle: {
		"paddingLeft": '19px',
		width: '20%'
	},
	modal: {
		top: `30%`,
		left: `30%`,
		transform: `translate(-30%, -30%)`,
		position: 'absolute',

	},
	dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
        minWidth: '80%',
        maxWidth: '80%',
    },
})

export default styles