import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 200
	}
});

function DateAndTimePickers(props) {
	const { classes } = props;

	return (
		<form className={classes.container} noValidate>
			<TextField
				id="datetime-local"
				label={props.label}
				type="datetime-local"
				name={props.name}
				defaultValue={props.defaultValue}
				value={props.value}
				className={classes.textField}
				onChange={props.onChange}
				InputLabelProps={{
					shrink: true
				}}
			/>
		</form>
	);
}

DateAndTimePickers.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DateAndTimePickers);
