import React from 'react';
import Step from './Step';
import '../style/toolbarItem.css';
export default class DraggableToolbarIcon extends React.Component {
  render() {
    const { dropToolbarIcon, dragToolbarIcon, height, Item, width } = this.props;
    const margin = Item.defaultProps.style.strokeWidth;
    return (
      <div
        draggable
        onDragStart={dragToolbarIcon(Item)}
        onDragEnd={dropToolbarIcon(Item)}
        style={{
          height: 40,
          width: 40,
          opacity: 0.9,
        }}
      >
        <svg height={40} width={40}>
          <Item
            x={margin}
            y={margin}
            height={height - 10 - 2 * margin}
            width={width - 10 - 2 * margin}
          />
        </svg>
      </div>
    );
  }
}

DraggableToolbarIcon.defaultProps = Step.defaultProps;
