import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
	FormControl, InputLabel, Checkbox, FormControlLabel, FormLabel, TextField, Button, Chip,
	Paper, Grid, Typography, makeStyles, Box, AppBar, Tabs, Tab, Divider, Select, Input,
	MenuItem, RadioGroup, Radio
} from '@material-ui/core'
import Loading from 'components/Loading'
import 'styles/styles.scss'
import NetworkDetails from './NetworkDetails'
import { TabPanel } from '../@tabpanel/tabpanel'
import rightarrow from 'images/rightarrow.svg'

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	}
}))

const View = (props) => {
	const classes = useStyles()
	const [value, setValue] = React.useState(0)

	const { form, roles } = props.state

	const handleChange = (event, newValue) => {
		setValue(newValue)
	};
	return (
		<React.Fragment>
			<Box className="quickwizard-page">
				<Grid container>
					<Grid item xs={12} md={6}>
						<div className="backbtn">
							<div className="backbtn ptl-20">
								<Link to={`/builder/${props.match.params.dappId}/networks`}
									style={{ textDecoration: 'none', color: "#1B2A47" }}>
									<img src={rightarrow} alt="backicon" className="backicon" />
									<Box className="companyheadingtext">
										<Typography variant="h4">{"Network List"}</Typography>
									</Box>
								</Link>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} md={6} className="savebtn-sec">
						{props.match.params.networkId === 'new'
							? <Button
								className="whitespace-no-wrap addnewentrybtn mb5"
								variant="contained" color="primary"
								disabled={!props.canBeSubmitted()}
								onClick={() => props.saveNetwork()}>
								{"Save"}
							</Button>
							: <Button
								className="whitespace-no-wrap addnewentrybtn mb5"
								variant="contained" color="primary"
								disabled={!props.canBeSubmitted()}
								onClick={() => props.updateNetwork()}>
								{"Update"}
							</Button>
						}
					</Grid>
				</Grid>
				<Box className="pxy20">
					<div className={classes.root} >
						<Box px="1.5rem">
							<AppBar position="static" className="bg-white">
								<Tabs value={value} onChange={handleChange}
									aria-label="simple tabs example" className="quicktabs-sec">
									<Tab label="Basic Info" {...a11yProps(0)} />
									<Tab label="Policies" {...a11yProps(1)} />
									<Tab label="Business" {...a11yProps(2)} />
								</Tabs>
								<Divider />
							</AppBar>
							<TabPanel value={value} index={0}>
								<NetworkDetails {...props} classes={classes} />
							</TabPanel>
							<TabPanel value={value} index={1}>
								<Grid container>
									<Grid item sm={12}>
										<form className="form" className="policies-frm" noValidate autoComplete="off">
											<FormLabel component="legend">{"Onboarding New Participants"}</FormLabel>
											<RadioGroup
												aria-label="offBoardingPolicy"
												name="offBoardingPolicy"
												className={classes.group}
												value={form.offBoardingPolicy ? form.offBoardingPolicy : ''}
												onChange={props.handleChange}
												row>
												<FormControlLabel value="ONALLPARTICIPANTSIGNS"
													control={<Radio />}
													label="All participants in the network must be signed" />
												<br />
												<FormControlLabel value="ONMAJPARTICIPANTSIGNS" control={<Radio />}
													label="Majority of participants must be signed" /><br />
												<FormControlLabel value="ONPRIMARYPARTICIPANTSIGNS" control={<Radio />}
													label="Primary participant decides" /><br />
												<FormControlLabel value="ONONEPARTICIPANTSIGN" control={<Radio />}
													label="Atleast one participant must be signed" /><br />
												<FormControlLabel value="ONNOSIGN" control={<Radio />}
													label="No signaure required. Any one can join by invitation" />
											</RadioGroup>
											<br />
											<FormLabel component="legend">{"Offboarding Participants"}</FormLabel>
											<RadioGroup
												aria-label="onBoardingPolicy"
												name="onBoardingPolicy"
												className={classes.group}
												value={form.onBoardingPolicy ? form.onBoardingPolicy : ''}
												onChange={props.handleChange}
												row>
												<FormControlLabel value="OFFALLPARTICIPANTSIGNS" control={<Radio />}
													label="All participants in the network must be signed" /><br />
												<FormControlLabel value="OFFMAJPARTICIPANTSIGNS" control={<Radio />}
													label="Majority of participants must be signed" /><br />
												<FormControlLabel value="OFFPRIMARYPARTICIPANTSIGNS" control={<Radio />}
													label="Primary participant decides" /><br />
												<FormControlLabel value="OFFONEPARTICIPANTSIGN" control={<Radio />}
													label="Atleast one participant must be signed" /><br />
												<FormControlLabel value="OFFNOSIGN" control={<Radio />}
													label="No signaure required. Any one can join by invitation" />
											</RadioGroup>
										</form>
									</Grid>
								</Grid>
							</TabPanel>
						</Box>
					</div>

				</Box>
			</Box>
		</React.Fragment>
	)
}

export default View