import React from 'react';
import { withStyles, Button, Tab, Tabs, TableHead, TableRow, TextField, InputAdornment, Icon, Select, MenuItem, Typography, FormControl, FormGroup, Checkbox, RadioGroup, FormControlLabel, Radio, FormLabel, Table, TableCell, TableBody, Input, Chip } from '@material-ui/core';

const dataCleaningTools = [
	{ "id": "3432432", "name": "Duplicate Record Eliminator" },
	{ "id": "3432433", "name": "Missing Data Eliminator" },
	{ "id": "3432434", "name": "Wrong Data Eliminator" },
	{ "id": "3432435", "name": "Unwanted Data Eliminator" },
	{ "id": "3432436", "name": "Entity Name Validator" },
	{ "id": "3432437", "name": "Entity Name Unifier" },
	{ "id": "3432438", "name": "Location Validator" },
	{ "id": "3432439", "name": "Location Unifier" },
]

const dataQualityTools = [
	{ "id": "3432432", "name": "Name Format Standardizer" },
	{ "id": "3432433", "name": "Address Format Standardizer" },
	{ "id": "3432434", "name": "Email Format Standardizer" },
	{ "id": "3432435", "name": "Phone Number Standardizer" },
	{ "id": "3432436", "name": "Currency Format Standardizer" },
	{ "id": "3432437", "name": "Date Format Standardizer" },
	{ "id": "3432438", "name": "Invoice Number Validator" },
	{ "id": "3432439", "name": "Date Dependecy Checker" },
]

const dataModels = [
	{ "id": "3432432", "name": "Order" },
	{ "id": "3432433", "name": "Invoice" },
	{ "id": "3432434", "name": "Contract" }
]

export default function PipelineDetails(props) {
	const { state } = props;
	const { classes } = props;
	console.log("PipelineDetails", state);
	return (
		state && (
			<div className="p-16 sm:p-24 max-w-2xl">
				<form className="form" noValidate autoComplete="off" >
					<FormControl>
						<FormLabel variant="h6" className="label-headings">Name *</FormLabel>
						<TextField
							className="form-control"
							error={state.name === ''}
							autoFocus
							id="name"
							name="name"
							value={state.name}
							onChange={props.handleChange}
							variant="outlined"
							color="secondary"
							placeholder="Enter Name"
							required
							fullWidth
						/>
					</FormControl>

					<FormControl>
						<FormLabel variant="h6" className="label-headings">Description</FormLabel>
						<TextField
							className="form-control"
							id="description"
							name="description"
							multiline
							rows={2}
							value={state.description}
							onChange={props.handleChange}
							variant="outlined"
							color="secondary"
							placeholder="Enter Description"
							required
							fullWidth
						/>
					</FormControl>

					<FormGroup row>
						<FormControlLabel
							control={
								<Checkbox
									checked={state.useCustom}
									onChange={props.handleChange}
									value="useCustom"
								/>
							}
							label="use Custom JSON"
						/>
					</FormGroup>

					<FormControl>
						<FormLabel variant="h6" className="label-headings">Pipeline JSON</FormLabel>
						<TextField
							className="form-control"
							id="pipelinejson"
							name="pipelinejson"
							multiline
							rows={30}
							value={state.pipelinejson}
							onChange={props.handleChange}
							variant="outlined"
							color="secondary"
							placeholder="Enter Pipeline JSON"
							required
							fullWidth
						/>
					</FormControl>

					{/* <FormControl>
                        <Typography>Select Datamodel</Typography>
                        <br />
                        <Select name="datamodels" className="form-control" id="grouped-native-select" variant="outlined" multiple onChange={(e) => props.handleChipChange(e, 'dataModels')} value={form.dataModels} input={<Input />}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected && selected.map((item, i) => (
                                        <Chip key={i} label={item} className={classes.chip} />
                                    ))}
                                </div>
                            )}>
                            {dataModels && dataModels.map((item, i) => (
                                <MenuItem key={i} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <Typography>Select DataCleaningTools</Typography>
                        <br />
                        <Select name="dataCleaningTools" className="form-control" id="grouped-native-select" variant="outlined" multiple onChange={(e) => props.handleChipChange(e, 'dataCleaningTools')} value={form.dataCleaningTools} input={<Input />}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected && selected.map((item, i) => (
                                        <Chip key={i} label={item} className={classes.chip} />
                                    ))}
                                </div>
                            )}>
                            {dataCleaningTools && dataCleaningTools.map((item, i) => (
                                <MenuItem key={i} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <Typography>Select DataQualityTools</Typography>
                        <br />
                        <Select name="dataQualityTools" className="form-control" id="grouped-native-select" variant="outlined" multiple onChange={(e) => props.handleChipChange(e, 'dataQualityTools')} value={form.dataQualityTools} input={<Input />}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected && selected.map((item, i) => (
                                        <Chip key={i} label={item} className={classes.chip} />
                                    ))}
                                </div>
                            )}>
                            {dataQualityTools && dataQualityTools.map((item, i) => (
                                <MenuItem key={i} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
				</form>
			</div>
		)
	)
}