import React from 'react';
import ConnectionPoint from './ConnectionPoint';
import SuggestionRect from './SuggestionRect';
import Step from './Step';
import '../style/elementText.css';
export default class Process extends Step {
  render() {
    const {
      createArrow,
      height,
      width,
      x,
      y,
      rx = 6,
      multipleSelection,
      selected,
      selectStep,
      stopDragging,
      mouseOverConnection,
      setMouseOverConnection,
      mouseOverStep,
      setMouseOverStep,
      onClickSugessionElement,
      setOnClickConnection,
      setClickStep,
      clickStep,
      editElementStep,
      name,
      bgColor,
      onDragConnection,
      moveArrow,
    } = this.props;

    // const showConnectionPoints =
    //   (selected && !multipleSelection) || mouseOverStep;
    const showConnectionPoints = clickStep;
    // const style = this.getStyle();
    const halfHeight = height / 2;
    const halfWidth = width / 2;
    return (
      <g
        onMouseOver={setMouseOverStep}
        onMouseLeave={setMouseOverStep}
        onMouseDown={selectStep}
        onMouseUp={stopDragging}
        transform={`translate(${x},${y})`}
        className="processText"
      >
        <rect
          className="processText"
          onClick={setClickStep}
          onDoubleClick={editElementStep}
          height={height}
          onMouseMove={moveArrow}
          // style={style}
          style={{
            fill: bgColor === undefined ? 'white' : bgColor,
            fontSize: 14,
            stroke: '#000',
            strokeWidth: 2,
          }}
          width={width}
          rx={rx}
        />

        <foreignObject
          width="100"
          height="80"
          className="processText"
          onDoubleClick={editElementStep}
          onClick={setClickStep}
          onMouseMove={moveArrow}
        >
          <p>{name}</p>
        </foreignObject>

        {showConnectionPoints && (
          <SuggestionRect
            x={width + 10}
            y={-10}
            height={100}
            width={50}
            onClickSugessionElement={onClickSugessionElement}
          />
        )}
        {showConnectionPoints ? (
          <ConnectionPoint
            onDragConnection={e => onDragConnection(e, 'left')}
            setMouseOverConnection={setMouseOverConnection}
            cx={0}
            cy={halfHeight}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            onDragConnection={e => onDragConnection(e, 'top')}
            setMouseOverConnection={setMouseOverConnection}
            cx={halfWidth}
            cy={0}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            onDragConnection={e => onDragConnection(e, 'right')}
            setMouseOverConnection={setMouseOverConnection}
            cx={width}
            cy={halfHeight}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            onDragConnection={e => onDragConnection(e, 'bottom')}
            setMouseOverConnection={setMouseOverConnection}
            cx={halfWidth}
            cy={height}
            setOnClickConnection={setOnClickConnection}
          />
        ) : null}
      </g>
    );
  }
}

Process.defaultProps = Step.defaultProps;
