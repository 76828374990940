import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Radar } from 'react-chartjs-2';
import 'styles/page-designer.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'

const styles = theme => ({});

class PageDesignerRadarChart extends Component {
	render() {
		let { data, width, height, error } = this.props.element.chartData;

		if (error) {
				return <div className="errorDisplayCenter">{error}</div>
		} else {
				return(<div
					id={`RadarChartContainer${this.props.element.chartUniqueId}`}
					style={{ height: 'inherit', width: 'inherit' }}
				>
					<Radar
						id={`RadarChartElement${this.props.element.chartUniqueId}`}
						className="text"
						width={'inherit'}
						height={'inherit'}
						data={data}
						options={{
							maintainAspectRatio: false
						}}
					/>
				</div>)
		}
	}
}

function collect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource()
	};
}

const cardSource = {
	beginDrag(props) {
		const item = { id: props.element.chartUniqueId };
		return item;
	}
};

export default withStyles(styles, { withTheme: true })(
	withRouter(connect(null,null)(PageDesignerRadarChart))
);
