import React, { Component } from 'react';
import {
	TextField, FormControl, Select, FormControlLabel, Divider,
	MenuItem, Checkbox, Radio, Typography, RadioGroup, FormLabel
} from '@material-ui/core';
import 'styles/page-designer.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as Actions from 'actions';
import _ from 'modules/lodash';
import BackgroundAdd from "./BackgroundAdd";
import BackgroundsTable from "./BackgroundsTable";

let collageType1 = 'assets/images/dsilo/collages/collage-type-1.png';
let collageType2 = 'assets/images/dsilo/collages/collage-type-2.png';
let collageType3 = 'assets/images/dsilo/collages/collage-type-3.png';
let collageType4 = 'assets/images/dsilo/collages/collage-type-4.png';

class UI extends Component {
	render() {
		let props = this.props;
		const { dataModels, classes, values, entryForms } = this.props;
		// console.log("values.selectedGroupingDataElement.length > 0", values.selectedGroupingDataElement.length > 0
		// 	, values.selectedGroupingDataElement.value
		// 	, values.isCardCategories);

		return (
			<>
				<div className="pageModelUI">
				<FormControl>
           		 <FormLabel variant="h6" className="label-headings">
             	 Style Object
            	</FormLabel>
				<TextField
					className="mt-8 mb-16 ml-0"
					// label="Style Object"
					id="style"
					name="style"
					value={this.props.values.style}
					onChange={this.props.onChangeHandler()}
					variant="outlined"
					// fullWidth
				/>
				</FormControl>
				<FormControl>
           		 <FormLabel variant="h6" className="label-headings">
            	  Class Name
            	</FormLabel>
				<TextField
					className="mt-8 mb-16 ml-0"
					// label="Class Name"
					id="className"
					name="className"
					value={this.props.values.className}
					onChange={this.props.onChangeHandler()}
					variant="outlined"
					// fullWidth
				/>
				</FormControl>
				{!this.props.checkForNullOrUndefined(this.props.values.borderColor) && (
					  <FormControl>
					  <FormLabel variant="h6" className="label-headings">
						BackGround Color
					  </FormLabel>
					<TextField
						className="mt-8 mb-16"
						// label="BackGround Color"
						id="backgroundColor"
						name="backgroundColor"
						value={this.props.values.backgroundColor}
						onChange={this.props.onChangeHandler()}
						variant="outlined"
						// fullWidth
					/>
					</FormControl>
				)}
				{!this.props.checkForNullOrUndefined(this.props.values.borderColor) && (
					<FormControl>
					<FormLabel variant="h6" className="label-headings">
					  Border Color
					</FormLabel>
					<TextField
						className="mt-8 mb-16"
						// label="Border Color"
						id="borderColor"
						name="borderColor"
						value={this.props.values.borderColor}
						onChange={this.props.onChangeHandler()}
						variant="outlined"
						// fullWidth
					/>
					</FormControl>
				)}
				{!this.props.checkForNullOrUndefined(this.props.values.borderWidth) && (
					  <FormControl>
					  <FormLabel variant="h6" className="label-headings">
						Border Width
					  </FormLabel>
					<TextField
						className="mt-8 mb-16"
						// label="Border Width"
						id="borderWidth"
						name="borderWidth"
						value={this.props.values.borderWidth}
						onChange={this.props.onChangeHandler()}
						variant="outlined"
						// fullWidth
					/>
					</FormControl>
				)}
				{!this.props.checkForNullOrUndefined(this.props.values.hoverBackgroundColor) && (
					<FormControl>
					<FormLabel variant="h6" className="label-headings">
					  Hover BackGround Color
					</FormLabel>
					<TextField
						className="mt-8 mb-16"
						// label="Hover BackGround Color"
						id="hoverBackgroundColor"
						name="hoverBackgroundColor"
						value={this.props.values.hoverBackgroundColor}
						onChange={this.props.onChangeHandler()}
						variant="outlined"
						// fullWidth
					/>
					</FormControl>
				)}
				{!this.props.checkForNullOrUndefined(this.props.values.hoverBorderColor) && (
					<FormControl>
					<FormLabel variant="h6" className="label-headings">
					  BackGround Color
					</FormLabel>
					<TextField
						className="mt-8 mb-16"
						// label="BackGround Color"
						id="hoverBorderColor"
						name="hoverBorderColor"
						value={this.props.values.hoverBorderColor}
						onChange={this.props.onChangeHandler()}
						variant="outlined"
						// fullWidth
					/>
					</FormControl>
				)}
				<br />
				{!this.props.checkForNullOrUndefined(this.props.dataModels)
					&& this.props.values.selectedDataModel
					&& this.props.values.dataHandler === "data_models"
					&& (this.props.chartType === Actions.listChart
						|| this.props.chartType === Actions.simpleTable) && (<>
						<div className="bg-based-clm">
							<Divider />
							<br />
							{/* TO DO - Need to update requirement */}
							<FormLabel component="legend">Background colors based on column value</FormLabel>
							<br />
							<BackgroundAdd
								values={this.props.values}
								classes={this.props.classes}
								onBackgroundChangeHandler={this.props.onBackgroundChangeHandler}
								addBackground={this.props.addBackground}
							/>
							<FormLabel component="legend">Backgrounds Table</FormLabel>
							<BackgroundsTable
								backgrounds={this.props.values.backgrounds}
								editBackground={this.props.editBackground}
								deleteBackground={this.props.deleteBackground}
								pages={this.props.pages}
							/>
							</div>
						</>)
				}

				<br />
				{!this.props.checkForNullOrUndefined(this.props.dataModels)
					&& this.props.values.selectedDataModel
					&& this.props.values.dataHandler === "data_models"
					&& this.props.chartType === Actions.card
					&& ((values.selectedGroupingDataElement.length > 0
						|| values.selectedGroupingDataElement.value)
						|| values.isCardCategories)
					&& (<>
						<Divider />
						<br />
						<br />
						<FormControl component="fieldset" className="">
							<FormLabel component="legend">Select card template UI view</FormLabel>
							<br />
							<RadioGroup row aria-label="Select card template view"
								name="cardTemplateUI"
								value={props.values.cardTemplateUI}
								onChange={props.onChangeHandler()}>
								{/* <RadioGroup row aria-label="position" name="position" defaultValue="top"> */}
								<FormControlLabel value="collageType1" control={<Radio />}
									label={<img src={collageType1} alt="" style={{
										borderWidth: "thin",
										width: "200px",
										height: "200px"
									}} />}
								/>
								<FormControlLabel value="collageType2" control={<Radio />}
									label={<img src={collageType2} alt="" style={{
										borderWidth: "thin",
										width: "200px",
										height: "200px"
									}} />}
								/>
								<FormControlLabel value="collageType3" control={<Radio />}
									label={<img src={collageType3} alt="" style={{
										borderWidth: "thin",
										width: "200px",
										height: "200px"
									}} />}
								/>
								<FormControlLabel value="collageType4" control={<Radio />}
									label={<img src={collageType4} alt="" style={{
										borderWidth: "thin",
										width: "200px",
										height: "200px"
									}} />}
								/>
							</RadioGroup>
						</FormControl>
					</>)
				}
			</div></>)
	}
}

function mapStateToProps({ pages, dataModels, entryForms }) {
	return {
		chartType: pages.page.chartType,
		dataModels: dataModels.data,
		pages: pages.data,
		// entryForms: entryForms.data,
	};
}

export default connect(
	mapStateToProps,
	null
)(UI);
