export default {

	GET_NOTIFICATIONS: undefined,
	GET_NOTIFICATIONS_SUCCESS: undefined,
	GET_NOTIFICATIONS_FAILURE: undefined,

	GET_NOTIFICATION: undefined,
	GET_NOTIFICATION_SUCCESS: undefined,
	GET_NOTIFICATION_FAILURE: undefined,

	SAVE_NOTIFICATION: undefined,
	SAVE_NOTIFICATION_SUCCESS: undefined,
	SAVE_NOTIFICATION_FAILURE: undefined,

	UPDATE_NOTIFICATION: undefined,
	UPDATE_NOTIFICATION_SUCCESS: undefined,
	UPDATE_NOTIFICATION_FAILURE: undefined,

	DELETE_NOTIFICATION: undefined,
	DELETE_NOTIFICATION_SUCCESS: undefined,
	DELETE_NOTIFICATION_FAILURE: undefined,

}