export default {
	GET_DAPPS: undefined,
	GET_DAPPS_SUCCESS: undefined,
	GET_DAPPS_FAILURE: undefined,

	GET_DAPP: undefined,
	GET_DAPP_SUCCESS: undefined,
	GET_DAPP_FAILURE: undefined,
	GET_DAPP_FILES: undefined,

	SAVE_DAPP: undefined,
	SAVE_DAPP_SUCCESS: undefined,
	SAVE_DAPP_FAILURE: undefined,

	UPDATE_DAPP: undefined,
	UPDATE_DAPP_SUCCESS: undefined,
	UPDATE_DAPP_FAILURE: undefined,

	DELETE_DAPPS: undefined,
	DELETE_DAPPS_SUCCESS: undefined,
	DELETE_DAPPS_FAILURE: undefined,

	DELETE_DAPP: undefined,
	DELETE_DAPP_SUCCESS: undefined,
	DELETE_DAPP_FAILURE: undefined,

	GET_CATEGORIES: undefined,
	GET_CATEGORIES_SUCCESS: undefined,
	GET_CATEGORIES_FAILURE: undefined,

	UPDATE_CURRENT_DAPPID: undefined,
	UPDATE_CURRENT_DAPP: undefined,
}