import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
	FormControl, InputLabel, Button, Grid, Typography, makeStyles, Box, AppBar, Tabs, Tab, Divider, Select, MenuItem,
} from '@material-ui/core'
import 'styles/styles.scss'
import BasicInfo from './BasicInfo'
import NewDataElement from './NewDataElement'
import DataElements from './DataElements'
import Header from './Header'
import { TabPanel } from '../@tabpanel/tabpanel'
import Utils from 'packages/page/utils'

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	}
}))


const View = (props) => {
	const classes = useStyles()
	const [value, setValue] = React.useState(0)

	const { form } = props.state
	const handleChange = (event, newValue) => setValue(newValue)
	console.log(' handleCheck={props.handleCheck}',  props.handleCheck);
	return (<Box className="quickwizard-page">
		<Header
			save={props.saveDatamodelData}
			update={props.updateDatamodelData}
			canBeSubmitted={props.canBeSubmitted}
			to={"datamodels"} title={"DataModels List"}
			id={props.match.params.datamodelId} />
		<Box className="pxy20">
			<div className={classes.root} >
				<Box px="1.5rem">
					<AppBar position="static" className="bg-white">
						<Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className="quicktabs-sec">
							<Tab label="Basic Info" {...a11yProps(0)} />
							{form && form.type === "networkdatamodel" &&
								<Tab label="Data models" {...a11yProps(1)} />
							}
							<Tab label="Data elements" {...a11yProps(2)} />
						</Tabs>
						<Divider />
					</AppBar>
					<TabPanel value={value} index={0}>
						<BasicInfo {...props} classes={classes} />
					</TabPanel>

					{form && form.type === "networkdatamodel" ? (<>
					<TabPanel value={value} index={1}>
						{(props.dataModels && props.dataModels.orgDataModels && props.dataModels.orgDataModels.length > 0 && <>
							<div className="p-16 sm:p-24 max-w-2xl">
								<FormControl variant="outlined" className={classes.formControl} fullWidth>
									<InputLabel id="demo-simple-select-outlined-label">
										{"select a data model"}
									</InputLabel>
									<Select
										id="demo-simple-select-outlined"
										value={form.currentRefDataModelId}
										onChange={props.handleDataModelChange}
										name="currentRefDataModelId">
										<MenuItem value="">
											<em>{"None"}</em>
										</MenuItem>
										{props.dataModels.orgDataModels.map((item, i) => {
											return <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
										})}
									</Select>
								</FormControl>
							</div>
							<div className="p-16 sm:p-24 max-w-2xl">
								<Typography className="text-16 sm:text-20 truncate">{"Select multiple dataelements"}</Typography>
								<br />
								<FormControl className={classes.formControl} fullWidth>
									<Select
										className="w-full my-16"
										value={form.dataEles}
										onChange={(value) => props.handleSelectedDataElements(value, 'dataEles')}
										placeholder="Select multiple dataelements"
										textFieldProps={{
											label: 'dataElements',
											InputLabelProps: { shrink: true },
											variant: 'standard'
										}}
										options={props.state.dataEles}
										isMulti
									/>
								</FormControl>
							</div>
							{params.dataModelId !== 'new' &&
								<Button
									color="secondary"
									variant="outlined"
									className={classes.formControl}
									onClick={() => {
										props.addDataElement(props.state)
									}}
									disabled={!props.canBeSubmitted()}
									fullWidth>
									{"Add Data elements"}
								</Button>
							}
						</>)
						}
					</TabPanel><TabPanel value={value} index={2}>
							<DataElements
								{...props} handleCheck={props.handleCheck} classes={classes} />
						</TabPanel></>) :
						<TabPanel value={value} index={1}>
							<DataElements
								{...props} handleCheck={props.handleCheck} classes={classes} />
						</TabPanel>
					}
				</Box>
			</div>
		</Box>
		{props.state.selectedDataElement === "new" ? <NewDataElement {...props} newId={Utils.generateGUID()} dataElement={props.state.selectedDataElement} /> :
			(props.state.selectedDataElement
				? <NewDataElement {...props} dataElement={props.state.selectedDataElement} appId={props.match.params.dappId} /> : '')}
	</Box>)
}

export default View