import React from 'react';
import { withStyles, Button, Tab, Tabs, TableHead, TableRow, TextField, InputAdornment, Icon, Select, MenuItem, Typography, FormControl, FormGroup, Checkbox, RadioGroup, FormControlLabel, Radio, FormLabel, Table, TableCell, TableBody, Input, Chip } from '@material-ui/core';

export default function NotificationDetails(props) {
    const { form } = props.state;
    const { classes } = props;
    console.log("NotificationDetails", props);
    return (
        form && (
            <div className="p-16 sm:p-24 max-w-2xl">
                <form className="form" noValidate autoComplete="off" >
                    <FormControl>
                        <FormLabel variant="h6" className="label-headings">
                            {"Name *"}
                        </FormLabel>
                        <TextField
                            className="form-control"
                            error={form.name === ''}
                            autoFocus
                            id="name"
                            name="name"
                            value={form.name}
                            onChange={props.handleChange}
                            variant="outlined"
                            color="secondary"
                            placeholder="Enter Name"
                            required
                            fullWidth
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel variant="h6" className="label-headings">
                            {"Description"}
                        </FormLabel>
                        <TextField
                            className="form-control"
                            id="description"
                            name="description"
                            multiline
                            rows={2}
                            value={form.description}
                            onChange={props.handleChange}
                            variant="outlined"
                            color="secondary"
                            placeholder="Enter Description"
                            required
                            fullWidth
                        />
                    </FormControl>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={form.checkedA}
                                    onChange={props.handleChange}
                                    value="checkedA"
                                />
                            }
                            label="Send email notification"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={form.checkedB}
                                    onChange={props.handleChange}
                                    value="checkedB"
                                    color="primary"
                                />
                            }
                            label="Send text message"
                        />
                    </FormGroup>
                    <FormControl>
                        <FormLabel component="legend">Allow to use in following components</FormLabel>
                        <RadioGroup
                            aria-label="Gender"
                            name="gender1"
                            className={classes.group}
                            value={form.value}
                            onChange={props.handleChange}
                            row
                        >
                            <FormControlLabel value="current" control={<Radio />} label="Workflow" />
                            <FormControlLabel value="workflow" control={<Radio />} label="Pages" />
                            <FormControlLabel value="systems" control={<Radio />} label="Forms" />
                        </RadioGroup>
                    </FormControl>
                </form>
            </div>
        )
    )
}