class Utils {

    static setDataElemenets(rows) {
        // const { rows } = this.state.design
        let dataElements = [{
            "defaultValue": "-",
            "isUnique": false,
            "searcheable": false,
            "lookup": false,
            "validation": "-",
            "name": 'createdBy',
            "description": 'Created By',
            "type": 'datetime'
        },
        {
            "defaultValue": "-",
            "isUnique": false,
            "searcheable": false,
            "lookup": false,
            "name": 'updatedBy',
            "description": 'Updated By',
            "type": 'datetime'
        },
        {
            "defaultValue": "-",
            "isUnique": false,
            "searcheable": false,
            "lookup": false,
            "validation": "-",
            "name": 'createdDate',
            "description": 'Created Date',
            "type": 'datetime'
        },
        {
            "defaultValue": "-",
            "isUnique": false,
            "searcheable": false,
            "lookup": false,
            "validation": "-",
            "name": 'updatedDate',
            "description": 'Updated Date',
            "type": 'datetime'
        }]
        let selectedDataElement = []
        if (!rows) {
            return { dataElements, selectedDataElement }
        }
        for (let i = 0; i < rows.length; i++) {

            for (let j = 0; j < rows[i].columns.length; j++) {
                let type = rows[i].columns[j].type
                if (type === 'html' || type === 'label' || type === 'HTML_content' || type === 'button'
                    || type === 'chip' || type === 'multiLine' || type === 'table') {
                } else if (type === 'tab' || type === 'multiStepForm' || type === 'expansionPanels') {
                    for (let z = 0; z < rows[i].columns[j].innerForms.length; z++) {
                        for (let m = 0; m < rows[i].columns[j].innerForms[z].innerForm.rows.length; m++) {
                            for (let n = 0; n < rows[i].columns[j].innerForms[z].innerForm.rows[m].columns.length; n++) {
                                let column = rows[i].columns[j].innerForms[z].innerForm.rows[m].columns[n];
                                let _type = column.type;
                                if (column.typeOfText) {
                                    _type = column.typeOfText === 'datetime-local' ? 'Date' : _type
                                }
                                dataElements.push({
                                    "defaultValue": "-",
                                    "isUnique": false,
                                    "searcheable": false,
                                    "lookup": false,
                                    "validation": "-",
                                    "name": rows[i].columns[j].name + '.' + rows[i].columns[j].innerForms[z].innerForm.rows[m].columns[n].name,
                                    "description": rows[i].columns[j].name + '.' + rows[i].columns[j].innerForms[z].innerForm.rows[m].columns[n].name,
                                    "type": type
                                })
                                if (rows[i].columns[j].showInThePageGrid) {
                                    selectedDataElement.push({
                                        value: rows[i].columns[j].innerForms[z].innerForm.rows[m].columns[n].name,
                                        label: rows[i].columns[j].innerForms[z].innerForm.rows[m].columns[n].label.replace(/\*$/, ""),
                                    })
                                }

                            }
                        }
                    }

                } else if (type === 'card') {
                    for (let z = 0; z < rows[i].columns[j].innerForm.rows.length; z++) {
                        for (let n = 0; n < rows[i].columns[j].innerForm.rows[z].columns.length; n++) {
                            let column = rows[i].columns[j].innerForm.rows[z].columns[n];
                            let _type = column.type;
                            if (column.typeOfText) {
                                _type = column.typeOfText === 'datetime-local' ? 'Date' : _type
                            }
                            dataElements.push({
                                "defaultValue": "-",
                                "isUnique": false,
                                "searcheable": false,
                                "lookup": false,
                                "validation": "-",
                                "name": rows[i].columns[j].name + '.' + rows[i].columns[j].innerForm.rows[z].columns[n].name,
                                "description": rows[i].columns[j].name + '.' + rows[i].columns[j].innerForm.rows[z].columns[n].name,
                                "type": _type
                            })
                            if (rows[i].columns[j].showInThePageGrid) {
                                selectedDataElement.push({
                                    value: rows[i].columns[j].innerForm.rows[z].columns[n].name,
                                    label: rows[i].columns[j].innerForm.rows[z].columns[n].label.replace(/\*$/, ""),
                                })
                            }

                        }
                    }
                } else if (type === 'namePicker') {
                    let column = rows[i].columns[j];
                    let _type = column.type;
                    dataElements.push({
                        "defaultValue": "-",
                        "isUnique": false,
                        "searcheable": false,
                        "lookup": false,
                        "validation": "-",
                        "name": `${rows[i].columns[j].name}._id`,
                        "description": `${rows[i].columns[j].label.replace(/\*$/, "")} id`,
                        "type": _type
                    })
                    dataElements.push({
                        "defaultValue": "-",
                        "isUnique": false,
                        "searcheable": false,
                        "lookup": false,
                        "validation": "-",
                        "name": `${rows[i].columns[j].name}.name`,
                        "description": `${rows[i].columns[j].label.replace(/\*$/, "")} Name`,
                        "type": _type
                    })
                    if (rows[i].columns[j].showInThePageGrid) {
                        selectedDataElement.push({
                            value: `${rows[i].columns[j].name}.name`, //TODO: need to check rows[i].columns[j].name,
                            label: rows[i].columns[j].label.replace(/\*$/, ""),
                        })
                    }

                } else {
                    let column = rows[i].columns[j];
                    let _type = column.type;
                    if (column.typeOfText) {
                        _type = column.typeOfText === 'datetime-local' ? 'Date' : _type
                    }
                    let label = rows[i].columns[j].label
                    label = typeof label === 'object' && label !== null ? label.labelText.replace(/\*$/, "") : label.replace(/\*$/, "")

                    dataElements.push({
                        "defaultValue": "-",
                        "isUnique": false,
                        "searcheable": false,
                        "lookup": false,
                        "validation": "-",
                        "name": rows[i].columns[j].name,
                        "description": label,
                        "type": _type
                    })
                    // Todo
                    if (rows[i].columns[j].showInThePageGrid) {
                        selectedDataElement.push({
                            value: rows[i].columns[j].name,
                            label: label,
                        })
                    }
                }

            }
        }
        return { dataElements, selectedDataElement }
    }

}

export default Utils