import React from 'react'
import { Link } from 'react-router-dom'
import {
	Button, Grid, Typography, makeStyles, Box, AppBar, Tabs, Tab, Divider, FormControl, FormLabel
} from '@material-ui/core'
import 'styles/styles.scss'
import { DatasourceDetails } from './datasourcedetails'
import SecurityAccess from './SecurityAccess'
import Schedule from './Schedule'
import { TabPanel } from '../@tabpanel/tabpanel'
import rightarrow from 'images/rightarrow.svg'
import ChipSelect from 'components/@custom/ChipSelect'

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	}
}))

const View = (props) => {
	const classes = useStyles()
	const [value, setValue] = React.useState(0)

	const { form, roles } = props.state
	const handleChange = (event, newValue) => setValue(newValue)
	return (<Box className="quickwizard-page">
		<Grid container>
			<Grid item xs={12} md={6}>
				<div className="backbtn">
					<div className="backbtn ptl-20">
						<Link to={`/builder/${props.match.params.dappId}/datasources`}
							style={{ textDecoration: 'none', color: "#1B2A47" }}>
							<img src={rightarrow} alt="backicon" className="backicon" />
							<Box component="h3" className="companyheadingtext">
								<Typography variant="h4">{"Datasources List"}</Typography>
							</Box>
						</Link>
					</div>
				</div>
			</Grid>
			<Grid item xs={12} md={6} className="savebtn-sec">
				<Button
					className="addnewentrybtn mb5 whitespace-no-wrap"
					variant="contained"
					color="primary"
					disabled={!props.canBeSubmitted()}
					onClick={() => props.match.params.datasourceId === 'new' ? props.saveDatasource() : props.updateDatasource()}>
					{props.match.params.datasourceId === 'new' ? "Save" : "Update"}
				</Button>
			</Grid>
		</Grid>
		<Box className="pxy20">
			<div className={classes.root} >
				<Box px="1.5rem">
					<AppBar position="static" className="bg-white">
						<Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className="quicktabs-sec">
							<Tab label="Basic Info" {...a11yProps(0)} />
							{/* <Tab label="Security & Access" {...a11yProps(1)} />
							<Tab label="Schedule" {...a11yProps(2)} /> */}
						</Tabs>
						<Divider />
					</AppBar>
					<TabPanel value={value} index={0}>
						<DatasourceDetails {...props} classes={classes} />
					</TabPanel>
					{/* <TabPanel value={value} index={1}>
						<SecurityAccess {...props} classes={classes} />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<Schedule {...props} classes={classes} />
					</TabPanel> */}

					{/*<TabPanel value={value} index={1}>
						<Grid container>
							<Grid item sm={12}>
								<form className="form" noValidate autoComplete="off">
									<FormControl>
										<FormLabel variant="h6" className="label-headings">
											{"Admin Access Control"}
										</FormLabel>
										<ChipSelect
											className="mt-8 mb-16"
											value={
												roles && roles.map(item => ({
													value: item.value,
													label: item.label
												}))
											}
											onChange={(e) => props.handleChipChange(e.target.value, 'admin_roles')}
											placeholder="Select multiple tags"
											textFieldProps={{
												InputLabelProps: {
													shrink: true
												},
												variant: 'outlined'
											}}
											isMulti
										/>
										{<Select name="Roles" displayEmpty className="form-control" id="grouped-native-select" variant="outlined" multiple
												onChange={(e) => props.handleChipChange(e, 'selectedRoles')} value={form.selectedRoles}
												input={<Input />}
												renderValue={(selected) => (
													<div className={classes.chips}>
														{selected && selected.map((item) => (
															<Chip key={item.value} label={item.label} className={classes.chip} />
														))}
													</div>
														)}>
												{roles && roles.map((item, i) => (
													<MenuItem key={item.value} value={item}>
														{item.label}
													</MenuItem>
												))}
											</Select>
									</FormControl>
									<FormControl>
										<FormLabel variant="h6" className="label-headings">
											{"Editor Access Control"}
										</FormLabel>
										<ChipSelect
											className="mt-8 mb-16"
											value={
												roles && roles.map(item => ({
													value: item.value,
													label: item.label
												}))
											}
											onChange={(e) => props.handleChipChange(e.target.value, 'editor_roles')}
											placeholder="Select multiple tags"
											textFieldProps={{
												InputLabelProps: {
													shrink: true
												},
												variant: 'outlined'
											}}
											isMulti
										/>
									</FormControl>
									<FormControl>
										<FormLabel variant="h6" className="label-headings">
											{"Reader Access Control"}
										</FormLabel>
										<ChipSelect
											className="mt-8 mb-16"
											value={
												roles && roles.map(item => ({
													value: item.value,
													label: item.label
												}))
											}
											onChange={(e) => props.handleChipChange(e.target.value, 'reader_roles')}
											placeholder="Select multiple tags"
											textFieldProps={{
												InputLabelProps: {
													shrink: true
												},
												variant: 'outlined'
											}}
											isMulti
										/>
									</FormControl>
								</form>
							</Grid>
						</Grid>
					</TabPanel>*/}
				</Box>
			</div>
		</Box>
	</Box>)
}

export default View
