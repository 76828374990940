import React from 'react'
import {
	Stepper, Step, StepLabel, Button, Typography, Card, CardContent,
} from '@material-ui/core'
import InnerForm from '../InnerForm'
import classNames from 'classnames'

const MultiStepForm = (props) => {
	let { item, visibility, classes } = props
	let selectedvalue = props.filledData[item.name]
	let values = item.data && item.data.values
	return (<>
		<Stepper activeStep={props.activeStep - 1}>
			{values.map((el, index) => (<Step key={index}><StepLabel>{el.label}</StepLabel></Step>))}
		</Stepper>
		{item.innerForms && item.innerForms.map((item, index) => (<Typography
			component="div"
			role="tabpanel"
			style={props.activeStep == (index + 1) ? {} : { display: "none" }}>
			{<Card className={classNames(classes.card)}>
				<CardContent>
					<InnerForm design={item.innerForm}
						viewOnly={props.viewOnly}
						setData={(name, val, item) => { props.setData(name, val, item) }}
						innerFormUpdate={(jsonCopy, modalData) => {
							props.innerFormUpdateMultiple(props.itemPosition, jsonCopy, modalData, index);
						}}
						modalData={item.innerFormModalData}
						filledData={props.filledData}
					/>
				</CardContent>
			</Card>}
		</Typography>))}
		<Button disabled={props.activeStep === 0}
			onClick={(e) => { props.decrementActiveStep({ activeStep: props.activeStep - 1 }) }}>
			{"Back"}
		</Button>
		<Button
			variant="contained"
			color="primary"
			onClick={(e) => { props.incrementActiveStep({ activeStep: props.activeStep + 1 }) }}
			disabled={props.activeStep == (item.data && item.data.values && item.data.values.length)}>
			{"Next"}
		</Button>
	</>)
}

export default MultiStepForm