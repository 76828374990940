import React, { Component } from 'react'
import { connect } from 'react-redux'
import { submitLogin, emptyPrevPath } from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, datamodel }) => {
		return {
			user: user,
			navigation: navigation,
			// datamodel: datamodel
		}
	}

	const mapDispatchToProps = {
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux