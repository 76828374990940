import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
	AppBar, Tabs, Tab, Box, Divider, FormLabel, FormControl, Radio, FormControlLabel,
	Button, RadioGroup, Checkbox, Grid, Typography
} from '@material-ui/core'
import multisetting from '../../images/multisetting.png'
import { TabPanel } from './tabpanel'
import EntityDetails from './EntityDetails'
import UI from './UI'
import Upload from './Upload'
import rightarrow from 'images/rightarrow.svg'
import { EmailCreds } from './emailCreds'

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	}
}))

const View = (props) => {
	const classes = useStyles()
	// const [value, setValue] = React.useState(0)

	const { form } = props.state
	const { setTabValue, tabValue } = props

	const handleChange = (event, newValue) => {
		setTabValue(newValue)
		// setValue(newValue)
	};

	return (
		<React.Fragment>
			<Box className="quickwizard-page">
				<Grid container>
					<Grid item xs={12} md={6}>
						<div className="backbtn">
							<div className="backbtn ptl-20">
								<Link to={`/builder/${props.match.params.dappId}/entities`}
									style={{ textDecoration: 'none', color: "#1B2A47" }}>
									<img src={rightarrow} alt="backicon" className="backicon" />
									<Box className="companyheadingtext">
										<Typography variant="h4">{"Entities List"}</Typography>
									</Box>
								</Link>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} md={6} className="savebtn-sec">
						{props.match.params.entityId === 'new'
							? <Button
								className="addnewentrybtn mb5 whitespace-no-wrap"
								variant="contained"
								color="primary"
								disabled={!props.canBeSubmitted()}
								onClick={() => props.saveEntity()}>
								{"Save"}
							</Button>
							: <Button
								className="whitespace-no-wrap addnewentrybtn mb5"
								variant="contained"
								color="primary"
								disabled={!props.canBeSubmitted()}
								onClick={() => props.updateEntity()}>
								{"Update"}
							</Button>
						}
					</Grid>
				</Grid>
				<Box className="pxy20">
					<div className={classes.root} >
						<Box px="1.5rem">
							<AppBar position="static" className="bg-white">
								<Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example" className="quicktabs-sec">
									<Tab label="Basic Info" {...a11yProps(0)} />
									<Tab label="Contact Info" {...a11yProps(1)} />
									<Tab disabled={!form.currentOrganization} label="Email Credentials" {...a11yProps(2)} />
									{/* <Tab label="Network" {...a11yProps(2)} /> */}
								</Tabs>
								<Divider />
							</AppBar>
							<TabPanel value={tabValue} index={0}>
								<EntityDetails {...props} classes={classes} s />
							</TabPanel>
							<TabPanel value={tabValue} index={1}>
								<UI
									form={props.state.form}
									handleUIChange={props.handleUIChange}
									classes={classes}
									{...props}
								/>
							</TabPanel>
							<TabPanel value={tabValue} index={2}>
								<EmailCreds
									form={props.state.form}
									handleUIChange={props.handleUIChange}
									classes={classes}
									{...props}
								/>
							</TabPanel>
							{/* <TabPanel value={tabValue} index={2}>
								<div>
									<FormLabel component="legend">Participation rules</FormLabel>
									<FormControlLabel
										control={
											<Checkbox
												checked={form.checkedA}
												value="checkedA"
											/>
										}
										label="Allow all participants in the network to find and send invite"
									/>
									<br />
									<FormControlLabel
										control={
											<Checkbox
												checked={form.checkedA}
												value="checkedA"
											/>
										}
										label="Visible to only selected participants"
									/>
								</div>
								<div style={{ padding: '10px' }}></div>
								<div>
									<FormControl component="fieldset" className={classes.formControl}>
										<FormLabel component="legend">Participant Role</FormLabel>
										<RadioGroup
											aria-label="Gender"
											name="gender1"
											className={classes.group}
											value={form.value}
											onChange={props.handleChange}
											row
										>
											<FormControlLabel value="female" control={<Radio />} label="Validator" />
											<FormControlLabel value="male" control={<Radio />} label="Orderer" />
											<FormControlLabel value="other" control={<Radio />} label="Both" />
										</RadioGroup>
									</FormControl>
								</div>
							</TabPanel> */}

						</Box>
					</div>

				</Box>

				{/* <Grid item md={6}>
                    <Grid container className="px-15">
                        
                    </Grid>
                </Grid> */}
			</Box>
		</React.Fragment>
	);
}

export default View