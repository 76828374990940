import React, { useCallback } from 'react';
import { TextField } from '@material-ui/core';
import { AlphanumericRegex } from '../../utils/regex';

// eslint-disable-next-line react/prop-types
const InputTextField = ({ onChange, regex = AlphanumericRegex, ...rest }) => {
    const handleChange = useCallback(
        e => {
            e.currentTarget.value = e.currentTarget.value.replace(regex, '');
            onChange(e);
        },
        [onChange, regex],
    );

    // eslint-disable-next-line react/jsx-filename-extension
    return <TextField onChange={handleChange} {...rest} />;
};

export default React.memo(InputTextField);
