import React, { Component } from 'react'
import {
	Table, TableBody, TableCell, TableHead, TableRow,
	withStyles, Button
} from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import classNames from 'classnames'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const styles = theme => ({
	head: {
		backgroundColor: "lightgrey",
		color: theme.palette.common.white,
	},
	customFont: {
		"font-size": "large"
	}
});

class Worksheet extends Component {
	constructor(props) {
		super(props);
		this.state = { rows: props.rows }
	}
	render() {
		const { classes } = this.props;
		console.log('props .. ws', this.props);
		return (
			<>
				<Button
					variant="contained"
					color="default"
					className={classes.button}
					startIcon={<CloudUploadIcon />}>
					{"Upload"}
				</Button>
				<Table className={classNames(classes.table)} size="small" aria-label="a dense table">
					<TableHead className={classNames(classes.head)}>
						<TableRow>
							{this.props.data.values.map((item) => {
								return (<TableCell className={classNames(classes.customFont)}>{item.value}</TableCell>);
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{this.props.data.rows.map((row, index) => {
							return (
								<TableRow>
									{row.map((rowdata) => {
										return (<TableCell className={classNames(classes.customFont)}>
											{rowdata}</TableCell>)
									})}
								</TableRow>)
						})}
					</TableBody>
				</Table>
			</>
		)
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}

function mapStateToProps() {
	return {
	}
}

export default withStyles(styles, { withTheme: true })(
	withRouter(connect(mapStateToProps, mapDispatchToProps)(Worksheet))
)
