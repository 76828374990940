import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import SelectTemplate from './SelectTemplate'
import 'styles/styles.scss'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}))

const View = (props) => {
	const classes = useStyles()

	return (<SelectTemplate {...props} />)
}

export default withRouter(View)