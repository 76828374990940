import React from 'react';
import ConnectionPoint from './ConnectionPoint';
import Step from './Step';
import SuggestionRect from './SuggestionRect';
import '../style/elementText.css';
export default class Decision extends Step {
  render() {
    const {
      height,
      width,
      x,
      y,
      multipleSelection,
      selected,
      selectStep,
      stopDragging,
      mouseOverConnection,
      mouseOverStep,
      setMouseOverStep,
      setMouseOverConnection,
      onClickSugessionElement,
      setClickStep,
      clickStep,
      editElementStep,
      name,
      bgColor,
      moveArrow,
    } = this.props;
    // const showConnectionPoints =
    //   (selected && !multipleSelection) || mouseOverStep;
    const showConnectionPoints = clickStep;
    const style = this.getStyle();
    const halfHeight = height / 2;
    const halfWidth = width / 2;

    return (
      <g
        onMouseOver={setMouseOverStep}
        onMouseLeave={setMouseOverStep}
        onMouseDown={selectStep}
        onMouseUp={stopDragging}
        transform={`translate(${x},${y})`}
      >
        <path
          d={`M0 ${halfHeight} L${halfWidth} 0 L${width} ${halfHeight} L${halfWidth} ${height}Z`}
          onClick={setClickStep}
          onDoubleClick={editElementStep}
          onMouseMove={moveArrow}
          // style={style}
          style={{
            fill: bgColor === undefined ? 'white' : bgColor,
            fontSize: 14,
            stroke: '#000',
            strokeWidth: 2,
          }}
        />
        <foreignObject
          width="60"
          height="55"
          className="processText"
          onDoubleClick={editElementStep}
          onClick={setClickStep}
          onMouseMove={moveArrow}
        >
          <p>{name}</p>
        </foreignObject>

        {showConnectionPoints ? (
          <ConnectionPoint setMouseOverConnection={setMouseOverConnection} cx={0} cy={halfHeight} />
        ) : null}
        {showConnectionPoints ? (
          <ConnectionPoint setMouseOverConnection={setMouseOverConnection} cx={halfWidth} cy={0} />
        ) : null}
        {showConnectionPoints ? (
          <ConnectionPoint
            setMouseOverConnection={setMouseOverConnection}
            cx={width}
            cy={halfHeight}
          />
        ) : null}
        {showConnectionPoints ? (
          <ConnectionPoint
            setMouseOverConnection={setMouseOverConnection}
            cx={halfWidth}
            cy={height}
          />
        ) : null}
        {showConnectionPoints && (
          <SuggestionRect
            type={'decision'}
            x={width + 10}
            y={-10}
            height={100}
            width={50}
            onClickSugessionElement={onClickSugessionElement}
          />
        )}
      </g>
    );
  }
}

Decision.defaultProps = Step.defaultProps;
