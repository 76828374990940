import React, { Component } from 'react'
import { connect } from 'react-redux'
import { submitLogin, emptyPrevPath, showAlert, hideAlert, getMemberByUserId } from 'actions'

const LoginRedux = (PassedComponent) => {

	const mapStateToProps = ({ user, login, navigation, member }) => {
		return {
			login,
			user,
			navigation,
			memberByUserId: member.memberByUserId
		}
	}

	const mapDispatchToProps = {
		submitLogin: submitLogin,
		emptyPrevPath: emptyPrevPath,
		showAlert: showAlert,
		hideAlert: hideAlert,
		getMemberByUserId: getMemberByUserId
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default LoginRedux