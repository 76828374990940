import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, forms }) => {
		return {
			user: user,
			navigation: navigation,
			forms: forms,
			formSuccess: forms.formSuccess
		}
	}

	const mapDispatchToProps = {
		getForms: Actions.getEntryForms,
		deleteEntryForm: Actions.deleteEntryForm,
		saveEntryForm: Actions.saveEntryForm
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux;