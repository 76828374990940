import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button, TextField, FormControl, FormLabel } from '@material-ui/core'

const s3View = (props) => {
	let { form, classes, testS3Connection, handleChange } = props
	return (<>
		<br />
		<FormLabel variant="h6">{"AWS S3 Bucket"}</FormLabel>
		<br />
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Bucket Name"}</FormLabel>
			<TextField
				id="s3BucketName"
				name="s3BucketName"
				placeholder="Bucket Name"
				className="mt-6 mb-10"
				value={form.s3BucketName}
				onChange={handleChange}
				margin="normal"
				fullWidth
				variant="outlined"
			/>
		</FormControl>
		<br />
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Access Key"}</FormLabel>
			<TextField
				id="s3AccessKey"
				name="s3AccessKey"
				placeholder="Access Key"
				className={classes.textField}
				value={form.s3AccessKey}
				onChange={handleChange}
				margin="normal"
				variant="outlined"
			/>
		</FormControl>
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Secret Key"}</FormLabel>
			<TextField
				id="s3SecretKey"
				name="s3SecretKey"
				placeholder="Secret Key"
				className={classes.textField}
				value={form.s3SecretKey}
				onChange={handleChange}
				margin="normal"
				variant="outlined"
			/>
		</FormControl>
		<Button
			className="whitespace-no-wrap"
			variant="contained"
			onClick={() => testS3Connection(form, props.match.params.dappId)}>
			{"Test"}
		</Button>
		<br />
	</>)
}

export default withRouter(s3View)