import React from 'react'
import {
	RadioGroup, Checkbox, Input, MenuItem, Chip, FormControlLabel, Grid, TextareaAutosize, TextField,
	Radio, Select, FormControl, FormLabel, Box
} from '@material-ui/core'
import multisetting from 'images/multisetting.png'
import ChipSelect from 'components/@custom/ChipSelect'

export default function DappDetails(props) {
	const { form, classes, error } = props
	return (<Grid container spacing={2}>
		<Grid item sm={12} md={6}>
			<Grid container className="dapp-page">
				{form.dappCode && <>
					<FormLabel variant="h6" className="label-headings">
						{"DappCode :  "}
					</FormLabel>
					<FormLabel variant="h6" className="label-headings">
						{"  " + form.dappCode}
					</FormLabel><br /> </>
				}
				<form className="form" noValidate autoComplete="off" >
					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Title *"}
						</FormLabel>
						<TextField
							id="title"
							name="title"
							variant="outlined"
							color="secondary"
							className="form-control"
							placeholder="Title"
							value={form.title}
							onChange={props.handleChange}
							helperText={error.title && error.title}
							error={Boolean(error.title)}
							autoFocus
						/>
					</FormControl>
					{/* <FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Dapp Code *"}
						</FormLabel>
						<TextField
							id="dappCode"
							name="dappCode"
							variant="outlined"
							color="secondary"
							className="form-control"
							placeholder="Dapp Code"
							value={form.dappCode}
							onChange={props.handleChange}
							helperText={error.dappCode && error.dappCode}
							error={Boolean(error.dappCode)}
						/>
					</FormControl> */}
					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Sub Title *"}
						</FormLabel>
						<TextField
							id="subTitle"
							name="subTitle"
							variant="outlined"
							color="secondary"
							className="form-control"
							placeholder="SubTitle"
							value={form.subTitle}
							onChange={props.handleChange}
						/>
					</FormControl>
					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Description *"}
						</FormLabel>
						<TextareaAutosize
							aria-label="minimum height"
							id="description"
							name="description"
							rowsMin={3}
							className="form-control"
							variant="outlined"
							placeholder="Description"
							value={form.description}
							onChange={props.handleChange}
						/>
					</FormControl>
					{/* <FormControl>
						<Box className="form-group">
							<FormLabel variant="h6" className="label-headings textblack" >
								{"Dapp Type"}
							</FormLabel>
							<RadioGroup
								aria-label="Dapp Type"
								name="dappType"
								className={classes.group}
								value={form.dappType}
								onChange={props.handleChange}
								row
							>
								<FormControlLabel value="public" control={<Radio />} label="Public" />
								<FormControlLabel value="private" control={<Radio />} label="Private" />
								<FormControlLabel value="permission" control={<Radio />} label="Permissioned" />
							</RadioGroup>
						</Box>
					</FormControl> */}
					<FormControl>
						<FormControlLabel control={
							<Checkbox
								checked={form.useledger}
								onChange={props.handleChange}
								value="useledger"
								color="primary"
								id="useledger"
								name="useledger"
								disabled={true}
							/>}
							label="Use Ledger to store transactions"
						/>
					</FormControl>
					<FormControl>
						<FormControlLabel control={
							<Checkbox
								checked={form.useledger}
								onChange={props.handleChange}
								value="saveAsTmplate"
								color="primary"
								id="saveAsTmplate"
								name="saveAsTmplate"
								disabled={true}
							/>}
							label="Save this application as template"
						/>
					</FormControl>
					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Categories"}
						</FormLabel>
						<ChipSelect
							className="mt-8 mb-24"
							value={
								form.categories && form.categories.map(item => ({
									value: item,
									label: item
								}))
							}
							onChange={(value) => props.handleChipChange(value, 'categories')}
							placeholder="Select multiple categories"
							textFieldProps={{
								InputLabelProps: {
									shrink: true
								},
								variant: 'outlined'
							}}
							isMulti
						/>
						{/* <Select name="categories" displayEmpty
							className="form-control"
							id="grouped-native-select"
							variant="outlined" multiple
							onChange={(e) => props.handleChipChange(e, 'categories')}
							value={form.categories} input={<Input />}
							renderValue={(selected) => (
								<div className={classes.chips}>
									{selected && selected.map((value) => (
										<Chip key={value} label={value} className={classes.chip} />
									))}
								</div>
							)}>
							{props.categories && props.categories.map((item, i) => (
								<MenuItem key={i} value={item}>
									{item}
								</MenuItem>
							))}
						</Select> */}
					</FormControl>
					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Tags"}
						</FormLabel>
						<ChipSelect
							className="mt-8 mb-16"
							value={
								form.tags && form.tags.map(item => ({
									value: item,
									label: item
								}))
							}
							onChange={(value) => props.handleChipChange(value, 'tags')}
							placeholder="Select multiple tags"
							textFieldProps={{
								InputLabelProps: {
									shrink: true
								},
								variant: 'outlined'
							}}
							isMulti
						/>
						{/* <Select name="tags" displayEmpty
							className="form-control"
							id="grouped-native-select"
							variant="outlined" multiple
							onChange={(e) => props.handleChipChange(e, 'tags')}
							value={form.tags} input={<Input />}
							renderValue={(selected) => (
								<div className={classes.chips}>
									{selected && selected.map((value) => (
										<Chip key={value} label={value} className={classes.chip} />
									))}
								</div>
							)}>
							{props.tags && props.tags.map((item, i) => (
								<MenuItem key={i} value={item}>
									{item}
								</MenuItem>
							))}
						</Select> */}
					</FormControl>
					{/* <FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Footer *"}
						</FormLabel>
						<TextField
							id="footer"
							name="footer"
							value={form.footer}
							onChange={props.handleChange}
							variant="outlined"
							color="secondary"
							className="form-control"
							placeholder="Footer"
						/>
					</FormControl> */}
				</form>
			</Grid>
		</Grid>
		<Grid item md={6}>
			<Grid container className="px-15">
				<img src={multisetting} alt="multi setting image" className="img-fluid" />
			</Grid>
		</Grid>
	</Grid>)
}