import React, { Component } from 'react'
import axios from 'axios'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
	Button, Checkbox, Chip, FormControl, FormControlLabel, FormLabel, InputAdornment, InputLabel,
	Radio, RadioGroup, Step, StepLabel, Stepper, Switch, Tab, Tabs, TextField, Typography, AppBar,
	Card, CardHeader, CardContent, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary,
	FormGroup, Grid, NativeSelect, withStyles
} from '@material-ui/core'
import { Delete, Edit, ExpandMore } from '@material-ui/icons'
import { orange } from '@material-ui/core/colors'

import { bindActionCreators } from 'redux'
import * as Actions from 'actions'

import Redux from '../../redux'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	card: {
		minWidth: 275,
		padding: "15px",
		width: "100%"
	},
	title: {
		color: '#fff',
	},
	textField: {
		"paddingRight": "10px",
	},
	label: {
		display: "block !important",
		"paddingTop": "20px",
		"paddingLeft": "0px"
	},
	staticText: {
		"width": "50%",
		"top": "50%"
	},
	radioStyle: {

		left: `21%`,
	}
	,
	dense: {
		marginTop: 19,
	},
	menu: {
		width: 200,
	},
	entryFormImageFeaturedStar: {
		position: 'absolute',
		top: 0,
		right: 0,
		color: orange[400],
		opacity: 0
	},
	modal: {
		top: `30%`,
		left: `30%`,
		transform: `translate(-30%, -30%)`,
		position: 'absolute',

	},
	inputLabel: {
		"paddingBottom": "35px",
		"paddingLeft": "5px",
		"position": "static"
	},
	formlabel: {
		minWidth: 100,
		display: "block",
		"paddingTop": "25px"
	}
});

class FormElements extends Component {
	constructor(props) {
		super(props);
		this.state = {
			design: {
				rows: []
			},
			hoverElement: "",
			dynamicData: null,
			activeStep: 1,
			dataModelValue: [],
			workflowTask: []
		}
	}

	elementDragStart = (event, selectedElement) => {
		if (this.props.viewOnly) {
			return;
		}
		event.stopPropagation();
		this.selectedElement = selectedElement;
		this.props.draggedElementHandler(this.selectedElement);

	}

	componentDidMount() {
		console.log("in - componentDidUpdate FE");
		// ExternalScripts["testPopulate"]();
		const { dynamicDataAPI, dataType, dataElement, dataModel, computed, lookupDataModal, lookupDataElement,
			lookupDataKey, workflow, type } = this.props.item

		if (dynamicDataAPI) {
			this.getDynamicData(this.props.item.dynamicDataAPI);
		}
		if (dataType === 'dataModel') {
			this.getDataModel(`${API_BASE_URL}/dataconnect/${dataModel}?q=${dataElement}`, this.props.appId);
		}

		if (computed === 'Lookup') {
			this.getDataModelValueForLookup(`${API_BASE_URL}/dataconnect/${lookupDataModal}?q=${lookupDataElement},${lookupDataKey}`)
		}

		if (type === 'namePicker') {
		}

		if (type === 'pageOrFormPicker') {
		}
	}

	getDataModelValueForLookup(url) {
		axios.get(url, { headers: { 'appId': this.props.match.params.dappId } })
			.then(res => {
				let lookupDataModelValue = res.data.data
				this.setState({ lookupDataModelValue: lookupDataModelValue })
			})
	}
	getDataModel = (url) => {
		if (!url) {
			this.setState({ dynamicData: null, apiUrl: null });
		}
		else {
			axios.get(url, { headers: { 'appId': this.props.match.params.dappId } })
				.then(res => {
					let dataModelValue = []
					const { dataElement, type } = this.props.item
					for (let i = 0; i < res.data.data.length; i++) {
						let val = res.data.data[i][dataElement]
						if (type == 'radio' || type === 'checkbox' || type === 'multiStepForm') {
							dataModelValue.push({
								value: val,
								label: val
							})
						} else {
							dataModelValue.push(val)
						}

					}
					this.setState({ dataModelValue })
				}).catch((err) => {
					alert("Unable to fetch data from the provided endpoint" + err);
				})
		}
	}

	getDynamicData = (url) => {
		if (!url) {
			this.setState({ dynamicData: null, apiUrl: null });
		}
		else {
			axios.get(url, { headers: { 'appId': this.props.match.params.dappId } })
				.then(res => {
					this.setState({ dynamicData: res.data, apiUrl: url });
				}).catch((err) => {
					alert("Unable to fetch data from the provided endpoint" + err);
				})
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.item.dynamicDataAPI != this.props.item.dynamicDataAPI) {
			this.getDynamicData(this.props.item.dynamicDataAPI)
		}
	}

	elementDropped = (e, selectedPosition) => {
		if (this.props.viewOnly) {
			return;
		}
		this.selectedPosition = selectedPosition;
		this.props.rearrangeElements(this.state.selectedElement, this.selectedPosition);
	}
	showEditOptions = (itemPosition) => {
		if (!this.props.viewOnly) {
			this.setState({ hoverElement: itemPosition })
		}
	}
	render() {
		const { classes } = this.props;
		const { item, outerIndex, gridSize, index } = this.props;
		if (item.dynamicDataAPI != null && this.state.dynamicData != null) {
			this.setDynamicData(item);
		}
		let itemPosition = `${outerIndex}-${index}`;
		return (
			<Grid style={item.backgroundColor ? { backgroundColor: item.backgroundColor } : {}}
				onMouseEnter={() => { this.showEditOptions(itemPosition) }}
				onMouseLeave={() => { this.setState({ hoverElement: "" }) }}
				draggable="true"
				onDragStart={(e) => {
					this.setState({ selectedElement: `${outerIndex}-${index}` });
					this.selectedElement = `${outerIndex}-${index}`;
					this.elementDragStart(e, `${outerIndex}-${index}`)
				}}
				onDragOver={(event) => event.preventDefault()}
				onDrop={(e) => { e.stopPropagation(); this.elementDropped(e, `${outerIndex}-${index}`) }}
				item xs={item.columns ? item.columns : gridSize} key={outerIndex + "-" + index}>
				{/* {this.generateElement()} */}
				<GenerateElements
					{...this.props}
					lookupDataModelValue={this.state.lookupDataModelValue}
					dataModelValue={this.state.dataModelValue}
					workflowTask={this.state.workflowTask}
					activeStep={this.state.activeStep}
					incrementActiveStep={(o) => this.setState(o)}
					decrementActiveStep={(o) => this.setState(o)}
					handleFileChange={this.handleFileChange}
					formElements={FormElements}
				/>
				{
					this.state.hoverElement == itemPosition && <>
						<Delete onClick={() => { this.props.deleteElement(outerIndex + "-" + index) }} />
						<Edit onClick={() => { this.props.editElement(item, outerIndex + "-" + index) }} />
					</>
				}
			</Grid>
		)
	}

}

export default withStyles(styles, { withTheme: true })(
	withRouter(Redux(FormElements))
)

import GenerateElements from './GenerateElements'

