import { ActionTypes } from 'constants/index'
import Utils from 'packages/page/utils'
import moment from 'moment'

export const getJobs = (appId) => dispatch => {
    return dispatch({ type: ActionTypes.GET_JOBS, payload: appId })
}

export const setJobsSearchText = (value) => dispatch => {
    return dispatch({ type: ActionTypes.SET_JOBS_SEARCH_TEXT, searchText: value })
}

export const getJob = (params) => dispatch => {
    return dispatch({ type: ActionTypes.GET_JOB, payload: params })
}

export const saveJob = (data) => dispatch => {
    return dispatch({ type: ActionTypes.SAVE_JOB, payload: data })
}

export const updateJob = (data) => dispatch => {
    return dispatch({ type: ActionTypes.UPDATE_JOB, payload: data })
}

export const deleteJob = (data) => dispatch => {
    return dispatch({ type: ActionTypes.DELETE_JOB, payload: data })
}

export const newJob = () => dispatch => {
    const data = {
        id: Utils.generateGUID(),
        name: '',
        description: '',
        jobType: '',
        frequency: '',
        rule: '',
        startDateTime: moment().format("YYYY-MM-DDTHH:mm"),
        endDateTime: moment().format("YYYY-MM-DDTHH:mm")
    }
    return dispatch({ type: ActionTypes.GET_JOB_SUCCESS, payload: data })
}