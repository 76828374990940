import {
  FormControlLabel,
  Grid,
  NativeSelect,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

import React, { useState } from 'react';

const Access = props => {
  const { selectedstep } = props;
  // const [assignTask, setassignTask] = useState('role');
  const [taskOwnerValue, setTaskOwnerValue] = useState('0');
  const handleChange = event => {
    // setassignTask(event.target.value);
  };
  return (
    <>
      {props.value === props.index && (
        <Grid container className="formPmodel">
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Escalate task when it’s due for long</label>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Duration in Days:</label>
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                name="AccessDurationDays"
                margin="dense"
                variant="filled"
                fullWidth
                value={selectedstep.AccessDurationDays}
                onChange={e => props.onChangeValue(e.target.name, e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Assigned To:</label>
            </Grid>
            <Grid item xs={8}>
              <RadioGroup
                name="assignTask"
                value={selectedstep.assignTask}
                onChange={e => props.onChangeValue(e.target.name, e.target.value)}
                row
              >
                <FormControlLabel value="role" control={<Radio />} label="Role" />
                <FormControlLabel value="persongroup" control={<Radio />} label="Person/Group" />
              </RadioGroup>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}></Grid>
            <Grid xs={8} style={{ display: 'flex', alignItems: 'center' }}>
              {selectedstep.assignTask === 'role' && (
                <>
                  <Grid xs={2}>
                    <label>Pick a role:</label>
                  </Grid>
                  <Grid xs={6}>
                    <NativeSelect
                      fullWidth
                      defaultValue="0"
                      // value={selectedstep.bussinessrule}
                      // onChange={e => this.onChangeValue('bussinessrule', e.target.value)}
                    >
                      <option value="0" disabled>
                        Select a Role
                      </option>

                      <option value="casemanager">Case Manager</option>
                      <option value="analyst">Analyst</option>
                      <option value="caseprocessor">Case Processor</option>
                      <option value="auditor">Auditor</option>
                    </NativeSelect>
                  </Grid>
                </>
              )}
              {selectedstep.assignTask === 'persongroup' && (
                <>
                  <Grid xs={2}>
                    <label>Pick a role:</label>
                  </Grid>
                  <Grid xs={6}>
                    <NativeSelect
                      fullWidth
                      defaultValue="0"
                      // value={selectedstep.bussinessrule}
                      // onChange={e => this.onChangeValue('bussinessrule', e.target.value)}
                    >
                      <option value="0" disabled>
                        Select a Role
                      </option>

                      <option value="john">John Smith</option>
                      <option value="johnny">Johnny Patt</option>
                      <option value="chris">Chris Jr</option>
                      <option value="dwyane">Dwyane Johnson</option>
                    </NativeSelect>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid xs={12}>
              <label> Who can take ownership of the task</label>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}></Grid>
            <Grid item xs={8}>
              <RadioGroup
                name="taskOwner"
                value={selectedstep.taskOwner}
                onChange={e => props.onChangeValue(e.target.name, e.target.value)}
                row
              >
                <FormControlLabel value="role" control={<Radio />} label="Role" />
                <FormControlLabel value="persongroup" control={<Radio />} label="Person/Group" />
              </RadioGroup>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}></Grid>
            <Grid xs={8} style={{ display: 'flex', alignItems: 'center' }}>
              {selectedstep.taskOwner === 'role' && (
                <>
                  <Grid xs={2}>
                    <label>Pick a role:</label>
                  </Grid>
                  <Grid xs={6}>
                    <NativeSelect
                      fullWidth
                      defaultValue="0"
                      // value={selectedstep.bussinessrule}
                      // onChange={e => this.onChangeValue('bussinessrule', e.target.value)}
                    >
                      <option value="0" disabled>
                        Select a Role
                      </option>

                      <option value="casemanager">Case Manager</option>
                      <option value="analyst">Analyst</option>
                      <option value="caseprocessor">Case Processor</option>
                      <option value="auditor">Auditor</option>
                    </NativeSelect>
                  </Grid>
                </>
              )}
              {selectedstep.taskOwner === 'persongroup' && (
                <>
                  <Grid xs={2}>
                    <label>Pick a role:</label>
                  </Grid>
                  <Grid xs={6}>
                    <NativeSelect
                      fullWidth
                      defaultValue="0"
                      // value={selectedstep.bussinessrule}
                      // onChange={e => this.onChangeValue('bussinessrule', e.target.value)}
                    >
                      <option value="0" disabled>
                        Select a Role
                      </option>

                      <option value="john">John Smith</option>
                      <option value="johnny">Johnny Patt</option>
                      <option value="chris">Chris Jr</option>
                      <option value="dwyane">Dwyane Johnson</option>
                    </NativeSelect>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Access;
