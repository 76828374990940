import React from 'react';
import { connect } from 'react-redux';
import * as Actions from 'actions';

const Redux = PassedComponent => {
  const mapStateToProps = ({ user, groups }) => {
    return {
      user: user,
      groups: groups,
    };
  };

  const mapDispatchToProps = {
    getGroups: Actions.getGroups,
    showAlert: Actions.showAlert,
    hideAlert: Actions.hideAlert,
    deleteGroup: Actions.deleteGroup,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(({ ...props }) => <PassedComponent {...props} />);
};

export default Redux;
