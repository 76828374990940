import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {
	const mapStateToProps = ({ user, navigation, members }) => {
		return {
			user: user,
			navigation: navigation,
			members: members,
			showAlert: Actions.showAlert,
			hideAlert: Actions.hideAlert
		}
	}

	const mapDispatchToProps = {
		getMembers: Actions.getMembers,
		deleteMember: Actions.deleteMember
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux