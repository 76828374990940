import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import photoone from '../../images/photoone.svg';
import oracleimg from '../../images/oracleimg.svg';
import microsoftsqlserver from '../../images/microsoftsqlserver.svg';
import netsuitelogo from '../../images/netsuitelogo.png';
import addnewimg from '../../images/addnewimg.svg';
import HocCard from '../@custom/HocCard';

const enterpriseSystems = [{
    name: "SAP",
    imgURL: `${photoone}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
}, {
    name: "Oracle ERP",
    imgURL: `${oracleimg}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
},
{
    name: "Microsoft Dynamics",
    imgURL: `${microsoftsqlserver}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
}, {
    name: "NetSuite",
    imgURL: `${netsuitelogo}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
}, {
    name: "Ariba",
    imgURL: `${photoone}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
}, {
    name: "Logo",
    imgURL: `${photoone}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
}
];

const databasesArray = [{
    name: "SAP",
    imgURL: `${photoone}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
}, {
    name: "Oracle ERP",
    imgURL: `${oracleimg}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
},
{
    name: "Microsoft Dynamics",
    imgURL: `${microsoftsqlserver}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
}, {
    name: "NetSuite",
    imgURL: `${netsuitelogo}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
}, {
    name: "Ariba",
    imgURL: `${photoone}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
}, {
    name: "Logo",
    imgURL: `${photoone}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
}];

const externalDataSources = [{
    name: "SAP",
    imgURL: `${photoone}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
}, {
    name: "Oracle ERP",
    imgURL: `${oracleimg}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
},
{
    name: "Microsoft Dynamics",
    imgURL: `${microsoftsqlserver}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
}, {
    name: "NetSuite",
    imgURL: `${netsuitelogo}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
}, {
    name: "Ariba",
    imgURL: `${photoone}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
}, {
    name: "Logo",
    imgURL: `${photoone}`,
    text: 'Text messaging, or texting, is the act of composing and sending electronic messages'
}];


export default function SelectAddDatasource(props) {
    return (
        <>
            <Grid item xs={12} className="mb5">
                <Typography variant="h5" className="enterpriseHeading">
                    Enterprise Systems
              </Typography>
                <Box className="enterpriseblock" component="div">
                    {props.datasourceTypes && props.datasourceTypes.data && <HocCard data={props.datasourceTypes.data.filter((item) => { if (item.type === "enterprise") return true })} noOfComponents={6} {...props} titlePosition='top' direction='ltr' isImage={true} isText={true} width="200px" handleNext={props.handleNext} />}
                    <Paper className="crsr-pntr">
                        <Box component="label">Add New</Box>
                        <img src={addnewimg} alt="temp image" />
                    </Paper>
                </Box>
            </Grid>
            <Grid item xs={12} className="mb5">
                <Typography component="h4" className="enterpriseHeading">
                    Databases
              </Typography>
                <Box className="enterpriseblock" component="div">
                {props.datasourceTypes && props.datasourceTypes.data && <HocCard data={props.datasourceTypes.data.filter((item) => { if (item.type === "database") return true })} noOfComponents={6} {...props} titlePosition='top' direction='ltr' isImage={true} isText={true} width="200px" handleNext={props.handleNext} />}
                    <Paper className="crsr-pntr">
                        <Box component="label">Add New</Box>
                        <img src={addnewimg} alt="temp image" />
                    </Paper>
                </Box>
            </Grid>
            <Grid item xs={12} className="mb5">
                <Typography component="h4" className="enterpriseHeading">
                    External Data Sources
              </Typography>
                <Box className="enterpriseblock" component="div">
                {props.datasourceTypes && props.datasourceTypes.data && <HocCard data={props.datasourceTypes.data.filter((item) => { if (item.type === "directoryServer") return true })} noOfComponents={6} {...props} titlePosition='top' direction='ltr' isImage={true} isText={true} width="200px" handleNext={props.handleNext} />}
                    <Paper className="crsr-pntr">
                        <Box component="label">Add New</Box>
                        <img src={addnewimg} alt="temp image" />
                    </Paper>
                </Box>
            </Grid>
        </>
    )

}