import React, { Component, useEffect, useState } from 'react'
// import { matchRoutes } from 'react-router-config'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import jwtService from 'jwtService'
import _ from 'modules/lodash'
import * as Actions from 'actions'
import { navigationConfig, navigationAppAdminConfig } from 'packages/navigationConfig/index'

let redirect = false


const Authorization = (props) => {

	const [appId, setAppId] = useState()

	useEffect(() => {
		
		let appId = manipulateNavigation(true)
		props.currentDappId(appId);
		setAppId(appId)
		if (props.memberByUserId?.data === null && appId && (props.user.id || props.user.email))
			props.getMemberByUserId({ ...props.user, dappId: appId })
	}, [])

	useEffect(() => {
		if (props.memberByUserId?.data === null && appId && (props.user.id || props.user.email))
			props.getMemberByUserId({ ...props.user, dappId: props.dappId })
	}, [props.user.id])

	useEffect(() => {
		let appId = manipulateNavigation(true)
		props.currentDappId(appId);
		setAppId(appId)
		if (props.memberByUserId?.data === null && appId && (props.user.id || props.user.email))
			props.getMemberByUserId({ ...props.user, dappId: appId })
	}, [props.dappId])

	useEffect(() => {
		let appId = manipulateNavigation(true)
		props.currentDappId(appId);
		setAppId(appId)
		if (props.memberByUserId?.data && props.memberByUserId?.data?.selectedRoles?.length > 0) {
			let superAdmin = false, appAdmin = false
			props.memberByUserId?.data?.selectedRoles?.map(role => {
				if (role.label === "SuperAdmin")
					superAdmin = true
				if (role.label === "AppAdmin" || role.label === "AppAdministrator")
					appAdmin = true
			})
			if (superAdmin && appId) {
				updateNavigation(appId, navigationConfig)
			} else if (appAdmin && appId) {
				updateNavigation(appId, navigationAppAdminConfig)
			}
		}
	}, [props.memberByUserId?.data])

	useEffect(() => {
		checkAuth()
		// manipulateNavigation()
	}, [props.location.pathname])

	const manipulateNavigation = (reset) => {
		if (props.location.pathname === "/builder/dapps" ||
			props.location.pathname === "/builder" ||
			props.location.pathname === "/builder/dapps/addappconfig" ||
			props.location.pathname === "/builder/dapps/addappfile") {
			reset && props.resetNavigation();
			props.resetMemberByUserId()
		} else {
			const appId = props.location.pathname.search("/builder/dapps") !== -1
				? props.location.pathname.split("/")[3]
				: props.location.pathname.split("/")[2];
			if (appId === ":appid" || appId === "addappconfig" || appId === "addappfile") {
				props.history.push("/builder/dapps")
			}
			if (appId === "new") {
				if(props.location.pathname === "/builder/dapps/new"){		  
					// props.history.push({
					//   pathname: "/builder/dapps/new",
					//   search: `${props.location.search}&h=${props.location.pathname}`,
					//   state: props.location.state
					// })
				  }
					else {
						props.history.push({
						pathname: "/builder/dapps/new",
						search: `${props.location.search}&h=${props.location.pathname}`,
						state: props.location.state
						})
				  }		  
			}
			if (appId && appId !== ":appid" && appId !== "dapps" && appId !== "addappconfig" && appId !== "addappfile") {
				return appId
				// props.currentDappId(appId);
				// setAppId(appId)
				// updateNavigation(appId);
			}
		}
		return false
	}

	const updateNavigation = (appId, navigation) => {
		let nav = navigation.map(val => {
			let children = val.children
				? val.children.map(c => {
					return { ...c, url: c.url.replace(/:appid|addappconfig|addappfile|appid|new/, appId) };
				})
				: undefined;
			return { ...val, url: val.url.replace(/:appid|addappconfig|addappfile|appid|new/, appId), children };
		})
		props.setNavigation(nav);
	}

	const checkAuth = () => {
		/*const matched = matchRoutes(props.routes, props.location.pathname)[0];
		console.log("matched ==> " + JSON.stringify(matched));
		if (matched && matched.route.auth && matched.route.auth.length > 0) {
			console.log("matched.route.auth == > " + JSON.stringify(matched.route.auth));
			console.log("props.user == > " + JSON.stringify(props.user));
			// if (props.user.roles.length === 0 || props.user.roles[0] === 'guest')
			if (!matched.route.auth.includes(props.user.role)) {
				redirect = true;
				console.log("user is " + JSON.stringify(props.user));
				if (props.user.role === 'guest')
				// if ( props.user.roles[0] === 'guest' )
				{
					//jwtService.handleAuthentication();

					props.history.push({
						pathname: '/login',
						state: { redirectUrl: props.location.pathname }
					});

				}
				else {
					props.history.push({
						pathname: '/'
					});
				}
			}
		}*/
		if (props.user.role === 'guest') {
			props.history.push({
				pathname: '/login',
				state: { redirectUrl: props.location.pathname }
			});
		} else {
			// props.history.push({
			// 	pathname: '/'
			// });
		}
	}

	useEffect(() => {
		// if (redirect) {
		// 	redirect = false;
		// 	return false;
		// }
		// else {
		// 	return true;
		// }
	}, [redirect])

	const { children } = props;

	return (
		<React.Fragment>
			{children}
		</React.Fragment>
	)
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getNavigation: Actions.getNavigation,
		setNavigation: Actions.setNavigation,
		resetNavigation: Actions.resetNavigation,
		currentDappId: Actions.currentDappId,
		getMemberByUserId: Actions.getMemberByUserId,
		resetMemberByUserId: Actions.resetMemberByUserId
	}, dispatch);
}

function mapStateToProps({ navigation, user, dapp, member }) {
	return {
		user,
		navigation: navigation.nav,
		dappId: dapp.appId,
		memberByUserId: member.memberByUserId
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Authorization));
