import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { DappsService } from 'services/index'
import { ActionTypes } from 'constants/index'


async function fetchAsync(func, arg) {
	const response = arg ? await func(arg) : await func();
	return response;
}

function* getDapps(action) {
	try {
		const dapps = yield call(fetchAsync, DappsService.getDapps, action.payload);
		yield put({ type: ActionTypes.GET_DAPPS_SUCCESS, payload: dapps });
	} catch (e) {
		yield put({ type: ActionTypes.GET_DAPPS_FAILURE, error: e.response });
	}
}

function* getCategories(action) {
	try {
		const categories = yield call(fetchAsync, DappsService.getCategories, action.payload);
		yield put({ type: ActionTypes.GET_CATEGORIES_SUCCESS, payload: categories });
	} catch (e) {
		yield put({ type: ActionTypes.GET_CATEGORIES_FAILURE, error: e.response });
	}
}

function* getApp(action) {
	try {
		const dapp = yield call(fetchAsync, DappsService.getApp, action.payload);
		yield put({ type: ActionTypes.GET_DAPP_SUCCESS, payload: dapp });
	} catch (e) {
		yield put({ type: ActionTypes.GET_DAPP_FAILURE, error: e.response });
	}
}

function* saveDapp(action) {
	try {
		const dapp = yield call(fetchAsync, DappsService.saveDapp, action.payload);
		yield put({ type: ActionTypes.SAVE_DAPP_SUCCESS, payload: dapp });
	} catch (e) {
		let errObj = { appCode: false, title: false };
		if (e.response) {
			errObj = e.response.fields || {};
			errObj.message = e.response.message ? e.response.message : "";
			errObj.code = e.response.code ? e.response.code : "";
			errObj.status = e.status ? e.status : ""
		}
		yield put({ type: ActionTypes.SAVE_DAPP_FAILURE, error: errObj });
	}
}

function* updateDapp(action) {
	try {
		console.log("action", action)
		const dapp = yield call(fetchAsync, DappsService.updateDapp, action.payload);
		yield put({ type: ActionTypes.UPDATE_DAPP_SUCCESS, payload: dapp });
	} catch (e) {
		let errObj = { appCode: false, title: false };
		if (e.response) {
			errObj = e.response.fields || {};
			errObj.message = e.response.message ? e.response.message : "";
			errObj.code = e.response.code ? e.response.code : "";
			errObj.status = e.status ? e.status : ""
		}
		yield put({ type: ActionTypes.UPDATE_DAPP_FAILURE, error: errObj });
	}
}

function* deleteDapp(action) {
	try {
		const dapps = yield call(fetchAsync, DappsService.deleteDapp, action.payload);
		yield put({ type: ActionTypes.DELETE_DAPPS_SUCCESS, payload: dapps });
	} catch (e) {
		yield put({ type: ActionTypes.DELETE_DAPPS_FAILURE, error: e.response });
	}
}

export function* watchDappsSaga() {
	yield takeEvery(ActionTypes.GET_DAPPS, getDapps)
	yield takeEvery(ActionTypes.GET_DAPP, getApp)
	yield takeEvery(ActionTypes.SAVE_DAPP, saveDapp)
	yield takeEvery(ActionTypes.UPDATE_DAPP, updateDapp)
	yield takeEvery(ActionTypes.DELETE_DAPP, deleteDapp)
	yield takeEvery(ActionTypes.GET_CATEGORIES, getCategories)
}

export default watchDappsSaga;