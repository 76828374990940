import React from 'react'
import {
    Button, Checkbox, FormControl, FormControlLabel, FormLabel, NativeSelect, Radio,
    RadioGroup, Tab, Tabs, TextField, Typography, withStyles, Select, MenuItem, Input,
    AppBar, Dialog, DialogActions, DialogTitle, Grid
} from '@material-ui/core'

const Conditions = (props) => {
    return Object.entries(props.elementsData.validation).map((el, index) => {
        return (
            <React.Fragment key={index} >
                <Grid style={{ "padding": "10px" }} item xs={4}>
                    <FormLabel >{el[0]}</FormLabel>
                </Grid>
                <Grid style={{ "padding": "10px" }} item xs={4}>
                    <FormLabel >{el[1] + ""}</FormLabel>
                </Grid>
                <Grid style={{ "padding": "10px" }} item xs={4}>
                    <Button variant="contained" color="secondary" onClick={() => {
                        props.removeCondition(el);
                    }} >-</Button>
                </Grid>
            </React.Fragment>
        )
    })
}
export default Conditions