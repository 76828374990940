export default {
    tabValue: 0,
    form: {
        accessRoles: [],
        readRoles: [],
        createRoles: [],
        deleteRoles: [],
        workflowId: ''
    },
    isRBMSSelected: true,
    savePossible: false,
    message: "Please provide name for the EntryForm",
    showMessage: false,
    editForm: false,
    formState: null,
    formExistsWithProvidedName: false,
    saveAsTemplate: false,
    design: {
        rows: []
    },
    modalData: {
        rows: []
    }
}