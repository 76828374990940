import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'styles/page-designer.css';
import PageDesignerCard from '../chart/PageDesignerCard';
import PageDesignerCardHighchart from '../highchart/PageDesignerCard';
import { withRouter } from 'react-router-dom';
import * as Actions from 'actions';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import _ from 'modules/lodash';
import NewPageDialog from 'components/Pages/new/NewPageDialog'
import { Responsive, WidthProvider } from "react-grid-layout";
import "./styles.css";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const styles = theme => ({
	closeIconContainer: {
		display: 'flex',
		flexDirection: 'row'
	}
});
let initialLayout = [];

class ComponentViewPane extends Component {
	static defaultProps = {
		className: "layout",
		rowHeight: 30,
		onLayoutChange: function () { },
		cols: { lg: 12, md: 12, sm: 12, xs: 4, xxs: 2 },
	};

	state = {
		currentBreakpoint: "lg",
		compactType: "vertical",
		mounted: false,
		layouts: { lg: initialLayout },
		currentAction: '',
		appId: this.props.appId ?
			this.props.appId : this.props.location.pathname.split("/")[3]
	};

	componentDidMount() {
		this.setState({ mounted: true });
		console.log(this.props.match.params, "this.props.match.params");
		if (this.props.match.params.pageId !== "new") {
			this.props.getPage(this.props.match.params);
			this.props.closeNewPageDialog();
		}
		// else
			// this.props.openNewPageDialog();
	}

	componentDidUpdate(prevProps, prevState) {
		let componentViewPaneElementHeight = document.getElementById(
			'componentViewPaneElement'
		).scrollHeight;
		let pageRendererElement = document.getElementById('pageRenderer');
		if (
			pageRendererElement &&
			!parseInt(pageRendererElement.style.height) <
			componentViewPaneElementHeight
		) {
			pageRendererElement.style.height =
				componentViewPaneElementHeight + 'px';
		}
		let width = parseInt(document.getElementById('componentViewPaneElement').style.width);
		let w = parseInt(pageRendererElement.style.width);
		console.log("width", width, w, pageRendererElement.style.width, "zz");
		if (width != w - 55 - 35) {
			document.getElementById('componentViewPaneElement').style.width = w - 55 - 35 + "px";
		}
		if (this.props.match.params.pageId !== prevProps.match.params.pageId) {
			this.props.getPage(this.props.match.params);
		}
	}

	onDrop = elemParams => {
		console.log("onDrop", elemParams, elemParams.e.dataTransfer.getData("id"));
		console.log(elemParams.e.dataTransfer);
		let id = elemParams.e.dataTransfer.getData("id");
		this.drop(id, elemParams);
	};

	addChartData = (defaultChartData, elemParams) => {
		defaultChartData.chartData.layout = {
			...defaultChartData.chartData.layout,
			x: elemParams.x,
			y: elemParams.y,
		}
		return defaultChartData
	}

	drop(id, elemParams) {
		let props = this.props;
		if (id === Actions.barChart) {
			props.addBarChartData({
				id: Actions.barChart,
				chart: this.addChartData(Actions.defaultBarChartData(), elemParams)
			})
		}
		if (id === Actions.stackedBarChart) {
			props.addStackedBarChartData({
				id: Actions.stackedBarChart,
				chart: this.addChartData(Actions.defaultStackedBarChartData(), elemParams)
			})
		}
		if (id === Actions.lineChart) {
			props.addLineChartData({
				id: Actions.lineChart,
				chart: this.addChartData(Actions.defaultLineChartData(), elemParams)
			})
		}
		if (id === Actions.pieChart) {
			props.addPieChartData({
				id: Actions.pieChart,
				chart: this.addChartData(Actions.defaultPieChartData(), elemParams)
			})
		}
		if (id === Actions.mixedChart) {
			props.addMixedChartData({
				id: Actions.mixedChart,
				chart: this.addChartData(Actions.defaultMixedChartData(), elemParams)
			})
		}
		if (id === Actions.bubbleChart) {
			props.addBubbleChartData({
				id: Actions.bubbleChart,
				chart: this.addChartData(Actions.defaultBubbleChartData(), elemParams)
			})
		}
		if (id === Actions.doughnutChart) {
			props.addDoughnutChartData({
				id: Actions.doughnutChart,
				chart: this.addChartData(Actions.defaultDoughnutChartData(), elemParams)
			})
		}
		if (id === Actions.radarChart) {
			props.addRadarChartData({
				id: Actions.radarChart,
				chart: this.addChartData(Actions.defaultRadarChartData(), elemParams)
			})
		}
		if (id === Actions.polarChart) {
			props.addPolarChartData({
				id: Actions.polarChart,
				chart: this.addChartData(Actions.defaultPolarChartData(), elemParams)
			})
		}
		if (id === Actions.listChart) {
			props.addListChartData({
				id: Actions.listChart,
				chart: this.addChartData(Actions.defaultListChartData(), elemParams)
			})
		}
		if (id === Actions.simpleTable) {
			props.addSimpleTableData({
				id: Actions.simpleTable,
				chart: this.addChartData(Actions.defaultSimpleTableData(), elemParams)
			})
		}
		if (id === Actions.card) {
			props.addCardData({
				id: Actions.card,
				chart: this.addChartData(Actions.defaultCardData(), elemParams)
			})
		}
		if (id === Actions.html) {
			props.addHTMLData({
				id: Actions.html,
				chart: this.addChartData(Actions.defaultHTMLData(), elemParams)
			})
		}
		if (id === Actions.simpleList) {
			props.addSimpleListData({
				id: Actions.simpleList,
				chart: this.addChartData(Actions.defaultSimpleListData(), elemParams)
			})
		}
		if (id === Actions.summaryTable) {
			props.addSummaryTableData({
				id: Actions.summaryTable,
				chart: this.addChartData(Actions.defaultSummaryTableData(), elemParams)
			})
		}
		if (id === Actions.bulletChart) {
			props.addBulletChartData({
				id: Actions.bulletChart,
				chart: this.addChartData(Actions.defaultBulletChartData(), elemParams)
			})
		}
		if (id === Actions.links) {
			props.addLinksData({
				id: Actions.links,
				chart: this.addChartData(Actions.defaultLinksData(), elemParams)
			})
		}
	}

	onBreakpointChange = breakpoint => {
		this.setState({
			currentBreakpoint: breakpoint
		});
	};

	onCompactTypeChange = () => {
		const { compactType: oldCompactType } = this.state;
		const compactType =
			oldCompactType === "horizontal"
				? "vertical"
				: oldCompactType === "vertical"
					? null
					: "horizontal";
		this.setState({ compactType });
	};

	onLayoutChange = (layout, layouts) => {
		console.log("ComponentViewPane onLayoutChange");
		let newItem = false;
		layout.map(l => {
			if (l.i === "__dropping-elem__")
				newItem = true;
		})
		console.log("newItem =- 1", !newItem && this.props.page.add, layout);
		console.log("newItem =- 2", newItem, this.props.page.add, !newItem, !this.props.page.add);

		if (!newItem) {
			let page = this.props.page;
			page.chart && page.chart.length > 0
				&& page.chart.map((element, i) => {
					if (!_.isEqual(this.props.page.chart[i].chartData.layout, layout[i])) {
						console.log("resize==", this.props.page.chart[i].chartData.layout, layout[i]);

						let chart = JSON.parse(JSON.stringify(this.props.page.chart[i]))
						chart.chartData.layout = layout[i];

						this.props.saveResizedChartData({
							chart,
							index: i,
							showDataToEditComponent: false
						})
					}
				})
		}
	};

	onResize = (layout, oldItem, newItem, placeholder, e, element) => {
		console.log("ComponentViewPane onResize", oldItem, newItem, !_.isEqual(oldItem, newItem));

		// let chartUniqueId = this.props.page.chart[parseInt(newItem.i)].chartUniqueId;
		// let chartType = this.props.page.chart[parseInt(newItem.i)].chartType;

		if (!_.isEqual(oldItem, newItem)) {
			console.log("onResize Update Chart");
			let chart = JSON.parse(JSON.stringify(this.props.page.chart[parseInt(newItem.i)]))
			chart.chartData.layout = newItem;

			// this.props.saveResizedChartData(page)

			this.props.saveResizedChartData({
				chart,
				index: newItem.i,
				showDataToEditComponent: false
			});

		}
	}

	render() {
		console.log("this.pages.page", this.props.page);
		const page = _.cloneDeep(this.props.page);
		let allChart = null;
		let componentViewPaneClass =
			'componentViewPane layoutRoot' +
			(this.props.readOnly ? ' removeLeftPadding' : '');
		console.log("page.chart", page.chart);

		allChart =
			page.chart &&
			page.chart.length > 0 &&
			page.chart.map((element, i) => {
				return (
					<div key={i}
						style={{ height: "inherit", width: "inherit" }}
						id="PageDesignerCard1-wrapper">
						{
							process.env.REACT_APP_HIGHCHARTS === "true"
								? <PageDesignerCardHighchart
									readOnly={this.props.readOnly}
									key={element.chartUniqueId}
									element={element}
								/>
								: <PageDesignerCard
									readOnly={this.props.readOnly}
									key={element.chartUniqueId}
									element={element}
								/>
						}
					</div>
				);
			});
		let lg;
		if (page.chart && page.chart.length > 0) {
			lg = page.chart.map((element, i) => {
				console.log("element", element);
				console.log("element.chartData", element.chartData);
				console.log("element.chartData.layout", element.chartData.layout);
				if (element.chartData.layout) {

				}
				return {
					...element.chartData.layout,
					'x': element.chartData.layout && element.chartData.layout.x ? element.chartData.layout.x : 0,
					'y': element.chartData.layout && element.chartData.layout.y ? element.chartData.layout.y : 0,
					'i': "" + i,
					"moved": false,
					"static": false
				}
			})
		} else {
			lg = [{
				'x': 0, 'y': 0, w: 12, h: 2,
				'i': "enforce", "moved": false, "static": true
			}];
			allChart = <div key="enforce" align="center"
				style={{ "backgroundColor": "transparent", "borderRadius": "0px" }}>
				{this.props.match.params.pageId === "new"
					? <>"There is no layout to load" <br /></>
					: <> {/*<CircularProgress size={68} /> */}"There is no layout to load" <br /></>
				}
			</div>
		}
		let layouts = { lg };

		return (
			<div
				id="componentViewPaneElement"
				className={componentViewPaneClass}
				style={{ width: "92.1%", height: "100%" }} // TODO: Need top fix this styles
			>
				{layouts && <ResponsiveReactGridLayout
					{...this.props}
					layouts={layouts}
					onBreakpointChange={this.onBreakpointChange}
					onLayoutChange={this.onLayoutChange}
					onResize={this.onResize}
					onDrop={this.onDrop}
					// WidthProvider option
					measureBeforeMount={false}
					// I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
					// and set `measureBeforeMount={true}`.
					useCSSTransforms={this.state.mounted}
					compactType={this.state.compactType}
					preventCollision={!this.state.compactType}
					isDroppable={true}
				>
					{allChart}
				</ResponsiveReactGridLayout>
				}
				<NewPageDialog appId={this.state.appId} page={page} edit={true} />
			</div>
		);
	}
}


function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			closeNewPageDialog: Actions.closeNewPageDialog,
			openNewPageDialog: Actions.openNewPageDialog,
			getPage: Actions.getPage,
			// deleteChartData: Actions.deleteChartData,
			addBarChartData: Actions.addBarChartData,
			addStackedBarChartData: Actions.addStackedBarChartData,
			addBubbleChartData: Actions.addBubbleChartData,
			addPieChartData: Actions.addPieChartData,
			addMixedChartData: Actions.addMixedChartData,
			addDoughnutChartData: Actions.addDoughnutChartData,
			addListChartData: Actions.addListChartData,
			addCardData: Actions.addCardData,
			addHTMLData: Actions.addHTMLData,
			addLineChartData: Actions.addLineChartData,
			addRadarChartData: Actions.addRadarChartData,
			addPolarChartData: Actions.addPolarChartData,
			addBulletChartData: Actions.addBulletChartData,
			addSimpleListData: Actions.addSimpleListData,
			addSummaryTableData: Actions.addSummaryTableData,
			addSimpleTableData: Actions.addSimpleTableData,
			saveResizedChartData: Actions.saveResizedChartData,
			addLinksData: Actions.addLinksData
		},
		dispatch
	);
}

function mapStateToProps({ pages, dapp }) {
	console.log("pages.page", pages.page);
	return {
		page: pages.page,
		appId: dapp.appId,
	};
}

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(
			mapStateToProps,
			mapDispatchToProps
		)(ComponentViewPane)
	)
)
