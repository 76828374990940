import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './View'
import Redux from './Redux'
import { withRouter } from 'react-router-dom'
import {
	addCondAndLink,
	editCondAndLink,
	deleteCondAndLink
} from 'packages/page/component.module';

const Container = (props) => {

	const [state, setState] = useState({
		tabValue: 0,
		form: { entityId: props.user ? props.user.orgId : "", privileges: [], privilege: {} },
		privileges: [],
		isSubmitButton: true,
		privilege: {}
	});

	const [error, setError] = useState({ name: false, email: false })

	const handleChange = (event) => {
		setState({
			...state,
			form: _.set({ ...state.form }, event.target.name,
				event.target.type === 'checkbox' ? event.target.checked : event.target.value)
		});
	};

	const updateRoleState = () => {
		const params = props.match.params;
		const { roleId } = params;

		if (roleId === 'new') {
			props.newRole();
		}
		else {
			props.getRole(props.match.params);
		}
		props.getPrivileges(props.match.params);
	};

	const updateFormState = () => {
		let pList = []
		console.log();
		props.role.data.privileges?.map((pr, i) => {
			// let pr = "App.SpendPlan:Forms.TPRSheet:*"
			console.log(pr, "pr ====");
			if (pr) {
				let scopes = pr.split(':')
				let cScopes = scopes[1].split('.')
				pList.push({
					privilegeType: cScopes[0],
					privilegeValue: cScopes[1],
					privilegeAction: scopes[2] === "*" ? 'all' : scopes[2]
				})
			}
		})
		setState({ ...state, form: { ...props.role.data, privileges: pList }, privileges: pList });
	};

	const setPrivilegeData = (pList) => {
		setState({ ...state, form: { ...form, privileges: pList }, privileges: pList });
	}

	const handleChipChange = (event, name) => {
		console.log('event::', event.target.value, name);
		setState({ ...state, form: _.set({ ...state.form }, name, event.target.value) });
	};

	const canBeSubmitted = () => {
		const { name } = state.form;
		if (name && name.length === 0) {
			setError({ ...error, name: "Please enter name" })
		}
		return name && name.length > 0 && !_.isEqual(props.role.data, state.form) && state.isSubmitButton
	}

	useEffect(() => {
		props.getDapp({ dappId: props.match.params.dappId })
		props.getPages(props.match.params.dappId);
		props.getEntryForms(props.match.params.dappId);
		props.getDataModels(props.match.params.dappId);
		updateRoleState();
	}, [props.location])

	useEffect(() => {
		if (props.role.data && props.role.data._id)
			updateFormState();
	}, [props.role.data])

	useEffect(() => {
		// let pList = []
		// if (props.match.params.roleId !== "new") {
		// 	props.privileges && props.privileges.map(privilege => {
		// 		return props.role && props.role.data && props.role.data.privileges && props.role.data.privileges.map(id => {
		// 			if (id === privilege._id) {
		// 				pList.push({
		// 					value: privilege._id,
		// 					label: privilege.name
		// 				})
		// 			}
		// 		})
		// 	})
		// }
		// setState({
		// 	...state,
		// 	form: { ...state.form, privileges: pList },
		// 	privileges: props.privileges && props.privileges.map(privilege => {
		// 		return {
		// 			value: privilege._id,
		// 			label: privilege.name
		// 		}
		// 	})
		// })
	}, [props.privileges]);

	useEffect(() => {
		if (props.role.saveSuccess) {
			props.showAlert('Role saved successfully', { id: "role", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/roles/${props.role.data._id}` });
		}
		if (props.role.updateSuccess) {
			props.showAlert('Role updated successfully', { id: "role", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/roles/${props.role.data._id}` });
		}
	}, [props.role.saveSuccess, props.role.updateSuccess])


	useEffect(() => {
		let error = props.role.error;
		if (error) {
			error.name
				&& props.showAlert("NETWORK ERROR: Please verify your internet connection", {
					id: "role",
					variant: 'danger',
					icon: 'times-circle'
				})
			if (error.name) {
				setError({ ...error, name: error.name });
			}
		}
	}, [props.role.error])

	useEffect(() => {
		if (typeof props.role.error === "object" && props.role.error.status) {
			if (props.role.error.status == 401) {
				props.history.push({ pathname: "/login" });
				props.showAlert(props.role.error.errorMessage, { id: "role", variant: 'error' });
			} else {
				if (props.role.error.errorMessage) {
					setState({ ...state, isSubmitButton: true });
					if (props.role.error.errorMessage) {
						props.showAlert(props.role.error.errorMessage, { id: "role", variant: 'error' });
					}
				}
			}
		}
	}, [props.role.error])

	const onPrivilegeChangeHandler = event => {
		let privilege = state.privilege;
		privilege[event.target.name] = event.target.value
		setState({ ...state, privilege });
	}

	const addPrivilege = (privilege) => {
		let privileges = addCondAndLink(privilege, state.privileges);
		setState({ ...state, privileges, privilege: { value: "" } });
	}

	const editPrivilege = (privilege, index) => {
		let cond = editCondAndLink(privilege, index, state.privileges);
		setState({ ...state, privileges: cond.ary, privilege: cond.obj });
	}

	const deletePrivilege = (privilege, index) => {
		let privileges = deleteCondAndLink(privilege, index, state.privileges);
		setState({ ...state, privileges });
	}

	const genPrivileges = () => {
		return state.privileges.map((p, i) => {
			return `App.${props.dapp.data.dappCode}:${p.privilegeType}.${p.privilegeValue}:${p.privilegeAction}`
		})
	}

	const save = () => {
		let privileges = genPrivileges()
		props.saveRole({ ...state.form, privileges, appId: props.match.params.dappId })
	}

	const update = () => {
		let privileges = genPrivileges()
		props.updateRole({ ...state.form, privileges, appId: props.match.params.dappId })
	}

	return (
		<View
			{...props}
			state={state}
			error={error}
			handleChange={handleChange}
			handleChipChange={handleChipChange}
			saveRole={() => save()}
			roles={state.roles}
			updateRole={() => update()}
			canBeSubmitted={canBeSubmitted}
			setPrivilegeData={setPrivilegeData}
			onPrivilegeChangeHandler={onPrivilegeChangeHandler}
			addPrivilege={addPrivilege}
			editPrivilege={editPrivilege}
			deletePrivilege={deletePrivilege}
		/>
	)
}

export default hot(withRouter(Redux(Container)))