import React from 'react';

const SystemTask = props => {
  return (
    <svg
      id="page_designer"
      data-name="page designer"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 41 41"
      x={props.x}
      y={props.y}
      onClick={props.onClick}
    >
      <rect
        id="Rectangle_598"
        data-name="Rectangle 598"
        width="41"
        height="41"
        fill="#fff"
        opacity="0"
      />
      <g id="Group_321" data-name="Group 321" transform="translate(-48 -1162)">
        <path
          id="Path_492"
          data-name="Path 492"
          d="M19.305,0H2.323A2.326,2.326,0,0,0,0,2.323V5.111H21.629V2.323A2.326,2.326,0,0,0,19.305,0ZM13.349,3.844a.634.634,0,1,1,.634-.634A.634.634,0,0,1,13.349,3.844Zm2.535,0a.634.634,0,1,1,.634-.634A.634.634,0,0,1,15.884,3.844Zm2.535,0a.634.634,0,1,1,.634-.634A.634.634,0,0,1,18.418,3.844Zm0,0"
          transform="translate(58 1172)"
          fill="#000"
        />
        <path
          id="Path_493"
          data-name="Path 493"
          d="M3.844,159.871v2.535H8.913v-2.535Zm0-6.337v2.535h13.94v-2.535Zm0,0v2.535h13.94v-2.535Zm0,6.337v2.535H8.913v-2.535ZM0,151v12.926a2.326,2.326,0,0,0,2.323,2.323H19.305a2.326,2.326,0,0,0,2.323-2.323V151Zm10.181,12.039a.634.634,0,0,1-.634.634H3.211a.634.634,0,0,1-.634-.634v-3.8a.634.634,0,0,1,.634-.634H9.547a.634.634,0,0,1,.634.634Zm8.871,0a.634.634,0,0,1-.634.634H12.082a.634.634,0,0,1-.634-.634v-3.8a.634.634,0,0,1,.634-.634h6.337a.634.634,0,0,1,.634.634Zm0-6.337a.634.634,0,0,1-.634.634H3.211a.634.634,0,0,1-.634-.634v-3.8a.634.634,0,0,1,.634-.634H18.418a.634.634,0,0,1,.634.634Zm-6.337,5.7h5.069v-2.535H12.715Zm-8.871-6.337h13.94v-2.535H3.844Zm0,6.337H8.913v-2.535H3.844Zm0-8.871v2.535h13.94v-2.535Zm0,0v2.535h13.94v-2.535Zm0,0"
          transform="translate(58 1027.379)"
          fill="#000"
        />
      </g>
    </svg>
  );
};

export default SystemTask;
