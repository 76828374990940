import React from 'react';

const DatapipelineTask = props => {
  return (
    <svg
      id="Datasources"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 41 41"
      x={props.x}
      y={props.y}
      onClick={props.onClick}
    >
      <rect
        id="Rectangle_592"
        data-name="Rectangle 592"
        width="41"
        height="41"
        fill="#fff"
        opacity="0"
      />
      <g id="data-center" transform="translate(4 4)">
        <path
          id="Path_456"
          data-name="Path 456"
          d="M31.237,7H23V9.746h8.237ZM28.492,8.831h-.915V7.915h.915Zm1.831,0h-.915V7.915h.915Z"
          transform="translate(-10.847 -2.169)"
          fill="#000"
        />
        <path
          id="Path_457"
          data-name="Path 457"
          d="M23,34.225c.151-.013.3-.022.458-.022a5.046,5.046,0,0,1,1.818.339,5.9,5.9,0,0,1,5.962-2V31H23Zm2.746-2.31h.915v.915h-.915Zm-1.831,0h.915v.915h-.915Z"
          transform="translate(-10.847 -15.186)"
          fill="#000"
        />
        <path
          id="Path_458"
          data-name="Path 458"
          d="M31.237,15H23v2.746h8.237Zm-6.407,1.831h-.915v-.915h.915Zm1.831,0h-.915v-.915h.915Z"
          transform="translate(-10.847 -6.508)"
          fill="#000"
        />
        <path
          id="Path_459"
          data-name="Path 459"
          d="M31.237,23H23v2.746h8.237Zm-2.746,1.831h-.915v-.915h.915Zm1.831,0h-.915v-.915h.915Z"
          transform="translate(-10.847 -10.847)"
          fill="#000"
        />
        <path
          id="Path_460"
          data-name="Path 460"
          d="M9.407,11.915V11H4.373A1.375,1.375,0,0,0,3,12.373V25.186a1.371,1.371,0,0,0,.915,1.289V12.831a.916.916,0,0,1,.915-.915Z"
          transform="translate(0 -4.339)"
          fill="#000"
        />
        <path
          id="Path_461"
          data-name="Path 461"
          d="M7,33.746h4.576V31H7Zm2.746-1.831h.915v.915H9.746Zm-1.831,0h.915v.915H7.915Z"
          transform="translate(-2.169 -15.186)"
          fill="#000"
        />
        <path
          id="Path_462"
          data-name="Path 462"
          d="M11.576,15H7v2.746h4.576ZM8.831,16.831H7.915v-.915h.915Zm1.831,0H9.746v-.915h.915Z"
          transform="translate(-2.169 -6.508)"
          fill="#000"
        />
        <path
          id="Path_463"
          data-name="Path 463"
          d="M7,23h4.576v2.746H7Z"
          transform="translate(-2.169 -10.847)"
          fill="#000"
        />
        <path
          id="Path_464"
          data-name="Path 464"
          d="M19.915,4.831a.916.916,0,0,1,.915-.915h8.237a.916.916,0,0,1,.915.915V17.647a5.954,5.954,0,0,1,.915.479V4.373A1.375,1.375,0,0,0,29.525,3H20.373A1.375,1.375,0,0,0,19,4.373v15.2a4.962,4.962,0,0,1,.915-.358Z"
          transform="translate(-8.678)"
          fill="#000"
        />
        <path
          id="Path_465"
          data-name="Path 465"
          d="M47,17.746h4.576V15H47Zm2.746-1.831h.915v.915h-.915Zm-1.831,0h.915v.915h-.915Z"
          transform="translate(-23.864 -6.508)"
          fill="#000"
        />
        <path
          id="Path_466"
          data-name="Path 466"
          d="M11.576,39.694V39H7v2.746h3.091A5.02,5.02,0,0,1,11.576,39.694Z"
          transform="translate(-2.169 -19.525)"
          fill="#000"
        />
        <path
          id="Path_467"
          data-name="Path 467"
          d="M47,33.746h4.576V31H47Zm2.746-1.831h.915v.915h-.915Zm-1.831,0h.915v.915h-.915Z"
          transform="translate(-23.864 -15.186)"
          fill="#000"
        />
        <path
          id="Path_468"
          data-name="Path 468"
          d="M52.034,11H47v.915h4.576a.916.916,0,0,1,.915.915V26.475a1.371,1.371,0,0,0,.915-1.289V12.373A1.375,1.375,0,0,0,52.034,11Z"
          transform="translate(-23.864 -4.339)"
          fill="#000"
        />
        <path
          id="Path_469"
          data-name="Path 469"
          d="M47,23h4.576v2.746H47Z"
          transform="translate(-23.864 -10.847)"
          fill="#000"
        />
        <path
          id="Path_470"
          data-name="Path 470"
          d="M49.422,41.746H52.24V39H48.223a5.917,5.917,0,0,1,1.2,2.746Z"
          transform="translate(-24.528 -19.525)"
          fill="#000"
        />
        <path
          id="Path_471"
          data-name="Path 471"
          d="M26.763,41.492h-.021c.014-.151.021-.3.021-.458a5.03,5.03,0,0,0-9.336-2.6l.005-.017a4.12,4.12,0,0,0-6.2,3.08H11.2a3.2,3.2,0,0,0,0,6.407H26.763a3.2,3.2,0,0,0,0-6.407ZM12.119,46.525H11.2V45.61h.915Zm1.831,0h-.915V45.61h.915Zm1.831,0h-.915V45.61h.915Zm8.667-5.884a2.748,2.748,0,0,0-3.5-2.239l-.261-.878a3.665,3.665,0,0,1,4.67,2.987Z"
          transform="translate(-2.712 -17.898)"
          fill="#000"
        />
      </g>
    </svg>
  );
};

export default DatapipelineTask;
