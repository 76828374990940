import React, { useState, useEffect } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import {
	Stepper, Step, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	StepLabel, Paper, Grid, List, ListItem, ListItemText, Link, Typography,
	TextField, FormControl, InputLabel, Checkbox, FormControlLabel, FormLabel, Button,
	Select, Dialog, MuiDialogTitle, MuiDialogContent, MuiDialogActions, IconButton, CloseIcon
} from '@material-ui/core'

import photoone from 'images/photoone.svg';
import Editicon from 'images/pencil-edit-button.svg';
import deleteredicon from 'images/deleteredicon.svg';
import oracleimg from 'images/oracleimg.svg';
import microsoftsqlserver from 'images/microsoftsqlserver.svg';
import netsuitelogo from 'images/netsuitelogo.png';
import addnewimg from 'images/addnewimg.svg';
import active from 'images/entities-active.svg';
import inActive from 'images/entities-inactive.svg';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}))

export default function Row(props) {
	const classes = useStyles()
	const { entity } = props
	return (<Box className="abccord-block">
		<Grid container>
			<Grid item md={3}>
				<Box className="">
					<Box component="span" className="acordimg-block">
						<img src={photoone} alt="Abccord" className="cordimg" />
					</Box>
					<Typography variant="h5" className="fontbold">Name</Typography>
					<Typography variant="h6">{entity.name}</Typography>
					<Typography variant="h6">{entity.addressLine1}</Typography>
					<Typography variant="h6">{entity.addressLine2}</Typography>
				</Box>
			</Grid>
			<Grid item md={2}>
				<Box className="">
					<Typography variant="h5" className="fontbold">Contact</Typography>
					<Typography variant="h6">{entity.primaryContactName}</Typography>
					<Typography variant="h6">{entity.primaryContactPhone}</Typography>
				</Box>
			</Grid>
			<Grid item md={2}>
				<Box className="">
					<Typography variant="h5" className="fontbold">Industry</Typography>
					<Typography variant="h5">{entity.industryType1}</Typography>
					<Typography variant="h5">{entity.industryType2}</Typography>
				</Box>
			</Grid>
			<Grid item md={2}>
				<Box className="">
					<Typography variant="h5" className="fontbold">Status</Typography>
					<Typography variant="h5">{entity.status}</Typography>
					{/* <Typography variant="h5">{entity.service2}</Typography> */}
				</Box>
			</Grid>
			<Grid item md={3} className="cordimggrid">
				<Box className="imggrid">
					<Box className="crsr-pntr" onClick={() => props.handleActionsClick('edit', entity)}>
						<img src={Editicon} alt="" />
						<Typography variant="h6" className="smalltxt">Edit</Typography>
					</Box>
					{/* <Box className="crsr-pntr" onClick={() => props.handleActionsClick('add', entity)}>
						<img src={Editicon} alt="" />
						<Typography variant="h6" className="smalltxt">Add to Network</Typography>
					</Box> */}
					<Box className="crsr-pntr" onClick={() => props.handleActionsClick('delete', entity)}>
						<img src={deleteredicon} alt="" />
						<Typography variant="h6" className="smalltxt">Delete</Typography>
					</Box>
					<Box className="crsr-pntr" onClick={() => props.handleActionsClick('inactive', entity)}>
						<img src={entity.status === "Active" ? active : inActive} alt="" />
						<Typography variant="h6" className="smalltxt">{entity.status}</Typography>
					</Box>
				</Box>
			</Grid>
		</Grid>
	</Box>)
}