import { Grid, NativeSelect, TextField } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import React from 'react';

const Notification = props => {
  const { selectedstep, notifications } = props;
  
  const [chipData, setChipData] = React.useState([
    { key: 0, label: 'Form Section: Approver' },
    { key: 1, label: 'Form Section: Interset' },
  ]);

  return (
    <>
      {props.value === props.index && (
        <Grid container className="formPmodel">
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Select notification template to send email to task owner</label>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <label>When task is assigned:</label>
            </Grid>
            <Grid item xs={6}>
              <NativeSelect
                fullWidth
                defaultValue="0"
                name = "notification"
                value={selectedstep.notification}
                onChange={e => props.onChangeValue(e.target.name, e.target.value)}
              >
                <option value="0" disabled>
                  Notification: Loan Application Reviewer Template
                </option>
                {notifications && notifications.data.map(item => {
                  return (<option value={item.name}>{item.name}</option>)
                })}
              </NativeSelect>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <label>When task is Due:</label>
            </Grid>
            <Grid item xs={7}>
              <NativeSelect
                fullWidth
                defaultValue="0"
                name="NotificationTaskDue"
                value={selectedstep.NotificationTaskDue}
                onChange={e => props.onChangeValue(e.target.name, e.target.value)}
              >
                <option value="0" disabled>
                  Notification: Loan Application Reviewer - Due - Template
                </option>

                <option value="taskDue1">Task Due 1</option>
                <option value="taskDue2">Task Due 2</option>
                <option value="taskDue3">Task Due 3</option>
              </NativeSelect>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Notification;
