import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import React from 'react';

const UI = props => {
  const { selectedstep } = props;

  const [chipData, setChipData] = React.useState([
    { key: 0, label: 'Form Section: Approver' },
    { key: 1, label: 'Form Section: Interset' },
  ]);
  const [personName, setPersonName] = React.useState([]);

  const handleChange = event => {
    setPersonName(event.target.value);
  };

  const names = ['Form Section: Approver', 'Form Section: Interset'];
  function getStyles(name, personName) {
    return {
      fontWeight: personName.indexOf(name) === -1 ? 'regular' : 'bold',
    };
  }

  return (
    <>
      {props.value === props.index && (
        <Grid container className="formPmodel">
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <label>UI Screen to display when this task assigned to a user</label>
            </Grid>
            <Grid item xs={6}>
              <NativeSelect
                fullWidth
                defaultValue="0"
                name="taskAssignedToUser"
                value={selectedstep.taskAssignedToUser}
                onChange={e => props.onChangeValue(e.target.name, e.target.value)}
              >
                <option value="0" disabled>
                  Loan Application
                </option>

                <option value="app1">Application 1</option>
                <option value="app2">Application 2</option>
                <option value="app3">Application 3</option>
              </NativeSelect>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Form sections/elements must be completed by task owner in this step:</label>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <Select
                  multiple
                  name="taskOwnerSection"
                  // value={selectedstep.taskOwnerSection}
                  // onChange={e => props.onChangeValue(e.target.name, e.target.value)}
                  value={personName}
                  onChange={handleChange}
                  input={<Input id="select-multiple-chip" />}
                  displayEmpty
                  renderValue={selected => (
                    <div
                      style={{
                        display: 'flex',
                        paddingLeft: 5,
                        flexWrap: 'wrap',
                      }}
                    >
                      {selected.map(value => (
                        <li style={{ listStyle: 'none', marginRight: 5, marginBottom: 5 }}>
                          <Chip key={value} label={value} onDelete={() => {}} />
                        </li>
                      ))}
                    </div>
                  )}
                >
                  <MenuItem value="" disabled>
                    Placeholder
                  </MenuItem>
                  {names.map(name => (
                    <MenuItem key={name} value={name} style={getStyles(name, personName)}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UI;
