import { ActionTypes } from 'constants/index'
import Utils from 'packages/page/utils';

export const getDatasourceTypes = () => dispatch => {
	return dispatch({ type: ActionTypes.GET_DATASOURCE_TYPES })
}

export const getDatasourceType = (params) => dispatch => {
	return dispatch({ type: ActionTypes.GET_DATASOURCE, payload: params })
}

export const saveDatasource = (data) => dispatch => {
	return dispatch({ type: ActionTypes.SAVE_DATASOURCE, payload: data })
}

export const updateDatasource = (data) => dispatch => {
	if (data.dsType === 'NOSQL' && data.noSQLPassword) {
		data.noSQLPassword = btoa(data.noSQLPassword);
	}
	return dispatch({ type: ActionTypes.UPDATE_DATASOURCE, payload: data })
}

export const deleteDatasource = (data) => dispatch => {
	return dispatch({ type: ActionTypes.DELETE_DATASOURCE, payload: data })
}

export const newDatasourceTypes = () => dispatch => {
	const data = {
		id: Utils.generateGUID(),
		name: '',
		handle: '',
		description: '',
		dsType: '',
		active: true
	}
	return dispatch({ type: ActionTypes.GET_DATASOURCE_TYPES_SUCCESS, payload: data })
}