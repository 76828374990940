import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { PipelinesService } from 'services/index'
import { ActionTypes } from 'constants/index'

async function fetchAsync(func, arg) {
	const response = arg ? await func(arg) : await func()
	return response
}

function* getPipelines(action) {
	try {
		const pipelines = yield call(fetchAsync, PipelinesService.getPipelines, action.payload)
		yield put({ type: ActionTypes.GET_PIPELINES_SUCCESS, payload: pipelines })
	} catch (e) {
		yield put({ type: ActionTypes.GET_PIPELINES_FAILURE, error: e.message })
	}
}

function* getPipeline(action) {
	try {
		const pipeline = yield call(fetchAsync, PipelinesService.getPipeline, action.payload)
		yield put({ type: ActionTypes.GET_PIPELINE_SUCCESS, payload: pipeline })
	} catch (e) {
		yield put({ type: ActionTypes.GET_PIPELINE_FAILURE, error: e.message })
	}
}

function* savePipeline(action) {
	try {
		const pipeline = yield call(fetchAsync, PipelinesService.savePipeline, action.payload)
		yield put({ type: ActionTypes.SAVE_PIPELINE_SUCCESS, payload: pipeline })
	} catch (e) {
		yield put({ type: ActionTypes.SAVE_PIPELINE_FAILURE, error: e.response })
	}
}

function* updatePipeline(action) {
	try {
		const pipeline = yield call(fetchAsync, PipelinesService.updatePipeline, action.payload)
		yield put({ type: ActionTypes.UPDATE_PIPELINE_SUCCESS, payload: pipeline })
	} catch (e) {
		yield put({ type: ActionTypes.UPDATE_PIPELINE_FAILURE, error: e.response })
	}
}

function* deletePipeline(action) {
	try {
		const pipeline = yield call(fetchAsync, PipelinesService.deletePipeline, action.payload)
		yield put({ type: ActionTypes.DELETE_PIPELINE_SUCCESS, payload: pipeline })
	} catch (e) {
		yield put({ type: ActionTypes.DELETE_PIPELINE_FAILURE, error: e.message })
	}
}

export function* watchPipelinesSaga() {
	yield takeEvery(ActionTypes.GET_PIPELINES, getPipelines);
	yield takeEvery(ActionTypes.GET_PIPELINE, getPipeline)
	yield takeEvery(ActionTypes.SAVE_PIPELINE, savePipeline)
	yield takeEvery(ActionTypes.UPDATE_PIPELINE, updatePipeline)
	yield takeEvery(ActionTypes.DELETE_PIPELINE, deletePipeline)
}

export default watchPipelinesSaga;