import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button, TextField, FormControl, FormLabel } from '@material-ui/core'
import Upload from 'components/@upload/Upload'

const bigqueryView = (props) => {
	let { form, classes, testDatasource, handleChange, handleFileChange } = props
	return (<div maxWidth="sm">
		<br />
		<FormLabel component="legend">{"BigQuery Key File"}</FormLabel>
		<br />
		<Upload handleFileChange={handleFileChange} />
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Database Name"}</FormLabel>
			<TextField
				id="bigQueryDatabase"
				name="bigQueryDatabase"
				placeholder="Database Name"
				className={classes.textField}
				value={form.bigQueryDatabase}
				onChange={handleChange}
				margin="normal"
				variant="outlined"
			/>
		</FormControl>
		<br />
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Service Account E-Mail"}</FormLabel>
			<TextField
				id="bqServiceAccount"
				name="bqServiceAccount"
				placeholder="Service Account E-Mail"
				className={classes.textField}
				value={form.bqServiceAccount}
				onChange={handleChange}
				margin="normal"
				variant="outlined"
			/>
		</FormControl>
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Password for key file"}</FormLabel>
			<TextField
				id="bqServiceAccountPassword"
				name="bqServiceAccountPassword"
				placeholder="Password for key file"
				className={classes.textField}
				value={form.bqServiceAccountPassword}
				onChange={handleChange}
				type="password"
				margin="normal"
				variant="outlined"
			/>
		</FormControl>
		<Button
			className="whitespace-no-wrap"
			variant="contained"
			onClick={() => testDatasource(form, props.match.params.dappId)}>
			{"Test BQ Connection"}
		</Button>
		<br />
	</div>)
}

export default withRouter(bigqueryView)