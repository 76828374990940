import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import React from 'react';

const Workflow = props => {
  const [personName, setPersonName] = React.useState([]);
  const names = ['Form Section: Approver', 'Form Section: Interset'];

  const handleChange = event => {
    setPersonName(event.target.value);
  };

  function getStyles(name, personName) {
    return {
      fontWeight: personName.indexOf(name) === -1 ? 'regular' : 'bold',
    };
  }
  return (
    <>
      {props.value === props.index && (
        <Grid container className="formPmodel">
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Select workflow to start</label>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              <NativeSelect
                fullWidth
                defaultValue="0"
                // value={selectedStep.bussinessrule}
                // onChange={e => this.onChangeValue('bussinessrule', e.target.value)}
              >
                <option value="0" disabled>
                  Workflow: HELOC Loan processing
                </option>

                <option value=""></option>
                <option value=""></option>
                <option value=""></option>
                <option value=""></option>
              </NativeSelect>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Wait for selected workflow to complete</label>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Select form sections/elements to pass to the selected worflow</label>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  // placeholder="select from following"
                  value={personName}
                  onChange={handleChange}
                  input={<Input id="select-multiple-chip" />}
                  displayEmpty
                  renderValue={selected => (
                    <div
                      style={{
                        display: 'flex',
                        paddingLeft: 5,
                        flexWrap: 'wrap',
                      }}
                    >
                      {selected.map(value => (
                        <li style={{ listStyle: 'none', marginRight: 5, marginBottom: 5 }}>
                          <Chip key={value} label={value} onDelete={() => {}} />
                        </li>
                      ))}
                    </div>
                  )}
                  // MenuProps={MenuProps}
                >
                  <MenuItem value="" disabled>
                    Placeholder
                  </MenuItem>
                  {names.map(name => (
                    <MenuItem key={name} value={name} style={getStyles(name, personName)}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Workflow;
