import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import photoone from '../../images/photoone.svg';
import microsoftsqlserver from '../../images/microsoftsqlserver.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    control: {
        padding: theme.spacing(2),
    },
}));


export default function SelectColumnFields(props) {
    const classes = useStyles();
    const [spacing, setSpacing] = React.useState(2);
    const [tasks, setTasks] = React.useState([
        {name:"orderOrder Number1",category:"wip"},
        {name:"Order Number2", category:"wip"},
        {name:"Order Number3", category:"wip"}
      ]);
    const onDragStart = (ev, id) => {
        ev.dataTransfer.setData("id", id);
    }

    const onDragOver = (ev) => {
        ev.preventDefault();
    }

    const onDrop = (ev, cat) => {
        let id = ev.dataTransfer.getData("id");
        let tasks3 = tasks.filter((task) => {
            if (task.name == id) {
                task.category = cat;
            }
            return task;
        });
        setTasks(tasks3);
     }
     const tasks2 = {
        wip: [],
        complete: []
    }
    tasks.forEach ((t) => {
        tasks2[t.category].push(
            <Button key={t.name}
                variant="contained"
                className="orderbtn"
                onDragStart = {(e) => onDragStart(e, t.name)}
                draggable
                className="draggable orderbtn"
                >
                {t.name}
            </Button>
        );
    });

    return (
        <Box className="pxy20">
            <Grid container>
                <Box className="processtab-sec">
                    <Grid container>
                        <Grid item xs={12} className="savebtn-sec">
                            <Button variant="contained" color="primary" className="savebtn btnwidth" onClick={props.handleNext}>
                                SAVE
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="selecteddata-grid">
                        <Typography variant="h5" className="mb3 d-block font-bold">Select Data Source</Typography>
                        <Box className="selecteddatasource-head" component="div">
                            <img src={microsoftsqlserver} alt="sql server logo" className="serverlogo" />
                            <Typography component="div" className="data-block">
                                <Grid container className="mb2">
                                    <Grid item xs={12} md={2} >
                                        <Box component="label" className="connectionlabel">
                                            Connection Name:
						                </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3} >
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="email"
                                            name="email"
                                            className="drmstextfield"
                                            autoComplete="email"
                                            placeholder="DRMS Server Connection"
                                            autoFocus
                                        />
                                    </Grid>
                                </Grid>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid className="my5" container>
                        <Typography variant="h5" className="mb3 d-block font-bold">Selected Table</Typography>
                        <Grid item xs={12} className="selecteddata-grid">
                            <Box className="selecteddatasource-head" component="div">
                                <img src={photoone} alt="selected logo" className="serverlogo" />
                                <Typography component="div" className="data-block">
                                    <Box component="label" m={1}>
                                        Drag and drop columns from Order table to Order data model
				                </Box>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="my5">
                        <Box className="orderbox-container">
                            <Grid container spacing={2}>
                                <Grid item sm={6} spacing={spacing} 
                                >
                                <Typography component="div" className="orderbox wip"
                                onDragOver={(e)=> onDragOver(e)}
                                onDrop={(e)=> onDrop(e, "wip")}
                                >
                                    <Typography className="mb3" variant="h5">Order</Typography>
                                    {tasks2.wip}
                                </Typography>

                                </Grid>
                                <Grid item sm={6} spacing={spacing}>
                                    <Typography component="div" className="orderbox droppable"
                                    onDragOver={(e)=> onDragOver(e)}
                                    onDrop={(e)=> onDrop(e, "complete")}
                                    >
                                        <Typography className="mb3" variant="h5">Order Data Model</Typography>
                                        {tasks2.complete}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </Box>


    );
}
