import React from 'react'
import { Grid} from '@material-ui/core'
import GenerateRow from './GenerateRow'

const GenerateForm = (props) => {
    const Jsondata = props.rows;
    if (!Jsondata) {
        return
    }
    return Jsondata.map((row, outerIndex) => {
        const columns = row.columns
        return (
            <React.Fragment key={outerIndex}>
                <Grid
                    draggable="true"
                    onDragStart={(e) => {
                    }}
                    onDragOver={(e) => props.onDragOver(e, outerIndex)}
                    onDrop={(e) => { props.dropBetweenRows(e, outerIndex) }}
                    item xs={12} style={{ 'paddingBottom': '15px' }}></Grid>
                <Grid container style={{ border: "1px dotted", padding: '15px', marginTop: '5px' }}>
                    {/* {props.generateRow(columns, outerIndex)} */}
                    <GenerateRow columns={columns} outerIndex={outerIndex}
                    deleteElement={props.deleteElement}
                    innerFormUpdateMultiple={props.innerFormUpdateMultiple}
                    innerFormUpdate={props.innerFormUpdate}
                    editElement={props.editElement}
                    buttonAction={props.buttonAction}
                    setData={props.setData}
                    filledData={props.filledData}
                    rearrangeElements={props.rearrangeElements}
                    />
                </Grid>
            </React.Fragment>
        )
    })
}

export default GenerateForm