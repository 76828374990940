import React from 'react';
import { Button, Grid, FormLabel, Divider } from '@material-ui/core';

const CategoriesTable = (props) => {
	console.log("props", props, props.categories)
	console.log("props", Array.isArray(props.categories));
	if (props.categories
		&& Array.isArray(props.categories)) {
		return props.categories.map((cate, index) => {
			if (!cate.editMode)
				return (<React.Fragment key={index}>
					<Grid container key={index}>
						<Grid style={{ "padding": "10px" }} xs={2}>
							<FormLabel >{cate.selectedCategoryDataElement}</FormLabel>
						</Grid>
						<Grid style={{ "padding": "10px" }} xs={1}>
							<FormLabel >{cate.title}</FormLabel>
						</Grid>
						<Grid style={{ "padding": "10px" }} xs={1}>
							<FormLabel >{cate.metric}</FormLabel>
						</Grid>
						<Grid style={{ "padding": "10px" }} xs={2}>
							<FormLabel >{cate.selectedCategoryCondDataElement}</FormLabel>
						</Grid>
						<Grid style={{ "padding": "10px" }} xs={2}>
							<FormLabel >{cate.selectedCategoryOperator}</FormLabel>
						</Grid>
						<Grid style={{ "padding": "10px" }} xs={2}>
							<FormLabel >{cate.value}</FormLabel>
						</Grid>
						<Grid style={{ "padding": "10px" }} xs={2}>
							<Button variant="contained" color="secondary" onClick={() => {
								props.editCategory(cate, index);
							}} >Edit</Button>
							<Button variant="contained" color="secondary" onClick={() => {
								props.deleteCategory(cate, index);
							}} >-</Button>
						</Grid>
					</Grid>
					<Divider />
				</React.Fragment>
				)
			else return ''
		})
	} else return ''
}
export default CategoriesTable;