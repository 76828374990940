import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, notification }) => {
		console.log("notification", notification);
		return {
			user: user,
			navigation: navigation,
			notification: notification
		}
	}

	const mapDispatchToProps = {
		getNotification: Actions.getNotification,
		saveNotification: Actions.saveNotification,
		newNotification: Actions.newNotification,
		updateNotification: Actions.updateNotification,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux