import React from 'react';
import {
	FormControl, AppBar, Tabs, Tab, Typography, Card, CardContent,
} from '@material-ui/core';
import InnerForm from '../InnerForm'

const TabElement = (props) => {
	let selectedvalue = props.filledData[item.name];
	return (<>
		<FormControl style={{
			"paddingBottom": "10px",
			"paddingTop": "10px", visibility: visibility,
			...JSON.parse(item.style ? item.style : "{}")
		}} className="native-select-dropdown">
			<AppBar position="static" className="formelements-appbar">
				<Tabs
					className="formElements-tab"
					value={selectedvalue}
					indicatorColor="primary"
					textColor="primary"
					variant="fullWidth"
					onChange={(e, val) => { props.onChange(item.name, val, item.type) }}>
					{item.data && item.data.values && item.data.values.map((el) => (<Tab label={el.label}
						disabled={item.disabled} value={el.value} />
					))}
				</Tabs>
			</AppBar>
			{item.innerForms && item.innerForms.map((item, index) => (<Typography
				component="div"
				role="tabpanel"
				hidden={selectedvalue != item.value}>
				{selectedvalue == item.value &&
					<Card className={classNames(classes.card)} >
						<CardContent>
							<InnerForm design={item.innerForm}
								viewOnly={props.viewOnly}
								setData={(name, val, item) => {
									props.setData(name, val, item)
								}}
								innerFormUpdate={(jsonCopy, modalData) => {
									props.innerFormUpdateMultiple(props.itemPosition, jsonCopy, modalData, index);
								}}
								modalData={item.innerFormModalData}
								filledData={props.filledData}
							/>
						</CardContent>
					</Card>
				}
			</Typography>))}
		</FormControl>
	</>)
}

export default TabElement