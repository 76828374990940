import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Bubble } from 'react-chartjs-2';
import 'styles/page-designer.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'

const styles = theme => ({});

class PageDesignerBubbleChart extends Component {
    render() {
        let { data, width, height, error } = this.props.element.chartData;
        if (error) {
                return(<div className="errorDisplayCenter">{error}</div>)
        } else {
            return(<div
                    id={`BubbleChartContainer${this.props.element.chartUniqueId}`}
                    style={{ height: "inherit", width: "inherit" }}
                >
                    <Bubble
                        id={`BubbleChartElement${this.props.element.chartUniqueId}`}
                        className="text"
                        width={"inherit"}
                        height={"inherit"}
                        data={data}
                        options={{
                            maintainAspectRatio: false
                        }}
                    />
                </div>)
        }
    }
}

export default withStyles(styles, { withTheme: true })(
    withRouter(
        connect(null, null)(PageDesignerBubbleChart)
    )
);
