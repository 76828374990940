import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './View'
import Redux from './Redux'
import { withRouter } from 'react-router-dom';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
// import { stat } from 'fs-extra';

const Container = (props) => {

	const [state, setState] = useState({
		form: {},
		isSubmitButton: true,
		editorState: EditorState.createEmpty()
	});

	const handleChange = (event) => setState({
		...state,
		form: _.set({ ...state.form }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value)
	})

	const updateNotificationState = () => {
		const params = props.match.params;
		const { notificationId, dappId } = params;

		if (notificationId === 'new') {
			props.newNotification();
		}
		else {
			props.getNotification(props.match.params);
		}
	};

	const updateFormState = () => {
		if (props.notification.data && props.notification.data.emailBody) {
			const DBEditorState = convertFromRaw(JSON.parse(props.notification.data.emailBody))
			setState({
				...state,
				form: props.notification.data, editorState: EditorState.createWithContent(
					DBEditorState
				)
			});
		} else {
			setState({
				...state,
				form: props.notification.data, editorState: EditorState.createEmpty()
			})
		}
	};

	const handleChipChange = (event, name) => {
		setState({ ...state, form: _.set({ ...state.form }, name, event.target.value) });
	};

	const canBeSubmitted = () => {
		const { form } = state;
		return (form && form.name && form.name.length > 0 &&
			!_.isEqual(props.notification.data, state.form) && state.isSubmitButton
		);
	}

	useEffect(() => {
		if (!_.isEqual(props?.notification?.data, state.form)) {
			setState({ ...state, isSubmitButton: true })
		}
	}, [state.form])

	useEffect(() => {
		updateNotificationState();
	}, [props.location])

	useEffect(() => {
		updateFormState();
	}, [props.notification.data])

	useEffect(() => {
		if (props.notification.saveSuccess) {
			props.showAlert('Notification saved successfully', { id: "notification", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/notifications/${props.notification.data._id}` });
		}
		if (props.notification.updateSuccess) {
			props.showAlert('Notification updated successfully', { id: "notification", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/notifications/${props.notification.data._id}` });
		}
	}, [props.notification.saveSuccess, props.notification.updateSuccess])

	const saveNotificationData = () => {
		setState({ ...state, isSubmitButton: false });
		props.saveNotification({ ...state.form, appId: props.match.params.dappId });
	}

	const updateNotificationData = () => {
		setState({ ...state, isSubmitButton: false });
		props.updateNotification({ ...state.form, appId: props.match.params.dappId });
	}

	const onEditorStateChange = (editor) => {
		setState({ ...state, form: _.set({ ...state.form }, 'emailBody', JSON.stringify(convertToRaw(editor.getCurrentContent()))), editorState: editor });
	};

	return (
		<View
			{...props}
			state={state}
			handleChange={handleChange}
			handleChipChange={handleChipChange}
			canBeSubmitted={canBeSubmitted}
			saveNotification={() => saveNotificationData()}
			updateNotification={() => updateNotificationData()}
			onEditorStateChange={onEditorStateChange}
		/>
	)
}

export default hot(withRouter(Redux(Container)))