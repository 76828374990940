import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, entities, entity }) => {
		return {
			user,
			navigation,
			entities: entities,
			searchText: entities.searchText,
			entity: entity
		}
	}

	const mapDispatchToProps = {
		getEntities: Actions.getEntities,
		deletEntity: Actions.deletEntity,
		getEntity: Actions.getEntity,
		updateEntity: Actions.updateEntity,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux