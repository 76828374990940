export default {
  SAVE_FLOWCHART: undefined,
  SAVE_FLOWCHART_SUCCESS: undefined,
  SAVE_FLOWCHART_FAILURE: undefined,

  GET_FLOWCHART: undefined,
  GET_FLOWCHART_SUCCESS: undefined,
  GET_FLOWCHART_FAILURE: undefined,

 
}
