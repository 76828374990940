import React, { Component } from 'react';
import {
	TextField, FormControl, Select, FormControlLabel, Divider,
	MenuItem, Checkbox, Radio, Typography, RadioGroup, FormLabel
} from '@material-ui/core';
import 'styles/page-designer.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as Actions from 'actions';
import _ from 'modules/lodash';
import LinkAdd from "./Link";
import LinksTable from "./LinksTable";
import ChipSelect from "components/@custom/ChipSelect"

class Links extends Component {
	render() {
		let props = this.props;
		const { dataModels, classes, values, forms } = this.props;
		return (
			<>
				{!props.checkForNullOrUndefined(dataModels)
					&& values.selectedDataModel
					&& values.dataHandler === "data_models"
					&& (this.props.chartType === Actions.listChart
						|| this.props.chartType === Actions.simpleTable) && (<>
							<LinkAdd
								values={values}
								classes={classes}
								onLinkChangeHandler={props.onLinkChangeHandler}
								addLink={props.addLink}
								checkForNullOrUndefined={props.checkForNullOrUndefined}
							/>
							<FormLabel component="legend">
								Links Table
                        </FormLabel>
							<LinksTable
								links={values.links}
								editLink={props.editLink}
								deleteLink={props.deleteLink}
								pages={props.pages}
							/>
						</>)
				}
				{!props.checkForNullOrUndefined(dataModels)
					&& values.selectedDataModel
					&& values.dataHandler === "data_models"
					&& (this.props.chartType === Actions.card) && (<>
						<RadioGroup
							aria-label="Card Linking"
							name="cardLinking"
							className={classes.group}
							label="Card Linking"
							value={values.cardLinking}
							onChange={props.onChangeHandler()}
							row
						>
							<FormControlLabel
								value="direct_link"
								control={<Radio color="primary" />}
								label="Direct Link"
								labelPlacement="end" />
							<FormControlLabel
								value="custom_link"
								control={<Radio color="primary" />}
								label="Custom Link"
								labelPlacement="end" />
						</RadioGroup>
						{values.cardLinking === 'direct_link' &&
							<TextField
								className={classes.formControl + " mt-8 mb-16"}
								id="linkURL"
								label="link URL"
								type="text"
								name="linkURL"
								value={props.values.linkURL}
								onChange={props.onChangeHandler()}
								multiline
								variant="outlined"
								fullWidth
							/>
						}
						{values.cardLinking === 'custom_link' && <>
							<FormControl variant="outlined" className={classes.formControl}>
								<Select className="pageModelLink"
									value={values.linkCard.selectedLinkType}
									onChange={props.onCardLinkChangeHandler}
									inputProps={{
										name: 'selectedLinkType',
										id: 'selectedLinkType',
									}}>
									<MenuItem value="">
										<em>Select Link Type</em>
									</MenuItem>
									<MenuItem key={"page"} value={"page"}>
										<em>{"Page"}</em>
									</MenuItem>
									<MenuItem key={"form"} value={"form"}>
										<em>{"Form"}</em>
									</MenuItem>
								</Select>
							</FormControl>
							<FormControl variant="outlined" className={classes.formControl}>
								<Select className="pageModelLink"
									value={values.linkCard.selectedLinkValue}
									onChange={props.onCardLinkChangeHandler}
									inputProps={{
										name: 'selectedLinkValue',
										id: 'selectedLinkValue',
									}}>
									{values.linkCard.selectedLinkType === "page" && <MenuItem value="">
										<em>Select a Page</em>
									</MenuItem>}
									{values.linkCard.selectedLinkType === "form" && <MenuItem value="">
										<em>Select a Link</em>
									</MenuItem>}
									{!values.linkCard.selectedLinkType && <MenuItem value="">
										<em>Please Select Link Type</em>
									</MenuItem>}
									{values.linkCard.selectedLinkType !== "" &&
										(values.linkCard.selectedLinkType === "page"
											? props.pages
											: (values.linkCard.selectedLinkType === "form"
												? props.forms
												: []
											)
										).map((ele, i) => {
											return <MenuItem key={i}
												value={values.linkCard.selectedLinkType === "page"
													? ele._id : ele.name
												}>
												{ele.name}
											</MenuItem>
										})
									}
								</Select>
							</FormControl>
						</>}
					</>)
				}
				{<>
					{!props.checkForNullOrUndefined(dataModels)
						&& props.values.selectedDataModel
						&& props.values.dataHandler === "data_models"
						&& this.props.chartType === Actions.pieChart
						&& (<>
							<Typography className="text-16 sm:text-20 truncate">
								Linking Pie chart with page or form page
                            </Typography>
							<br />
							<FormControl variant="outlined" className={classes.formControl}>
								<Select
									value={values.linkPie.selectedLinkType}
									onChange={props.onPieLinkChangeHandler}
									inputProps={{
										name: 'selectedLinkType',
										id: 'selectedLinkType',
									}}>
									<MenuItem value="">
										<em>Select Link Type</em>
									</MenuItem>
									<MenuItem key={"page"} value={"page"}>
										<em>{"Page"}</em>
									</MenuItem>
									<MenuItem key={"form"} value={"form"}>
										<em>{"Form"}</em>
									</MenuItem>
								</Select>
							</FormControl>
							<FormControl variant="outlined" className={classes.formControl}>
								<Select
									value={values.linkPie.selectedLinkValue}
									onChange={props.onPieLinkChangeHandler}
									inputProps={{
										name: 'selectedLinkValue',
										id: 'selectedLinkValue',
									}}>
									{values.linkPie.selectedLinkType === "page" && <MenuItem value="">
										<em>Select a Page</em>
									</MenuItem>}
									{values.linkPie.selectedLinkType === "form" && <MenuItem value="">
										<em>Select a Link</em>
									</MenuItem>}
									{!values.linkPie.selectedLinkType && <MenuItem value="">
										<em>Please Select Link Type</em>
									</MenuItem>}
									{values.linkPie.selectedLinkType !== "" &&
										(values.linkPie.selectedLinkType === "page"
											? props.pages
											: (values.linkPie.selectedLinkType === "form"
												? props.forms
												: []
											)
										).map((ele, i) => {
											return <MenuItem key={i}
												value={values.linkPie.selectedLinkType === "page"
													? ele._id : ele.name
												}>
												{ele.name}
											</MenuItem>
										})
									}
								</Select>
							</FormControl>
							{/* <FormControl variant="outlined" className={classes.formControl}>
								<Select
									value={values.linkPie.selectedLinkDataModel}
									onChange={props.onPieLinkChangeHandler}
									inputProps={{
										name: 'selectedLinkDataModel',
										id: 'selectedLinkDataModel',
									}}
									helperText="Pie label element considered as elected link key">
									{<MenuItem value="">
										<em>Select a Datamodel</em>
									</MenuItem>}
									{dataModels !== null && dataModels.map(dataModel => (
										<MenuItem key={dataModel._id} value={dataModel._id}>
											{dataModel.name}
										</MenuItem>
									))}
								</Select>
							</FormControl> */}
						</>)}
					{!props.checkForNullOrUndefined(dataModels)
						&& props.values.selectedDataModel
						&& props.values.dataHandler === "data_models"
						&& props.values.selectedDataElement
						&& (this.props.chartType === Actions.listChart
							|| this.props.chartType === Actions.simpleTable)
						&& (<>
							<br />
							<Divider />
							<br />
							<br />
							{/* TO DO - Need to update requirement */}
							<span style={{ "color": "red" }}>{"Old Format"}</span>
							<br />
							<Typography className="text-16 sm:text-20 truncate">
								Linking with details page or form page
                            </Typography>
							<br />
							<FormLabel component="legend">Page Linking</FormLabel>
							<RadioGroup
								name="pageLinking"
								label="Page Linking"
								value={props.values.pageLinking}
								onChange={props.onChangeHandler()}
								row
							>
								<FormControlLabel
									value="details_page"
									control={<Radio color="primary" />}
									label="Details Page"
									labelPlacement="end" />
								<FormControlLabel
									value="form_page"
									control={<Radio color="primary" />}
									label="Form Page"
									labelPlacement="end" />
							</RadioGroup>
						</>)}
					{(!props.checkForNullOrUndefined(dataModels)
						&& props.values.selectedDataModel
						&& props.values.dataHandler === "data_models"
						&& props.values.selectedDataElement
						&& this.props.pages && props.values.pages
						&& (this.props.chartType === Actions.listChart
							|| this.props.chartType === Actions.simpleTable)) && (
							<div>
								{props.values.pageLinking === "details_page" &&
									<>
										<Typography className="text-16 sm:text-20 truncate">
											Select columns with pagination
                                        </Typography>
										<ChipSelect
											className="w-full my-16"
											value={props.values.selectedDataColumns}
											onChange={props.handleDataColumnsChange}
											placeholder="Select multiple data columns"
											textFieldProps={{
												label: 'Data Columns',
												InputLabelProps: {
													shrink: true
												},
												variant: 'standard'
											}}
											options={props.values.selectedDataElement}
											isMulti
										/>
										<Typography className="text-16 sm:text-20 truncate">
											Select pages for respective columns
                                        </Typography>
										<ChipSelect
											className="w-full my-16"
											value={props.values.selectedDataPages}
											onChange={props.handleDataPagesChange}
											placeholder="Select multiple data pages"
											textFieldProps={{
												label: 'Data Pages',
												InputLabelProps: {
													shrink: true
												},
												variant: 'standard'
											}}
											options={props.values.pages}
											isMulti
										/>
									</>}
								{this.props.chartType === Actions.simpleTable && (
									<>
										<Typography className="text-16 sm:text-20 truncate">
											Extended table page
                                        </Typography>
										<ChipSelect
											className="w-full my-16"
											value={props.values.extendedTablePage}
											onChange={props.handleExtendedTablePageChange}
											placeholder="Select single page with complex table"
											textFieldProps={{
												label: 'Extended Table Page',
												InputLabelProps: {
													shrink: true
												},
												variant: 'standard'
											}}
											options={props.values.pages}
										/>
									</>
								)}
							</div>
						)}
					{(this.props.chartType === Actions.listChart
						|| this.props.chartType === Actions.simpleTable)
						&& props.values.pageLinking === "form_page"
						&& (
							<>
								<Typography className="text-16 sm:text-20 truncate">
									Select associated Form Name
                        </Typography>
								<FormControl fullWidth className="mt-8 mb-16">
									<Select
										value={props.values.associatedFormName}
										onChange={props.onChangeHandler()}
										inputProps={{
											name: 'associatedFormName',
											id: 'associatedFormName',
										}}>
										<MenuItem value="">
											<em>None</em>
										</MenuItem>
										{forms !== null && forms.map(entryForm => (
											<MenuItem key={entryForm._id} value={entryForm.name}>
												{entryForm.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</>
						)}
				</>
				}
			</>
		)
	}
}

function mapStateToProps({ pages, dataModels, forms }) {
	return {
		chartType: pages.page.chartType,
		dataModels: dataModels.data,
		pages: pages.data,
		forms: forms.data,
	};
}

export default connect(
	mapStateToProps,
	null
)(Links);