export const rows = [
	{
		id: 'name',
		numeric: false,
		disablePadding: false,
		label: 'Name',
		sort: true
	},
	{
		id: 'description',
		numeric: false,
		disablePadding: false,
		label: 'Description',
		sort: true
	},
	{
		id: 'datamodelType',
		numeric: false,
		disablePadding: false,
		label: 'Type',
		sort: true
	},
	{
		id: 'active',
		numeric: true,
		disablePadding: false,
		label: 'Active',
		sort: true
	}
];

export function isRowSelected(dataElement, selected) {
	let flag = false;
	selected.map(ele => {
		if (ele.name === dataElement.name) {
			flag = ele.name === dataElement.name;
		}
	});
	return flag;
}

export function prepareDataElements(dataModel, dataElement, currentDatamdelId) {
	let index = -1, dEles = Array.isArray(dataElement) ? [...dataElement] : [];
	dataModel.dataElements.map((de, i) => {
		let ind = -1;
		if (Array.isArray(dataElement)) {
			dataElement.map((d, j) => {
				console.log("d, in", d._id, de._id, j);
				if (de._id === d._id)
					ind = j
			});
			console.log("ind", ind);
			if (ind !== -1)
				dEles.splice(ind, 1);
		} else {
			if (de.name === dataElement.name || de._id === dataElement._id)
				index = i;
		}
	})
	console.log("dEles", dEles);
	dEles = dEles.map(de => ({ ...de, dataModelId: currentDatamdelId }))

	if (Array.isArray(dataElement)) {
		dataModel.dataElements = [...dataModel.dataElements, ...dEles];
	} else {
		if (index === -1) {
			dataModel.dataElements.push(dataElement);
		} else {
			dataModel.dataElements[index] = dataElement;
		}
	}
	return dataModel;
}
