import { ActionTypes } from 'constants/index'

const initialState = {
    data: [],
    flowcharts: [],
    searchText: "",
    error: "",
    loading: false,
    workflow: {},
    workflowById: null,
    workflowSaveSuccess: false
}

const workflowsReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_WORKFLOWS: {
            return {
                ...state,
                loading: true,
                workflowSaveSuccess: false
            }
        }
        case ActionTypes.GET_WORKFLOW_SUCCESS: {
            return {
                ...state,
                loading: false,
                workflowById: action.payload,
                workflowSaveSuccess: false
            }
        }
        case ActionTypes.CLEAR_WORKFLOW: {
            return {
                ...state,
                loading: false,
                workflowById: null,
            }
        }
        case ActionTypes.GET_WORKFLOWS_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                workflowSaveSuccess: false
            }
        }
            case ActionTypes.GET_FLOWCHARTS_SUCCESS: {
            return {
                ...state,
                flowcharts: action.payload,
                workflowSaveSuccess: false
            }
        }
        case ActionTypes.GET_WORKFLOWS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.SAVE_WORKFLOW_SUCCESS: {
            return {
                ...state,
                loading: false,
                workflow: action.payload,
                workflowSaveSuccess: true
            };
        }
            
        case ActionTypes.SAVE_FLOWCHARTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                flowchart: action.payload,
                workflowSaveSuccess: true
            };
        }
            
        case ActionTypes.UPDATE_WORKFLOW_SUCCESS: {
            return {
                ...state,
                loading: false,
                workflowSaveSuccess: true
            };
        }
        case ActionTypes.DELETE_WORKFLOW_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case ActionTypes.DELETE_WORKFLOW_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        default: {
            return state;
        }
    }
};

export default workflowsReducer;
