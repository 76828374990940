import React from 'react';

const WorkflowTask = props => {
  return (
    <svg
      id="workflow"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 41 41"
      x={props.x}
      y={props.y}
      onClick={props.onClick}
    >
      <rect
        id="Rectangle_596"
        data-name="Rectangle 596"
        width="41"
        height="41"
        fill="#fff"
        opacity="0"
      />
      <g id="Group_349" data-name="Group 349" transform="translate(-47 -833.9)">
        <path
          id="Path_485"
          data-name="Path 485"
          d="M161.283,0h-9.492A.791.791,0,0,0,151,.791v5.8a.791.791,0,0,0,.791.791h9.492a.791.791,0,0,0,.791-.791V.791A.791.791,0,0,0,161.283,0Zm0,0"
          transform="translate(-89.037 840.9)"
          fill="#000"
        />
        <path
          id="Path_486"
          data-name="Path 486"
          d="M161.283,186h-9.492a.791.791,0,0,0-.791.791v5.8a.791.791,0,0,0,.791.791h9.492a.791.791,0,0,0,.791-.791v-5.8A.791.791,0,0,0,161.283,186Zm0,0"
          transform="translate(-89.037 664.709)"
          fill="#000"
        />
        <path
          id="Path_487"
          data-name="Path 487"
          d="M161.283,372h-9.492a.791.791,0,0,0-.791.791v5.8a.791.791,0,0,0,.791.791h9.492a.791.791,0,0,0,.791-.791v-5.8A.791.791,0,0,0,161.283,372Zm0,0"
          transform="translate(-89.037 488.517)"
          fill="#000"
        />
        <path
          id="Path_488"
          data-name="Path 488"
          d="M385.955,78h-3.164a.791.791,0,1,0,0,1.582h3.164a1.32,1.32,0,0,1,1.318,1.318v3.164a1.32,1.32,0,0,1-1.318,1.318H384.7l.759-.759a.791.791,0,0,0-1.119-1.119l-2.109,2.109a.791.791,0,0,0,0,1.119l2.109,2.109a.791.791,0,0,0,1.119-1.119l-.759-.759h1.254a2.9,2.9,0,0,0,2.9-2.9V80.9A2.9,2.9,0,0,0,385.955,78Zm0,0"
          transform="translate(-307.855 767.013)"
          fill="#000"
        />
        <path
          id="Path_489"
          data-name="Path 489"
          d="M4.514,269.506A.791.791,0,0,0,3.4,270.624l.759.759H2.9a1.32,1.32,0,0,1-1.318-1.318V266.9A1.32,1.32,0,0,1,2.9,265.582H6.064a.791.791,0,0,0,0-1.582H2.9A2.9,2.9,0,0,0,0,266.9v3.164a2.9,2.9,0,0,0,2.9,2.9H4.155l-.759.759a.791.791,0,1,0,1.119,1.119l2.109-2.109a.791.791,0,0,0,0-1.119Zm0,0"
          transform="translate(54 590.821)"
          fill="#000"
        />
      </g>
    </svg>
  );
};

export default WorkflowTask;
