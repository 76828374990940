import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, roles }) => {
		return {
			user: user,
			navigation: navigation,
			roles: roles
		}
	}

	const mapDispatchToProps = {
		getRoles: Actions.getRoles,
		deleteRole: Actions.deleteRole,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux