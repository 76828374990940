import React, { Component } from 'react';
import {
	TextField, FormControl, Select, FormControlLabel, Divider, FormGroup,
	MenuItem, Checkbox, Radio, Typography, RadioGroup, FormLabel, InputLabel
} from '@material-ui/core';
import 'styles/page-designer.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import _ from 'modules/lodash';
import Conditions from "./Conditions";
import ConditionsTable from "./ConditionsTable";
import Categories from "./Categories";
import CategoriesTable from "./CategoriesTable";

class MixedChart extends Component {
	render() {
		let props = this.props;
		const { dataModels, classes, values, onConditionChangeHandler, businessRules } = this.props;

		return (
			<>
				<br />
				<Divider />
				<br />
				<FormControl component="fieldset">
					<FormLabel component="legend">Properties Specific to Mixed chart</FormLabel>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={values.includeMixedBar}
									onChange={props.handleCheckboxChange('includeMixedBar')}
									value="includeMixedBar"
									color="primary"
									disabled
									checked
								/>
							}
							label="Bar chart"
						/>
						<FormGroup row>
							<FormControlLabel
								control={
									<Checkbox
										checked={values.includeMixedLine}
										onChange={props.handleCheckboxChange('includeMixedLine')}
										value="includeMixedLine"
										color="primary"
									/>
								}
								label="Line chart"
							/>
							{values.includeMixedLine &&
								<div>
									<FormControl fullWidth className="mt-8 mb-16">
										<InputLabel id="line-metric">Line Metric</InputLabel>
										<Select
											labelid="line-metric"
											value={values.mixedLineMetric}
											onChange={props.onChangeHandler()}
											inputProps={{
												name: 'mixedLineMetric',
												id: 'mixedLineMetric',
											}}
											style={{
												minWidth: 150
											}}>
											<MenuItem value={""}>
												{"Please select a line metric"}
											</MenuItem>
											<MenuItem value={"count"}>
												{"Count"}
											</MenuItem>
											<MenuItem value={"total"}>
												{"Total"}
											</MenuItem>
											<MenuItem value={"average"}>
												{"Average"}
											</MenuItem>
											<MenuItem value={"max"}>
												{"Max"}
											</MenuItem>
											<MenuItem value={"min"}>
												{"Min"}
											</MenuItem>
											<MenuItem value={"percentage"}>
												{"Percentage"}
											</MenuItem>
										</Select>
									</FormControl>
								</div>
							}
						</FormGroup>
						<FormGroup row>
							<FormControlLabel
								control={
									<Checkbox
										checked={values.includeMixedPie}
										onChange={props.handleCheckboxChange('includeMixedPie')}
										value="includeMixedPie"
										color="primary"
									/>
								}
								label="Pie chart"
							/>
							{values.includeMixedPie &&
								<div>
									<FormControl fullWidth className="mt-8 mb-16">
										<InputLabel id="pie-metric">Pie Metric</InputLabel>
										<Select
											labelid="pie-metric"
											value={values.mixedPieMetric}
											onChange={props.onChangeHandler()}
											inputProps={{
												name: 'mixedPieMetric',
												id: 'mixedPieMetric',
											}}
											style={{
												minWidth: 150
											}}>
											<MenuItem value={""}>
												{"Please select a pie metric"}
											</MenuItem>
											<MenuItem value={"count"}>
												{"Count"}
											</MenuItem>
											<MenuItem value={"total"}>
												{"Total"}
											</MenuItem>
											<MenuItem value={"average"}>
												{"Average"}
											</MenuItem>
											<MenuItem value={"max"}>
												{"Max"}
											</MenuItem>
											<MenuItem value={"min"}>
												{"Min"}
											</MenuItem>
											<MenuItem value={"percentage"}>
												{"Percentage"}
											</MenuItem>
										</Select>
									</FormControl>
								</div>
							}
						</FormGroup>
					</FormGroup>
				</FormControl>
			</>
		)
	}
}

function mapStateToProps({ pages, dataModels, businessRules }) {
	return {
		chartType: pages.page.chartType,
		dataModels: dataModels.data,
		// businessRules: businessRules.data,
	};
}

export default connect(
	mapStateToProps,
	null
)(MixedChart);