import React, { useEffect } from 'react'
import {
	Divider, List, ListItem, ListItemIcon, ListItemText, Typography, Box
} from '@material-ui/core'
import { Link } from "react-router-dom"
import { Mail, MoveToInbox } from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const drawerWidth = 240

export default function NavBox(props) {
	let { nav } = props
	const preventDefault = (event) => event.preventDefault()
	return (<>
		<Typography component="div">
			<Link to={nav.url} key={nav.title}>
				<Box componnet="label" className="menulist-headings" m={2}> {nav.title}</Box>
			</Link>
		</Typography>
		<Divider />
		<List>
			{nav.children.map((item, index) => (
				<ListItem button component={Link}
					to={item.url} key={item.title}>
					<ListItemIcon>
						<img src={item.icon} />
						{/* {index % 2 === 0 ? <MoveToInbox /> : <Mail />} */}
					</ListItemIcon>
					<ListItemText className="listfont-size" primary={item.title} />
				</ListItem>
			))}
		</List>
		<Divider />
	</>)
}