import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { JobsService } from 'services/index'
import { ActionTypes } from 'constants/index'


async function fetchAsync(func, arg) {
	const response = arg ? await func(arg) : await func()
	return response
}

function* getJobs(action) {
	try {
		const jobs = yield call(fetchAsync, JobsService.getJobs, action.payload)
		yield put({ type: ActionTypes.GET_JOBS_SUCCESS, payload: jobs })
	} catch (e) {
		yield put({ type: ActionTypes.GET_JOBS_FAILURE, error: e.message })
	}
}

function* getJob(action) {
	try {
		const job = yield call(fetchAsync, JobsService.getJob, action.payload)
		yield put({ type: ActionTypes.GET_JOB_SUCCESS, payload: job })
	} catch (e) {
		yield put({ type: ActionTypes.GET_JOB_FAILURE, error: e.message })
	}
}

function* saveJob(action) {
	try {
		const job = yield call(fetchAsync, JobsService.saveJob, action.payload)
		yield put({ type: ActionTypes.SAVE_JOB_SUCCESS, payload: job })
	} catch (e) {
		yield put({ type: ActionTypes.SAVE_JOB_FAILURE, error: e.message })
	}
}

function* updateJob(action) {
	try {
		const job = yield call(fetchAsync, JobsService.updateJob, action.payload)
		yield put({ type: ActionTypes.UPDATE_JOB_SUCCESS, payload: job })
	} catch (e) {
		yield put({ type: ActionTypes.UPDATE_JOB_FAILURE, error: e.message })
	}
}

function* deleteJob(action) {
	try {
		const job = yield call(fetchAsync, JobsService.deleteJob, action.payload)
		yield put({ type: ActionTypes.DELETE_JOB_SUCCESS, payload: job })
	} catch (e) {
		yield put({ type: ActionTypes.DELETE_JOB_FAILURE, error: e.response })
	}
}

export function* watchJobsSaga() {
	yield takeEvery(ActionTypes.GET_JOBS, getJobs)
	yield takeEvery(ActionTypes.GET_JOB, getJob)
	yield takeEvery(ActionTypes.SAVE_JOB, saveJob)
	yield takeEvery(ActionTypes.UPDATE_JOB, updateJob)
	yield takeEvery(ActionTypes.DELETE_JOB, deleteJob)
}

export default watchJobsSaga;