/**
 * @module Sagas/Navigation
 * @desc App
 */
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { ActionTypes } from 'constants/index'

/**
 * Application
 *
 * @param {Object} action
 *
 */

async function fetchAsync(func, arg) {
	const response = await func(arg)
	return response
}

function* getAppNavigation(action) {
	try {
		yield put({ type: ActionTypes.SET_PREV_PATH })
	} catch (e) {
		yield put({ type: ActionTypes.EMPTY_PREV_PATH, error: e.message })
	}
}

export function* watchNavigationSaga() {
	// yield takeEvery(ActionTypes.SET_PREV_PATH_LOADING, getAppNavigation)
}

export default watchNavigationSaga
