import { Button, Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import '../style/extension.css';
const Extension = props => {
  const [inputList, setInputList] = useState([{ name: '', value: '' }]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { name: '', value: '' }]);
  };

  return (
    <>
      {props.value === props.index && (
        <Grid container className="formPmodel">
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Enter Properties to use during this task</label>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Name</label>
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Value</label>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          {inputList.map((x, i) => {
            return (
              <Grid container row>
                <Grid item xs={4}>
                  <TextField
                    margin="dense"
                    variant="filled"
                    value="1"
                    fullWidth
                    name="name"
                    value={x.name}
                    onChange={e => handleInputChange(e, i)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    margin="dense"
                    variant="filled"
                    value="1"
                    fullWidth
                    name="value"
                    value={x.value}
                    onChange={e => handleInputChange(e, i)}
                  />
                </Grid>

                <Grid item xs={1} className="iconWrapper">
                  {inputList.length - 1 === i && <AddCircleOutlineIcon onClick={handleAddClick} />}
                </Grid>
                <Grid item xs={1} className="iconWrapper">
                  {inputList.length !== 1 && (
                    <RemoveCircleOutlineIcon onClick={() => handleRemoveClick(i)} />
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default Extension;
