import React from 'react';
import {
    TextField, FormControl, Select, Grid,
    MenuItem, FormGroup, FormLabel, Button
} from '@material-ui/core';
import 'styles/page-designer.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'modules/lodash';
import { withRouter } from 'react-router-dom';

const linkTypeOptions = [{ "value": "page", "name": "Page" }, { "value": "form", "name": "Form" }, , { "value": "external", "name": "External" }];

const StaticLinks = (props) => {
    let { classes, values, onStaticLinkChangeHandler } = props;
    const { staticLink } = values;

    return (<>
        <FormGroup row className="items-center">
            <FormLabel component="legend">
                {"Static Links"}
            </FormLabel>
        </FormGroup>

        <FormGroup row className="items-center  max-w-full">
            <Grid container>
                <Grid xs={3} style={{ "padding": "5px" }}>
                    <TextField
                        autoFocus
                        fullWidth
                        id="title"
                        name="title"
                        variant="outlined"
                        label="Enter Title"
                        value={staticLink.title}
                        onChange={onStaticLinkChangeHandler}
                    />
                </Grid>
                <Grid xs={2} style={{ "padding": "5px" }}>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <Select
                            value={staticLink.linkType}
                            onChange={onStaticLinkChangeHandler}
                            inputProps={{
                                name: 'linkType',
                                id: 'linkType',
                            }}>
                            <MenuItem value="">
                                <em>{"Select Link Type"}</em>
                            </MenuItem>
                            {
                                linkTypeOptions.map((element, i) => {
                                    return <MenuItem key={i} value={element.value}>{element.name}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={3} style={{ "padding": "5px" }}>
                    {
                        staticLink.linkType === "external" ?
                            <TextField
                                autoFocus
                                fullWidth
                                id="external_link"
                                variant="outlined"
                                name="external_link"
                                label="Enter Enternal Link"
                                value={staticLink.externalLink}
                                onChange={onStaticLinkChangeHandler}
                            />
                            :
                            <></>
                    }
                </Grid>
                <Grid xs={2} style={{ "padding": "5px 20px" }}>
                    <Button
                        fullWidth
                        color="secondary"
                        variant="outlined"
                        className={classes.formControl}
                        onClick={() => {
                            props.addStaticLink(staticLink);
                        }}
                    >
                        {"Add"}
                    </Button>
                </Grid>
            </Grid>
        </FormGroup>
    </>)
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps({ dataModels }) {
    return {
        dataModels: dataModels,
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(StaticLinks)
)