import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { withRouter } from 'react-router-dom'
import View from './view'
import Redux from './redux'
import { updateTableData, setDatafromApi } from 'packages/common'
import sharedJSON from 'packages/sharedJSON'
import ConfirmDialog from '../confirmDialog'

import {
	getFilteredArray,
	handleSelectedRow,
	isRowselected,
	requestSort,
	handleTableSelectAllClick,
	getTableRowStyles
} from 'modules/common';

const Container = (props) => {
	const [state, setState] = useState({
		...sharedJSON
	})
	const [open, setOpen] = React.useState(false);
	const [job, setJob] = React.useState({});

	const handleChangePage = (event, page) => setState({ ...state, page: page })

	const handleChangeRowsPerPage = event => setState({ ...state, rowsPerPage: event.target.value, page: 0 });

	const onRowClick = (rowData, rowMeta) => {
		console.log('onRowClick');
	}

	const searchHandler = (e) => setState({ ...state, searchText: e ? e.target.value : "" })

	const handleInputChange = (event) => {
		let obj = state.filterTableData;
		let list = obj.columns;
		list.map((item, index) => {
			if (item.name === event.target.value) list[index].checked = event.target.checked
		})
		obj.columns = [...list]
		setState({ ...state, filterTableData: obj });
		setState({ ...updateTableData(state) });
	}

	const resetFilters = (event) => setState({ ...state, filterList: [] })

	const handleDropdownChange = (event, index, column) => {
		const value = event.target.value === "All" ? [] : [event.target.value];
		// filterUpdate(index, value, column, 'dropdown');
		let list = state.filterList;
		list[index] = event.target.value;
		setState({ ...state, filterList: [...list] });
		setState({ ...updateTableData(state) });
	};

	const handleRequestSort = (event, property) => {
		let obj = requestSort(event, property, state);
		console.log("obj", obj);
		setState({ ...state, ...obj });
	};

	const handleSelectAllClick = event => setState({ ...state, selected: handleTableSelectAllClick(event, state.filterTableData.datasets) });

	const tableCrudOperations = (item, type) => {
		if (type === "edit") {
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/jobs/${item._id}` });
		}
		if (type === "delete") {
			setJob(item);
			setOpen(true);
		}
	}

	const handleAddClick = () => props.history.push({ pathname: `/builder/${props.match.params.dappId}/jobs/new` })

	const handleCheck = (event, id) => {
		const { selected } = state;
		let newSelected = handleSelectedRow(event, id, selected)
		setState({ ...state, selected: newSelected });
	};

	useEffect(() => {
		setState({ ...updateTableData(state) });
	}, [state.searchText, state.filterList]);

	useEffect(() => {
		if (props.jobs.deleteSuccess) {
			props.showAlert('Job deleted successfully', { id: "job", variant: 'success', icon: 'bell' });
		}
	}, [props.jobs.deleteSuccess])

	useEffect(() => {
		const params = props.match.params;
		const { dappId } = params;
		props.getJobs(dappId);
	}, []);

	useEffect(() => {
		const rows = [
			{
				id: 'name',
				numeric: false,
				disablePadding: false,
				label: 'Name',
				sort: true
			},
			{
				id: 'description',
				numeric: false,
				disablePadding: false,
				label: 'Description',
				sort: true
			},
			{
				id: 'frequency',
				numeric: false,
				disablePadding: false,
				label: 'Frequency',
				sort: true
			},
			{
				id: 'type',
				numeric: false,
				disablePadding: false,
				label: 'Type',
				sort: true
			},
			{
				id: 'repeatType',
				numeric: false,
				disablePadding: false,
				label: 'Repeat Type',
				sort: true
			}
		];
		let keysArr = ['_id', 'networkId', 'participants', 'businessRuleId', '__v', 'matchLevel', 'resultStore', 'rule'];
		setState({ ...setDatafromApi(props.jobs.data, keysArr, state, rows) });
	}, [props.jobs.data]);

	return (<>
		<View
			{...props}
			state={state}
			handleChangePage={handleChangePage}
			handleChangeRowsPerPage={handleChangeRowsPerPage}
			onRowClick={onRowClick}
			searchHandler={searchHandler}
			handleCheck={handleCheck}
			handleInputChange={handleInputChange}
			resetFilters={resetFilters}
			handleDropdownChange={handleDropdownChange}
			handleRequestSort={handleRequestSort}
			handleSelectAllClick={handleSelectAllClick}
			tableCrudOperations={tableCrudOperations}
			handleAddClick={handleAddClick}
		/>
		<ConfirmDialog
			title="Delete"
			open={open}
			setOpen={setOpen}
			onConfirm={() => props.deleteJob({ _id: job._id, appId: props.match.params.dappId })}
		>
			job {job.name}
		</ConfirmDialog>
	</>)
}

export default hot(withRouter(Redux(Container)))