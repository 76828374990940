import React from 'react'
import { Button, FormLabel, Grid } from '@material-ui/core'

const ConditionRequired = (props) => {
    let { conditions, variableName, removeConditionalRequired } = props
    return conditions.map((el, index) => {
        return (<React.Fragment key={index} >
            <Grid style={{ "padding": "10px" }}>
                <FormLabel >{el.element}</FormLabel>
            </Grid>
            <Grid style={{ "padding": "10px" }}>
                <FormLabel >{el.operator}</FormLabel>
            </Grid>
            <Grid style={{ "padding": "10px" }}>
                <FormLabel >{el.value}</FormLabel>
            </Grid>
            <Grid style={{ "padding": "10px" }}>
                <FormLabel >{el.clause}</FormLabel>
            </Grid>
            <Grid style={{ "padding": "10px" }}>
                <FormLabel >{el.element1}</FormLabel>
            </Grid>
            <Grid style={{ "padding": "10px" }}>
                <FormLabel >{el.operator1}</FormLabel>
            </Grid>
            <Grid style={{ "padding": "10px" }}>
                <FormLabel >{el.value1}</FormLabel>
            </Grid>
            <Grid style={{ "padding": "10px" }}>
                <Button variant="contained" color="secondary" onClick={() => {
                    removeConditionalRequired(el, variableName);
                }} >-</Button>
            </Grid>
        </React.Fragment>)
    })
}
export default ConditionRequired