import React from 'react'
import {
	TextField, FormControl, FormLabel, Select, MenuItem, Grid, FormControlLabel, RadioGroup, Radio, TextareaAutosize, Typography
} from '@material-ui/core'
import Conditions from './Conditions'
import ConditionsTable from './ConditionsTable'

const Logic = (props) => {
	let { values, onChangeHandler, classes, dataModels, businessRules } = props
	console.log("props =-=-=?", props);
	return (<>
		<div className="pro-basic">
			<Grid container className={classes.root} spacing={2}>
				<Grid item xs={12}>
					<FormControl>
						<FormLabel component="legend">{"Select Logic Type"}</FormLabel>
						<RadioGroup
							aria-label="Select Logic Type"
							name="logicType"
							className={classes.group}
							value={values.logicType}
							onChange={onChangeHandler}
							row>
							<FormControlLabel
								value="conditions"
								control={<Radio />}
								label="Conditions" />
							<FormControlLabel
								value="businessRule"
								control={<Radio />}
								label="Business Rules" />
							<FormControlLabel
								value="query"
								control={<Radio />}
								label="Query" />
						</RadioGroup>
					</FormControl>
				</Grid>
				{values.dataModelId
					&& values.logicType === "conditions" && (<>
						<Conditions
							values={values}
							classes={classes}
							onConditionChangeHandler={props.onConditionChangeHandler}
							addCondition={props.addCondition}
						/>
						<FormLabel component="legend">{"Conditions Table"}</FormLabel>
						<ConditionsTable
							conditions={values.conditions}
							editCondition={props.editCondition}
							deleteCondition={props.deleteCondition}
						/>
					</>)
				}
				{values.logicType === "businessRule" && (
					<Grid item xs={12}>
						<FormControl>
							<FormLabel variant="h6" className="label-headings">{"Select "}</FormLabel>
							<Select
								value={values.businessRuleId ? values.businessRuleId : ''}
								onChange={onChangeHandler}
								inputProps={{
									name: 'businessRuleId',
									id: 'businessRuleId',
								}}>
								<MenuItem value=""><em>{"None"}</em></MenuItem>
								{businessRules.map(br => <MenuItem value={br._id}><em>{br.name}</em></MenuItem>)}
							</Select>
						</FormControl>
					</Grid>
				)}
				{values.logicType === "query" && (<>
					{/* <Grid item xs={12}> */}
					<Grid item xs={5}>
						<FormLabel variant="h6" className="label-headings">{"Select Supported Query Languages"}</FormLabel>

						{/* <Typography variant="subtitle1" color="inherit">{"Select Supported Query Languages"}</Typography> */}
					</Grid>
					<Grid item xs={5}>
						<FormControl>
							<Select
								value={values.queryLanguage ? values.queryLanguage : ''}
								onChange={onChangeHandler}
								inputProps={{
									name: 'queryLanguage',
									id: 'queryLanguage',
								}}>
								<MenuItem value=""><em>{"None"}</em></MenuItem>
								<MenuItem value="mssql"><em>{"SQL (MS SQL Server)"}</em></MenuItem>
								<MenuItem value="oracle"><em>{"SQL (Oracle)"}</em></MenuItem>
								<MenuItem value="mysql"><em>{"SQL (MYSQL)"}</em></MenuItem>
								<MenuItem value="mongodb"><em>{"MongoDb"}</em></MenuItem>
							</Select>
						</FormControl>
						{/* </Grid> */}
					</Grid>
					<Grid item xs={12}>
						<FormControl>
							<TextareaAutosize
								className="form-control"
								id="inputQuery"
								name="description"
								value={values.inputQuery}
								onChange={onChangeHandler}
								variant="outlined"
								color="secondary"
								placeholder="Enter Query"
								rowsMin={10}
								fullWidth
							/>
						</FormControl>

					</Grid>
				</>)}
			</Grid>
		</div>
	</>)
}
export default Logic