/**
 * @module Actions/Application
 * @desc Application Actions
 */
import { ActionTypes } from 'constants/index';
import Utils from 'packages/page/utils';

export const getEntities = appId => dispatch => {
  dispatch({
    type: ActionTypes.GET_ENTITIES,
    payload: { appId },
  });
};

export const getEntity = params => dispatch => {
  dispatch({
    type: ActionTypes.GET_ENTITY,
    payload: params,
  });
};

export const saveEntity = (data, user, appId) => dispatch => {
  let entity = data;
  entity.orgId = user.orgId;
  entity.createdBy = user.id;
  entity.createdDateTime = new Date();
  entity.appId = appId;
  dispatch({
    type: ActionTypes.SAVE_ENTITY,
    payload: { entity, appId },
  });
};

export const updateEntity = (data, user, appId) => dispatch => {
  let entity = data;
  entity.orgId = user.orgId;
  entity.udpatedBy = user.id;
  entity.updateddDateTime = new Date();
  entity.appId = appId;
  dispatch({
    type: ActionTypes.UPDATE_ENTITY,
    payload: { entity, appId },
  });
};

export const setEntitiesSearchText = event => dispatch => {
  return {
    type: ActionTypes.SET_ENTITIES_SEARCH_TEXT,
    searchText: event.target.value,
  };
};

export const deletEntity = (entity, appId) => dispatch => {
  dispatch({
    type: ActionTypes.DELETE_ENTITY,
    payload: { entity, appId },
  });
};

export const newEntity = () => dispatch => {
  const data = {
    entityId: Utils.generateGUID(),
    name: '',
    handle: '',
    description: '',
    logoUrl: '',
    categories: '',
    tags: '',
    industry:'',
    primaryContactName: '',
    primaryContactEmail: '',
    primaryContactPhone: '',
    secContactName: '',
    secContactEmail: '',
    secContactPhone: '',
    natureofBusiness: '',
    services: '',
    active: true,
  };

  dispatch({
    type: ActionTypes.GET_ENTITY_SUCCESS,
    payload: data,
  });
};
