export function generateLineData(element, d) {
	let { data, metric, selectedDataElement } = element;
	d.xAxis.categories = data.labels;
	d.yAxis.title.text = metric
	if (selectedDataElement && selectedDataElement[0])
		d.yAxis.title.text += ` of ${selectedDataElement[0].value}`
	d.series = data.datasets.map((ds, i) => {
		return {
			name: ds.label,
			data: ds.data
		}
	})
	// let d = data.labels.map((l, i) => {
	// 	return {
	// 		name: l,
	// 		y: data.datasets[0].data[i],
	// 		// color: data.datasets[0].backgroundColor[i]
	// 	}
	// })
	// series[0].name = data.datasets[0].label;
	// series[0].data = d;
	// return series;
	return d;
}

export function getDefaultLineChart() {
	return {
		series: [{
			name: 'Installation',
			data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
		}, {
			name: 'Manufacturing',
			data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434]
		}, {
			name: 'Sales & Distribution',
			data: [11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387]
		}, {
			name: 'Project Development',
			data: [null, null, 7988, 12169, 15112, 22452, 34400, 34227]
		}, {
			name: 'Other',
			data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
		}],
		yAxis: {
			title: {
				text: 'Number of Employees'
			}
		},
		xAxis: {
			categories: ['2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017'],
			title: {
				text: null
			}
		},
	}
}

export function getLineChartConfig(data, renderTo) {
	return {
		chart: {
			type: 'spline',
			renderTo: renderTo
		},
		title: {
			text: ''
		},
		legend: {
			layout: 'vertical',
			align: 'right',
			verticalAlign: 'middle'
		},
		responsive: {
			rules: [{
				condition: {
					maxWidth: 500
				},
				chartOptions: {
					legend: {
						layout: 'horizontal',
						align: 'center',
						verticalAlign: 'bottom'
					}
				}
			}]
		},
		series: data.series,
		yAxis: data.yAxis,
		xAxis: data.xAxis,
	};
}