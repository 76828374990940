import Highcharts from 'highcharts';

import Utils from 'packages/page/utils';

export const barChart = "bar";
export const pieChart = "pie";
export const mixedChart = "mixed";
export const listChart = "list";
export const lineChart = "line";
export const bubbleChart = "bubble";
export const doughnutChart = "doughnut";
export const radarChart = "radar";
export const polarChart = "polar";
export const card = "card";
export const html = "html";
export const bulletChart = "bullet";
export const orgChart = "org";
export const simpleList = "simplelist";
export const simpleTable = "simpletable";
export const stackedBarChart = "stackedbar";
export const summaryTable = "summaryTable";
export const links = "links";

export const chartDisplayError = "There is no data to display, Please use correct API which return the data.";

export function defaultBarChartData() {
	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'bar',
		'chartData': {
			'width': 300,
			'height': 300,
			"layout": {
				"w": 4,
				"h": 8,
				"x": 0,
				"y": 0,
				"moved": false,
				"static": false
			},
			'name': 'Bar chart',
			'data': {
				'stacked': false,
				'labels': ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
				'datasets': [
					{
						label: 'My First dataset',
						backgroundColor: 'rgba(255,199,0,0.6)',
						borderColor: 'rgba(0,0,0,0.8)',
						borderWidth: 1,
						hoverBackgroundColor: 'rgba(255,199,0,0.4)',
						hoverBorderColor: 'rgba(0,0,0,1)',
						data: [65, 59, 80, 81, 56, 55, 40, 19]
					},
					{
						label: 'My Second dataset',
						backgroundColor: 'rgba(255,199,0,0.6)',
						borderColor: 'rgba(0,0,0,0.8)',
						borderWidth: 1,
						hoverBackgroundColor: 'rgba(255,199,0,0.4)',
						hoverBorderColor: 'rgba(0,0,0,1)',
						data: [55, 54, 30, 42, 66, 15, 65, 19]
					}
				]
			},
			'dataUrl': ""
		}
	}
};

export function defaultStackedBarChartData() {

	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'stackedbar',
		'chartData': {
			'width': 300,
			'height': 300,
			"layout": {
				"w": 4,
				"h": 8,
				"x": 0,
				"y": 0,
				"moved": false,
				"static": false
			},
			'name': 'Bar chart',
			'data': {
				'stacked': true,
				'labels': ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
				'datasets': [
					{
						label: 'My First dataset',
						backgroundColor: 'rgba(255,199,0,0.6)',
						borderColor: 'rgba(0,0,0,0.8)',
						borderWidth: 1,
						hoverBackgroundColor: 'rgba(255,199,0,0.4)',
						hoverBorderColor: 'rgba(0,0,0,1)',
						data: [65, 59, 80, 81, 56, 55, 40, 19]
					},
					{
						label: 'My Second dataset',
						backgroundColor: 'rgba(255,199,0,0.6)',
						borderColor: 'rgba(0,0,0,0.8)',
						borderWidth: 1,
						hoverBackgroundColor: 'rgba(255,199,0,0.4)',
						hoverBorderColor: 'rgba(0,0,0,1)',
						data: [55, 54, 30, 42, 66, 15, 65, 19]
					}
				]
			},
			'dataUrl': ""
		}
	}
};

export function defaultPieChartData() {
	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'pie',
		'chartData': {
			'width': 300,
			'name': 'Pie chart',
			'height': 300,
			"layout": {
				"w": 4,
				"h": 8,
				"x": 0,
				"y": 0,
				"moved": false,
				"static": false
			},
			'data': {
				"labels": ['Cricket', 'Football', 'Chess'],
				"datasets": [
					{
						backgroundColor: ['#FF6384', '#ffc700', '#B2912F'],
						hoverBackgroundColor: ['rgba(255,99,132,0.8)', 'rgba(255,199,0,0.8)', 'rgba(178,145,47,0.8)'],
						data: [300, 50, 100]
					}
				]
			},
			'dataUrl': ""
		}
	}
};

export function defaultMixedChartData() {
	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'mixed',
		'chartData': {
			'name': 'Mixed chart',
			"layout": {
				"w": 4,
				"h": 8,
				"x": 0,
				"y": 0,
				"moved": false,
				"static": false
			},
			'data': {
				chart: {
					renderTo: "mixed"
				},
				title: {
					text: 'Top 5 Portfolio companies with reconciliation issues'
				},
				credits: {
					enabled: false
				},
				xAxis: {
					categories: ['Qwerty Distributors Inc', 'Poiuy Manufactures Ltd', 'Vybzior Inc', 'Jutiet Manufacturer LLC', 'Kiopet Manufacturer LLC']
				},
				labels: {
					items: [{
						html: 'Issue types',
						style: {
							left: '50px',
							top: '18px',
							color: (Highcharts.theme && Highcharts.theme.textColor) || 'black'
						}
					}]
				},
				series: [{
					type: 'column',
					name: 'No/Wrong contracts',
					data: [3, 2, 1, 3, 4]
				}, {
					type: 'column',
					name: 'Quantity Mismatch',
					data: [2, 3, 5, 7, 6]
				}, {
					type: 'column',
					name: 'Tax mismatch',
					data: [4, 3, 3, 9, 0]
				}, {
					type: 'spline',
					name: 'Average',
					data: [3, 2.67, 3, 6.33, 3.33],
					marker: {
						lineWidth: 2,
						lineColor: Highcharts.getOptions().colors[3],
						fillColor: 'white'
					}
				}, {
					type: 'pie',
					name: 'No: of attacks',
					data: [{
						name: 'Financial Hacker',
						y: 13,
						color: Highcharts.getOptions().colors[0] // Jane's color
					}, {
						name: 'Internal Employee',
						y: 23,
						color: Highcharts.getOptions().colors[1] // John's color
					}, {
						name: 'Policitical Cyber-Warrior',
						y: 19,
						color: Highcharts.getOptions().colors[2] // Joe's color
					}],
					center: [100, 80],
					size: 100,
					showInLegend: false,
					dataLabels: {
						enabled: false
					}
				}]
			},
			'dataUrl': ""
		}
	};
}

export function defaultSimpleTableData() {
	let data = defaultTableChartData();
	data.chartType = "simpletable";
	data.chartData.name = "Simple Table";
	return data;
}

export function defaultListChartData() {
	return defaultTableChartData();
}

export function defaultSummaryTableData() {
	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'summaryTable',
		'chartData': {
			'width': 500,
			'height': 500,
			"layout": {
				"w": 4,
				"h": 8,
				"x": 0,
				"y": 0,
				"moved": false,
				"static": false
			},
			'name': 'Summary Table',
			'rowsPerPage': 10,
			'data': {
				'datasets': [
					{
						"category": "Category 1",
						"score": 3
					}, {
						"category": "Category 2",
						"score": 4
					}, {
						"category": "Category 3",
						"score": 5
					}, {
						"category": "Category 4",
						"score": 0
					}, {
						"category": "Category 5",
						"score": 2
					}, {
						"category": "Category 6",
						"score": 1
					}
				],
				"columns": [
					{
						"name": "category",
						"label": "Category"
					},
					{
						"name": "score",
						"label": "Score"
					}
				]
			},
			'selectedDataModel': "",
			'dataUrl': "",
			'pageSize': 10
		}
	}
};

function defaultTableChartData() {
	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'list',
		'chartData': {
			'width': 500,
			'height': 500,
			"layout": {
				"w": 12,
				"h": 12,
				"x": 0,
				"y": 0,
				"moved": false,
				"static": false
			},
			'name': 'Table',
			'rowsPerPage': 10,
			'data': {
				'datasets': [
					{
						"_id": "123a",
						"firstName": "investment",
						"lastName": "foot",
						"age": 28,
						"visits": 8,
						"progress": 48,
						"status": "relationship"
					}, {
						"_id": "123b",
						"firstName": "drawer",
						"lastName": "limit",
						"age": 29,
						"visits": 79,
						"progress": 95,
						"status": "single"
					}, {
						"_id": "123c",
						"firstName": "marriage",
						"lastName": "iron",
						"age": 29,
						"visits": 67,
						"progress": 19,
						"status": "single"
					}, {
						"_id": "123d",
						"firstName": "length",
						"lastName": "player",
						"age": 12,
						"visits": 15,
						"progress": 6,
						"status": "relationship"
					}, {
						"_id": "123e",
						"firstName": "wash",
						"lastName": "quartz",
						"age": 10,
						"visits": 23,
						"progress": 22,
						"status": "single"
					}, {
						"_id": "123f",
						"firstName": "face",
						"lastName": "tomatoes",
						"age": 12,
						"visits": 78,
						"progress": 54,
						"status": "single"
					}
				],
				"columns": [
					{
						"name": "firstName",
						"label": "First Name"
					},
					{
						"name": "lastName",
						"label": "Last Name"
					},
					{
						"name": "age",
						"label": "Age"
					},
					{
						"name": "visits",
						"label": "Visits"
					},
					{
						"name": "progress",
						"label": "Progress"
					},
					{
						"name": "status",
						"label": "Status"
					}
				]
			},
			'selectedDataModel': "",
			'dataUrl': "",
			'pageSize': 10
		}
	}
};

export function defaultLineChartData() {
	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'line',
		'chartData': {
			'width': 300,
			'height': 300,
			orientation: "vertical",
			"layout": {
				"w": 4,
				"h": 8,
				"x": 0,
				"y": 0,
				"moved": false,
				"static": false
			},
			'name': 'Line chart',
			'data': {
				"labels": [1500, 1600, 1700, 1750, 1800, 1850, 1900, 1950, 1999, 2050],
				"datasets": [{
					data: [86, 114, 106, 106, 107, 111, 133, 221, 783, 2478],
					label: "Africa",
					borderColor: "#3e95cd",
					fill: false
				}, {
					data: [282, 350, 411, 502, 635, 809, 947, 1402, 3700, 5267],
					label: "Asia",
					borderColor: "#8e5ea2",
					fill: false
				}, {
					data: [168, 170, 178, 190, 203, 276, 408, 547, 675, 734],
					label: "Europe",
					borderColor: "#3cba9f",
					fill: false
				}, {
					data: [40, 20, 10, 16, 24, 38, 74, 167, 508, 784],
					label: "Latin America",
					borderColor: "#e8c3b9",
					fill: false
				}, {
					data: [6, 3, 2, 2, 7, 26, 82, 172, 312, 433],
					label: "North America",
					borderColor: "#c45850",
					fill: false
				}
				]
			},
			'dataUrl': ""
		},
	}
}

export function defaultBubbleChartData() {
	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'bubble',
		'chartData': {
			'width': 300,
			'height': 300,
			"layout": {
				"w": 4,
				"h": 8,
				"x": 0,
				"y": 0,
				"moved": false,
				"static": false
			},
			'name': 'Bubble chart',
			'data': {
				labels: ['January'],
				datasets: [
					{
						label: 'My First dataset',
						fill: false,
						lineTension: 0.1,
						backgroundColor: 'rgba(75,192,192,0.4)',
						borderColor: 'rgba(75,192,192,1)',
						borderCapStyle: 'butt',
						borderDash: [],
						borderDashOffset: 0.0,
						borderJoinStyle: 'miter',
						pointBorderColor: 'rgba(75,192,192,1)',
						pointBackgroundColor: '#fff',
						pointBorderWidth: 1,
						pointHoverRadius: 5,
						pointHoverBackgroundColor: 'rgba(75,192,192,1)',
						pointHoverBorderColor: 'rgba(220,220,220,1)',
						pointHoverBorderWidth: 2,
						pointRadius: 1,
						pointHitRadius: 10,
						data: [
							{
								x: 10,
								y: 20,
								r: 5
							},
							{
								x: 20,
								y: 30,
								r: 3
							}
						]
					}
				]
			},
			'dataUrl': ""
		},
	}
};

export function defaultDoughnutChartData() {
	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'doughnut',
		'chartData': {
			'width': 300,
			'height': 300,
			"layout": {
				"w": 4,
				"h": 8,
				"x": 0,
				"y": 0,
				"moved": false,
				"static": false
			},
			'name': 'Doughnut chart',
			'data': {
				labels: [
					'Red',
					'Green',
					'Yellow'
				],
				datasets: [
					{
						data: [300, 50, 100],
						backgroundColor: [
							'#FF6384',
							'#36A2EB',
							'#FFCE56'
						],
						hoverBackgroundColor: [
							'#FF6384',
							'#36A2EB',
							'#FFCE56'
						]
					}
				]
			},
			'dataUrl': ""
		},
	}
}

export function defaultRadarChartData() {
	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'radar',
		'chartData': {
			'width': 300,
			'height': 300,
			"layout": {
				"w": 4,
				"h": 8,
				"x": 0,
				"y": 0,
				"moved": false,
				"static": false
			},
			'name': 'Radar chart',
			'data': {
				labels: ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'],
				datasets: [
					{
						label: 'My First dataset',
						backgroundColor: 'rgba(179,181,198,0.2)',
						borderColor: 'rgba(179,181,198,1)',
						pointBackgroundColor: 'rgba(179,181,198,1)',
						pointBorderColor: '#fff',
						pointHoverBackgroundColor: '#fff',
						pointHoverBorderColor: 'rgba(179,181,198,1)',
						data: [65, 59, 90, 81, 56, 55, 40]
					},
					{
						label: 'My Second dataset',
						backgroundColor: 'rgba(255,99,132,0.2)',
						borderColor: 'rgba(255,99,132,1)',
						pointBackgroundColor: 'rgba(255,99,132,1)',
						pointBorderColor: '#fff',
						pointHoverBackgroundColor: '#fff',
						pointHoverBorderColor: 'rgba(255,99,132,1)',
						data: [28, 48, 40, 19, 96, 27, 100]
					}
				]
			},
			'dataUrl': ""
		},
	}
}

export function defaultPolarChartData() {
	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'polar',
		'chartData': {
			'width': 300,
			'height': 300,
			"layout": {
				"w": 4,
				"h": 8,
				"x": 0,
				"y": 0,
				"moved": false,
				"static": false
			},
			'name': 'Polar chart',
			'data': {
				datasets: [
					{
						data: [
							11,
							16,
							7,
							3,
							14
						],
						backgroundColor: [
							'#FF6384',
							'#4BC0C0',
							'#FFCE56',
							'#E7E9ED',
							'#36A2EB'
						],
						label: 'My dataset' // for legend
					}
				],
				labels: [
					'Red',
					'Green',
					'Yellow',
					'Grey',
					'Blue'
				]
			},
			'dataUrl': ""
		},
	}
}

export function defaultHTMLData() {
	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'html',
		'chartData': {
			'width': 300,
			'height': 300,
			"layout": {
				"w": 4,
				"h": 8,
				"x": 0,
				"y": 0,
				"moved": false,
				"static": false
			},
			'name': 'HTML',
			'title': "HTML",
			"html": "<p>This is sample HTML</p>",
			'description': "",
			'data': {}
		},
	}
}

export function defaultCardData() {
	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'card',
		'chartData': {
			'width': 300,
			'height': 300,
			"layout": {
				"w": 4,
				"h": 8,
				"x": 0,
				"y": 0,
				"moved": false,
				"static": false
			},
			'name': 'Card Heading',
			'title': "Lizard",
			"body": 55,
			'description': "",
			'data': [10, 30, 15],
			'dataUrl': "",
			'formula': "total",
		},
	}
}

export function defaultSimpleListData() {
	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'simplelist',
		'chartData': {
			'title': "Classes",
			'width': 300,
			'height': 300,
			"layout": {
				"w": 4,
				"h": 8,
				"x": 0,
				"y": 0,
				"moved": false,
				"static": false
			},
			'name': 'SimpleList Chart',
			'data': [
				{
					"grade": "1st grade",
					"URL": "/firstgrade"
				},
				{
					"grade": "2nd grade",
					"URL": "/secondgrade"
				}
			],
			'dataUrl': "",
			"renderType": "links",
			"orientation": "verticle"
		},
	}
}

export function defaultBulletChartData() {
	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'bullet',
		"chartData": {
			"width": 407,
			"height": 338,
			"layout": {
				"w": 4,
				"h": 8,
				"x": 0,
				"y": 0,
				"moved": false,
				"static": false
			},
			"title": "This is bullet title",
			'name': 'Bullet chart',
			"data": [
				{
					"title": "Revenue",
					"subtitle": "US$, in thousands",
					"ranges": [150, 225, 300],
					"measures": [220, 270],
					"markers": [250]
				},
				{
					"title": "Profit",
					"subtitle": "%",
					"ranges": [20, 25, 30],
					"measures": [21, 23],
					"markers": [26]
				},
				{
					"title": "Order Size",
					"subtitle": "US$, average",
					"ranges": [350, 500, 600],
					"measures": [100, 320],
					"markers": [550]
				},
				{
					"title": "New Customers",
					"subtitle": "count",
					"ranges": [1400, 2000, 2500],
					"measures": [1000, 1650],
					"markers": [2100]
				},
				{
					"title": "Satisfaction",
					"subtitle": "out of 5",
					"ranges": [3.5, 4.25, 5],
					"measures": [3.2, 4.7],
					"markers": [4.4]
				}
			],
			"dataUrl": "",
			"error": "There is no data to display, Please use correct API which return the data."
		},
	}
}

export function defaultOrgChartData() {
	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'org',
		"chartData": {}
	}
}

export function defaultLinksData() {
	return {
		'chartUniqueId': Utils.generateGUID(),
		'chartType': 'links',
		'chartData': {
			'title': "Links",
			'width': '100%',
			'height': 300,
			"layout": {
				"w": 4,
				"h": 8,
				"x": 0,
				"y": 0,
				"moved": false,
				"static": false
			},
			'name': 'Links',
			'data': [
				{
					ExternalLink: 'https://dsilo.io',
					LinkType: "External",
					Title: "DSilo platform"
				},
				{
					ExternalLink: null,
					InternalLinkType: "Form",
					LinkFormURL: "6041973d26135d00116e421a",
					LinkPageURL: null,
					LinkType: "Internal",
					Title: "Forms Link",
				},
				{
					ExternalLink: null,
					InternalLinkType: "Page",
					LinkFormURL: "6041973d26135d00116e421a",
					LinkPageURL: null,
					LinkType: "Internal",
					Title: "Pages Link",
				},
			],
			'dataUrl': "",
			"renderType": "links",
			"orientation": "verticle"
		},
	}
}
