import { ActionTypes } from 'constants/index'

export const getRoles = (appId) => dispatch => {
	return dispatch({ type: ActionTypes.GET_ROLES, payload: appId });
}

export const getRole = (params) => dispatch => {
	dispatch({ type: ActionTypes.GET_ROLE, payload: params });
}

export const saveRole = (data) => dispatch => {
	return dispatch({ type: ActionTypes.SAVE_ROLE, payload: data });
}

export const updateRole = (data) => dispatch => {
	return dispatch({ type: ActionTypes.UPDATE_ROLE, payload: data });
}

export const deleteRole = (data) => dispatch => {
	return dispatch({ type: ActionTypes.DELETE_ROLE, payload: data });
}

export const newRole = () => dispatch => {
	const data = {
		name: '',
		description: '',
		appId: '',
		privileges: [],
	};
	return dispatch({ type: ActionTypes.GET_ROLE_SUCCESS, payload: data })
}