import { ActionTypes } from 'constants/index'

const initialState = {
	data: null,
	loading: false,
	error: '',
	saveSuccess: false,
	updateSuccess: false

};

const roleReducer = function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_ROLE_SUCCESS:
			{
				return {
					...state,
					data: action.payload,
					saveSuccess: false,
					updateSuccess: false
				};
			}
		case ActionTypes.GET_ROLE_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error
				};
			}
		case ActionTypes.SAVE_ROLE_SUCCESS:
			{
				return {
					...state,
					data: action.payload,
					saveSuccess: true
				};
			}
		case ActionTypes.SAVE_ROLE_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error
				};
			}
		case ActionTypes.UPDATE_ROLE_SUCCESS:
			{
				return {
					...state,
					data: action.payload,
					updateSuccess: true
				};
			}
		case ActionTypes.UPDATE_ROLE_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error
				};
			}
		default:
			{
				return state;
			}
	}
};

export default roleReducer;