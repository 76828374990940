import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, pipeline, dataModels, businessRules }) => {
		console.log("pipeline", pipeline);
		return {
			user,
			navigation,
			pipeline,
			saveSuccess: pipeline.saveSuccess,
			updateSuccess: pipeline.updateSuccess,
			error: pipeline.error,
			dataModels: dataModels.data,
			businessRules: businessRules.data,
		}
	}

	const mapDispatchToProps = {
		getPipeline: Actions.getPipeline,
		savePipeline: Actions.savePipeline,
		newPipeline: Actions.newPipeline,
		updatePipeline: Actions.updatePipeline,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert,
		getDataModels: Actions.getDataModels,
		getBusinessRules: Actions.getBusinessRules,
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux