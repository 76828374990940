import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './View'
import Redux from './Redux'
import { withRouter } from 'react-router-dom'

const Container = (props) => {
	const [state, setState] = useState({
		tabValue: 0,
		form: { entityId: props.user ? props.user.orgId : "", },
		privileges: [],
		roles: [],
		isSubmitButton: true
	});
	const [error, setError] = useState({ firstName: false, lastName: false, email: false, entityId: false })
	const [tabValue, setTabValue] = React.useState(0)

	const handleChange = (event) => setState({
		...state,
		form: _.set({ ...state.form }, event.target.name,
			event.target.type === 'checkbox' ? event.target.checked : event.target.value)
	})

	const updateMemberState = () => {
		const params = props.match.params;
		const { memberId } = params;

		if (memberId === 'new') {
			props.newMember();
		}
		else {
			props.getMember(props.match.params);
		}
		props.getRoles(props.match.params.dappId);
		props.getEntities(props.match.params.dappId);
	};

	const updateFormState = () => {
		console.log("props.member.data ===", props.member.data);
		setState({ ...state, form: { ...props.member.data } })
	}

	const handleChipChange = (value, name) => setState({ ...state, form: _.set({ ...state.form }, name, value) })

	const canBeSubmitted = () => {
		// const { firstName, lastName, email, entityId } = state.form;
		// if (firstName && firstName.length === 0) {
		// 	setError({ ...error, firstName: "Please enter first name" })
		// }
		// if (lastName && lastName.length === 0) {
		// 	setError({ ...error, lastName: "Please enter last name" })
		// }
		// if (email && email.length === 0) {
		// 	setError({ ...error, email: "Please enter email" })
		// }
		// if (entityId && entityId == "") {
		// 	setError({ ...error, entityId: "Please select company" })
		// }
		// return (
		// 	firstName && firstName.length > 0 && lastName && lastName.length > 0 && email && email.length > 0 && entityId && entityId !== "" && !_.isEqual(props.member.data, state.form) && state.isSubmitButton
		// );
		return true;
	}

	useEffect(() => {
		if (!_.isEqual(props.member.data, state.form)) {
			setState({ ...state, isSubmitButton: true })
		}
	}, [state.form])

	useEffect(() => {
		props.getGroups(props.match.params.dappId)
		updateMemberState();
	}, [props.location])

	useEffect(() => {
		updateFormState();
	}, [props.member.data])

	useEffect(() => {
		updateFormState();
	}, [props.groups])

	useEffect(() => {
		setState({
			...state,
			roles: props.roles && props.roles.data && props.roles.data.map(role => {
				return {
					value: role._id,
					label: role.name
				}
			})
		})
	}, [props.roles.data]);

	useEffect(() => {
		if (props.member.saveSuccess) {
			props.showAlert('Member saved successfully', { id: "member", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/members/${props.member.data._id}` });
		}
		if (props.member.updateSuccess) {
			props.showAlert('Member updated successfully', { id: "member", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/members/${props.member.data._id}` });
		}
	}, [props.member.saveSuccess, props.member.updateSuccess])

	useEffect(() => {
		let error = props.member.error;
		if (error) {
			error.firstName && error.lastName && error.email
				&& props.showAlert("NETWORK ERROR: Please verify your internet connection", {
					id: "member",
					variant: 'danger',
					icon: 'times-circle'
				})
			if (error.firstName) {
				setError({ ...error, firstName: error.firstName });
			} else if (error.lastName) {
				setError({ ...error, lastName: error.lastName });
			} else if (error.email) {
				setError({ ...error, email: error.email });
			}
		}
	}, [props.member.error])

	useEffect(() => {
		if (typeof props.member.error === "object" && props.member.error.status) {
			if (props.member.error.status == 401) {
				props.history.push({ pathname: "/login" });
				props.showAlert(props.member.error.errorMessage, { id: "member", variant: 'error' });
			} else {
				if (props.member.error.errorMessage) {
					setState({ ...state, isSubmitButton: true });
					if (props.member.error.errorMessage) {
						props.showAlert(props.member.error.errorMessage, { id: "member", variant: 'error' });
					}
				}
			}
		}
	}, [props.member.error])

	useEffect(() => {
		if (!props.roles.error && props.roles.error !== "") {
			props.showAlert("Network Error", { id: "member", variant: 'danger', icon: 'times-circle' });
		}
	}, [props.roles.error])

	useEffect(() => {
		if (!props.entities.error && props.entities.error !== "") {
			props.showAlert("Network Error", { id: "member", variant: 'danger', icon: 'times-circle' });
		}
	}, [props.entities.error])

	useEffect(() => {
		props.newMember()
		return () => {
			props.newMember()
		}
	}, [])

	useEffect(() => {
		console.log("groups===", props.groups);
		// { userId: "6012e7bb791fd200129bb1ae", userName: "Anand R" }
		// setState({ ...state, form: _.set({ ...state.form }, name, value) })
	}, [props.groups])

	const saveMemberData = () => {
		const { firstName, lastName, email, entityId, selectedRoles } = state.form;
		var reEmail = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
		// if (!firstName || firstName.length === 0) {  making first name and last name optional
		// 	setTabValue(0)
		// 	setError({ ...props.member.error, firstName: "Please enter first name" })
		// 	return; 
		// }
		// if (!lastName || lastName.length === 0) {
		// 	setTabValue(0)
		// 	setError({ ...props.member.error, lastName: "Please enter last name" })
		// 	return;
		// } 
		if (!email || email.length === 0 || !email.match(reEmail)) {
			setTabValue(0)
			setError({ ...props.member.error, email: "Please enter email" })
			return;
		}
		if (!entityId || entityId === "") {
			setTabValue(0)
			setError({ ...props.member.error, entityId: "Please select a company" })
			return;
		}
		if (!selectedRoles || selectedRoles.length === 0) {
			setTabValue(1)
			setError({ ...props.member.error, selectedRole: "Please select a role" })
			return;
		}
		setState({ ...state, isSubmitButton: false });
		console.log("saveMemberData === state.form ===", state.form);
		props.saveMember({ ...state.form, appId: props.match.params.dappId })
	}

	const updateMemberData = () => {
		console.log("updateMemberData === state.form ===", state.form);
		setState({ ...state, isSubmitButton: false });
		props.updateMember({ ...state.form, appId: props.match.params.dappId })
	}
	
	const handleDisable = () => {
		props.disableMember({ _id: state.form._id, appId: props.match.params.dappId })
	}

	return (
		<View
			{...props}
			state={state}
			error={error}
			handleChange={handleChange}
			handleChipChange={handleChipChange}
			canBeSubmitted={canBeSubmitted}
			handleDisable={handleDisable}
			saveMember={() => saveMemberData()}
			updateMember={() => updateMemberData()}
			tabValue={tabValue}
			setTabValue={setTabValue}
		/>
	)
}

export default hot(withRouter(Redux(Container)))