import _ from 'modules/lodash';
import { generateCardBody } from './page.module';
import Utils from './utils';

export function checkForNullOrUndefined(input) {
	return input === null || input === undefined;
};

export function updatedChartData(updatedData, data) {
	let conditions = data.conditions.map(cond => {
		return { ...cond, editMode: false }
	})
	updatedData.chartData.height = parseInt(data.height);
	updatedData.chartData.width = parseInt(data.width);
	updatedData.chartData.dataUrl = data.dataUrl;
	updatedData.chartData.linkURL = data.linkURL;
	updatedData.chartData.name = data.name;
	updatedData.chartData.description = data.description;
	updatedData.chartData.dataHandler = data.dataHandler;
	updatedData.chartData.selectedDataModel = data.selectedDataModel;
	updatedData.chartData.selectedDataElement = data.selectedDataElement;
	updatedData.chartData.scoreDataElement = data.scoreDataElement;
	updatedData.chartData.selectedDataLabels = data.selectedDataLabels;
	updatedData.chartData.pageLinking = data.pageLinking;
	updatedData.chartData.associatedFormName = data.associatedFormName;
	updatedData.chartData.datelabeltype = data.datelabeltype;
	updatedData.chartData.labelformat = data.labelformat;
	updatedData.chartData.style = data.style;
	updatedData.chartData.orientation = data.orientation;
	updatedData.chartData.className = data.className;
	updatedData.chartData.conditions = conditions;
	updatedData.chartData.links = data.links;
	updatedData.chartData.linkPie = data.linkPie;
	updatedData.chartData.linkCard = data.linkCard;
	updatedData.chartData.cardLinking = data.cardLinking;
	updatedData.chartData.backgrounds = data.backgrounds;
	updatedData.chartData.categories = data.categories;
	updatedData.chartData.dataOrHTML = data.dataOrHTML;
	updatedData.chartData.query = data.query;
	updatedData.chartData.json = data.json;
	updatedData.chartData.metric = data.metric;
	updatedData.chartData.html = data.html;
	updatedData.chartData.cardTemplateUI = data.cardTemplateUI;
	updatedData.chartData.isCardCategories = data.isCardCategories;
	updatedData.chartData.barXAxis = data.barXAxis;
	updatedData.chartData.barYAxis = data.barYAxis;
	updatedData.chartData.parentDataElement = data.parentDataElement;
	updatedData.chartData.loadChildData = data.loadChildData;
	updatedData.chartData.groupByXAxis = data.groupByXAxis;
	updatedData.chartData.selectedTypeKey = data.selectedTypeKey;
	updatedData.chartData.enableLinking = data.enableLinking;
	updatedData.chartData.selectedLinkValue = data.selectedLinkValue;
	updatedData.chartData.selectedTitleColumn = data.selectedTitleColumn;
	updatedData.chartData.openLinkType = data.openLinkType;
	updatedData.chartData.mainDataModel = data.lookUpConfig.mainDataModel;
	updatedData.chartData.lookupFromDataModels = data.lookUpConfig.lookupFromDataModels;
	updatedData.chartData.mappings = data.lookUpConfig.mappings;

	return updatedData;
}

export function getUpdatedBarChartData(currentChartDataToEdit, data) {
	let updatedData = _.cloneDeep(currentChartDataToEdit);
	updatedData = updatedChartData(updatedData, data);
	updatedData.chartData.data.stacked = data.stacked;
	updatedData.chartData.data.labels = data.labels && JSON.parse(data.labels);
	updatedData.chartData.data.datasets[0] = {
		title: data.title,
		label: data.label,
		backgroundColor: data.backgroundColor ? data.backgroundColor : 'rgba(255,199,0,0.6)',
		borderColor: data.borderColor ? data.borderColor : 'rgba(0,0,0,0.8)',
		borderWidth: data.borderWidth ? data.borderWidth : 1,
		hoverBackgroundColor: data.hoverBackgroundColor ? data.hoverBackgroundColor : 'rgba(255,199,0,0.4)',
		hoverBorderColor: data.hoverBorderColor ? data.hoverBorderColor : 'rgba(0,0,0,1)',
		data: data.data && JSON.parse(data.data)
			? JSON.parse(data.data) : updatedData.chartData.data.datasets[0].data
	};
	return updatedData;
};

export function getUpdatedPieChartData(currentChartDataToEdit, data) {
	let updatedData = _.cloneDeep(currentChartDataToEdit);
	updatedData = updatedChartData(updatedData, data);
	updatedData.chartData.data.labels = JSON.parse(data.labels);
	updatedData.chartData.data.datasets[0] = {
		title: data.title,
		backgroundColor: data.backgroundColor && data.backgroundColor,
		hoverBackgroundColor: data.hoverBackgroundColor && data.hoverBackgroundColor,
		data: data.data && JSON.parse(data.data)
			? JSON.parse(data.data) : updatedData.chartData.data.datasets[0].data
	};
	return updatedData;
};


export function getUpdatedDoughnutChartData(currentChartDataToEdit, data) {
	let updatedData = _.cloneDeep(currentChartDataToEdit);
	updatedData = updatedChartData(updatedData, data);
	updatedData.chartData.data.labels = JSON.parse(data.labels);
	updatedData.chartData.data.datasets[0] = {
		title: data.title,
		backgroundColor: data.backgroundColor,
		hoverBackgroundColor: data.hoverBackgroundColor,
		data: data.data && JSON.parse(data.data)
			? JSON.parse(data.data) : updatedData.chartData.data.datasets[0].data
	};
	return updatedData;
};

export function getUpdatedPolarChartData(currentChartDataToEdit, data) {
	let updatedData = _.cloneDeep(currentChartDataToEdit);
	updatedData = updatedChartData(updatedData, data);
	updatedData.chartData.data.labels = JSON.parse(data.labels);
	updatedData.chartData.data.datasets[0] = {
		title: data.title,
		backgroundColor: data.backgroundColor && data.backgroundColor,
		hoverBackgroundColor: data.hoverBackgroundColor && JSON.parse(data.hoverBackgroundColor),
		data: data.data && JSON.parse(data.data)
	};
	return updatedData;
};

export function getUpdatedListChartData(currentChartDataToEdit, data) {
	let updatedData = _.cloneDeep(currentChartDataToEdit);
	updatedData = updatedChartData(updatedData, data);
	console.log("updatedData ===", updatedData);
	if (!updatedData.chartData.data)
		updatedData.chartData.data = {}
	updatedData.chartData.data.datasets = data.data ? JSON.parse(data.data) : {};
	updatedData.chartData.data.columns = data.columns ? JSON.parse(data.columns) : {};
	updatedData.chartData.selectedDataPages = data.selectedDataPages;
	updatedData.chartData.selectedDataColumns = data.selectedDataColumns;
	updatedData.chartData.rowsPerPage = data.rowsPerPage;
	updatedData.chartData.extendedTablePage = data.extendedTablePage;
	return updatedData;
};

export function getUpdatedMixedChartData(currentChartDataToEdit, data) {
	return getUpdatedListChartData(currentChartDataToEdit, data);
};

export function getUpdatedCardData(currentChartDataToEdit, data) {
	console.log("getUpdatedCardData invoked");
	let updatedData = _.cloneDeep(currentChartDataToEdit);
	updatedData = updatedChartData(updatedData, data);
	updatedData.chartData.selectedDataElement = Array.isArray(data.selectedDataElement)
		? data.selectedDataElement : [data.selectedDataElement];
	updatedData.chartData.selectedGroupingDataElement = Array.isArray(data.selectedGroupingDataElement)
		? data.selectedGroupingDataElement
		: (data.selectedGroupingDataElement ? [data.selectedGroupingDataElement] : []);
	updatedData.chartData.useGrouping = data.useGrouping;
	updatedData.chartData.title = data.title;
	updatedData.chartData.metric = data.metric;
	updatedData.chartData.data = data.data && JSON.parse(data.data);
	if (updatedData.chartData.data) {
		updatedData.chartData.body = generateCardBody(data.metric, updatedData.chartData.data);
	}
	return updatedData;
};

export function getUpdatedHTMLData(currentChartDataToEdit, data) {
	let updatedData = _.cloneDeep(currentChartDataToEdit);
	updatedData = updatedChartData(updatedData, data);
	updatedData.chartData.selectedDataElement = Array.isArray(data.selectedDataElement)
		? data.selectedDataElement : [data.selectedDataElement];
	updatedData.chartData.title = data.title;
	updatedData.chartData.metric = data.metric;
	updatedData.chartData.html = data.html;
	updatedData.chartData.data = data.data && JSON.parse(data.data);
	if (updatedData.chartData.data) {
		updatedData.chartData.body = generateCardBody(data.metric, updatedData.chartData.data);
	}
	return updatedData;
};


export function getUpdatedRadarChartData(currentChartDataToEdit, data) {
	let updatedData = _.cloneDeep(currentChartDataToEdit);
	updatedData = updatedChartData(updatedData, data);
	updatedData.chartData.data.labels = JSON.parse(data.labels);
	updatedData.chartData.data.datasets[0] = {
		title: data.title,
		backgroundColor: data.backgroundColor,
		hoverBackgroundColor: data.hoverBackgroundColor,
		data: data.data && JSON.parse(data.data)
			? JSON.parse(data.data) : updatedData.chartData.data.datasets[0].data
	};

	return updatedData;
};

export function getUpdatedSimpleListData(currentChartDataToEdit, data) {
	let updatedData = _.cloneDeep(currentChartDataToEdit);
	updatedData = updatedChartData(updatedData, data);
	updatedData.chartData.title = data.title;
	updatedData.chartData.data = data.data && JSON.parse(data.data);
	return updatedData;
};

export function getUpdatedLineChartData(currentChartDataToEdit, data) {
	let updatedData = _.cloneDeep(currentChartDataToEdit);
	updatedData = updatedChartData(updatedData, data);
	updatedData.chartData.isGrouping = data.isGrouping;
	updatedData.chartData.data.labels = data.labels && JSON.parse(data.labels);
	updatedData.chartData.data.datasets = data.data && JSON.parse(data.data);
	return updatedData;
};

/*export function getUpdatedRadarChartData(currentChartDataToEdit, data) {
	let updatedData = _.cloneDeep(currentChartDataToEdit);
	updatedData = updatedChartData(updatedData, data);
	updatedData.chartData.data.labels = data.labels && JSON.parse(data.labels);
	updatedData.chartData.data.datasets = data.data && JSON.parse(data.data);
	return updatedData;
};*/

export function addCondAndLink(ary, data) {
	let arys = [...data];
	if (ary.id && ary.index > -1 && ary.editMode) {
		arys[ary.index] = { ...ary, editMode: false };
	} else {
		let obj = ary.id ? ary : { id: Utils.generateGUID(), ...ary };
		arys.push({ ...obj });
	}
	return arys;
}

export function editCondAndLink(object, index, data) {
	let obj = { ...data[index] };
	obj.editMode = true;
	obj.index = index;
	let ary = [...data];
	ary[index] = obj;
	return { ary, obj };
}

export function deleteCondAndLink(object, index, data) {
	let ary = [...data];
	ary.splice(index, 1);
	return ary;
}

export function calculateMetrix(data, metric) {
	if (metric === "count") {
		return data.length;
	} else if (metric === "total") {
		return data.reduce((acc, item) => {
			return parseFloat(('' + acc).replace(/,/g, '')) + item;
		}, 0);
	} else if (metric === "average") {
		let sum = data.reduce((acc, item) => {
			return ('' + acc).replace(/,/g, '') + item;
		}, 0);
		return sum / data.length;
	} else if (metric === "max") {
		return Math.max(...data.map(Number));
	} else if (metric === "min") {
		return Math.min(...data.map(Number));
	} else if (metric === "percentage") {
		let sum = data.reduce((acc, item) => {
			return parseFloat(('' + acc).replace(/,/g, '')) + item;
		}, 0)
		return data.map(d => {
			return parseFloat((('' + d).replace(/,/g, '') / sum) * 100) + "%";
		})
	} else {
		return data[0];
	}
}

export function calculateMetrixFromArray(data, metric) {
	let l = data.length;
	let sum = data.reduce(function (r, a) {
		a.data.forEach(function (b, i) {
			r[i] = (r[i] || 0) + b;
		});
		return r;
	}, []);

	if (metric === "count") {
		return data.length;
	} else if (metric === "total") {
		return sum;
	} else if (metric === "average") {
		return sum.map(d => d / l)
	} else if (metric === "max") {
		return data.map(d => {
			return Math.max(...d.data.map(Number));
		})
	} else if (metric === "min") {
		return data.map(d => {
			return Math.min(...data.map(Number));
		})
	} else if (metric === "percentage") {
		let s = sum.reduce((acc, item) => {
			return acc + item;
		}, 0)
		return data.map(d => {
			return ((d / s) * 100) + "%";
		})
	} else {
		return data[0];
	}
}

export function getUpdatedLinksData(currentChartDataToEdit, data) {
	let updatedData = _.cloneDeep(currentChartDataToEdit);
	updatedData = updatedChartData(updatedData, data);
	updatedData.chartData.title = data.title;
	if (data.data && data.data.length > 0)
		updatedData.chartData.data = JSON.parse(data.data);
	return updatedData;
};
