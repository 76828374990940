import { put, takeEvery, takeLatest } from 'redux-saga/effects';

import { ActionTypes } from 'constants/index';

function* saveFlowchart(action) {
    console.log("inside saga",action.data)
    try {
      
      yield put({ type: ActionTypes.SAVE_FLOWCHART_SUCCESS, payload: action.data });
    } 
    catch (e) {
      console.error('e', e);
      yield put({ type: ActionTypes.SAVE_FLOWCHART_FAILURE, error: e.data });
    }
  }

  function* getFlowchart(action) {
    try {
      console.log('Get Flowchart Data' );
      
      yield put({ type: ActionTypes.GET_FLOWCHART_SUCCESS, payload: flowchartDat });
    } catch (e) {

      console.error('e', e);
      yield put({ type: ActionTypes.GET_FLOWCHART_FAILURE, error: e.data });
    }
  }
  

  export function* watchflowchart() {
    yield takeEvery(ActionTypes.SAVE_FLOWCHART, saveFlowchart);
    yield takeEvery(ActionTypes.GET_FLOWCHART, getFlowchart);
   
  }
  export default watchflowchart;