import React, { Component } from 'react';
import {
	TextField, FormControl, Select,
	MenuItem, FormGroup, FormLabel, Button
} from '@material-ui/core';
import 'styles/page-designer.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import _ from 'modules/lodash';
import { withRouter } from 'react-router-dom';
import ChipSelect from "components/@custom/ChipSelect"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .MuiFormControl-root': {
      width: 'unset',
    },
  },
});
const Link = (props) => {
	const myClasses = useStyles();
	let { classes, values, selectedComparatorList, appId } = props;
	console.log("props.dataModels", props.dataModels)
	let dataModels = props.dataModels.data;
	console.log('====================================');
	console.log("dataModels", dataModels);
	console.log('====================================');
	console.log("value.dataElementss", values.dataElements);
	console.log("values.link", values.link);
	console.log('====================================');
	console.log("values.link.dataElements", values.link.dataElements);
	console.log("values.link.additionalFields", values.link.additionalFields);
	console.log("values.link.selectedLinkDataModel", values.link.selectedLinkDataModel);
	console.log('====================================');

	return (<>
		{/* <FormGroup row className="items-center"> */}
			<FormLabel component="legend">
				Links
            </FormLabel>
		{/* </FormGroup> */}
		<FormGroup row className={`items-center  max-w-full ${myClasses.root}`}>
			<FormControl variant="outlined" className={classes.formControl}>
				<Select
					value={values.link.selectedLinkDataElement}
					onChange={props.onLinkChangeHandler}
					inputProps={{
						name: 'selectedLinkDataElement',
						id: 'selectedLinkDataElement',
					}}>
					<MenuItem value="">
						<em>Select a column</em>
					</MenuItem>
					{values && values.selectedDataElement && values.selectedDataElement.map((item, i) => {
						return <MenuItem key={i} value={item.value} >{item.label}</MenuItem>
					})}
				</Select>
			</FormControl>
			<FormControl variant="outlined" className={classes.formControl}>
				<Select
					value={values.link.selectedLinkType}
					onChange={props.onLinkChangeHandler}
					inputProps={{
						name: 'selectedLinkType',
						id: 'selectedLinkType',
					}}>
					<MenuItem value="">
						<em>Select Link Type</em>
					</MenuItem>
					<MenuItem key={"page"} value={"page"}>
						<em>{"Page"}</em>
					</MenuItem>
					<MenuItem key={"form"} value={"form"}>
						<em>{"Form"}</em>
					</MenuItem>
				</Select>
			</FormControl>
			<FormControl variant="outlined" className={classes.formControl}>
				<Select
					value={values.link.selectedLinkValue}
					onChange={props.onLinkChangeHandler}
					inputProps={{
						name: 'selectedLinkValue',
						id: 'selectedLinkValue',
					}}>
					{values.link.selectedLinkType === "page" && <MenuItem value="">
						<em>Select a Page</em>
					</MenuItem>}
					{values.link.selectedLinkType === "form" && <MenuItem value="">
						<em>Select a Link</em>
					</MenuItem>}
					{!values.link.selectedLinkType && <MenuItem value="">
						<em>Please Select Link Type</em>
					</MenuItem>}
					{values.link.selectedLinkType !== "" &&
						(values.link.selectedLinkType === "page"
							? props.pages
							: (values.link.selectedLinkType === "form"
								? props.forms
								: []
							)
						).map((ele, i) => {
							return <MenuItem key={i}
								value={values.link.selectedLinkType === "page"
									? ele._id : ele.name
								}>
								{ele.name}
							</MenuItem>
						})
					}
				</Select>
			</FormControl>
			<FormControl variant="outlined" className={classes.formControl}>
				<Select
					value={values.link.selectedLinkKey}
					onChange={props.onLinkChangeHandler}
					inputProps={{
						name: 'selectedLinkKey',
						id: 'selectedLinkKey',
					}}>
					<MenuItem value="">
						<em>Select a link key</em>
					</MenuItem>
					{values && values.selectedDataElement && values.selectedDataElement.map((item, i) => {
						return <MenuItem key={i} value={item.value} >{item.label}</MenuItem>
					})}
				</Select>
			</FormControl>
			{!props.checkForNullOrUndefined(dataModels)
				&& (<>
					<FormControl variant="outlined" className={classes.formControl}>
						<Select
							value={values.link.selectedLinkDataModel}
							onChange={props.onLinkChangeHandler}
							inputProps={{
								name: 'selectedLinkDataModel',
								id: 'selectedLinkDataModel',
							}}>
							{<MenuItem value="">
								<em>Select a Datamodel</em>
							</MenuItem>}
							{dataModels !== null && dataModels.map(dataModel => (
								<MenuItem key={dataModel._id} value={dataModel._id}>
									{dataModel.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</>
				)}
			{values.link.selectedLinkDataModel
				&& <FormControl variant="outlined" className={classes.formControl1}>
					<ChipSelect
						className="w-full my-16"
						value={values.link.additionalFields}
						onChange={props.onLinkChangeHandler}
						placeholder="Select additional fields"
						textFieldProps={{
							label: 'Select additional fields',
							InputLabelProps: {
								shrink: true
							},
							variant: 'standard'
						}}
						options={values.link.dataElements}
						isMulti
					/>
				</FormControl>
			}
			<Button
				color="secondary"
				variant="outlined"
				className={classes.formControl}
				onClick={() => {
					props.addLink(values.link);
				}}
			// disabled={!this.canBeSubmitted()}
			>
				Add
            </Button>
		</FormGroup>
	</>)
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}

function mapStateToProps({ dataModels, pages, forms }) {
	return {
		dataModels: dataModels,
		pages: pages.data,
		forms: forms.data,
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Link)
)
