const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
import { request } from 'modules/client';

export class GroupsService {
  static getGroups(appId) {
    const uri = API_BASE_URL + '/groups';
    return request(uri, {
      method: 'GET',
      headers: { appId: appId },
    });
  }

  static getGroup(params) {
    const uri = API_BASE_URL + '/groups/' + params.groupId;
    return request(uri, {
      method: 'GET',
      headers: { appId: params.dappId },
    });
  }

  static saveGroup(data) {
    const uri = API_BASE_URL + '/groups';
    return request(uri, {
      method: 'POST',
      headers: { appId: data.appId },
      payload: data,
    });
  }

  static deleteGroup(data) {
    const uri = API_BASE_URL + '/groups/' + data._id;
    return request(uri, {
      method: 'DELETE',
      headers: { appId: data.appId },
    });
  }

  static updateGroup(data) {
    const uri = API_BASE_URL + '/groups/' + data._id;
    return request(uri, {
      method: 'PUT',
      headers: { appId: data.appId },
      payload: data,
    });
  }
}
