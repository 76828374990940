import React, { Component } from 'react'
import {
	Button, TextField, Table, TableBody, TableCell, TableHead, TableRow,
	withStyles
} from '@material-ui/core'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const styles = theme => ({
	head: {
		backgroundColor: "lightgrey",
		color: theme.palette.common.white,
	},
	customFont: {
		"font-size": "large"
	}
});

class TableInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			json: this.props.json,
			rows: []
		}
		this.newRow = new Array(this.props.json.data.values.length).fill("");
	}
	componentDidMount() {
	}
	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if (this.props.json.data.values.length !== prevProps.json.data.values.length) {
			this.newRow = new Array(this.props.json.data.values.length).fill("");
		}
	}
	static getDerivedStateFromProps(props, state) {
		return { json: props.json }
	}
	addData = () => {
		let rowsData = JSON.parse(JSON.stringify(this.state.rows));
		rowsData = Array.isArray(this.state.rows) ? this.state.rows : [];
		rowsData.push([...this.newRow]);
		this.setState({ rows: rowsData })
		this.props.setData("rows", rowsData)
	}
	emptyRow = () => {
		const { classes } = this.props;
		return (<TableRow>
			{this.props.json.data.values.map((item, index) => {
				return (<TableCell className={classNames(classes.customFont)}>
					<TextField variant="outlined" value={this.newRow[index]}
						onChange={(e) => { this.newRow[index] = e.target.value; this.forceUpdate() }} /></TableCell>);
			})}
			<TableCell>
				<Button
					variant="contained" onClick={(e) => { this.addData(); }} color="secondary">
					Add Data</Button></TableCell>
		</TableRow>)
	}

	delete = (index) => {
		let rowsData = JSON.parse(JSON.stringify(this.state.rows));
		let newRows = rowsData.filter((row, i) => {
			return i != index;
		});
		this.setState({ rows: newRows });
		this.props.setData("rows", newRows);
	}
	editRowdata = (editData, index) => {
		if (this.edit == index) {
			let rowsData = JSON.parse(JSON.stringify(this.state.rows));
			rowsData[index] = this.editData;
			this.edit = null;
			this.editData = null;
			this.setState({ rows: rowsData });
			this.props.setData("rows", rowsData)
			return;
		}
		this.edit = index;
		this.editData = editData;
		this.forceUpdate();
	}
	render() {
		const { classes } = this.props;
		const { item, outerIndex, gridSize, index } = this.props;
		return (
			<>
				<Table className={classNames(classes.table)} size="small" aria-label="a dense table">
					<TableHead className={classNames(classes.head)}>
						<TableRow>
							{this.props.json.data.values.map((item) => {
								return (<TableCell className={classNames(classes.customFont)}>{item.value}</TableCell>);
							})}
							<TableCell className={classNames(classes.customFont)}>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.props.json.data.rows.map((row, index) => {
							return (
								<TableRow>
									{this.edit != index && row.map((rowdata) => {
										return (<TableCell className={classNames(classes.customFont)}>
											{rowdata}</TableCell>)
									})}

									{this.edit == index && row.map((rowdata, index) => {
										return (<TableCell className={classNames(classes.customFont)}>
											<TextField value={this.editData[index]}
												onChange={(e) => { this.editData[index] = e.target.value; this.forceUpdate() }}
												variant="outlined" />
										</TableCell>)
									})}
									<TableCell>
										<Button onClick={(e) => { this.editRowdata([...row], index) }}
											variant="contained" color="secondary">
											Edit</Button>
										<Button onClick={(e) => { this.delete(index) }}
											variant="contained" color="secondary">
											Delete</Button>
									</TableCell>
								</TableRow>)
						})}
						{this.emptyRow()}
					</TableBody>
				</Table>
			</>
		)
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}

function mapStateToProps() {
	return {
	}
}

export default withStyles(styles, { withTheme: true })(
	withRouter(connect(mapStateToProps, mapDispatchToProps)(TableInput))
)
