import React from "react";
import {
	TableHead, TableSortLabel, TableCell, TableRow, Checkbox, Tooltip, IconButton,
	Icon, Menu, MenuList, MenuItem, ListItemIcon, ListItemText,
	withStyles
} from "@material-ui/core";

const rows = [
	// {
	// 	id: "firstName",
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: "First Name",
	// 	sort: true
	// },
	// {
	// 	id: "lastName",
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: "Last Name",
	// 	sort: true
	// },
	{
		id: "email",
		numeric: false,
		disablePadding: false,
		label: "Email",
		sort: true
	},
	{
		id: "company",
		numeric: false,
		disablePadding: false,
		label: "Company",
		sort: true
	},
	{
		id: "actions",
		numeric: true,
		disablePadding: false,
		label: "Actions",
		sort: false
	}
];

const styles = theme => ({
	root: {},
	actionsButtonWrapper: {
		position: "absolute",
		top: 0,
		left: 64,
		width: 64,
		height: 63,
		zIndex: 10,
		background: theme.palette.background.paper,
		alignItems: "center",
		display: "flex",
		justifyContent: "center"
	}
});

class MembersTableHead extends React.Component {
	state = {
		selectedMembersMenu: null
	};

	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	openSelectedMembersMenu = event => {
		this.setState({ selectedMembersMenu: event.currentTarget });
	};

	closeSelectedMembersMenu = () => {
		this.setState({ selectedMembersMenu: null });
	};

	render() {
		const {
			onSelectAllClick,
			order,
			orderBy,
			numSelected,
			rowCount,
			classes
		} = this.props;
		const { selectedMembersMenu } = this.state;

		return (
			<TableHead>
				<TableRow className="h-64">
					<TableCell padding="checkbox" className="relative pl-4 sm:pl-12">
						<Checkbox
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={numSelected === rowCount}
							onChange={onSelectAllClick}
						/>
						{numSelected > 0 && (
							<div className={classes.actionsButtonWrapper}>
								<IconButton
									aria-owns={selectedMembersMenu ? "selectedMembersMenu" : null}
									aria-haspopup="true"
									onClick={this.openSelectedMembersMenu}
								>
									<Icon>more_horiz</Icon>
								</IconButton>
								<Menu
									id="selectedMembersMenu"
									anchorEl={selectedMembersMenu}
									open={Boolean(selectedMembersMenu)}
									onClose={this.closeSelectedMembersMenu}
								>
									<MenuList>
										<MenuItem
											onClick={() => {
												this.closeSelectedMembersMenu();
											}}
										>
											<ListItemIcon className={classes.icon}>
												<Icon>delete</Icon>
											</ListItemIcon>
											<ListItemText inset primary="Remove" />
										</MenuItem>
									</MenuList>
								</Menu>
							</div>
						)}
					</TableCell>
					{rows.map(row => {
						return (
							<TableCell
								key={row.id}
								numeric={row.numeric}
								padding={row.disablePadding ? "none" : "default"}
								sortDirection={orderBy === row.id ? order : false}
							>
								{row.sort ? (
									<Tooltip
										title="Sort"
										placement={row.numeric ? "bottom-end" : "bottom-start"}
										enterDelay={300}
									>
										<TableSortLabel
											active={orderBy === row.id}
											direction={order}
											onClick={this.createSortHandler(row.id)}
										>
											{row.label}
										</TableSortLabel>
									</Tooltip>
								) : (<>
									<TableSortLabel>
										{row.label}
									</TableSortLabel>
								</>)
								}
							</TableCell>
						);
					}, this)}
				</TableRow>
			</TableHead>
		);
	}
}

export default withStyles(styles, { withTheme: true })(MembersTableHead);
