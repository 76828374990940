import React from 'react';
import Step from './Step';
import '../style/toolbarItem.css';
import usertask from '../../../images/workflow/userTask.svg';
import businesstask from '../../../images/workflow/data models.svg';
import systemtask from '../../../images/workflow/page designer.svg';
import sendtask from '../../../images/workflow/forms.svg';
import workflowtask from '../../../images/workflow/workflow.svg';
import datapipelinetask from '../../../images/workflow/Datasources.svg';
export default class DraggableToolbarIcon extends React.Component {
  render() {
    const { dropToolbarIcon, dragToolbarIcon, height, Item, width, itemType, subType } = this.props;
    const margin = Item.defaultProps.style.strokeWidth;
    if (
      subType === 'UserTask' ||
      subType === 'BusinessTask' ||
      subType === 'SystemTask' ||
      subType === 'SendTask' ||
      subType === 'WorkflowTask' ||
      subType === 'DataPipelineTask'
    ) {
      return (
        <div
          draggable
          onDragStart={dragToolbarIcon(Item)}
          onDragEnd={dropToolbarIcon(Item, subType)}
          className="toolbarWrapper"
        >
          {subType === 'UserTask' && (
            <>
              <img src={usertask} />
              <p className="toolbarText">User Task</p>
            </>
          )}
          {subType === 'BusinessTask' && (
            <>
              <img src={businesstask} />
              <p className="toolbarText">Business Task</p>
            </>
          )}
          {subType === 'SystemTask' && (
            <>
              <img src={systemtask} />
              <p className="toolbarText">System Task</p>
            </>
          )}
          {subType === 'SendTask' && (
            <>
              <img src={sendtask} />
              <p className="toolbarText">Send Task</p>
            </>
          )}
          {subType === 'WorkflowTask' && (
            <>
              <img src={workflowtask} />
              <p className="toolbarText">Workflow Task</p>
            </>
          )}
          {subType === 'DataPipelineTask' && (
            <>
              <img src={datapipelinetask} />
              <p className="toolbarText">Datapipeline Task</p>
            </>
          )}

          {/* <p className="toolbarText">{itemType}</p> */}
        </div>
      );
    } else {
      return (
        <div
          draggable
          onDragStart={dragToolbarIcon(Item)}
          onDragEnd={dropToolbarIcon(Item, subType)}
          className="toolbarWrapper"
          style={{ marginLeft: 5 }}
        >
          <svg height={40} width={40}>
            <Item
              x={margin}
              y={margin}
              height={height - 10 - 2 * margin}
              width={width - 10 - 2 * margin}
            />
          </svg>
          <p className="toolbarText toolbarText2">{itemType}</p>
        </div>
      );
    }
  }
}

DraggableToolbarIcon.defaultProps = Step.defaultProps;
