import { ActionTypes } from 'constants/index'

export const setPrevPath = (path) => dispatch => {
	dispatch({ type: ActionTypes.SET_PREV_PATH, path })
}

export const emptyPrevPath = () => dispatch => {
	dispatch({ type: ActionTypes.EMPTY_PREV_PATH })
}

export const getNavigation = () => dispatch => {
	dispatch({ type: ActionTypes.GET_NAVIGATION })
}

export const setNavigation = (navigation) => dispatch => {
	dispatch({ type: ActionTypes.SET_NAVIGATION, navigation })
}

export const resetNavigation = () => dispatch => {
	dispatch({ type: ActionTypes.RESET_NAVIGATION })
}