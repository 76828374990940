import React from 'react'
import {
	Checkbox, TableHead, TableRow, Table, TableCell, TableBody
} from '@material-ui/core';

function dataModalTable(props) {

	const onRowClick = (dataelement, eve) => {
		props.openDataElementDialog(dataelement)
	}

    const { tableDataElements } = props;
    // console.log(props,'---------------------')

    return (
        <Table className="tableg">
			<TableHead>
				<TableCell padding="checkbox" className="relative pl-4 sm:pl-12"></TableCell>
				<TableCell>{"Name"}</TableCell>
				<TableCell>{"Description"}</TableCell>
				<TableCell>{"Type"}</TableCell>
				<TableCell>{"Required"}</TableCell>
				<TableCell>{"Default value"}</TableCell>
				<TableCell>{"Unique"}</TableCell>
				<TableCell>{"Lookup"}</TableCell>
				<TableCell>{"Validation"}</TableCell>
				<TableCell>{"Searcheable"}</TableCell>
			</TableHead>
			<TableBody>
				{/* {form.dataElements && form.dataElements.length > 0 && form.dataElements.map(dataelement => { */}
				{tableDataElements && tableDataElements.length > 0 && tableDataElements.map(dataelement => {
					return (<TableRow
						className="h-64 cursor-pointer"
						hover
						role="checkbox"
						key={dataelement.name}
						aria-checked={props.isSelected(dataelement)}
						tabIndex={-1}
						selected={props.isSelected(dataelement)}
						onClick={e => onRowClick(dataelement, e)}>
						<TableCell className="w-48 pl-4 sm:pl-12" padding="checkbox">
							<Checkbox
								checked={props.isSelected(dataelement)}
								onClick={event => event.stopPropagation()}
								onChange={event => props.handleCheck(event, dataelement)}
							/>
						</TableCell>
						<TableCell>
							{dataelement.name}
							{props.isSelected(dataelement)}
						</TableCell>
						<TableCell className="truncate">{dataelement.description}</TableCell>
						<TableCell>{dataelement.type}</TableCell>
						<TableCell>{dataelement.required ? "Yes" : "No"}</TableCell>
						<TableCell>{dataelement.defaultValue}</TableCell>
						<TableCell>{dataelement.unique ? "Yes" : "No"}</TableCell>
						<TableCell>{dataelement.lookup ? "Yes" : "No"}</TableCell>
						<TableCell>{dataelement.validation}</TableCell>
						<TableCell>{dataelement.searchable ? "Yes" : "No"}</TableCell>
					</TableRow>)
				})}
			</TableBody>
		</Table>
    )
}

export default dataModalTable
