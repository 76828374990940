import login from './login'
import user from './user'
import alerts from './alerts'
import navigation from './navigation'
import dapps from './dapps'
import message from './message'
import entities from './entities'
import dapp from './dapp'
import roles from './roles'
import role from './role'
import members from './members'
import member from './member'
import privileges from './privileges'
import pages from './pages'
import datasources from './datasources'
import dataModels from './dataModels'
import businessRules from './businessRules'
import networks from './networks'
import pipelines from './pipelines'
import notifications from './notifications'
import network from './network'
import pipeline from './pipeline'
import jobs from './jobs'
import workflows from './workflows'
import forms from './forms'
import entity from './entity'
import job from './job'
import dataModel from './dataModel'
import notification from './notification'
import businessRule from './businessRule'
import datasource from './datasource'
import flowchart from "./flowchart"
import datasourceTypes from "./datasourceTypes"
import formula from './formula';
import formulas from './formulas';
import groups from './groups';
import group from './group'
export default {
	login,
	user,
	alerts,
	navigation,
	dapps,
	message,
	entities,
	dapp,
	roles,
	members,
	member,
	role,
	privileges,
	pages,
	datasources,
	dataModels,
	businessRules,
	networks,
	pipelines,
	notifications,
	network,
	pipeline,
	jobs,
	workflows,
	forms,
	entity,
	job,
	dataModel,
	notification,
	businessRule,
	datasource,
	flowchart,
	datasourceTypes,
	formulas,
	formula,
	groups,
	group
}
