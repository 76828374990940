import { ActionTypes } from 'constants/index'
import { navigationConfig, navigationAppAdminConfig } from 'packages/navigationConfig/index'

const initialState = {
	prevPath: '',
	prevSearch: '',
	usePrevPath: false,
	nav: navigationAppAdminConfig
};

const navigation = function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_PREV_PATH: {
			return {
				...state,
				prevPath: action.path.prevPath,
				prevSearch: action.path.prevSearch,
				usePrevPath: true,
			};
		}
		case ActionTypes.EMPTY_PREV_PATH: {
			return {
				...state,
				prevPath: '',
				prevSearch: '',
				usePrevPath: false,
			};
		}
		case ActionTypes.GET_NAVIGATION: {
			return {
				...state
			}
		}
		case ActionTypes.SET_NAVIGATION: {
			return {
				...state,
				nav: action.navigation
			}
		}
		case ActionTypes.RESET_NAVIGATION: {
			return {
				...state,
				nav: navigationAppAdminConfig
			}
		}
		default: {
			return state
		}
	}
};

export default navigation