import React, { Component } from 'react';
import {
	TextField, FormControl, Select,
	MenuItem, FormGroup, FormLabel, Button
} from '@material-ui/core';
import 'styles/page-designer.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import _ from 'modules/lodash';
import { withRouter } from 'react-router-dom';

const BackgroundAdd = (props) => {
	let { classes, values, dataModels } = props;

	console.log("value.dataElementss", values.dataElements);
	console.log("values.background", values.background);

	return (<>
		<FormGroup row className="items-center">
			<FormLabel component="legend">
				Background
            </FormLabel>
		</FormGroup>
		<FormGroup row className="items-center  max-w-full">
			<FormControl variant="outlined" className={classes.formControl}>
				<Select
					value={values.background.selectedBackgroundDataElement}
					onChange={props.onBackgroundChangeHandler}
					inputProps={{
						name: 'selectedBackgroundDataElement',
						id: 'selectedBackgroundDataElement',
					}}>
					<MenuItem value="">
						<em>Select a column</em>
					</MenuItem>
					{values && values.selectedDataElement && values.selectedDataElement.map((item, i) => {
						return <MenuItem key={i} value={item.value} >{item.label}</MenuItem>
					})}
				</Select>
			</FormControl>
			<FormControl variant="outlined" className={classes.formControl}>
				<TextField
					className="mt-8 mb-16"
					label="Expecting value for column"
					id="selectedBackgroundFieldValue"
					name="selectedBackgroundFieldValue"
					value={values.background.selectedBackgroundFieldValue}
					onChange={props.onBackgroundChangeHandler}
					variant="outlined"
					fullWidth
				/>
			</FormControl>
			<FormControl variant="outlined" className={classes.formControl}>
				<TextField
					className="mt-8 mb-16"
					label="Enter background color code"
					id="selectedBackgroundValue"
					name="selectedBackgroundValue"
					value={values.background.selectedBackgroundValue}
					onChange={props.onBackgroundChangeHandler}
					variant="outlined"
					fullWidth
				/>
			</FormControl>
			<Button
				color="secondary"
				variant="outlined"
				className={classes.formControl}
				onClick={() => {
					props.addBackground(values.background);
				}}
			// disabled={!this.canBeSubmitted()}
			>
				Add
            </Button>
		</FormGroup>
	</>)
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}

function mapStateToProps({ pages, dataModels }) {
	return {
		dataModels: dataModels,
		pages: pages.data,
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BackgroundAdd)
)
