import React, { Component } from 'react'
import {
	TextField, Select, MenuItem, FormControl, FormGroup, Checkbox, RadioGroup, FormControlLabel, Radio, FormLabel
} from '@material-ui/core'
import _ from 'lodash'
import ChipSelect from 'components/@custom/ChipSelect'

class BasicInfo extends Component {
	render() {
		let { datasources, classes, workflows } = this.props
		let { form } = this.props.state
		let props = this.props

		return (form && <div className="p-16 sm:p-24 max-w-2xl">
			<form className="form dataModelList" noValidate autoComplete="off" >
				<FormControl>
					<FormLabel variant="h6" className="label-headings">
						{"Name *"}
					</FormLabel>
					<TextField
						className="mt-8 mb-16"
						error={form.name === ''}
						required
						placeholder="Name"
						autoFocus
						id="name"
						name="name"
						value={form.name}
						onChange={props.handleChange}
						variant="outlined"
						color="secondary"
						fullWidth
					/>
				</FormControl>
				<FormControl>
					<FormLabel variant="h6" className="label-headings">
						{"Description"}
					</FormLabel>
					<TextField
						className="mt-8 mb-16"
						id="description"
						name="description"
						onChange={props.handleChange}
						placeholder="Description"
						type="text"
						value={form.description}
						multiline
						rows={2}
						variant="outlined"
						fullWidth
					/>
				</FormControl>
				<FormControl>
					<FormLabel variant="h6" className="label-headings">
						{"Type"}
					</FormLabel>
					<RadioGroup
						aria-label="DataModel Type"
						name="type"
						className={classes.group}
						value={form.type ? form.type : ''}
						onChange={props.handleChange}
						row>
						<FormControlLabel value="entitydatamodel" control={<Radio />} label="Entity Data Model" />
						<FormControlLabel value="networkdatamodel" control={<Radio />} label="Network Data Model" />
					</RadioGroup>
				</FormControl>
				{form.type !== "networkdatamodel" &&
					<FormControl>
						<FormLabel variant="h6" className="label-headings">{"Select a datasource from the list below."}</FormLabel>
						<FormControl fullWidth style={{ marginBottom: 10 }}>
							<Select
								value={form.datasourceId ? form.datasourceId : ''}
								onChange={props.handleDatasourceChange}
								inputProps={{
									name: 'datasourceId',
									id: 'datasourceId',
								}}>
								<MenuItem value={form.datasourceId === undefined ? undefined : ""} >
									<em>{"None"}</em>
								</MenuItem>
								{datasources && datasources.map(datasource => {
									return (
										<MenuItem key={datasource._id} value={datasource._id}>
											{datasource.name}
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</FormControl>
				}
				{form.type !== "networkdatamodel" && form.datasourceId && props.datasource && props.datasource.dsType !== "FILE" &&
					// <FormControl>
					// 	<FormLabel variant="h6" className="label-headings">{"Select a collection from the list below."}</FormLabel>
					// 	<FormControl fullWidth style={{ marginBottom: 10 }}>
					// 		<Select
					// 			value={form.collectionName ? form.collectionName : ""}
					// 			onChange={props.handleCollectionChange}
					// 			inputProps={{
					// 				name: 'collectionName',
					// 				id: 'collectionName',
					// 			}}>
					// 			<MenuItem value="">
					// 				<em>{"None"}</em>
					// 			</MenuItem>
					// 			{this.props.collections
					// 				&& this.props.collections.map(collectionName => (
					// 					<MenuItem key={collectionName} value={collectionName}>
					// 						{collectionName}
					// 					</MenuItem>
					// 				))}
					// 		</Select>
					// 	</FormControl>
					// </FormControl>
					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Select a collection from the list below"}
						</FormLabel>
						<ChipSelect
							className="mt-8 mb-24"
							value={form.collectionName ? {
								value: form.collectionName,
								label: form.collectionName
							} : ""}
							onChange={(value) => props.handleChipChangeData(value, 'collectionName')}
							placeholder="Select a collection"
							textFieldProps={{
								InputLabelProps: {
									shrink: true
								},
								variant: 'outlined'
							}}
							options={props.collections?.map(item => ({
								value: item,
								label: item
							}))}
						/>
					</FormControl>
				}
				{/*form.type !== "networkdatamodel" &&
					<FormControl>
						<FormLabel variant="h6" className="label-headings">{"Select a workflow."}</FormLabel>
						<FormControl fullWidth style={{ marginBottom: 10 }}>
							<Select
								value={form.workflowId}
								onChange={props.handleWorkflowChange}
								inputProps={{
									name: 'workflowId',
									id: 'workflowId',
								}} >
								<MenuItem value={form.workflowId === undefined ? undefined : ""}>
									<em>{"None"}</em>
								</MenuItem>
								{workflows && workflows.data.map(workflow => (
									<MenuItem key={workflow._id} value={workflow._id}>
										{workflow.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</FormControl>
								*/}
				<FormControl>
					<FormLabel variant="h6" className="label-headings">{"Accept fields not listed in this model"}</FormLabel>
					<FormControlLabel
						control={
							<Checkbox
								checked={form.allowExternalElements ? form.allowExternalElements : false}
								onChange={props.handleChange}
								value="lookup"
								color="primary"
								name="allowExternalElements"
							/>
						}
						label="Allow external elements"
					/>
				</FormControl>
				<FormControl>
					<FormLabel variant="h6" className="label-headings">{"Allowable data exchange formats"}</FormLabel>
					<FormGroup row>
						<FormControlLabel
							control={
								<Checkbox
									checked={form.JSONDataFormat ? form.JSONDataFormat : false}
									onChange={props.handleChange}
									value="true"
									name="JSONDataFormat"
								/>
							}
							label="JSON"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={form.XMLDataFormat ? form.XMLDataFormat : false}
									onChange={props.handleChange}
									name="XMLDataFormat"
									color="primary"
								/>
							}
							label="XML"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={form.CSVDataFormat ? form.CSVDataFormat : false}
									onChange={props.handleChange}
									color="primary"
									name="CSVDataFormat"
								/>
							}
							label="CSV"
						/>
					</FormGroup>
				</FormControl>
			</form>
		</div>)
	}
}

export default BasicInfo