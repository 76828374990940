import { request } from 'modules/client'
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export class EntityService {
	static getEntities({ appId }) {

		const uri = API_BASE_URL + "/entities"
		return request(uri, {
			method: 'GET',
			headers: {
				appId
			}
		});
	}
	static getEntity(data) {
		console.log("services getEntity", data);
		const uri = API_BASE_URL + "/entities/" + data.id;
		return request(uri, {
			method: 'GET',
			headers: {
				appId: data.appId
			}
		})
	}
	static deleteEntity(data) {
		const uri = API_BASE_URL + "/entities/delete/" + data.entity._id
		return request(uri, {
			method: 'GET',
			headers: { appId: data.appId }
		})
	}
	static saveEntity(data) {
		const uri = API_BASE_URL + "/entities/add"
		return request(uri, {
			method: 'POST',
			payload: data.entity,
			headers: { appId: data.appId }
		})
	}

	static updateEntity(data) {
		const uri = API_BASE_URL + "/entities/update/" + data.entity._id
		return request(uri, {
			method: 'PUT',
			payload: data.entity,
			headers: { appId: data.appId }
		})
	}
}