import { ActionTypes } from 'constants/index'

const initialState = {
	data: [],
	searchText: "",
	error: "",
	loading: false
}

const datasourceTypesReducer = function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_DATASOURCE_TYPES: {
			return {
				...state,
				loading: true,
			}
		}
		case ActionTypes.GET_DATASOURCE_TYPES_SUCCESS: {
			return {
				...state,
				data: action.payload
			}
		}
		case ActionTypes.GET_DATASOURCE_TYPES_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error
			}
		}
		default: {
			return state;
		}
	}
};

export default datasourceTypesReducer;
