import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import elements1 from '../data/elements1'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
}))

const Elements = (props) => {
	const classes = useStyles()
	const [expanded, setExpanded] = React.useState('basicElements')

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	}

	return (<>
		<div className="form-elements">
			<div className="form-element-header">{"Elements"}</div>
			<div className="form-elements-pane">
				{/* {elements1.map((ele, i) => {
					return (<div className={classes.root}>
						<Accordion expanded={expanded === 'panel1'}
							onChange={handleChange('panel1')}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1bh-content"
								id="panel1bh-header">
								<Typography className={classes.heading}>{ele.label}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								{ele.childrens.map((e, j) => {
									return (<div id={e.id} draggable="true" key={j}
										className="spacing-form-element"
										onDragStart={(e) => { props.dragStart(e) }}
										onDragOver={(event) => event.preventDefault()}>
										<div className="form-element">
											<div style={{ position: "relative" }}>
												{Array.isArray(e.icon) && e.icon.length > -1 ?
													e.icon.map((icon, k) => {
														if (k === 0)
															return <img src={icon} key={k} index={k} style={{
																position: "initial"
															}} />
														else
															return <img src={icon} key={k} index={k} style={{
																position: "absolute",
																left: "5px",
																top: "5px"
															}} />
													})
													: <img src={e.icon} style={{
														position: "initial",
														left: "0px",
														top: "0px"
													}} />
												}
											</div>
											<span style={{ marginLeft: "5px" }}>{e.label}</span>
										</div>
									</div>)
								})}
							</AccordionDetails>
						</Accordion></div>)
				})} */}
				{elements1.map((ele, i) => {
					return (<>
						<Accordion expanded={expanded === ele.id} key={i}
							onChange={handleChange(ele.id)}>
							<AccordionSummary style={{ marginTop: '5px', marginBottom: (i === elements1.length - 1 ? '1px' : '5px') }}
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1bh-content"
								id="panel1bh-header">
								<Typography className={classes.heading} style={{ whiteSpace: 'nowrap' }}>{ele.label}</Typography>
							</AccordionSummary>
						</Accordion>
						{expanded === ele.id && ele.childrens.map((e, j) => {
							return (<div id={e.id} draggable="true" key={j}
								className="spacing-form-element"
								onDragStart={(e) => { props.dragStart(e) }}
								onDragOver={(event) => event.preventDefault()}>
								<div className="form-element">
									<span style={{ position: "relative" }}>
										{Array.isArray(e.icon) && e.icon.length > -1 ?
											e.icon.map((icon, k) => {
												if (k === 0)
													return <img src={icon} key={k} index={k} style={{
														position: "initial"
													}} />
												else
													return <img src={icon} key={k} index={k} style={{
														position: "absolute",
														left: "5px",
														top: "5px"
													}} />
											})
											: <img src={e.icon} style={{
												position: "initial",
												left: "0px",
												top: "0px"
											}} />
										}
									</span>
									<span style={{ marginLeft: "5px" }}>{e.label}</span>
								</div>
							</div>)
						})}
					</>)
				})}
			</div>
		</div>
	</>)
}

export default Elements