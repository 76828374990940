import React from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, forms, dataModels, pages, datasources, datasource, roles, dapp, members, notifications }) => {
		return {
			navigation,
			forms: forms.form,

			user,
			dataModal: dataModels.dataModel,
			dataModels: dataModels.data,
			entryForm: JSON.parse(JSON.stringify(forms.entryForm)),
			selectedForm: JSON.parse(JSON.stringify(forms.entryForm)),
			// selectedForm: forms.selectedForm, //from Entry Form
			savedTemplates: JSON.parse(JSON.stringify(forms.allTemplates)),
			saveFormStatus: JSON.parse(JSON.stringify(forms.formBuilder)),
			formBuilder: JSON.parse(JSON.stringify(forms.formBuilder)),
			page: pages.page,
			// datasource: datasources.datasource,
			datasource,
			roles: roles,
			dapp: dapp.data,
			saveSuccess: forms.saveSuccess,


			draggedElement: forms.formElements,
			// selectedForm: forms.selectedForm, //For Builder
			selectedTemplate: forms.selectedTemplate,


			members: members.data,
			savedForms: JSON.parse(JSON.stringify(forms.formBuilder)) //For FormElements
		}
	}

	const mapDispatchToProps = {
		getForm: Actions.getEntryForm,
		updateForm: Actions.updateEntryForm,
		newEntryForm: Actions.newEntryForm,

		editEntryForm: Actions.editForm,
		saveForm: Actions.saveForm,
		saveEntryForm: Actions.saveEntryForm,
		saveDataModel: Actions.saveDataModel,
		saveDataModelFromForm: Actions.saveDataModelFromForm,
		updateDataModel: Actions.updateDataModel,
		updateEntryForm: Actions.updateEntryForm,
		savePage: Actions.savePage,
		getEntryForm: Actions.getEntryForm,
		selectTemplate: Actions.selectTemplate,
		clearSelectedForm: Actions.clearSelectedForm,
		clearSelectedTemplate: Actions.clearSelectedTemplate,
		getTemplates: Actions.getTemplates,
		getPage: Actions.getPage,
		updatePage: Actions.updatePage,
		getDatasourceByName: Actions.getDatasourceByName,
		getRoles: Actions.getRoles,
		getDapp: Actions.getDapp,

		clearDraggedElement: Actions.clearDraggedElement,
		draggedNewInputType: Actions.draggedNewInputType,
		clrMessage: Actions.clearSaveMessage,

		draggedElementHandler: Actions.draggedElement,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert,

        getDataModels: Actions.getDataModels,
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux