import { ActionTypes } from 'constants/index'

/**
 * Login form submit with email & password
 */
export const submitLogin = ({ email, password }) => dispatch => {
	dispatch({ type: ActionTypes.USER_LOGIN, payload: { email, password } })
}

/**
 * Login with token
 */
export const submitLoginwithToken = () => dispatch => {
	dispatch({ type: ActionTypes.USER_LOGIN_SUCCESS })
}

/**
 * Password reset request
 */
export const resetPassword = (payload) => dispatch => {
	dispatch({ type: ActionTypes.RESET_PASSWORD, payload })
}

export const isUserExist = (data) => dispatch => {
    dispatch({ type: ActionTypes.IS_USER_EXIST, payload: data });
};