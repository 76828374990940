import React from 'react'
import {
	FormControl, InputLabel, Checkbox, FormControlLabel, FormLabel, TextField, Button, Chip,
	Paper, Grid, Link, Typography, makeStyles, Box, AppBar, Tabs, Tab, Divider, Select, Input, MenuItem
} from '@material-ui/core'
import { TabPanel } from '../@tabpanel/tabpanel';
import Flowchart from './flowchart'
import ChipSelect from 'components/@custom/ChipSelect'

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	}
}));

let actions = [
	{ value: 'Done Updates', label: 'Done Updates' },
	{ value: 'Freeze Plan', label: 'Freeze Plan' }]

let formTypes = [{
	value: 'bopvolumes',
	label: 'BOP Volumes'
}, {
	value: 'rlppermsu',
	label: 'RLP per MSU'
}, {
	value: 'pyrlp',
	label: 'Past Year Retailing'
}, {
	value: 'pyrlppermsu',
	label: 'Past Year RLP/MSU'
}, {
	value: 'rlpytdact',
	label: 'YTD Actual Retailing'
}, {
	value: 'rlpsalience',
	label: 'Channel Retailing Salience'
}, {
	value: 'corpbud',
	label: 'Corp Bud'
}, {
	value: 'mrecomm',
	label: 'MR & Ecomm'
}, {
	value: 'accrualshc',
	label: 'Accruals & HC'
}, {
	value: 'f1inputs',
	label: 'F1 Inputs'
}, {
	value: 'tpr',
	label: 'Trade Price Reduction'
}, {
	value: 'spConsumerPromotions',
	label: 'Consumer Promotions'
}, {
	value: 'spTrialDiscounts',
	label: 'Trial Discounts'
}, {
	value: 'spDisplays',
	label: 'Displays'
}, {
	value: 'spConversionFunds',
	label: 'Conversion Funds'
}, {
	value: 'spMRandEcommTFIs',
	label: 'MR and Ecomm TFIs'
}]

const View = (props) => {
	const { form } = props.state;
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<React.Fragment>
			<Box className="quickwizard-page">
				<Grid container>
					<Grid item xs={12} md={6}>
						<div className="backbtn">
							<div className="backbtn ptl-20">
								<Box component="h3" className="companyheadingtext">
									<Typography variant="h4">{"Flowchart"}</Typography>
								</Box>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} md={6} className="savebtn-sec">
						<Button
							variant="contained" color="primary"
							className="addnewentrybtn mb5 whitespace-no-wrap"
							onClick={() => props.saveWorkflow()}>
							{props.workflowId ? "Update" : "Save"}
						</Button>
					</Grid>
				</Grid>
				<Box className="pxy20">
					<div className={classes.root} >
						<Box px="1.5rem">
							<AppBar position="static" className="bg-white">
								<Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className="quicktabs-sec">
									<Tab label="Basic Info" {...a11yProps(0)} />
									<Tab label="Flowchart" {...a11yProps(1)} />
								</Tabs>
								<Divider />
							</AppBar>
							<TabPanel value={value} index={0}>
								<form className="form" noValidate autoComplete="off" >
									<FormControl>
										<FormLabel variant="h6" className="label-headings">
											Name *
							</FormLabel>
										<TextField
											className="form-control"
											// error={form.name === ''}
											autoFocus
											id="name"
											name="name"
											value={form.name}
											onChange={props.handleChange}
											variant="outlined"
											color="secondary"
											placeholder="Enter Name"
											required
											fullWidth
										/>
									</FormControl>

									<FormControl>
										<FormLabel variant="h6" className="label-headings">
											Description
							</FormLabel>
										<TextField
											className="form-control"
											id="description"
											name="description"
											// multiline
											rows={2}
											value={form.description}
											onChange={props.handleChange}
											variant="outlined"
											color="secondary"
											placeholder="Enter Description"
											required
											fullWidth
										/>
									</FormControl>
									<FormControl>
										<FormLabel variant="h6" className="label-headings">
											{"Form"}
										</FormLabel>
										<ChipSelect
											className="mt-8 mb-24"
											value={form.formType}
											onChange={(value) => props.handleChipChange(value, 'formType')}
											placeholder="Form"
											textFieldProps={{
												InputLabelProps: {
													shrink: true
												},
												variant: 'outlined'
											}}
											options={formTypes}
											isMulti
										/>
									</FormControl>
									<FormControl>
										<FormLabel variant="h6" className="label-headings">
											{"Action"}
										</FormLabel>
										<ChipSelect
											className="mt-8 mb-24"
											value={form.action}
											onChange={(value) => props.handleChipChange(value, 'action')}
											placeholder="Form"
											textFieldProps={{
												InputLabelProps: {
													shrink: true
												},
												variant: 'outlined'
											}}
											options={actions}
										/>
									</FormControl>
								</form>

								{/*Updated Code  end*/}
							</TabPanel>
							<TabPanel value={value} index={1}>
								<Flowchart editable diagramChange={props.diagramChange} />
							</TabPanel>
						</Box>
					</div>
				</Box>
			</Box>
		</React.Fragment>
	)
}

export default View