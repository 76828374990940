import React from 'react';
import bindme from 'bindme';
import DeleteIcon from './delete.js';
import ArrowIcon from './right-arrow.js';
import '../style/EditElement.css';
import StartIcon from './start-button.js';
import UserTask from './UserTask';
import BusinessTask from './BusinessTask';
import SystemTask from './SystemTask';
import SendTask from './SendTask';
import WorkflowTask from './WorkflowTask';
import DatapipelineTask from './DatapipelineTask';

export default class SuggestionRect extends React.Component {
  constructor(props) {
    bindme(super(props), 'onMouseLeave', 'onMouseOver');
    this.state = {
      focused: false,
    };
  }

  onMouseLeave(event) {
    this.props.setMouseOverConnection(event, false);
    this.setState({ focused: false });
    // this.props.onMouseLeave();
  }

  onMouseOver(event) {
    this.props.setMouseOverConnection(event, true);
    this.setState({ focused: true });
  }

  render() {
    const { id, x, y, height, width, type } = this.props;

    const halfHeight = height / 2;
    const halfWidth = width / 2;

    if (type === 'circle') {
      return (
        <>
          <rect
            // onMouseDown={createArrow}
            x={x + 35}
            y={y + 10}
            fill="white"
            height={60}
            // width={100}
            width={155}
            stroke="#000"
            strokeWidth={1}
            rx="6"
            strokeDasharray="5,5"
          ></rect>
          <StartIcon
            x="50"
            y="5"
            height="22"
            width="22"
            onClick={() => this.props.onClickSugessionElement('circle')}
            style={{ zIndex: 2000 }}
          />
          {/* <circle
            onClick={() => this.props.onClickSugessionElement('circle')}
            stroke="#000"
            strokeWidth={1}
            fill="white"
            x={x + width}
            y={y}
            r={10}
            cx={x + 15}
            cy={y + 25}
          /> */}
          <circle
            onClick={() => this.props.onClickSugessionElement('twocircle')}
            stroke="#000"
            strokeWidth={3}
            fill="white"
            x={x + width}
            y={y}
            r={9}
            cx={x + 80}
            cy={y + 55}
          />
          {/* <circle
            onClick={() => this.props.onClickSugessionElement('twocircle')}
            stroke="#000"
            strokeWidth={1}
            fill="white"
            x={x + width}
            y={y}
            r={8}
            cx={x + 40}
            cy={y + 55}
          /> */}
          <rect
            // onMouseDown={createArrow}
            onClick={() => this.props.onClickSugessionElement('rect')}
            x={x + 43}
            y={y + 45}
            fill="white"
            height={20}
            width={20}
            stroke="#000"
            strokeWidth={1}
            rx="4"
          ></rect>
          <path
            onClick={() => this.props.onClickSugessionElement('diamond')}
            fill="white"
            height={30}
            width={30}
            stroke="#000"
            strokeWidth={1}
            d="M75,15 L87,4 L99,15 L87,26 Z"
          />
          {/* <ellipse
              onClick={() => this.props.onClickSugessionElement('ellipse')}
              cx="151"
              cy="15"
              rx="12"
              ry="8"
              fill="white"
              stroke="#000"
              strokeWidth={1}
            /> */}

          {/* <ArrowIcon x="150" y="35" onClick={() => this.props.onClickSugessionElement('flow')} /> */}
          <ArrowIcon x="102" y="5" onClick={() => this.props.onClickSugessionElement('flow')} />
          <DeleteIcon
            x={x + 95}
            y={y + 46}
            onClick={() => this.props.onClickSugessionElement('delete')}
          />
          <UserTask
            x={x + 110}
            y={y + 5}
            //  onClick={() => console.log('Usertask')}
            onClick={() => this.props.onClickSugessionElement('usertask')}
          />
          <BusinessTask
            x={x + 140}
            y={y + 14}
            onClick={() => this.props.onClickSugessionElement('businesstask')}
          />
          <SystemTask
            x={x + 160}
            y={y + 10}
            onClick={() => this.props.onClickSugessionElement('systemtask')}
          />
          <SendTask
            x={x + 112}
            y={y + 40}
            onClick={() => this.props.onClickSugessionElement('sendtask')}
          />
          <WorkflowTask
            x={x + 138}
            y={y + 42}
            // onClick={() => this.props.onClickSugessionElement('workflowtask')}
            onClick={() => this.props.onClickSugessionElement('workflowtask')}
          />
          <DatapipelineTask
            x={x + 162}
            y={y + 42}
            //  onClick={() => this.props.onClickSugessionElement('datapipelinetask')}
            onClick={() => this.props.onClickSugessionElement('datapipelinetask')}
          />
        </>
      );
    } else if (type === 'twocircle') {
      return (
        <>
          {/* <img src={deleteIcon} alt="delete-icon" className="icon"></img> */}
          <rect
            // onMouseDown={createArrow}
            x={x}
            y={y + 10}
            fill="white"
            height={60}
            // width={100}
            width={150}
            stroke="#000"
            strokeWidth={1}
            rx="6"
            strokeDasharray="5,5"
          ></rect>

          {/* <circle
            onClick={() => this.props.onClickSugessionElement('circle')}
            stroke="#000"
            strokeWidth={1}
            fill="white"
            x={x + width}
            y={y}
            r={10}
            cx={x + 15}
            cy={y + 25}
          /> */}
          <StartIcon
            x="85"
            y="5"
            height="22"
            width="22"
            onClick={() => this.props.onClickSugessionElement('circle')}
          />
          <circle
            onClick={() => this.props.onClickSugessionElement('twocircle')}
            stroke="#000"
            strokeWidth={3}
            fill="white"
            x={x + width}
            y={y}
            r={9}
            cx={x + 40}
            cy={y + 55}
          />
          {/* <circle
            onClick={() => this.props.onClickSugessionElement('twocircle')}
            stroke="#000"
            strokeWidth={1}
            fill="white"
            x={x + width}
            y={y}
            r={8}
            cx={x + 40}
            cy={y + 55}
          /> */}
          <rect
            // onMouseDown={createArrow}
            onClick={() => this.props.onClickSugessionElement('rect')}
            x={x + 6}
            y={y + 45}
            fill="white"
            height={20}
            width={20}
            stroke="#000"
            strokeWidth={1}
            rx="4"
          ></rect>
          <path
            onClick={() => this.props.onClickSugessionElement('diamond')}
            fill="white"
            height={30}
            width={30}
            stroke="#000"
            strokeWidth={1}
            d="M110,15 L122,4 L134,15 L122,26 Z"
          />
          {/* <ellipse
              onClick={() => this.props.onClickSugessionElement('ellipse')}
              cx="151"
              cy="15"
              rx="12"
              ry="8"
              fill="white"
              stroke="#000"
              strokeWidth={1}
            /> */}

          {/* <ArrowIcon x="150" y="35" onClick={() => this.props.onClickSugessionElement('flow')} /> */}
          <ArrowIcon x="137" y="5" onClick={() => this.props.onClickSugessionElement('flow')} />
          <DeleteIcon
            x={x + 52}
            y={y + 46}
            onClick={() => this.props.onClickSugessionElement('delete')}
          />
          <UserTask
            x={x + 70}
            y={y + 5}
            onClick={() => this.props.onClickSugessionElement('usertask')}
          />
          <BusinessTask
            x={x + 100}
            y={y + 14}
            onClick={() => this.props.onClickSugessionElement('businesstask')}
          />
          <SystemTask
            x={x + 120}
            y={y + 10}
            onClick={() => this.props.onClickSugessionElement('systemtask')}
          />
          <SendTask
            x={x + 70}
            y={y + 40}
            onClick={() => this.props.onClickSugessionElement('sendtask')}
          />
          <WorkflowTask
            x={x + 95}
            y={y + 42}
            onClick={() => this.props.onClickSugessionElement('workflowtask')}
          />
          <DatapipelineTask
            x={x + 120}
            y={y + 42}
            onClick={() => this.props.onClickSugessionElement('datapipelinetask')}
          />
        </>
      );
    } else if (type === 'decision') {
      return (
        <>
          <rect
            // onMouseDown={createArrow}
            x={x}
            y={y + 10}
            fill="white"
            height={60}
            width={150}
            stroke="#000"
            strokeWidth={1}
            rx="6"
            strokeDasharray="5,5"
          ></rect>

          {/* <circle
            onClick={() => this.props.onClickSugessionElement('circle')}
            stroke="#000"
            strokeWidth={1}
            fill="white"
            x={x + width}
            y={y}
            r={10}
            cx={x + 15}
            cy={y + 25}
          /> */}
          <StartIcon
            x="75"
            y="5"
            height="22"
            width="22"
            onClick={() => this.props.onClickSugessionElement('circle')}
          />
          <circle
            onClick={() => this.props.onClickSugessionElement('twocircle')}
            stroke="#000"
            strokeWidth={3}
            fill="white"
            x={x + width}
            y={y}
            r={9}
            cx={x + 40}
            cy={y + 55}
          />
          {/* <circle
            onClick={() => this.props.onClickSugessionElement('twocircle')}
            stroke="#000"
            strokeWidth={1}
            fill="white"
            x={x + width}
            y={y}
            r={8}
            cx={x + 40}
            cy={y + 55}
          /> */}
          <rect
            // onMouseDown={createArrow}
            onClick={() => this.props.onClickSugessionElement('rect')}
            x={x + 6}
            y={y + 45}
            fill="white"
            height={20}
            width={20}
            stroke="#000"
            strokeWidth={1}
            rx="4"
          ></rect>
          <path
            onClick={() => this.props.onClickSugessionElement('diamond')}
            fill="white"
            height={30}
            width={30}
            stroke="#000"
            strokeWidth={1}
            d="M100,15 L112,4 L124,15 L112,26 Z"
          />
          {/* <ellipse
              onClick={() => this.props.onClickSugessionElement('ellipse')}
              cx="151"
              cy="15"
              rx="12"
              ry="8"
              fill="white"
              stroke="#000"
              strokeWidth={1}
            /> */}

          {/* <ArrowIcon x="150" y="35" onClick={() => this.props.onClickSugessionElement('flow')} /> */}
          <ArrowIcon x="127" y="5" onClick={() => this.props.onClickSugessionElement('flow')} />
          <DeleteIcon
            x={x + 52}
            y={y + 46}
            onClick={() => this.props.onClickSugessionElement('delete')}
          />
          <UserTask
            x={x + 70}
            y={y + 5}
            onClick={() => this.props.onClickSugessionElement('usertask')}
          />
          <BusinessTask
            x={x + 100}
            y={y + 14}
            onClick={() => this.props.onClickSugessionElement('businesstask')}
          />
          <SystemTask
            x={x + 120}
            y={y + 10}
            onClick={() => this.props.onClickSugessionElement('systemtask')}
          />
          <SendTask
            x={x + 70}
            y={y + 40}
            onClick={() => this.props.onClickSugessionElement('sendtask')}
          />
          <WorkflowTask
            x={x + 95}
            y={y + 42}
            onClick={() => this.props.onClickSugessionElement('workflowtask')}
          />
          <DatapipelineTask
            x={x + 120}
            y={y + 42}
            onClick={() => this.props.onClickSugessionElement('datapipelinetask')}
          />
        </>
      );
    } else if (type === 'pool') {
      return (
        <>
          <DeleteIcon
            x={x + 20}
            y={y + 9}
            onClick={() => this.props.onClickSugessionElement('delete')}
          />
        </>
      );
    } else if (type === 'line') {
      return (
        <>
          <DeleteIcon
            x={x - 30}
            y={y}
            height={14}
            width={14}
            onClick={() => this.props.onClickSugessionElement('deleteline')}
          />
        </>
      );
    } else {
      return (
        <>
          <rect
            x={x}
            y={y + 10}
            fill="white"
            height={60}
            width={155}
            stroke="#000"
            strokeWidth={1}
            rx="6"
            strokeDasharray="5,5"
          ></rect>

          <StartIcon
            x="235"
            y="5"
            height="22"
            width="22"
            onClick={() => this.props.onClickSugessionElement('circle')}
          />
          <circle
            onClick={() => this.props.onClickSugessionElement('twocircle')}
            stroke="#000"
            strokeWidth={3}
            fill="white"
            x={x + width}
            y={y}
            r={9}
            cx={x + 40}
            cy={y + 55}
          />
          <rect
            onClick={() => this.props.onClickSugessionElement('rect')}
            x={x + 6}
            y={y + 45}
            fill="white"
            height={20}
            width={20}
            stroke="#000"
            strokeWidth={1}
            rx="4"
          ></rect>
          <path
            onClick={() => this.props.onClickSugessionElement('diamond')}
            fill="white"
            height={30}
            width={30}
            stroke="#000"
            strokeWidth={1}
            d={`M${x + 30},${y + 25} L${x + 40},${y + 15} L${x + 50},${y + 25} L${x + 40},${y +
              35} Z`}
          />
          <ArrowIcon x="285" y="5" onClick={() => this.props.onClickSugessionElement('flow')} />
          <DeleteIcon
            x={x + 52}
            y={y + 46}
            onClick={() => this.props.onClickSugessionElement('delete')}
          />
          <UserTask
            x={x + 70}
            y={y + 5}
            onClick={() => this.props.onClickSugessionElement('usertask')}
          />
          <BusinessTask
            x={x + 100}
            y={y + 14}
            onClick={() => this.props.onClickSugessionElement('businesstask')}
          />
          <SystemTask
            x={x + 120}
            y={y + 10}
            onClick={() => this.props.onClickSugessionElement('systemtask')}
          />
          <SendTask
            x={x + 70}
            y={y + 40}
            onClick={() => this.props.onClickSugessionElement('sendtask')}
          />
          <WorkflowTask
            x={x + 95}
            y={y + 42}
            onClick={() => this.props.onClickSugessionElement('workflowtask')}
          />
          <DatapipelineTask
            x={x + 120}
            y={y + 42}
            onClick={() => this.props.onClickSugessionElement('datapipelinetask')}
          />
          {/* <DeleteIcon
            x={x + 145}
            y={y + 16}
            onClick={() => this.props.onClickSugessionElement('deleteline')}
          /> */}
        </>
      );
    }
  }
}

SuggestionRect.defaultProps = {
  r: 5,
};
