import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { withRouter } from 'react-router-dom';
import View from './view'
import Redux from './redux'
import { updateTableData, setDatafromApi } from 'packages/common'
import sharedJSON from 'packages/sharedJSON'
import {
	getFilteredArray,
	handleSelectedRow,
	isRowselected,
	requestSort,
	handleTableSelectAllClick,
	getTableRowStyles
} from 'modules/common';
import ConfirmDialog from '../confirmDialog'

const Container = (props) => {
	const [state, setState] = useState({
		...sharedJSON
	})
	const [open, setOpen] = React.useState(false);
	const [datamodel, setDatamodel] = React.useState({});

	const handleChangePage = (event, page) => {
		setState({ ...state, page: page });
	};

	const handleChangeRowsPerPage = event => {
		setState({ ...state, rowsPerPage: event.target.value, page: 0 });
	};

	const onRowClick = (rowData, rowMeta) => {
		console.log('onRowClick');
	}

	const searchHandler = (e) => {
		setState({ ...state, searchText: e ? e.target.value : "" });
	}

	const handleInputChange = (event) => {
		let obj = state.filterTableData;
		let list = obj.columns;
		list.map((item, index) => {
			if (item.name === event.target.value) list[index].checked = event.target.checked
		})
		obj.columns = [...list]
		setState({ ...state, filterTableData: obj });
		setState({ ...updateTableData(state) });
	}

	const resetFilters = (event) => {
		setState({ ...state, filterList: [] });
	}

	const handleDropdownChange = (event, index, column) => {
		const value = event.target.value === "All" ? [] : [event.target.value];
		// filterUpdate(index, value, column, 'dropdown');
		let list = state.filterList;
		list[index] = event.target.value;
		setState({ ...state, filterList: [...list] });
		setState({ ...updateTableData(state) });
	};

	const handleRequestSort = (event, property) => {
		let obj = requestSort(event, property, state);
		setState({ ...state, ...obj });
	};

	const handleSelectAllClick = event => {
		setState({ ...state, selected: handleTableSelectAllClick(event, state.filterTableData.datasets) });
	};


	const tableCrudOperations = (item, type) => {
		if (type === "edit") {
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/datamodels/${item._id}` });
		}
		if (type === "delete") {
			setDatamodel(item);
			setOpen(true);
		}
	}

	const handleAddRoleClick = () => {
		props.history.push({ pathname: `/builder/${props.match.params.dappId}/datamodels/new` });
	}

	const handleCheck = (event, id) => {
		const { selected } = state;
		let newSelected = handleSelectedRow(event, id, selected)
		setState({ ...state, selected: newSelected });
	};

	useEffect(() => {
		if (props.dataModels.deleteSuccess) {
			props.showAlert('Datamodel deleted successfully', { id: "datamodel", variant: 'success', icon: 'bell' });
		}
	}, [props.dataModels.deleteSuccess]);

	useEffect(() => {
		setState({ ...updateTableData(state) });
	}, [state.searchText, state.filterList]);


	useEffect(() => {
		const params = props.match.params;
		const { dappId } = params;
		props.getDataModels(dappId);
	}, []);

	useEffect(() => {
		let dArray = props.dataModels.data.map((item) => {
			let obj = { ...item }
			if (obj.type === "networkdatamodel") {
				obj.type = "Network Data Model";
			} else {
				obj.type = "Entity Data Model"
			}
			return obj;
		})
		const rows = [
			{
				id: 'name',
				numeric: false,
				disablePadding: false,
				label: 'Name',
				sort: true
			},
			{
				id: 'description',
				numeric: false,
				disablePadding: false,
				label: 'Description',
				sort: true
			},
			{
				id: 'type',
				numeric: false,
				disablePadding: false,
				label: 'Type',
				sort: true
			}
		];
		let keysArr = ['_id', 'orgId', 'refDataModels', 'appId', '__v', 'datasourceId', 'collectionName', 'allowExternalElements', 'dataFormat', 'dataElements'];
		setState({ ...setDatafromApi(dArray, keysArr, state, rows) });
	}, [props.dataModels.data]);

	return (<>
		<View
			{...props}
			state={state}
			handleChangePage={handleChangePage}
			handleChangeRowsPerPage={handleChangeRowsPerPage}
			onRowClick={onRowClick}
			searchHandler={searchHandler}
			handleCheck={handleCheck}
			handleInputChange={handleInputChange}
			resetFilters={resetFilters}
			handleDropdownChange={handleDropdownChange}
			handleRequestSort={handleRequestSort}
			handleSelectAllClick={handleSelectAllClick}
			tableCrudOperations={tableCrudOperations}
			handleAddDatasourceClick={handleAddRoleClick}
		/>
		<ConfirmDialog
			title="Delete"
			open={open}
			setOpen={setOpen}
			onConfirm={() => props.deleteDatamodel({ _id: datamodel._id, appId: props.match.params.dappId })}
		>
			datamodel {datamodel.name}
		</ConfirmDialog>
	</>)
}

export default hot(withRouter(Redux(Container)))