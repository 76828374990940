import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './view'
import Redux from './redux'
import { withRouter } from 'react-router'

const SideNavContainer = (props) => {

	useEffect(() => {
	}, [])

	useEffect(() => {
	}, [props.navigation])

	return (
		<View
			{...props}
		/>
	)
}

export default hot(withRouter(Redux(SideNavContainer)))