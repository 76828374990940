import React, { useState, useEffect } from 'react';
import {
	withStyles, Button, Tab, Tabs, TableHead, TableRow, TextField, InputAdornment, Icon, Select,
	MenuItem, Typography, FormControl, FormGroup, Checkbox, RadioGroup, FormControlLabel, Radio, FormLabel,
	Table, TableCell, TableBody, Input, Chip,
} from '@material-ui/core';
import Privileges from './Privileges/Privileges'
import PrivilegesTable from './Privileges/PrivilegesTable'

export default function RoleDetails(props) {
	const { form } = props.state;
	const { classes, error } = props;

	return (
		form && (
			<div className="p-16 sm:p-24 max-w-2xl">
				<form className="form" noValidate autoComplete="off">
					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{'Name *'}
						</FormLabel>
						<TextField
							className="form-control"
							error={form.name === ''}
							id="name"
							name="name"
							value={form.name ? form.name : ''}
							onChange={props.handleChange}
							variant="outlined"
							color="secondary"
							placeholder="Enter Name"
							required
							fullWidth
							helperText={error.name && error.name}
						/>
					</FormControl>
					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{'Description'}
						</FormLabel>
						<TextField
							className="form-control"
							id="description"
							name="description"
							value={form.description ? form.description : ''}
							onChange={props.handleChange}
							variant="outlined"
							placeholder="Enter Description"
							fullWidth
						/>
					</FormControl>
					<FormLabel variant="h6" className="label-headings">
						{'Privileges'}
					</FormLabel>
					<FormControl fullWidth style={{ marginBottom: 10 }}>
						{/*<Select name="privileges" displayEmpty className="form-control" id="grouped-native-select"
                            variant="outlined" multiple
                            onChange={(e) => props.handleChipChange(e, 'privileges')}
                            value={form.privileges ? form.privileges : ''} input={<Input />}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected && selected.map((item) => (
                                        <Chip key={item.value} label={item.label} className={classes.chip} />
                                    ))}
                                </div>
                            )}>
                            {/* <MenuItem value=''>
                                {"Please select a privilege"}
                            </MenuItem> */}
						{/* privileges && privileges.map((item, i) => (
                                <MenuItem key={item.value} value={item}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>*/}
						<Privileges
							{...props}
							values={props.state}
							classes={classes}
							onPrivilegeChangeHandler={props.onPrivilegeChangeHandler}
							addPrivilege={props.addPrivilege}
						/>
						<FormLabel component="legend">
							{"Privileges Table"}
						</FormLabel>
						<PrivilegesTable
							{...props}
							privileges={props.state.privileges}
							editPrivilege={props.editPrivilege}
							deletePrivilege={props.deletePrivilege}
						/>
					</FormControl>
				</form>
			</div>
		)
	);
}
