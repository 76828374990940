import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { pageService } from 'services/index'
import { ActionTypes } from 'constants/index'


async function fetchAsync(func, arg) {
	const response = arg ? await func(arg) : await func()
	return response
}

function* getPages(action) {
	try {
		const pages = yield call(fetchAsync, pageService.getPages, action.appId)
		yield put({ type: ActionTypes.GET_PAGES_SUCCESS, payload: pages })
	} catch (e) {
		yield put({ type: ActionTypes.GET_PAGES_FAILURE, error: e.data })
	}
}

function* getPage(action) {
	try {
		console.log("action.payload", action.payload);
		const page = yield call(fetchAsync, pageService.getPage, action.payload)
		console.log("page===", page);
		yield put({ type: ActionTypes.GET_PAGE_SUCCESS, payload: page })
	} catch (e) {
		yield put({ type: ActionTypes.GET_PAGE_FAILURE, error: e.data })
	}
}

function* savePage(action) {
	try {
		const page = yield call(fetchAsync, pageService.savePage, action.payload)
		yield put({ type: ActionTypes.SAVE_PAGE_SUCCESS, payload: page })
	} catch (e) {
		yield put({ type: ActionTypes.SAVE_PAGE_FAILURE, error: e.data })
	}
}

function* updatePage(action) {
	try {
		const page = yield call(fetchAsync, pageService.updatePage, action.payload)
		yield put({ type: ActionTypes.UPDATE_PAGE_SUCCESS, payload: page })
	} catch (e) {
		console.log("e", e);
		yield put({ type: ActionTypes.UPDATE_PAGE_FAILURE, error: e.data })
	}
}

function* deletePage(action) {
	try {
		const page = yield call(fetchAsync, pageService.deletePage, action.data)
		console.log("page", page)
		yield put({ type: ActionTypes.DELETE_PAGE_SUCCESS, payload: page })
	} catch (e) {
		console.error("page error", e)
		yield put({ type: ActionTypes.DELETE_PAGE_FAILURE, error: e.data })
	}
}


export function* watchDappsSaga() {
	yield takeEvery(ActionTypes.GET_PAGES, getPages)
	yield takeEvery(ActionTypes.GET_PAGE, getPage)
	yield takeEvery(ActionTypes.SAVE_PAGE, savePage)
	yield takeEvery(ActionTypes.UPDATE_PAGE, updatePage)
	yield takeEvery(ActionTypes.DELETE_PAGE, deletePage)
}

export default watchDappsSaga;