/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable prettier/prettier */
import React from 'react';
import ReactDOM from 'react-dom';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';

import Modal from './components/Modal';

// import { Grid, NativeSelect, TextField } from '@material-ui/core';
import Canvas from './components/Canvas';
import ErrorBoundary from './components/ErrorBoundary';
import Step from './components/Step';
import Toolbar from './components/Toolbar';
import randomString from '../../utils/randomString';
import './style/button.css';
import './style/EditElement.css';
import Redux from './redux';

import Basicinfo from './Properties/Basicinfo';
import Process from './Properties/Process';

const initialDiagram = {
  steps: [
    {
      d: 'M35,0 L35 390',
      height: 390,
      id: 'sbbq',
      inputConnectors: [],
      outputConnectors: [],
      type: 'pool',
      width: window.innerWidth - 550,
      x: 28,
      x1: 28,
      x2: 28 + window.innerWidth - 420,
      y: 4,
      y1: 4,
      y2: 394,
    },
  ],
  style: { backgroundColor: '#eee' },
  height: window.innerHeight - 100,
  width: '100%',
};
class Workflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      diagram: props.workflow ? JSON.parse(props.workflow.diagram) : initialDiagram,
      dragging: null,
      isMouseDown: false,
      isMouseMoving: false,
      isMouseOver: false,
      isMouseMove: false,
      offset: { x: 0, y: 0 },
      scroll: { x: 0, y: 0 },
      rectangularSelection: null,
      selected: {},
      mouseOverConnection: {},
      clickStep: {},
      mouseOverStep: {},
      shiftPressed: false,
      toolbarHeight: 50,
      show: false,
      ids: [],
      selectedStep: {},
      value: 0,
    };
  }

  componentDidMount() {
    const container = ReactDOM.findDOMNode(this).parentNode;

    // document.addEventListener('keydown', this.onDocumentKeydown)
    // document.addEventListener('keyup', this.onDocumentKeyup)

    window.addEventListener('scroll', this.onWindowScroll);
    window.addEventListener('resize', this.onWindowResize(container));

    const offset = {
      x: container.offsetLeft,
      y: container.offsetTop,
    };

    const scroll = {
      x: window.scrollX,
      y: window.scrollY,
    };

    this.setState({
      offset,
      scroll,
    });
  }

  dragToolbarIcon = StepIcon => {
    return event => {
      const { diagram, toolbarHeight } = this.state;
      // console.log(diagram.steps)
      // const coordinates = this.getCoordinates(event);
      // if(coordinates.x > this.state.poolCoordinateX1 && coordinates.y > this.state.poolCoordinateY1 && coordinates.x < this.state.poolCoordinateX2 && coordinates.y < this.state.poolCoordinateY2){
      //   console.log("insidePool")
      // } else {
      //   console.log("outsidePool")
      // }
    };
  };

  dropToolbarIcon = (StepIcon, subType) => {
    // console.log('-----Inside drop toolbar-----', StepIcon);
    // console.log('-----Inside drop toolbar SubTYPE-----', subType);

    return event => {
      const { diagram, toolbarHeight } = this.state;
      const coordinates = this.getCoordinates(event);
      console.log(coordinates);

      // Create item if dropped inside Workflow.
      // console.log(this.isInsideWorkflow(coordinates))
      if (this.isInsideWorkflow(coordinates)) {
        const id = this.generateId(16);
        // const parentId = this.generateId(4);

        const type = StepIcon.name.toLowerCase();
        const subtype = subType.toLowerCase();
        console.log('Droptoolbaricon', diagram);
        const x = coordinates.x - Step.defaultProps.width - 90;
        const y = coordinates.y - Step.defaultProps.height / 2;
        // const x = coordinates.x;
        // const y = coordinates.y;
        const step = {
          id,
          type,
          x,
          y,
          inputConnectors: [],
          outputConnectors: [],
          height: 40,
          width: 100,
          subType: subtype,
          name: subtype,
        };
        if (type === 'line' || type === 'optionalline') {
          step.startX = step.startY = 0;
          step.endX = step.endY = 80;
        }
        if (type === 'pool') {
          step.d = `M35,0 L35 390`;
          step.height += 350;
          step.width = window.innerWidth - 550;
          step.x1 = x;
          step.y1 = y;
          step.x2 = x + step.width;
          step.y2 = y + step.height;
          this.setState(
            {
              diagram: Object.assign({}, diagram, {
                steps: [...diagram.steps, step],
              }),
              clickStep: {
                [id]: true,
              },
            },
            () => {
              this.props.diagramChange(this.state.diagram);
            },
          );
        }
        if (type === 'process') {
          step.d = 'M0,30 L220 30';
          step.height = 120;
          step.width = 220;
          step.innerRectHeight = 20;
          step.innerRectWidth = 20;
          step.x1 = x;
          step.y1 = y;
          step.x2 = x + step.width;
          step.y2 = y + step.height;
          for (let i = 0; i < diagram.steps.length; i++) {
            if (diagram.steps[i].type === 'pool') {
              if (
                step.x > diagram.steps[i].x1 &&
                step.y > diagram.steps[i].y1 &&
                step.x < diagram.steps[i].x2 &&
                step.y < diagram.steps[i].y2
              ) {
                step.parentId = diagram.steps[i].id;
                this.setState(
                  {
                    diagram: Object.assign({}, diagram, {
                      steps: [...diagram.steps, step],
                    }),
                    clickStep: {
                      [id]: true,
                    },
                  },
                  () => {
                    this.props.diagramChange(this.state.diagram);
                  },
                );
              }
            }
          }
        }
        if (type === 'start') {
          step.x1 = x;
          step.y1 = y;
          step.width = 28;
          step.height = 28;
          step.x2 = x + step.width;
          step.y2 = y + step.height;
          for (let i = 0; i < diagram.steps.length; i++) {
            if (diagram.steps[i].type === 'pool') {
              if (
                step.x > diagram.steps[i].x1 &&
                step.y > diagram.steps[i].y1 &&
                step.x < diagram.steps[i].x2 &&
                step.y < diagram.steps[i].y2
              ) {
                step.parentId = diagram.steps[i].id;
                this.setState(
                  {
                    diagram: Object.assign({}, diagram, {
                      steps: [...diagram.steps, step],
                    }),
                    clickStep: {
                      [id]: true,
                    },
                  },
                  () => {
                    this.props.diagramChange(this.state.diagram);
                  },
                );
              }
            }
          }
        }
        if (type === 'end') {
          step.x1 = x;
          step.y1 = y;
          step.x2 = x + step.width;
          step.y2 = y + step.height;
          for (let i = 0; i < diagram.steps.length; i++) {
            if (diagram.steps[i].type === 'pool') {
              if (
                step.x > diagram.steps[i].x1 &&
                step.y > diagram.steps[i].y1 &&
                step.x < diagram.steps[i].x2 &&
                step.y < diagram.steps[i].y2
              ) {
                step.parentId = diagram.steps[i].id;
                this.setState(
                  {
                    diagram: Object.assign({}, diagram, {
                      steps: [...diagram.steps, step],
                    }),
                    clickStep: {
                      [id]: true,
                    },
                  },
                  () => {
                    this.props.diagramChange(this.state.diagram);
                  },
                );
              }
            }
          }
        }
        if (type === 'decision') {
          step.height = 55;
          step.width = 60;
          step.x1 = x;
          step.y1 = y;
          step.x2 = x + step.width;
          step.y2 = y + step.height;
          for (let i = 0; i < diagram.steps.length; i++) {
            if (diagram.steps[i].type === 'pool') {
              if (
                step.x > diagram.steps[i].x1 &&
                step.y > diagram.steps[i].y1 &&
                step.x < diagram.steps[i].x2 &&
                step.y < diagram.steps[i].y2
              ) {
                step.parentId = diagram.steps[i].id;
                this.setState(
                  {
                    diagram: Object.assign({}, diagram, {
                      steps: [...diagram.steps, step],
                    }),
                    clickStep: {
                      [id]: true,
                    },
                  },
                  () => {
                    this.props.diagramChange(this.state.diagram);
                  },
                );
              }
            }
          }
        }
        if (type === 'terminator') {
          step.x1 = x;
          step.y1 = y;
          step.x2 = x + step.width;
          step.y2 = y + step.height;
          for (let i = 0; i < diagram.steps.length; i++) {
            if (diagram.steps[i].type === 'pool') {
              if (
                step.x > diagram.steps[i].x1 &&
                step.y > diagram.steps[i].y1 &&
                step.x < diagram.steps[i].x2 &&
                step.y < diagram.steps[i].y2
              ) {
                step.parentId = diagram.steps[i].id;
                this.setState(
                  {
                    diagram: Object.assign({}, diagram, {
                      steps: [...diagram.steps, step],
                    }),
                    clickStep: {
                      [id]: true,
                    },
                  },
                  () => {
                    this.props.diagramChange(this.state.diagram);
                  },
                );
              }
            }
          }
        }
      }
    };
  };

  generateId = l => {
    const newId = randomString(l);

    const idExists = this.state.diagram.steps.find(({ id }) => id === newId);

    // If new random id was found, try again with a longer random string.
    return idExists ? this.generateId(l + 1) : newId;
  };

  getCoordinates = event => {
    const { offset, scroll } = this.state;

    return {
      x: event.clientX - offset.x + scroll.x,
      y: event.clientY - offset.y + scroll.y,
      // x: event.clientX,
      // y: event.clientY,
    };
  };

  isInsideWorkflow = coordinates => {
    const { diagram, offset, scroll, toolbarHeight } = this.state;

    const { height, width } = diagram;
    console.log('Width', width);
    return (
      coordinates.x > offset.x + scroll.x - window.innerWidth &&
      coordinates.x < offset.x + scroll.x + window.innerWidth &&
      coordinates.y > offset.y + scroll.y + toolbarHeight - toolbarHeight * 7 &&
      coordinates.y < offset.y + scroll.y + height
    );
  };

  onMouseDown = event => {
    const { mouseOverConnection, toolbarHeight, diagram } = this.state;
    const startId = Object.keys(mouseOverConnection).find(p => mouseOverConnection[p]);
    if (startId) {
      console.log(startId);
      const step = diagram.steps.find(s => s.id === startId);
      const coordinates = this.getCoordinates(event);
      if (step.type === 'line' || step.type === 'optionalline') {
        const connection = mouseOverConnection[startId];
        this.setState({
          connection,
          dragging: coordinates,
        });
      } else {
        // this.setState({
        //   arrow: {
        //     startId,
        //     startX: coordinates.x,
        //     startY: coordinates.y - toolbarHeight,
        //     endX: coordinates.x,
        //     endY: coordinates.y - toolbarHeight,
        //   },
        // });
      }
    }
    if (event.target.id === 'svg') this.setState({ selected: {} });
  };

  onMouseEnter = () => {
    this.setState({
      isMouseOver: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      isMouseOver: false,
    });
  };

  onMouseMove = event => {
    const {
      diagram,
      dragging,
      isMouseDown,
      isMouseMove,
      selected,
      arrow,
      toolbarHeight,
      connection,
    } = this.state;

    const steps = [...diagram.steps];

    const coordinates = this.getCoordinates(event);

    const deltaX = dragging ? coordinates.x - dragging.x : 0;
    const deltaY = dragging ? coordinates.y - dragging.y : 0;

    if (isMouseDown) {
      // console.log(coordinates)

      if (arrow) {
        this.setState(s => ({
          arrow: {
            ...s.arrow,
            endX: coordinates.x,
            endY: coordinates.y - toolbarHeight,
          },
        }));
        return;
      }
      if (Object.keys(selected).length) {
        console.log(steps);

        const ids = Object.keys(selected);

        steps
          .filter(el => {
            // if (el.type === 'line' || el.type === 'optionalline') {
            //   return;
            // } else {
              return (el.parentId === ids[0] || el.id === ids[0]) && el.type !== 'line';
            // }
          })
          .forEach(step => {
            if (step.type === 'pool') {
              console.log("inside")
              step.x += deltaX;
              step.y += deltaY;
              step.x1 = step.x;
              step.y1 = step.y;
              step.x2 = step.x + step.width;
              step.y2 = step.y + step.height;
              //====================================== TODO for drag line ================
              // if (connection) {
              //   console.log(step);
              //   step[connection + "X"] += deltaX;
              //   step[connection + "Y"] += deltaY;
              //   console.log(step);
              // } else {
              //   step.x += deltaX;
              //   step.y += deltaY;
              // }
              steps.filter(({ id }) => selected[id]);
              // console.log(steps)
              this.setState(
                {
                  diagram: Object.assign({}, diagram, { steps }),
                  dragging: coordinates,
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
            } else {
              console.log("outside")
              const { inputConnectors, outputConnectors } = step;
              if (inputConnectors && inputConnectors.length > 0) {
                for (let i = 0; i < inputConnectors.length; i++) {
                  steps
                    .filter(({ id }) => {
                      return inputConnectors[i].id === id;
                    })

                    .forEach(step => {
                      if (step.name === 'secondLine') {
                        step.endX += deltaX;
                        step.endY += deltaY;
                        step.endY2 += deltaY / 2;
                        step.endY3 = step.endY2;
                        step.endX3 += deltaX;
                      } else {
                          // step.endX += deltaX;
                          // step.endY += deltaY;
                          // step.endX2 += deltaX / 2;
                          // step.endX3 = step.endX2;
                          // step.endY3 += deltaY;
                        // if(step.endX < 50 ) {

                        
                          let dataOfSource = [];
                          let currentSource = [];
                          for (let i = 0; i < steps.length; i++) {
                            if (steps[i].type === 'line' || steps[i].type === 'optionalline') {
                              for (let x = 0; x < steps.length; x++) {
                                if (steps[x].outputConnectors.length > 0) {
                                  steps[x].outputConnectors.forEach(item => {
                                    if (item.id === steps[i].id) {
                                      dataOfSource.push(steps[x]);
                                    }
                                  });
                                }
                                if (steps[x].inputConnectors.length > 0) {
                                  steps[x].inputConnectors.forEach(item => {
                                    if (item.id === steps[i].id) {
                                      currentSource.push(steps[x]);
                                    }
                                  });
                                }           
                              }
                            }
                          }
                          for(let i = 0; i < steps.length; i++) {
                            for(let j = 0; j < currentSource.length; j++) {
                              if(currentSource[j].inputConnectors[0].id === steps[i].id && dataOfSource[j].outputConnectors[0].id === steps[i].id) {
                                if(currentSource[j].type === "end") {
                                  if ((currentSource[j].x1 - dataOfSource[j].x2) < 45 ) {
                                    steps[i].startX = dataOfSource[j].x2 - dataOfSource[j].x1 - dataOfSource[j].width + steps[i].startX;
                                    steps[i].startY = dataOfSource[j].y2 - dataOfSource[j].y1 - dataOfSource[j].height + steps[i].startY;
                                    steps[i].endX2 = currentSource[j].x1 - dataOfSource[j].x2 + currentSource[j].width/2 + steps[i].startX;
                                    steps[i].endY2 = steps[i].startY;
                                    steps[i].endX3 = steps[i].endX2;
                                    steps[i].endY3 = steps[i].startY;
                                    steps[i].endX = steps[i].endX2 + 15;
                                    steps[i].endY = currentSource[j].y2 - dataOfSource[j].y2 - currentSource[j].height/2 + 25 + steps[i].startY;
                                  } else {
                                    steps[i].startX = steps[i].startX;
                                    steps[i].startY = steps[i].startY;
                                    steps[i].endY2 = steps[i].endY2;
                                    steps[i].endY3 = currentSource[j].y2 - dataOfSource[j].y2 + steps[i].startY + 40;
                                    steps[i].endX = currentSource[j].x1 - dataOfSource[j].x2 + steps[i].startX + 30;
                                    steps[i].endY = currentSource[j].y2 - dataOfSource[j].y2 + steps[i].startY + 40;
                                    steps[i].endX2 = (steps[i].endX + steps[i].startX)/2;
                                    steps[i].endX3 = steps[i].endX2;
                                  }
                                }
                                 else if(dataOfSource[j].type === "start") {
                                  if ((currentSource[j].x1 - dataOfSource[j].x2) < 45 && (currentSource[j].y1 > dataOfSource[j].y2) ) {
                                    steps[i].startX = dataOfSource[j].x2 - dataOfSource[j].x1 - dataOfSource[j].width + steps[i].startX;
                                    steps[i].startY = dataOfSource[j].y2 - dataOfSource[j].y1 - dataOfSource[j].height + steps[i].startY;
                                    steps[i].endX2 = currentSource[j].x1 - dataOfSource[j].x2 + currentSource[j].width/2 + steps[i].startX;
                                    steps[i].endY2 = steps[i].startY;
                                    steps[i].endX3 = steps[i].endX2;
                                    steps[i].endY3 = steps[i].startY;
                                    steps[i].endX = steps[i].endX2 + 15;
                                    steps[i].endY = currentSource[j].y2 - dataOfSource[j].y2 - currentSource[j].height/2 - 65 + steps[i].startY;
          
                                    // if(currentSource[j].y2 < dataOfSource[j].y1) {
                                    //   steps[i].startX = 0;
                                    //   steps[i].startY = 0;
                                    //   steps[i].endX2 = currentSource[j].x1 - dataOfSource[j].x2 + currentSource[j].width/2;
                                    //   steps[i].endY2 = 0;
                                    //   steps[i].endX3 = steps[i].endX2;
                                    //   steps[i].endY3 = 0;
                                    //   steps[i].endX = currentSource[j].x1 - dataOfSource[j].x2 + currentSource[j].width/2 + 15;
                                    //   steps[i].endY =  currentSource[j].y2 - dataOfSource[j].y2 - currentSource[j].height/2 + 85;
                                    // }
                                    
                                    // if(currentSource[j].x1 < dataOfSource[j].x1 - 50) {
                                    //   steps[i].startX = dataOfSource[j].x2 - dataOfSource[j].x1 - dataOfSource[j].width - 10;
                                    //   steps[i].startY = dataOfSource[j].y2 - dataOfSource[j].y1 - dataOfSource[j].height/2 - 5;
                                    //   steps[i].endX2 = steps[i].startX;
                                    //   steps[i].endY2 = steps[i].startY;
                                    //   steps[i].endX3 = steps[i].startX;
                                    //   steps[i].endY3 = steps[i].startY;
                                    //   steps[i].endX = currentSource[j].x1 - dataOfSource[j].x2 + currentSource[j].width/2 + 15;
                                    //   steps[i].endY = currentSource[j].y2 - dataOfSource[j].y2 - currentSource[j].height/2 - 65;
          
                                    //   if(currentSource[j].y2 < dataOfSource[j].y1) {
                                    //     steps[i].startX = dataOfSource[j].x2 - dataOfSource[j].x1 - dataOfSource[j].width - dataOfSource[j].width/2;
                                    //     steps[i].startY = dataOfSource[j].y2 - dataOfSource[j].y2 - dataOfSource[j].height/2;
                                    //     steps[i].endX2 = steps[i].startX;
                                    //     steps[i].endY2 = currentSource[j].y2 - dataOfSource[j].y2;
                                    //     steps[i].endX3 = steps[i].endX2;
                                    //     steps[i].endY3 = steps[i].endY2;
                                    //     steps[i].endX = currentSource[j].x1 - dataOfSource[j].x2;
                                    //     steps[i].endY = steps[i].endY2;
                                    //   }
                                    //  }
                                  } else {
                                    steps[i].startX = steps[i].startX;
                                    steps[i].startY = steps[i].startY;
                                    steps[i].endY2 = steps[i].endY2;
                                    steps[i].endY3 = currentSource[j].y2 - dataOfSource[j].y2 + steps[i].startY - 40;
                                    steps[i].endX = currentSource[j].x1 - dataOfSource[j].x2 + steps[i].startX;
                                    steps[i].endY = currentSource[j].y2 - dataOfSource[j].y2 + steps[i].startY - 40;
                                    steps[i].endX2 = (steps[i].endX + steps[i].startX)/2;
                                    steps[i].endX3 = steps[i].endX2;
                                  }
                                } else {
                                  if ((currentSource[j].x1 - dataOfSource[j].x2) < 75 && (currentSource[j].x1 - dataOfSource[j].x2) > 0 ) {
                                    steps[i].startX = dataOfSource[j].x2 - dataOfSource[j].x1 - dataOfSource[j].width + steps[i].startX;
                                    steps[i].startY = dataOfSource[j].y2 - dataOfSource[j].y1 - dataOfSource[j].height + steps[i].startY;
                                    steps[i].endX2 = currentSource[j].x1 - dataOfSource[j].x2 + currentSource[j].width/2 + steps[i].startX;
                                    steps[i].endY2 = steps[i].startY;
                                    steps[i].endX3 = steps[i].endX2;
                                    steps[i].endY3 = steps[i].startY;
                                    steps[i].endX = steps[i].endX2 + 15;
                                    steps[i].endY = currentSource[j].y2 - dataOfSource[j].y2 - currentSource[j].height/2 - 17 + steps[i].startY;
          
                                    // if(currentSource[j].y2 < dataOfSource[j].y1) {
                                    //   steps[i].startX = 0;
                                    //   steps[i].startY = 0;
                                    //   steps[i].endX2 = currentSource[j].x1 - dataOfSource[j].x2 + currentSource[j].width/2;
                                    //   steps[i].endY2 = 0;
                                    //   steps[i].endX3 = steps[i].endX2;
                                    //   steps[i].endY3 = 0;
                                    //   steps[i].endX = currentSource[j].x1 - dataOfSource[j].x2 + currentSource[j].width/2 + 15;
                                    //   steps[i].endY = currentSource[j].y2 - dataOfSource[j].y1 - currentSource[j].height/2 + 17;
                                    // }
                                    
                                    // if(currentSource[j].x1 < dataOfSource[j].x2) {
                                      
                                    //   steps[i].startX = dataOfSource[j].x2 - dataOfSource[j].x1 - dataOfSource[j].width - dataOfSource[j].width/2;
                                    //   steps[i].startY = dataOfSource[j].y2 - dataOfSource[j].y1 - dataOfSource[j].height/2;
                                    //   steps[i].endX2 = steps[i].startX;
                                    //   steps[i].endY2 = currentSource[j].y2 - dataOfSource[j].y2;
                                    //   steps[i].endX3 = steps[i].endX2;
                                    //   steps[i].endY3 = steps[i].endY2;
                                    //   steps[i].endX = currentSource[j].x1 - dataOfSource[j].x2;
                                    //   steps[i].endY = steps[i].endY2;
          
                                    //   if(currentSource[j].y2 < dataOfSource[j].y1) {
                                    //     steps[i].startX = dataOfSource[j].x2 - dataOfSource[j].x1 - dataOfSource[j].width - dataOfSource[j].width/2;
                                    //     steps[i].startY = dataOfSource[j].y2 - dataOfSource[j].y2 - dataOfSource[j].height/2;
                                    //     steps[i].endX2 = steps[i].startX;
                                    //     steps[i].endY2 = currentSource[j].y2 - dataOfSource[j].y2;
                                    //     steps[i].endX3 = steps[i].endX2;
                                    //     steps[i].endY3 = steps[i].endY2;
                                    //     steps[i].endX = currentSource[j].x1 - dataOfSource[j].x2;
                                    //     steps[i].endY = steps[i].endY2;
                                    //   }
                                    //  }
                                    //  if(currentSource[j].x1 < (dataOfSource[j].x2 - dataOfSource[j].width/2 + 20)) {
                                    //   steps[i].endX2 = steps[i].startX
                                    //   steps[i].endY2 = steps[i].startY
                                    //   steps[i].endX3 = steps[i].startX
                                    //   steps[i].endY3 = steps[i].startY;
                                    //   steps[i].endX = currentSource[j].x1 - dataOfSource[j].x2 + currentSource[j].width/2 + 15;
                                    //   steps[i].endY = currentSource[j].y2 - dataOfSource[j].y2 - currentSource[j].height/2 - 17;
          
                                    //   if(currentSource[j].x2 <(dataOfSource[j].x2 - dataOfSource[j].width/2 - 20)){
                                    //     console.log("Inverse L");
                                    //     steps[i].startX = dataOfSource[j].x2 - dataOfSource[j].x1 - dataOfSource[j].width - dataOfSource[j].width/2;
                                    //     steps[i].startY = dataOfSource[j].y2 - dataOfSource[j].y1 - dataOfSource[j].height/2;
                                    //     steps[i].endX2 = steps[i].startX;
                                    //     steps[i].endY2 = currentSource[j].y2 - dataOfSource[j].y2;
                                    //     steps[i].endX3 = steps[i].endX2;
                                    //     steps[i].endY3 = steps[i].endY2;
                                    //     steps[i].endX = currentSource[j].x2 - dataOfSource[j].x2 + 30;
                                    //     steps[i].endY = steps[i].endY2;
          
                                    //     if(currentSource[j].y2 < dataOfSource[j].y1) {
                                    //     steps[i].startX = dataOfSource[j].x2 - dataOfSource[j].x1 - dataOfSource[j].width - dataOfSource[j].width/2;
                                    //     steps[i].startY = dataOfSource[j].y2 - dataOfSource[j].y2 - dataOfSource[j].height/2;
                                    //     steps[i].endX2 = steps[i].startX;
                                    //     steps[i].endY2 = currentSource[j].y2 - dataOfSource[j].y2;
                                    //     steps[i].endX3 = steps[i].endX2;
                                    //     steps[i].endY3 = steps[i].endY2;
                                    //     steps[i].endX = currentSource[j].x2 - dataOfSource[j].x2 + 30;
                                    //     steps[i].endY = steps[i].endY2;
                                    //     }
                                    //       if(currentSource[j].x2 < dataOfSource[j].x1)
                                    //       {
                                    //         console.log("LLL change")
                                    //         steps[i].startX = -dataOfSource[j].width;
                                    //         steps[i].startY = 0;
                                    //         steps[i].endX2 = currentSource[j].x1 - dataOfSource[j].x2 + currentSource[j].width/2;
                                    //         steps[i].endY2 = 0;
                                    //         steps[i].endX3 = steps[i].endX2;
                                    //         steps[i].endY3 = 0;
                                    //         steps[i].endX = currentSource[j].x1 - dataOfSource[j].x2 + currentSource[j].width/2 + 15;
                                    //         steps[i].endY = currentSource[j].y2 - dataOfSource[j].y2 - currentSource[j].height/2 - 17;
          
                                    //           if(currentSource[j].y2 < dataOfSource[j].y1) {
                                    //           steps[i].startX =-dataOfSource[j].width;
                                    //           steps[i].startY = 0;
                                    //           steps[i].endX2 = currentSource[j].x1 - dataOfSource[j].x2 + currentSource[j].width/2;
                                    //           steps[i].endY2 = 0;
                                    //           steps[i].endX3 = steps[i].endX2;
                                    //           steps[i].endY3 = 0;
                                    //           steps[i].endX = currentSource[j].x1 - dataOfSource[j].x2 + currentSource[j].width/2 + 15;
                                    //           steps[i].endY = currentSource[j].y2 - dataOfSource[j].y1 - currentSource[j].height/2 + 17;
                                    //           }
                                    //       }
                                    //     }
                                    //  }
                                  } else {
                                    steps[i].startX = steps[i].startX;
                                    steps[i].startY = steps[i].startY;
                                    steps[i].endY2 = steps[i].endY2;
                                    steps[i].endY3 = currentSource[j].y2 - dataOfSource[j].y2 + steps[i].startY;
                                    steps[i].endX = currentSource[j].x1 - dataOfSource[j].x2 + steps[i].startX;
                                    steps[i].endY = currentSource[j].y2 - dataOfSource[j].y2 + steps[i].startY;
                                    steps[i].endX2 = (steps[i].endX + steps[i].startX)/2;
                                    steps[i].endX3 = steps[i].endX2;
                                  }
                                }

                              }
                            }
                          }
                      }
                    });
                }
              }

              if (outputConnectors && outputConnectors.length > 0) {
                for (let i = 0; i < outputConnectors.length; i++) {
                  steps
                    .filter(({ id }) => {
                      return outputConnectors[i].id === id;
                    })
                    .forEach(step => {
                      if (step.name === 'secondLine') {
                        step.startX += deltaX;
                        step.startY += deltaY;
                        step.endY2 += deltaY / 2;
                        step.endY3 = step.endY2;
                        step.endX2 += deltaX;
                      } else {                  
                        step.startX += deltaX;
                        step.startY += deltaY;
                        step.endX2 += deltaX / 2;
                        step.endY2 += deltaY;
                        step.endX3 = step.endX2;
                        // let dataOfSource = [];
                        // let currentSource = [];
                        // for (let i = 0; i < steps.length; i++) {
                        //   if (steps[i].type === 'line' || steps[i].type === 'optionalline') {
                        //     for (let x = 0; x < steps.length; x++) {
                        //       if (steps[x].outputConnectors.length > 0) {
                        //         steps[x].outputConnectors.forEach(item => {
                        //           if (item.id === steps[i].id) {
                        //             currentSource.push(steps[x]);
                        //           }
                        //         });
                        //       }
                        //       if (steps[x].inputConnectors.length > 0) {
                        //         steps[x].inputConnectors.forEach(item => {
                        //           if (item.id === steps[i].id) {
                        //             dataOfSource.push(steps[x]);
                        //           }
                        //         });
                        //       }           
                        //     }
                        //   }
                        // }

                        // for(let i = 0; i < steps.length; i++) {
                        //   for(let j = 0; j < currentSource.length; j++) {
                        //     if(currentSource[j].outputConnectors[0].id === steps[i].id && dataOfSource[j].inputConnectors[0].id === steps[i].id) {
                        //       if(currentSource[j].type === "start") {
                        //         steps[i].startX = currentSource[j].x2 - dataOfSource[j].x1 + steps[i].endX;
                        //         steps[i].startY = currentSource[j].y2 - dataOfSource[j].y2 + steps[i].endY + 50;
                        //         steps[i].endY2 = currentSource[j].y2 - dataOfSource[j].y2 + steps[i].endY + 50;
                        //         steps[i].endY3 = steps[i].endY3;
                        //         steps[i].endX = steps[i].endX;
                        //         steps[i].endY = steps[i].endY;
                        //         steps[i].endX2 = (steps[i].endX + steps[i].startX)/2;
                        //         steps[i].endX3 = steps[i].endX2;
                        //       } else {
                        //         steps[i].startX = currentSource[j].x2 - dataOfSource[j].x1 + steps[i].endX;
                        //         steps[i].startY = currentSource[j].y2 - dataOfSource[j].y2 + steps[i].endY;
                        //         steps[i].endY2 = currentSource[j].y2 - dataOfSource[j].y2 + steps[i].endY;
                        //         steps[i].endY3 = steps[i].endY3;
                        //         steps[i].endX = steps[i].endX;
                        //         steps[i].endY = steps[i].endY;
                        //         steps[i].endX2 = (steps[i].endX + steps[i].startX)/2;
                        //         steps[i].endX3 = steps[i].endX2;
                        //       }

                        //     }
                        //   }
                        // }

                      }
                    });
                }
              }

              step.x += deltaX;
              step.y += deltaY;
              step.x1 = step.x;
              step.y1 = step.y;
              step.x2 = step.x + step.width;
              step.y2 = step.y + step.height;
              //====================================== TODO for drag line ================
              // if (connection) {
              //   console.log(step);
              //   step[connection + "X"] += deltaX;
              //   step[connection + "Y"] += deltaY;
              //   console.log(step);
              // } else {
              //   step.x += deltaX;
              //   step.y += deltaY;
              // }
              steps.filter(({ id }) => selected[id]);
              // console.log(steps)
              this.setState(
                {
                  diagram: Object.assign({}, diagram, { steps }),
                  dragging: coordinates,
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
            }
          });
      }
    } else {
      steps.forEach(step => {
        if (isMouseMove === false) {
          if (step.type === 'line') {
            if (!step.connected === true) {
              step.endY = coordinates.y - step.y;
              step.endX += deltaX;
              step.endY += deltaY;
              step.endX2 += deltaX / 2;
              step.endX3 = step.endX2;
              step.endY3 = step.endY;
            }
          }
          if (step.type === 'optionalline') {
            if (!step.connected === true) {
              step.endY = coordinates.y - step.y;
              step.endX += deltaX;
              step.endY += deltaY;
              step.endX2 += deltaX / 2;
              step.endX3 = step.endX2;
              step.endY3 = step.endY;
            }
          }
        }
      });

      this.setState(
        {
          diagram: Object.assign({}, diagram, { steps }),
          dragging: coordinates,
        },
        () => {
          this.props.diagramChange(this.state.diagram);
        },
      );
    }
  };

  moveArrow = (event, type) => {
    // console.log(type)
    // if (type === 'process') {
    //   this.setState({ isMouseMove: true });
    // }
    // // if(type === 'decision'){
    // //   this.setState({isMouseMove: true})
    // // }
    // // if(type === 'terminator'){
    // //   this.setState({isMouseMove: true})
    // // }
    // // if(type === 'start'){
    // //   this.setState({isMouseMove: true})
    // // }
    // if (type === 'pool') {
    //   this.setState({ isMouseMove: false });
    // }
  };

  onDragConnection = (event, type, id) => {
    const {
      diagram,
      dragging,
      isMouseDown,
      isMouseMove,
      selected,
      arrow,
      toolbarHeight,
      connection,
    } = this.state;
    const steps = [...diagram.steps];
    console.log('steps====', type);
    const coordinates = this.getCoordinates(event);
    console.log('coordinates====', dragging);

    const deltaX = dragging ? coordinates.x - dragging.x : 0;
    const deltaY = dragging ? coordinates.y - dragging.y : 0;
    if (isMouseDown) {
      steps.forEach(step => {
        if (step.type === 'pool') {
          if (type === 'bottom' || type === 'top') {
            if (deltaY > 0) {
              step.height += coordinates.y - step.height;
              step.d = `M35,0 L35 ${step.height}`;
              // step.height += deltaY;
            } else {
              step.height -= coordinates.y - step.height;
              step.d = `M35,0 L35 ${step.height}`;
              // step.height += deltaY;
            }
          }
        }
      });
    }
    this.setState(
      {
        diagram: Object.assign({}, diagram, { steps }),
        dragging: coordinates,
      },
      () => {
        this.props.diagramChange(this.state.diagram);
      },
    );
  };

  onMouseUp = event => {
    const { diagram, arrow, mouseOverConnection } = this.state;
    const end = Object.keys(mouseOverConnection).find(p => mouseOverConnection[p]);
    if (arrow && end) {
      const steps = [...diagram.steps];
      const step = steps.find(s => s.id === arrow.startId);
      if (step.id !== end) {
        step.inputConnectors.push(arrow);
        this.setState({ diagram: Object.assign({}, diagram, { steps }) }, () => {
          this.props.diagramChange(this.state.diagram);
        });
      }
    }
    this.setState({
      dragging: null,
      isMouseDown: false,
      arrow: null,
    });
  };

  onWindowResize = container => {
    return () => {
      const rect = container.getBoundingClientRect();

      const dynamicView = {
        height: rect.height,
        width: rect.width,
      };
      this.setState({ dynamicView });
    };
  };

  onWindowScroll = () => {
    const scroll = {
      x: window.scrollX,
      y: window.scrollY,
    };
    this.setState({ scroll });
  };

  setMouseOverConnection = id => (event, data) => {
    const { mouseOverConnection } = this.state;
    mouseOverConnection[id] = event.type === 'mouseover' ? data || true : null;
    this.setState({
      mouseOverConnection: Object.assign({}, mouseOverConnection),
    });
  };

  setMouseOverStep = id => event => {
    const { mouseOverStep } = this.state;
    mouseOverStep[id] = event.type === 'mouseover';
    // console.log(mouseOverStep)
    this.setState({ mouseOverStep: Object.assign({}, mouseOverStep) });
  };
  selectStep = (id, parentId, type) => {
    const { diagram } = this.state;
    return event => {
      const { selected, shiftPressed } = this.state;
      let selectedStep = shiftPressed ? Object.assign({}, selected) : {};

      selectedStep[id] = true;
      selectedStep[parentId] = true;
      selectedStep[type] = true;

      this.setState({
        isMouseDown: event.type === 'mousedown',
        selected: selectedStep,
      });
    };
  };

  stopDragging = event => {
    this.setState({
      dragging: null,
      isMouseDown: false,
      selected: {},
    });
  };

  setClickStep = (event, id, type) => {
    let { clickStep, diagram, selected, offset } = this.state;
    const steps = [...diagram.steps];
    selected[id] = event.type === 'click';
    console.log('Clicked', steps);
    steps
      .filter(el => {
        return el.id === id;
      })
      .forEach(step => {
        if (step.type === 'pool') {
          for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === 'line') {
              if (!steps[i].connected === true) {
                this.deleteById(steps, 'id', steps[i].id);
                console.log(steps);
              }
            }
          }
        } else if (step.type === 'process') {
          for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === 'line') {
              if (!steps[i].connected === true) {
                steps[i].connected = true;
                if (steps[i].parentId != step.parentId) {
                  steps[i].strokedash = 5;
                  steps[i].type = 'optionalline';
                }

                let dataOfSource;
                for (let x = 0; x < steps.length; x++) {
                  if (steps[x].outputConnectors.length > 0) {
                    steps[x].outputConnectors.forEach(item => {
                      if (item.id === steps[i].id) {
                        dataOfSource = steps[x];
                        console.log('Connceted line Source', dataOfSource.x2);
                      }
                    });
                  }
                }
                if (dataOfSource.type === 'decision') {
                  console.log('input from decision');
                  steps[i].endX = step.x - dataOfSource.x2 - 3;
                  steps[i].endY = step.y - dataOfSource.y + 32;
                  steps[i].endY3 = step.y - dataOfSource.y + 32;
                } else if (dataOfSource.type === 'end') {
                  console.log('from end');
                  steps[i].endX = step.x - dataOfSource.x2 - 3 + 30;
                  steps[i].endY = step.y - dataOfSource.y + 39;
                  steps[i].endY3 = step.y - dataOfSource.y + 39;
                } else if (dataOfSource.type === 'start') {
                  console.log('from start');
                  steps[i].endX = step.x - dataOfSource.x2 - 3 + 74;
                  steps[i].endY = step.y - dataOfSource.y + 45;
                  steps[i].endY3 = step.y - dataOfSource.y + 45;
                } else {
                  steps[i].endX = step.x - dataOfSource.x2 - 3;
                  steps[i].endY = step.y - dataOfSource.y;
                  steps[i].endY3 = step.y - dataOfSource.y;
                }
                step.inputConnectors.push({ id: steps[i].id, point: 'end' });
              }
            }
          }
        } else if (step.type === 'start') {
          for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === 'line') {
              if (!steps[i].connected === true) {
                steps[i].connected = true;
                if (steps[i].parentId != step.parentId) {
                  steps[i].strokedash = 5;
                  steps[i].type = 'optionalline';
                }

                let dataOfSource;
                for (let x = 0; x < steps.length; x++) {
                  if (steps[x].outputConnectors.length > 0) {
                    steps[x].outputConnectors.forEach(item => {
                      if (item.id === steps[i].id) {
                        dataOfSource = steps[x];
                        console.log('Connceted line Source', dataOfSource);
                      }
                    });
                  }
                }
                if (dataOfSource.type === 'start') {
                  steps[i].endX = step.x - dataOfSource.x2 + 70;
                  steps[i].endY = step.y - dataOfSource.y;
                  steps[i].endY3 = step.y - dataOfSource.y;
                } else if (dataOfSource.type === 'end') {
                  steps[i].endX = step.x - dataOfSource.x2 + 28;
                  steps[i].endY = step.y - dataOfSource.y - 5;
                  steps[i].endY3 = step.y - dataOfSource.y - 5;
                } else if (dataOfSource.type === 'decision') {
                  steps[i].endX = step.x - dataOfSource.x2;
                  steps[i].endY = step.y - dataOfSource.y - 14;
                  steps[i].endY3 = step.y - dataOfSource.y - 14;
                } else if (dataOfSource.type === 'process') {
                  steps[i].endX = step.x - dataOfSource.x2;
                  steps[i].endY = step.y - dataOfSource.y - 45;
                  steps[i].endY3 = step.y - dataOfSource.y - 45;
                } else {
                  steps[i].endX = step.x - dataOfSource.x2 - 3;
                  steps[i].endY = step.y - dataOfSource.y;
                  steps[i].endY3 = step.y - dataOfSource.y;
                }
                step.inputConnectors.push({ id: steps[i].id, point: 'end' });
              }
            }
          }
        } else if (step.type === 'decision') {
          for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === 'line') {
              if (!steps[i].connected === true) {
                steps[i].connected = true;
                if (steps[i].parentId != step.parentId) {
                  steps[i].strokedash = 5;
                  steps[i].type = 'optionalline';
                }
                let dataOfSource;
                for (let x = 0; x < steps.length; x++) {
                  if (steps[x].outputConnectors.length > 0) {
                    steps[x].outputConnectors.forEach(item => {
                      if (item.id === steps[i].id) {
                        dataOfSource = steps[x];
                        console.log('Connceted line Source', dataOfSource);
                      }
                    });
                  }
                }
                if (dataOfSource.type === 'process') {
                  steps[i].endX = step.x - dataOfSource.x2 - 3;
                  steps[i].endY = step.y - dataOfSource.y - step.height / 2 - 5;
                  steps[i].endY3 = step.y - dataOfSource.y - step.height / 2 - 5;
                } else if (dataOfSource.type === 'start') {
                  steps[i].endX = step.x - dataOfSource.x2 + 70;
                  steps[i].endY = step.y - dataOfSource.y + 12;
                  steps[i].endY3 = step.y - dataOfSource.y + 12;
                } else if (dataOfSource.type === 'end') {
                  steps[i].endX = step.x - dataOfSource.x2 + 27;
                  steps[i].endY = step.y - dataOfSource.y + 7;
                  steps[i].endY3 = step.y - dataOfSource.y + 7;
                } else {
                  steps[i].endX = step.x - dataOfSource.x2 - 3;
                  steps[i].endY = step.y - dataOfSource.y;
                  steps[i].endY3 = step.y - dataOfSource.y;
                }
                step.inputConnectors.push({ id: steps[i].id, point: 'end' });
              }
            }
          }
        } else if (step.type === 'end') {
          for (let i = 0; i < steps.length; i++) {
            if (steps[i].type === 'line') {
              if (!steps[i].connected === true) {
                steps[i].connected = true;
                if (steps[i].parentId != step.parentId) {
                  steps[i].strokedash = 5;
                  steps[i].type = 'optionalline';
                }
                let dataOfSource;
                for (let x = 0; x < steps.length; x++) {
                  if (steps[x].outputConnectors.length > 0) {
                    steps[x].outputConnectors.forEach(item => {
                      if (item.id === steps[i].id) {
                        dataOfSource = steps[x];
                        console.log('Connceted line Source', dataOfSource);
                      }
                    });
                  }
                }
                if (dataOfSource.type === 'start') {
                  steps[i].endX = step.x - dataOfSource.x2 + 100;
                  steps[i].endY = step.y - dataOfSource.y + 4;
                  steps[i].endY3 = step.y - dataOfSource.y + 4;
                } else if (dataOfSource.type === 'end') {
                  steps[i].endX = step.x - dataOfSource.x2 + 55;
                  steps[i].endY = step.y - dataOfSource.y;
                  steps[i].endY3 = step.y - dataOfSource.y;
                } else if (dataOfSource.type === 'decision') {
                  steps[i].endX = step.x - dataOfSource.x2 + 27;
                  steps[i].endY = step.y - dataOfSource.y - 8;
                  steps[i].endY3 = step.y - dataOfSource.y - 8;
                } else if (dataOfSource.type === 'process') {
                  steps[i].endX = step.x - dataOfSource.x2 + 27;
                  steps[i].endY = step.y - dataOfSource.y - 40;
                  steps[i].endY3 = step.y - dataOfSource.y - 40;
                } else {
                  steps[i].endX = step.x - dataOfSource.x2 - 3;
                  steps[i].endY = step.y - dataOfSource.y;
                  steps[i].endY3 = step.y - dataOfSource.y;
                }
                step.inputConnectors.push({ id: steps[i].id, point: 'end' });
              }
            }
          }
        }
        //  else if (step.type === 'terminator') {
        //   for (let i = 0; i < steps.length; i++) {
        //     if (steps[i].type === 'line') {
        //       if (!steps[i].connected === true) {
        //         steps[i].connected = true;
        //         if (steps[i].parentId != step.parentId) {
        //           steps[i].strokedash = 5;
        //           steps[i].type = 'optionalline';
        //         }
        //         let dataOfSource;
        //         for (let x = 0; x < steps.length; x++) {
        //           if (steps[x].outputConnectors.length > 0) {
        //             steps[x].outputConnectors.forEach(item => {
        //               if (item.id === steps[i].id) {
        //                 dataOfSource = steps[x];
        //                 console.log('Connceted line Source', dataOfSource);
        //               }
        //             });
        //           }
        //         }

        //         step.inputConnectors.push({ id: steps[i].id, point: 'end' });
        //       }
        //     }
        //   }
        // }
      });
    this.setState(
      {
        diagram: Object.assign({}, diagram, { steps }),
        clickStep: Object.assign({}, selected),
      },
      () => {
        this.props.diagramChange(this.state.diagram);
      },
    );
  };

  editElementStep = id => {
    const { diagram } = this.state;
    let step = diagram.steps.find(prod => {
      return prod.id === id;
    });
    this.setState({
      show: true,
      id: id,
      selectedStep: step,
    });
  };

  onChangeValue = (key, value) => {
    this.setState(
      prevState => ({
        ...prevState,
        selectedStep: {
          ...prevState.selectedStep,
          [key]: value,
        },
      }),
      () => this.updateProperty(true),
    );
  };
  updateProperty = show => {
    const { diagram, selectedStep } = this.state;
    let steps = diagram.steps;
    let indexOf = steps.findIndex(step => step.id === selectedStep.id);
    steps[indexOf] = selectedStep;
    this.setState(
      prevState => ({
        ...prevState,
        diagram: {
          ...prevState.diagram,
          steps: steps,
        },
        show,
      }),
      () => {
        this.props.diagramChange(this.state.diagram);
      },
    );
  };

  renderEditElement() {
    const { selectedStep, value } = this.state;
    console.log('selected stepvalue', selectedStep);
    return (
      <>
        <Basicinfo value={value} index={0} />
        <Process value={0} index={1} />
      </>
    );
  }

  onClickSugessionElement = (_step, id, type) => {
    const { diagram } = this.state;
    const randomId1 = this.generateId(4);
    const randomId2 = this.generateId(4);
    let x = _step.x + 150;
    let y = _step.y;

    const step2 = {
      id: randomId2,
      type: 'line',
      x: _step.x + 100,
      y: _step.y + 20,
      inputConnectors: [],
      outputConnectors: [],
      connected: true,
      startX: 0,
      startY: 0,
      endX2: 0,
      endY2: 0,
      endX3: 0,
      endY3: 0,
      endX: 78,
      endY: 0,
    };

    if (type === 'circle') {
      if (_step.type === 'start') {
        step2.x = _step.x + 26;
        step2.y = _step.y + 15;
        x = _step.x + 106;
        // y = _step.y + 108;
      }
      if (_step.type === 'end') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
      }
      if (_step.type === 'process') {
        x = _step.x + 300;
        y = _step.y + 40;
        step2.x = _step.x + 220;
        step2.y = _step.y + 55;
      }
      if (_step.type === 'decision') {
        x = _step.x + 140;
        y = _step.y + 12;
        step2.x = _step.x + 60;
        step2.y = _step.y + 27.5;
      }
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (_step.parentId === diagram.steps[i].id) {
            step2.parentId = _step.parentId;
            if (x + 100 < diagram.steps[i].x2) {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'start',
                x,
                y,
                height: 28,
                width: 28,
                x1: x,
                y1: y,
                x2: x + 100,
                y2: y + 40,
                inputConnectors: [
                  {
                    id: randomId2,
                    parentId: _step.parentId,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log('step1====', step1);

              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                parentId: _step.parentId,
                point: 'start',
              });
            } else {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'start',
                x,
                y,
                height: 28,
                width: 28,
                x1: x,
                y1: y,
                x2: x + 100,
                y2: y + 40,
                inputConnectors: [
                  {
                    id: randomId2,
                    parentId: _step.parentId,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log(diagram.steps[i].id);
              diagram.steps[i].width += 200;
              // this.increasePoolWidth(diagram.steps, "id", diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                parentId: _step.parentId,
                point: 'start',
              });
            }
          }
        }
      }
    }
    if (type === 'twocircle') {
      if (_step.type === 'start') {
        step2.x = _step.x + 26;
        step2.y = _step.y + 15;
        x = _step.x + 76;
        y = _step.y - 8;
      }
      if (_step.type === 'end') {
        step2.x = _step.x + 70;
        x = _step.x + 120;
      }
      if (_step.type === 'process') {
        x = _step.x + 270;
        y = _step.y + 40;
        step2.x = _step.x + 220;
        step2.y = _step.y + 60;
      }
      if (_step.type === 'decision') {
        step2.x = _step.x + 60;
        x = _step.x + 111;
        y = _step.y + 8;
        step2.y = _step.y + 27.5;
      }
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (_step.parentId === diagram.steps[i].id) {
            step2.parentId = _step.parentId;
            if (x + 100 < diagram.steps[i].x2) {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'end',
                x,
                y,
                height: 40,
                width: 100,
                x1: x,
                y1: y,
                x2: x + 100,
                y2: y + 40,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log('End step1====', step1);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            } else {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'end',
                x,
                y,
                height: 40,
                width: 100,
                x1: x,
                y1: y,
                x2: x + 100,
                y2: y + 40,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log(diagram.steps[i].id);
              diagram.steps[i].width += 200;
              // this.increasePoolWidth(diagram.steps, "id", diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            }
          }
        }
      }
    }
    if (type === 'rect') {
      // console.log("step2====", step2);
      if (_step.type === 'start') {
        step2.x = _step.x + 25;
        step2.y = _step.y + 15;
        x = _step.x + 105;
        y = _step.y - 45;
      }
      if (_step.type === 'end') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
        y = _step.y - 40;
      }
      if (_step.type === 'process') {
        step2.x = _step.x + 220;
        step2.y = _step.y + 60;
        x = _step.x + 300;
      }
      if (_step.type === 'decision') {
        if (_step.outputConnectors.length > 0) {
          step2.name = 'secondLine';
          step2.x = _step.x + 30;
          step2.y = _step.y + 55;
          step2.endX = 15;
          step2.endY = 60;
          x = _step.x - 80;
          y = _step.y + 132;
        } else {
          step2.x = _step.x + 60;
          x = _step.x + 140;
          y = _step.y - 35;
          step2.y = _step.y + 27.5;
        }
      }
      if (_step.type === 'terminator') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
      }
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (_step.parentId === diagram.steps[i].id) {
            step2.parentId = _step.parentId;
            if (x + 100 < diagram.steps[i].x2) {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'process',
                x,
                y,
                height: 120,
                width: 220,
                innerRectHeight: 20,
                innerRectWidth: 20,
                d: 'M0,30 L220 30',
                rx: 6,
                x1: x,
                y1: y,
                x2: x + 220,
                y2: y + 120,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log('Process step1====', step1);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            } else {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'process',
                x,
                y,
                height: 120,
                width: 220,
                innerRectHeight: 20,
                innerRectWidth: 20,
                d: 'M0,30 L220 30',
                rx: 6,
                x1: x,
                y1: y,
                x2: x + 220,
                y2: y + 120,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log(diagram.steps[i].id);
              diagram.steps[i].width += 200;
              // this.increasePoolWidth(diagram.steps, "id", diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            }
          }
        }
      }
    }
    if (type === 'diamond') {
      console.log('step2====', step2);
      if (_step.type === 'start') {
        step2.x = _step.x + 27;
        step2.y = _step.y + 15;
        x = _step.x + 108;
        y = _step.y - 12;
      }
      if (_step.type === 'end') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
        y = _step.y - 8;
      }
      if (_step.type === 'process') {
        x = _step.x + 300;
        y = _step.y + 32;
        step2.x = _step.x + 220;
        step2.y = _step.y + 60;
      }
      if (_step.type === 'decision') {
        step2.x = _step.x + 60;
        x = _step.x + 139;
        step2.y = _step.y + 27.5;
      }
      if (_step.type === 'terminator') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
      }
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (_step.parentId === diagram.steps[i].id) {
            step2.parentId = _step.parentId;
            if (x + 100 < diagram.steps[i].x2) {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'decision',
                x,
                y,
                height: 55,
                width: 60,
                x1: x,
                y1: y,
                x2: x + 60,
                y2: y + 55,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log('Decision step1====', step1);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            } else {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'decision',
                x,
                y,
                height: 55,
                width: 60,
                x1: x,
                y1: y,
                x2: x + 60,
                y2: y + 55,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log(diagram.steps[i].id);
              diagram.steps[i].width += 200;
              // this.increasePoolWidth(diagram.steps, "id", diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            }
          }
        }
      }
    }
    if (type === 'ellipse') {
      console.log('step2====', step2);
      if (_step.type === 'start') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
      }
      if (_step.type === 'end') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
      }
      if (_step.type === 'process') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
      }
      if (_step.type === 'decision') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
      }
      if (_step.type === 'terminator') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
      }
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (_step.parentId === diagram.steps[i].id) {
            if (x + 100 < diagram.steps[i].x2) {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'terminator',
                x,
                y,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            } else {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'terminator',
                x,
                y,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log(diagram.steps[i].id);
              diagram.steps[i].width += 200;
              // this.increasePoolWidth(diagram.steps, "id", diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            }
          }
        }
      }
    }
    if (type === 'flow') {
      const {
        diagram,
        dragging,
        isMouseDown,
        selected,
        arrow,
        toolbarHeight,
        connection,
      } = this.state;

      if (_step.type === 'end') {
        let step3 = {
          id: randomId2,
          type: 'line',
          x: _step.x + 70,
          y: _step.y + 20,
          inputConnectors: [],
          outputConnectors: [],
          startX: 0,
          startY: 0,
          endX2: 0,
          endY2: 0,
          endX3: 0,
          endY3: 0,
          endX: 80,
          endY: 0,
        };
        step3.parentId = _step.parentId;
        this.setState(
          {
            diagram: Object.assign({}, diagram, {
              steps: [...diagram.steps, step3],
            }),
            clickStep: {},
          },
          () => {
            this.props.diagramChange(this.state.diagram);
          },
        );
        const step = diagram.steps.find(s => s.id === id);
        step.outputConnectors.push({
          id: randomId2,
          point: 'start',
        });
      } else if (_step.type === 'start') {
        let step3 = {
          id: randomId2,
          type: 'line',
          x: _step.x + 27,
          y: _step.y + 15,
          inputConnectors: [],
          outputConnectors: [],
          startX: 0,
          startY: 0,
          endX2: 0,
          endY2: 0,
          endX3: 0,
          endY3: 0,
          endX: 80,
          endY: 0,
        };
        step3.parentId = _step.parentId;
        this.setState(
          {
            diagram: Object.assign({}, diagram, {
              steps: [...diagram.steps, step3],
            }),
            clickStep: {},
          },
          () => {
            this.props.diagramChange(this.state.diagram);
          },
        );
        const step = diagram.steps.find(s => s.id === id);
        step.outputConnectors.push({
          id: randomId2,
          point: 'start',
        });
      } else if (_step.type === 'process') {
        let step3 = {
          id: randomId2,
          type: 'line',
          x: _step.x + 220,
          y: _step.y + 60,
          inputConnectors: [],
          outputConnectors: [],
          startX: 0,
          startY: 0,
          endX2: 0,
          endY2: 0,
          endX3: 0,
          endY3: 0,
          endX: 80,
          endY: 0,
        };
        step3.parentId = _step.parentId;
        this.setState(
          {
            diagram: Object.assign({}, diagram, {
              steps: [...diagram.steps, step3],
            }),
            clickStep: {},
          },
          () => {
            this.props.diagramChange(this.state.diagram);
          },
        );
        const step = diagram.steps.find(s => s.id === id);
        step.outputConnectors.push({
          id: randomId2,
          point: 'start',
        });
      } else if (_step.type === 'decision') {
        let step3 = {
          id: randomId2,
          type: 'line',
          x: _step.x + 60,
          y: _step.y + 28,
          inputConnectors: [],
          outputConnectors: [],
          startX: 0,
          startY: 0,
          endX2: 0,
          endY2: 0,
          endX3: 0,
          endY3: 0,
          endX: 80,
          endY: 0,
        };
        step3.parentId = _step.parentId;
        this.setState(
          {
            diagram: Object.assign({}, diagram, {
              steps: [...diagram.steps, step3],
            }),
            clickStep: {},
          },
          () => {
            this.props.diagramChange(this.state.diagram);
          },
        );
        const step = diagram.steps.find(s => s.id === id);
        step.outputConnectors.push({
          id: randomId2,
          point: 'start',
        });
      } else {
        let step3 = {
          id: randomId2,
          type: 'line',
          x: _step.x + 100,
          y: _step.y + 20,
          inputConnectors: [],
          outputConnectors: [],
          startX: 0,
          startY: 0,
          endX2: 0,
          endY2: 0,
          endX3: 0,
          endY3: 0,
          endX: 80,
          endY: 0,
        };
        step3.parentId = _step.parentId;
        this.setState(
          {
            diagram: Object.assign({}, diagram, {
              steps: [...diagram.steps, step3],
            }),
            clickStep: {},
          },
          () => {
            this.props.diagramChange(this.state.diagram);
          },
        );
        const step = diagram.steps.find(s => s.id === id);
        step.outputConnectors.push({
          id: randomId2,
          point: 'start',
        });
      }
    }
    if (type === 'delete') {
      const { diagram } = this.state;
      console.log('delete', id);
      console.log(diagram.steps);
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (diagram.steps[i].id === id) {
            this.deleteById(diagram.steps, 'parentId', diagram.steps[i].id);
            // this.deleteById(diagram.steps, 'type', 'line');
          }
        }
        if (diagram.steps[i].id === id) {
          if (
            diagram.steps[i].inputConnectors.length > 0 &&
            diagram.steps[i].outputConnectors.length > 0
          ) {
            for (let j = 0; j < diagram.steps.length; j++) {
              if (diagram.steps[j].outputConnectors.length > 0) {
                if (
                  diagram.steps[j].outputConnectors[0].id === diagram.steps[i].inputConnectors[0].id
                ) {
                  this.deleteById(
                    diagram.steps[j].outputConnectors,
                    'id',
                    diagram.steps[i].inputConnectors[0].id,
                  );
                }
              }
              if (diagram.steps[j].inputConnectors.length > 0) {
                if (
                  diagram.steps[j].inputConnectors[0].id === diagram.steps[i].outputConnectors[0].id
                ) {
                  this.deleteById(
                    diagram.steps[j].inputConnectors,
                    'id',
                    diagram.steps[i].outputConnectors[0].id,
                  );
                }
              }
            }
            this.deleteById(diagram.steps, 'id', diagram.steps[i].inputConnectors[0].id);
            this.deleteById(diagram.steps, 'id', diagram.steps[i].outputConnectors[0].id);
            this.deleteById(diagram.steps, 'id', diagram.steps[i].id);
            this.setState(
              {
                diagram: Object.assign({}, diagram, {
                  steps: [...diagram.steps],
                }),
                clickStep: {},
              },
              () => {
                this.props.diagramChange(this.state.diagram);
              },
            );
            return;
          } else if (
            diagram.steps[i].inputConnectors.length > 0 &&
            diagram.steps[i].outputConnectors.length < 1
          ) {
            for (let j = 0; j < diagram.steps.length; j++) {
              if (diagram.steps[j].outputConnectors.length > 0) {
                for (let k = 0; k < diagram.steps[j].outputConnectors.length; k++) {
                  if (
                    diagram.steps[j].outputConnectors[k].id ===
                    diagram.steps[i].inputConnectors[0].id
                  ) {
                    this.deleteById(
                      diagram.steps[j].outputConnectors,
                      'id',
                      diagram.steps[i].inputConnectors[0].id,
                    );
                  }
                }
              }
            }
            this.deleteById(diagram.steps, 'id', diagram.steps[i].inputConnectors[0].id);
            this.deleteById(diagram.steps, 'id', diagram.steps[i].id);
            this.setState(
              {
                diagram: Object.assign({}, diagram, {
                  steps: [...diagram.steps],
                }),
                clickStep: {},
              },
              () => {
                this.props.diagramChange(this.state.diagram);
              },
            );
            return;
          } else if (
            diagram.steps[i].inputConnectors.length < 1 &&
            diagram.steps[i].outputConnectors.length > 0
          ) {
            for (let j = 0; j < diagram.steps.length; j++) {
              if (diagram.steps[j].inputConnectors.length > 0) {
                if (
                  diagram.steps[j].inputConnectors[0].id === diagram.steps[i].outputConnectors[0].id
                ) {
                  this.deleteById(
                    diagram.steps[j].inputConnectors,
                    'id',
                    diagram.steps[i].outputConnectors[0].id,
                  );
                }
              }
            }
            this.deleteById(diagram.steps, 'id', diagram.steps[i].outputConnectors[0].id);
            this.deleteById(diagram.steps, 'id', diagram.steps[i].id);

            this.setState(
              {
                diagram: Object.assign({}, diagram, {
                  steps: [...diagram.steps],
                }),
                clickStep: {},
              },
              () => {
                this.props.diagramChange(this.state.diagram);
              },
            );
            return;
          } else {
            this.deleteById(diagram.steps, 'id', id);
            this.setState(
              {
                diagram: Object.assign({}, diagram, {
                  steps: [...diagram.steps],
                }),
                clickStep: {},
              },
              () => {
                this.props.diagramChange(this.state.diagram);
              },
            );
            return;
          }
        }
      }
    }
    if(type === 'deleteline')
    {
      const { diagram } = this.state;
      console.log('delete', id);
      console.log(diagram.steps);
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (diagram.steps[i].id === id) {
            this.deleteById(diagram.steps, 'parentId', diagram.steps[i].id);
            // this.deleteById(diagram.steps, 'type', 'line');
          }
        }
        if (diagram.steps[i].id === id) {
          if (
            diagram.steps[i].inputConnectors.length > 0 &&
            diagram.steps[i].outputConnectors.length > 0
          ) {
            for (let j = 0; j < diagram.steps.length; j++) {
              if (diagram.steps[j].outputConnectors.length > 0) {
                if (
                  diagram.steps[j].outputConnectors[0].id === diagram.steps[i].inputConnectors[0].id
                ) {
                  this.deleteById(
                    diagram.steps[j].outputConnectors,
                    'id',
                    diagram.steps[i].inputConnectors[0].id,
                  );
                }
              }
              if (diagram.steps[j].inputConnectors.length > 0) {
                if (
                  diagram.steps[j].inputConnectors[0].id === diagram.steps[i].outputConnectors[0].id
                ) {
                  this.deleteById(
                    diagram.steps[j].inputConnectors,
                    'id',
                    diagram.steps[i].outputConnectors[0].id,
                  );
                }
              }
            }
            this.deleteById(diagram.steps, 'id', diagram.steps[i].inputConnectors[0].id);
            this.deleteById(diagram.steps, 'id', diagram.steps[i].outputConnectors[0].id);
            this.deleteById(
              diagram.steps[i].inputConnectors,
              'id',
              diagram.steps[i].inputConnectors[0].id,
            );
            this.deleteById(
              diagram.steps[i].outputConnectors,
              'id',
              diagram.steps[i].outputConnectors[0].id,
            );

            // this.deleteById(diagram.steps, 'id', diagram.steps[i].id);
            this.setState(
              {
                diagram: Object.assign({}, diagram, {
                  steps: [...diagram.steps],
                }),
                clickStep: {},
              },
              () => {
                this.props.diagramChange(this.state.diagram);
              },
            );
            return;
          } else if (
            diagram.steps[i].inputConnectors.length > 0 &&
            diagram.steps[i].outputConnectors.length < 1
          ) {
            for (let j = 0; j < diagram.steps.length; j++) {
              if (diagram.steps[j].outputConnectors.length > 0) {
                for (let k = 0; k < diagram.steps[j].outputConnectors.length; k++) {
                  if (
                    diagram.steps[j].outputConnectors[k].id ===
                    diagram.steps[i].inputConnectors[0].id
                  ) {
                    this.deleteById(
                      diagram.steps[j].outputConnectors,
                      'id',
                      diagram.steps[i].inputConnectors[0].id,
                    );
                  }
                }
              }
            }
            this.deleteById(diagram.steps, 'id', diagram.steps[i].inputConnectors[0].id);
            this.deleteById(                       
              diagram.steps[i].inputConnectors,
              'id',
              diagram.steps[i].inputConnectors[0].id,
            );
            
            // this.deleteById(diagram.steps, 'id', diagram.steps[i].id);
            this.setState(
              {
                diagram: Object.assign({}, diagram, {
                  steps: [...diagram.steps],
                }),
                clickStep: {},
              },
              () => {
                this.props.diagramChange(this.state.diagram);
              },
            );
            return;
          } else if (
            diagram.steps[i].inputConnectors.length < 1 &&
            diagram.steps[i].outputConnectors.length > 0
          ) {
            for (let j = 0; j < diagram.steps.length; j++) {
              if (diagram.steps[j].inputConnectors.length > 0) {
                if (
                  diagram.steps[j].inputConnectors[0].id === diagram.steps[i].outputConnectors[0].id
                ) {
                  this.deleteById(
                    diagram.steps[j].inputConnectors,
                    'id',
                    diagram.steps[i].outputConnectors[0].id,
                  );
                }
              }
            }
            this.deleteById(diagram.steps, 'id', diagram.steps[i].outputConnectors[0].id);
            this.deleteById(
              diagram.steps[i].outputConnectors,
              'id',
              diagram.steps[i].outputConnectors[0].id,
            );
            
            // this.deleteById(diagram.steps, 'id', diagram.steps[i].id);

            this.setState(
              {
                diagram: Object.assign({}, diagram, {
                  steps: [...diagram.steps],
                }),
                clickStep: {},
              },
              () => {
                this.props.diagramChange(this.state.diagram);
              },
            );
            return;
          } else {
            this.deleteById(diagram.steps, 'id', id);
            this.setState(
              {
                diagram: Object.assign({}, diagram, {
                  steps: [...diagram.steps],
                }),
                clickStep: {},
              },
              () => {
                this.props.diagramChange(this.state.diagram);
              },
            );
            return;
          }
        }
      }
    }
    if (type === 'usertask') {
      console.log('inside usertask');
      if (_step.type === 'start') {
        step2.x = _step.x + 25;
        step2.y = _step.y + 15;
        x = _step.x + 105;
        y = _step.y - 45;
      }
      if (_step.type === 'end') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
        y = _step.y - 40;
      }
      if (_step.type === 'process') {
        step2.x = _step.x + 220;
        step2.y = _step.y + 60;
        x = _step.x + 300;
      }
      if (_step.type === 'decision') {
        if (_step.outputConnectors.length > 0) {
          step2.name = 'secondLine';
          step2.x = _step.x + 30;
          step2.y = _step.y + 55;
          step2.endX = 15;
          step2.endY = 60;
          x = _step.x - 80;
          y = _step.y + 132;
        } else {
          step2.x = _step.x + 60;
          x = _step.x + 140;
          y = _step.y - 35;
          step2.y = _step.y + 27.5;
        }
      }
      if (_step.type === 'terminator') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
      }
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (_step.parentId === diagram.steps[i].id) {
            step2.parentId = _step.parentId;
            if (x + 100 < diagram.steps[i].x2) {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'process',
                subType: 'usertask',
                name: 'usertask',
                x,
                y,
                height: 120,
                width: 220,
                innerRectHeight: 20,
                innerRectWidth: 20,
                d: 'M0,30 L220 30',
                rx: 6,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log('Process step1====', step1);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            } else {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'process',
                subType: 'usertask',
                name: 'usertask',
                x,
                y,
                height: 80,
                width: 100,
                rx: 10,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log(diagram.steps[i].id);
              diagram.steps[i].width += 200;
              // this.increasePoolWidth(diagram.steps, "id", diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            }
          }
        }
      }
    }
    if (type === 'businesstask') {
      console.log('inside businesstask');
      if (_step.type === 'start') {
        step2.x = _step.x + 25;
        step2.y = _step.y + 15;
        x = _step.x + 105;
        y = _step.y - 45;
      }
      if (_step.type === 'end') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
        y = _step.y - 40;
      }
      if (_step.type === 'process') {
        step2.x = _step.x + 220;
        step2.y = _step.y + 60;
        x = _step.x + 300;
      }
      if (_step.type === 'decision') {
        if (_step.outputConnectors.length > 0) {
          step2.name = 'secondLine';
          step2.x = _step.x + 30;
          step2.y = _step.y + 55;
          step2.endX = 15;
          step2.endY = 60;
          x = _step.x - 80;
          y = _step.y + 132;
        } else {
          step2.x = _step.x + 60;
          x = _step.x + 140;
          y = _step.y - 35;
          step2.y = _step.y + 27.5;
        }
      }
      if (_step.type === 'terminator') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
      }
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (_step.parentId === diagram.steps[i].id) {
            step2.parentId = _step.parentId;
            if (x + 100 < diagram.steps[i].x2) {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'process',
                subType: 'businesstask',
                name: 'businesstask',
                x,
                y,
                height: 120,
                width: 220,
                innerRectHeight: 20,
                innerRectWidth: 20,
                d: 'M0,30 L220 30',
                rx: 6,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log('Process step1====', step1);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            } else {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'process',
                subType: 'businesstask',
                name: 'businesstask',
                x,
                y,
                height: 80,
                width: 100,
                rx: 10,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log(diagram.steps[i].id);
              diagram.steps[i].width += 200;
              // this.increasePoolWidth(diagram.steps, "id", diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            }
          }
        }
      }
    }
    if (type === 'systemtask') {
      console.log('inside systemtask');
      if (_step.type === 'start') {
        step2.x = _step.x + 25;
        step2.y = _step.y + 15;
        x = _step.x + 105;
        y = _step.y - 45;
      }
      if (_step.type === 'end') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
        y = _step.y - 40;
      }
      if (_step.type === 'process') {
        step2.x = _step.x + 220;
        step2.y = _step.y + 60;
        x = _step.x + 300;
      }
      if (_step.type === 'decision') {
        if (_step.outputConnectors.length > 0) {
          step2.name = 'secondLine';
          step2.x = _step.x + 30;
          step2.y = _step.y + 55;
          step2.endX = 15;
          step2.endY = 60;
          x = _step.x - 80;
          y = _step.y + 132;
        } else {
          step2.x = _step.x + 60;
          x = _step.x + 140;
          y = _step.y - 35;
          step2.y = _step.y + 27.5;
        }
      }
      if (_step.type === 'terminator') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
      }
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (_step.parentId === diagram.steps[i].id) {
            step2.parentId = _step.parentId;
            if (x + 100 < diagram.steps[i].x2) {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'process',
                subType: 'systemtask',
                name: 'systemtask',
                x,
                y,
                height: 120,
                width: 220,
                innerRectHeight: 20,
                innerRectWidth: 20,
                d: 'M0,30 L220 30',
                rx: 6,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log('Process step1====', step1);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            } else {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'process',
                subType: 'systemtask',
                name: 'systemtask',
                x,
                y,
                height: 80,
                width: 100,
                rx: 10,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log(diagram.steps[i].id);
              diagram.steps[i].width += 200;
              // this.increasePoolWidth(diagram.steps, "id", diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            }
          }
        }
      }
    }
    if (type === 'sendtask') {
      console.log('inside sendtask');
      if (_step.type === 'start') {
        step2.x = _step.x + 25;
        step2.y = _step.y + 15;
        x = _step.x + 105;
        y = _step.y - 45;
      }
      if (_step.type === 'end') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
        y = _step.y - 40;
      }
      if (_step.type === 'process') {
        step2.x = _step.x + 220;
        step2.y = _step.y + 60;
        x = _step.x + 300;
      }
      if (_step.type === 'decision') {
        if (_step.outputConnectors.length > 0) {
          step2.name = 'secondLine';
          step2.x = _step.x + 30;
          step2.y = _step.y + 55;
          step2.endX = 15;
          step2.endY = 60;
          x = _step.x - 80;
          y = _step.y + 132;
        } else {
          step2.x = _step.x + 60;
          x = _step.x + 140;
          y = _step.y - 35;
          step2.y = _step.y + 27.5;
        }
      }
      if (_step.type === 'terminator') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
      }
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (_step.parentId === diagram.steps[i].id) {
            step2.parentId = _step.parentId;
            if (x + 100 < diagram.steps[i].x2) {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'process',
                subType: 'sendtask',
                name: 'sendtask',
                x,
                y,
                height: 120,
                width: 220,
                innerRectHeight: 20,
                innerRectWidth: 20,
                d: 'M0,30 L220 30',
                rx: 6,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log('Process step1====', step1);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            } else {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'process',
                subType: 'sendtask',
                name: 'sendtask',
                x,
                y,
                height: 80,
                width: 100,
                rx: 10,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log(diagram.steps[i].id);
              diagram.steps[i].width += 200;
              // this.increasePoolWidth(diagram.steps, "id", diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            }
          }
        }
      }
    }
    if (type === 'workflowtask') {
      console.log('inside workflowtask');
      if (_step.type === 'start') {
        step2.x = _step.x + 25;
        step2.y = _step.y + 15;
        x = _step.x + 105;
        y = _step.y - 45;
      }
      if (_step.type === 'end') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
        y = _step.y - 40;
      }
      if (_step.type === 'process') {
        step2.x = _step.x + 220;
        step2.y = _step.y + 60;
        x = _step.x + 300;
      }
      if (_step.type === 'decision') {
        if (_step.outputConnectors.length > 0) {
          step2.name = 'secondLine';
          step2.x = _step.x + 30;
          step2.y = _step.y + 55;
          step2.endX = 15;
          step2.endY = 60;
          x = _step.x - 80;
          y = _step.y + 132;
        } else {
          step2.x = _step.x + 60;
          x = _step.x + 140;
          y = _step.y - 35;
          step2.y = _step.y + 27.5;
        }
      }
      if (_step.type === 'terminator') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
      }
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (_step.parentId === diagram.steps[i].id) {
            step2.parentId = _step.parentId;
            if (x + 100 < diagram.steps[i].x2) {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'process',
                subType: 'workflowtask',
                name: 'workflowtask',
                x,
                y,
                height: 120,
                width: 220,
                innerRectHeight: 20,
                innerRectWidth: 20,
                d: 'M0,30 L220 30',
                rx: 6,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log('Process step1====', step1);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            } else {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'process',
                subType: 'workflowtask',
                name: 'workflowtask',
                x,
                y,
                height: 80,
                width: 100,
                rx: 10,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log(diagram.steps[i].id);
              diagram.steps[i].width += 200;
              // this.increasePoolWidth(diagram.steps, "id", diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            }
          }
        }
      }
    }
    if (type === 'datapipelinetask') {
      console.log('inside datapipelinetask');
      if (_step.type === 'start') {
        step2.x = _step.x + 25;
        step2.y = _step.y + 15;
        x = _step.x + 105;
        y = _step.y - 45;
      }
      if (_step.type === 'end') {
        step2.x = _step.x + 70;
        x = _step.x + 150;
        y = _step.y - 40;
      }
      if (_step.type === 'process') {
        step2.x = _step.x + 220;
        step2.y = _step.y + 60;
        x = _step.x + 300;
      }
      if (_step.type === 'decision') {
        if (_step.outputConnectors.length > 0) {
          step2.name = 'secondLine';
          step2.x = _step.x + 30;
          step2.y = _step.y + 55;
          step2.endX = 15;
          step2.endY = 60;
          x = _step.x - 80;
          y = _step.y + 132;
        } else {
          step2.x = _step.x + 60;
          x = _step.x + 140;
          y = _step.y - 35;
          step2.y = _step.y + 27.5;
        }
      }
      if (_step.type === 'terminator') {
        step2.x = _step.x + 100;
        x = _step.x + 180;
      }
      for (let i = 0; i < diagram.steps.length; i++) {
        if (diagram.steps[i].type === 'pool') {
          if (_step.parentId === diagram.steps[i].id) {
            step2.parentId = _step.parentId;
            if (x + 100 < diagram.steps[i].x2) {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'process',
                subType: 'datapipelinetask',
                name: 'datapipelinetask',
                x,
                y,
                height: 120,
                width: 220,
                innerRectHeight: 20,
                innerRectWidth: 20,
                d: 'M0,30 L220 30',
                rx: 6,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log('Process step1====', step1);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            } else {
              const step1 = {
                id: randomId1,
                parentId: _step.parentId,
                type: 'process',
                subType: 'datapipelinetask',
                name: 'datapipelinetask',
                x,
                y,
                height: 80,
                width: 100,
                rx: 10,
                inputConnectors: [
                  {
                    id: randomId2,
                    point: 'end',
                  },
                ],
                outputConnectors: [],
              };
              console.log(diagram.steps[i].id);
              diagram.steps[i].width += 200;
              // this.increasePoolWidth(diagram.steps, "id", diagram.steps[i].id);
              this.setState(
                {
                  diagram: Object.assign({}, diagram, {
                    steps: [...diagram.steps, step1, step2],
                  }),
                  clickStep: {},
                },
                () => {
                  this.props.diagramChange(this.state.diagram);
                },
              );
              const step = diagram.steps.find(s => s.id === id);
              step.outputConnectors.push({
                id: randomId2,
                point: 'start',
              });
            }
          }
        }
      }
    }
  };
  deleteById = (arr, attr, value) => {
    let i = arr.length;
    while (i--) {
      if (arr[i] && arr[i].hasOwnProperty(attr) && arr[i][attr] === value) {
        arr.splice(i, 1);
      }
    }
    return arr;
  };
  increasePoolWidth = (arr, attr, value) => {
    let i = arr.length;
    while (i--) {
      if (arr[i] && arr[i].hasOwnProperty(attr) && arr[i][attr] === value) {
        arr[i].width += 200;
      }
    }
    return arr;
  };
  onCanvasClick = () => {
    this.setState({
      clickStep: {},
      selectStep: {},
      show: false,
    });
  };
  saveWorkflow = () => {
    const { diagram } = this.state;
    this.props.onSaveWorkflow({
      name: 'test',
      description: 'test',
      appId: '5f995ad426063f37f0ff604d',
      diagram: JSON.stringify(diagram),
    });
  };
  handleClickOpen = () => {
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  onChangeProperty = (name, value) => {
    console.log('======name', name);
    console.log('======value', value);
    /// TODO save to digram
    console.log('====state', this.state.diagram);
  };

  render() {
    const { editable, subType } = this.props;
    const {
      diagram,
      isMouseOver,
      rectangularSelection,
      selected,
      toolbarHeight,
      arrow,
      mouseOverConnection,
      mouseOverStep,
      clickStep,
      selectedStep,
    } = this.state;
    // console.log('diagram====', this.state);
    // console.log('Props ====', this.props);
    const { height, width } = diagram;
    const containerStyle = {
      height: toolbarHeight + height,
      width,
      display: 'flex',
    };

    return editable ? (
      <g
        onMouseDown={this.onMouseDown}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseMove={this.onMouseMove}
        onMouseUp={this.onMouseUp}
        style={containerStyle}
      >
        <Toolbar dropToolbarIcon={this.dropToolbarIcon} dragToolbarIcon={this.dragToolbarIcon} />
        <ErrorBoundary>
          <Canvas
            arrow={arrow}
            diagram={diagram}
            clickStep={clickStep}
            setClickStep={this.setClickStep}
            moveArrow={this.moveArrow}
            onDragConnection={this.onDragConnection}
            editElementStep={id => this.editElementStep(id)}
            mouseOverConnection={mouseOverConnection}
            setMouseOverConnection={this.setMouseOverConnection}
            mouseOverStep={mouseOverStep}
            setMouseOverStep={this.setMouseOverStep}
            rectangularSelection={rectangularSelection}
            selected={selected}
            selectStep={this.selectStep}
            stopDragging={this.stopDragging}
            onClickSugessionElement={this.onClickSugessionElement}
            onCanvasClick={() => this.onCanvasClick()}
          />
        </ErrorBoundary>
        {this.state.show && (
          <Modal
            {...this.props}
            open={this.state.show}
            onClose={this.handleClose}
            // {...this.state.diagram[id]}
            selectedstep={selectedStep}
            subtype={subType}
            onChange={e => this.onChangeValue(e.target.name, e.target.value)}
            onChangeValue={(name, value) => this.onChangeProperty(name, value)}
            // onchangevalue={(name, value) => this.onChangeProperty(name, value)}
          />
        )}
        {/* <button className="btn" onClick={() => this.saveWorkflow()}>
          Save
        </button> */}
      </g>
    ) : (
      <Canvas diagram={diagram} />
    );
  }
}

export default hot(withRouter(Redux(Workflow)));
