import React from 'react';
import { withStyles, Button, Tab, Tabs, TableHead, TableRow, TextField, InputAdornment, Icon, Select, MenuItem, Typography, FormControl, FormGroup, Checkbox, RadioGroup, FormControlLabel, Radio, FormLabel, Table, TableCell, TableBody, Input, Chip } from '@material-ui/core';

export default function NetworkDetails(props) {
	const { form } = props.state;
	const { classes, entities, error } = props;
	return (
		form && (
			<div className="p-16 sm:p-24 max-w-2xl">
				<form className="form" noValidate autoComplete="off" >
					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Name *"}
						</FormLabel>
						<TextField
							className="form-control"
							error={form.name === ''}
							autoFocus
							id="name"
							name="name"
							value={form.name ? form.name : ''}
							onChange={props.handleChange}
							variant="outlined"
							color="secondary"
							placeholder="Enter Name"
							required
							fullWidth
							helperText={error.name && error.name}
							error={Boolean(error.name)}
						/>
					</FormControl>

					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Description"}
						</FormLabel>
						<TextField
							className="form-control"
							id="description"
							name="description"
							multiline
							rows={2}
							value={form.description ? form.description : ''}
							onChange={props.handleChange}
							variant="outlined"
							color="secondary"
							placeholder="Enter Description"
							fullWidth
						/>
					</FormControl>

					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Network Type"}
						</FormLabel>
						<RadioGroup
							aria-label="Network Type"
							name="networkType"
							className={classes.group}
							value={form.networkType ? form.networkType : ''}
							onChange={props.handleChange}
							row>
							<FormControlLabel value="quorum" control={<Radio />} label="Quorum" />
							<FormControlLabel value="hyperledger" control={<Radio />} label="Hyperledger" />
							<FormControlLabel value="ethereum" control={<Radio />} label="Ethereum" />
							<FormControlLabel value="qldb" control={<Radio />} label="Amazon QLDB" />
							<FormControlLabel value="database" control={<Radio />} label="Database" />
						</RadioGroup>
					</FormControl>

					{form.participants && form.participants.length > 0 && <FormControl>
						<FormLabel variant="h6" className="label-headings">Select multiple participants.</FormLabel>
						{console.log("props.participants", form.participants)}
						<Select name="participants" className="form-control" id="grouped-native-select"
							variant="outlined" multiple
							onChange={(e) => props.handleChipChange(e, 'participants')}
							value={form.participants ? form.participants : ''} input={<Input />}
							renderValue={(selected) => (
								<div className={classes.chips}>
									{selected && selected.map((item, i) => (
										<Chip key={i} label={item} className={classes.chip} />
									))}
								</div>
							)}>
							{entities && entities.data.map((item, i) => (
								<MenuItem key={i} value={item.name}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>}
				</form>
			</div>
		)
	)
}