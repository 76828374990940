import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { datasourceTypesService } from 'services/index'
import { ActionTypes } from 'constants/index'


async function fetchAsync(func, arg) {
	const response = arg ? await func(arg) : await func()
	return response
}

function* getDatasourceTypes(action) {
	try {
		const dsTypes = yield call(fetchAsync, datasourceTypesService.getDatasourceTypes, action.payload)
		yield put({ type: ActionTypes.GET_DATASOURCE_TYPES_SUCCESS, payload: dsTypes })
	} catch (e) {
		yield put({ type: ActionTypes.GET_DATASOURCE_TYPES_FAILURE, error: e.message })
	}
}

function* getDatasourceType(action) {
	try {
		const member = yield call(fetchAsync, datasourceTypesService.getDatasourceType, action.payload)
		yield put({ type: ActionTypes.GET_MEMBER_SUCCESS, payload: member })
	} catch (e) {
		yield put({ type: ActionTypes.GET_MEMBER_FAILURE, error: e.message })
	}
}

function* saveMember(action) {
	try {
		const member = yield call(fetchAsync, MemberService.saveMember, action.payload)
		yield put({ type: ActionTypes.SAVE_MEMBER_SUCCESS, payload: member })
	} catch (e) {
		let errObj = { firstName: false, lastName: false, email: false };
		if (e.response) {
			errObj = e.response.fields;
			errObj.errorMessage = e.response.message ? e.response.message : "";
			errObj.status = e.response.status ? e.response.status : ""
		}
		yield put({ type: ActionTypes.SAVE_MEMBER_FAILURE, error: errObj })
	}
}

function* updateMember(action) {
	try {
		const member = yield call(fetchAsync, MemberService.updateMember, action.payload)
		yield put({ type: ActionTypes.UPDATE_MEMBER_SUCCESS, payload: member })
	} catch (e) {
		let errObj = { firstName: false, lastName: false, email: false };
		if (e.response) {
			errObj = e.response.fields;
			errObj.errorMessage = e.response.message ? e.response.message : "";
			errObj.status = e.response.status ? e.response.status : ""
		}
		yield put({ type: ActionTypes.UPDATE_MEMBER_FAILURE, error: errObj })
	}
}

function* deleteMember(action) {
	try {
		const member = yield call(fetchAsync, MemberService.deleteMember, action.payload)
		yield put({ type: ActionTypes.DELETE_MEMBER_SUCCESS, payload: member })
	} catch (e) {
		yield put({ type: ActionTypes.DELETE_MEMBER_FAILURE, error: e.message })
	}
}



export function* watchDatasourceTypesSaga() {
	yield takeEvery(ActionTypes.GET_DATASOURCE_TYPES, getDatasourceTypes)
}

export default watchDatasourceTypesSaga;