import { ActionTypes } from 'constants/index'

const initialState = {
	data: null,
	loading: false,
	searchText: "",
	error: "",
	loading: false,
	dataElements: [],
	saveSuccess: false,
    collections: [],
	updateSuccess: false
}

const datasourcesReducer = function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_DATASOURCES_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText,
			}
		}
		case ActionTypes.GET_DATASOURCE_SUCCESS: {
			return {
				...state,
				loading: false,
				data: action.payload,
				saveSuccess: false,
				updateSuccess: false
			}
		}
		case ActionTypes.GET_DATASOURCE_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error
			}
		}
		case ActionTypes.SAVE_DATASOURCE_SUCCESS: {
			return {
				...state,
				loading: false,
				data: action.payload,
				saveSuccess: true
			}
		}
		case ActionTypes.SAVE_DATASOURCE_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error
			}
		}
		case ActionTypes.UPDATE_DATASOURCE_SUCCESS: {
			return {
				...state,
				loading: false,
				data: action.payload,
				updateSuccess: true
			}
		}
		case ActionTypes.UPDATE_DATASOURCE_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error
			}
		}
		case ActionTypes.GET_DATAELEMENTS_SUCCESS: {
			return {
				...state,
				loading: false,
				dataElements: action.payload
			}
		}
		case ActionTypes.GET_DATAELEMENTS_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error
			}
		}
		case ActionTypes.EMPTY_DATAELEMENTS: {
			return {
				...state,
				dataElements: [],
			}
		}
		case ActionTypes.GET_COLLECTIONS_FROM_DATASOURCE_SUCCESS: {
			return {
				...state,
				collections: action.payload
			}
		}
		case ActionTypes.GET_COLLECTIONS_FROM_DATASOURCE_FAILURE: {
			return {
				...state,
				collections: action.payload
			}
		}
		default: {
			return state;
		}
	}
};

export default datasourcesReducer;
