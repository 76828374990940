import React from 'react';
import { withStyles, Button, Tab, Tabs, TableHead, TableRow, TextField, InputAdornment, Icon, Select, MenuItem, Typography, FormControl, FormGroup, Checkbox, RadioGroup, FormControlLabel, Radio, FormLabel, Table, TableCell, TableBody } from '@material-ui/core';

export default function MemberDetails(props) {
	const { form } = props.state;
	const { classes, entities, error } = props;
	return (
		form && (
			<div className="p-16 sm:p-24 max-w-2xl">
				<form className="form" noValidate autoComplete="off" >
					{/* <FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"First Name"}
						</FormLabel>
						<TextField
							className="form-control"
							id="firstName"
							name="firstName"
							value={form.firstName ? form.firstName : ''}
							onChange={props.handleChange}
							variant="outlined"
							color="secondary"
							placeholder="Enter First Name"
							required
							fullWidth
							helperText={error.firstName && error.firstName}
							error={Boolean(error.firstName)}
							autoFocus
						/>
						 {props.showMessage
							&& <FormHelperText id="name-error-text">
								{"Invalid form name"}
							</FormHelperText>} 
					</FormControl>

					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Last Name"}
						</FormLabel>
						<TextField
							className="form-control"
							id="lastName"
							name="lastName"
							value={form.lastName ? form.lastName : ''}
							onChange={props.handleChange}
							variant="outlined"
							color="secondary"
							placeholder="Enter Last Name"
							required
							fullWidth
							helperText={error.lastName && error.lastName}
							error={Boolean(error.lastName)}
							autoFocus={!!error.lastName}
						/>
					</FormControl> */}

					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Email *"}
						</FormLabel>
						<TextField
							className="form-control"
							type="email"
							name="email"
							value={form.email ? form.email : ''}
							onChange={props.handleChange}
							variant="outlined"
							color="secondary"
							placeholder="Enter Email"
							required
							fullWidth
							helperText={error.email && error.email}
							error={Boolean(error.email)}
							autoFocus={!!error.email}
						/>
					</FormControl>

					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Select a company from the list below *"}
						</FormLabel>
						<Select
							className="form-control"
							name="entityId"
							value={form.entityId ? form.entityId : ''}
							onChange={props.handleChange}
							// helperText={error.entityId && error.entityId}
							error={Boolean(error.entityId)}
							color="secondary"
							autoFocus={!!error.entityId}
							required>
							<MenuItem value="">
								<em>{"None"}</em>
							</MenuItem>
							{entities.data &&
								entities.data.map(entity => (
									<MenuItem key={entity._id} value={entity._id}>
										{entity.name}
									</MenuItem>
								))}
						</Select>
						{error?.entityId && <p className={classes.error}>{error?.entityId}</p>}
					</FormControl>

					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Contact"}
						</FormLabel>
						<TextField
							className="mt-8 mb-16"
							id="phone"
							name="phone"
							value={form.phone ? form.phone : ''}
							onChange={props.handleChange}
							placeholder="Enter Contact"
							type="text"
							variant="outlined"
							fullWidth
						/>
					</FormControl>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<FormControl>
							<FormLabel variant="h6" className="label-headings">{"Status"}</FormLabel>
							<RadioGroup
								aria-label="User Status"
								name="status"
								className={classes.group}
								value={form.status ? form.status : ''}
								onChange={props.handleChange}
								row>
								<FormControlLabel
									value="active"
									control={<Radio />}
									label="Active"
								/>
								<FormControlLabel
									value="inactive"
									control={<Radio />}
									label="Inactive"
								/>
								<FormControlLabel
									value="suspended"
									control={<Radio />}
									label="Suspended"
								/>
								<FormControlLabel
									value="terminated"
									control={<Radio />}
									label="Terminated"
								/>
							</RadioGroup>
						</FormControl>
						<Button
							className="whitespace-no-wrap addnewentrybtn mb5"
							variant="contained"
							color="primary"
							style={{height: 42}}
							onClick={() => props.handleDisable()}>
							{"Terminate"}
						</Button>
					</div>

				</form>
			</div>
		)
	)
}