import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './View'
import Redux from './Redux'
import { withRouter } from 'react-router-dom'
import {
	conditionObj, actionObj, dataTypeNumList, dataTypeStringList, actionTypesList, selectedComparatorList,
	retriveconditionsData, retriveActionsData, constructConditionsData, constructActionsData
} from '../../packages/businessrule'
import { Query, Builder, BasicConfig, Utils as QbUtils } from 'react-awesome-query-builder';

const Container = (props) => {

	const [state, setState] = useState({
		form: {
			queryBuilder: { "id": QbUtils.uuid(), "type": "group" },
			queryDatamodel: ''
		},
		article: '',
		isSubmitButton: true,
		conditionsUIList: [],
		actionsUIList: [],
		dataModelList: [],
		dataElementList: [],
		forms: false,
	});

	const handleChange = (event) => {
		setState({ ...state, form: _.set({ ...state.form }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value) });
	};

	const updateBusinessRuleState = () => {
		const params = props.match.params;
		const { businessRuleId, dappId } = params;
		props.getDataModels(props.match.params.dappId);
		if (businessRuleId === 'new') {
			props.newBusinessRule();
		}
		else {
			props.getBusinessRule(props.match.params);
			resetState();
		}
	};

	const updateFormState = () => {
		let conditionList = [], actionList = [];
		if (props.businessRule.data && props.dataModels.data && props.businessRule.data.ruleset) {
			let conditionData = props.businessRule.data.ruleset[0].rule_definition.conditions;
			if (conditionData.length > 0) {
				conditionList = retriveconditionsData(conditionData, props.dataModels);
			} else {
				conditionList.push(conditionObj());
			}
			let actionData = props.businessRule.data.ruleset[0].rule_definition.actions;
			if (actionData && actionData.length > 0) {
				actionList = retriveActionsData(actionData, props.dataModels)
			} else {
				actionList.push(actionObj());
			}
			let updateObj = {}
			updateObj.form = { ...state.form, ...props.businessRule.data }
			if (props.businessRule.data && props.businessRule.data.ruleset) {
				updateObj.conditionsUIList = conditionList;
				updateObj.actionsUIList = actionList;
			}
			setState({ ...state, ...updateObj });
		} else {
			if (state.conditionsUIList.length === 0) {
				addConditions();
			}
			if (state.actionsUIList.length === 0) {
				addActions();
			}
		}
	};

	const handleChipChange = (event, name) => {
		setState({ ...state, form: _.set({ ...state.form }, name, event.target.value) });
	};

	const canBeSubmitted = () => {
		const { form } = state;
		// console.log("isBussinessRuleUpdated", isBussinessRuleUpdated);
		return (form && form.name && form.name.length > 0 && isBussinessRuleUpdated()
			&& state.isSubmitButton
		);
	}

	const isBussinessRuleUpdated = () => {
		let isUpdate = false
		if (!_.isEqual(props.businessRule.data, state.form)) {
			return true;
		}
		if (props.businessRule.data && props.businessRule.data.ruleset) {
			let conditionData = props.businessRule.data.ruleset[0].rule_definition.conditions;
			let conditionList = retriveconditionsData(conditionData, props.dataModels);
			if (!_.isEqual(conditionList, state.conditionsUIList)) {
				return true;
			}
			let actionData = props.businessRule.data.ruleset[0].rule_definition.actions;
			let actionList = retriveActionsData(actionData, props.dataModels)
			if (!_.isEqual(actionList, state.actionsUIList)) {
				return true;
			}
			isUpdate = false;
		}
		return isUpdate;
	}

	useEffect(() => {
		updateBusinessRuleState();
	}, [props.location])

	useEffect(() => {
		updateFormState();
	}, [props.businessRule.data])

	useEffect(() => {
		if (props.businessRule.saveSuccess) {
			props.showAlert('Businessrule saved successfully', { id: "businessrule", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/businessRules/${props.businessRule.data._id}` });
		}
		if (props.businessRule.updateSuccess) {
			props.showAlert('Businessrule updated successfully', { id: "businessrule", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/businessRules/${props.businessRule.data._id}` });
		}
	}, [props.businessRule.saveSuccess, props.businessRule.updateSuccess])


	const saveBusinessRuleData = () => {
		setState({ ...state, isSubmitButton: false });
		props.saveBusinessRule({ ...state.form, appId: props.match.params.dappId });
	}


	const updateBusinessRuleData = () => {
		setState({ ...state, isSubmitButton: false });
		props.updateBusinessRule({ ...state.form, appId: props.match.params.dappId });
	}

	const resetState = () => {
		setState({
			...state,
			article: '',
			form: props.businessRule.data,
			conditionsUIList: [],
			actionsUIList: [],
			dataModelList: [],
			dataElementList: [],
			forms: false
		})
		addConditions();
		addActions();
	}

	const handleDataModelLeftChange = (event, index) => {
		let list = props.dataModels.data
		let conditionData = state.conditionsUIList;
		for (let i = 0; i < list.length; i++) {
			if (list[i]._id === event.target.value) {
				conditionData[index].dataModelLeftSelected = event.target.value;
				conditionData[index].dataElementLeftList = [...list[i].dataElements]
				setState({ ...state, conditionsUIList: conditionData });
				break;
			} else {
				conditionData[index].dataModelLeftSelected = "";
				conditionData[index].dataElementLeftList = []
				setState({ ...state, conditionsUIList: conditionData });
			}
		}
	};

	const handleElementsLeftChange = (event, index) => {
		let conditionData = state.conditionsUIList;
		conditionData[index].dataElementLeftSelected = event.target.value;
		for (let i = 0; i < conditionData[index].dataElementLeftList.length; i++) {
			if (conditionData[index].dataElementLeftList[i].name === event.target.value) {
				conditionData[index].dataElementType = conditionData[index].dataElementLeftList[i].dataElementType;
				break;
			} else {
				conditionData[index].dataElementType = "";
			}
		}
		setState({ ...state, conditionsUIList: conditionData });
	}

	const handleOperatorChange = (event, index) => {
		let conditionData = state.conditionsUIList;
		conditionData[index].operatorSelected = event.target.value;
		setState({ ...state, conditionsUIList: conditionData });
	}

	const handleCompartorChange = (event, index) => {
		let conditionData = state.conditionsUIList;
		conditionData[index].selectedComparator = event.target.value;
		setState({ ...state, conditionsUIList: conditionData });
	}

	const handleInpuFieldChange = (data, index) => {
		let conditionData = state.conditionsUIList;
		conditionData[index].inputField = data.target.value;
		setState({ ...state, conditionsUIList: conditionData });
	}

	const handleDataModelRightChange = (event, index) => {
		let list = props.dataModels.data
		let conditionData = state.conditionsUIList;
		for (let i = 0; i < list.length; i++) {
			if (list[i]._id === event.target.value) {
				conditionData[index].dataModelRightSelected = event.target.value;
				conditionData[index].dataElementRightList = [...list[i].dataElements]
				setState({ ...state, conditionsUIList: conditionData });
				break;
			} else {
				conditionData[index].dataModelRightSelected = "";
				conditionData[index].dataElementRightList = [];
				setState({ ...state, conditionsUIList: conditionData });
			}
		}
	};

	const handleElementsRightChange = (event, index) => {
		let conditionData = state.conditionsUIList;
		conditionData[index].dataElementRightSelected = event.target.value;
		setState({ ...state, conditionsUIList: conditionData });
	}

	const handleActionTypeChange = (event, index) => {
		let actionsData = state.actionsUIList;
		actionsData[index].actionTypeSelected = event.target.value;
		setState({ ...state, actionsUIList: actionsData });
	}

	const handleDataModelActionChange = (event, index) => {
		let list = props.dataModels.data
		let actionsData = state.actionsUIList;
		for (let i = 0; i < list.length; i++) {
			if (list[i]._id === event.target.value) {
				actionsData[index].dataModelSelected = event.target.value;
				actionsData[index].dataElementList = [...list[i].dataElements];
				actionsData[index].dataElementMultiList = list[i].dataElements.map((item) => ({ label: item.name, value: item.name }));
				actionsData[index].dataElementMultiSelected = [];
				setState({ ...state, actionsUIList: actionsData });
				break;
			} else {
				actionsData[index].dataModelSelected = "";
				actionsData[index].dataElementList = [];
				actionsData[index].dataElementMultiList = [];
				actionsData[index].dataElementMultiSelected = [];
				setState({ ...state, actionsUIList: actionsData });
			}
		}
	};

	const handleElementsActionChange = (event, index) => {
		let actionsData = state.actionsUIList;
		actionsData[index].dataElementSelected = event.target.value;
		setState({ ...state, actionsUIList: actionsData });
	}

	const handleMultipleDataChange = (data, index) => {
		let actionsData = state.actionsUIList;
		actionsData[index].dataElementMultiSelected = data.target.value;
		setState({ ...state, actionsUIList: actionsData })
	}

	const handleSetValueChange = (data, index) => {
		let actionsData = state.actionsUIList;
		actionsData[index].setValue = data.target.value;
		setState({ ...state, actionsUIList: actionsData });
	}

	const handleDataValueChange = (data, index) => {
		let actionsData = state.actionsUIList;
		actionsData[index].dataValue = data.target.value;
		setState({ ...state, actionsUIList: actionsData })
	}

	const addConditions = () => {
		setState({ ...state, conditionsUIList: [conditionObj(), ...state.conditionsUIList] });
	}

	const addActions = () => {
		setState({ ...state, actionsUIList: [actionObj(), ...state.actionsUIList] });
	}

	const removeConditions = (index) => {
		let list = state.conditionsUIList;
		list.splice(index, 1);
		setState({ ...state, conditionsUIList: list }, () => console.log(state.conditionsUIList));
	}

	const removeActions = (index) => {
		let list = state.actionsUIList;
		list.splice(index, 1);
		setState({ ...state, actionsUIList: list }, () => console.log(state.actionsUIList));
	}
	const executeRule = () => {
		if (state.form._id)
			props.executeRule(state.form._id, props.match.params.dappId);
		else
			alert("please save the businessRule")
	}
	const saveDetails = (isNew, appId) => {
		let data = state.form;
		data.description = "";
		data.ruleset = [];
		data.ruleset.push({ "rule_definition": {} });
		data.ruleset[0].rule_definition.conditions = constructConditionsData(state.conditionsUIList, props.dataModels);
		data.ruleset[0].rule_definition.actions = constructActionsData(state.actionsUIList, props.dataModels);
		data.appId = props.match.params.dappId
		if (isNew) {
			props.saveBusinessRule(data);
		} else {
			props.updateBusinessRule(data);
		}
	}

	const handleChangeTree = (treeData) => {
		setState({ ...state, form: { ...state.form, queryBuilder: treeData } });
	}

	const handleQueryDatamodelChange = (event) => {
		setState({ ...state, form: { ...state.form, queryDatamodel: event.target.value } });
	}

	return (
		<View
			{...props}
			state={state}
			handleChange={handleChange}
			handleChipChange={handleChipChange}
			canBeSubmitted={canBeSubmitted}
			saveBusinessRule={() => saveBusinessRuleData()}
			updateBusinessRule={() => updateBusinessRuleData()}
			handleDataModelLeftChange={handleDataModelLeftChange}
			handleElementsLeftChange={handleElementsLeftChange}
			handleOperatorChange={handleOperatorChange}
			handleCompartorChange={handleCompartorChange}
			handleDataModelRightChange={handleDataModelRightChange}
			handleElementsRightChange={handleElementsRightChange}
			handleInpuFieldChange={handleInpuFieldChange}
			handleDataModelActionChange={handleDataModelActionChange}
			handleElementsActionChange={handleElementsActionChange}
			handleMultipleDataChange={handleMultipleDataChange}
			handleSetValueChange={handleSetValueChange}
			handleDataValueChange={handleDataValueChange}
			handleActionTypeChange={handleActionTypeChange}
			handleChangeTree={handleChangeTree}
			handleQueryDatamodelChange={handleQueryDatamodelChange}
			removeConditions={removeConditions}
			addConditions={addConditions}
			removeActions={removeActions}
			addActions={addActions}
			selectedComparatorList={selectedComparatorList}
			actionTypesList={actionTypesList}
			dataTypeNumList={dataTypeNumList}
			dataTypeStringList={dataTypeStringList}
			saveDetails={saveDetails}
			executeRule={executeRule}
		/>
	)
}

export default hot(withRouter(Redux(Container)))