import { Button, FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup, TextField } from "@material-ui/core";
import React, { useState } from "react";
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles((theme) => ({
    typeSection: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '60%',
        marginBottom: 15,
    },
    tokenContainer: {
        height: 60,
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#cecece',
        paddingLeft: 15,
        paddingRight: 15,
    },
    lockbox: {
        display: 'flex'
    },
    textInput: {
        width: '100%',
        height: 42,
        padding: 0,
    }
}))

const credsType = {
    SMTP: 'SMTP',
    SendGrid: 'SendGrid',
}

export const EmailCreds = (props) => {

    const classes = useStyles();
    const [credsInputVisible, setCredsInputVisible] = useState(false);
    const [passwordInputVisible, setPasswordInputVisible] = useState(false);

    return (<div className="form">
        <FormControl component="fieldset" className={classes.typeSection}>
            <FormLabel component="legend" style={{ marginBottom: 15 }}>Email Credentials Type</FormLabel>
            <RadioGroup
                row
                aria-label="gender"
                defaultValue="female"
                name="emailCredType"
                onChange={props.handleUIChange}
                value={props.form.emailCredType || ''}
            >
                <FormControlLabel value={credsType.SMTP} control={<Radio />} label="SMTP" />
                <FormControlLabel value={credsType.SendGrid} control={<Radio />} label="SendGrid" />
            </RadioGroup>
        </FormControl>

        {
            props.form.emailCredType === credsType.SendGrid &&
            <div>
                <FormLabel component="legend" style={{ marginBottom: 15, }}>Send Grid Token</FormLabel>
                <div className={classes.tokenContainer}>
                    <div className={classes.lockbox}>
                        <LockIcon style={{ margin: 'auto' }} />
                        <FormLabel component="legend" style={{ margin: 'auto', marginLeft: 15, }}>{!credsInputVisible ? 'Concealed for confidentiality' : 'Enter sendgrid token'}</FormLabel>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Button variant="contained" style={{ alignSelf: 'center' }} color="primary" size="small" onClick={() => setCredsInputVisible(!credsInputVisible)}>
                            {!credsInputVisible ? 'Replace' : 'Hide'}
                        </Button>
                    </div>
                </div>
                {credsInputVisible &&
                    <TextField
                        className="form-control"
                        name="sgToken"
                        type="password"
                        rows={2}
                        id="password"
                        onChange={props.handleUIChange}
                        value={props.form.sgToken || ''}
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        helperText={props.error.sgToken && props.error.sgToken}
                        error={Boolean(props.error.sgToken)}
                    />
                }
            </div>
        }
        {
            props.form.emailCredType === credsType.SMTP &&
            <form className="form" noValidate autoComplete="off" style={{ width: '60%' }}>
                <FormControl>
                    <FormLabel variant="h6" className="label-headings">
                        {"Email Host URL *"}
                    </FormLabel>
                    <TextField
                        name="emailHost"
                        variant="outlined"
                        onChange={props.handleUIChange}
                        value={props.form.emailHost || ''}
                        color="secondary"
                        className="form-control"
                        variant="outlined"
                        fullWidth
                        required
                        autoFocus
                        helperText={props.error.emailHost && props.error.emailHost}
                        error={Boolean(props.error.emailHost)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel variant="h6" className="label-headings">
                        {"Email Host PORT *"}
                    </FormLabel>
                    <TextField
                        name="emailDomainPort"
                        className={classes.textInput}
                        onChange={props.handleUIChange}
                        value={props.form.emailDomainPort || ''}
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        helperText={props.error.emailDomainPort && props.error.emailDomainPort}
                        error={Boolean(props.error.emailDomainPort)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel variant="h6" className="label-headings">
                        {"Email *"}
                    </FormLabel>
                    <TextField
                        className={classes.textInput}
                        name="emailUser"
                        onChange={props.handleUIChange}
                        value={props.form.emailUser || ''}
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        helperText={props.error.emailUser && props.error.emailUser}
                        error={Boolean(props.error.emailUser)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel variant="h6" className="label-headings">
                        {"Password *"}
                    </FormLabel>
                    <div className={classes.tokenContainer}>
                        <div className={classes.lockbox}>
                            <LockIcon style={{ margin: 'auto' }} />
                            <FormLabel component="legend" style={{ margin: 'auto', marginLeft: 15,paddingTop:0 }}>{!passwordInputVisible ? 'Concealed for confidentiality' : 'Enter user password'}</FormLabel>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Button variant="contained" style={{ alignSelf: 'center' }} color="primary" size="small" onClick={() => setPasswordInputVisible(!passwordInputVisible)}>
                                {!passwordInputVisible ? 'Replace' : 'Hide'}
                            </Button>
                        </div>
                    </div>
                    {passwordInputVisible &&
                        <TextField
                            className="form-control"
                            name="emailPassword"
                            type="password"
                            id="password"
                            onChange={props.handleUIChange}
                            value={props.form.emailPassword || ''}
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            helperText={props.error.emailPassword && props.error.emailPassword}
                            error={Boolean(props.error.emailPassword)}
                        />
                    }
                </FormControl>
            </form>
        }
    </div>)
}