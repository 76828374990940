import React from 'react';

const UserTask = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      x={props.x}
      y={props.y}
      viewBox="0 0 40 40"
      onClick={props.onClick}
    >
      <g transform="translate(-20 -301)">
        <g transform="translate(20 301)" opacity="0">
          <g fill="#000">
            <path
              d="M 34.05941009521484 39.5 L 5.940599918365479 39.5 C 2.940639972686768 39.5 0.5 37.05936050415039 0.5 34.05941009521484 L 0.5 5.940599918365479 C 0.5 2.940639972686768 2.940639972686768 0.5 5.940599918365479 0.5 L 34.05941009521484 0.5 C 37.05936050415039 0.5 39.5 2.940639972686768 39.5 5.940599918365479 L 39.5 34.05941009521484 C 39.5 37.05936050415039 37.05936050415039 39.5 34.05941009521484 39.5 Z"
              stroke="none"
            />
            <path
              d="M 5.940589904785156 1 C 3.216339111328125 1 1 3.216339111328125 1 5.940589904785156 L 1 34.05941009521484 C 1 36.78366088867188 3.216339111328125 39 5.940589904785156 39 L 34.05941009521484 39 C 36.78366088867188 39 39 36.78366088867188 39 34.05941009521484 L 39 5.940589904785156 C 39 3.216339111328125 36.78366088867188 1 34.05941009521484 1 L 5.940589904785156 1 M 5.940589904785156 0 L 34.05941009521484 0 C 37.34030151367188 0 40 2.659690856933594 40 5.940589904785156 L 40 34.05941009521484 C 40 37.34030151367188 37.34030151367188 40 34.05941009521484 40 L 5.940589904785156 40 C 2.659690856933594 40 0 37.34030151367188 0 34.05941009521484 L 0 5.940589904785156 C 0 2.659690856933594 2.659690856933594 0 5.940589904785156 0 Z"
              stroke="none"
              fill="#e7e7f7"
            />
          </g>
        </g>
        <g transform="translate(20 301)">
          <g transform="translate(-0.6 -1.5)">
            <g transform="translate(10.6 12.5)">
              <g transform="translate(0 0)">
                <path
                  d="M64.4,37.3c1.977,0,4.754,2.552,4.754,6.681a1.652,1.652,0,0,1-.025.35,5.262,5.262,0,0,0-4.579-3.5,4.531,4.531,0,0,0-.576-1.026A7.283,7.283,0,0,0,62.4,38.026,3.578,3.578,0,0,1,64.4,37.3Z"
                  transform="translate(-49.438 -31.094)"
                  fill="#000"
                />
                <path
                  d="M65.052,12.5a2.536,2.536,0,0,1,2.527,2.527,2.437,2.437,0,0,1-.45,1.451A2.716,2.716,0,0,1,65.1,17.5a2.32,2.32,0,0,1-1.476-.475,4.357,4.357,0,0,0,.325-1.6,4.028,4.028,0,0,0-.651-2.152A2.3,2.3,0,0,1,65.052,12.5Z"
                  transform="translate(-50.113 -12.5)"
                  fill="#000"
                />
                <path
                  d="M57.929,56.4a5.173,5.173,0,0,1,.926.125,3.843,3.843,0,0,1,2.9,3.428,1.652,1.652,0,0,1,.025.35,3.33,3.33,0,0,1-.125,1,3.888,3.888,0,0,1-5.98,2.177,4.024,4.024,0,0,1-1.1-1.151A3.867,3.867,0,0,1,57.3,56.475a.551.551,0,0,1,.2-.025A1.39,1.39,0,0,1,57.929,56.4Zm2.3,3.153a.58.58,0,0,0,.1-.626.606.606,0,0,0-.976-.175c-.651.726-1.3,1.476-1.977,2.2-.225-.225-.475-.45-.7-.676a.621.621,0,0,0-.976.2.537.537,0,0,0,.15.626l1.126,1.076a.6.6,0,0,0,.425.175.627.627,0,0,0,.45-.2L58.5,61.4C59.1,60.8,59.68,60.178,60.231,59.553Z"
                  transform="translate(-43.14 -45.415)"
                  fill="#000"
                />
                <path
                  d="M35.882,41.276a6.034,6.034,0,0,1,1.8,1.977c-.125.025-.3.075-.425.1a5.172,5.172,0,0,0-3.7,4.955,4.955,4.955,0,0,0,.45,2.1,10.325,10.325,0,0,1-4.129-.626A2.718,2.718,0,0,1,29,49.258a1.693,1.693,0,0,1-.45-.651,1.865,1.865,0,0,1-.15-.776,7.988,7.988,0,0,1,3.153-6.681,3.987,3.987,0,0,1,1.076-.551,3.1,3.1,0,0,1,1.026-.2,3.685,3.685,0,0,1,1.2.25A6.644,6.644,0,0,1,35.882,41.276Z"
                  transform="translate(-23.946 -33.419)"
                  fill="#000"
                />
                <path
                  d="M41.153,12.9A2.753,2.753,0,1,1,38.4,15.653,2.745,2.745,0,0,1,41.153,12.9Z"
                  transform="translate(-31.444 -12.8)"
                  fill="#000"
                />
                <path
                  d="M10.6,43.881c0-4.129,2.778-6.681,4.754-6.681a3.467,3.467,0,0,1,1.877.651,6.51,6.51,0,0,0-1.7,1.877,10.2,10.2,0,0,0-1.7,5.68,2.155,2.155,0,0,0,.075.7,4.775,4.775,0,0,1-2.727-.951A1.611,1.611,0,0,1,10.6,43.881Z"
                  transform="translate(-10.6 -31.019)"
                  fill="#000"
                />
                <path
                  d="M19.4,15.127A2.536,2.536,0,0,1,21.927,12.6a2.417,2.417,0,0,1,1.652.626,3.978,3.978,0,0,0-.7,2.3,4.559,4.559,0,0,0,.35,1.752,2.231,2.231,0,0,1-1.3.4,2.494,2.494,0,0,1-1.8-.776,2.347,2.347,0,0,0-.225-.3A2.445,2.445,0,0,1,19.4,15.127Z"
                  transform="translate(-17.198 -12.575)"
                  fill="#000"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default UserTask;
