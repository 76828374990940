import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button, TextField, FormControl, FormLabel } from '@material-ui/core'
import Upload from 'components/@upload/Upload'

const fileView = (props) => {
	let { form, classes, testDatasource, handleChange, handleFileChange } = props
	return (<div className="max-w-2xl">
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6">{"File/Folder"}</FormLabel>
			<Upload handleFileChange={handleFileChange} />
		</FormControl>
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"File/Folder Path"}</FormLabel>
			<TextField
				id="filePath"
				name="filePath"
				placeholder="File/Folder Path"
				className={classes.textField}
				value={form.filePath}
				onChange={handleChange}
				margin="normal"
				disabled
				variant="outlined"
			/>
		</FormControl>
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"User Id"}</FormLabel>
			<TextField
				id="filePathUserId"
				name="filePathUserId"
				placeholder="User Id"
				className={classes.textField}
				value={form.filePathUserId}
				onChange={handleChange}
				margin="normal"
				variant="outlined"
			/>
		</FormControl>
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Password"}</FormLabel>
			<TextField
				id="filePathUserPassword"
				name="filePathUserPassword"
				placeholder="Password"
				className={classes.textField}
				value={form.filePathUserPassword}
				onChange={handleChange}
				margin="normal"
				variant="outlined"
			/>
		</FormControl>
		<Button
			className="whitespace-no-wrap"
			variant="contained"
			onClick={() => testDatasource(form, props.match.params.dappId)}>
			{"Test"}
		</Button>
		<br />
	</div>)
}

export default withRouter(fileView)