import React, { useEffect, useState } from 'react'
import ImageButtonElement from './ImageButtonElement'
import listJson from './ComponentListJson'

const GenerateCompList = ({ columns }) => {
	const [compList, setCompList] = useState([])
	useEffect(() => {
		let c = columns && columns > 0 ? columns : 1
		const compListContainer = []
		for (let i = 0; i < listJson.length; i = i + c) {
			const items = []
			for (let j = 0; j < c; j++) {
				items.push(<ImageButtonElement key={i + j}
					{...listJson[i + j]}
				/>)
			}
			compListContainer.push(<div key={"componentContainer" + i}
				className="componentContainer">{items}</div>)
		}
		setCompList(compListContainer)
	}, [columns])

	return (<>{compList}</>)
}

export default GenerateCompList