import { all, fork } from 'redux-saga/effects'

import login from './login'
import navigation from './navigation'
import dapps from './dapps'
import roles from './roles'
import entities from './entities'
import formulas from './formulas'
import members from './members'
import privileges from './privileges'
import datasources from './datasources'
import dataModels from './dataModels'
import pages from './pages'
import businessRules from './businessRules'
import networks from './networks'
import pipelines from './pipelines'
import notifications from './notifications'
import jobs from './jobs'
import workflows from './workflows'
import forms from './forms'
import flowchart from './flowchart'
import datasourceTypes from './datasourceTypes'
import groups from './groups'
/**
 * rootSaga
 */
export default function* root() {
	yield all([
		fork(login),
		fork(navigation),
		fork(dapps),
		fork(roles),
		fork(members),
		fork(entities),
		fork(formulas),
		fork(privileges),
		fork(datasources),
		fork(dataModels),
		fork(pages),
		fork(businessRules),
		fork(networks),
		fork(pipelines),
		fork(notifications),
		fork(jobs),
		fork(workflows),
		fork(forms),
		fork(flowchart),
		fork(datasourceTypes),
		fork(groups)
	])
}
