import React, { useState, useEffect } from 'react'
import {
	Checkbox, Button, TableHead, TableRow, Typography, Table, TableCell, TableBody,
	Tabs, Tab, AppBar
} from '@material-ui/core'
import Upload from '../@upload/Upload';
import DataModalTable from './dataModalTable'

const DataElements = (props) => {

	const [state, setState] = useState({ tabValue: 0 });

	const onRowClick = (dataelement, eve) => {
		props.openDataElementDialog(dataelement)
	}
	let { form, dataElements } = props.state


	const handleChangeTab = (event, tabValue) => {
		setState({ ...state, tabValue });
	};

	let systemElements = ['workflow', '_id', 'createdDate', 'updatedDate',
		'createdBy', 'updatedBy', 'lastModifiedDate', 'lastModifiedBy',
		'version', '_v', '__v'
	]

	const filterWorkflowFields = (data) => {
		return data.filter(ele => systemElements.find(se => ele.name.includes(se)))
	}
	const filterFormulaFields = (data) => {
		return data.filter(ele => ele.formula)
	}
	const commonFormulaFields = (data) => {
		return data.filter(ele => !(ele.formula || systemElements.find(se => ele.name.includes(se))))
	}

	return (<>
		<Typography component="legend">{"Data Elements"}</Typography>
		<br />
		{form.allowExternalElements && form.allowExternalElements === true && <>
			<div style={{ marginLeft: "10px" }}>
				<div style={{ float: 'left' }}>
					<Button
						className="whitespace-no-wrap"
						variant="contained"
						color="primary"
						onClick={() => props.openDataElementDialog("new")}>
						{"New Data Element"}
					</Button>
				</div>
				<div style={{ float: 'left' }} className='w260'>
					<Upload handleFileChange={props.handleFileChange} type="ds"
						name="ADD DATA ELEMENTS WITH FILE" dmId={form._id} />
				</div>
			</div>
		</>}
		<AppBar position="static"
			elevation={0}
			color="default"
			className="tabPropertiesPanel">
			<Tabs
				indicatorColor="primary"
				textColor="inherit"
				aria-label="wrapped label tabs example"
				value={state.tabValue}
				onChange={handleChangeTab}
			>
				<Tab className="h-64 normal-case" label="Data Elements" />
				<Tab className="h-64 normal-case" label=" Derived Elements" />
				<Tab className="h-64 normal-case" label="System Elements" />

			</Tabs>
		</AppBar>
		{state.tabValue === 0 &&
			<DataModalTable tableDataElements={commonFormulaFields(props.state.dataElements)} {...props} />}

		{state.tabValue === 1 &&
			<DataModalTable tableDataElements={filterFormulaFields(props.state.dataElements)} {...props} />}

		{state.tabValue === 2 &&
			<DataModalTable tableDataElements={filterWorkflowFields(props.state.dataElements)} {...props} />}
	</>)
}

export default DataElements
