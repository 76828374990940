import React, { Component } from 'react';
import { NativeSelect, TableRow, withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as Actions from 'actions'
import _ from 'modules/lodash';

const styles = theme => ({
	root: {}
});

class WorkflowsRenderer extends Component {

	componentDidMount() {
		const { workflows } = this.props;
		if (workflows.length === 0) {
			this.props.getWorkflows(this.props.match.params.dappId, 'workflow');
		}
	}


	render() {
		return (
			<div className="w-full flex flex-col">
				<NativeSelect style={{ "width": "100%", "paddingTop": "10px" }}
					onChange={(e) => { this.props.selectWorkFlow(e.target.value) }}
				>
					<option selected disabled>Select a Workflow</option>
					{this.props.workflows && this.props.workflows.map((element, index) => {
						return <option value={element._id} selected={element._id == this.props.selectedWorkFlow} >{element.name}</option>
					})}
				</NativeSelect>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getWorkflows: Actions.getWorkflows
	}, dispatch);
}

function mapStateToProps({ workflows }) {
	return {
		workflows: workflows.data,
	}
}

export default withStyles(styles, { withTheme: true })(
	withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkflowsRenderer))
);
