import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './view'
import Redux from './redux'
import { withRouter } from 'react-router-dom'

const Container = (props) => {

	const [state, setState] = useState({
		tabValue: 0,
		form: { name: '' },
		selected: [],
		selectedDataElement: "",
		isSubmitButton: true
	});


	const handleChange = (event) => {
		setState({ ...state, form: _.set({ ...state.form }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value) });
	};

	const updateJobState = () => {
		const params = props.match.params;
		const { jobId, dappId } = params;

		if (jobId === 'new') {
			props.newJob();
		}
		else {
			props.getJob(props.match.params);
		}
	};

	const updateFormState = () => {
		console.log("fire");
		setState({
			...state, form: props.job.data
		})
	};

	const handleChipChange = (event, name) => setState({ ...state, form: _.set({ ...state.form }, name, event.target.value) })

	const canBeSubmitted = () => {
		const { form } = state;
		return (form && form.name && form.name.length > 0 &&
			!_.isEqual(props.job.data, state.form) && state.isSubmitButton
		);
	}

	useEffect(() => {
		if (!_.isEqual(props.job.data, state.form)) {
			setState({ ...state, isSubmitButton: true })
		}
	}, [state.form])

	useEffect(() => {
		updateJobState();
	}, [props.location])

	useEffect(() => {
		updateFormState();
	}, [props.job.data])

	useEffect(() => {
		if (props.job.saveSuccess) {
			props.showAlert('Job saved successfully', { id: "job", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/jobs/${props.job.data._id}` });
		}
		if (props.job.updateSuccess) {
			props.showAlert('Job updated successfully', { id: "job", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/jobs/${props.job.data._id}` });
		}
	}, [props.job.saveSuccess, props.job.updateSuccess])


	const saveJobData = () => {
		setState({ ...state, isSubmitButton: false });
		props.saveJob({ ...state.form, appId: props.match.params.dappId });
	}


	const updateJobData = () => {
		setState({ ...state, isSubmitButton: false });
		props.updateJob({ ...state.form, appId: props.match.params.dappId });
	}

	return (
		<View
			{...props}
			state={state}
			handleChange={handleChange}
			handleChipChange={handleChipChange}
			canBeSubmitted={canBeSubmitted}
			saveJob={() => saveJobData()}
			updateJob={() => updateJobData()}
		/>
	)
}

export default hot(withRouter(Redux(Container)))