import React from 'react';
import { Button, Grid, FormLabel } from '@material-ui/core';

const StaticLinksTable = (props) => {
    const { values: { staticLinks }, editStaticLink, deleteStaticLink } = props;

    if (staticLinks
        && Array.isArray(staticLinks)) {
        return staticLinks.map((link, index) => {
            if (!link.editMode)
                return (
                    <Grid container key={index}>
                        <Grid style={{ "padding": "10px" }} xs={3}>
                            <FormLabel >{link.title}</FormLabel>
                        </Grid>
                        <Grid style={{ "padding": "10px" }} xs={3}>
                            <FormLabel >{link.linkType}</FormLabel>
                        </Grid>
                        <Grid style={{ "padding": "10px" }} xs={2}>
                            <FormLabel >{link.external_link}</FormLabel>
                        </Grid>
                        <Grid style={{ "padding": "10px" }} xs={2}>
                            <Button variant="contained" color="secondary" onClick={() => {
                                editStaticLink(link, index);
                            }}>{"Edit"}</Button>
                            <Button variant="contained" color="secondary" onClick={() => {
                                deleteStaticLink(link, index);
                            }}>{"-"}</Button>
                        </Grid>
                    </Grid>
                )
            else return ''
        })
    } else return ''
}
export default StaticLinksTable;