import { Button, FormControl, FormLabel, Grid, MenuItem, Select } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import ChipSelect from "components/@custom/ChipSelect";
import React, { useState } from 'react';
import 'styles/page-designer.css';
const StyledDiv = styled('div')({
    '& .MuiInput-underline': {
        width: '100%',
        '&:after': {
            borderBottom: 'none',
        },
    },
});

export const LookupHandler = (props) => {
    const { values, dataModels } = props;
    const { lookUpConfig } = values;
    const [mapping, setMapping] = useState({})
    let [dataElements1, setDataElements1] = useState([])
    let [dataElements2, setDataElements2] = useState([])

    const onDataModelChange = (type, selectedDataModelId) => {
        props.allDataModels.map((dataModel) => {
            if (dataModel._id === selectedDataModelId) {
                let _dataElements = dataModel.dataElements.map(item => ({
                    _id: item._id,
                    value: item.name,
                    label: item.description,
                    type: item.dataElementType,
                }))
                if (type === '1') {
                    setDataElements1(_dataElements)
                } else {
                    setDataElements2(_dataElements)
                }
            }
        });
    }
    console.log('allDataModelsallDataModels', props.allDataModels)

    const onSelectMapping = (type, value) => {
        let _mapping = mapping;
        if (type === 'mainDataModel') {
            _mapping[lookUpConfig.mainDataModel.id] = {
                "_id": value,
                "name": value
            }
        } else {
            _mapping[lookUpConfig.lookupFromDataModels[0]?.id] = {
                "_id": value,
                "name": value
            }
        }
        setMapping({ ..._mapping })

    }

    return <>
        <Grid container spacing={4} xs={12}>
            <Grid item xs={5}>
                <FormControl>
                    <FormLabel component="legend">{"Select Main Data Model"}</FormLabel>
                    <StyledDiv>
                        <Select
                            value={lookUpConfig.mainDataModel.id}
                            onChange={e => { props.onChangeLookUpField('mainDataModel', e.target.value); onDataModelChange('1', e.target.value) }}
                            inputProps={{
                                name: 'selectedDataModel',
                                id: 'selectedDataModel',
                            }}>
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {dataModels !== null && dataModels.map(dataModel => (
                                <MenuItem key={dataModel._id} value={dataModel._id}>
                                    {dataModel.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </StyledDiv>
                </FormControl>
                {(!props.checkForNullOrUndefined(dataModels)
                    && lookUpConfig.mainDataModel.id) && (
                        <FormControl>
                            <FormLabel component="legend">{"Select column for data"}</FormLabel>
                            <ChipSelect
                                className="w-full my-16"
                                value={lookUpConfig.mainDataModel.selectedDataElements?.map(i => ({ label: i.name, value: i._id }))}
                                onChange={v => props.onChangeLookUpField('mainSelectedDataElement', v)}
                                textFieldProps={{
                                    InputLabelProps: {
                                        shrink: true
                                    },
                                    variant: 'standard'
                                }}
                                options={dataElements1}
                                isMulti
                            />
                        </FormControl>
                    )}
            </Grid>
            <Grid item xs={5}>
                <FormControl>
                    <FormLabel component="legend">{"Select Lookup Data Model"}</FormLabel>
                    <StyledDiv>
                        <Select
                            value={lookUpConfig.lookupFromDataModels ? lookUpConfig.lookupFromDataModels[0]?.id : ''}
                            onChange={e => { props.onChangeLookUpField('lookupDataModel', e.target.value); onDataModelChange('2', e.target.value) }}
                            inputProps={{
                                name: 'selectedDataModel',
                                id: 'selectedDataModel',
                            }}>
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {dataModels !== null && dataModels.map(dataModel => (
                                <MenuItem key={dataModel._id} value={dataModel._id}>
                                    {dataModel.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </StyledDiv>
                </FormControl>
                {(!props.checkForNullOrUndefined(dataModels)
                    && (lookUpConfig.lookupFromDataModels.length ? lookUpConfig.lookupFromDataModels[0]?.id : null)) && (
                        <FormControl>
                            <FormLabel component="legend">{"Select column for data"}</FormLabel>
                            <ChipSelect
                                className="w-full my-16"
                                value={lookUpConfig.lookupFromDataModels.length ? (lookUpConfig.lookupFromDataModels[0].selectedDataElements)?.map(i => ({ label: i.name, value: i._id })) : []}
                                onChange={v => props.onChangeLookUpField('lookupSelectedDataElement', v)}
                                textFieldProps={{
                                    InputLabelProps: {
                                        shrink: true
                                    },
                                    variant: 'standard'
                                }}
                                options={dataElements2}
                                isMulti
                            />
                        </FormControl>
                    )}
            </Grid>

        </Grid>
        {
            Boolean(lookUpConfig.mappings?.length) &&
            <>
                <FormLabel component="legend">{"Selected Mappings"}</FormLabel>

                {lookUpConfig.mappings.map((item, index) => {
                    let val = (index + 1) + ". " + item[lookUpConfig.mainDataModel.id]._id + ' --> ' + item[lookUpConfig.lookupFromDataModels[0]?.id]._id
                    return (<div style={{ display: 'flex', alignItems: 'center' }}>
                        <h2>{val} </h2>
                        <Button variant="contained"
                            color="primary"
                            style={{ height: 30, marginLeft: 35 }}
                            onClick={e => props.onChangeLookUpField('removeMapping', index)}>
                            X
                        </Button>
                    </div>)
                })}
            </>
        }
        <Grid item xs={12}>
            <FormLabel component="legend">{"Select new Mapping"}</FormLabel>
        </Grid>
        <Grid container spacing={4} xs={12}>
            <Grid item xs={5}>
                <Select
                    value={mapping[lookUpConfig.mainDataModel.id] ? (mapping[lookUpConfig.mainDataModel.id]._id) : null}
                    onChange={e => onSelectMapping('mainDataModel', e.target.value)}
                    style={{ width: '100%' }}
                    disabled={!lookUpConfig.mainDataModel.id}
                    inputProps={{
                        name: 'selectedDataModel',
                        id: 'selectedDataModel',
                    }}>
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {dataElements1?.map(dataModel => (
                        <MenuItem key={dataModel.value} value={dataModel.value}>
                            {dataModel.label}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>

            <Grid item xs={5}>
                <Select
                    value={mapping[lookUpConfig.lookupFromDataModels[0]?.id] ? (mapping[lookUpConfig.lookupFromDataModels[0]?.id]._id) : null}
                    onChange={e => onSelectMapping('lookupMapping', e.target.value)}
                    style={{ width: '100%' }}
                    disabled={!(lookUpConfig.lookupFromDataModels ? lookUpConfig.lookupFromDataModels[0]?.id : '')}
                    inputProps={{
                        name: 'selectedDataModel',
                        id: 'selectedDataModel',
                    }}>
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {dataElements2?.map(dataModel => (
                        <MenuItem key={dataModel.value} value={dataModel.value}>
                            {dataModel.label}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>

            <Grid item xs={2} style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Button variant="contained"
                    color="primary"
                    onClick={e => { props.onChangeLookUpField('addMapping', mapping); setMapping({}) }}
                    disabled={Object.keys(mapping)?.length !== 2}>
                    Add
                </Button>
            </Grid>
        </Grid>

    </>
}