import React from 'react';
import ConnectionPoint from './ConnectionPoint';
import Step from './Step';
import SuggestionRect from './SuggestionRect';
import StartIcon from './start-button.js';

export default class Start extends Step {
  render() {
    const {
      createArrow,
      height,
      width,
      x,
      y,
      multipleSelection,
      selected,
      selectStep,
      stopDragging,
      mouseOverConnection,
      setMouseOverConnection,
      onClickSugessionElement,
      mouseOverStep,
      setMouseOverStep,
      setClickStep,
      clickStep,
      editElementStep,
      name,
      bgColor,
      moveArrow,
      label,
    } = this.props;

    // const showConnectionPoints =
    //   (selected && !multipleSelection) || mouseOverStep;

    const showConnectionPoints = clickStep;

    const style = this.getStyle();

    const halfHeight = height / 2;
    const halfWidth = width / 2;

    return (
      <g
        onMouseOver={setMouseOverStep}
        onMouseLeave={setMouseOverStep}
        // onMouseDown={selectStep}
        onMouseUp={stopDragging}
        transform={`translate(${x},${y})`}
        // onDoubleClick={editElementStep}
      >
        <StartIcon
          height="28"
          width="28"
          onClick={setClickStep}
          onMouseDown={selectStep}
          onDoubleClick={editElementStep}
          onMouseMove={moveArrow}
        />

        {/* <circle
          r={height / 2}
          // style={style}
          cx={halfWidth}
          cy={halfHeight}
          onClick={setClickStep}
          onDoubleClick={editElementStep}
          onMouseMove={moveArrow}
          style={{
            fill: bgColor === undefined ? 'white' : bgColor,
            fontSize: 14,
            stroke: '#000',
            strokeWidth: 2,
          }}
        /> */}
        <foreignObject
          width="30"
          height="30"
          className="processText"
          onDoubleClick={editElementStep}
          onClick={setClickStep}
          onMouseDown={selectStep}
          onMouseMove={moveArrow}
        ></foreignObject>

        <foreignObject width="80" height="120" x="-25" y="40">
          {label ? (
            <div className="startLabel">
              <p>{label}</p>
            </div>
          ) : null}
        </foreignObject>
        {/* {showConnectionPoints ? (
          <ConnectionPoint
            setMouseOverConnection={setMouseOverConnection}
            cx={halfWidth - 20}
            cy={halfHeight}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint setMouseOverConnection={setMouseOverConnection} cx={halfWidth} cy={0} />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            setMouseOverConnection={setMouseOverConnection}
            cx={halfWidth + 20}
            cy={halfHeight}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            setMouseOverConnection={setMouseOverConnection}
            cx={halfWidth}
            cy={height}
          />
        ) : null} */}

        {showConnectionPoints && (
          <SuggestionRect
            type={'circle'}
            x={width - 20}
            y={-10}
            height={100}
            width={50}
            onClickSugessionElement={onClickSugessionElement}
          />
        )}
      </g>
    );
  }
}

Start.defaultProps = Step.defaultProps;
