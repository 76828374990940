import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Actions from 'actions'

const Redux = PassedComponent => {
  const mapStateToProps = state => {
    return {
      workflows: state.workflows,
      workflow: state.workflows.workflowById,
      notifications: state.notifications
    };
  };
  const mapDispatchToProps = {
    onSaveWorkflow: Actions.saveWorkflow,
    getWorkflow: Actions.getWorkflow,
    updateWorkflow: Actions.updateWorkflow,
    clearWorkflow: Actions.clearWorkflow,
    getNotifications: Actions.getNotifications,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(({ ...props }) => <PassedComponent {...props} />);
};

export default Redux;
