import React, { Component } from 'react'
import {
	TextField, Button, Dialog, DialogActions, DialogContent, Toolbar, AppBar,
	MenuItem, FormControl, Select, Checkbox, FormControlLabel, FormLabel,
	Tabs, Tab, DialogTitle, TextareaAutosize, Grid
} from '@material-ui/core'
import { withStyles,styled } from '@material-ui/core/styles'
import _ from 'modules/lodash';
import Utils from 'packages/page/utils';

const StyledDiv = styled('div')({
    '& .MuiInput-underline': {
        width: '100%',
        '&:after': {
            borderBottom: 'none',
        },
    },
});

const styles = theme => ({
	root: {},
	formControl: {
		marginTop: 8,
		marginBottom: 16,
		paddingRight: 8,
		'&::placeholder': {
			color: '#A5A5A5'
		}
	},
	selectEmpty: {
		marginTop: 16,
	},
})
const coloumnObject = () => ({ id: Utils.generateGUID(), value: '', isEdit: false })
const defaultEventState = {
	id: '',
	_id: '',
	name: '',
	description: '',
	isActive: true,
	default: false,
	rank: 0,
	tabValue: 0,
	form: {
		name: '',
		description: '',
		type: "string",
		defaultValue: '',
		validation: '',
		alternateColumnNames: [coloumnObject()],
		formula: '',
		required: false,
		unique: false,
		lookup: false,
		searcheable: false,
		useFormulaBuilder:true
	},
	types: [
		{
			"id": "string",
			"label": "String"
		},
		// {
		// 	"id": "objectId",
		// 	"label": "String"
		// },
		{
			"id": "Integer",
			"label": "Integer"
		},
		{
			"id": "int",
			"label": "Integer"
		},
		{
			"id": "double",
			"label": "Double"
		},
		{
			"id": "date",
			"label": "Date"
		},
		{
			"id": "Boolean",
			"label": "Boolean"
		},
		{
			"id": "array",
			"label": "Array"
		},
		{
			"id": "TimeStamp",
			"label": "Time stamp"
		},
		{
			"id": "text",
			"label": "String"
		},
		{
			"id": "currency",
			"label": "Currency"
		},
		{
			"id": "select",
			"label": "Select"
		},
	]
}

class NewDataElement extends Component {
	state = { ...defaultEventState }

	componentDidMount() {
		console.log('this.props.dataElement componentDidMount', this.props.dataElement);
		const {formulas,getFormulas}= this.props;
		formulas?.data.length === 0 && getFormulas(this.props.match.params?.dappId);
		if (this.props.dataElement !== "new") {
			this.setState({ form: { ...this.props.dataElement, alternateColumnNames: this.state.form.alternateColumnNames, type: "string", useFormulaBuilder:true } })
		} else {
			this.resetFormState();
		}

	}

	componentDidUpdate(prevProps, prevState) {
		// if (!prevProps.datamodel.dataModelDialog.props.open && this.props.datamodel.dataModelDialog.props.open) {
		// 	console.log("fire1", prevProps, this.props, this.state, prevState);
		// 	this.setState({
		// 		form: { ...this.state.form }, ...this.props.datamodel.dataModelDialog.data
		// 	})
		// }


		console.log('this.props.dataElement componentDidUpdate', this.props.dataElement, prevProps.dataElement);
		console.log('this.state.form._id componentDidUpdate', this.state.form._id, prevState.form._id);
		console.log('this.props.datamodel.dataModelDialog.props.opend componentDidUpdate', this.props.datamodel.dataModelDialog.props.open, prevProps.datamodel.dataModelDialog.props.open);
		// if (this.props.dataElement === "new")
		// 	this.resetFormState();
		if (this.props.dataElement
			&& (this.props.dataElement !== prevProps.dataElement || this.state.form._id !== prevState.form._id
				|| this.props.datamodel.dataModelDialog.props.open !== prevProps.datamodel.dataModelDialog.props.open)) {
			console.log("fire2");
			if (this.props.dataElement !== "new") {
				this.setState({ form: { ...this.props.dataElement, alternateColumnNames: this.state.form.alternateColumnNames }, ...this.props.datamodel.dataModelDialog.data })
			} else {
				this.resetFormState();
			}

		}
	}

	resetFormState = () => {
		this.setState({
			form: {
				_id: this.props.newId,
				name: '',
				description: '',
				type: "string",
				defaultValue: '',
				validation: '',
				alternateColumnNames: [coloumnObject()],
				formula: '',
				required: false,
				unique: false,
				lookup: false,
				searcheable: false,
				useFormulaBuilder:true
			}
		})
	}

	handleChange = event => this.setState({ form: _.set({ ...this.state.form }, event.target.name, event.target.value) })

	handleCheckboxChange = name => event => this.setState({ form: { ...this.state.form, [name]: event.target.checked } })

	closeComposeDialog = () => this.props.closeNewDataModelDialog()

	canBeSubmitted = () => {
		console.log("this.styate", this.state, this.props, _.isEqual(this.props.dataElement, this.state.form))
		let a = this.state.form.name.trim().length > 0 && this.state.form.type; /* && !_.isEqual(this.props.dataElement, this.state.form);*/
		console.log("a", a);
		return a
	}

	handleChangeTab = (event, tabValue) => {
		this.setState({ tabValue });
	};
	// addDataElement = () => this.props.addDataElement(Object.assign({}, this.props.state.form),
	// 	{ ...this.state }, this.props.match.params.datamodelId, this.props.match.params.dappId)

	addNewColumn=()=>{
		const alternateColumnArray= JSON.parse(JSON.stringify(this.state.form.alternateColumnNames));
		if(alternateColumnArray.find(item=> item.value==="")){
			return 	this.props.showAlert('Please fill previous Alternate Column Names ', { variant: 'danger', icon: 'bell', timeout:2 });
		}
		alternateColumnArray.push(coloumnObject());
		this.setState({ form: { ...this.state.form, alternateColumnNames: alternateColumnArray } })
	}

	removeColumn = (item) => {
		const alternateColumnArray = JSON.parse(JSON.stringify(this.state.form.alternateColumnNames));
		let newAlternateColumnArray = alternateColumnArray.filter(value => value.id !== item.id);
		this.setState({ form: { ...this.state.form, alternateColumnNames: newAlternateColumnArray } })
	}

	alternateColumnChange = (event, index) => {
		const alternateColumnArray = JSON.parse(JSON.stringify(this.state.form.alternateColumnNames))
		alternateColumnArray[index]['value'] = event.target.value;
		this.setState({ form: { ...this.state.form, alternateColumnNames: alternateColumnArray } })
	}

	convertObjToStr = (form) => {
		let newForm = JSON.parse(JSON.stringify(form));
		newForm.alternateColumnNames = newForm.alternateColumnNames.map(el => el.value).join()
		return newForm
	}

	render() {
		const { classes, datamodel, formulas } = this.props;
		const { tabValue, form: { alternateColumnNames,useFormulaBuilder } } = this.state;
		return (<Dialog
			className={classes.root}
			{...datamodel.dataModelDialog.props}
			onClose={this.closeComposeDialog}
			fullWidth
			maxWidth="md">
			{/* <AppBar position="static" style={{background: '#1B2A47'}}>
				<Toolbar className="flex w-full">
					<FormLabel variant="subtitle1" color="inherit" style={{ color: "#FFF" }}>
						{"Data Elements Properties"}
					</FormLabel>
				</Toolbar>
			</AppBar> */}
			<DialogTitle id="alert-dialog-title" position="static">
				Data Elements Properties
                </DialogTitle>
			<AppBar position="static"
				className="tabPropertiesPanel">
				<Tabs
					aria-label="wrapped label tabs example"
					value={tabValue}
					onChange={this.handleChangeTab}
				>
					<Tab className="h-64 normal-case" label="Basic Info" />
					<Tab className="h-64 normal-case" label="Data" />
					<Tab className="h-64 normal-case" label="Advanced" />
					<Tab className="h-64 normal-case" label="Logic" />
				</Tabs>
			</AppBar>
			<DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
				<div className="p-16 sm:p-24 max-w-2xl">
					<form className="form" noValidate autoComplete="off" >
						{tabValue === 0 &&
							(
								<>
									<FormControl>
										<FormLabel variant="h6" className="label-headings">
											{"Name *"}
										</FormLabel>
										<TextField
											id="name"
											name="name"
											placeholder="Name"
											type="text"
											className={classes.formControl}
											value={this.state.form.name}
											onChange={this.handleChange}
											variant="outlined"
											fullWidth
										/>
									</FormControl>
									<FormControl>
										<FormLabel variant="h6" className="label-headings">
											{"Description"}
										</FormLabel>
										<TextField
											className={classes.formControl}
											id="description"
											placeholder="Description"
											type="text"
											name="description"
											value={this.state.form.description}
											onChange={this.handleChange}
											multiline
											rows={5}
											variant="outlined"
											fullWidth
										/>
									</FormControl>
									<FormControl variant="outlined" className={classes.formControl} fullWidth>
										<FormLabel id="demo-simple-select-outlined-label">
											{"Type *"}
										</FormLabel>
										<Select
											labelId="demo-simple-select-outlined-label"
											id="demo-simple-select-outlined"
											value={this.state.form.type}
											onChange={this.handleChange}
											name="type">
											<MenuItem value="">
												<em>{"None"}</em>
											</MenuItem>
											{this.state.types.map((item, i) => {
												return <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
											})}
										</Select>
									</FormControl>
									{['Integer','int','currency','double'].includes(this.state.form.type)  ?
										<FormControl>
										<FormLabel variant="h6" className="label-headings">
											{"Minimum Value"}
										</FormLabel>
										<TextField
											id="minimum"
											name="minimum"
											placeholder="Minimum Value"
											type="number"
											className={classes.formControl}
											value={this.state.form.minimum}
											onChange={this.handleChange}
											variant="outlined"
										/>
											<FormLabel variant="h6" className="label-headings">
											{"Maximum Value"}
										</FormLabel>
										<TextField
											id="maximum"
											name="maximum"
											placeholder="Maximum Value"
											type="number"
											className={classes.formControl}
											value={this.state.form.maximum}
											onChange={this.handleChange}
											variant="outlined"
										/>
									</FormControl> : null
									}
								</>
							)}
						{tabValue === 1 &&
							(
								<>
									<FormControl>
										<FormLabel variant="h6" className="label-headings">
											{"Default Value"}
										</FormLabel>
										<TextField
											id="defaultValue"
											name="defaultValue"
											placeholder="Default Value"
											type="text"
											className={classes.formControl}
											value={this.state.form.defaultValue}
											onChange={this.handleChange}
											variant="outlined"
											fullWidth
										/>
									</FormControl>
									<FormControl>
										<FormLabel variant="h6" className="label-headings">
											{"Validation"}
										</FormLabel>
										<TextField
											id="validation"
											name="validation"
											placeholder="Validation"
											type="text"
											className={classes.formControl}
											value={this.state.form.validation}
											onChange={this.handleChange}
											variant="outlined"
											fullWidth
										/>
									</FormControl>
								</>
							)}
						{tabValue === 2 &&
							(
								<>
									<FormControlLabel
										control={<Checkbox
											checked={this.state.form.required}
											onChange={this.handleCheckboxChange('required')}
											value="required"
											color="primary"
										/>}
										label="Required"
									/>
									<FormControlLabel
										control={<Checkbox
											checked={this.state.form.unique}
											onChange={this.handleCheckboxChange('unique')}
											value="unique"
											color="primary"
										/>}
										label="Unique"
									/>
									<FormControlLabel
										control={<Checkbox
											checked={this.state.form.lookup}
											onChange={this.handleCheckboxChange('lookup')}
											value="lookup"
											color="primary"
										/>}
										label="Lookup"
									/>
									<FormControlLabel
										control={<Checkbox
											checked={this.state.form.searcheable}
											onChange={this.handleCheckboxChange('searcheable')}
											value="searcheable"
											color="primary"
										/>}
										label="Searcheable"
									/>
									<FormControlLabel
										control={<Checkbox
											checked={this.state.form.primaryKey}
											onChange={this.handleCheckboxChange('primaryKey')}
											value="primaryKey"
											color="primary"
										/>}
										label="Primary key"
									/>
									<FormControlLabel
										control={<Checkbox
											checked={this.state.form.refrenceKey}
											onChange={this.handleCheckboxChange('refrenceKey')}
											value="refrenceKey"
											color="primary"
										/>}
										label="Reference key"
									/>
									<FormControlLabel
										control={<Checkbox
											checked={this.state.form.creatingIndex}
											onChange={this.handleCheckboxChange('creatingIndex')}
											value="creatingIndex"
											color="primary"
										/>}
										label="Create indexing"
									/>
									<FormControl>
										<FormLabel variant="h6" className="label-headings">
											{"Alternate Column Names"}
										</FormLabel>
										<Grid container spacing={2}>
											{
												alternateColumnNames.map((item, index) => (
													<React.Fragment key={item.id}>
														<Grid item xs={10}>
															<TextField
																// id="alternateColumnNames"
																name="alternateColumnNames"
																placeholder="Alternate Column Names"
																type="text"
																className={classes.formControl}
																value={item.value}
																onChange={(e) => this.alternateColumnChange(e, index)}
																variant="outlined"
															// fullWidth
															/>
														</Grid>

														{ alternateColumnNames.length !== 1 &&
															<Grid item xs={2}>
																<Button
																	color="secondary"
																	variant="outlined"
																	className={classes.formControl}
																	onClick={() => {
																		this.removeColumn(item);
																	}}
																	fullWidth
																>
																	Remove
          									</Button>
															</Grid>
														}
														{ alternateColumnNames.length - 1 === index &&
															<Grid item xs={2}>
																<Button
																	color="primary"
																	variant="outlined"
																	className={classes.formControl}
																	onClick={() => {
																		this.addNewColumn();
																	}}
																	fullWidth
																>
																	Add
          									</Button>
															</Grid>
														}
													</React.Fragment>
												))

											}
										</Grid>
									</FormControl>
								</>
							)}
						{tabValue === 3 &&
							(
								<>
								<FormControl>
								<FormControlLabel
										control={<Checkbox
											checked={this.state.form.useFormulaBuilder || false}
											onChange={this.handleCheckboxChange('useFormulaBuilder')}
											value="useFormulaBuilder"
											color="primary"
										/>}
										label="load from formula builder"
									/>
									{useFormulaBuilder?<>
								
								<FormLabel component="legend">{"Formulas"}</FormLabel>
								<StyledDiv>
								<Select
                                value={this.state.form.formula || ''}
                                onChange={this.handleChange}
                                inputProps={{
                                    name: 'formula',
                                    id: 'formula',
                                }}>
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                { formulas?.data?.map(formula => (
                                    <MenuItem key={formula._id} value={formula._id}>
                                        {formula.name}
                                    </MenuItem>
                                ))}
                            </Select>
							</StyledDiv>
							</>
							:<>
										<FormLabel variant="h6" className="label-headings">
											{"Formula"}
										</FormLabel>
										<TextareaAutosize
											id="formula"
											name="formula"
											placeholder="Formula"
											rowsMin={5}
											className={classes.formControl}
											value={this.state.form.formula}
											onChange={this.handleChange}
											variant="outlined"
										/>
										</>
								}
									</FormControl>
								</>
							)}
					</form>
				</div>
			</DialogContent>
			<DialogActions className="justify-between pl-8 sm:pl-16">
				<FormControl>
					<Button
						color="secondary"
						variant="outlined"
						className={classes.formControl}
						onClick={() => {
							this.closeComposeDialog()
							if (this.props.dataElement !== "new")
								this.props.updateNewDataElement(this.convertObjToStr(this.state.form))
							else
								this.props.addNewDataElement(this.convertObjToStr(this.state.form))
						}}
						disabled={!this.canBeSubmitted()}
						fullWidth>
						{"Add"}
					</Button>
				</FormControl>
			</DialogActions>
		</Dialog>)
	}
}

export default (withStyles(styles, { withTheme: true })(NewDataElement))
