import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import 'styles/page-designer.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Highcharts from 'highcharts';
import Utils from 'packages/page/utils';
import _ from 'modules/lodash'
import {
	getLineChartConfig,
	getDefaultLineChart,
	generateLineData
} from 'packages/page/highcharts/line.module';
import {
	getApiDataFromDataUrl
} from 'services/page.service';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const styles = theme => ({});

class PageDesignerLineChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...getDefaultLineChart(),
			id: `linechart-${Utils.generateGUID()}`
		}
	}

	highChartsRender() {
		let lineConfig = getLineChartConfig(this.state, this.state.id);
		Highcharts.chart(lineConfig);
	}

	componentDidMount() {
		this.initiateGetApi();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(this.props.element, prevProps.element)
			&& JSON.stringify(this.props.element) !== JSON.stringify(prevProps.element)) {
			// this.highChartsSeries();
			this.initiateGetApi();
		}
	}

	highChartsSeries() {
		let s = generateLineData(this.props.element.chartData, this.state)
		this.setState({ ...s }, () => {
			this.highChartsRender();
		})
	}

	setDatafromApi(res, data) {
		let d = { ...data };
		let chartData = { ...this.props.element.chartData };

		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			d.labels = res.labels;
			if (res.datasets) {
				d.datasets = res.datasets.map((ds, i) => {
					if (!d.datasets)
						d.datasets = []
					if (!d.datasets[i])
						d.datasets[i] = {};
					return { ...d.datasets[i].data, ...ds };
				})
			} else {
				if (!d.datasets)
					d.datasets = [];
				d.datasets[0] = { label: res.label, data: res.data }
			}
		} else {
			d.labels = res.labels;
			d.datasets = res.datasets;
		}
		chartData.data = d;
		let s = generateLineData(chartData, this.state)
		this.setState({ ...s }, () => {
			this.highChartsRender();
		})
	}

	getApiDataFromDataUrl(url, data) {
		getApiDataFromDataUrl(url, this.props.match.params.dappId)
			.then(res => {
				this.setDatafromApi(res.data, data)
			})
	}

	initiateGetApi() {
		let { chartData } = this.props.element;
		let props = this.props;
		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			let targetURL = API_BASE_URL + `/dataconnect/${chartData.isGrouping ? 'bar' : 'line'}/` + chartData.selectedDataModel + "?";
			const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';

			if (chartData.isGrouping) {
				targetURL += `&barXAxis=${chartData.selectedDataLabels[0].value}`;
				targetURL += `&barYAxis=${chartData.selectedDataElement[0].value}`;
			} else {
				targetURL += chartData.selectedDataElement.reduce(reducer, '&measure=');
				targetURL += chartData.selectedDataLabels.reduce(reducer, '&label=');
			}

			targetURL += '&metric=' + chartData.metric;
			if (props.queryString) {
				targetURL += '&' + props.queryString;
			}
			if (chartData.datelabeltype) {
				targetURL += '&labeltype=date'
				targetURL += chartData.labelformat ? `&format=${chartData.labelformat}` : '&format=%Y-%m-%d';
			}

			if (chartData.conditions && chartData.conditions.length > 0) {
				let cond = JSON.stringify(chartData.conditions);
				targetURL += "&conditions=" + cond;
			}
			if (props.page && props.page.conditions && props.page.conditions.length > 0) {
				let cond = JSON.stringify(props.page.conditions);
				targetURL += "&pConditions=" + cond;
			}
			this.getApiDataFromDataUrl(targetURL, props.data);
		} else if (props.dataUrl) {
			this.getApiDataFromDataUrl(props.dataUrl, props.data);
		} else {
			let s = generateLineData(props.element.chartData, this.state)
			this.setState({ ...s }, () => {
				this.highChartsRender();
			})
		}
	}

	render() {
		return (
			<div id={this.state.id} style={{ height: "inherit", width: "inherit" }}></div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(
	withRouter(connect(null, null)(PageDesignerLineChart))
);
