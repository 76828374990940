import { ActionTypes } from 'constants/index'

/**
 * Opens new page dialogue
 */
export const openNewPageDialog = () => dispatch => {
	dispatch({ type: ActionTypes.OPEN_NEW_PAGE_DIALOG })
}

/**
 * Closes new page dialogue
 */
export const closeNewPageDialog = () => dispatch => {
	dispatch({ type: ActionTypes.CLOSE_NEW_PAGE_DIALOG })
}

/**
 * Fetch all pages belongs to current app
 */
export const getPages = (appId) => dispatch => {
	dispatch({ type: ActionTypes.GET_PAGES, appId })
}

/**
 * Fetch page
 */
export const getPage = ({ pageId, dappId }) => dispatch => {
	dispatch({ type: ActionTypes.GET_PAGE, payload: { pageId, appId: dappId } })
}

/**
 * save Page
 */
export const savePage = (data) => dispatch => {
	dispatch({ type: ActionTypes.SAVE_PAGE, payload: data });
}

/**
 * update Page
 */
export const updatePage = (data) => dispatch => {
	dispatch({ type: ActionTypes.UPDATE_PAGE, payload: data });
}

/**
 * update Page
 */
export const deletePage = (data) => dispatch => {
	dispatch({ type: ActionTypes.DELETE_PAGE, data });
}

export const clearPage = () => dispatch => {
	return dispatch({ type: ActionTypes.EMPTY_PAGE })
}

export const toggleChartListComponent = (data) => dispatch => {
	return dispatch({ type: ActionTypes.TOGGLE_CHART_LIST_COMPONENT, data: data })
}

export const saveResizedChartData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.SAVE_RESIZED_CHART_DATA, payload: data })
}

export const addBarChartData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_BAR_CHART_DATA, payload: data })
}

export const addStackedBarChartData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_STACKED_BAR_CHART_DATA, payload: data })
}

export const addLineChartData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_LINE_CHART_DATA, payload: data })
}

export const addBubbleChartData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_BUBBLE_CHART_DATA, payload: data })
}

export const addPieChartData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_PIE_CHART_DATA, payload: data })
}

export const addMixedChartData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_MIXED_CHART_DATA, payload: data })
}

export const addDoughnutChartData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_DOUGHNUT_CHART_DATA, payload: data })
}

export const addRadarChartData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_RADAR_CHART_DATA, payload: data })
}

export const addPolarChartData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_POLAR_CHART_DATA, payload: data })
}

export const addBulletChartData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_BULLET_CHART_DATA, payload: data })
}

export const addListChartData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_LIST_CHART_DATA, payload: data })
}

export const addSimpleTableData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_SIMPLE_TABLE_DATA, payload: data })
}

export const addCardData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_CARD_DATA, payload: data })
}

export const addHTMLData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_HTML_DATA, payload: data })
}

export const addSimpleListData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_SMIPLELIST_DATA, payload: data })
}

export const addSummaryTableData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_SUMMARYTABLE_DATA, payload: data })
}

export const currentChartToEdit = (chart) => dispatch => {
	return dispatch({ type: ActionTypes.CURRENT_CHART_TO_EDIT, payload: chart })
}

export const closeChartToEdit = () => dispatch => {
	return dispatch({ type: ActionTypes.CLOSE_CHART_TO_EDIT })
}

export const deleteChartData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.DELETE_CHART_DATA, payload: data })
}

export const saveChartData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.SAVE_CHART_DATA, payload: data })
}

export const addLinksData = (data) => dispatch => {
	return dispatch({ type: ActionTypes.ADD_LINKS_DATA, payload: data })
}
