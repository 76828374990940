import React from 'react';
import bindme from 'bindme';
import Step from './Step';
import ConnectionPoint from './ConnectionPoint';
import SuggestionRect from './SuggestionRect';
import '../style/EditElement.css';

export default class Line extends Step {
  static defaultProps = {
    ...Step.defaultProps,
    startX: 0,
    startY: 0,
    endX: 80,
    endY: 80,
  };

  constructor(props) {
    super(props);

    bindme(this, 'onMouseDown', 'onMouseEnter', 'onMouseLeave');
    this.state = { isMouseOver: false };
  }

  onMouseDown() {
    // TODO selectArrow
  }
  onMouseOver() {
    console.log('Hover');
  }

  onMouseEnter() {
    this.setState({ isMouseOver: true });
  }

  onMouseLeave() {
    this.setState({ isMouseOver: false });
  }

  render() {
    let { stroke, strokeWidth } = Step.defaultProps.style;
    const {
      x,
      y,
      startX,
      startY,
      endX = 78,
      endY,
      endX2 = 0,
      endY2 = 0,
      endX3 = 0,
      endY3 = 0,
      selectStep,
      stopDragging,
      multipleSelection,
      selected,
      setMouseOverStep,
      setMouseOverConnection,
      mouseOverStep,
      editElementStep,
      values,
      strokedash = 0,
      setClickStep,
      onClickSugessionElement,
      clickStep,
    } = this.props;
    const showConnectionPoints = clickStep;
    const { isMouseOver } = this.state;
    if (isMouseOver) strokeWidth++;
    const style = this.getStyle();
    // const showConnectionPoints = (selected && !multipleSelection) || mouseOverStep;
    // console.log("startX====", startX);
    // console.log("startY====", startY);
    // console.log("endX====", endX);
    // console.log("endY====", endY);
    // console.log("endX2====", endX2);
    // console.log("endY2===", endY2);
    // console.log("endX3====", endX3);
    // console.log("endY3====", endY3);

    return (
      <g
        onMouseOver={setMouseOverStep}
        onMouseLeave={setMouseOverStep}
        onMouseDown={selectStep}
        onMouseUp={stopDragging}
        onDoubleClick={editElementStep}
        transform={`translate(${x},${y})`}
        className="lineG"
      >
        <polyline
          onClick={setClickStep}
          fill="none"
          className="line"
          points={`${startX},${startY} ${endX2},${endY2} ${endX3},${endY3} ${endX - 15},${endY}`}
          strokeWidth="2"
          onMouseOver={this.onMouseOver}
          stroke="#000"
          strokeDasharray={strokedash}
          markerEnd="url(#arrow)"
        />

        <text class="lineText" transform={`translate(${endX3 + 10},${endY3 - 5})`}>
          {values}
        </text>

        {showConnectionPoints && (
          <SuggestionRect
            type={'line'}
            x={endX}
            y={endY - 20}
            onClickSugessionElement={onClickSugessionElement}
          />
        )}
        {/* {showConnectionPoints ? (
          <ConnectionPoint
            setMouseOverConnection={(e) => setMouseOverConnection(e, "start")}
            cx={startX}
            cy={startY}
          />
        ) : null}
        {showConnectionPoints ? (
          <ConnectionPoint
            setMouseOverConnection={(e) => setMouseOverConnection(e, "end")}
            cx={endX}
            cy={endY}
          />
        ) : null} */}
      </g>
    );
  }
}
