import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './View'
import Redux from './redux'
import { withRouter } from 'react-router-dom'
import { validateEmail, validateMobileNumber } from '../../modules/common'
import _ from 'lodash'

const Container = (props) => {
	const [state, setState] = useState({
		form: {},
		isSubmitButton: true
	});
	const [tabValue, setTabValue] = useState(0);

	const [error, setError] = useState({ name: false, industry: false, primaryContactName: false, primaryContactEmail: false, primaryContactPhone: false })

	const handleUIChange = (event, key) => {
		setState({
			...state,
			form: _.set({ ...state.form },
				event.target.name,
				event.target.type === 'checkbox' ? event.target.checked : event.target.value)
		})
	}

	const canBeSubmitted = () => {
		// const { name, industry, primaryContactName, primaryContactEmail, primaryContactPhone } = state.form
		// if (name && name.length === 0) {
		// 	setError({ ...error, name: "Please enter name" })
		// }
		// if (industry && industry.length === 0) {
		// 	setError({ ...error, industry: "Please enter industry" })
		// }
		// if (primaryContactName && primaryContactName.length === 0) {
		// 	setError({ ...error, primaryContactName: "Please enter primary contact name" })
		// }
		// if (primaryContactEmail && primaryContactEmail == "") {
		// 	setError({ ...error, primaryContactEmail: "Please enter primary contact email" })
		// }
		// if (primaryContactPhone && primaryContactPhone == "") {
		// 	setError({ ...error, primaryContactPhone: "Please enter primary contact phone" })
		// }
		// return (
		// 	name && name.length > 0 && industry && industry.length > 0 && primaryContactName && primaryContactName.length > 0 && primaryContactEmail && primaryContactEmail.length > 0 && validateEmail(primaryContactEmail) && primaryContactPhone && primaryContactPhone.length > 0 && validateMobileNumber(primaryContactPhone) &&
		// 	!_.isEqual(props.entity.data, state.form) && state.isSubmitButton
		// )
		// if (name && industry && primaryContactName && primaryContactEmail && primaryContactPhone){
		// 	return true
		// }
		// else return false
		return true;
	}

	const handleFileChange = (file) => {
		setState({ ...state, form: _.set({ ...state.form }, 'logoId', file.id) })
	}

	useEffect(() => {
		if (!_.isEqual(props.entity.data, state.form)) {
			setState({ ...state, isSubmitButton: true })
		}
	}, [state.form])

	useEffect(() => {
		if (props.entity.saveSuccess) {
			props.showAlert('Entity saved successfully', { id: "entity", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/entities/${props.entity.data.entityId}` })
		}
		if (props.entity.updateSuccess) {
			props.showAlert('Entity updated successfully', { id: "entity", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/entities/${props.entity.data.entityId}` })
		}
	}, [props.entity.saveSuccess, props.entity.updateSuccess])

	const updateEntityState = () => {
		const { dappId, entityId } = props.match.params
		if (entityId === 'new') {
			props.newEntity();
		}
		else {
			props.getEntity({ appId: dappId, id: entityId })
		}
	};

	const updateFormState = () => setState({ ...state, form: { ...props.entity.data } })

	useEffect(() => {
		updateEntityState()
	}, [props.location])

	useEffect(() => {
		updateFormState()
	}, [props.entity.data])

	const saveEntityData = () => {
		const { name, industry, primaryContactName, primaryContactEmail, primaryContactPhone } = state.form
		var reEmail = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
		if (!name || name.length === 0) {
			setTabValue(0)
			setError({ ...props.entity.error, name: "Please enter name" })
			return;
		}
		if (!industry || industry.length === 0) {
			setTabValue(0)
			setError({ ...props.entity.error, industry: "Please enter industry" })
			return;
		}
		if (!primaryContactName || primaryContactName.length === 0) {
			setTabValue(1)
			setError({ ...props.entity.error, primaryContactName: "Please enter primary contact name" })
			return;
		}
		if (!primaryContactEmail || primaryContactEmail == "" || !primaryContactEmail.match(reEmail)) {
			setTabValue(1)
			setError({ ...props.entity.error, primaryContactEmail: "Please enter primary contact email" })
			return;
		}
		if (!primaryContactPhone || primaryContactPhone == "") {
			setTabValue(1)
			setError({ ...props.entity.error, primaryContactPhone: "Please enter primary contact phone" })
			return;
		}
		setState({ ...state, isSubmitButton: false });
		props.saveEntity(state.form, props.user, props.match.params.dappId);
	}

	const updateEntityData = () => {
		setState({ ...state, isSubmitButton: false });
		let data = {...state.form}
		if(data.emailPassword ===""){
			delete data.emailPassword;
		}
		if(data.sgToken ===""){
			delete data.sgToken;
		}
		props.updateEntity(data, props.user, props.match.params.dappId);
	}

	useEffect(() => {
		// name, industry, primaryContactName, primaryContactEmail, primaryContactPhone
		let error = props.entity.error;
		if (error) {
			error.name && error.industry && error.primaryContactName && error.primaryContactEmail && error.primaryContactPhone
				&& props.showAlert("NETWORK ERROR: Please verify your internet connection", {
					id: "entity",
					variant: 'danger',
					icon: 'times-circle'
				})
			if (error.name) {
				setError({ ...error, name: error.name });
			} else if (error.industry) {
				setError({ ...error, industry: error.industry });
			} else if (error.primaryContactName) {
				setError({ ...error, primaryContactName: error.primaryContactName });
			} else if (error.primaryContactEmail) {
				setError({ ...error, primaryContactEmail: error.primaryContactEmail });
			} else if (error.primaryContactPhone) {
				setError({ ...error, primaryContactPhone: error.primaryContactPhone });
			}
		}
	}, [props.entity.error])

	useEffect(() => {
		if (typeof props.entity.error === "object" && props.entity.error.status) {
			if (props.entity.error.status == 401) {
				props.history.push({ pathname: "/login" });
				props.showAlert(props.entity.error.errorMessage, { id: "entity", variant: 'error' });
			} else {
				if (props.entity.error.errorMessage) {
					this.setState({ ...state, isSubmitButton: true });
					if (props.entity.error.errorMessage) {
						props.showAlert(props.entity.error.errorMessage, { id: "entity", variant: 'error' });
					}
				}
			}
		}
	}, [props.entity.error])


	return (
		<View
			{...props}
			state={state}
			error={error}
			tabValue={tabValue}
			handleUIChange={handleUIChange}
			canBeSubmitted={canBeSubmitted}
			saveEntity={() => saveEntityData()}
			updateEntity={() => updateEntityData()}
			handleFileChange={handleFileChange}
			setTabValue={setTabValue}
		/>
	)
}

export default hot(withRouter(Redux(Container)))
