import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import connect from 'react-redux/es/connect/connect'
import { Link, withRouter } from 'react-router-dom'
import { DropzoneDialog } from 'material-ui-dropzone'
import {
	Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core'
import { withStyles } from '@material-ui/core'
// import _ from '@lodash';
// import withReducer from 'store/withReducer';
// import reducer from 'store/reducers/appConfigApp';

import {
	submitForm
} from 'packages/upload.module'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: '8px',
		marginRight: '8px',
		width: 200,
	},
})

class Upload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			progressOpen: false,
			files: [],
			file: null,
			title: "Please wait, still in progress...",
			description: "Please wait, still in progress...",
			fileId: props.fileId
		}
	}

	componentDidUpdate(prevProps, prevState) {
	}

	handleClose(open) {
		this.setState({
			[open]: false
		})
	}

	uploadWithFormData(files, title, desc) {
		const formData = new FormData()
		// formData.append("files", files);
		formData.append("file", files[0])

		submitForm("multipart/form-data", formData, this.props, (res) => {
			console.log("res - fileupload", res)
			if (!res.error && res.status === "success") {
				this.setState({
					fileId: res.files[0].id,
					file: res.files[0],
					open: false,
					progressOpen: true,
					description: "File has been uploaded to the server successfully."
				});
				this.props.handleFileChange && this.props.handleFileChange(res.files[0])
			} else {
				console.log("error", res)
			}
		})
	}

	handleSave(files) {
		this.setState({
			files: files
		});
		this.uploadWithFormData(files)
	}

	handleOpen(open) {
		this.setState({
			[open]: true,
		})
	}

	render() {
		const { classes } = this.props;
		let acceptedFiles = this.props.acceptedFiles
			? this.props.acceptedFiles
			: ['application/vnd.ms-excel',
				'application/x-csv',
				'application/csv',
				'text/x-csv',
				'text/csv',
				'.csv',
				'text/x-comma-separated-values',
				'text/comma-separated-values',
				'text/tab-separated-values',
				'text/plain',
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'', // TO DO - Excel sheets update
			]

		return (<>
			<div>
				<Button onClick={this.handleOpen.bind(this, 'open')} color="primary" variant="contained" className={classes.textField}>
					{this.state.fileId ? "Update File" :
						(this.props.name ? this.props.name : "Add File")}
				</Button>
				<DropzoneDialog
					open={this.state.open}
					onSave={this.handleSave.bind(this)}
					acceptedFiles={acceptedFiles}
					showPreviews={true}
					maxFileSize={50000000}
					filesLimit={1}
					onClose={this.handleClose.bind(this, 'open')}
				/>
			</div>
			{/* {this.props.type == "logo" &&
                this.state.fileId &&
                <img src={`${API_BASE_URL}/dapps/logo/${this.state.fileId}`} alt="Logo" />} */}
		</>)
	}
}

export default withStyles(styles, { withTheme: true })(withRouter(Upload))