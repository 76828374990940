import React, { Component } from 'react'
import {
	Button, Dialog, DialogActions, DialogContent, Typography, Toolbar, AppBar,
	MenuItem, FormControl, Select, Tab, Tabs, DialogTitle, FormLabel
} from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as Actions from 'actions'
import { connect } from 'react-redux'
import _ from 'modules/lodash'
import { generateGUID } from '../utils'
import BasicInfo from "./BasicInfo";
import Data from "./Data";
import Advanced from "./Advanced";
import UI from "./UI";
import ChipSelect from "components/@custom/ChipSelect"
import Filters from './Filters';

const styles = theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	formControl: {
		marginTop: 8,
		marginBottom: 16
	},
	selectEmpty: {
		marginTop: 16,
	},
	dialogPaper: {
		minHeight: '80vh',
		maxHeight: '80vh',
	},
});

const defaultEventState = {
	id: '',
	name: '',
	description: '',
	chart: [],
	url: '',
	appId: '',
	isActive: true,
	default: false,
	hide: false,
	rank: 0,
	type: "dashboard",
	selectedRoles: [],
	roles: [],
	tabValue: 0,
	condition: {
		selectedConditionDataElement: "",
		selectedConditionOperator: "",
		selectedConditionClause: ""
	},
	conditions: [],
	pageLevelDataModel: '',
	header: {},
	footer: {},
	body: {},
	filterPosition: "right"
};

class NewPageDialog extends Component {

	state = { ...defaultEventState };

	componentDidMount() {
		// this.props.getApps();
		this.props.getRoles(this.props.match.params.dappId);
		this.props.getDataModels(this.props.match.params.dappId);
		// this.props.getRolesByAppId(this.props.match.params.dappId);
	}

	componentDidUpdate(prevProps, prevState) {
		/*if (
			!prevProps.pageDialog.props.open &&
			this.props.pageDialog.props.open
		) {
			this.setState({
				...defaultEventState,
				...this.props.pageDialog.data,
				...this.props.page
			});
		}*/
		if (this.props.pageDialog.open && !prevProps.pageDialog.open && this.props.match.params.pageId) {
			this.setState({ ...this.props.page });
		}

		if ((this.props.roles && this.props.roles.data.length > 0)
			&& this.props.roles.data.length !== this.state.roles.length) {
			let roles = this.props.roles.data.map(role => {
				return {
					value: role._id,
					label: role.name
				}
			});
			this.setState({ roles, ...(!this.props.edit && { selectedRoles: roles }) });
		}
		if (this.props.dataModels !== prevProps.dataModels
			|| this.state.pageLevelDataModel != prevState.pageLevelDataModel) {
			this.props.dataModels.map((dataModel) => {
				if (dataModel._id === this.state.pageLevelDataModel) {
					this.setState({
						dataElements: this.props.chartType === Actions.card
							? dataModel.dataElements.filter((de) => de.dataElementType === "Integer")
								.map(item => ({
									value: item.name,
									label: item.description,
									type: item.dataElementType,
								}))
							: dataModel.dataElements.map(item => ({
								value: item.name,
								label: item.description,
								type: item.dataElementType,
							}))
					})
					return
				}
			});
		}
		if (this.props.saveSuccess !== prevProps.saveSuccess && this.props.saveSuccess) {
			this.props.history.push(`/builder/${this.props.match.params.dappId}/pages/${this.props.page._id}`);
		}
	}

	handleUIChange = (event, key) => {
		let d = { ...this.state[key] }
		d[event.target.name] = event.target.value;
		this.setState(
			_.set({ ...this.state }, key, d)
		);
	};

	handleChange = event => {
		if (event.target.name === "name") {
			this.state.url = event.target.value.replace(/\s/g, "");
		}
		this.setState(
			_.set({ ...this.state }, event.target.name, event.target.value)
		);
	};

	handleCheckboxChange = name => event => {
		this.setState({ ...this.state, [name]: event.target.checked });
	};

	closeComposeDialog = () => {
		this.props.closeNewPageDialog();
	};

	canBeSubmitted = () => {
		const { name } = this.state;
		return name.trim().length > 0;
	};

	addPage = (state) => {
		let conditions = this.state.conditions.map(cond => {
			return { ...cond, editMode: false }
		})
		console.log("this.props.match", this.props.match.params.dappId);
		let data = { ...state, appId: this.props.match.params.dappId, conditions }
		if (this.props.edit) {
			this.props.updatePage(data, true);
		} else {
			this.props.savePage(data);
		}
	}

	handleSelectedRoles = (value, name) => {
		this.setState({ [name]: value });
	};

	handleChangeTab = (event, tabValue) => {
		this.setState({ tabValue });
	};

	onConditionChangeHandler = event => {
		let condition = this.state.condition;
		condition[event.target.name] = event.target.value
		this.setState({ condition });
	}

	addCondition = (condition) => {
		let conditions = [...this.state.conditions];
		if (condition.id && condition.index > -1 && condition.editMode) {
			conditions[condition.index] = { ...condition, editMode: false };
		} else {
			let cond = condition.id ? condition : { id: generateGUID(), ...condition };
			conditions.push({ ...cond });
		}
		this.setState({ conditions });
		this.setState({ condition: { value: "" } });
	}

	deleteCondition = (condition, index) => {
		let conditions = [...this.state.conditions];
		conditions.splice(index, 1);
		this.setState({ conditions });
	}

	editCondition = (condition, index) => {
		let cond = { ...this.state.conditions[index] };
		cond.editMode = true;
		cond.index = index;
		let conditions = [...this.state.conditions];
		conditions[index] = cond;
		this.setState({ conditions: conditions, condition: cond });
	}

	handleChipChange = (value) => {
		this.setState({ selectedFilterDataElements: value });
	}

	onChangeHandler = name => event => {
		this.setState({
			[event.target.name]: event.target.value
		});
		if ("pageLevelDataModel" === event.target.name) {
			this.props.dataModels.map((dataModel) => {
				if (dataModel._id === event.target.value) {
					this.setState({
						dataElements: dataModel.dataElements.map(item => ({
							value: item.name,
							label: item.description,
							type: item.dataElementType,
						})),
						selectedFilterDataElements:[],
						filtersDataElements: dataModel.dataElements.map(item => ({
							value: item.name,
							label: item.description,
							type: item.type,
						}))
					})
				}
			});
		}
		// if ("selectedFilterDataModel" === event.target.name) {
		// 	this.props.dataModels.map((dataModel) => {
		// 		if (dataModel._id === event.target.value) {
		// 			this.setState({
		// 				selectedFilterDataElements:[],
		// 				filtersDataElements: dataModel.dataElements.map(item => ({
		// 					value: item.name,
		// 					label: item.description,
		// 					type: item.type,
		// 				}))
		// 			})
		// 		}
		// 	});
		// }
	};

	checkForNullOrUndefined = input => {
		return input === null || input === undefined;
	};

	render() {
		const { classes, pageDialog, addPage, apps, dataModels } = this.props
		const { tabValue } = this.state;
		return (
			<Dialog
				className={classes.root}
				{...pageDialog}
				onClose={this.closeComposeDialog}
				fullWidth
				maxWidth={'md'}
				classes={{ paper: classes.dialogPaper }}>
				<DialogTitle id="alert-dialog-title" position="static" className="page-dialog">
					New Page
                </DialogTitle>
				<div className={classes.root} >
					<AppBar position="static"
						className="tabPropertiesPanel bg-white p15">
						<Tabs
							aria-label="wrapped label tabs example"
							value={tabValue}
							onChange={this.handleChangeTab}
						>
							<Tab className="h-64 normal-case" label="Basic Info" />
							<Tab className="h-64 normal-case" label="Data" />
							<Tab className="h-64 normal-case" label="Advanced" />
							<Tab className="h-64 normal-case" label="Filters" />
							<Tab className="h-64 normal-case" label="Theme" />
							<Tab className="h-64 normal-case" label="Security & Access" />
						</Tabs>
					</AppBar>
				</div>
				{/* <AppBar position="static">
                    <Toolbar className="flex w-full">
                        <Typography variant="subtitle1" color="inherit">
                            New Page
                        </Typography>
                    </Toolbar>
                </AppBar> */}

				<DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
					<form className="form" noValidate autoComplete="off" >
						{tabValue === 0 && (
							<BasicInfo
								values={this.state}
								handleChange={this.handleChange}
								classes={classes}
								handleCheckboxChange={this.handleCheckboxChange}
							/>
						)}
						{tabValue === 1 && (<>
							<Data
								values={this.state}
								handleChange={this.handleChange}
								onChangeHandler={this.onChangeHandler}
								checkForNullOrUndefined={this.checkForNullOrUndefined}
								onConditionChangeHandler={this.onConditionChangeHandler}
								addCondition={this.addCondition}
								editCondition={this.editCondition}
								deleteCondition={this.deleteCondition}
								classes={classes}
								dataModels={dataModels}
							/>
						</>)}
						{tabValue === 2 && (
							<Advanced
								values={this.state}
								handleChange={this.handleChange}
								handleCheckboxChange={this.handleCheckboxChange}
								classes={classes}
							/>
						)}
					  {tabValue === 3 &&
									(
										<Filters dataModels={dataModels} values={this.state} onChangeHandler={this.onChangeHandler} 
										handleChipChange={this.handleChipChange}
										/>
									)}
						{tabValue === 4 && (
							<UI
								values={this.state}
								handleChange={this.handleChange}
								handleUIChange={this.handleUIChange}
								classes={classes}
							/>
						)}
						{tabValue === 5 && (<>
							<FormControl className="w-full max-w-sm pt-64 pb-224">
								<Typography className="text-16 sm:text-20 truncate">Access Control</Typography>
								<br />
								<ChipSelect
									className="w-full my-16"
									value={this.state.selectedRoles}
									onChange={(value) => this.handleSelectedRoles(value, 'selectedRoles')}
									placeholder="Select multiple roles"
									textFieldProps={{
										InputLabelProps: {
											shrink: true
										},
										variant: 'standard'
									}}
									options={this.state.roles}
									isMulti
									fullWidth
								/>
							</FormControl>
						</>)}
					</form>
				</DialogContent>
				<DialogActions className=" pl-8 sm:pl-16 m15 dialog-page-btns">
					<Button
						color="secondary"
						variant="outlined"
						className={classes.formControl}
						onClick={() => {
							this.closeComposeDialog();
							this.addPage(this.state);
						}}
						disabled={!this.canBeSubmitted()}
						fullWidth
					>
						Update
                    </Button>
					<Button
						color="secondary"
						variant="outlined"
						className={classes.formControl}
						onClick={() => {
							this.closeComposeDialog();
						}}
						fullWidth
					>
						Cancel
                    </Button>
				</DialogActions>
			</Dialog>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			savePage: Actions.savePage,
			updatePage: Actions.updatePage,
			closeNewPageDialog: Actions.closeNewPageDialog,
			getRoles: Actions.getRoles,
			getDataModels: Actions.getDataModels,
		},
		dispatch
	);
}

function mapStateToProps({ pages, user, roles, dataModels }) {
	return {
		pageDialog: pages.pageDialog,
		saveSuccess: pages.saveSuccess,
		page: pages.page,
		user,
		roles,
		dataModels: dataModels.data,
	};
}

export default withRouter(withStyles(styles, { withTheme: true })(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(NewPageDialog)
));
