export default {
  GET_GROUPS: undefined,
  GET_GROUPS_SUCCESS: undefined,
  GET_GROUPS_FAILURE: undefined,
  SAVE_GROUP: undefined,
  SAVE_GROUP_SUCCESS: undefined,
  SAVE_GROUP_FAILURE: undefined,
  GET_GROUP: undefined,
  GET_GROUP_SUCCESS: undefined,
  GET_GROUP_FAILURE: undefined,
  DELETE_GROUP: undefined,
  DELETE_GROUP_SUCCESS: undefined,
  DELETE_GROUP_FAILURE: undefined,
  UPDATE_GROUP: undefined,
  UPDATE_GROUP_SUCCESS: undefined,
  UPDATE_GROUP_FAILURE: undefined,
};
