import { CircularProgress, CssBaseline, Divider, Drawer, Toolbar } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import NavBox from './NavBox';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
	version: {
		marginLeft: 16,
		fontSize: '12px !important',
		marginBottom: 5,
	},
	loaderContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 'calc(100vh - 75px)',
		width: 185,
	},
}))


const View = (props) => {
	const classes = useStyles()
	const [versionInfo, setVersionInfo] = useState(null)
	const [versionInfoMetaJSON, setVersionInfoMetaJSON] = useState(null)
	const [open, setOpen] = useState(true)

	useEffect(() => {
		const appId = props.location.pathname.split("/")[3];
		Axios.get(API_BASE_URL + '/version', { headers: { 'appId': appId } }).then(response => {
			if (response.data) {
				setVersionInfo(response.data);
			}
		}).catch((e) => {
			console.log(e)
		})
		getMetaJSONData()
	}, [])

	const getMetaJSONData = () => {
		fetch('/meta.json')
			.then((response) => {
				return response.json()
			})
			.then((meta) => {
				setVersionInfoMetaJSON(meta)
			}).catch(e => {
				console.log(e)
			})
	}

	const getAPIBuildVersion = () => {
		return versionInfo ? ((versionInfo.VERSION || versionInfo.version) + (versionInfo['BUILD NUMBER'] ? '.' : '') + (versionInfo['BUILD NUMBER'] || '')) : '-'
	}

	const getBuildDate = () => {
		return versionInfoMetaJSON ? versionInfoMetaJSON['BUILD DATE'] : null;
	}

	const getBuildVersion = () => {
		return Boolean(Object.keys(versionInfoMetaJSON || {}).length) ? ((versionInfoMetaJSON.VERSION || versionInfoMetaJSON.version) + 
		(versionInfoMetaJSON['BUILD NUMBER'] ? '.' : '') + (versionInfoMetaJSON['BUILD NUMBER'] || '')) : null;
	}

	const handleClick = () => {
		setOpen(!open);
	}

	if (props.member?.memberByIdLoading) {
		return <div className={classes.loaderContainer}><CircularProgress /></div>
	}		

	return (<div className="dashboardkeywordB-main">
		<div className={classes.root}>
			<CssBaseline />
			<div className="side-menu">
				<nav className={classes.drawer} aria-label="mailbox folders">
					<Drawer
						className={classes.drawer}
						variant="permanent"
						classes={{
							paper: classes.drawerPaper,
						}}
					>
						<Toolbar />
						{/* <Toolbar /> */}
						<div className={classes.drawerContainer}>
							{props.navigation.map((item, i) => {
								return <NavBox nav={item} key={i} />
							})}
							<Divider />
						</div>
						<span className={classes.version} variant="caption" display="block" gutterBottom>
							API Version - {getAPIBuildVersion()}
						</span>
						{
							getBuildVersion() &&
							<span className={classes.version} variant="caption" display="block" gutterBottom>
								Version - {getBuildVersion()}
							</span>
						}
						{
							getBuildDate() && <span className={classes.version} variant="caption" display="block" gutterBottom>
								Build Date - {new Date(getBuildDate()).toLocaleDateString()}
							</span>
						}
					</Drawer>
				</nav>
			</div>
		</div>
	</div >)
}

export default withRouter(View)