import React from 'react'
import {
    Button, Checkbox, FormControl, FormControlLabel, FormLabel, NativeSelect, Radio,
    RadioGroup, Tab, Tabs, TextField, Typography, withStyles, Select, MenuItem, Input,
    AppBar, Dialog, DialogActions, DialogTitle, Grid
} from '@material-ui/core'
import TableInput from './TableInput'
import ChipSelect from 'components/@custom/ChipSelect'
import Headers from './Headers'
const Data = (props) => {
    const { classes, dataModels } = props;
    const { dataElements, lookupDataElements, formElements, elementType, elementsData } = props;
    const { dataModel, workflow, dataElement, lookupDataModal, lookupDataElement, lookupDataKey,
        lookupDataValue, dataSelectorDataModal, dataSelectorElement, dataSelectorElementForSearch,
        useGrouping, groupingDataElement,
        fileSave,
        submitFileData, reloadDependents, childFormElement, requiredFormElement, parentFormElement
    } = props.elementsData.data.data
    const { setData } = props

    return (<><div className="formPmodel">
        {elementType !== 'button' && elementType !== 'HTML'
            && elementType !== 'header' && elementType !== 'label' && elementType !== 'worksheet' &&
            // elementType !== 'tab' && elementType !== 'multiLine' && .elementType !== 'multiStepForm' &&
            <>
                <Grid container spacing={8} className="formPmodel">
                    {(elementType !== 'dataSelector' && elementType !== 'dataTable' && elementType !== 'file') && (<>
                        <Grid item xs={4} xs={3}>
                            <label>Default Value</label>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                label=""
                                margin="dense"
                                variant="filled"
                                fullWidth
                                value={elementsData.data.data.defaultValue}
                                onChange={(e) => { setData("defaultValue", e) }}
                            />
                        </Grid>
                        <Grid item xs={4} xs={3}>
                            <label>Data Input type</label>
                        </Grid>
                        <Grid item xs={8}>
                            <RadioGroup name="computed" row>
                                <FormControlLabel value="Editable"
                                    control={<Radio value={true}
                                        checked={elementsData.data.data.computed == "Editable"}
                                        onClick={(e) => {
                                            setData("computed", "Editable")
                                        }} />} label="Editable" />
                                <FormControlLabel value="Computed"
                                    control={<Radio value={true}
                                        checked={elementsData.data.data.computed == "Computed"}
                                        onClick={(e) => {
                                            setData("computed", "Computed")
                                        }} />} label="Computed" />
                                <FormControlLabel value="Yes"
                                    control={<Radio value={true}
                                        checked={props.elementsData.data.data.computed == "Lookup"}
                                        onClick={(e) => {
                                            setData("computed", "Lookup")
                                        }} />} label="Lookup" />
                            </RadioGroup>
                        </Grid>
                    </>
                    )}

                    {elementType === 'dataTable' && (
                        <>
                            <Grid item xs={12}>
                                <FormLabel variant="h6" className="label-headings">
                                    {"Data Handler *"}
                                </FormLabel></Grid><br></br>
                            <Grid item xs={4}>
                                <FormLabel>Select a Data Model</FormLabel>
                            </Grid>
                            <Grid item xs={7}>
                                <Select
                                    fullWidth
                                    value={dataModel || ''}
                                    onChange={(e) => {
                                        props.handleLookupDataModalChange('dataModel', e.target.value)
                                    }}
                                    input={<Input />}
                                >
                                    <MenuItem value="">{"Select a Data Model"}</MenuItem>
                                    {dataModels && dataModels.map(item => (
                                        <MenuItem key={item._id}
                                            value={item._id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <FormLabel>Select data elements for display</FormLabel>
                            </Grid>
                            <Grid item xs={7}>
                                <Select
                                    fullWidth
                                    multiple
                                    value={dataElement || []}
                                    onChange={(e) => props.setData("dataElement", e.target.value)}
                                    input={<Input />}>
                                    <MenuItem value="">{"Select data elements"}</MenuItem>
                                    <MenuItem value={[]}>{"Select data elements"}</MenuItem>
                                    {lookupDataElements && lookupDataElements.map(item => (
                                        <MenuItem key={item._id}
                                            value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={useGrouping}
                                            onChange={(e) => { setData("useGrouping", e) }}
                                            value="useGrouping"
                                            color="primary"
                                        />
                                    }
                                    label="Use Grouping"
                                />
                            </Grid>
                            {useGrouping && (<>
                                <Grid item xs={4}>
                                    <FormLabel component="legend">{"Select a column for grouping"}</FormLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <Select
                                        fullWidth
                                        value={groupingDataElement || ''}
                                        placeholder="Select a data element"
                                        onChange={(e) => props.setData("groupingDataElement", e.target.value)}
                                        input={<Input />}>
                                        <MenuItem value="">{"Select a grouping data element"}</MenuItem>
                                        {lookupDataElements && lookupDataElements.map(item => (
                                            <MenuItem key={item._id}
                                                value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </>)}

                            <Grid item xs={4}>
                                <FormLabel>Select a Parent Form element</FormLabel>
                            </Grid>
                            <Grid item xs={7}>
                                <Select
                                    fullWidth
                                    value={parentFormElement || ''}
                                    onChange={(e) => {
                                        setData('parentFormElement', e.target.value)
                                    }}
                                    input={<Input />}
                                >
                                    <MenuItem value="">{"Select a Parent Form element"}</MenuItem>
                                    {formElements && formElements.filter(fe => fe.item && fe.item !== props.elementsData.basic.name)
                                        .map(formElement => (
                                            <MenuItem key={formElement.item}
                                                value={formElement.item}>
                                                {formElement.item}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <FormLabel>Required Form elements before submit</FormLabel>
                            </Grid>
                            <Grid item xs={7}>
                                <Select
                                    fullWidth
                                    multiple
                                    value={requiredFormElement || []}
                                    onChange={(e) => {
                                        console.log('e.target.value', e.target.value);
                                        let val = e.target.value.filter(v => {
                                            console.log('v === >', v);
                                            let f = formElements.filter(fe => {
                                                return fe.item === v
                                            })
                                            console.log('f === ', f, f.length);
                                            return !(f.length === 0)
                                        })
                                        console.log('val ==> ', val);
                                        setData('requiredFormElement', val)
                                        // setData('requiredFormElement', e.target.value)
                                    }}
                                    input={<Input />}
                                >
                                    <MenuItem value="">{"Required Form elements before submit"}</MenuItem>
                                    {formElements && formElements.filter(fe => fe.item && fe.item !== props.elementsData.basic.name)
                                        .map(formElement => (
                                            <MenuItem key={formElement.item}
                                                value={formElement.item}>
                                                {formElement.item}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                        </>
                    )}

                    {elementType === 'dataSelector' && (<>
                        <Grid item xs={4}>
                            <label>Data</label>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid item xs={4}>
                                <FormLabel>Select a Data Model</FormLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    fullWidth
                                    value={dataSelectorDataModal}
                                    onChange={(e) => {
                                        props.handleLookupDataModalChange('dataSelectorDataModal', e.target.value)
                                    }}
                                    input={<Input />}
                                >
                                    {dataModels && dataModels.map(item => (
                                        <MenuItem key={item._id}
                                            value={item._id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid item xs={4}>
                                <FormLabel>Select a data elements for search</FormLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    fullWidth
                                    multiple
                                    value={dataSelectorElementForSearch || []}
                                    onChange={(e) => props.setData("dataSelectorElementForSearch", e.target.value)}
                                    input={<Input />}
                                >
                                    {lookupDataElements && lookupDataElements.map(item => (
                                        <MenuItem key={item._id}
                                            value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid item xs={4}>
                                <FormLabel>Select a data elements</FormLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    fullWidth
                                    multiple
                                    value={dataSelectorElement || []}
                                    onChange={(e) => props.setData("dataSelectorElement", e.target.value)}
                                    input={<Input />}
                                >
                                    {lookupDataElements && lookupDataElements.map(item => (
                                        <MenuItem key={item._id}
                                            value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                    </>
                    )}
                    {elementType === 'file' && (<>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormLabel component="legend">{"File save information"}</FormLabel>
                                <RadioGroup
                                    aria-label="File save information"
                                    name="share"
                                    value={fileSave}
                                    onClick={(e) => { props.setData("fileSave", e) }}
                                    row>
                                    <FormControlLabel
                                        value="file"
                                        control={<Radio />}
                                        label="Save as File" />
                                    <FormControlLabel
                                        value="dataModel"
                                        control={<Radio />}
                                        label="Save into a selected data model" />
                                    <FormControlLabel
                                        value="newDataModel"
                                        control={<Radio />}
                                        label="New Data model" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {fileSave === 'dataModel' && <>
                            <Grid item xs={4}>
                                <FormLabel>Select a Data Model</FormLabel>
                            </Grid>
                            <Grid item xs={7}>
                                <Select
                                    fullWidth
                                    value={dataModel || ''}
                                    onChange={(e) => {
                                        props.handleLookupDataModalChange('dataModel', e.target.value)
                                    }}
                                    input={<Input />}
                                >
                                    <MenuItem value="">{"Select a Data Model"}</MenuItem>
                                    {dataModels && dataModels.map(item => (
                                        <MenuItem key={item._id}
                                            value={item._id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </>}
                    </>)}
                    {elementsData.data.data.computed == "Computed" && (
                        <>
                            <Grid item xs={4}>
                                <label>Formula</label>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    label=""
                                    margin="dense"
                                    variant="filled"
                                    fullWidth
                                    value={elementsData.data.data.formula}
                                    onChange={(e) => { props.setData("formula", e) }}
                                />
                            </Grid>
                        </>
                    )}
                    {elementsData.data.data.computed == "Lookup" && (
                        <>
                            <Grid item xs={4}>
                                <NativeSelect
                                    fullWidth
                                    onChange={(e) => props.handleLookupDataModalChange('lookupDataModal', e.target.value)}
                                >
                                    <option value="" selected disabled>Select Data Model</option>
                                    {dataModels && dataModels.map((element) => {
                                        return <option value={element._id} selected={element._id === lookupDataModal}>{element.name}</option>
                                    })}
                                </NativeSelect>
                            </Grid>
                            <Grid item xs={4}>
                                <NativeSelect
                                    fullWidth
                                    onChange={(e) => props.setData("lookupDataKey", e.target.value)}
                                >
                                    <option value="" selected disabled>Select Key</option>
                                    {lookupDataElements && lookupDataElements.map((element) => {
                                        return <option value={element.name} selected={element.name === lookupDataKey}>{element.name}</option>
                                    })}
                                </NativeSelect>
                            </Grid>
                            <Grid item xs={4}>
                                <NativeSelect
                                    fullWidth
                                    onChange={(e) => props.setData("lookupDataValue", e.target.value)}
                                >
                                    <option value="" selected disabled>Select Value</option>
                                    {formElements && formElements.map((element) => {
                                        return <option value={element.item} selected={element.item === lookupDataValue} > {element.item}</option>
                                    })}
                                </NativeSelect>
                            </Grid>
                            <Grid item xs={12}>
                                <NativeSelect
                                    fullWidth
                                    onChange={(e) => props.setData("lookupDataElement", e.target.value)}
                                >
                                    <option value="" selected disabled>Select date element</option>
                                    {lookupDataElements && lookupDataElements.map((element) => {
                                        return <option value={element.name} selected={element.name === lookupDataElement}>{element.name}</option>
                                    })}
                                </NativeSelect>
                            </Grid>

                        </>
                    )}
                    <br />
                    {props.showKeyValueOptions && <>
                        <Grid item xs={4}>
                            <label>Data Source</label>
                        </Grid>
                        <Grid item xs={8}>
                            <RadioGroup name="align" row>
                                <FormControlLabel control={
                                    <Radio value={"static"}
                                        checked={elementsData.data.data.dataType == 'static'}
                                        onClick={(e) => {
                                            props.setData("dataType", "static");
                                            props.setData("dynamicDataAPI", null);
                                            props.setData("dynamicDataFunction", null)
                                        }} />} label="Enter Data" />
                                <FormControlLabel control={
                                    <Radio value={"workflow"}
                                        checked={elementsData.data.data.dataType == 'workflow'}
                                        onClick={(e) => {
                                            props.setData("dataType", "workflow");
                                            props.setData("workflow", null);
                                            props.setData("dynamicDataFunction", null)
                                        }} />} label="Workflow" />
                                <FormControlLabel control={
                                    <Radio value={"dataModel"}
                                        checked={elementsData.data.data.dataType == 'dataModel'}
                                        onClick={(e) => {
                                            props.setData("dataType", "dataModel");
                                            props.setData("dynamicDataAPI", null);
                                            props.setData("dynamicDataFunction", null)
                                        }} />} label="Data Model" />
                                <FormControlLabel control={
                                    <Radio value={"dynamic"}
                                        checked={elementsData.data.data.dataType == 'dynamic'}
                                        onClick={(e) => {
                                            props.setData("dataType", "dynamic");
                                            props.setData("values", [])
                                        }} />} label="External API" />
                                <FormControlLabel control={
                                    <Radio value={"javascriptFunction"}
                                        checked={elementsData.data.data.dataType == 'javascriptFunction'}
                                        onClick={(e) => {
                                            setData("dataType", "javascriptFunction");
                                            setData("dynamicDataAPI", null);
                                            setData("dynamicDataFunction", null)
                                        }} />} label="JavaScript Function" />
                            </RadioGroup>
                        </Grid>
                    </>}
                    <Grid item xs={12}>
                    </Grid>
                    {props.showKeyValueOptions && elementsData.data.data.dataType == "static" && <>
                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label=""
                                type=""
                                margin="dense"
                                variant="filled"
                                placeholder="key"
                                fullWidth
                                value={props.vkey}
                                onChange={props.setKey}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label=""
                                margin="dense"
                                variant="filled"
                                fullWidth
                                type={elementType == 'slider' ? 'number' : 'text'}
                                placeholder="value"
                                value={props.value}
                                onChange={props.setValue}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <Button variant="contained" color="secondary"
                                onClick={() => { props.pushValue("values") }} className={classes.button}>
                                {"ADD"}
                            </Button>
                        </Grid>
                    </>}
                </Grid>
                <Grid container spacing={8}>
                    {props.showKeyValueOptions && elementsData.data.data.dataType == "workflow" && (
                        <>
                            <Grid item xs={4}>
                                <label>Workflow</label>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    value={workflow}
                                    fullWidth
                                    onChange={props.handleWorkflowChange}

                                >
                                    {props.workflows && props.workflows.map((item) => {
                                        return (
                                            <MenuItem value={item._id} key={item._id}>
                                                {item.name}
                                            </MenuItem>
                                        )
                                    })
                                    }
                                </Select>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid container spacing={8}>
                    {props.showKeyValueOptions && elementsData.data.data.dataType == "dataModel" && (
                        <>
                            <Grid item xs={4}>
                                <label>Data Model</label>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    value={dataModel}
                                    fullWidth
                                    onChange={props.handleDataModelChange}

                                >
                                    {props.dataModels && props.dataModels.map((item) => {
                                        return (
                                            <MenuItem value={item._id} key={item._id}>
                                                {item.name}
                                            </MenuItem>
                                        )
                                    })
                                    }
                                </Select>
                            </Grid>
                        </>
                    )}
                    {dataModel && dataElements && (
                        <>
                            <Grid item xs={4}>
                                <label>Data Elements</label>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    value={dataElement}
                                    fullWidth
                                    onChange={props.handleDataElementChange}
                                >
                                    {dataElements.map((item) => {
                                        return (
                                            <MenuItem value={item.name} key={item._id}>
                                                {item.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                        </>
                    )}
                </Grid>
                {props.showKeyValueOptions && elementsData.data.data.dataType == "static"
                    && <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Typography variant={'h6'}>Current Key/Value Pairs:</Typography>
                        </Grid>
                        {props.generateValues()}
                    </Grid>}

                {props.showKeyValueOptions && elementType == "table" && <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <TableInput setData={(property, val) => { setData(property, val) }}
                            json={elementsData.data} />
                    </Grid>
                </Grid>}

                {props.showKeyValueOptions && elementsData.data.data.dataType == "dynamic"
                    && <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <label>api URL (Required)</label>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                label=""
                                margin="dense"
                                variant="filled"
                                value={elementsData.data.data.dynamicDataAPI}
                                onChange={(e) => { setData("dynamicDataAPI", e) }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <label>Enter Function Name</label>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                value={elementsData.data.data.dynamicDataFunction}
                                onChange={(e) => { setData("dynamicDataFunction", e) }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>}

            </>}
        {elementType == "button" && < >
            <Grid container spacing={8}>
                <Grid item xs={4}>
                    <FormLabel>Action</FormLabel>
                </Grid>
                <Grid item xs={8}>
                    <FormControl className="native-select-dropdown">
                        <NativeSelect fullWidth
                            value={elementsData.data.data.selectedFunction}
                            onChange={props.changeSelectedFunction}
                        >
                            <option value="" selected disabled>Select an action</option>
                            <option value="save">Save</option>
                            <option value="function" >Function</option>
                            <option value="api" >Api</option>

                        </NativeSelect>
                    </FormControl>
                </Grid>
                {elementsData.data.data.selectedFunction == 'save' && <>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={submitFileData}
                                    onChange={(e) => { setData("submitFileData", e) }}
                                    value="submitFileData"
                                    color="primary"
                                />
                            }
                            label="Submit File Data"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={reloadDependents}
                                    onChange={(e) => { setData("reloadDependents", e) }}
                                    value="reloadDependents"
                                    color="primary"
                                />
                            }
                            label="Reload Dependent Form elements"
                        />
                    </Grid>
                    {reloadDependents && <>
                        <Grid item xs={4}>
                            <FormLabel>Select a Child Form element</FormLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <Select
                                fullWidth
                                value={childFormElement || ''}
                                onChange={(e) => {
                                    setData('childFormElement', e.target.value)
                                }}
                                input={<Input />}
                            >
                                <MenuItem value="">{"Select a Form element"}</MenuItem>
                                {formElements && formElements.filter(fe => fe.item && fe.item !== props.elementsData.basic.name)
                                    .map(formElement => (
                                        <MenuItem key={formElement.item}
                                            value={formElement.item}>
                                            {formElement.item}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                    </>
                    }
                    {submitFileData && <>
                        <Grid item xs={4}>
                            <FormLabel>Required Form elements before submit</FormLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <Select
                                fullWidth
                                multiple
                                value={requiredFormElement || []}
                                onChange={(e) => {
                                    console.log('e.target.value', e.target.value);
                                    let val = e.target.value.filter(v => {
                                        console.log('v === >', v);
                                        let f = formElements.filter(fe => {
                                            return fe.item === v
                                        })
                                        console.log('f === ', f, f.length);
                                        return !(f.length === 0)
                                    })
                                    console.log('val ==> ', val);
                                    setData('requiredFormElement', val)
                                }}
                                input={<Input />}
                            >
                                <MenuItem value="">{"Required Form elements before submit"}</MenuItem>
                                {formElements && formElements.filter(fe => fe.item && fe.item !== props.elementsData.basic.name)
                                    .map(formElement => (
                                        <MenuItem key={formElement.item}
                                            value={formElement.item}>
                                            {formElement.item}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid></>}
                </>}
                {elementsData.data.data.selectedFunction == 'function' && <>
                    <Grid item xs={4}>
                        <FormLabel>Enter Function Name (required)</FormLabel>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            margin="dense"
                            variant="filled"
                            fullWidth
                            value={elementsData.data.data.functionName}
                            onChange={(e) => { setData("functionName", e) }}
                        />

                    </Grid>
                </>}

                {elementsData.data.data.selectedFunction == 'api' && <>
                    <Grid item xs={4}>
                        <FormLabel>Enter URL (required)</FormLabel>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            margin="dense"
                            variant="filled"
                            fullWidth
                            value={elementsData.data.data.url}
                            onChange={(e) => { setData("url", e) }}
                        />

                    </Grid>
                    <>
                        <Grid item xs={4}>
                            <TextField
                                label=""
                                margin="dense"
                                variant="filled"
                                placeholder="key"
                                fullWidth
                                value={props.headerKey}
                                onChange={props.setHeaderKey}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label=""
                                margin="dense"
                                variant="filled"
                                fullWidth
                                placeholder="value"
                                value={props.headerValue}
                                onChange={props.setHeaderValue}
                            />
                        </Grid>

                        <Grid item xs={4}>

                            <Button variant="contained" color="secondary"
                                onClick={() => { props.pushValue("headers") }} className={classes.button}>
                                {"+"}
                            </Button>
                        </Grid>
                    </>

                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Typography variant={'h6'}>Current Header Key/Value Pairs:</Typography>
                        </Grid>
                        <Headers
                            removeKeyValuePair={props.removeKeyValuePair}
                            elementsData={elementsData}
                        />
                    </Grid>

                </>}
            </Grid>
        </>}
        {elementType === 'worksheet' && <>
            <Grid container spacing={12}>
                <Grid item xs={4}>
                    <label>Data Model</label>
                </Grid>
                <Grid item xs={4}>
                    <Select
                        value={dataModel}
                        fullWidth
                        style={{ backgroundColor: '#eee' }}
                        onChange={props.handleDataModelChange}>
                        {props.dataModels && props.dataModels.map((item) => {
                            return (
                                <MenuItem value={item._id} key={item._id}>
                                    {item.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </Grid>
            </Grid>
            {dataModel && dataElements && (<>
                <Grid container spacing={12}>
                    <Grid item xs={4}>
                        <label>Data Elements</label>
                    </Grid>
                    <Grid item xs={4}>
                        <Select
                            value={dataElement}
                            fullWidth
                            onChange={props.handleDataElementChange}>
                            {dataElements.map((item) => {
                                return (
                                    <MenuItem value={item.name} key={item._id}>
                                        {item.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>

                        {/* <ChipSelect
							className="w-full my-16"
                            value={dataElement ? dataElement : [] }
                            onChange={props.handleDataElementChange}
							placeholder="Select multiple dataElements"
							textFieldProps={{
								InputLabelProps: {
									shrink: true
								},
								variant: 'standard'
							}}
							options={dataElements && dataElements.map(item => ({
								value: item._id,
								label: item.name
							}))}
							isMulti
						/> */}
                    </Grid>
                </Grid>
            </>)}
        </>}
    </div></>)
}
export default Data