import * as Actions from 'actions'

import ListComponentImage from 'images/dsilo/list.svg'
import SimpleListComponentImage from 'images/dsilo/simplelist.svg'
import SimpleTableComponentImage from 'images/dsilo/simpletable.svg'
import BarComponentImage from 'images/dsilo/barchart.svg'
import StackedBarComponentImage from 'images/dsilo/bargraph1.svg'
import PieComponentImage from 'images/dsilo/piechart.svg'
import MixedComponentImage from 'images/dsilo/mixedchart.svg'
import LineComponentImage from 'images/dsilo/linechart.svg'
import BubbleComponentImage from 'images/dsilo/bubblechart.svg'
import DoughnutComponentImage from 'images/dsilo/doughnutchart.svg'
import RadarComponentImage from 'images/dsilo/radarchart.svg'
import PolarComponentImage from 'images/dsilo/polarchart.svg'
import CardComponentImage from 'images/dsilo/card.svg'
import OrgChartComponentImage from 'images/dsilo/organization-generic.svg'
import BulletComponentImage from 'images/dsilo/bulletchart.svg'
import SummaryTableComponentImage from 'images/dsilo/simpletable.svg'

export default [
    {
        id: Actions.listChart,
        buttonStyle: "listButton",
        imageStyle: "listButtonImage",
        buttonTextStyle: "buttonText",
        buttonText: "Table",
        src: ListComponentImage
    },
    {
        id: Actions.pieChart,
        buttonContainerStyle: "buttonImageContainer",
        buttonStyle: "barButton",
        imageStyle: "listButtonImage",
        buttonTextStyle: "buttonText",
        buttonText: "Pie Chart",
        src: PieComponentImage,
    },
    {
        id: Actions.barChart,
        buttonStyle: "listButton",
        imageStyle: "listButtonImage",
        buttonTextStyle: "buttonText",
        buttonText: "Bar Chart",
        src: BarComponentImage,
    },
    {
        id: Actions.doughnutChart,
        buttonStyle: "barButton",
        buttonContainerStyle: "buttonImageContainer",
        imageStyle: "listButtonImage",
        buttonTextStyle: "buttonText",
        buttonText: "Doughnut Chart",
        src: DoughnutComponentImage
    },
    // {
    //     id: Actions.stackedBarChart,
    //     buttonStyle: "listButton",
    //     imageStyle: "listButtonImage",
    //     buttonTextStyle: "buttonText",
    //     buttonText: "Stacked Bar Chart",
    //     src: StackedBarComponentImage,
    // },
    {
        id: Actions.lineChart,
        buttonContainerStyle: "buttonImageContainer",
        buttonStyle: "barButton",
        imageStyle: "listButtonImage",
        buttonTextStyle: "buttonText",
        buttonText: "Line Chart",
        src: LineComponentImage
    },
    // {
    //     id: Actions.bubbleChart,
    //     buttonStyle: "listButton",
    //     imageStyle: "listButtonImage",
    //     buttonTextStyle: "buttonText",
    //     buttonText: "Bubble Chart",
    //     src: BubbleComponentImage
    // },
    // {
    //     id: Actions.radarChart,
    //     buttonStyle: "listButton",
    //     imageStyle: "listButtonImage",
    //     buttonTextStyle: "buttonText",
    //     buttonText: "Radar Chart",
    //     src: RadarComponentImage
    // },
    // {
    //     id: Actions.polarChart,
    //     buttonContainerStyle: "buttonImageContainer",
    //     buttonStyle: "barButton",
    //     imageStyle: "listButtonImage",
    //     buttonTextStyle: "buttonText",
    //     buttonText: "Polar Chart",
    //     src: PolarComponentImage
    // },
    // {
    //     id: Actions.orgChart,
    //     buttonStyle: "listButton",
    //     imageStyle: "listButtonImage",
    //     buttonTextStyle: "buttonText",
    //     buttonText: "Org Chart",
    //     src: OrgChartComponentImage
    // },
    // {
    //     id: Actions.bulletChart,
    //     buttonContainerStyle: "buttonImageContainer",
    //     buttonStyle: "listButton",
    //     imageStyle: "listButtonImage",
    //     buttonTextStyle: "buttonText",
    //     buttonText: "Bullet Chart",
    //     src: BulletComponentImage
    // },
    {
        id: Actions.card,
        buttonStyle: "listButton",
        imageStyle: "listButtonImage",
        buttonTextStyle: "buttonText",
        buttonText: "Card",
        src: CardComponentImage
    }, {

        id: Actions.simpleList,
        buttonContainerStyle: "buttonImageContainer",
        buttonStyle: "listButton",
        imageStyle: "listButtonImage",
        buttonTextStyle: "buttonText",
        buttonText: "Simple List",
        src: SimpleListComponentImage
    },
    {
        id: Actions.simpleTable,
        buttonContainerStyle: "buttonImageContainer",
        buttonStyle: "listButton",
        imageStyle: "listButtonImage",
        buttonTextStyle: "buttonText",
        buttonText: "Simple Table",
        src: SimpleTableComponentImage
    },
    {
        id: Actions.html,
        buttonStyle: "listButton",
        imageStyle: "listButtonImage",
        buttonTextStyle: "buttonText",
        buttonText: "HTML",
        src: CardComponentImage
    },
    {
        id: Actions.mixedChart,
        buttonContainerStyle: "buttonImageContainer",
        buttonStyle: "barButton",
        imageStyle: "listButtonImage",
        buttonTextStyle: "buttonText",
        buttonText: "Mixed Chart",
        src: MixedComponentImage
    },
    {
        id: Actions.links,
        buttonContainerStyle: "buttonImageContainer",
        buttonStyle: "barButton",
        imageStyle: "listButtonImage",
        buttonTextStyle: "buttonText",
        buttonText: "Links",
        src: SimpleListComponentImage
    },
    // {
    //     id: Actions.summaryTable,
    //     buttonStyle: "listButton",
    //     imageStyle: "listButtonImage",
    //     buttonTextStyle: "buttonText",
    //     buttonText: "Summary Table",
    //     src: SummaryTableComponentImage
    // }
]
