const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
import { request } from 'modules/client'

export class MemberService {

	static getMembers(appId) {
		const uri = API_BASE_URL + "/member"
		return request(uri, {
			method: 'GET',
			headers: { 'appId': appId }
		})
	}

	static getMember(params) {
		const uri = API_BASE_URL + "/member/" + params.memberId
		return request(uri, {
			method: 'GET',
			headers: { 'appId': params.dappId }
		})
	}

	static getMemberByUserId(params) {
		console.log("getMemberByUserId", params);
		const uri = API_BASE_URL + "/member/uid"
		return request(uri, {
			method: 'POST',
			headers: { 'appId': params.dappId },
			payload: {
				email: params.email,
				id: params.id
			}
		})
	}

	static saveMember(data) {
		const uri = API_BASE_URL + "/member"
		return request(uri, {
			method: 'POST',
			headers: { 'appId': data.appId },
			payload: data
		})
	}

	static updateMember(data) {
		const uri = API_BASE_URL + "/member/" + data._id
		return request(uri, {
			method: 'PATCH',
			headers: { 'appId': data.appId },
			payload: data
		})
	}

	static deleteMember(data) {
		const uri = API_BASE_URL + "/member/" + data._id
		return request(uri, {
			method: 'DELETE',
			headers: { 'appId': data.appId },
		})
	}
	static disableMember(data) {
		const uri = API_BASE_URL + "/member/" + data._id + "/disable"
		return request(uri, {
			method: 'POST',
			headers: { 'appId': data.appId },
			payload: {},
		})
	}
}