import React from 'react'
import {
	TextField, FormControl, FormLabel, Select, MenuItem, Grid, FormControlLabel, Checkbox
} from '@material-ui/core'
import DataElementDND from './DataElementDND'

const BasicInfo = (props) => {
	let { values, onChangeHandler, classes, dataModels } = props
	console.log('values.dataElements', values);
	return (<>
		<div className="pro-basic">
			<Grid container className={classes.root} spacing={2}>
				<Grid item xs={6}>
					<FormControl>
						<FormLabel variant="h6" className="label-headings">{"Task Name *"}</FormLabel>
						<TextField
							className="form-control"
							error={values.taskName === ''}
							autoFocus
							id="taskName"
							name="taskName"
							value={values.taskName}
							onChange={onChangeHandler}
							variant="outlined"
							color="secondary"
							placeholder="Enter Task Name"
							required
							fullWidth
							helperText={values.taskName === '' && "Required Field"}
						/>
					</FormControl>
				</Grid>
				{/* <Grid item xs={6}>
					<FormControl>
						<FormLabel variant="h6" className="label-headings">{"Description"}</FormLabel>
                        <TextField
                            className="form-control"
                            id="description"
                            name="description"
                            value={values.description}
                            onChange={onChangeHandler}
                            variant="outlined"
                            color="secondary"
                            placeholder="Enter Description"
                            required
							fullWidth
							rows={3}
                        />
					</FormControl>
				</Grid> */}
				<Grid item xs={6}>
					<FormControl>
						<FormLabel variant="h6" className="label-headings">{"Task Type*"}</FormLabel>
						<Select
							value={values.taskType ? values.taskType : ''}
							onChange={onChangeHandler}
							inputProps={{
								name: 'taskType',
								id: 'taskType',
							}}>
							<MenuItem value=""><em>{"None"}</em></MenuItem>
							<MenuItem value="FETCH_DATA"><em>{"Fetch Data"}</em></MenuItem>
							<MenuItem value="WRITE_DATA"><em>{"Write Data"}</em></MenuItem>
							<MenuItem value="CUSTOM_MODULE"><em>{"Custom Module"}</em></MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={6}>
					<FormControl>
						<FormLabel variant="h6" className="label-headings">{"Select Data Model*"}</FormLabel>
						<Select
							value={values.dataModelId ? values.dataModelId : ''}
							onChange={onChangeHandler}
							inputProps={{
								name: 'dataModelId',
								id: 'dataModelId',
							}}>
							<MenuItem value=""><em>{"None"}</em></MenuItem>
							{dataModels.map(dm => <MenuItem value={dm._id}><em>{dm.name}</em></MenuItem>)}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={6}></Grid>
				<Grid item xs={6}>
					<FormControl>
						<FormControlLabel
							control={
								<Checkbox
									checked={values.fetchAllDocs}
									color="primary"
									value="true"
									onChange={onChangeHandler}
									name="fetchAllDocs"
								/>
							}
							label="Fetch all documents"
						/>
					</FormControl>
				</Grid>
				<Grid item xs={6}>
					<FormControl>
						<FormControlLabel
							control={
								<Checkbox
									checked={values.includeAllElements}
									color="primary"
									value="true"
									onChange={onChangeHandler}
									name="includeAllElements"
								/>
							}
							label="Include all data elements"
						/>
					</FormControl>
				</Grid>
				{!values.includeAllElements && <Grid item xs={12}>
					<DataElementDND
						elements={values.dataElements}
						selectedElements={values.selectedDataElements}
						listName1="Original elements"
						listName2="Selected data elements"
						handleElements={props.handleElements}
						label="name"
					/>
				</Grid>}
			</Grid>
		</div>
	</>)
}
export default BasicInfo