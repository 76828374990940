import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { hot } from 'react-hot-loader/root'
import View from './view'
import Redux from './redux'

const Container = (props) => {

	return (
		<View {...props} />
	)
}

export default hot(withRouter(Redux(Container)))