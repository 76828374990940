import React, { Component } from 'react'
import { connect } from 'react-redux'
import { submitLogin, emptyPrevPath } from 'actions'

const SideNavRedux = (PassedComponent) => {

	const mapStateToProps = ({ user, login, navigation, member }) => {
		return {
			login,
			user,
			navigation: navigation.nav,
			member,
		}
	}

	const mapDispatchToProps = {
		submitLogin,
		emptyPrevPath
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default SideNavRedux