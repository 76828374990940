import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, pages }) => {
		return {
			user,
			pageDialog: pages.pageDialog,
			pages: pages.data,
			page: pages.page,
		}
	}

	const mapDispatchToProps = {
        openNewPageDialog: Actions.openNewPageDialog,
		getPages: Actions.getPages,
		savePage: Actions.savePage,
		updatePage: Actions.updatePage,
		deletePage: Actions.deletePage,
		clearPage: Actions.clearPage,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux