import React, { useEffect, useState } from 'react'
import {
	Button, Select, Table, TableBody, TableCell, TableHead, TableRow, Input, MenuItem,
	Typography, Box, TextField
} from '@material-ui/core'
import Redux from '../../redux'
import { withRouter } from 'react-router-dom'

import Utils from 'packages/page/utils'

let metrics = [
	{ value: "count", label: "Count" },
	{ value: "total", label: "Total" },
	{ value: "average", label: "Average" },
	{ value: "max", label: "Max" },
	{ value: "min", label: "Min" },
	{ value: "percentage", label: "Percentage" },
]

let r = [{
	id: Utils.generateGUID(),
	columns: [
		{ type: 'emptycell' },
		{ type: 'text', value: 'Select Measure Type' },
		{ type: 'metric', value: 'total' },
	]
}, {
	id: Utils.generateGUID(),
	columns: [
		{ type: 'complex', value: [{ type: 'text', value: 'Group 1' }, { type: 'dataModel', value: '' }] },
		{ type: 'complex', value: [{ type: 'text', value: 'Group 2' }, { type: 'dataModel', value: '' }] },
		{ type: 'dataModel', value: '' },
	]
}, {
	id: Utils.generateGUID(),
	columns: [
		{ type: 'dataElement', depend: 'parentDM', value: '', options: [] },
		{ type: 'dataElement', depend: 'parentDM', value: '', options: [] },
		{ type: 'input', value: '' },
	]
}, {
	id: Utils.generateGUID(),
	columns: [
		{ type: 'value', view: 'dropdown', options: [], depend: 'parentDE', value: '' },
		{ type: 'value', view: 'dropdown', options: [], depend: 'parentDE', value: '' },
		{ type: 'dataElement', depend: 'parentDE', value: '', options: [] },
	]
}]

const ReportConfigurator = (props) => {
	const [rows, setRows] = useState([{ id: 1 }])
	const [columns, setColumns] = useState([{ id: 1 }, { id: 2 }, { id: 3 }])
	const [headerDataModels, setHeaderDataModels] = useState(["", "", ""])
	const [columnDataElementList, setColumnDataElementList] = useState([[], [], []])
	const [headers, setHeaders] = useState(["", "", ""])
	const [cellDataElements, setCellDataElements] = useState([["", "", ""]])
	const [value, setValue] = useState(null)

	const [rs, setRs] = useState(r)

	const addGroup = () => {
		console.log("addGroup")
		let g = [...groups, { id: groups.length }]
		setColumns(g)
	}
	const addColumn = () => {
		console.log("addColumn")
		let c = [...columns, { id: columns.length }]
		setColumns(c)
	}

	const addRow = () => {
		console.log("addRow");
		let row = [...rows, { id: rows.length }]
		setData(row)
	}

	const setHeaderDataModel = (dmId, index) => {
		let dataModels = [...headerDataModels]
		dataModels[index] = dmId
		setHeaderDataModels(dataModels)
	}

	const setColumnDataElement = (dmId, index) => {
		let columnDataElementList = [...columnDataElementList]
		dataModels[index] = dmId
		setColumnDataElementList(dataModels)
	}

	const setHeader = (header, index) => {
		let h = [...headers]
		h[index] = header
		setHeaders(h)
	}

	const setCellDataElement = (cellDataElement, rowIndex, columnIndex) => {
		let cde = [...cellDataElements]
		cde[rowIndex][columnIndex] = cellDataElement
		setCellDataElements(cde)
	}

	useEffect(() => {
		props.getDataModels(props.match.params.dappId)
	}, [])

	const setData = (value, rowIndex, columnIndex, innerIndex) => {
		let r = JSON.parse(JSON.stringify(rs))
		if (innerIndex > -1 && r[rowIndex].columns[columnIndex].type === 'complex') {
			r[rowIndex].columns[columnIndex].value[innerIndex].value = value
		} else {
			r[rowIndex].columns[columnIndex].value = value
		}
		r = setOptions(r, value, rowIndex, columnIndex, innerIndex)
		setRs(r)
	}

	const setOptions = (r, value, rowIndex, columnIndex, innerIndex) => {
		let rs = {}, flag = false
		if (r[rowIndex].columns[columnIndex].type === 'dataModel'
			|| (innerIndex && r[rowIndex].columns[columnIndex].type === 'complex'
				&& r[rowIndex].columns[columnIndex].value[innerIndex].type === 'dataModel')) {
			flag = true
			let dm = props.dataModels.find(dm => dm._id === value)
			rs = r.map((row, i) => {
				if (r[rowIndex].columns[columnIndex].type === 'dataModel' && row.columns[columnIndex].type === 'dataElement') {
					row.columns[columnIndex].options = dm.dataElements
				} else if (innerIndex > -1 && row.columns[columnIndex].type === 'dataElement'
					&& r[rowIndex].columns[columnIndex].value[innerIndex].type === 'dataModel'
					&& r[rowIndex].columns[columnIndex].type === 'complex') {
					row.columns[columnIndex].options = dm.dataElements
				} else if (innerIndex > -1
					&& row.columns[columnIndex].type === 'complex'
					&& row.columns[columnIndex].value[innerIndex].type === 'dataElement'
					&& r[rowIndex].columns[columnIndex].type === 'complex'
					&& r[rowIndex].columns[columnIndex].value[innerIndex].type === 'dataModel'
				) {
					row.columns[columnIndex].value[innerIndex].options = dm.dataElements
				}
				return row
			})
		}
		return flag ? rs : r
	}


	console.log('props.dataModels', props.dataModels);
	console.log('columns', columns);
	console.log('cellDataElements', columns);
	console.log('headerDataModels', headerDataModels);
	console.log('headers', headers);
	console.log('rs', rs);

	return (<div className="container">
		<h1>{"Report Configurator"}</h1>
		<Button variant="contained" color="primary" bordered size="sm" onClick={addGroup}
			style={{ "marginRight": "20px" }}>{"Add Group"}</Button>
		<Button variant="contained" color="primary" bordered size="sm" onClick={addColumn}
			style={{ "marginRight": "20px" }}>{"Add Column"}</Button>
		<Button variant="contained" color="primary" bordered size="sm" onClick={addRow}>{"Add Row"}</Button>
		<br />
		<br />
		<br />
		<Typography >{"Please data models for each column :"}</Typography>

		<Table>
			<TableHead>
				<TableRow>
					{rs[0].columns.map((column, i) => (
						<TableCell key={i}>
							{column.type === 'emptycell' && <></>}
							{column.type === 'text' && <>
								<Typography variant="div" gutterBottom>
									<Box fontWeight="fontWeightBold" m={1}>{column.value}</Box>
								</Typography>
							</>}
							{column.type === 'metric' && <><Select
								fullWidth
								labelId="metric-label"
								value={column.value}
								onChange={(event) => setData(event.target.value, 0, i)}
								input={<Input />}
								inputProps={{
									name: 'metric',
									id: 'metric',
								}}>
								<MenuItem value={""}>
									{"Please select a metric"}
								</MenuItem>
								{metrics.map(m => <MenuItem value={m.value}>{m.label}</MenuItem>)}
							</Select>
							</>}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableHead>
				<TableRow>
					{rs[1].columns.map((column, i) => (
						<TableCell key={i}>
							{column.type === 'emptycell' && <></>}

							{column.type === 'text' && <>
								<Typography variant="div" gutterBottom>
									<Box fontWeight="fontWeightBold" m={1}>{column.value}</Box>
								</Typography>
							</>}

							{column.type === 'metric' && <><Select
								fullWidth
								labelId="metric-label"
								value={column.value}
								onChange={(event) => setData(event.target.value, 1, i)}
								input={<Input />}
								inputProps={{
									name: 'metric',
									id: 'metric',
								}}>
								<MenuItem value={""}>
									{"Please select a metric"}
								</MenuItem>
								{metrics.map(m => <MenuItem value={m.value}>{m.label}</MenuItem>)}
							</Select>
							</>}

							{column.type === 'dataModel' && <>
								<Select
									fullWidth
									value={column.value}
									onChange={(e) => setData(e.target.value, 1, i)}
									input={<Input />}>
									<MenuItem value="">{"Select a Data Model"}</MenuItem>
									{props.dataModels?.map(dm => (
										<MenuItem key={dm._id}
											value={dm._id}>
											{dm.name}
										</MenuItem>
									))}
								</Select>
							</>}

							{column.type === 'dataElement' && column.options && (<>
								<Grid item xs={4}>
									<label>Data Elements</label>
								</Grid>
								<Grid item xs={8}>
									<Select
										value={column.value}
										fullWidth
										onChange={(e) => setData(e.target.value, 1, i)}>
										{column.options.map((item) => (
											<MenuItem value={item.name} key={item._id}>
												{item.name}
											</MenuItem>)
										)}
									</Select>
								</Grid>
							</>)}

							{column.type === 'complex' && <>
								{column.value.map((item, k) => {
									console.log('item ==>', item);
									return (<React.Fragment key={k + '' + i}>
										{item.type === 'emptycell' && <></>}
										{item.type === 'text' && <>
											<Typography variant="div" gutterBottom>
												<Box fontWeight="fontWeightBold" m={1}>{item.value}</Box>
											</Typography>
										</>}
										{item.type === 'dataModel' && <>
											<Select
												fullWidth
												value={item.value}
												onChange={(e) => setData(e.target.value, 1, i, k)}
												input={<Input />}>
												<MenuItem value="">{"Select a Data Model"}</MenuItem>
												{props.dataModels?.map(dm => (
													<MenuItem key={dm._id}
														value={dm._id}>
														{dm.name}
													</MenuItem>
												))}
											</Select>
										</>}
									</React.Fragment>)
								})}
							</>}

						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableHead>
				<TableRow>
					{rs[2].columns.map((column, i) => (
						<TableCell key={i}>
							{column.type === 'emptycell' && <></>}

							{column.type === 'text' && <>
								<Typography variant="div" gutterBottom>
									<Box fontWeight="fontWeightBold" m={1}>{column.value}</Box>
								</Typography>
							</>}

							{column.type === 'metric' && <><Select
								fullWidth
								labelId="metric-label"
								value={column.value}
								onChange={(event) => setData(event.target.value, 2, i)}
								input={<Input />}
								inputProps={{
									name: 'metric',
									id: 'metric',
								}}>
								<MenuItem value={""}>
									{"Please select a metric"}
								</MenuItem>
								{metrics.map(m => <MenuItem value={m.value}>{m.label}</MenuItem>)}
							</Select>
							</>}

							{column.type === 'dataModel' && <>
								<Select
									fullWidth
									value={column.value}
									onChange={(e) => setData(e.target.value, 2, i)}
									input={<Input />}>
									<MenuItem value="">{"Select a Data Model"}</MenuItem>
									{props.dataModels?.map(dm => (
										<MenuItem key={dm._id}
											value={dm._id}>
											{dm.name}
										</MenuItem>
									))}
								</Select>
							</>}

							{column.type === 'dataElement' && column.options && (<>
								<Select
									value={column.value}
									fullWidth
									onChange={(e) => setData(e.target.value, 2, i)}>
									{column.options.map((item) => (
										<MenuItem value={item.name} key={item._id}>
											{item.name}
										</MenuItem>)
									)}
								</Select>
							</>)}

							{column.type === 'complex' && <>
								{column.value.map((item, k) => {
									console.log('item ==>', item);
									return (<React.Fragment key={k + '' + i}>
										{item.type === 'emptycell' && <></>}

										{item.type === 'text' && <>
											<Typography variant="div" gutterBottom>
												<Box fontWeight="fontWeightBold" m={1}>{item.value}</Box>
											</Typography>
										</>}

										{item.type === 'dataModel' && <>
											<Select
												fullWidth
												value={item.value}
												onChange={(e) => setData(e.target.value, 2, i, k)}
												input={<Input />}>
												<MenuItem value="">{"Select a Data Model"}</MenuItem>
												{props.dataModels?.map(dm => (
													<MenuItem key={dm._id}
														value={dm._id}>
														{dm.name}
													</MenuItem>
												))}
											</Select>
										</>}

										{item.type === 'dataElement' && item.options && (<>
											<Select
												value={item.value}
												fullWidth
												onChange={(e) => setData(e.target.value, 2, i, k)}>
												{item.options.map((option) => (
													<MenuItem value={option.name} key={option._id}>
														{option.name}
													</MenuItem>)
												)}
											</Select>
										</>)}

										{column.type === 'input' && (<>
											<TextField
												id={column + i}
												name={column + i}
												onChange={(e) => setData(e.target.value, 2, i)}
												variant="outlined"
												color="secondary"
												className="form-control"
												placeholder="Enter column header"
												value={column.value}
												fullWidth
												required
											/>
										</>)}

									</React.Fragment>)
								})}
							</>}

							{column.type === 'input' && (<>
								<TextField
									id={column + i}
									name={column + i}
									onChange={(e) => setData(e.target.value, 2, i)}
									variant="outlined"
									color="secondary"
									className="form-control"
									placeholder="Enter column header"
									value={column.value}
									fullWidth
									required
								/>
							</>)}

						</TableCell>
					))}
				</TableRow>
			</TableHead>

			<TableBody>
				{rs.map((row, j) => {
					if (j === 0 || j == 1 | j === 2)
						return <></>
					return (
						<TableRow>
							{row.columns.map((column, i) => (
								<TableCell key={i}>
									{column.type === 'emptycell' && <></>}

									{column.type === 'text' && <>
										<Typography variant="div" gutterBottom>
											<Box fontWeight="fontWeightBold" m={1}>{column.value}</Box>
										</Typography>
									</>}

									{column.type === 'metric' && <><Select
										fullWidth
										labelId="metric-label"
										value={column.value}
										onChange={(event) => setData(event.target.value, j, i)}
										input={<Input />}
										inputProps={{
											name: 'metric',
											id: 'metric',
										}}>
										<MenuItem value={""}>
											{"Please select a metric"}
										</MenuItem>
										{metrics.map(m => <MenuItem value={m.value}>{m.label}</MenuItem>)}
									</Select>
									</>}

									{column.type === 'dataModel' && <>
										<Select
											fullWidth
											value={column.value}
											onChange={(e) => setData(e.target.value, j, i)}
											input={<Input />}>
											<MenuItem value="">{"Select a Data Model"}</MenuItem>
											{props.dataModels?.map(dm => (
												<MenuItem key={dm._id}
													value={dm._id}>
													{dm.name}
												</MenuItem>
											))}
										</Select>
									</>}

									{column.type === 'dataElement' && column.options && (<>
										<Select
											value={column.value}
											fullWidth
											onChange={(e) => setData(e.target.value, j, i)}>
											{column.options.map((item) => (
												<MenuItem value={item.name} key={item._id}>
													{item.name}
												</MenuItem>)
											)}
										</Select>
									</>)}

									{column.type === 'complex' && <>
										{column.value.map((item, k) => {
											console.log('item ==>', item);
											return (<React.Fragment key={k + '' + i + '' + j}>
												{item.type === 'emptycell' && <></>}

												{item.type === 'text' && <>
													<Typography variant="div" gutterBottom>
														<Box fontWeight="fontWeightBold" m={1}>{item.value}</Box>
													</Typography>
												</>}

												{item.type === 'dataModel' && <>
													<Select
														fullWidth
														value={item.value}
														onChange={(e) => setData(e.target.value, j, i, k)}
														input={<Input />}>
														<MenuItem value="">{"Select a Data Model"}</MenuItem>
														{props.dataModels?.map(dm => (
															<MenuItem key={dm._id}
																value={dm._id}>
																{dm.name}
															</MenuItem>
														))}
													</Select>
												</>}

												{item.type === 'dataElement' && item.options && (<>
													<Select
														value={item.value}
														fullWidth
														onChange={(e) => setData(e.target.value, j, i, k)}>
														{item.options.map((option) => (
															<MenuItem value={option.name} key={option._id}>
																{option.name}
															</MenuItem>)
														)}
													</Select>
												</>)}

												{column.type === 'input' && (<>
													<TextField
														id={column + i}
														name={column + i}
														onChange={(e) => setData(e.target.value, j, i)}
														variant="outlined"
														color="secondary"
														className="form-control"
														placeholder="Enter column header"
														value={column.value}
														fullWidth
														required
													/>
												</>)}

											</React.Fragment>)
										})}
									</>}

									{column.type === 'input' && (<>
										<TextField
											id={column + i}
											name={column + i}
											onChange={(e) => setData(e.target.value, j, i)}
											variant="outlined"
											color="secondary"
											className="form-control"
											placeholder="Enter column header"
											value={column.value}
											fullWidth
											required
										/>
									</>)}

									{column.type === 'value' && <>
										<Select
											fullWidth
											value={column.value}
											onChange={(e) => setData(e.target.value, j, i)}
											input={<Input />}>
											<MenuItem value="">{"Select a value"}</MenuItem>
											{column.options?.map(v => (
												<MenuItem key={v} value={v}>{v}</MenuItem>
											))}
										</Select>
									</>}

								</TableCell>
							))}
						</TableRow>
					)
				})}
			</TableBody>
			{/* <TableHead>
				<TableRow>
					{columns.map((item, i) => (
						<TableCell key={i}>
							<Select
								fullWidth
								value={headers[i]}
								onChange={(event) => setHeader(event.target.value, i)}
								input={<Input />}>
								<MenuItem value="">{"Select a Data Element"}</MenuItem>
								<MenuItem value="value1">{"Value One"}</MenuItem>
								<MenuItem value="value2">{"Value Two"}</MenuItem>
								<MenuItem value="value3">{"Value Three"}</MenuItem>
							</Select>
						</TableCell>
					))}
				</TableRow>
			</TableHead> */}
		</Table>
	</div>)
}

export default withRouter(Redux(ReportConfigurator))