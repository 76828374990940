import React, { useState, useEffect } from 'react'
import {
	Stepper, Step, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	StepLabel, Paper, Grid, List, ListItem, ListItemText, Link, Typography,
	TextField, FormControl, InputLabel, Checkbox, FormControlLabel, FormLabel, Button,
	Select, Dialog, MuiDialogTitle, MuiDialogContent, MuiDialogActions, IconButton, CloseIcon
} from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles';
// import 'styles/styles.scss'
import Loading from 'components/Loading'
import rightarrow from 'images/rightarrow.svg';
import Row from './Row'

export default function View() {
	return (
		<Box className="abcCompany-page">
			<Box className="pxy20">
				<Grid container>
					<Box className="processtab-sec">
						<div className="backbtn">
							<div className="backbtn">
								<img src={rightarrow} alt="backicon" className="backicon" />
								<Box component="h3" className="companyheadingtext">
									<Typography variant="h4">ABC Company User Interface</Typography>
								</Box>
							</div>
						</div>
						<Grid item xs={12} md={12} className="abcuserinterface-sec">
							<Row />
						</Grid>
					</Box>
				</Grid>
			</Box>
		</Box>
	)
}