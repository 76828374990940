import React, { Component } from 'react';
import {
	Button, Dialog, AppBar, Tab, Tabs, DialogTitle, TextField, FormLabel, DialogActions, DialogContent, FormControl
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import 'styles/page-designer.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as Actions from 'actions';
import _ from 'modules/lodash';
import BasicInfo from "./BasicInfo";
import Data from "./Data";
import Links from "./Links";
import UI from "./UI";
import Conditions from "./Conditions";
import ConditionsTable from "./ConditionsTable";

import {
	generateDataElements,
	generateCardBody,
} from 'packages/page/page.module';

import {
	checkForNullOrUndefined,
	updatedChartData,
	getUpdatedBarChartData,
	getUpdatedPieChartData,
	getUpdatedDoughnutChartData,
	getUpdatedPolarChartData,
	getUpdatedListChartData,
	getUpdatedMixedChartData,
	getUpdatedCardData,
	getUpdatedHTMLData,
	getUpdatedRadarChartData,
	getUpdatedSimpleListData,
	getUpdatedLineChartData,
	addCondAndLink,
	editCondAndLink,
	deleteCondAndLink,
	getUpdatedLinksData
} from 'packages/page/component.module';

import {
} from 'services/page.service';

const styles = theme => ({
	arrowIcon: {
		height: 20,
		width: 14
	},
	arrowColor: {
		color: '#ffd700'
	},
	tab: {
		"width": "20%"
	},
	dialogPaper: {
		minHeight: '80vh',
		maxHeight: '80vh',
	},
	formControl: {
		minWidth: 120,
		padding: '10px',
		margin: '8px 0px',
	},
	formControl1: {
		minWidth: 200,
		padding: '10px',
		margin: '8px 0px',
	},
});

const defaultEventState = {
	name: '',
	description: '',
	height: '0',
	width: '0',
	labels: null,
	label: null,
	selectedDataModel: '',
	selectedDataElement: null,
	scoreDataElement: null,
	businessRules: '',
	data: '',
	dataOrHTML: '',
	query: '',
	json: {},
	dataUrl: '',
	linkURL: '',
	backgroundColor: null,
	borderColor: null,
	borderWidth: null,
	hoverBackgroundColor: null,
	hoverBorderColor: null,
	dataHandler: 'data',
	title: '',
	formula: '',
	metric: '',
	stacked: false,
	tabValue: 0,
	condition: {},
	conditions: [],
	links: [],
	link: {},
	backgrounds: [],
	background: {},
	categories: [],
	category: {},
	linkPie: {},
	cardLinking: "direct_link",
	linkCard: {},
	orientation: "column",
	cardTemplateUI: "",
	mixedPieMetric: "total",
	mixedLineMetric: "average",
	includeMixedBar: true,
	includeMixedPie: true,
	includeMixedLine: true,
	isCardCategories: false,
	barXAxis: null,
	barYAxis: null,
	isGrouping: false,
	selectedTypeKey: null,
	enableLinking: false,
	selectedLinkValue: null,
	selectedTitleColumn: null,
	openLinkType: null,
	staticLinks: [],
	staticLink: {},
	lookUpConfig: {
		"mainDataModel": {
			id: null,
			selectedDataElements: [],
		},
		"lookupFromDataModels": [
			{
				id: null,
				selectedDataElements: [],
			}
		],
		"mappings": [
		]
	},
};

class ComponentDescriptionPane extends Component {
	state = {
		...defaultEventState,
		dataModels: [],
		open: false,
		appId: this.props.match.params.dappId
	};


	componentDidMount() {
		console.log("this.props.match???", this.props.match);
		this.props.getDataModels(this.props.match.params.dappId);
		// this.props.closeDataElementsDialog();
		this.props.getPages(this.props.match.params.dappId);
		this.props.getEntryForms(this.props.match.params.dappId);
		this.props.getBusinessRules(this.props.match.params.dappId);
	}

	componentDidUpdate(prevProps) {

		if (
			this.props.currentChartDataToEdit &&
			this.props.currentChartDataToEdit !==
			prevProps.currentChartDataToEdit
		) {

			let chartData =
				this.props.currentChartDataToEdit &&
				this.props.currentChartDataToEdit.chartData;
			let dataset =
				chartData &&
				chartData.data &&
				chartData.data.datasets &&
				chartData.data.datasets.length > 0 &&
				chartData.data.datasets[0];

			let dEle = generateDataElements(chartData, this.props.dataModels, this.props.chartType);
			console.log("dEle...===", dEle);
			if (dEle)
				this.setState({ dataElements: dEle });

			this.setState({
				pages: this.props.pages.map(item => ({
					value: item._id,
					label: item.name,
					url: item.url
				}))
			})

			this.setState({
				name: chartData && chartData.name,
				description: chartData && chartData.description,
				dataHandler: chartData && chartData.dataHandler,
				height: chartData.height && parseInt(chartData.height),
				width: chartData.width && parseInt(chartData.width),
				labels: chartData && chartData.data && chartData.data.labels && JSON.stringify(chartData.data.labels),
				selectedDataModel: chartData.selectedDataModel,
				selectedDataElement: chartData.selectedDataElement,
				scoreDataElement: chartData.scoreDataElement,
				selectedDataLabels: chartData.selectedDataLabels,
				dataUrl: chartData.dataUrl,
				linkURL: chartData.linkURL,
				pageLinking: chartData.pageLinking,
				associatedFormName: chartData.associatedFormName,
				datelabeltype: chartData.datelabeltype,
				labelformat: chartData.labelformat,
				conditions: chartData.conditions ? chartData.conditions : [],
				links: chartData.links ? chartData.links : [],
				linkPie: chartData.linkPie ? chartData.linkPie : {},
				linkCard: chartData.linkCard ? chartData.linkCard : {},
				cardLinking: chartData.cardLinking,
				backgrounds: chartData.backgrounds ? chartData.backgrounds : [],
				categories: chartData.categories ? chartData.categories : [],
				className: chartData.className,
				style: chartData.style,
				orientation: chartData.orientation,
				dataOrHTML: chartData.dataOrHTML,
				query: chartData.query,
				json: chartData.json,
				backgroundColor: dataset && dataset.backgroundColor,
				borderColor: dataset && dataset.borderColor,
				borderWidth: dataset && dataset.borderWidth,
				hoverBackgroundColor: dataset && dataset.hoverBackgroundColor,
				hoverBorderColor: dataset && dataset.hoverBorderColor,
				label: dataset && dataset.label,
				columns: null,
				html: chartData.html,
				cardTemplateUI: chartData.cardTemplateUI,
				barXAxis: chartData.barXAxis,
				barYAxis: chartData.barYAxis,
				isCardCategories: chartData.isCardCategories,
				loadChildData: chartData.loadChildData,
				parentDataElement: chartData.parentDataElement,
				metric: chartData.metric ? chartData.metric : "total",
				groupByXAxis: chartData.groupByXAxis,
				stacked: chartData.data && chartData.data.stacked ? chartData.data.stacked : false,
				isGrouping: chartData.isGrouping || false,
				enableLinking: chartData.enableLinking || false,
				selectedTypeKey: chartData.selectedTypeKey || "",
				selectedLinkValue: chartData.selectedLinkValue || "",
				selectedTitleColumn: chartData.selectedTitleColumn || "",
				openLinkType: chartData.openLinkType,
				lookUpConfig: {
					mainDataModel : chartData.mainDataModel || {
						id: null,
						selectedDataElements: [],
					},
					lookupFromDataModels: chartData.lookupFromDataModels || [
						{
							id: null,
							selectedDataElements: [],
						}
					],
					mappings: chartData.mappings || [],
				}
			});
			if (this.props.chartType === Actions.barChart
				|| this.props.chartType === Actions.pieChart
				|| this.props.chartType === Actions.doughnutChart) {
				this.setState({
					data: dataset && JSON.stringify(dataset.data),
				});
			} else if (this.props.chartType === Actions.stackedBarChart) {
				this.setState({
					data: dataset && JSON.stringify(dataset.data),
				});
			} else if (this.props.chartType === Actions.listChart
				|| this.props.chartType === Actions.simpleTable
				|| this.props.chartType === Actions.summaryTable) {
				this.setState({
					data: chartData && chartData.data && chartData.data.datasets && JSON.stringify(chartData.data.datasets),
					columns: chartData && chartData.data && chartData.data.columns && JSON.stringify(chartData.data.columns),
					selectedDataPages: chartData.selectedDataPages,
					selectedDataColumns: chartData.selectedDataColumns,
					rowsPerPage: chartData.rowsPerPage,
					extendedTablePage: chartData.extendedTablePage
				});
			} else if (this.props.chartType === Actions.simpleTable) {
			} else if (this.props.chartType === Actions.card) {
				let body;
				if (chartData & chartData.data) {
					body = generateCardBody(chartData.metric, chartData.data);
				}
				this.setState({
					title: chartData.title,
					selectedGroupingDataElement: chartData.selectedGroupingDataElement,
					useGrouping: chartData.useGrouping,
					metric: chartData.metric ? chartData.metric : "total",
					data: chartData && chartData.data && JSON.stringify(chartData.data),
					body: body,
				});
			} else if (this.props.chartType === Actions.simpleList) {
				this.setState({
					data: chartData && chartData.data && JSON.stringify(chartData.data),
					title: chartData.title,
				});
			} else if (this.props.chartType === Actions.lineChart) {
				this.setState({
					data: chartData && chartData.data && chartData.data.datasets && JSON.stringify(chartData.data.datasets),
				});
			}
		}
	}

	canBeUpdated = () => {
		if (this.state.dataHandler === "data_models") {
			if (this.props.chartType === Actions.listChart
				|| this.props.chartType === Actions.simpleTable
				|| this.props.chartType === Actions.summaryTable) {
				return this.state.selectedDataModel
					&& this.state.selectedDataElement
					&& (this.state.selectedDataElement.length > 0
						|| (this.state.loadChildData && this.state.loadChildData))
			} else if (this.props.chartType === Actions.card) {
				if (this.state.isCardCategories) {
					return this.state.categories && this.state.categories.length > 0
				} else {
					return this.state.selectedDataModel && this.state.selectedDataElement
				}
			} else if (this.props.chartType === Actions.barChart) {
				return this.state.barXAxis && this.state.barYAxis
			} else if (this.props.chartType === Actions.links) {
				return this.state.selectedDataModel && this.state.openLinkType;
			} else {
				return this.state.selectedDataModel
					&& this.state.selectedDataElement && this.state.selectedDataElement.length > 0
					&& this.state.selectedDataLabels && this.state.selectedDataLabels.length > 0;
			}
		} else if (this.state.dataHandler === "data_business_rules") {
			return this.state.businessRules;
		} else if (this.state.dataHandler === "data_html") {
			return this.state.dataOrHTML;
		} else if (this.state.dataHandler === "query") {
			return this.state.query;
		} else if (this.state.dataHandler === "json") {
			return this.state.json;
		} else if (this.state.dataHandler === "lookup") {
			return this.state.lookUpConfig;
		}
		return true;
	}

	onUpdateChartData = () => {
		let updatedData;
		let { conditions } = this.props.page;
		console.log('this.props.chartType', this.props.chartType)
		switch (this.props.chartType) {
			case Actions.stackedBarChart: {
				updatedData = this.props.saveChartData({
					chartType: Actions.stackedBarChart,
					chartData: getUpdatedBarChartData(this.props.currentChartDataToEdit, this.state),
					showDataToEditComponent: true,
					conditions: conditions
				}, this.state.appId);
				break;
			}
			case Actions.barChart: {
				updatedData = this.props.saveChartData({
					chartType: Actions.barChart,
					chartData: getUpdatedBarChartData(this.props.currentChartDataToEdit, this.state),
					showDataToEditComponent: true,
					conditions: conditions
				}, this.state.appId);
				break;
			}
			case Actions.pieChart: {
				updatedData = this.props.saveChartData({
					chartType: Actions.pieChart,
					chartData: getUpdatedPieChartData(this.props.currentChartDataToEdit, this.state),
					showDataToEditComponent: true,
					conditions: conditions
				}, this.state.appId);
				break;
			}
			case Actions.doughnutChart: {
				updatedData = this.props.saveChartData({
					chartType: Actions.doughnutChart,
					chartData: getUpdatedDoughnutChartData(this.props.currentChartDataToEdit, this.state),
					showDataToEditComponent: true,
					conditions: conditions
				}, this.state.appId);
				break;
			}
			case Actions.listChart: {
				updatedData = this.props.saveChartData({
					chartType: Actions.listChart,
					chartData: getUpdatedListChartData(this.props.currentChartDataToEdit, this.state),
					showDataToEditComponent: true,
					conditions: conditions
				}, this.state.appId);
				break;
			}
			case Actions.mixedChart: {
				updatedData = this.props.saveChartData({
					chartType: Actions.mixedChart,
					chartData: getUpdatedMixedChartData(this.props.currentChartDataToEdit, this.state),
					showDataToEditComponent: true,
					conditions: conditions
				}, this.state.appId);
				break;
			}
			case Actions.simpleTable: {
				updatedData = this.props.saveChartData({
					chartType: Actions.simpleTable,
					chartData: getUpdatedListChartData(this.props.currentChartDataToEdit, this.state),
					showDataToEditComponent: true,
					conditions: conditions
				}, this.state.appId);
				break;
			}
			case Actions.summaryTable: {
				updatedData = this.props.saveChartData({
					chartType: Actions.summaryTable,
					chartData: getUpdatedListChartData(this.props.currentChartDataToEdit, this.state),
					showDataToEditComponent: true,
					conditions: conditions
				}, this.state.appId);
				break;
			}
			case Actions.card: {
				updatedData = this.props.saveChartData({
					chartType: Actions.card,
					chartData: getUpdatedCardData(this.props.currentChartDataToEdit, this.state),
					showDataToEditComponent: true,
					conditions: conditions
				}, this.state.appId);
				break;
			}
			case Actions.html: {
				updatedData = this.props.saveChartData({
					chartType: Actions.html,
					chartData: getUpdatedHTMLData(this.props.currentChartDataToEdit, this.state),
					showDataToEditComponent: true,
					conditions: conditions
				}, this.state.appId);
				break;
			}
			case Actions.simpleList: {
				updatedData = this.props.saveChartData({
					chartType: Actions.simpleList,
					chartData: getUpdatedSimpleListData(this.props.currentChartDataToEdit, this.state),
					showDataToEditComponent: true,
					conditions: conditions
				}, this.state.appId);
				break;
			}
			case Actions.lineChart: {
				updatedData = this.props.saveChartData({
					chartType: Actions.lineChart,
					chartData: getUpdatedLineChartData(this.props.currentChartDataToEdit, this.state),
					showDataToEditComponent: true,
					conditions: conditions
				}, this.state.appId);
				break;
			}
			case Actions.radarChart: {
				updatedData = this.props.saveChartData({
					chartType: Actions.radarChart,
					chartData: getUpdatedRadarChartData(this.props.currentChartDataToEdit, this.state),
					showDataToEditComponent: true,
					conditions: conditions
				}, this.state.appId);
				break;
			}
			case Actions.polarChart: {
				updatedData = this.props.saveChartData({
					chartType: Actions.polarChart,
					chartData: getUpdatedPolarChartData(this.props.currentChartDataToEdit, this.state),
					showDataToEditComponent: true,
					conditions: conditions
				}, this.state.appId);
				break;
			}
			case Actions.links: {
				updatedData = this.props.saveChartData({
					chartType: Actions.links,
					chartData: getUpdatedLinksData(this.props.currentChartDataToEdit, this.state),
					showDataToEditComponent: true,
					conditions: conditions
				}, this.state.appId);
				break;
			}
		}
		this.setState({ condition: {}, link: {}, background: {}, staticLink: {} });
		if (updatedData && updatedData.then)
			return updatedData.then((res) => {
				this.handleClose();
			});
		else {
			this.handleClose();
			return updatedData;
		}
	};

	getUpdatedChartData = (updatedData) => {
		return updatedChartData(updatedData, this.state);
	}

	onChangeHandler = name => event => {
		this.setState({
			[event.target.name]: event.target.value,
			...(event.target.name === 'stacked' && { stacked: event.target.checked }),
			...(event.target.name === 'isGrouping' && { isGrouping: event.target.checked }),
			...(event.target.name === 'enableLinking' && { enableLinking: event.target.checked }),
		});
		if ("selectedDataModel" === event.target.name) {
			this.props.dataModels.map((dataModel) => {
				if (dataModel._id === event.target.value) {
					this.setState({
						dataElements: dataModel.dataElements.map(item => ({
							_id: item._id,
							value: item.name,
							label: item.description,
							type: item.dataElementType,
						}))
					})
				}
			});
		}
	};

	onChangeLookUpField = (type, value) => {
		console.log('value', value)
		let data = this.state.lookUpConfig;
		if (type === 'mainDataModel') {
			data['mainDataModel']['id'] = value
			data['mappings'] = []
			data['mainDataModel']['selectedDataElements'] = []
		} else if (type === 'lookupDataModel') {
			data['lookupFromDataModels'][0]['id'] = value
			data['mappings'] = []
			data['lookupFromDataModels'][0]['selectedDataElements'] = []
		} else if (type === 'mainSelectedDataElement') {
			data['mainDataModel']['selectedDataElements'] = value.map(i=> ({_id: i.value, name: i.label}));
		} else if (type === 'lookupSelectedDataElement') {
			data['lookupFromDataModels'][0]['selectedDataElements'] = value.map(i=> ({_id: i.value, name: i.label}));
		} else if (type === 'addMapping') {
			data['mappings'].push(value);
		} else if (type === 'removeMapping') {
			data['mappings'].splice(value, 1) // value --> index
		}
		this.setState({
			lookUpConfig: { ...data }
		})
	}

	toggleComponentListComponent = event => {
		this.props.toggleChartDescriptionComponent(
			!this.props.showDataToEditComponent
		);
	};

	handleClickOpen = () => {
		this.props.openDataToEditComponent();
		// this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({
			...defaultEventState,
			dataModels: [],
			open: false,
			appId: this.props.match.params.dappId
		})
		this.props.closeChartToEdit();
	};

	openDataElementsDialog = () => {
		this.setState({ open: true });
	}

	handleChipChange = (value) => {
		this.setState({ selectedDataElement: value });
	}

	handleLinksFieldChange = (key, value) => {
		this.setState({ [key]: value });
	}

	handleChipDataChange = (v, k) => {
		this.setState({ [k]: v });
	}

	handleGroupingChange = (value) => {
		this.setState({ selectedGroupingDataElement: value });
	}

	handleScoreElementChange = (value) => {
		this.setState({ scoreDataElement: value });
	}

	handleLabelsChipChange = (value) => {
		this.setState({ selectedDataLabels: value });
	}

	handleDataColumnsChange = (value) => {
		this.setState({ selectedDataColumns: value });
	}

	handleDataPagesChange = (value) => {
		this.setState({ selectedDataPages: value });
	}

	handleExtendedTablePageChange = (value) => {
		this.setState({ extendedTablePage: value });
	}

	handleCheckboxChange = name => event => {
		this.setState({ [name]: event.target.checked });
	};

	handleChangeTab = (event, tabValue) => {
		this.setState({ tabValue });
	};

	onConditionChangeHandler = event => {
		let condition = this.state.condition;
		condition[event.target.name] = event.target.value
		this.setState({ condition });
	}

	onStaticLinkChangeHandler = event => {
		let staticLink = this.state.staticLink;
		staticLink[event.target.name] = event.target.value;
		this.setState({ staticLink });
	}

	onCategoryChangeHandler = event => {
		let category = this.state.category;
		category[event.target.name] = event.target.value
		this.setState({ category });
	}

	onLinkChangeHandler = event => {
		let link = this.state.link;
		if (Array.isArray(event)) {
			link["additionalFields"] = event
		} else {
			if ("selectedLinkDataModel" === event.target.name) {
				this.props.dataModels !== null && this.props.dataModels.map(dataModel => {
					if (dataModel._id === event.target.value) {
						link.dataElements = dataModel.dataElements.map(item => ({
							_id: item._id,
							value: item.name,
							label: item.description
						}))
					}
				})
			}
			link[event.target.name] = event.target.value
		}
		this.setState({ link });
	}

	onPieLinkChangeHandler = event => {
		let linkPie = this.state.linkPie;
		linkPie[event.target.name] = event.target.value
		this.setState({ linkPie });
	}

	onCardLinkChangeHandler = event => {
		let linkCard = this.state.linkCard;
		linkCard[event.target.name] = event.target.value
		this.setState({ linkCard });
	}

	onBackgroundChangeHandler = event => {
		let background = this.state.background;
		background[event.target.name] = event.target.value
		this.setState({ background });
	}

	addCondition = (condition) => {
		let conditions = addCondAndLink(condition, this.state.conditions);
		this.setState({ conditions, condition: { value: "" } });
	}

	addStaticLink = (sLink) => {
		let staticLinks = addCondAndLink(sLink, this.state.staticLinks);
		this.setState({ staticLinks, staticLink: { title: "" } });
	}

	addCategory = (category) => {
		let categories = addCondAndLink(category, this.state.categories);
		this.setState({ categories, category: { value: "" } });
	}

	addLink = (link) => {
		let links = addCondAndLink(link, this.state.links);
		this.setState({ links, link: { value: "" } });
	}

	addBackground = (background) => {
		let backgrounds = addCondAndLink(background, this.state.backgrounds);
		this.setState({ backgrounds, background: { value: "" } });
	}

	deleteCondition = (condition, index) => {
		let conditions = deleteCondAndLink(condition, index, this.state.conditions);
		this.setState({ conditions });
	}

	deleteCategory = (category, index) => {
		let categories = deleteCondAndLink(category, index, this.state.categories);
		this.setState({ categories });
	}

	deleteStaticLink = (sLink, index) => {
		let staticLinks = deleteCondAndLink(sLink, index, this.state.staticLinks);
		this.setState({ staticLinks });
	}

	deleteLink = (link, index) => {
		let links = deleteCondAndLink(link, index, this.state.links);
		this.setState({ links });
	}

	deleteBackground = (background, index) => {
		let backgrounds = deleteCondAndLink(background, index, this.state.backgrounds);
		this.setState({ backgrounds });
	}

	editCondition = (condition, index) => {
		let cond = editCondAndLink(condition, index, this.state.conditions);
		this.setState({ conditions: cond.ary, condition: cond.obj });
	}

	editStaticLink = (sLink, index) => {
		let cond = editCondAndLink(sLink, index, this.state.staticLinks);
		this.setState({ staticLinks: cond.ary, staticLink: cond.obj });
	}

	editCategory = (category, index) => {
		let cate = editCondAndLink(category, index, this.state.categories);
		this.setState({ categories: cate.ary, category: cate.obj });
	}

	editLink = (link, index) => {
		let l = editCondAndLink(link, index, this.state.links);
		this.setState({ links: l.ary, link: l.obj });
	}

	editBackground = (background, index) => {
		let bg = editCondAndLink(background, index, this.state.backgrounds);
		this.setState({ backgrounds: bg.ary, background: bg.obj });
	}

	render() {
		const { classes, showDataToEditComponent, dataModels, entryForms } = this.props;
		const { tabValue, open } = this.state;

		return (
			<Dialog
				open={showDataToEditComponent}
				onClose={this.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				classes={{ paper: classes.dialogPaper }}
				fullWidth={true}
				maxWidth={'md'}
			>
				<DialogTitle id="alert-dialog-title" position="static">
					Properties
				</DialogTitle>
				<AppBar position="static"
					className="tabPropertiesPanel">
					<Tabs
						aria-label="wrapped label tabs example"
						value={tabValue}
						onChange={this.handleChangeTab}
					>
						<Tab className="h-64 normal-case" label="Basic Info" />
						<Tab className="h-64 normal-case" label="Data" />
						<Tab className="h-64 normal-case" label="Logic" />
						<Tab className="h-64 normal-case" label="Links" />
						<Tab className="h-64 normal-case" label="UI" />
						<Tab className="h-64 normal-case" label="Security & Access" />
					</Tabs>
				</AppBar>
				<DialogContent>
					<div
						id="componentDescriptionPane" >
						<div className="p-16 sm:p-24 max-w-2xl" >
							<form className="form" noValidate autoComplete="off" >
								{tabValue === 0 &&
									(
										<BasicInfo
											chartType={this.props.chartType}
											values={this.state}
											onChangeHandler={this.onChangeHandler}
											handleCheckboxChange={this.handleCheckboxChange}
											classes={classes}
										/>
									)}
								{tabValue === 1 &&
									(<>
										{this.props.chartType !== Actions.html
											? <Data
												allDataModels={this.props.dataModels}
												values={this.state}
												onChangeHandler={this.onChangeHandler}
												onChangeLookUpField={this.onChangeLookUpField}
												handleCheckboxChange={this.handleCheckboxChange}
												checkForNullOrUndefined={checkForNullOrUndefined}
												handleChipChange={this.handleChipChange}
												handleGroupingChange={this.handleGroupingChange}
												handleChange={this.onChangeHandler}
												handleLabelsChipChange={this.handleLabelsChipChange}
												onConditionChangeHandler={this.onConditionChangeHandler}
												addCondition={this.addCondition}
												deleteCondition={this.deleteCondition}
												editCondition={this.editCondition}
												onCategoryChangeHandler={this.onCategoryChangeHandler}
												addCategory={this.addCategory}
												deleteCategory={this.deleteCategory}
												editCategory={this.editCategory}
												classes={classes}
												handleChipDataChange={this.handleChipDataChange}
												handleLinksFieldChange={this.handleLinksFieldChange}
												onStaticLinkChangeHandler={this.onStaticLinkChangeHandler}
												addStaticLink={this.addStaticLink}
												deleteStaticLink={this.deleteStaticLink}
												editStaticLink={this.editStaticLink}
											/>
											: <TextField
												className={classes.formControl + " mt-8 mb-16"}
												id="html"
												label="HTML"
												type="text"
												name="html"
												value={this.state.html}
												onChange={this.onChangeHandler()}
												multiline
												rows={5}
												variant="outlined"
												fullWidth
											/>
										}
									</>)
								}
								{tabValue === 2 &&
									(<>
										{this.props.chartType !== Actions.html
											? <>{!checkForNullOrUndefined(dataModels)
												&& this.state.selectedDataModel
												&& this.state.dataHandler === "data_models" && (<>
													<Conditions
														values={this.state}
														classes={classes}
														onConditionChangeHandler={this.onConditionChangeHandler}
														addCondition={this.addCondition}
													/>
													<FormLabel component="legend">
														Conditions Table
													</FormLabel>
													<ConditionsTable
														conditions={this.state.conditions}
														editCondition={this.editCondition}
														deleteCondition={this.deleteCondition}
													/>
												</>)}</>
											: <></>
										}
									</>)
								}

								{tabValue === 3 &&
									(
										<Links
											values={this.state}
											onChangeHandler={this.onChangeHandler}
											handleCheckboxChange={this.handleCheckboxChange}
											handleDataColumnsChange={this.handleDataColumnsChange}
											handleDataPagesChange={this.handleDataPagesChange}
											handleExtendedTablePageChange={this.handleExtendedTablePageChange}
											checkForNullOrUndefined={checkForNullOrUndefined}
											handleChipChange={this.handleChipChange}
											handleChange={this.onChangeHandler}
											onLinkChangeHandler={this.onLinkChangeHandler}
											onPieLinkChangeHandler={this.onPieLinkChangeHandler}
											onCardLinkChangeHandler={this.onCardLinkChangeHandler}
											addLink={this.addLink}
											deleteLink={this.deleteLink}
											editLink={this.editLink}
											classes={classes}
										/>
									)}
								{tabValue === 4 &&
									(
										<UI
											values={this.state}
											onChangeHandler={this.onChangeHandler}
											handleCheckboxChange={this.handleCheckboxChange}
											handleDataColumnsChange={this.handleDataColumnsChange}
											handleDataPagesChange={this.handleDataPagesChange}
											handleExtendedTablePageChange={this.handleExtendedTablePageChange}
											checkForNullOrUndefined={checkForNullOrUndefined}
											handleChipChange={this.handleChipChange}
											handleChange={this.onChangeHandler}
											onBackgroundChangeHandler={this.onBackgroundChangeHandler}
											addBackground={this.addBackground}
											deleteBackground={this.deleteBackground}
											editBackground={this.editBackground}
											classes={classes}
										/>
									)}
								{tabValue === 5 &&
									(
										<>
										</>
									)}
							</form>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button variant="contained"
						color="primary"
						onClick={this.onUpdateChartData.bind(this)}
						disabled={!this.canBeUpdated()}>
						Update
					</Button>
					<Button variant="contained"
						style={{ marginLeft: "20px" }}
						color="primary"
						onClick={this.handleClose}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			saveChartData: Actions.saveChartData,
			// toggleChartDescriptionComponent: Actions.toggleChartDescriptionComponent,
			getDataModels: Actions.getDataModels,
			// openDataElementsDialog: Actions.openDataElementsDialog,
			// closeDataElementsDialog: Actions.closeDataElementsDialog,
			// selectDataElements: Actions.selectDataElements,
			// openDataElementsDialog: Actions.openDataElementsDialog,
			getDataModels: Actions.getDataModels,
			getPages: Actions.getPages,
			getEntryForms: Actions.getEntryForms,
			// openDataToEditComponent: Actions.openDataToEditComponent,
			closeChartToEdit: Actions.closeChartToEdit,
			getBusinessRules: Actions.getBusinessRules,
		},
		dispatch
	);
}

function mapStateToProps({ pages, dapp, dataModels, entryForms, businessRules }) {
	console.log("pages.page###", pages.page);
	return {
		currentChartDataToEdit: pages.page.currentChartDataToEdit,
		chartType: pages.page.chartType,
		showDataToEditComponent: pages.page.showDataToEditComponent,
		dataModels: dataModels.data,
		dataElementsDialog: pages.page.dataElementsDialog,
		appId: dapp.appId,
		pages: pages.data,
		// entryForms: entryForms.data,
		// businessRules: businessRules.data,
		page: pages.page,
	};
}

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(
			mapStateToProps,
			mapDispatchToProps
		)(ComponentDescriptionPane)
	)
);
