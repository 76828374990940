import {
  FormControlLabel,
  Grid,
  NativeSelect,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';

const Process = props => {
  const { selectedstep } = props;
  
  const [taskOwnerValue, setTaskOwnerValue] = useState('role');
  const [field, setField] = useState('');
  const handleChange = event => {
    setTaskOwnerValue(event.target.value);
  };
  return (
    <>
      {props.value === props.index && (
        <Grid container className="formPmodel">
          <Grid container>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Task</label>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="task"
                label=""
                name="task"
                margin="dense"
                variant="filled"
                value={selectedstep.task}
                onChange={e => props.onChangeValue(e.target.name, e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Task Owner</label>
            </Grid>
            <Grid item xs={8}>
              <RadioGroup
                name="taskowner"
                value={selectedstep.taskowner}
                onChange={e => props.onChangeValue(e.target.name, e.target.value)}
                row
              >
                <FormControlLabel value="role" control={<Radio />} label="Role" />
                <FormControlLabel value="persongroup" control={<Radio />} label="Person/Group" />
                <FormControlLabel value="formfield" control={<Radio />} label="Form Field" />
              </RadioGroup>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}></Grid>
              <Grid xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                {taskOwnerValue === 'role' && (
                  <>
                    <Grid xs={2}>
                      <label>Pick a role:</label>
                    </Grid>
                    <Grid xs={6}>
                      <NativeSelect
                        fullWidth
                        defaultValue="0"
                        // value={selectedstep.bussinessrule}
                        // onChange={e => this.onChangeValue('bussinessrule', e.target.value)}
                      >
                        <option value="0" disabled>
                          Select a Role
                        </option>

                        <option value="casemanager">Case Manager</option>
                        <option value="analyst">Analyst</option>
                        <option value="caseprocessor">Case Processor</option>
                        <option value="auditor">Auditor</option>
                      </NativeSelect>
                    </Grid>
                  </>
                )}
                {taskOwnerValue === 'persongroup' && (
                  <>
                    <Grid xs={2}>
                      <label>Pick a role:</label>
                    </Grid>
                    <Grid xs={6}>
                      <NativeSelect
                        fullWidth
                        defaultValue="0"
                        // value={selectedstep.bussinessrule}
                        // onChange={e => this.onChangeValue('bussinessrule', e.target.value)}
                      >
                        <option value="0" disabled>
                          Select a Role
                        </option>

                        <option value="john">John Smith</option>
                        <option value="johnny">Johnny Patt</option>
                        <option value="chris">Chris Jr</option>
                        <option value="dwyane">Dwyane Johnson</option>
                      </NativeSelect>
                    </Grid>
                  </>
                )}

                {taskOwnerValue === 'formfield' && (
                  <>
                    <Grid xs={2}>
                      <label>Pick a role:</label>
                    </Grid>
                    <Grid xs={6}>
                      <NativeSelect
                        fullWidth
                        defaultValue="0"
                        value={field}
                        onChange={e => setField(e.target.value)}
                      >
                        <option value="" disabled>
                          Select a Field
                        </option>

                        <option value="reviewer">Reviewer</option>
                        <option value="approver">Approver</option>
                        <option value="verifier">Verifier</option>
                      </NativeSelect>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            {field === 'reviewer' && (
              <>
                <Grid container style={{ marginTop: 10, marginBottom: 10 }}>
                  <Grid item xs={12}>
                    <label>Task Status to display when task is assigned to user:</label>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      id="propertyId"
                      label=""
                      margin="dense"
                      variant="filled"
                      // value={this.state.id}
                      value="Pending Review"
                      // onChange={e => this.onChangeValue('propertyId', e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <label>{`Action Label ( Action to be taken by task owner to processing):`}</label>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      id="propertyId"
                      label=""
                      margin="dense"
                      variant="filled"
                      // value={this.state.id}
                      value="Send to Approver"
                      // onChange={e => this.onChangeValue('propertyId', e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Process;
