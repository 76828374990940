import React, { Component } from 'react';
import {
    TextField, FormControlLabel, Checkbox, MenuItem,
    Select, FormControl, InputLabel, FormLabel, 
} from '@material-ui/core';

class Advanced extends Component {
    render() {
        let { classes, values, handleChange, handleCheckboxChange } = this.props;
        return (<form className="form" noValidate autoComplete="off" >
            <FormControl>
                <FormLabel variant="h6" className="label-headings">Rank *</FormLabel>
                <TextField
                    id="rank"
                    name="rank"
                    variant="outlined"
                    color="secondary"
                    className="form-control"
                    placeholder="Rank"
                    value={values.rank}
                    onChange={handleChange}
                />
            </FormControl>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={values.hide}
                        onChange={handleCheckboxChange('hide')}
                        value="hide"
                        color="primary"
                    />
                }
                label="Hide from top navigation"
            />
            <FormControl variant="outlined" className={classes.formControl} fullWidth>
            <FormLabel variant="h6" className="label-headings"
                id="demo-simple-select-outlined-label">Page Type</FormLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={values.type}
                    onChange={handleChange}
                    name="type">
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <MenuItem value="dashboard">
                        <em>Dashboard</em>
                    </MenuItem>
                    <MenuItem value="details">
                        <em>Details</em>
                    </MenuItem>
                </Select>
            </FormControl>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={values.isActive}
                        onChange={handleCheckboxChange('isActive')}
                        value="isActive"
                        color="primary"
                    />
                }
                label="Active"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={values.default}
                        onChange={handleCheckboxChange('default')}
                        value="default"
                        color="primary"
                    />
                }
                label="Default"
            />
        </form>
        )
    }
}
export default Advanced;