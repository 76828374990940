import { ActionTypes } from 'constants/index'

const initialState = {
	data: [],
	searchText: "",
	error: "",
	loading: false,
	// datasource: {},
	deleteSuccess: false
}

const datasourcesReducer = function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_DATASOURCES: {
			return {
				...state,
				loading: true,
				deleteSuccess: false
			}
		}
		case ActionTypes.GET_DATASOURCES_SUCCESS: {
			return {
				...state,
				data: action.payload,
				deleteSuccess: false
			}
		}
		case ActionTypes.GET_DATASOURCES_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error
			}
		}
		case ActionTypes.DELETE_DATASOURCE_SUCCESS: {
			return {
				...state,
				loading: false,
				data: [...state.data.filter(item => item._id !== action.payload)],
				deleteSuccess: true 
			}
		}
		case ActionTypes.DELETE_DATASOURCE_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error,
				deleteSuccess: false
			}
		}

		default: {
			return state;
		}
	}
};

export default datasourcesReducer;
