import React from 'react';
import { useDispatch } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { request } from 'modules/client';
import FormulaBuilder from './formulaBuilder';
import rightarrow from 'images/rightarrow.svg';
import FormulaBasicInfo from './formulaBasicInfo';
import Redux from '../redux';
import { TabPanel } from '../../@tabpanel/tabpanel';
import { Link, withRouter } from 'react-router-dom';
import { createStyles, makeStyles, withTheme } from '@material-ui/styles';
import { Grid, Button, Typography, Box, AppBar, Tab, Tabs, Divider } from '@material-ui/core';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const uri = API_BASE_URL + "/dataModels";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) =>
    createStyles({
        boxClass: {
            paddingRight: '10px'
        },
        root: {
            flexGrow: 1,
            backgroundColor: '#fff'
        },
        headingCon: {
            marginBlockEnd: '1em',
            marginBlockStart: '1em',
        },
    }),
);

const sidebarData = [
    {
        id: 1,
        title: "App"
    },
    {
        id: 2,
        title: "User"
    },
    {
        id: 3,
        title: "Data Models"
    },
    {
        id: 4,
        title: "Logic"
    }
]


function NewFormula(props) {
    const { match } = props,
    classes = useStyles(),
    dispatch = useDispatch(),
    [value, setValue] = React.useState(0),
    [formula, setFormula] = React.useState(""),
    [rules, setRules] = React.useState(""),
    [type, setType] = React.useState('excel'),
    [info, setInfo] = React.useState({ name: "", description: "" }),
	[error, setError] = React.useState({ name: false, formula: false }),
    [totalFields, setTotalFields] = React.useState([]),
    [fields, setFields] = React.useState([]),
    [bracesRemoved, setBracesRemoved] = React.useState(false),
    [collectionsName, setCollectionsName] = React.useState({}),
    [elementNames, setElementNames] = React.useState({});

    const replaceAll = (str, find, replace) => {
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    }

    const escapeRegExp = (str) => {
        return str + '(?![.])';
    }

    if (match.params.formulaid !== "new" && formula && totalFields && totalFields.length > 0 && elementNames && Object.keys(elementNames).length > 0 && !bracesRemoved) {
        setBracesRemoved(true);
        let formulaValue = formula;
        Object.keys(elementNames).forEach(key => {
            if (formulaValue.includes(key)) {
                formulaValue = formulaValue.replaceAll(key, elementNames[key])
            }
        })

        totalFields.forEach(field => {
            let spaceRemovedField = field.replace(/\s/g,'');
            if (formulaValue.includes(`{${field}}`)) {
                formulaValue = replaceAll(formulaValue, `{${field}}`, field);
            } else if (formulaValue.includes(`{${spaceRemovedField}}`)) {
                formulaValue = replaceAll(formulaValue, `{${spaceRemovedField}}`, spaceRemovedField);
            }
        })
        setFormula(formulaValue);
    }
    React.useEffect(() => {
        (async () => {
            request(uri, {
                method: 'GET',
                headers: { appId: match.params.dappId }
            }).then((resp) => {
                let data_to_save = [], totalFields = [], collectionsName = {}, elementNames = {};
                resp.forEach(element => {
                    const { dataElements, datasourceId, _id, type, name, description, collectionName } = element;
                    collectionsName[name] = collectionName;
                    elementNames[collectionName] = name;

                    dataElements.forEach(e => {
                        data_to_save.push({
                            ...e,
                            ...{
                                parentData: {
                                    datasourceId, _id, type, name, description, collectionName
                                },
                                id: `${e._id}-${_id}`,
                                display: `${name}.${e.name}`,
                            }
                        })
                        totalFields.push(`${name}.${e.name}`)
                    });
                });
                setTotalFields(totalFields);
                setFields([...data_to_save]);
                setCollectionsName(collectionsName);
                setElementNames(elementNames);
            }).catch((err) => {
                console.log("err in getting fields", err)
            });
        })();
    }, []);
    React.useEffect(() => {
		updateFormulaState()
	}, [props.location])

    React.useEffect(() => {
		if (props.formula.saveSuccess) {
			props.showAlert('Formula saved successfully', { id: "formula", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/formula/${props.formula.data.result.data._id}` })
		}
		if (props.formula.updateSuccess) {
			props.showAlert('Formula updated successfully', { id: "formula", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/formula/${match.params.formulaid}` })
		}
	}, [props.formula.saveSuccess, props.formula.updateSuccess])

    const updateInfoState = () => {
        let formulaData = props.formula.data && props.formula.data.result ? props.formula.data.result.data : props.formula.data;
        if (formulaData && formulaData.formulaType) {
            let { name, description, formulaType } = formulaData;
            let formulaValue = formulaData.formulaType === 'javascript' ? formulaData.formulaEval : formulaData.formulaValue;
            setInfo({name, description});
            setType(formulaType);
            setBracesRemoved(false);
            setFormula(formulaValue);
        }
    }

    const updateFormulaState = () => {
		const { dappId, formulaid } = props.match.params
		if (formulaid === 'new') {
			props.newFormula();
		}
		else {
			props.getFormula({ appId: dappId, id: formulaid })
		}
	};

	React.useEffect(() => {
		updateInfoState()
	}, [props.formula.data])

	React.useEffect(() => {
		let error = props.formulas.error;
		if (error) {
			error.name && error.formula
				&& props.showAlert("NETWORK ERROR: Please verify your internet connection", {
					id: "formula",
					variant: 'danger',
					icon: 'times-circle'
				})
			if (error.name) {
				setError({ ...error, name: error.name });
			} else if (error.formula) {
				setError({ ...error, formula: error.formula });
			}
		}
	}, [props.formula.error])

    React.useEffect(() => {
		if (typeof props.formula.error === "object") {
			if (props.formula.error.status && props.formula.error.status == 401) {
				props.history.push({ pathname: "/login" });
				props.showAlert(props.formula.error.errorMessage, { id: "formula", variant: 'error' });
			} else {
				if (props.formula.error.errorMessage) {
                    props.showAlert(props.formula.error.errorMessage, { id: "formula", variant: 'error' });
				}
			}
		}
	}, [props.formula.error])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    };

    const handleInfoChange = (key, value) => {
        if (key == 'name' && value && value.length > 0 && error.name) {
            setError({ ...error, name: false })
        }
        setInfo({
            ...info,
            ...{ [key]: value }
        })
    }

    const onTypeChange = (value) => {
        if (value === "excel") {
            setFormula("");
            setType(value);
        } else {
            let jsFormula = formula ? convertExcelToJs() : "";
            if (jsFormula != null) {
                setFormula(jsFormula);
                setType(value);
            }
        }
    }

    const onFormulaChange = (val) => {
        if (val.length > 0 && error.formula) {
            setError({ ...error, formula: false })
        }

        setFormula(val);
    }

    const handleRulesChange = (val) => {
        setRules(val);
    }

    const addFieldBraces = (jsFormula, mainFormula) => {
        if (totalFields && totalFields.length > 0) {
            totalFields.forEach(field => {
                let spaceRemovedField = field.replace(/\s/g,'');
                if (jsFormula.includes(field) && jsFormula.includes(field)) {
                    jsFormula = replaceAll(jsFormula, field, `{${field}}`);
                } else if (jsFormula.includes(spaceRemovedField)) {
                    jsFormula = replaceAll(jsFormula, spaceRemovedField, `{${spaceRemovedField}}`);
                }
                if (mainFormula.includes(field)) {
                    mainFormula = replaceAll(mainFormula, field, `{${field}}`);
                } else if (mainFormula.includes(spaceRemovedField)) {
                    mainFormula = replaceAll(mainFormula, spaceRemovedField, `{${spaceRemovedField}}`);
                }
            })

            Object.keys(collectionsName).forEach(key => {
                if (jsFormula.includes(key)) {
                    jsFormula = jsFormula.replaceAll(key, collectionsName[key])
                }
                if (mainFormula.includes(key)) {
                    mainFormula = mainFormula.replaceAll(key, collectionsName[key])
                }
            })
        }

        return {
            jsFormula,
            mainFormula
        }
    }

    const mergeDynamicElement = (formula = '') => {
        formula = formula.replace(/\s/g,'')
        if (formula.length > 0) {
            const openingBracketsIndices = [], closingBracketsIndices = []
            for (let i = 0; i < formula.length; i++) {
                if (formula[i] === '[') {
                    openingBracketsIndices.push(i)
                } else if (formula[i] === ']') {
                    closingBracketsIndices.push(i)
                }
            }

            if (openingBracketsIndices.length !== closingBracketsIndices.length) {
                return '';
            } else if (openingBracketsIndices.length > 0) {
                let bracketsRemoved = 0, indicesRemoved = [];
                openingBracketsIndices.forEach((i, index) => {
                    if (formula[i - 1 - bracketsRemoved] === '}') {
                        formula = formula.split('')
                        formula.splice(i - 1 - bracketsRemoved, 1)
                        formula = formula.join('')
                        bracketsRemoved++;
                        indicesRemoved.push(index);
                    }
                })
                closingBracketsIndices.forEach((i, index) => {
                    if (indicesRemoved.includes(index)) {
                        formula = formula.split('')
                        formula.splice(i, 0, '}')
                        formula = formula.join('')
                    }
                })
            }
        }
        return formula;
    }

    const saveFormula = () => {
        let jsFormula = convertExcelToJs();
        if (!jsFormula)
        return;
        let formulaObj = addFieldBraces(jsFormula, formula);
        let excelEvaluatedFormula = mergeDynamicElement(formulaObj.jsFormula);
        let jsEvaluatedFormula = mergeDynamicElement(formulaObj.mainFormula);
    
        if (!excelEvaluatedFormula || !jsEvaluatedFormula) {
            props.showAlert('Incomplete square brackets, please correct the formula!', { id: "formula", variant: 'error' });
            return;
        }

        let payload = {
            name: info.name,
            description: info.description,
            formulaType: type,
            formulaEval: type === "excel" ? excelEvaluatedFormula : jsEvaluatedFormula,
            formulaValue: type === "excel" ? formulaObj.mainFormula : "",
            context: rules
        }

        props.saveFormula(payload, props.user, props.match.params.dappId);
    }

    const updateFormula = () => {
        let jsFormula = convertExcelToJs();
        if (!jsFormula)
        return;
        let formulaObj = addFieldBraces(jsFormula, formula);
        let excelEvaluatedFormula = mergeDynamicElement(formulaObj.jsFormula);
        let jsEvaluatedFormula = mergeDynamicElement(formulaObj.mainFormula);

        if (!excelEvaluatedFormula || !jsEvaluatedFormula) {
            props.showAlert('Incomplete square brackets, please correct the formula!', { id: "formula", variant: 'error' });
            return;
        }

        let payload = {
            _id: match.params.formulaid,
            name: info.name,
            description: info.description,
            formulaType: type,
            formulaEval: type === "excel" ? excelEvaluatedFormula : jsEvaluatedFormula,
            formulaValue: type === "excel" ? formulaObj.mainFormula : "",
            context: rules
        }

        props.updateFormula(payload, props.user, props.match.params.dappId);
    }

    const saveFormulaData = (_, update = false) => {
		const { name } = info;
		if (!name || name.length === 0) {
			setValue(0);
			setError({ ...error, name: "Please enter name" })
			return;
		}

        if (!formula || formula.length === 0) {
            setValue(1);
            setError({ ...error, formula: "Formula cannot be empty." })
            return;
        }

        if (!update) {
            saveFormula();
        } else {
            updateFormula();
        }
	}

    const convertExcelToJs = () => {
        const formulaFormatter = require('excel-formula');
        try {
            let formula2JavaScript = formulaFormatter.toJavaScript(formula);
            return formula2JavaScript;
        } catch(err) {
            alert(err);
            return null;
        }
    }

    const renderHeader = () => {
        return (
            <Grid container>
                <Grid item xs={12} md={6}>
                    <div className="backbtn">
                        <div className="backbtn ptl-20">
                            <Link to={`/builder/${match.params.dappId}/formulas`}
                                style={{ textDecoration: 'none', color: "#1B2A47" }}>
                                <img src={rightarrow} alt="backicon" className="backicon" />
                                <Box component="div" className={`companyheadingtext ${classes.headingCon}`}>
                                    <Typography variant="h4">{"Formulas List"}</Typography>
                                </Box>
                            </Link>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} className="savebtn-sec">
                {props.match.params.formulaid === 'new'
							? <Button
                            color="primary"
                            onClick={saveFormulaData}
                            variant="contained"
                            className={"addnewentrybtn mb5 whitespace-no-wrap"}>
                            {"Save"}
                        </Button>
							: <Button
								className="whitespace-no-wrap addnewentrybtn mb5"
								variant="contained"
								color="primary"
								onClick={() => saveFormulaData(_, true)}>
								{"Update"}
							</Button>
						}
                </Grid>
            </Grid>
        );
    }

    return (
        <Box className={`quickwizard-page ${classes.boxClass}`}>
            {renderHeader()}
            <Box component="div" className="pxy20">
                <div className={classes.root}>
                    <Box component="div" px="1.5rem">
                        <AppBar position="static" className="bg-white">
                            <Tabs value={value} onChange={handleChange}
                                aria-label="simple tabs example" className="quicktabs-sec">
                                <Tab label="Basic Info" {...a11yProps(0)} />
                                <Tab label="Formula Builder" {...a11yProps(1)} />
                            </Tabs>
                            <Divider />
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <FormulaBasicInfo {...info} error={error} handleChange={handleInfoChange} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <FormulaBuilder
                                type={type}
                                formula={formula}
                                error={error}
                                onTypeChange={(val) => onTypeChange(val)}
                                onFormulaChange={(val, elementObj) => onFormulaChange(val)}
                                fields = { fields }
                                rules = {rules}
                                handleRulesChange = {handleRulesChange}
                            />
                        </TabPanel>
                    </Box>
                </div>
            </Box>
        </Box>
    );
}

export default hot(withRouter(Redux(withTheme(NewFormula))));
