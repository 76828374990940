import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, LinearProgress, LinearProgressWithLabel } from '@material-ui/core'
import { DropzoneArea } from 'components/@material-ui-dropzone'
import Button from '@material-ui/core/Button'
import acceptedFiles from 'packages/sharedJSON/acceptedFiles'
import 'styles/styles.scss'

import {
	submitForm
} from 'packages/upload.module';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}))

const View = (props) => {
	const classes = useStyles()
	const [files, setFiles] = useState([])
	const [responseFiles, setResponseFiles] = useState([])
	const [state, setState] = useState({
		progressOpen: false,
		progress: 0,
		description: "Please wait, still in progress..."
	})

	const onUploadProgress = function (progressEvent) {
		setState({ ...state, progress: Math.round((progressEvent.loaded * 100) / progressEvent.total) })
	}

	const handleRes = (res) => {
		console.log("res - fileupload", res)
		if (res.status === "success", res.files) {
			setResponseFiles(res.files)
			setState({
				progressOpen: false,
				description: "File has been uploaded to the server successfully."
			})
			props.history.push({
				pathname: "/builder/dapps/new",
				search: '?mode=addbyFile',
				state: { mode: 'addbyFile' }
			})
			props.getDappFiles(res)
		} else {
			console.error("error", res)
		}
	}

	const uploadWithFormData = (files, title, desc) => {
		const formData = new FormData();
		for (let i = 0; i < files.length; i++) {
			formData.append("file", files[i]);
		}
		// formData.append("file", files[0]);
		submitForm("multipart/form-data", formData, props, handleRes, onUploadProgress);
	}

	const handleNext = () => {
		if (files.length > 0) {
			uploadWithFormData(files)
			setState({
				progressOpen: true,
				description: "Please wait, still in progress..."
			})
		}
		else
			props.showAlert('Please select atleast one File to proceed', { variant: 'danger' })
	}

	return (<Box className="pxy20">
		<div className="recent-apps bg-white">
			<Grid container className={classes.root}>
				<div className="container">
					<div className="create-app-card">
						<Box component="div" className="drag-dropbox">
							<DropzoneArea
								dropzoneText={"Drag and drop an image here or click"}
								onChange={(files) => setFiles(files)}
								acceptedFiles={acceptedFiles}
								showPreviews={true}
								showPreviewsInDropzone={false}
								showFileNamesInPreview={true}
								maxFileSize={50000000}
								filesLimit={5}
							/>
							{state.progressOpen && <>
								{state.description}
								<LinearProgress variant="determinate" value={state.progress} />
							</>}
							<Box className="float-right">
								<Button variant="outlined"
									className="next-btn"
									onClick={handleNext}>
									{"Next"}
								</Button>
							</Box>
						</Box>
					</div>
				</div>
			</Grid>
		</div>
	</Box>)
}

export default withRouter(View)