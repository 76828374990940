import React from 'react'
import classNames from 'classnames'

import ChipSelect from 'components/@custom/ChipSelect'
import TextElement from '../elements/TextElement'
import MultiLine from './MultiLine'

import {
	Button, Checkbox, Chip, FormControl, FormControlLabel, FormLabel, InputAdornment, InputLabel,
	Radio, RadioGroup, Step, StepLabel, Stepper, Switch, Tab, Tabs, TextField, Typography, AppBar,
	Card, CardHeader, CardContent, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary,
	FormGroup, Grid, NativeSelect, withStyles
} from '@material-ui/core'

import Upload from 'components/@upload/Upload'
import DataTable from './DataTable'
import TableStatic from './TableStatic'
import Worksheet from './Worksheet'
import ReportConfigurator from '../elements/ReportConfigurator'

const GenerateElements = (props) => {
	const { classes } = props;
	const { item, outerIndex, gridSize, index } = props;
	let itemPosition = `${outerIndex}-${index}`;
	let visibility = item.hidden ? "hidden" : "visible"
	if (item.validation && item.validation.required && !item.label.labelText.endsWith("*")) {
		item.label.labelText += "*";
	} else if ((item.type == 'select' || item.type == 'text' || item.type == 'multiselect'
		|| item.type == 'date' || item.typeOfText == 'number' || item.typeOfText == 'time'
		|| item.typeOfText == 'datetime-local' || item.typeOfText == 'password'
		|| item.typeOfText == 'email' || item.typeOfText == 'tel')
		&& item.required && !item.label.endsWith("*")) {
		item.label += "*";
	}

	if (item.type == 'text' && item.rows != null) {
		return (<TextElement
			filledData={props.filledData}
			onChange={props.onChange}
			item={item}
			classes={classes}
			visibility={visibility}
		/>)
	}
	if (item.type == 'text') {
		let value;
		if (item.computed === 'Computed') {
			let formula = item.formula;
			const { id, displayName, email } = props.user
			let result;
			if (formula === '{CURRENT_USER_EMAIL}') {
				result = email
			} else if (formula === '{CURRENT_USER_FULLNAME}') {
				result = displayName
			} else if (formula === '{CURRENT_USER_ID}') {
				result = id
			} else if (formula === '{CURRENT_DATE}') {
				result = new Date();
			} else if (formula === '{CURRENT_DATETIME}') {
				result = new Date()
			} else {
				result = formula.replace(/\{(.*?)\}/g, (match, key) => {
					return props.filledData[key] && props.filledData[key];
				});
			}
			value = result
		} else if (item.computed === 'Lookup') {
			const { lookupDataElement, lookupDataKey, lookupDataValue } = item
			if (props.lookupDataModelValue && props.filledData[item.lookupDataValue]) {
				let filterData = props.lookupDataModelValue.filter(data => {
					return data[lookupDataKey] === props.filledData[item.lookupDataValue]
				})
				value = filterData && filterData[0][lookupDataElement]
			}

		} else {
			value = props.filledData[item.name] || item.defaultValue
		}
		return (
			<>
				{item.labelPosition == 'top' && <InputLabel className={classNames(classes.inputLabel)}>{item.label}</InputLabel>}
				<TextField className={classNames(classes.textField)}
					style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
					onChange={(e) => { props.onChange(item.name, e.target.value, item.type) }}
					margin="normal"
					variant={item.variant ? item.variant : "standard"}
					fullWidth
					value={value}
					InputProps={item.labelPosition == 'end' ? {
						endAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
					} : (item.labelPosition == 'start' ? {
						startAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
					} : {})}
					disabled={item.disabled || item.computed !== 'Editable'}
					required={item.required}
					inputProps={{ minLength: item.minLength, maxLength: item.maxLength, pattern: item.pattern }}
				/>
			</>

		)
	}
	else if (item.type == 'html' && item.tag == 'label') {
		return (
			<>
				<FormControl className="custom-control" className={classNames(classes.label)}>
					<FormLabel className={classNames(classes.formlabel)}
						style={{
							fontSize: item.size, color: item.color, textAlign: item.align, visibility: visibility,
							...JSON.parse(item.style ? item.style : "{}")
						}}>{item.content}</FormLabel>
				</FormControl>
			</>
		)
	}
	else if (item.type == 'html') {
		return (
			<>
				<Typography variant={item.tag}
					style={{
						color: item.color, visibility: visibility,
						...JSON.parse(item.style ? item.style : "{}")
					}}
					align={item.align}
					gutterBottom>
					{item.content}
				</Typography>

			</>
		)
	}
	else if (item.type == 'radio') {
		let values = []
		if (item.dataType === 'dataModel') {
			values = props.dataModelValue
		} else if (item.dataType === 'workflow') {
			values = props.workflowTask
		} else {
			values = item.data && item.data.values
		}
		return (
			<>
				<FormControl style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}>
					<FormLabel component="legend">{item.label.labelText}</FormLabel>
					<RadioGroup className={item.ui.className}
						row={item.alignment == 'inline' ? true : false}
						required={true}
						defaultValue={item.defaultValue}
						inputProps={{ required: item.validation.required }}
						onChange={(e) => { props.onChange(item.name, e.target.value, item.type) }} >
						{values && Array.isArray(values) && values.map((el, i) => {
							return <FormControlLabel key={i}
								checked={(props.filledData[item.name] || item.defaultValue) == el.value}
								value={el.value}
								control={<Radio disabled={item.disabled} required />}
								label={el.label} />
						})}
					</RadioGroup>
				</FormControl>

			</>
		)
	}
	else if (item.type == 'checkbox') {
		let values = []
		if (item.dataType === 'dataModel') {
			values = props.dataModelValue
		} else if (item.dataType === 'workflow') {
			values = props.workflowTask
		} else {
			values = item.data && item.data.values
		}
		return (
			<>
				<FormControl style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }} >
					<FormLabel component="legend">{item.label.labelText}</FormLabel>
					<FormGroup row={item.alignment == 'inline' ? true : false} defaultValue={item.defaultValue}>
						{values.map((el, i) => {
							return <FormControlLabel value={el.value} key={i}
								control={<Checkbox disabled={item.disabled}
									onChange={(e) => { props.onChange(item.name, e.target.value, item.type) }}
									className={item.ui.className} />}
								label={el.label} />
						})}
					</FormGroup>
				</FormControl>

			</>
		)
	}

	else if (item.type == 'select') {
		let values = []
		if (item.dataType === 'dataModel') {
			values = props.dataModelValue
		} else if (item.dataType === 'workflow') {
			values = props.workflowTask
		} else {
			values = item && item.values
		}
		return (
			<>
				<FormControl style={{ "paddingBottom": "10px", "paddingBop": "10px", visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }} className="native-select-dropdown">
					<FormLabel component="legend">{item.label}</FormLabel>
					<NativeSelect
						fullWidth
						className={item.className}
						onChange={(e) => { props.onChange(item.name, e.target.value, item.type) }}
						inputProps={{
							required: item.required
						}}
					>
						<option value="" selected disabled>Select an option</option>
						{values.map((el, i) => {
							let value = el.value ? el.value : el
							let label = el.label ? el.label : el
							return <option key={i}
								selected={props.filledData[item.name] == value || item.defaultValue == value}
								disabled={item.disabled} value={value}>
								{label}
							</option>

						})}
					</NativeSelect>
				</FormControl>

			</>
		)
	}
	else if (item.type == 'namePicker') {
		let values = item && item.values;
		return (
			<>
				<FormControl style={{
					"paddingBottom": "10px", "paddingTop": "10px",
					visibility: visibility, ...JSON.parse(item.style ? item.style : "{}")
				}} className="native-select-dropdown">
					<FormLabel component="legend">{item.label}</FormLabel>
					<NativeSelect
						fullWidth
						className={item.className}
						onChange={(e) => { props.onChange(item.name, e.target.value, item.type) }}
						inputProps={{
							required: item.required
						}}
					>
						<option value="" selected disabled>Select an option</option>
						{values.map((el, i) => {
							return <option key={i}
								selected={props.filledData[item.name] == el || item.defaultValue == el}
								disabled={item.disabled} value={el}>
								{el}
							</option>

						})}
					</NativeSelect>
				</FormControl>
			</>
		)
	}
	else if (item.type == 'pageOrFormPicker') {
		let values = item && item.values;
		return (
			<>
				<FormControl style={{
					"paddingBottom": "10px", "paddingTop": "10px",
					visibility: visibility, ...JSON.parse(item.style ? item.style : "{}")
				}} className="native-select-dropdown">
					<FormLabel component="legend">{item.label}</FormLabel>
					<NativeSelect
						fullWidth
						className={item.className}
						onChange={(e) => { props.onChange(item.name, e.target.value, item.type) }}
						inputProps={{
							required: item.required
						}}
					>
						<option value="" selected disabled>Select an option</option>
						{values.map((el, i) => {
							return <option key={i}
								selected={props.filledData[item.name] == el || item.defaultValue == el}
								disabled={item.disabled} value={el}>
								{el}
							</option>

						})}
					</NativeSelect>
				</FormControl>
			</>
		)
	}
	else if (item.type == 'dataSelector') {
		return (
			<>
				<Button style={{ visibility: visibility }}
					variant="contained" color="secondary"
				>{item.label}</Button>
			</>

		)
	}
	else if (item.type == 'multiselect') {
		let selectedvalue = props.filledData[item.name];
		let values = []
		if (item.dataType === 'dataModel') {
			values = props.dataModelValue
		} else if (item.dataType === 'workflow') {
			values = props.workflowTask
		} else {
			values = item && item.values
		}
		return (
			<>
				{
					<>
						<ChipSelect
							style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
							className="w-full my-16"
							value={selectedvalue ? selectedvalue : (item.defaultValue ? [{
								value: item.defaultValue,
								label: item.defaultValue
							}] : [])}
							onChange={(value) => { props.onChange(item.name, value, item.type) }}
							placeholder="Select multiple tags"
							textFieldProps={{
								label: item.label,
								InputLabelProps: {
									shrink: true
								},
								variant: 'standard'
							}}
							options={item.disabled ? [] : values.map(item => ({
								value: item,
								label: item
							}))}
							isMulti
						/>
					</>
				}
			</>
		)
	}
	else if (item.type == 'date') {
		return (
			<>
				{item.labelPosition == 'top' && <InputLabel className={classNames(classes.inputLabel)}>{item.label}</InputLabel>}
				<TextField className={classNames(classes.textField)}
					style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
					onChange={(e) => { props.onChange(item.name, e.target.value, item.type) }}
					type="date"
					InputProps={item.labelPosition == 'end' ? {
						endAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
					} : (item.labelPosition == 'start' ? {
						startAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
					} : {})}
					margin="dense"
					variant={item.variant ? item.variant : "standard"}
					required={item.required}
					defaultValue={item.defaultValue}
					value={props.filledData[item.name] || item.defaultValue}
					inputProps={{ min: item.min, max: item.max, disabled: item.disabled, visibility: visibility }}
					fullWidth
				/>
			</>

		)
	}
	else if (item.type == 'button') {
		return (
			<>
				<Button
					style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
					onClick={(e) => { props.buttonAction(itemPosition) }}
					variant="contained" color="secondary"
				>{item.label}</Button>
			</>

		)
	}
	else if (item.type == 'chip') {

		return (
			<>
				<FormControl style={{ "paddingBottom": "10px", "paddingTop": "10px", visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }} className="native-select-dropdown">
					<InputLabel className={classNames(classes.inputLabel)} component="legend">{item.label}</InputLabel>
					{item.values.map(data => {
						return (
							<Chip
								key={data.value}
								label={data.label}
							/>
						);
					})}
				</FormControl>
			</>
		)
	}
	else if (item.type == 'switch') {

		return (
			<>
				<FormControlLabel style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
					control={
						<Switch disabled={item.disabled} checked={props.filledData[item.name] || item.defaultValue}
							onChange={(e) => { props.onChange(item.name, e.target.value, item.type) }}

						/>
					}
					label={item.label}
				/>
			</>
		)
	}

	else if (item.typeOfText != null) {

		if (item.typeOfText === 'file') {
			return (
				<>
					<FormLabel component="legend">{item.label}</FormLabel>
					<Upload handleFileChange={props.handleFileChange} type="image"
						acceptedFiles={[
							'image/svg',
							'image/gif',
							'image/jpg',
							'image/jpeg',
							'image/png'
						]}
					// fileId={'tesr'}

					/>
				</>
			)
		} else {
			return (
				<>
					{item.labelPosition == 'top'
						&& <InputLabel className={classNames(classes.inputLabel)}>
							{item.label}
						</InputLabel>}
					{item.typeOfText != 'number'
						? <TextField
							style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
							className={classNames(classes.textField)}
							onChange={(e) => {
								if (item.typeOfText == 'file') {
									props.setFilesToUpload(item.name, e.target.files);
									props.onChange(item.name, e.target.files[0].name, "file");
									return;
								}
								props.onChange(item.name, e.target.value, item.type)
							}}
							margin={item.typeOfText != 'file' ? "dense" : "none"}
							type={item.typeOfText}
							name={item.name}
							id={item.name}
							disabled={item.disabled}
							defaultValue={item.defaultValue}
							value={props.filledData[item.name] || item.defaultValue}
							InputProps={item.labelPosition == 'end'
								? {
									endAdornment: <InputAdornment
										position={item.labelPosition}>
										{item.label}
									</InputAdornment>,
								}
								: (item.labelPosition == 'start'
									? {
										startAdornment: <InputAdornment position={item.labelPosition}>
											{item.label}
										</InputAdornment>,
									}
									: {})}
							required={item.required}
							style={{ visibility: visibility }}
							variant={item.variant ? item.variant : "standard"}
							fullWidth={item.typeOfText != 'file' ? true : false}
						/> : <TextField
							style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
							className={classNames(classes.textField)}
							onChange={(e) => { props.onChange(item.name, e.target.value, item.type) }}
							margin={item.typeOfText != 'file' ? "dense" : "none"}
							type={item.typeOfText}
							disabled={item.disabled}
							required={item.required}
							InputProps={item.labelPosition == 'end'
								? {
									endAdornment: <InputAdornment position={item.labelPosition}>
										{item.label}
									</InputAdornment>,
								}
								: (item.labelPosition == 'start' ? {
									startAdornment: <InputAdornment position={item.labelPosition}>
										{item.label}
									</InputAdornment>,
								}
									: {})}
							variant={item.variant ? item.variant : "standard"}
							fullWidth={item.typeOfText != 'file' ? true : false}
							inputProps={{ min: item.min, max: item.max, step: item.step, defaultValue: item.defaultValue, visibility: visibility }}
							defaultValue={parseInt(item.value)}
							value={item.defaultValue}

						/>}
				</>

			)
		}
	}
	else if (item.type == 'HTML_content') {
		return (<FormControl style={{
			"paddingBottom": "10px", "paddingTop": "10px",
			visibility: visibility, ...JSON.parse(item.style ? item.style : "{}")
		}}
			className="native-select-dropdown">
			<div dangerouslySetInnerHTML={{ __html: item.content }} />
		</FormControl>)
	}
	else if (item.type == 'multiLine') {
		return (<>
			<Card className={classNames(classes.card)}
				style={{
					color: item.color, visibility: visibility,
					...JSON.parse(item.style ? item.style : "{}")
				}}  >
				<CardContent>
					{visibility != 'hidden' && <MultiLine json={item}
						setData={(name, val) => { props.setData(name, val, "multiLine") }} />
					}
				</CardContent>
			</Card>
		</>)
	}
	else if (item.type == 'table') {
		return <TableStatic data={item.data} />
	}
	else if (item.type == 'worksheet') {
		return <Worksheet data={item.data} />
	}
	else if (item.type == 'dataTable') {
		const { dataModel, dataElement } = item
		return <DataTable dataModel={dataModel} dataElement={dataElement} />
	}
	else if (item.type == 'reportConfigurator') {
		const { dataModel, dataElement } = item
		return <ReportConfigurator dataModel={dataModel} dataElement={dataElement} />
	}
	else {
		return (<></>)
	}
}

export default GenerateElements