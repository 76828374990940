import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Button, Typography, Grid, Box } from '@material-ui/core'
import rightarrow from 'images/rightarrow.svg'

const Header = (props) => {
	return (<Grid container>
		<Grid item xs={12} md={6}>
			<div className="backbtn">
				<div className="backbtn ptl-20">
					<Link to={`/builder/${props.match.params.dappId}/${props.to}`}
						style={{ textDecoration: 'none', color: "#1B2A47" }}>
						<img src={rightarrow} alt="backicon" className="backicon" />
						<Box component="h3" className="companyheadingtext">
							<Typography variant="h4">{props.title}</Typography>
						</Box>
					</Link>
				</div>
			</div>
		</Grid>
		<Grid item xs={12} md={6} className="savebtn-sec">
			<Button
				className="addnewentrybtn mb5 whitespace-no-wrap"
				variant="contained"
				color="primary"
				disabled={!props.canBeSubmitted()}
				onClick={() => props.id === 'new' ? props.save() : props.update()}>
				{props.id === 'new' ? "Save" : "Update"}
			</Button>
		</Grid>
	</Grid>)
}

export default withRouter(Header)