import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import axios from 'axios'
import {
	Table, TableBody, TableCell, TableHead, TableRow,
	withStyles
} from '@material-ui/core'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const styles = theme => ({
	head: {
		backgroundColor: "lightgrey",
		color: theme.palette.common.white,
	},
	customFont: {
		"font-size": "large"
	}
});

class DataTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			header: []
		}
	}

	componentDidMount() {
		// ExternalScripts["testPopulate"]();
		const { dataElement, dataModel } = this.props

		this.getDataModel(`${API_BASE_URL}/dataconnect/${dataModel}?q=${dataElement}`)

	}

	getDataModel = (url) => {

		axios.get(url, { headers: { 'appId': this.props.match.params.dappId } })
			.then(res => {
				let rows = res.data.data;
				let header = [];
				if (rows && rows.length) {
					let row = rows[0]
					let keys = Object.keys(row)
					header = keys
				}
				this.setState({ header, data: rows })
			}).catch((err) => {
				alert("Unable to fetch data from the provided endpoint" + err);
			})
	}
	render() {
		console.log('this.state.header', this.state.header);
		console.log('this.state.data', this.state.data);
		const { dataModel, dataElement, classes } = this.props;
		return (<>
		{this.state.header.length === 0 && "Please select Data model and data elements."}
		{this.state.header.data === 0 && "No data available to display."}
			<Table className={classNames(classes.table)} size="small" aria-label="a dense table">
				<TableHead className={classNames(classes.head)}>
					<TableRow>
						{this.state.header && this.state.header.map((item) => {
							return (<TableCell className={classNames(classes.customFont)}>{item}</TableCell>);
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{this.state.data.map((row, index) => {
						let values = Object.values(row);
						return (
							<TableRow>
								{values.map((rowdata) => {
									return (<TableCell className={classNames(classes.customFont)}>
										{rowdata}</TableCell>)
								})}
							</TableRow>)
					})}
				</TableBody>
			</Table>
		</>)
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}

function mapStateToProps() {
	return {}
}

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(mapStateToProps, mapDispatchToProps)(DataTable)
	)
)
