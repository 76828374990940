import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './view'
import Redux from './redux'
import { withRouter } from 'react-router-dom'
import { isRowSelected } from 'packages/datamodel'

import {
	selectedRow,
} from 'packages/common'

const Container = (props) => {

	const [state, setState] = useState({
		form: {
			datasourceId: "",
			dataElements: [],
			workflowId: ""
		},
		selected: [],
		dataElements: [],
		selectedDataElement: "",
		isSubmitButton: true,
		currentRefDataModelId: "",
		type: "entitydatamodel",
	})

	const handleChange = (event) => {
		setState({
			...state,
			form: _.set({ ...state.form }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value)
		})
	}

	const handleChipChange = (event, name) => {
		setState({ ...state, form: _.set({ ...state.form }, name, event.target.value) })
	}

	const handleChipChangeData = (event, name) => {
		setState({ ...state, form: _.set({ ...state.form }, name, event.value) })
	}

	const handleFileChange = (file) => {
		props.getDataModel(props.match.params)
	}

	const handleDataModelChange = (event) => {
		let dataModel = props.orgDataModels.find(dm => {
			return event.target.value === dm._id
		})
		if (state.form) {
			setState({
				...state,
				form: _.set({
					...state.form,
					datasourceId: dataModel.datasourceId,
					refDataModels: (Array.isArray(state.form.refDataModels && state.form.refDataModels.indexOf(event.target.value) === -1)
						? [...state.form.refDataModels, event.target.value] : [event.target.value])
				}, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value)
			})
			dataModel.datasourceId && props.getDatasource({ datasourceId: dataModel.datasourceId, dappId: props.match.params.dappId })
		}
		setState({
			...state,
			dataEles: dataModel.dataElements.map(de => ({ ...de, label: de.name, value: de._id })),
			currentRefDataModelId: event.target.value
		})
	}

	const handleDatasourceChange = (event) => {
		setState({ ...state, form: _.set({ ...state.form }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value) });
		// event.target.value && props.getDatasource({ datasourceId: event.target.value, dappId: props.match.params.dappId });
	};

	const handleSelectedDataElements = (value, name) => {
		let v = value.map(val => ({ ...val, dataModelId: state.currentRefDataModelId }))
		setState({ ...state, form: _.set({ ...state.form }, name, v) })
	}

	const handleCollectionChange = (event) => {
		setState({ ...state, form: _.set({ ...state.form }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value) });
		console.log('handleCollectionChange');
		props.getDataElements(state.form.datasourceId, event.target.value, props.match.params.dappId);
	}

	const updateDatamodelState = () => {
		const { datamodelId } = props.match.params
		if (datamodelId === 'new') {
			props.newDataModel()
		}
		else {
			props.getDataModel(props.match.params)
		}
		props.getDatasources(props.match.params.dappId)
	}

	const canBeSubmitted = () => {
		const { form } = state
		let flag = form && form.name && form.name.length > 0 && state.isSubmitButton
		// && !_.isEqual(props.datamodel.data.dataElements, state.selected)
		if (props.datamodel.data) {
			console.log('!_.isEqual(props.datamodel.data, state.form', !_.isEqual(props.datamodel.data, state.form));
			console.log('!_.isEqual(props.datamodel.data.dataElements, state.selected)', !_.isEqual(props.datamodel.data.dataElements, state.selected));
			return flag
				&& (!_.isEqual(props.datamodel.data, state.form) || !_.isEqual(props.datamodel.data.dataElements, state.selected))
		}
		return flag && !_.isEqual(props.datamodel.data, state.form)
		// if (flag && props.datamodel.data && !_.isEqual(props.datamodel.data.dataElements, state.form.dataElements))
		// 	return true
		// return false
	}

	useEffect(() => {
		if (!_.isEqual(props.datamodel.data, state.form)) {
			setState({ ...state, isSubmitButton: true })
		}
	}, [state.form])

	const updateFormState = () => {
		console.log("fire", "fire", props.datamodel.data, state.form);
		if (props.datamodel.data && state.form
			&& (props.datamodel.data._id !== state.form._id || !_.isEqual(props.datamodel.data, state.form))
		) {
			console.log("fire", "fire1");
			setState({
				...state,
				form: JSON.parse(JSON.stringify(props.datamodel.data)),
				selected: JSON.parse(JSON.stringify(props.datamodel.data.dataElements)),
				dataElements: JSON.parse(JSON.stringify(props.datamodel.data.dataElements))
			})
			if (props.datamodel.data && props.datamodel.data.datasourceId) {
				console.log("fire", "fire2");
				props.getDatasource({ datasourceId: props.datamodel.data.datasourceId, dappId: props.match.params.dappId })
				props.getCollectionsFromDatasource(props.datamodel.data.datasourceId, props.match.params.dappId)
				props.getDataElements(props.datamodel.data.datasourceId, props.datamodel.data.collectionName, props.match.params.dappId)
			}
		}
	}

	const adjustForm = (form) => {
		form.dataElements = state.selected
		form.collectionName = props.datasource && props.datasource.dsType !== "FILE" && form.collectionName ? form.collectionName : form.name
	}

	const saveDatamodelData = () => {
		setState({ ...state, isSubmitButton: false })
		adjustForm(state.form)
		props.saveDatamodel({ ...state.form, appId: props.match.params.dappId, orgId: props.user.orgId })
	}

	const updateDatamodelData = () => {
		setState({ ...state, isSubmitButton: false })
		adjustForm(state.form)
		props.updateDatamodel({ ...state.form, appId: props.match.params.dappId }, props.match.params.dappId)
	}

	const isSelected = dataElement => {
		return isRowSelected(dataElement, state.selected)
	}

	const openDataElementDialog = (form) => {
		if (form === "new")
			setState({ ...state, selectedDataElement: "new" })
		else if (form) {
			setState({ ...state, selectedDataElement: form })
		}
		props.openNewDataModelDialog()
	}

	const addDataElement = (d1, d2, d3, d4) => {
		props.addDataElement(d1, d2, d3, d4);
	}

	const updateNewDataElement = (dataEle) => {
		let updatedDE = (dataElements) => {
			let newDataElements=JSON.parse(JSON.stringify(dataElements))
			return newDataElements.map(de => {
				if (de._id && de._id === dataEle._id || de.name === dataEle.name)
				return dataEle
				else return de
			})
		}
		// state.form.dataElements = updatedDE(state.form.dataElements)
		// state.dataElements = updatedDE(state.dataElements)
		// state.selected = updatedDE(state.selected)
		setState({
			...state,
			form: { ...state.form, dataElements: updatedDE(state.form.dataElements) },
			dataElements:updatedDE(state.dataElements),
			selected: updatedDE(state.selected),
		});
	}
	const addNewDataElement = (dataEle) => {
		let arr = [];
		delete dataEle._id;
		if (state.form.dataElements) {
			arr = [...state.form.dataElements, dataEle];
		} else {
			arr.push(dataEle);
		}
		console.log("arr", arr, state.form);
		setState({
			...state,
			form: { ...state.form, dataElements: [...arr] },
			dataElements: [...state.dataElements, dataEle],
			selected: [...state.selected, dataEle],
		});
		console.log("state", state, dataEle);
	}

	useEffect(() => {
		if (state.form.datasourceId) {
			props.getDatasource({ datasourceId: state.form.datasourceId, dappId: props.match.params.dappId })
			props.getCollectionsFromDatasource(state.form.datasourceId, props.match.params.dappId);
		}
	}, [state.form.datasourceId])

	useEffect(() => {
		if (state.form && state.form.type === "networkdatamodel") {
			props.getDataModelsByOrgId({ ...props.user, appId: props.match.params.dappId })
		}
	}, [state.form])

	useEffect(() => {
		updateDatamodelState()
	}, [props.location])

	useEffect(() => {
		const { datamodelId } = props.match.params;
		if (datamodelId !== "new") {
			updateFormState()
		}
	}, [props.datamodel.data])

	useEffect(() => {
		if (props.datamodel.saveSuccess) {
			props.showAlert('Datamodel saved successfully', { id: "datamodel", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/datamodels/${props.datamodel.data._id}` })
		}
		if (props.datamodel.updateSuccess) {
			props.showAlert('Datamodel updated successfully', { id: "datamodel", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/datamodels/${props.datamodel.data._id}` })
		}
	}, [props.datamodel.saveSuccess, props.datamodel.updateSuccess])

	const mergeDataElements = (arr1, arr2) => {
		let a1 = arr1.filter(ele1 => !arr2.find(ele2 => ele1.name === ele2.name))
		return [...arr2, ...a1]
	}

	useEffect(() => {
		const { datamodelId } = props.match.params
		if (datamodelId === 'new') {
			setState({
				...state,
				dataElements: props.dataElements
			})
		} else {
			if (Array.isArray(props.dataElements) && Array.isArray(state.form.dataElements)) {
				setState({
					...state, dataElements: mergeDataElements(props.dataElements, state.form.dataElements)
				})
			}
		}
	}, [props.dataElements])

	const handleCheck = (event, dataElement) => {
		const { selected } = state;

		let flag = false, selectedIndex = -1;
		selected.map((ele, i) => {
			if (ele.name === dataElement.name) {
				flag = ele.name === dataElement.name;
				selectedIndex = i;
			}
		});

		let newSelected = selectedRow(event, dataElement, selected, selectedIndex)
		setState({ ...state, selected: newSelected });
	}

	return (<View
		{...props}
		state={state}
		handleChange={handleChange}
		handleChipChange={handleChipChange}
		handleChipChangeData={handleChipChangeData}
		canBeSubmitted={canBeSubmitted}
		isSelected={isSelected}
		saveDatamodelData={() => saveDatamodelData()}
		updateDatamodelData={() => updateDatamodelData()}
		openDataElementDialog={openDataElementDialog}
		handleFileChange={handleFileChange}
		handleDataModelChange={handleDataModelChange}
		handleSelectedDataElements={handleSelectedDataElements}
		addDataElement={addDataElement}
		handleCollectionChange={handleCollectionChange}
		handleDatasourceChange={handleDatasourceChange}
		addNewDataElement={addNewDataElement}
		updateNewDataElement={updateNewDataElement}
		handleCheck={handleCheck}
	/>)
}

export default hot(withRouter(Redux(Container)))
