import React, { Component } from 'react';
import {
    TextField, FormControl, FormLabel, Typography, Select, MenuItem
} from '@material-ui/core';
import Conditions from '../common/Conditions'
import ConditionsTable from '../common/ConditionsTable'

class Data extends Component {
    render() {
        let { classes, values, dataModels, handleChange } = this.props;
        return (<>
            <form className="form" noValidate autoComplete="off" >
                <Typography className="text-16 sm:text-20 truncate mb-10">
                    Select Data Model
                        </Typography>
                <FormControl fullWidth className="mt-8 mb-16">
                    <Select
                        value={values.pageLevelDataModel}
                        onChange={this.props.onChangeHandler()}
                        inputProps={{
                            name: 'pageLevelDataModel',
                            id: 'pageLevelDataModel',
                        }}>
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {dataModels !== null && dataModels.map(dataModel => (
                            <MenuItem key={dataModel._id} value={dataModel._id}>
                                {dataModel.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {!this.props.checkForNullOrUndefined(dataModels)
                    && values.pageLevelDataModel && (<>
                        <Conditions
                            values={values}
                            classes={classes}
                            onConditionChangeHandler={this.props.onConditionChangeHandler}
                            addCondition={this.props.addCondition}
                        />
                        <FormLabel component="legend">
                            Conditions Table
                        </FormLabel>
                        <ConditionsTable
                            conditions={values.conditions}
                            editCondition={this.props.editCondition}
                            deleteCondition={this.props.deleteCondition}
                        />
                    </>)}
            </form>
        </>)
    }
}
export default Data;