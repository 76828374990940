import React, { Component, Fragment } from 'react';

// version from `meta.json` - first param
// version in bundle file - second param
const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};

export default class CacheBuster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
            refreshCacheAndReload: () => {
                this.clearCache()
                navigator.serviceWorker.getRegistrations()
                    .then(function (registrations) {
                        for (let registration of registrations) {
                            registration.unregister().then(v => {
                                window.location.reload(true)
                            })
                        }
                    });
            }
        };
    }

    clearCache() {
        console.log("clearCache");
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
                for (let name of names) caches.delete(name);
            });
        }
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_PUBLIC_PATH ? '/' + process.env.REACT_APP_PUBLIC_PATH : ''}/meta.json`)
            .then((response) => {
                console.log('response', response);
                return response.json()
            })
            .then((meta) => {
                const latestVersion = meta.version;
                const GITHASH = meta.hash
                const currentVersion = this.props.global.appVersion;
                const currentGITHASH = this.props.global.GITHASH

                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion, GITHASH, currentGITHASH);
                if (shouldForceRefresh || GITHASH !== currentGITHASH) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                    this.setState({ loading: false, isLatestVersion: false });
                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    this.setState({ loading: false, isLatestVersion: true });
                }
            }).catch((err) => {
                console.log(err)
                this.setState({ loading: false, isLatestVersion: true });
            })
    }

    render() {
        const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
        return <Fragment>
            {this.props.children({ loading, isLatestVersion, refreshCacheAndReload })}
        </Fragment>
    }
}