import axios from 'axios'
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export function submitForm(contentType, data, props, setResponse, onUploadProgress) {
	let url;
	if (props.type === "logo") {
		url = API_BASE_URL + '/dapps/upload/logo';
	} else if (props.type === "ds") {
		url = API_BASE_URL + `/dapps/upload/ds?dmId=${props.dmId}`;
	} else if (props.type === "appadd") {
		url = `${API_BASE_URL}/dapps/upload/add`
	} else {
		url = API_BASE_URL + '/dapps/upload'
	}
	axios({
		url: url,
		method: 'POST',
		data: data,
		headers: {
			'Content-Type': contentType,
			appId: props.match.params.dappId
		},
		onUploadProgress: (p) => {
			onUploadProgress && onUploadProgress(p)
		}
	}).then((response) => {
		//Saving files to state for further use and closing Modal.
		setResponse(response.data);
	}).catch((error) => {
		setResponse({ "error": error });
	})
}