import React from 'react';
import components from '../components';
import DraggableToolbarIcon from './DraggableToolbarIcon';

const HEIGHT = 40;
const WIDTH = 40;
export default class Toolbar extends React.Component {
  handle;
  render() {
    const { dropToolbarIcon, dragToolbarIcon, Item } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'white',
          borderRight: '1px solid #DAE0E2',
        }}
      >
        <div style={{ width: 'max-content' }} className="hellasd">
          {Object.keys(components).map(itemType => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                textAlign: 'center',
              }}
              draggable
              key={itemType}
              dragToolbarIcon={dragToolbarIcon(itemType)}
              dropToolbarIcon={dropToolbarIcon(itemType)}
            >
              <DraggableToolbarIcon
                key={itemType}
                dragToolbarIcon={dragToolbarIcon}
                dropToolbarIcon={dropToolbarIcon}
                height={HEIGHT}
                Item={components[itemType]}
                width={itemType === 'Terminator' ? HEIGHT * 1.5 : HEIGHT}
                subType={itemType}
                itemType={itemType}
              />
              {/* {console.log('ItemType===', itemType)} */}
              {/* <p style={{ color: '#000', paddingLeft: 8, paddingRight: 20, paddingBottom: 9 }}>
                {itemType}
              </p> */}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
