import React, { Component } from 'react';
import Highcharts from 'highcharts';
import ComponentListPane from './ComponentListPane/index';
import ComponentDescriptionPane from './ComponentDescriptionPane/index';
import ComponentViewPane from './ComponentViewPane';
import 'styles/page-designer.css';

class PageRenderer extends Component {

	componentDidMount() {
		console.log("componentDidMount ==== PageRenderer", !Highcharts.map, !Highcharts.charts.length);
		if (!Highcharts.map || !Highcharts.charts.length) {
			console.log("radialize")
			// Radialize the colors
			Highcharts.getOptions().colors = Highcharts.map(Highcharts.getOptions().colors, function (color) {
				return {
					radialGradient: {
						cx: 0.5,
						cy: 0.3,
						r: 0.7
					},
					stops: [
						[0, color],
						[1, Highcharts.Color(color).brighten(-0.3).get('rgb')] // darken
					]
				};
			});
		}
	}

	render() {
		return (
			<div id="pageRenderer" className="pageRenderer" style={{ height: "1200px" }}>
				{!this.props.readOnly && <ComponentListPane />}
				<div id="pageview" className="pageview">
					<ComponentViewPane
						readOnly={this.props.readOnly}
					/>
				</div>
				{!this.props.readOnly && <ComponentDescriptionPane location={this.props.location} />}
			</div>
		);
	}
}

export default PageRenderer;
