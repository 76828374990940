import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { NotificationsService } from 'services/index'
import { ActionTypes } from 'constants/index'

async function fetchAsync(func, arg) {
	const response = arg ? await func(arg) : await func()
	return response
}

function* getNotifications(action) {
	try {
		const notifications = yield call(fetchAsync, NotificationsService.getNotifications, action.payload)
		yield put({ type: ActionTypes.GET_NOTIFICATIONS_SUCCESS, payload: notifications })
	} catch (e) {
		yield put({ type: ActionTypes.GET_NOTIFICATIONS_FAILURE, error: e.message })
	}
}

function* getNotification(action) {
	try {
		const notification = yield call(fetchAsync, NotificationsService.getNotification, action.payload)
		yield put({ type: ActionTypes.GET_NOTIFICATION_SUCCESS, payload: notification })
	} catch (e) {
		yield put({ type: ActionTypes.GET_NOTIFICATION_FAILURE, error: e.message })
	}
}

function* saveNotification(action) {
	try {
		const notification = yield call(fetchAsync, NotificationsService.saveNotification, action.payload)
		yield put({ type: ActionTypes.SAVE_NOTIFICATION_SUCCESS, payload: notification })
	} catch (e) {
		yield put({ type: ActionTypes.SAVE_NOTIFICATION_FAILURE, error: e.message })
	}
}

function* updateNotification(action) {
	try {
		const notification = yield call(fetchAsync, NotificationsService.updateNotification, action.payload)
		yield put({ type: ActionTypes.UPDATE_NOTIFICATION_SUCCESS, payload: notification })
	} catch (e) {
		yield put({ type: ActionTypes.UPDATE_NOTIFICATION_FAILURE, error: e.message })
	}
}

function* deleteNotification(action) {
	try {
		const notification = yield call(fetchAsync, NotificationsService.deleteNotification, action.payload)
		yield put({ type: ActionTypes.DELETE_NOTIFICATION_SUCCESS, payload: notification })
	} catch (e) {
		yield put({ type: ActionTypes.DELETE_NOTIFICATION_FAILURE, error: e.message })
	}
}

export function* watchNotificationsSaga() {
	yield takeEvery(ActionTypes.GET_NOTIFICATIONS, getNotifications);
	yield takeEvery(ActionTypes.GET_NOTIFICATION, getNotification)
	yield takeEvery(ActionTypes.SAVE_NOTIFICATION, saveNotification)
	yield takeEvery(ActionTypes.UPDATE_NOTIFICATION, updateNotification)
	yield takeEvery(ActionTypes.DELETE_NOTIFICATION, deleteNotification)
}

export default watchNotificationsSaga;