import React from 'react';
import bindme from 'bindme';
import Step from './Step';
import ConnectionPoint from './ConnectionPoint';

export default class OptionalLine extends Step {
  static defaultProps = {
    ...Step.defaultProps,
    startX: 0,
    startY: 0,
    endX: 80,
    endY: 80,
  };

  constructor(props) {
    super(props);

    bindme(this, 'onMouseDown', 'onMouseEnter', 'onMouseLeave');

    this.state = { isMouseOver: false };
  }

  onMouseDown() {
    // TODO selectArrow
  }

  onMouseEnter() {
    this.setState({ isMouseOver: true });
  }

  onMouseLeave() {
    this.setState({ isMouseOver: false });
  }

  render() {
    let { stroke, strokeWidth } = Step.defaultProps.style;

    const { isMouseOver } = this.state;
    if (isMouseOver) strokeWidth++;
    const {
      x,
      y,
      startX,
      startY,
      endX,
      endY,
      endX2 = 0,
      endY2 = 0,
      endX3 = 0,
      endY3 = 0,
      selectStep,
      multipleSelection,
      stopDragging,
      setMouseOverStep,
      selected,
      setMouseOverConnection,
      mouseOverStep,
      values,

      strokedash,
    } = this.props;
    const style = this.getStyle();
    const showConnectionPoints = (selected && !multipleSelection) || mouseOverStep;
    return (
      <g
        onMouseOver={setMouseOverStep}
        onMouseLeave={setMouseOverStep}
        onMouseDown={selectStep}
        onMouseUp={stopDragging}
        transform={`translate(${x},${y})`}
      >
        {/* <path
                    d={`M ${startX},${startY}  L ${endX},${endY} `}
                    style={style}
                    onMouseDown={this.onMouseDown}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    stroke={stroke}
                    strokeDasharray="5"
                    strokeWidth={strokeWidth}
                /> */}
        <polyline
          points={`${startX},${startY} ${endX2},${endY2} ${endX3},${endY3} ${endX - 15},${endY}`}
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 2,
          }}
          //   onMouseOver={this.onMouseOver}
          stroke={stroke}
          strokeDasharray={strokedash}
          markerEnd="url(#arrow)"
        />

        <text class="lineText" transform={`translate(${endX3 + 10},${endY3 - 5})`}>
          {values}
        </text>
        {/* {showConnectionPoints ? (
                    <ConnectionPoint
                        setMouseOverConnection={e => setMouseOverConnection(e, 'start')}
                        cx={startX} cy={startY}/>
                ) : null}
                {showConnectionPoints ? (
                    <ConnectionPoint
                        setMouseOverConnection={e => setMouseOverConnection(e, 'end')}
                        cx={endX} cy={endY}/>
                ) : null} */}
      </g>
    );
  }
}
