import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, workflows }) => {
				console.log("======flowcharts",workflows )
		return {
			user: user,
			navigation: navigation,
			workflows: workflows
		}
	}

	const mapDispatchToProps = {
		getWorkflows: Actions.getWorkflows,
		deleteWorkflow: Actions.deleteWorkflow,
    	clearWorkflow: Actions.clearWorkflow
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux