import React, { Component, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
	Grid, Box, Typography, AppBar, List, ListItem, ListItemText, ListItemIcon, ListItemAvatar,
	TextField, Select, Toolbar, CssBaseline, Avatar, Button, Popover, MenuItem, Icon
} from '@material-ui/core'
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';

import {
	Notifications, ExpandLess, ExpandMore, Home, Search
} from '@material-ui/icons';
import muskman from 'images/muskman.jpg';
import hamburger from 'images/hamburger.svg';
import disiloLogo from 'images/dsiloLogo.png';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	large: {
		width: "56px !important",
		height: "40px !important"
	},
	medium: {
		fontSize: "medium"
	}
}));
export default function Header(props) {
	const classes = useStyles();
	const { user, state } = props;

	useEffect(() => {
		if (props.dapp.appId && props.dapp.appId !== "new"
			&& props.dapp.appId !== 'addappconfig' && props.dapp.appId !== 'addappfiles'
			&& props.dapp.appId !== 'addappquickwizard' && props.dapp.appId === 'addapptemplates')
			if (props.dapp.data) {
				if (props.dapp.appId !== props.dapp.data._id)
					props.getDapp({ dappId: props.dapp.appId })
			} else
				props.getDapp({ dappId: props.dapp.appId })
	}, [props.dapp.appId, props.dapp.data])

	return (
		<div className="dashboardkeywordB-main">
			<div className={classes.root}>
				<CssBaseline />
				<AppBar className="appBar">
					<Toolbar className={classes.toolbar}>
						<Grid container className="view-helo">

							{/* <Grid item sm={6} className="flex pl-24">
								<Link to={`/builder/dapps`} style={{ cursor: "pointer" }}>
									<Typography component="div" className="flex">
										<img src={disiloLogo} alt="Logo Image" className="logo-img" />
									</Typography>
								</Link>
								{props.dapp.appId && props.dapp.data && props.dapp.data.title &&
									<Box component="label" className="keywordtxt">
										<Link to={`/builder/dapps/${props.dapp.appId}`}
											style={{ cursor: "pointer", color: "#000" }}>
											<Typography variant="h5">{props.dapp.data.title}</Typography>
										</Link>
									</Box>
								}
							</Grid> */}

							<Grid item xs={12} sm={3} md={6} className="flex headerbtm-sec pl-24">
								<Box px={2} style={{ paddingLeft: '0px' }}>
									{/* {<Link
										to={`/builder/dapps`}
										style={{ cursor: "pointer" }}>
										<Home className="houseicon" />
									</Link>} */}
									<Link to={`/builder/dapps`} style={{ cursor: "pointer" }}>
										<Typography component="div" className="flex">
											<img src={disiloLogo} alt="Logo Image" className="logo-img" />
										</Typography>
									</Link>
								</Box>
								{props.dapp.appId && props.dapp.data && props.dapp.data.title &&
									<Box component="label" className="keywordtxt">
										<Link to={`/builder/dapps/${props.dapp.appId}`}
											style={{ cursor: "pointer", color: "#000" }}>
											<Typography variant="h5">{props.dapp.data.title}</Typography>
										</Link>
									</Box>
								}
							</Grid>

							<Grid item sm={6} className="navmenu-bar pr-24">
								<Grid container direction="row" className="menuleft">
									<List component="nav" className="menu-nav">
										{/* <ListItem className="menulists border-right">
											<Box>
												<ListItemIcon>
													<Search className="notifyicon" />
												</ListItemIcon>
											</Box>
										</ListItem>
										<ListItem className="menulists border-right">
											<Box>
												<ListItemIcon>
													<Notifications className="notifyicon" />
												</ListItemIcon>
											</Box>
										</ListItem> */}
										<ListItem
											// className="menulists"
											// onClick={props.userMenuClick} 
											// style={{ cursor: "pointer" }}
											style={{ color: "#111", fontSize: "16px" }}
										>
											{/* <Box pr={1}>{user.displayName}</Box>
											<Box pr={1}> {user.role}, {user.orgId}</Box>
											{open ? <ExpandLess /> : <ExpandMore />} */}
											<div>
												<Typography component="span" style={{ display: "inline-flex" }}>
													<span style={{ whiteSpace: 'nowrap' }}>{user.email}</span>
												</Typography>

												<Typography color="textSecondary" style={{ display: "inline-flex" }}>
													<span style={{ whiteSpace: 'nowrap' }}>{user.orgName}</span>
													{/* {state.userMenu ? <ExpandLess /> : <ExpandMore />} */}
												</Typography>
											</div>
										</ListItem>

										<ListItem className="menulists">
											<Box>
												<ListItemAvatar>
													{user.photoURL
														? <Avatar alt="user photo" src={user.photoURL}
															className={classes.large} />
														: <AccountCircleIcon className={classes.large} />
													}
												</ListItemAvatar>
											</Box>
										</ListItem>
										<ListItem
											style={{ cursor: "pointer" }}
											onClick={() => {
												props.logout();
												props.userMenuClose();
											}}>
											<ListItemIcon title="logout">
												<ExitToAppOutlinedIcon alt="logout" fontSize="large" />
											</ListItemIcon>
										</ListItem>
										{/* <ListItem className="menulists">
											<Box>
												<img src={hamburger} alt="menu icon" className="hamburgerimg" />
											</Box>
										</ListItem> */}
									</List>
								</Grid>
							</Grid>

							{/* 
							to={`/builder/dapps`
												/*props.dapp.data && props.dapp.appId === props.dapp.data._id
												? `/builder/dapps/${props.dapp.appId}`
												: `/builder/dapps`*/}
							{/* <Grid container className="bdr-t">
								<Grid item xs={12} sm={3} md={5} className="flex headerbtm-sec pl-24">
									<Box px={2} style={{ paddingLeft: '0px' }}>
										{<Link
											to={`/builder/dapps`}
											style={{ cursor: "pointer" }}>
											<Home className="houseicon" />
										</Link>}
									</Box>
								</Grid>
							</Grid>


							 <Grid container className="bdr-t">
 								<Grid item xs={12} sm={3} md={5} className="flex headerbtm-sec pl-24">
 									<Box px={2} style={{ paddingLeft: '0px' }}>
 										{<Link
 											to={`/builder/dapps`}
 											style={{ cursor: "pointer" }}>
 											<Home className="houseicon" />
 										</Link>}
  
 									</Box>
 									{props.dapp.appId && props.dapp.data && props.dapp.data.title &&
 										<Box component="label" className="keywordtxt">
 											<Link to={`/builder/dapps/${props.dapp.appId}`}
 												style={{ cursor: "pointer", color: "#000" }}>
 												<Typography variant="h5">{props.dapp.data.title}</Typography>
 											</Link>
 										</Box>
 									}
 								</Grid>
 								<Grid item xs={12} sm={9} md={7} className="downmenu navmenu-bar pr-24">
 									<Grid container direction="row" className="menuleft">
 										<List component="nav" className="menu-nav">
 											<ListItem className="menulists">
 												<Box>
 													<TextField
 														id="outlined-basic"
 														type="text"
 														className="Addapp-txtfield"
 														variant="outlined"
 														placeholder="Enter a key Word"
 													/>
 												</Box>
 											</ListItem>
 											<ListItem className="menulists">
 												<Select
 													native
 													defaultValue=""
 													name="age"
 													className="Addapp-txtfield"
 													id="grouped-native-select"
 													variant="outlined"
 												>
 													<option value="category">Select Categories</option>
 													{props.dapps && props.dapps.categories &&
 														props.dapps.categories.map((item, index) => {
 															return (
 																<option key={index} value={item._id}>
 																	{item.Name}
 																</option>
 															);
 														})}
 												</Select>
 											</ListItem>
 											{props.location && props.location.pathname === "/builder/dapps"
 												&& <ListItem className="menulists">
 													<Button variant="contained" color="primary"
 														className="addnewappbtn"
 														onClick={props.handleAddNewAppClick}>
 														{"ADD NEW APP"}
 													</Button>
 												</ListItem>}
 										</List>
 										<Popover
 											open={Boolean(state.userMenu)}
 											anchorEl={state.userMenu}
 											onClose={props.userMenuClose}
 											anchorOrigin={{
 												vertical: 'bottom',
 												horizontal: 'center'
 											}}
 											transformOrigin={{
 												vertical: 'top',
 												horizontal: 'center'
 											}}
 											classes={{
 												paper: "py-8"
 											}}
 										>
 											{props.user.role === 'guest' ? (
 												<React.Fragment>
 													<MenuItem component={Link} to="/login">
 														<ListItemIcon>
 															<LockOutlinedIcon />
 														</ListItemIcon>
 														<ListItemText className="pl-0" primary="Login" />
 													</MenuItem>
 													<MenuItem component={Link} to="/register">
 														<ListItemIcon>
 															<PersonAddOutlinedIcon />
 														</ListItemIcon>
 														<ListItemText className="pl-0" primary="Register" />
 													</MenuItem>
 												</React.Fragment>
 											) : (
 													<React.Fragment>
 														<MenuItem component={Link} to="/pages/profile" onClick={props.userMenuClose}>
 															<ListItemIcon>
 																<AccountCircleOutlinedIcon />
 															</ListItemIcon>
 															<ListItemText className="pl-0" primary="My Profile" />
 														</MenuItem>
 														<MenuItem component={Link} to="/apps/mail" onClick={props.userMenuClose}>
 															<ListItemIcon>
 																<MailOutlinedIcon />
 															</ListItemIcon>
 															<ListItemText className="pl-0" primary="Inbox" />
 														</MenuItem>
 														<MenuItem
 															onClick={() => {
 																props.logout();
 																props.userMenuClose();
 															}}>
 															<ListItemIcon>
 																<ExitToAppOutlinedIcon />
 															</ListItemIcon>
 															<ListItemText className="pl-0" primary="Logout" />
 														</MenuItem>
 													</React.Fragment>
 												)}
 										</Popover>
 									</Grid>
 								</Grid>
 							</Grid> */}

						</Grid>
					</Toolbar>
				</AppBar>
			</div>
		</div>
	);
}
