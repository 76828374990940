export default {
	GET_ENTRYFORMS: undefined,
	GET_ENTRYFORMS_SUCCESS: undefined,
	GET_ENTRYFORMS_FAILURE: undefined,

	GET_ENTRYFORM: undefined,
	GET_ENTRYFORM_SUCCESS: undefined,
	GET_ENTRYFORM_FAILURE: undefined,

	SAVE_ENTRYFORM: undefined,
	SAVE_ENTRYFORM_SUCCESS: undefined,
	SAVE_ENTRYFORM_FAILURE: undefined,

	UPDATE_ENTRYFORM: undefined,
	UPDATE_ENTRYFORM_SUCCESS: undefined,
	UPDATE_ENTRYFORM_FAILURE: undefined,

	DELETE_ENTRYFORM: undefined,
	DELETE_ENTRYFORM_SUCCESS: undefined,
	DELETE_ENTRYFORM_FAILURE: undefined,

	EDIT_FORM: undefined,

	SAVE_FORM: undefined,

	DELETE_FORM: undefined,

	ALL_FORMS: undefined,

	FORM_TEMPLATES: undefined,
	FORM_TEMPLATES_SUCCESS: undefined,
	FORM_TEMPLATES_FAILURE: undefined,

	FORM_TO_SUBMIT: undefined,

	DELETE_TEMPLATE: undefined,

	DELETE_TEMPLATE_FORMDATA: undefined,

	SELECT_TEMPLATE: undefined,

	CLEAR_SELCTED_TEMPLATE: undefined,

	SUBMITTED_FORM_DATA: undefined,

	SUBMIT_DATA: undefined,

	SET_ENTRYFORMS_SEARCH_TEXT: undefined,

	UPDATE_DATAMODAL_PAGE: undefined,

	DRAGGED_ELEMENT: undefined,
	CLEAR_DRAGGED_ELEMENT: undefined,
	DRAGGED_ELEMENT_TYPE: undefined,
}