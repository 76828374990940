import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import photoone from '../../images/photoone.svg';
import oracleimg from '../../images/oracleimg.svg';
import microsoftsqlserver from '../../images/microsoftsqlserver.svg';
import netsuitelogo from '../../images/netsuitelogo.png';
import addnewimg from '../../images/addnewimg.svg';
import DCard from './DCard';

const HocCard = ({data, noOfComponents, titlePosition, direction, isImage, isText, width, handleNext}, props) => {
    {console.log("SelectAddDatasource", data)}
    return data && data.map((item, index) => {
        if(index >= noOfComponents) return;
        return <DCard  name={item.name} imgURL={item.logoId} text={item.description} titlePosition={titlePosition} direction={direction} isImage={isImage} isText={isText} width={width} handleNext={handleNext} />
    })
}
export default HocCard;