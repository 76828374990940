import React from 'react'
import {
	Button, RadioGroup, Checkbox, Input, MenuItem, Chip, Radio, Select, Box, Divider,
	FormControlLabel, Grid, TextareaAutosize, TextField, FormLabel, Typography, FormControl
} from '@material-ui/core'
import multisetting from '../../images/multisetting.png'
import Upload from './Upload'
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export default function EntityDetails(props) {
	const { form } = props.state;
	const { error } = props;
	let acceptedFiles = ['image/svg', 'image/gif', 'image/jpg', 'image/jpeg', 'image/png']
	return <Grid container spacing={2}>
		<Grid item sm={12} md={6}>
			<Grid container className="">
				<form className="form" noValidate autoComplete="off" >
					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Name *"}
						</FormLabel>
						<TextField
							error={form.name === ''}
							id="name"
							name="name"
							variant="outlined"
							color="secondary"
							className="form-control"
							placeholder="Name"
							value={form.name ? form.name : ''}
							onChange={props.handleUIChange}
							variant="outlined"
							fullWidth
							required
							autoFocus
							helperText={error.name && error.name}
							error={Boolean(error.name)}
						/>
					</FormControl>
					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Description"}
						</FormLabel>
						<TextField
							className="form-control"
							id="description"
							name="description"
							multiline
							rows={2}
							value={form.description ? form.description : ''}
							onChange={props.handleUIChange}
							variant="outlined"
							color="secondary"
							placeholder="Enter Description"
							fullWidth
						/>
					</FormControl>
					<Grid container>
						<Grid item xs={6}>
							<FormControl>
								{/* <FormLabel variant="h6" className="label-headings textblack" >
							Logo URL
                        </FormLabel>
						<TextField
							id="logoUrl"
							name="logoUrl"
							value={form.logoUrl}
							onChange={props.handleUIChange}
							variant="outlined"
							color="secondary"
							className="form-control"
							placeholder="Logo URL"
							type="text"
							variant="outlined"
							fullWidth
						/> */}

								<Typography className="text-16 sm:text-20 truncate fw-600">{"Upload Logo"}</Typography><br />
								<Upload handleFileChange={props.handleFileChange} type="logo"
									acceptedFiles={acceptedFiles}
									fileId={form.logoId}
								/>
								{form.logoId && <img
									style={{ marginTop: "20px" }}
									src={(form.logoId) ? `${API_BASE_URL}/logo/${form.logoId}` : ""} alt="" width="110px" />}
							</FormControl>
						</Grid>
						<Grid item xs={6} style={{display: 'flex'}}>
							<FormControlLabel name="currentOrganization" control={<Checkbox checked={form.currentOrganization || ""}
							 onChange={props.handleUIChange} />} label="Current organization" />
						</Grid>
					</Grid>
					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Industry *"}
						</FormLabel>
						<TextField
							id="industry"
							name="industry"
							value={form.industry ? form.industry : ''}
							onChange={props.handleUIChange}
							variant="outlined"
							color="secondary"
							className="form-control"
							placeholder="Industry"
							variant="outlined"
							fullWidth
							required
							helperText={error.industry && error.industry}
							error={Boolean(error.industry)}
							autoFocus={!!error.industry}
						/>
					</FormControl>

					<FormControl>
						<FormLabel variant="h6" className="label-headings">
							{"Sub Industry"}
						</FormLabel>
						<TextField
							id="quantity"
							name="quantity"
							value={form.quantity ? form.quantity : ''}
							onChange={props.handleUIChange}
							variant="outlined"
							color="secondary"
							className="form-control"
							placeholder="Sub Industry"
							variant="outlined"
							type="text"
							fullWidth
						/>
					</FormControl>
				</form>
			</Grid>
		</Grid>
		<Grid item sm={12} md={6}>
			<img src={multisetting} alt="multi setting image" className="img-fluid" />
		</Grid>
	</Grid>
}