import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';


import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import Grid from '@material-ui/core/Grid';

export default function CollageType2(props) {
	return (
		<div>
			<Grid container alignItems="center" >
				{props.groupingData.map((gData,i) => {
					return (<React.Fragment key={i}>
						<p style={{
							textAlign: "center"
						}}>
							<ListItemText
								primary={gData[props.selectedGroupingDataElement[0].value]}
								secondary={gData[props.selectedDataElement[0].value]} />
						</p>
						<Divider orientation="vertical" flexItem />
					</React.Fragment>)
				})}
			</Grid>
		</div>

	);
}