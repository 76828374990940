import React, { useEffect } from 'react';
import Redux from './redux'
import { hot } from 'react-hot-loader/root'
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import photoone from '../../images/photoone.svg';
import oracleimg from '../../images/oracleimg.svg';
import microsoftsqlserver from '../../images/microsoftsqlserver.svg';
import netsuitelogo from '../../images/netsuitelogo.png';
import addnewimg from '../../images/addnewimg.svg';
import rightarrow from '../../images/rightarrow.svg';
import SelectAddDatasource from './selectadddatasource';
import ConnectionInfo from './connectioninfo';
import SelectTableCollection from './selecttablecollection'
import SelectColumnFields from './selectcolumnfields'
import SelectPipelines from './selectpipelines'
import Results from './results'


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function getSteps() {
  return [
    'Select or Add Data Source',
    'Enter Connection Information',
    'Select Table/Collection',
    'Select Columns/Fields',
    'Select Pipelines',
    'Results'
  ];
}

const Container = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    const params = props.match.params;
    const { dappId } = params;
    props.getPipelines(dappId);
    props.getDatasourceTypes();
  }, []);


  return (
    <Box className="abcCompany-page">
      <Box className="pxy20">
        <Grid container>
          <Box className="processtab-sec">
            <Grid item xs={12}>
              <div className="backbtn">
                <img src={rightarrow} alt="backicon" className="backicon" />
                <Box component="h3" className="companyheadingtext">
                  <Typography variant="h4">Datasource</Typography>
                </Box>
              </div>
              <div className="progresstabs">
                <Paper>
                  <div className={classes.root}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map(label => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                </Paper>
              </div>
            </Grid>
            <Grid container>
              <Grid xs={12} alignItems="right">
                <List component="nav" className="abcdownlists">
                  <ListItem className="menulists">
                    <Box>
                      <TextField
                        id="outlined-basic"
                        type="text"
                        className="Addapp-txtfield"
                        variant="outlined"
                        placeholder="Enter a key Word"
                      />
                    </Box>
                  </ListItem>
                  <ListItem className="menulists">
                    <Select
                      native
                      defaultValue=""
                      name="age"
                      className="Addapp-txtfield"
                      id="grouped-native-select"
                      variant="outlined"
                    >
                      <option value="category">Select Category</option>

                      <option value={1}>Option 1</option>
                      <option value={2}>Option 2</option>

                      <option value={3}>Option 3</option>
                      <option value={4}>Option 4</option>
                    </Select>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            {activeStep === 0 &&
              <SelectAddDatasource {...props} handleNext={handleNext} handleBack={handleBack} />
            }
            {activeStep === 1 &&
              <ConnectionInfo {...props} handleNext={handleNext} handleBack={handleBack} />
            }
            {activeStep === 2 &&
              <SelectTableCollection {...props} handleNext={handleNext} handleBack={handleBack} />
            }
            {activeStep === 3 &&
              <SelectColumnFields {...props} handleNext={handleNext} handleBack={handleBack} />
            }
            {activeStep === 4 &&
              <SelectPipelines {...props} handleNext={handleNext} handleBack={handleBack} />
            }
            {activeStep === 5 &&
              <Results {...props} handleNext={handleNext} handleBack={handleBack} />
            }
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default hot(withRouter(Redux(Container)))
