import { ActionTypes } from 'constants/index'

const initialState = {
	data: [],
	loading: false,
	error: '',
};

const privilegesReducer = function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_PRIVILEGES_SUCCESS:
			{
				return {
					...state,
					data: action.payload
				};
			}
		case ActionTypes.GET_PRIVILEGES_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error
				};
			}
		default:
			{
				return state;
			}
	}
};

export default privilegesReducer;
