import { ActionTypes } from 'constants/index'

const initialState = {
	data: null,
	appId: undefined,
	loading: false,
	error: {
		appCode: false,
		title: false,
		message: "",
		status: ""
	},
	files: {},
	saveSuccess: false,
	updateSuccess: false,
	deleteSuccess: false
};

const dappReducer = function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_DAPP_SUCCESS:
			{
				return {
					...state,
					data: action.payload,
					appId: action.payload._id,
					saveSuccess: false,
					updateSuccess: false,
					deleteSuccess: false
				};
			}
		case ActionTypes.GET_DAPP_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error
				};
			}
		case ActionTypes.SAVE_DAPP_SUCCESS:
			{
				return {
					...state,
					data: action.payload,
					saveSuccess: true
				};
			}
		case ActionTypes.SAVE_DAPP_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error
				};
			}
		case ActionTypes.UPDATE_DAPP_SUCCESS:
			{
				return {
					...state,
					data: action.payload,
					updateSuccess: true
				};
			}
		case ActionTypes.UPDATE_DAPP_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error
				};
			}
		case ActionTypes.UPDATE_CURRENT_DAPPID:
			{
				return {
					...state,
					appId: action.payload
				};
			}
		case ActionTypes.UPDATE_CURRENT_DAPP:
			{
				return {
					...state,
					app: action.payload
				};
			}
		case ActionTypes.GET_DAPP_FILES:
			{
				return {
					...state,
					files: action.payload
				};
			}
		case ActionTypes.DELETE_DAPPS_SUCCESS:
			{
				return {
					...state,
					app: action.payload,
					deleteSuccess: true
				};
			}
		case ActionTypes.DELETE_DAPPS_FAILURE:
			{
				return {
					...state,
					app: action.payload,
					deleteSuccess: false
				};
			}

		default:
			{
				return state;
			}
	}
};

export default dappReducer;