import { ActionTypes } from 'constants/index';

const initialState = {
	state: null,
	options: {
		anchorOrigin: {
			vertical: 'top',
			horizontal: 'center'
		},
		autoHideDuration: 6000,
		message: "Hi",
		variant: null
	}
};

const message = function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SHOW_MESSAGE:
			{
				return {
					state: true,
					options: {
						...initialState.options,
						...action.options
					},
					message: action.message
				};
			}
		case ActionTypes.HIDE_MESSAGE:
			{
				return {
					...state,
					message: null,
					state: null
				};
			}
		default:
			{
				return state;
			}
	}
};

export default message;
