import Utils from 'packages/page/utils'
import { ActionTypes } from 'constants/index'

export const getEntryForms = (appId) => dispatch => {
	return dispatch({ type: ActionTypes.GET_ENTRYFORMS, payload: appId })
}

export const setEntryFormsSearchText = (value) => dispatch => {
	return dispatch({ type: ActionTypes.SET_ENTRYFORMS_SEARCH_TEXT, searchText: value })
}


export const getEntryForm = (params) => dispatch => {
	return dispatch({ type: ActionTypes.GET_ENTRYFORM, payload: params })
	// return dispatch({
	//     type: ActionTypes.EDIT_FORM,
	//     payload: response.data
	// })
}

export const saveEntryForm = (data) => dispatch => {
	return dispatch({ type: ActionTypes.SAVE_ENTRYFORM, payload: data })
}

export const updateEntryForm = (data) => dispatch => {
	return dispatch({ type: ActionTypes.UPDATE_ENTRYFORM, payload: data })
}

export const deleteEntryForm = (data) => dispatch => {
	return dispatch({ type: ActionTypes.DELETE_ENTRYFORM, payload: data })
}

export const newEntryForm = () => dispatch => {
	const data = {
		id: Utils.generateGUID(),
		name: '',
		handle: '',
		description: '',
		entryFormType: '',
		active: true
	}
	return dispatch({ type: ActionTypes.GET_ENTRYFORM_SUCCESS, payload: null })
}

export const clearSelectedForm = () => dispatch => {
	dispatch({ type: ActionTypes.EDIT_FORM, payload: null })
}

export const selectedFormForEditing = (data) => dispatch => {
	dispatch({ type: ActionTypes.EDIT_FORM, payload: data })
}

export const clearSelectedTemplate = () => dispatch => {
	dispatch({ type: ActionTypes.CLEAR_SELCTED_TEMPLATE, payload: null })
}

export const clearSaveMessage = () => dispatch => {
	dispatch({ type: ActionTypes.SAVE_FORM, payload: null })
}

export const draggedElement = (data) => dispatch => {
	dispatch({ type: ActionTypes.DRAGGED_ELEMENT, payload: data })
}
export const selectTemplate = (data) => dispatch => {
	dispatch({ type: ActionTypes.SELECT_TEMPLATE, payload: data })
}

export const clearDraggedElement = () => dispatch => {
	dispatch({ type: ActionTypes.CLEAR_DRAGGED_ELEMENT, payload: null })
}

export const draggedNewInputType = (data) => dispatch => {
	dispatch({ type: ActionTypes.DRAGGED_ELEMENT_TYPE, payload: data })
}

export const saveForm = (data, appId) => dispatch => {
	dispatch({ type: ActionTypes.SAVE_FORM, payload: { data, appId } })
}

export const deleteForm = (data) => dispatch => {
	dispatch({ type: ActionTypes.DELETE_FORM, payload: data })
}

export const deleteTemplate = (data) => dispatch => {
	dispatch({ type: ActionTypes.DELETE_TEMPLATE, payload: data })
}

export const deleteFormData = (data) => dispatch => {
	dispatch({ type: ActionTypes.DELETE_TEMPLATE_FORMDATA, payload: data })
}

export const getForms = (appId) => dispatch => {
	dispatch({ type: ActionTypes.ALL_FORMS, payload: { appId } })
}

export const getTemplates = (appId) => dispatch => {
	dispatch({ type: ActionTypes.FORM_TEMPLATES, payload: { appId } })
}

export const getEntryFormById = (appId) => dispatch => {
	dispatch({ type: ActionTypes.FORM_TO_SUBMIT, payload: { appId } })
}

export const getSubmittedData = (appId) => dispatch => {
	dispatch({ type: ActionTypes.SUBMITTED_FORM_DATA, payload: { appId } })
}

export const editForm = (data) => dispatch => {
	dispatch({ type: ActionTypes.SAVE_FORM, payload: data })
}

export const updateDataModalAndPageId = (form, dataModalId, pageId, appId) => {
	dispatch({ type: ActionTypes.UPDATE_DATAMODAL_PAGE, payload: { form, dataModalId, pageId, appId } })
}

export const saveSubmittedData = (data, files) => {
	dispatch({ type: ActionTypes.SUBMIT_DATA, payload: { data, files } })
}