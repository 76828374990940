import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
	FormControl, Button, Chip, Grid, Typography, makeStyles, Box, AppBar, Tabs, Tab,
	Divider, FormLabel
} from '@material-ui/core'
import Loading from 'components/Loading'
import 'styles/styles.scss'
import MemberDetails from './MemberDetails'
import { TabPanel } from '../@tabpanel/tabpanel'
import rightarrow from 'images/rightarrow.svg'
import ChipSelect from 'components/@custom/ChipSelect'

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	error: {
		color: '#f44336',
		marginBottom: 0
	}
}))

const View = (props) => {
	const classes = useStyles()

	const { form, roles } = props.state
	const { error, tabValue, setTabValue } = props

	const handleChange = (event, newValue) => {
		setTabValue(newValue)
	};

	console.log("form.groups ===", form.groups);

	return (<>
		<Box className="quickwizard-page">
			<Grid container>
				<Grid item xs={12} md={6}>
					<div className="backbtn">
						<div className="backbtn ptl-20">
							<Link to={`/builder/${props.match.params.dappId}/members`}
								style={{ textDecoration: 'none', color: "#1B2A47" }}>
								<img src={rightarrow} alt="backicon" className="backicon" />
								<Box className="companyheadingtext">
									<Typography variant="h4">{"Members List"}</Typography>
								</Box>
							</Link>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} md={6} className="savebtn-sec">
					{props.match.params.memberId === 'new'
						? <Button
							className="addnewentrybtn mb5 whitespace-no-wrap"
							variant="contained"
							color="primary"
							disabled={!props.canBeSubmitted()}
							onClick={() => props.saveMember()}>
							{"Save"}
						</Button>
						: <Button
							className="whitespace-no-wrap addnewentrybtn mb5"
							variant="contained"
							color="primary"
							disabled={!props.canBeSubmitted()}
							onClick={() => props.updateMember()}>
							{"Update"}
						</Button>
					}
				</Grid>
			</Grid>
			<Box className="pxy20">
				<div className={classes.root} >
					<Box px="1.5rem">
						<AppBar position="static" className="bg-white">
							<Tabs value={tabValue} onChange={handleChange}
								aria-label="simple tabs example" className="quicktabs-sec">
								<Tab label="Basic Info" {...a11yProps(0)} />
								<Tab label="Security & Access" {...a11yProps(1)} />
							</Tabs>
							<Divider />
						</AppBar>
						<TabPanel value={tabValue} index={0}>
							<MemberDetails {...props} classes={classes} />
						</TabPanel>
						<TabPanel value={tabValue} index={1}>
							<Grid container>
								<Grid item sm={12}>
									<form className="form" noValidate autoComplete="off">
										<FormControl>
											<FormLabel variant="h6" className="label-headings">
												{"Select Groups"}
											</FormLabel>
											<ChipSelect
												className="mt-8 mb-16"
												value={form.groups ? form.groups : []}
												onChange={(v) => props.handleChipChange(v, 'groups')}
												placeholder="Select multiple groups"
												// autoFocus={!!error.selectedRole}
												textFieldProps={{
													InputLabelProps: {
														shrink: true
													},
													variant: 'outlined'
												}}
												options={props.groups.map((group) => ({
													value: group._id,
													label: group.name
												}))}
												isMulti
											/>
											{error?.groups && <p className={classes.error}>{error?.groups}</p>}
										</FormControl>
										<FormControl>
											<FormLabel variant="h6" className="label-headings">
												{"Select user roles"}
											</FormLabel>
											<ChipSelect
												className="mt-8 mb-16"
												value={form.selectedRoles ? form.selectedRoles : []}
												onChange={(v) => props.handleChipChange(v, 'selectedRoles')}
												placeholder="Select multiple tags"
												autoFocus={!!error.selectedRole}
												textFieldProps={{
													InputLabelProps: {
														shrink: true
													},
													variant: 'outlined'
												}}
												options={roles ? roles : []}
												isMulti
											/>
											{error?.selectedRole && <p className={classes.error}>{error?.selectedRole}</p>}
										</FormControl><FormControl>
											<FormLabel variant="h6" className="label-headings">
												{"Admin Access Control"}
											</FormLabel>
											<ChipSelect
												className="mt-8 mb-16"
												value={form.admin_roles}
												onChange={(v) => props.handleChipChange(v, 'admin_roles')}
												placeholder="Select multiple tags"
												textFieldProps={{
													InputLabelProps: {
														shrink: true
													},
													variant: 'outlined'
												}}
												options={roles?.map(item => ({
													value: item.value,
													label: item.label
												}))}
												isMulti
											/>
											{/* <ChipSelect
												className="w-full my-16"
												value={values.barYAxis}
												onChange={(v) => props.handleChipDataChange(v, 'barYAxis')}
												placeholder="Select column for Y-Axis"
												textFieldProps={{
													InputLabelProps: {
														shrink: true
													},
													variant: 'standard'
												}}
												options={values.dataElements}
											/> */}
											{/*<Select name="Roles" displayEmpty className="form-control" id="grouped-native-select" variant="outlined" multiple
												onChange={(e) => props.handleChipChange(e, 'selectedRoles')} value={form.selectedRoles}
												input={<Input />}
												renderValue={(selected) => (
													<div className={classes.chips}>
														{selected && selected.map((item) => (
															<Chip key={item.value} label={item.label} className={classes.chip} />
														))}
													</div>
														)}>
												{roles && roles.map((item, i) => (
													<MenuItem key={item.value} value={item}>
														{item.label}
													</MenuItem>
												))}
											</Select>*/}
										</FormControl>
										<FormControl>
											<FormLabel variant="h6" className="label-headings">
												{"Editor Access Control"}
											</FormLabel>
											<ChipSelect
												className="mt-8 mb-16"
												value={form.editor_roles}
												options={roles?.map(item => ({
													value: item.value,
													label: item.label
												}))}
												onChange={(v) => props.handleChipChange(v, 'editor_roles')}
												placeholder="Select multiple tags"
												textFieldProps={{
													InputLabelProps: {
														shrink: true
													},
													variant: 'outlined'
												}}
												isMulti
											/>
										</FormControl>
										<FormControl>
											<FormLabel variant="h6" className="label-headings">
												{"Reader Access Control"}
											</FormLabel>
											<ChipSelect
												className="mt-8 mb-16"
												value={form.reader_roles}
												options={roles?.map(item => ({
													value: item.value,
													label: item.label
												}))}
												onChange={(v) => props.handleChipChange(v, 'reader_roles')}
												placeholder="Select multiple tags"
												textFieldProps={{
													InputLabelProps: {
														shrink: true
													},
													variant: 'outlined'
												}}
												isMulti
											/>
										</FormControl>
									</form>
								</Grid>
							</Grid>
						</TabPanel>
					</Box>
				</div>
			</Box>
		</Box>
	</>)
}

export default View
