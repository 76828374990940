import { ActionTypes } from 'constants/index'

export const getMembers = (appId) => dispatch => {
	return dispatch({ type: ActionTypes.GET_MEMBERS, payload: appId })
}

export const getMember = (params) => dispatch => {
	return dispatch({ type: ActionTypes.GET_MEMBER, payload: params })
}

export const getMemberByUserId = (params) => dispatch => {
	return dispatch({ type: ActionTypes.GET_MEMBER_BY_USERID, payload: params })
}

export const resetMemberByUserId = (params) => dispatch => {
	return dispatch({ type: ActionTypes.RESET_MEMBER_BY_USERID, payload: params })
}

export const saveMember = (data) => dispatch => {
	return dispatch({ type: ActionTypes.SAVE_MEMBER, payload: data })
}

export const updateMember = (data) => dispatch => {
	return dispatch({ type: ActionTypes.UPDATE_MEMBER, payload: data })
}

export const deleteMember = (id) => dispatch => {
	return dispatch({ type: ActionTypes.DELETE_MEMBER, payload: id })
}

export const disableMember = (id) => dispatch => {
	return dispatch({ type: ActionTypes.DISABLE_MEMBER, payload: id })
}

export const newMember = () => dispatch => {
	const data = {
		firstName: "",
		lastName: "",
		email: "",
		entity: {},
		contact: "",
		status: "active",
		selectedRoles: []
	}
	return dispatch({ type: ActionTypes.GET_MEMBER_SUCCESS, payload: data })
}