import React from 'react';
import { withStyles, Button, Tab, Tabs, TableHead, TableRow, TextField, InputAdornment, Icon, Select, MenuItem, Typography, FormControl, FormGroup, Checkbox, RadioGroup, FormControlLabel, Radio, FormLabel, Table, TableCell, TableBody, Input, Chip } from '@material-ui/core';
import DateAndTimePickers from '../@datetimepicker/dateAndTimePickers';
const jobTypes = [{
    'label': 'Data',
    'value': 'data'
},
{
    'label': 'Process',
    'value': 'process'
},
{
    'label': 'Reconciliation',
    'value': 'reconciliation'
}
]
export function JobDetails(props) {
    const { form } = props.state;
    const { classes } = props;
    console.log("JobDetails", props);
    return (
        form && (
            <div className="p-16 sm:p-24 max-w-2xl">
                <form className="form" noValidate autoComplete="off" >
                    <FormControl>
                        <FormLabel variant="h6" className="label-headings">
                            Name *
                        </FormLabel>
                        <TextField
                            className="form-control"
                            error={form.name === ''}
                            autoFocus
                            id="name"
                            name="name"
                            value={form.name}
                            onChange={props.handleChange}
                            variant="outlined"
                            color="secondary"
                            placeholder="Enter Name"
                            required
                            fullWidth
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel variant="h6" className="label-headings">
                            Description
                        </FormLabel>
                        <TextField
                            className="form-control"
                            id="description"
                            name="description"
                            multiline
                            rows={2}
                            value={form.description}
                            onChange={props.handleChange}
                            variant="outlined"
                            color="secondary"
                            placeholder="Enter Description"
                            required
                            fullWidth
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel variant="h6" className="label-headings">
                            Select Job Type *
                        </FormLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={form.type ? form.type : ""}
                            onChange={props.handleChange}
                            name="type">
                            <MenuItem key={0} value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                jobTypes && jobTypes.map((item, i) => {
                                    return <MenuItem key={i + 1} value={item.value}>{item.label}</MenuItem>

                                })
                            }
                        </Select>
                    </FormControl>

                    <FormControl>
                        <FormLabel variant="h6" className="label-headings">
                            Frequency *
                        </FormLabel>
                        <RadioGroup
                            aria-label="Frequency"
                            name="frequency"
                            className={classes.group}
                            value={form.frequency}
                            onChange={props.handleChange}
                            row
                        >
                            <FormControlLabel value="oneTime" control={<Radio />} label="One time" />
                            <FormControlLabel value="repeat" control={<Radio />} label="Repeat" />
                        </RadioGroup>
                    </FormControl>
                    {form.frequency === "repeat" && <FormControl>
                        <FormLabel variant="h6" className="label-headings">
                            Repeat Type
                        </FormLabel>
                        <RadioGroup
                            aria-label="Frequency"
                            name="repeatType"
                            className={classes.group}
                            value={form.repeatType}
                            onChange={props.handleChange}
                            row
                        >
                            <FormControlLabel value="daily" control={<Radio />} label="Daily" />
                            <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
                            <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                            <FormControlLabel value="quartely" control={<Radio />} label="Quarterly" />
                            <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
                        </RadioGroup>
                    </FormControl>}


                    <FormControl>
                        <div style={{ display: 'flex' }}>
                            <DateAndTimePickers
                                label="Start Date Time"
                                name="startDateTime"
                                defaultValue={form.startDateTime}
                                value={form.startDateTime}
                                onChange={props.handleChange}
                            ></DateAndTimePickers>

                            <DateAndTimePickers
                                label="End Date Time"
                                name="endDateTime"
                                defaultValue={form.endDateTime}
                                value={form.endDateTime}
                                onChange={props.handleChange}
                            ></DateAndTimePickers>
                        </div>
                    </FormControl>
                </form>
            </div>
        )
    )
}