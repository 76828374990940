import { ActionTypes } from 'constants/index';

const initialState = {
    data: [],
    error: "",
    searchText: "",
    deleteSuccess: false,
    dataModalVisible: false
};

const formulasReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_FORMULAS_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                deleteSuccess: false
            };
        }
        case ActionTypes.GET_FORMULAS_FAILURE:
            {
                return {
                    ...state,
                    loading: false,
                    error: action.error
                };
            }
        case ActionTypes.DELETE_FORMULA_SUCCESS: {
            return { ...state,
                loading: false,
                data: [...state.data.filter(item => item._id !== action.payload.result.data._id)],
                deleteSuccess: true 
            }
        }
        case ActionTypes.DELETE_FORMULA_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false
            }
        }
        default: {
            return state;
        }
    }
};

export default formulasReducer;
