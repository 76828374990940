import { hot } from 'react-hot-loader/root'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Router, Switch, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import styled, { css, ThemeProvider } from 'styled-components'
import treeChanges from 'tree-changes'

import history from 'modules/history'
import theme, { headerHeight } from 'modules/theme'
import { utils } from 'styled-minimal'

import config from 'config'
import { showAlert } from 'actions'

import Header from 'components/Header'
import SystemAlerts from 'components/SystemAlerts'

import Footer from 'components/Footer'
import GlobalStyles from 'components/GlobalStyles'

import { Auth } from 'components/auth'

import Sidenav from './components/SideNav'
import AppRoutes from './AppRoutes'
import Authorization from './components/@custom/Authorization'
import Head from './components/CustomPage/header'
import Nav from './components/CustomPage/nav'
import Wrapper from './components/Wrapper'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import CacheBuster from './CacheBuster';
import PreAppLoading from './components/Loading/PreAppLoading';

import metaJson from '../assets/meta.json';

global.appVersion = metaJson.version;
global.GITHASH = metaJson.hash;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	}
}))

function m(props) {
	const classes = useStyles()
	//TODO: All pages without sidenav bar
	let pathname = props.location.pathname
	return pathname !== '/' && pathname !== '/login'
		&& pathname !== '/builder' && pathname !== '/builder/dapps'
		&& pathname !== '/builder/dapps/addappconfig'
		&& pathname !== '/builder/dapps/addappfiles'
		&& pathname !== '/builder/dapps/addapptemplates'
		? <div className={classes.root}>
			{props.children(pathname)}
		</div>
		: <>{props.children(pathname)}</>
}

const Main = withRouter(m)

export class App extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		user: PropTypes.object.isRequired,
	}

	componentDidUpdate(prevProps) {
		const { dispatch } = this.props
		const { changedTo } = treeChanges(prevProps, this.props)

		// TODO: Need to make it dynamic
		// if (changedTo('user.isAuthenticated', true)) {
		// 	dispatch(showAlert('Hello! And welcome!', { variant: 'success', icon: 'bell' }))
		// }
	}

	render() {
		const { dispatch, user, categories, roles } = this.props

		return (<CacheBuster global={global}>
			{({ loading, isLatestVersion, refreshCacheAndReload }) => {
				if (loading) return <PreAppLoading />;
				console.log('loading, isLatestVersion', loading, isLatestVersion);
				if (!loading && !isLatestVersion) {
					// You can decide how and when you want to force reload
					refreshCacheAndReload();
					return <PreAppLoading />
				}
				console.log('loading, isLatestVersion', loading, isLatestVersion);
				return <>
			<Auth>
				<Router history={history}>
					<Authorization roles={roles}>
						<ThemeProvider theme={theme}>
							<Helmet
								defer={false}
								htmlAttributes={{ lang: 'pt-br' }}
								encodeSpecialCharacters={true}
								defaultTitle={config.name}
								titleTemplate={`%s | ${config.name}`}
								titleAttributes={{ itemprop: 'name', lang: 'pt-br' }}
							/>
							<Main>
								{pathname => (<>
									{pathname !== '/' && pathname !== '/login'
										&& <>
											<Header dispatch={dispatch} user={user} categories={categories} />
											{pathname !== '/builder' && pathname !== '/builder/dapps'
												&& pathname !== '/builder/dapps/addappconfig'
												&& pathname !== '/builder/dapps/addappfiles'
												&& pathname !== '/builder/dapps/addapptemplates'
												&& <Sidenav />}
										</>}
									<AppRoutes user={user} />
								</>)}
							</Main>
							{window.location.pathname !== '/'
								&& window.location.pathname !== '/login' && <Footer />}
							<SystemAlerts />
							<GlobalStyles />
						</ThemeProvider>
					</Authorization>
				</Router>
			</Auth>
			</>}}
		</CacheBuster>);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
		categories: state.dapps.categories,
	};
}

export default hot(connect(mapStateToProps)(App));
