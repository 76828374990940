/**
 * Configuration
 * @module config
 */

const config = {
  name: 'DSilo - Decentralized Platform for Enterprises',
  description: 'DSilo - Decentralized Platform for Enterprises',
};

export default config;
