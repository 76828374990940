import {
	generateURL,
	generateCardBody,
} from 'packages/page/page.module'
import axios from 'axios/index'
import * as Actions from 'actions'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export function getChartDataFromApi(data, conditions, appId) {
	let targetURL = ''
	let { chartData, chartType } = data;
	if (chartData.selectedDataModel && chartData.selectedDataElement
		&& chartData.selectedDataLabels) {
		targetURL = generateURL(chartData, chartType, conditions)
	} else if (chartData.dataUrl) {
		targetURL = chartData.dataUrl
	}
	if (targetURL.trim()) {
		let chartDataPromise = new Promise((resolve, reject) => {
			axios.get(targetURL, { headers: { 'appId': appId } })
				.then(response => {
					resolve({ data: response.data, error: null })
				})
				.catch(() => {
					resolve({ error: Actions.chartDisplayError })
				});
		});
		return chartDataPromise
	} else {
		if (data.chartType === 'card') {
			chartData.body = generateCardBody(chartData.metric, chartData.data)
		}
		return { data: chartData.data }
	}
}

export function getChartDataFromDataModel(data) {
	if (data.chartData.selectedDataModel.trim()) {
		let chartDataPromise = new Promise((resolve, reject) => {
			axios
				.get(API_BASE_URL + "/dataconnect/" + data.chartData.selectedDataModel)
				.then(response => {
					resolve({ data: data.chartData.data, error: null })
				})
				.catch(() => {
					resolve({ error: Actions.chartDisplayError })
				});
		});
		return chartDataPromise
	} else {
		return { data: data.chartData.data }
	}
}

export function getApiDataFromDataUrl(dataUrl, appId) {
	return axios.get(dataUrl, { headers: { 'appId': appId } })
	// return request(dataUrl, {
	// 	method: 'GET',
	// 	headers: { "appId": appId }
	// });
}