import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './view'
import Redux from './redux'
import { withRouter } from 'react-router-dom'

const initialState = {
	tabValue: 0,
	form: { datasourceRoles: '', datasourceGroups: '', overrideSecurity: false, overrideConnection: false },
	isSubmitButton: true,
	roles: []
}

const Container = (props) => {

	const [state, setState] = useState(initialState)
	const [error, setError] = useState({ name: false, dataType: false })

	const handleChange = (event) => setState({
		...state,
		form: _.set({ ...state.form }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value)
	})

	const updateDatasourceState = () => props.match.params.datasourceId === 'new' ? props.newDatasource() : props.getDatasource(props.match.params)

	const updateFormState = () => setState({ ...state, form: { ...state.form, ...props.datasource.data } })

	const handleChipChange = (event, name) => setState({ ...state, form: _.set({ ...state.form }, name, event.target.value) })

	const canBeSubmitted = () => {
		// const { name, dsType } = state.form
		// return name && name.length > 0 && dsType && dsType.length > 0 && !_.isEqual(props.datasource.data, state.form) && state.isSubmitButton
		return true;
	}

	useEffect(() => {
		if (!_.isEqual(props.datasource.data, state.form)) {
			setState({ ...state, isSubmitButton: true })
		}
	}, [state.form])

	const saveDatasourceData = () => {
		const { name, dsType } = state.form;
		if (!name || name.length === 0) {
			setError({ ...error, name: "Please enter name" })
			return;
		}
		if (!dsType || dsType.length === 0) {
			setError({ ...error, dsType: "Please select data type" })
			return;
		}
		setState({ ...state, isSubmitButton: false })
		props.saveDatasource({ ...state.form, appId: props.match.params.dappId })
	}

	const updateDatasourceData = () => {
		setState({ ...state, isSubmitButton: false })
		props.updateDatasource({ ...state.form, appId: props.match.params.dappId })
	}

	// const updateFilePath = (name, value) => {
	// 	setState({ ...state, form: _.set({ ...state.form }, name, value) })
	// }

	const handleFileChange = (file) => {
		console.log("file", file);
		if (!state.form.prevFilePaths)
			state.form.prevFilePaths = []
		if (state.form.filePath)
			setState({ ...state, form: { ...state.form, prevFilePaths: [...state.form.prevFilePaths, state.form.filePath] } })

		state.form.newFile = true
		setState({ ...state, form: { ...state.form, 'filePath': file.path } })
	}

	useEffect(() => {
		updateDatasourceState()
		props.getRoles(props.match.params.dappId);
	}, [props.location])

	useEffect(() => {
		updateFormState()
	}, [props.datasource.data])

	useEffect(() => {
		setState({
			...state,
			roles: props.roles && props.roles.data && props.roles.data.map(role => {
				return {
					value: role._id,
					label: role.name
				}
			})
		})
	}, [props.roles.data]);

	useEffect(() => {
		if (props.datasource.saveSuccess) {
			props.showAlert('Datasource saved successfully', { id: "datasource", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/datasources/${props.datasource.data._id}` })
		}
		if (props.datasource.updateSuccess) {
			props.showAlert('Datasource updated successfully', { id: "datasource", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/datasources/${props.datasource.data._id}` })
		}
	}, [props.datasource.saveSuccess, props.datasource.updateSuccess])

	return (<View
		{...props}
		state={state}
		erorr={error}
		handleChange={handleChange}
		handleChipChange={handleChipChange}
		canBeSubmitted={canBeSubmitted}
		saveDatasource={() => saveDatasourceData()}
		updateDatasource={() => updateDatasourceData()}
		// updateFilePath={() => updateFilePath()}
		handleFileChange={handleFileChange}
	/>)
}

export default hot(withRouter(Redux(Container)))
