import React from 'react'
import {
    Button, Checkbox, FormControl, FormControlLabel, FormLabel, NativeSelect, Radio,
    RadioGroup, Tab, Tabs, TextField, Typography, withStyles, Select, MenuItem, Input,
    AppBar, Dialog, DialogActions, DialogTitle, Grid
} from '@material-ui/core'
import ConditionRequired from './ConditionRequired'

const Visibility = (props) => {
    const { classes, changeOperator1, changeConditionalRequiredValue1, removeConditionalRequired } = props;
    const { role, showWhenWorkflowTask } = props.elementsData.data.data

    return (<>
        <Grid item xs={12}>
            <Typography variant={'h6'}>Show when workflow task</Typography>
        </Grid>
        <Grid container>
            <Grid item xs={12}>
                <NativeSelect
                    onChange={(e) => props.setData("showWhenWorkflowTask", e.target.value)}
                >
                    <option value="" selected disabled>Select Elements</option>
                    {props.workflow && props.workflow.tasks && props.workflow.tasks.map((element) => {
                        return <option value={element.attr.id} 
                        selected={element.attr.id == showWhenWorkflowTask}>{element.attr.name}</option>
                    })}
                </NativeSelect>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Typography variant={'h6'}>Show when current role</Typography>
        </Grid>
        <Grid container>
            <Grid item xs={12}>
                <NativeSelect
                    onChange={(e) => props.setData("role", e.target.value)}
                >
                    <option value="" selected disabled>Select Role</option>
                    {props.basicInfo && props.basicInfo.accessRoles
                        && props.basicInfo.accessRoles.map((element) => {
                            return <option value={element._id}
                                selected={element._id === role} >{element.name}</option>
                        })}
                </NativeSelect>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Typography variant={'h6'}>Show</Typography>
        </Grid>
        <Grid container>
            <Grid item xs={3}>
                <NativeSelect
                    onChange={props.changeSelectElement}
                >
                    <option value="" selected disabled>Select Elements</option>
                    {props.formElements && props.formElements.map((element) => {
                        return <option value={element.item}  >{element.item}</option>
                    })}
                </NativeSelect>
            </Grid>
            <Grid item xs={3}>
                <NativeSelect
                    onChange={props.changeOperator}
                >
                    <option value="" selected disabled>Select Operator</option>
                    <option value=">">{">"}</option>
                    <option value="<">{"<"}</option>
                    <option value="==">{"=="}</option>
                    <option value="!=">{"!="}</option>
                </NativeSelect>
            </Grid>
            <Grid item xs={2}>
                <FormControl>
                    <TextField margin="dense"
                        variant="filled"
                        onChange={props.changeConditionalRequiredValue}
                        className={classes.textConditionalField}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <NativeSelect
                    onChange={props.changeClause}
                >
                    <option value="" selected >{"Select clause"}</option>
                    <option value={"&&"}>{"AND"}</option>
                    <option value="||">{"OR"}</option>

                </NativeSelect>
            </Grid>
            <Grid item xs={1}>
                <Button variant="contained" color="secondary"
                    onClick={(e) => { props.pushVisibilityRequired() }} className={classes.button}>
                    {"+"}
                </Button>
            </Grid>
        </Grid>
        {
            props.clause && (
                <Grid container>
                    <Grid item xs={3}>
                        <NativeSelect
                            onChange={props.changeSelectElement1}
                        >
                            <option value="" selected disabled>Select Elements</option>
                            {props.formElements && props.formElements.map((element) => {
                                return <option value={element.item}  >{element.item}</option>
                            })}
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={3}>
                        <NativeSelect
                            onChange={changeOperator1}
                        >
                            <option value="" selected disabled>Select Operator</option>
                            <option value=">">{">"}</option>
                            <option value="<">{"<"}</option>
                            <option value="==">{"=="}</option>
                            <option value="!=">{"!="}</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <TextField margin="dense"
                                variant="filled"
                                onChange={changeConditionalRequiredValue1}
                                className={classes.textConditionalField}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }

        <Grid item xs={12}>
            <Typography variant={'h6'}>Hide and show form elements based on workflow tasks</Typography>
        </Grid>
        <Grid container>
            <Grid item xs={3}>
                <NativeSelect
                    onChange={props.changePanelSelectEvent}
                >
                    <option value="" selected disabled>Select Event</option>
                    {props.workflow.events && props.workflow.events.map((element) => {
                        return <option value={element.name} >{element.name}</option>
                    })}
                </NativeSelect>
            </Grid>
            <Grid item xs={3}>
                <NativeSelect
                    onChange={props.changePanelSelectElement}
                >
                    <option value="" selected disabled>Select Element</option>
                    {props.elementsData.data && props.elementsData.data.data
                        && Array.isArray(props.elementsData.data.data.values)
                        && props.elementsData.data.data.values.map((element) => {
                            return <option value={element.label} >{element.value}</option>
                        })}
                </NativeSelect>
            </Grid>
            <Grid item xs={6}>
                <Button variant="contained" color="secondary"
                    onClick={(e) => { props.pushPanelVisibilityRequired() }} className={classes.button}>
                    {"+"}
                </Button>
            </Grid>
            {props.generatePanelVisibility(props.elementsData.data.data.panelVisibility)}

        </Grid>


        <Grid container spacing={8}>
            <Grid item xs={12}>
                <Typography variant={'h6'}>Current Conditions Required for visibility:</Typography>
            </Grid>

            <ConditionRequired
                conditions={props.elementsData.visibility}
                variableName={"visibility"}
                removeConditionalRequired={removeConditionalRequired}
            />
            {/* {props.generateConditionRequired(props.elementsData.visibility, "visibility")} */}
        </Grid>

    </>
    )
}

export default Visibility