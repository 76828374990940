import React from 'react'
import { Box, Grid, Button, Typography, makeStyles } from '@material-ui/core'
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	control: {
		padding: theme.spacing(2),
	},
}))

const DataElementDND = (props) => {
	const classes = useStyles();
	const [elements, setElements] = React.useState([]);
	const [selectedElements, setSelectedElements] = React.useState([]);

	const onDragStart = (ev, id) => {
		ev.dataTransfer.setData("id", id);
	}

	const onDragOver = (ev) => {
		ev.preventDefault();
	}

	const onDrop = (ev, cat) => {
		let id = ev.dataTransfer.getData("id");

		let matchEle = selectedElements.filter((task) => {
			return task._id === id
		});
		if (cat === 'selected' && matchEle.length === 0) {
			let ele = elements.filter((task) => {
				return task._id === id
			});
			// setElements(props.elements)
			// setElements1([...selectedElements, ...ele])
			props.handleElements([...selectedElements, ...ele])
		}
		else if (cat === 'all') {
			let selectedEle = selectedElements.filter((task) => {
				return task._id !== id
			});
			props.handleElements([...selectedEle])
		}
	}

	useEffect(() => {
		console.log('props.elements', props.elements);
		setElements(props.elements)
	}, [props.elements])

	useEffect(() => {
		console.log('props.selectedElements', props.selectedElements);
		setSelectedElements(props.selectedElements)
	}, [props.selectedElements])

	return (<Grid>
		<Grid item xs={12} className="my5">
			<Box className="dataelement-container">
				<Grid container spacing={2}>
					<Grid item sm={6} spacing={2}>
						<Typography component="div" className="dataelementbox wip"
							onDragOver={(e) => onDragOver(e)}
							onDrop={(e) => onDrop(e, "all")}>
							<Typography className="mb3" variant="h5">{props.listName1 ? PaymentResponse.listName1 : 'List 1'}</Typography>
							{elements.map(t => {
								console.log("t ==>", t);
								return <Button key={t._id}
									variant="contained"
									onDragStart={(e) => onDragStart(e, t._id)}
									draggable
									className="draggable dataelementbtn">
									{t.name}
								</Button>
							})}
						</Typography>

					</Grid>
					<Grid item sm={6} spacing={2}>
						<Typography component="div" className="dataelementbox droppable"
							onDragOver={(e) => onDragOver(e)}
							onDrop={(e) => onDrop(e, "selected")}>
							<Typography className="mb3" variant="h5">{props.listName2 ? PaymentResponse.listName2 : 'List 2'}</Typography>
							{selectedElements.map(t => {
								console.log("t ==>", t);
								return <Button key={t._id}
									variant="contained"
									onDragStart={(e) => onDragStart(e, t._id)}
									draggable
									className="draggable dataelementbtn">
									{t.name}
								</Button>
							})}
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Grid>
	</Grid>);
}
export default DataElementDND