import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './view'
import Redux from './redux'
import { withRouter } from 'react-router-dom';
import { WorkflowsService } from 'services/index'

import history from 'modules/history'

const Container = (props) => {
	
	const [state, setState] = useState({
		tabValue: 0,
		form: { name: '' },
		selected: [],
		selectedDataElement: "",
		isSubmitButton: true,
		diagram: null,
		workflowId: null
	});


	useEffect(() => {
		const { params } = props.match;
		const { dappId } = params;
		props.getNotifications(dappId);
		if (params.id) {
			props.getWorkflow(params)
		} else {	
			props.clearWorkflow()
		}
	}, [])

	useEffect(() => {
		if (props.workflows.workflowSaveSuccess) {
			history.push({ pathname: `/builder/${props.match.params.dappId}/workflows` });
		}
	}, [props.workflows.workflowSaveSuccess])


	useEffect(() => {
		if (props.workflow) {
			const { name, description, diagram, _id } = props.workflow
			setState({
				...state,
				form: { name, description },
				diagram: JSON.parse(diagram),
				workflowId: _id
			})	
		}
	}, [props.workflow])
	

	// const saveWorkflowData = () => {
	// 	setState({ ...state, isSubmitButton: false });
	// 	props.saveJob({ ...state.form, appId: props.match.params.dappId });
	// }

	const isValid = () => {
    const { form, diagram } = state;
		if(!form.name){
      alert("Please enter workflow name")
      return false
		} else if (!form.description){
      alert("Plese enter workflow description")
      return false
		} else if(!diagram){
      alert("Plese enter workflow ")
      return false
    }
    return true
	}

	const handleChange = (event) => {
		setState({ ...state, form: _.set({ ...state.form }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value) });
	};

	const handleDiagramChange = (diagram) => {
		setState({ ...state, diagram})
	}

	const saveWorkflow = () => {
		if (isValid()) {
			const { params } = props.match;
			const { form, diagram, workflowId } = state;
			let workflow = {
				name: form.name,
				description: form.description,
				appId: params.dappId, 
				diagram: JSON.stringify(diagram),
				workflowId,
				type:'workflow'
      		}
			if (params.id) {
				props.updateWorkflow(workflow);
			} else {
				props.onSaveWorkflow(workflow);
			}
    }
	}

	return (
		<View
			{...props}
			state={state}
			handleChange={handleChange}
			diagramChange={handleDiagramChange}
			saveWorkflow={() => saveWorkflow()}
			workflowId={state.workflowId}
		/>
	)
}

export default hot(withRouter(Redux(Container)))