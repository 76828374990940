import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Actions from 'actions';

const Redux = PassedComponent => {
  const mapStateToProps = ({ user, navigation, members, roles, group }) => {
    return {
      user: user,
      // navigation: navigation,
      members: members,
      roles: roles,
      group: group,
    };
  };

  const mapDispatchToProps = {
    getMembers: Actions.getMembers,
    getRoles: Actions.getRoles,
    saveGroup: Actions.saveGroup,
    getGroup: Actions.getGroup,
    updateGroup: Actions.updateGroup,
    newGroup: Actions.newGroup,
    showAlert: Actions.showAlert,
    hideAlert: Actions.hideAlert,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(({ ...props }) => <PassedComponent {...props} />);
};

export default Redux;
