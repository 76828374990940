import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { DataModelService } from 'services/index'
import { ActionTypes } from 'constants/index'


async function fetchAsync(func, arg) {
	const response = arg ? await func(arg) : await func()
	return response
}

function* getDataModels(action) {
	try {
		console.log("getDataModels", action);
		const dataModels = yield call(fetchAsync, DataModelService.getDataModels, action.payload)
		yield put({ type: ActionTypes.GET_DATAMODELS_SUCCESS, payload: dataModels })
	} catch (e) {
		yield put({ type: ActionTypes.GET_DATAMODELS_FAILURE, error: e.data })
	}
}

function* getDataModel(action) {
	try {
		const dataModel = yield call(fetchAsync, DataModelService.getDataModel, action.payload)
		yield put({ type: ActionTypes.GET_DATAMODEL_SUCCESS, payload: dataModel })
	} catch (e) {
		yield put({ type: ActionTypes.GET_DATAMODEL_FAILURE, error: e.data })
	}
}

function* saveDataModel(action) {
	try {
		const dataModel = yield call(fetchAsync, DataModelService.saveDataModel, action.payload)
		yield put({ type: ActionTypes.SAVE_DATAMODEL_SUCCESS, payload: dataModel })
	} catch (e) {
		yield put({ type: ActionTypes.SAVE_DATAMODEL_FAILURE, error: e.data })
	}
}

function* updateDataModel(action) {
	try {
		console.log("updateDataModel = action.payload", action.payload);
		const dataModel = yield call(fetchAsync, DataModelService.updateDataModel, action.payload)
		yield put({ type: ActionTypes.UPDATE_DATAMODEL_SUCCESS, payload: dataModel })
	} catch (e) {
		yield put({ type: ActionTypes.UPDATE_DATAMODEL_FAILURE, error: e.data })
	}
}

function* deleteDataModel(action) {
	try {
		const dataModel = yield call(fetchAsync, DataModelService.deleteDataModel, action.payload)
		yield put({ type: ActionTypes.DELETE_DATAMODEL_SUCCESS, payload: dataModel })
	} catch (e) {
		yield put({ type: ActionTypes.DELETE_DATAMODEL_FAILURE, error: e.data })
	}
}

function* deleteDataModels(action) {
	try {
		const dataModel = yield call(fetchAsync, DataModelService.deleteDataModels, action.payload)
		yield put({ type: ActionTypes.DELETE_DATAMODELS_SUCCESS, payload: dataModel })
	} catch (e) {
		yield put({ type: ActionTypes.DELETE_DATAMODELS_FAILURE, error: e.data })
	}
}

function* getDataModelsByOrgId(action) {
	try {
		const dataModel = yield call(fetchAsync, DataModelService.getDataModelsByOrgId, action.payload)
		yield put({ type: ActionTypes.GET_ORG_DATAMODELS_SUCCESS, payload: dataModel })
	} catch (e) {
		yield put({ type: ActionTypes.GET_ORG_DATAMODELS_FAILURE, error: e.data })
	}
}

function* getCollectionsFromDatasource(action) {
	try {
		const collections = yield call(fetchAsync, DataModelService.getCollectionsFromDatasource, action.payload)
		console.log("")
		yield put({ type: ActionTypes.GET_COLLECTIONS_FROM_DATASOURCE_SUCCESS, payload: collections })
	} catch (e) {
		yield put({ type: ActionTypes.GET_COLLECTIONS_FROM_DATASOURCE_FAILURE, error: e.response })
	}
}

export function* watchDataModelsSaga() {
	yield takeEvery(ActionTypes.GET_DATAMODELS, getDataModels)
	yield takeEvery(ActionTypes.GET_DATAMODEL, getDataModel)
	yield takeEvery(ActionTypes.SAVE_DATAMODEL, saveDataModel)
	yield takeEvery(ActionTypes.UPDATE_DATAMODEL, updateDataModel)
	yield takeEvery(ActionTypes.DELETE_DATAMODEL, deleteDataModel)
	yield takeEvery(ActionTypes.DELETE_DATAMODELS, deleteDataModels)
	yield takeEvery(ActionTypes.GET_ORG_DATAMODELS, getDataModelsByOrgId)
	yield takeEvery(ActionTypes.GET_COLLECTIONS_FROM_DATASOURCE, getCollectionsFromDatasource)
	
}

export default watchDataModelsSaga