import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, job }) => {
		console.log("job", job);
		return {
			user: user,
			navigation: navigation,
			job: job
		}
	}

	const mapDispatchToProps = {
		getJob: Actions.getJob,
		saveJob: Actions.saveJob,
		newJob: Actions.newJob,
		updateJob: Actions.updateJob,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux