import React, { Component, Fragment } from 'react'
import { InputElement } from '../CommomElement'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import 'styles/page-designer.css'
import * as Actions from 'actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import ComponentList from './ComponentList'

const styles = theme => ({
	arrowIcon: {
		height: 20,
		width: 14
	},
	arrowColor: {
		color: '#ffd700'
	}
});

class ComponentListPane extends Component {
	toggleChartListComponent = event => {
		this.props.toggleChartListComponent(!this.props.showListComponent);
	};

	render() {
		console.log("this.props.showListComponent", this.props.showListComponent);
		return (<div className="componentListPane">
			{ this.props.showListComponent
				? <div className="componentListPaneExpanded">
					<div style={{
						height: "49px",
						background: "#091834",
						opacity: 1
					}} >
					<div className="componentListPaneHeader" style={{ float: 'left' }}>Components</div>
						<div style={{ float: 'right', position: "relative", top: "-8px" }}>
							<IconButton
								className={this.props.classes.arrowColor}
								aria-label="Expand/Collapse"
								onClick={() => this.toggleChartListComponent()}
							>
								<ArrowBackIosIcon
									className={this.props.classes.arrowIcon}
								/>
								{/* <ArrowBackIosIcon
								className={this.props.classes.arrowIcon}
							/> */}
							</IconButton>
						</div>
					</div>
					{/* <InputElement
						className="searchComponentInput"
						placeHolder="search"
					/> */}
					<ComponentList columns={2} />
				</div>
				: <div className="componentListPaneCollapsed">
					<div style={{
						height: "49px",
						background: "#091834",
						opacity: 1
					}}>
						<IconButton
							className={this.props.classes.arrowColor}
							aria-label="Expand/Collapse"
							onClick={() => this.toggleChartListComponent()}
						>
							<ArrowForwardIosIcon
								className={this.props.classes.arrowIcon}
							/>
							{/* <ArrowForwardIosIcon
							className={this.props.classes.arrowIcon}
						/> */}
						</IconButton>
					</div>
					<ComponentList />
					{/* <ComponentList /> */}
				</div>
			}
		</div>)
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			toggleChartListComponent: Actions.toggleChartListComponent
		},
		dispatch
	);
}

function mapStateToProps({ pages }) {
	return {
		showListComponent: pages.page.showListComponent
	};
}

export default withStyles(styles, { withTheme: true })(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ComponentListPane)
);
