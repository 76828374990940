import { ActionTypes } from 'constants/index';

const initialState = {
	data: [],
	loading: false,
	error: '',
	current: {},
	searchText: '',
	deleteSuccess: false
};

export default function entityReducer(state = initialState, action) {

	switch (action.type) {
		case ActionTypes.GET_ENTITIES_SUCCESS: {
			return {
				...state,
				data: action.payload,
				loading: false,
				deleteSuccess: false
			}
		}
		case ActionTypes.GET_ENTITIES_FAILURE: {
			return {
				...state,
				error: action.error,
				loading: false
			}
		}
		case ActionTypes.DELETE_ENTITY_SUCCESS: {
			return { ...state,
				loading: false,
				data: [...state.data.filter(item => item._id !== action.payload)],
				deleteSuccess: true 
			}
		}
		case ActionTypes.DELETE_ENTITY_FAILURE: {
			return {
				...state,
				error: action.error,
				loading: false
			}
		}

		default: {
			return state;
		}
	}
}