import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { formulaSuggestions } from './formulasList.json';
import { createStyles, makeStyles } from '@material-ui/styles';
import { FormControl, FormLabel, FormControlLabel, TextField, RadioGroup, Radio } from '@material-ui/core';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';


const mentionDefaultStyle = {
    control: {
        fontSize: 14,
        fontWeight: 'normal',
        backgroundColor: '#fff',
    },
    '&multiLine': {
        control: {
            fontFamily: 'monospace',
            minHeight: 63,
        },
        highlighter: {
            padding: 9,
            border: '1px solid transparent',
        },
        input: {
            padding: 9,
            border: '1px solid silver',
        },
    },
    suggestions: {
        list: {
            bottom: 14,
            fontSize: 14,
            maxHeight: 300,
            overflow: 'auto',
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
        },
        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',
            '&focused': {
                backgroundColor: '#cee4e5',
            },
        },
    }
}

const useStyles = makeStyles((theme) =>
    createStyles({
        radioRoot: {
            display: 'flex',
            flexDirection: 'row'
        },
        typeCon: {
            marginBottom: '10px'
        },
        formulaError: {
            color: '#f44336 !important',
            margin: '3px 14px 0 14px',
            fontSize: '14px'
        },
        rules: {
            margin: "1.5rem 0 0 0 !important"
        }
    }),
);

export default function FormulaBuilder({ type, formula, onTypeChange,  onFormulaChange, error, fields, rules, handleRulesChange }) {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = React.useState(false);
    const isJSFormula = type === "javascript";
    const fieldLabel = isJSFormula ? "Type '@' for fields" : "Type '=' for Formulas and '@' for Fields"

    const handleTypeChange = (_ , value, confirmed = false) => {
        if (!confirmed && (value === "excel" && formula.length > 0)) {
            setOpenDialog(true);
            return false;
        }
        
        if (confirmed)
        setOpenDialog(false);
        onTypeChange(value);
    };

    const handleChange = (_, __, value) => {
        onFormulaChange(value);
    }

    const renderType = () => {
        return (
            <FormControl component="fieldset" classes={{ root: classes.typeCon }}>
                <RadioGroup classes={{ root: classes.radioRoot }} aria-label="gender" name="gender1" value={type} onChange={handleTypeChange}>
                    <FormControlLabel value="javascript" control={<Radio />} label="Javascript" />
                    <FormControlLabel value="excel" control={<Radio />} label="Excel" />
                </RadioGroup>
            </FormControl>
        );
    }

    const renderRulesInput = () => {
        return (
            <form className="form" noValidate autoComplete="off">
                <FormControl className={classes.rules}>
                    <FormLabel variant="h6" className="label-headings">
                        {"Context"}
                    </FormLabel>
                    <TextField
                        fullWidth
                        id="rules"
                        name="rules"
                        value={rules}
                        color="secondary"
                        variant="outlined"
                        className="form-control"
                        placeholder="Enter context for formula"
                        onChange={({ target }) => handleRulesChange(target.value)}
                    />
                </FormControl>
            </form>
        )
    }

    const renderDialog = () => {
        return (
            <Dialog
                open={openDialog}
                onClose={()=>setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Switch to Excel !?"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Switching from Javascript to Excel will reset the formula and formula will be lost !
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>setOpenDialog(false)} color="primary">
                    Close
                </Button>
                <Button onClick={()=>handleTypeChange(null, "excel", true)} color="primary" autoFocus>
                    Switch
                </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const renderFormulaError = () => {
        return (
            <p className={classes.formulaError}>
                {error.formula}
            </p>
        )
    }
    return (
        <div className="p-16 sm:p-24 max-w-2xl">
            {renderType()}
            <MentionsInput
                value={formula}
                onChange={handleChange}
                placeholder={fieldLabel}
                style={mentionDefaultStyle}>
                <Mention
                    trigger="="
                    data={isJSFormula ? [] : formulaSuggestions}
                />
                <Mention
                    trigger="@"
                    data={fields}
                />
            </MentionsInput>
            {error.formula ? renderFormulaError() : ""}
            {renderDialog()}
            {renderRulesInput()}
        </div>
    );
}
