import React from 'react';
import Arrow from './Arrow';
import Step from './Step';
import Decision from './Decision';
import Process from './Process';
import Terminator from './Terminator';
import Start from './Start';
import End from './End';
import Line from './Line';
import OptionalLine from './OptionalLine';
import Pool from './Pool';
import '../style/canvasStyle.css';

const component = {
  decision: Decision,
  process: Process,
  terminator: Terminator,
  start: Start,
  end: End,
  line: Line,
  optionalline: OptionalLine,
  pool: Pool,
};

class Canvas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      diagram,
      selected,
      selectStep,
      stopDragging,
      arrow,
      mouseOverConnection,
      setMouseOverStep,
      mouseOverStep,
      setMouseOverConnection,
      onClickSugessionElement,
      setClickStep,
      clickStep,
      onCanvasClick,
      editElementStep,
      onDragConnection,
      moveArrow,
    } = this.props;

    const { height, steps, style, width } = diagram;
    const multipleSelection = Object.keys(selected).length > 1;
    return (
      <svg
        id="svg"
        height="100%"
        width="100%"
        style={style}
        className="canvasScroll"
        overflow="auto"
      >
        {/* <div style={{ overflowY: 'scroll', height: '100%', width: '100%' }}> */}
          <defs>
            <marker
              id="arrow"
              markerWidth="10"
              markerHeight="10"
              refX="0"
              refY="3"
              orient="auto"
              markerUnits="strokeWidth"
            >
              <path d="M0,0 L0,6 L9,3 z" fill={Step.defaultProps.style.stroke} />
            </marker>
          </defs>
          <rect
            id="svg"
            width="100%"
            height="100%"
            fill="white"
            onClick={onCanvasClick}
            className="canvasScroll"
          />

          {steps.map((step, i) => {
            const { id, parentId, type } = step;
            const Step = component[type];

            return (
              <React.Fragment>
                <Step
                  key={i}
                  setClickStep={e => setClickStep(e, id, type)}
                  editElementStep={() => editElementStep(id)}
                  moveArrow={e => moveArrow(e, type)}
                  onDragConnection={(e, type) => onDragConnection(e, type, id)}
                  clickStep={clickStep[id]}
                  setMouseOverConnection={setMouseOverConnection(id)}
                  mouseOverConnection={mouseOverConnection[id]}
                  setMouseOverStep={setMouseOverStep(id)}
                  mouseOverStep={mouseOverStep[id]}
                  multipleSelection={multipleSelection}
                  selected={selected[id]}
                  selectStep={selectStep(id, parentId, type)}
                  stopDragging={stopDragging}
                  onClickSugessionElement={type => {
                    onClickSugessionElement(step, id, type);
                  }}
                  {...step}
                ></Step>
                {step.inputConnectors.map((c, i) => (
                  <Arrow key={i} connector={c} />
                ))}

                {arrow && <Arrow connector={arrow} />}
              </React.Fragment>
            );
          })}
        {/* </div> */}
      </svg>
    );
  }
}

export default Canvas;
