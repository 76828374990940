import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { hot } from 'react-hot-loader/root'
import View from './view'
import Redux from './redux'
import Utils from './utils'
import ConfirmDialog from '../confirmDialog'

const Container = (props) => {

	const [state, setState] = useState({
		order: 'asc',
		orderBy: null,
		selected: [],
		deleteEntitiesData: "",
		page: 0,
		rowsPerPage: 10,
		deleteOpen: false,
		data: props.entities?.data
	});

	const [open, setOpen] = React.useState(false);
	const [entity, setEntity] = React.useState({});

	useEffect(() => {
		props.getEntities(props.match.params.dappId)
	}, [])

	useEffect(() => {
		let data = Utils.getFilteredArray(props.entities?.data, props.searchText)
		setState({ ...state, data })
	}, [props.entities?.data, props.searchText])

	useEffect(() => {
		let data = Utils.getFilteredArray(props.entities?.data, props.searchText)
		setState({ ...state, data })
	}, [props.entities?.data, props.searchText])

	const handleRequestSort = (event, property) => {
		const orderBy = property
		let order = 'desc'

		if (state.orderBy === property && state.order === 'desc') {
			order = 'asc'
		}

		setState({
			...state,
			order,
			orderBy
		})
	}

	const handleSelectAllClick = event => {
		if (event.target.checked) {
			setState({
				...state,
				selected: state.data.map(n => n.entityId)
			})
			return
		}
		setState({ ...state, selected: [] })
	}

	const handleClick = (item) => props.history.push(`/appbuilder/${props.match.params.dappId}/entities/` + item.entityId)

	const handleAddClick = () => props.history.push({ pathname: `/builder/${props.match.params.dappId}/entities/new` })

	const handleCheck = (event, entityId) => {
		const { selected } = state
		const selectedIndex = selected.indexOf(entityId)
		let newSelected = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, entityId)
		}
		else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		}
		else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		}
		else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			)
		}

		setState({ ...state, selected: newSelected })
	}

	const handleChangePage = (event, page) => {
		setState({ ...state, page })
	}

	const handleChangeRowsPerPage = event => {
		setState({ ...state, rowsPerPage: event.target.value })
	}

	const isSelected = entityId => state.selected.indexOf(entityId) !== -1

	const deleteEntities = () => {
		let entityData = ''
		state.selected.map((item, index) => {
			state.data.map((entity) => {
				if (entity.entityId === item) {
					entityData = index === state.selected.length - 1
						? entityData + entity.name
						: entityData + entity.name + ", "
				}
			})
		})
		setState({ ...state, deleteOpen: true, deleteEntitiesData: entityData })
	}

	const handleDeleteDialog = () => {
		setState({ ...state, deleteOpen: false })
	}

	// const onConfirmDelete = (entity) => {
	// 	setState({ ...state, deleteOpen: false, deleteEntitiesData: "" })
	// 	props.deletEntity(state.selected).then((data) => {
	// 		props.getEntities(props.match.params.dappId)
	// 	})
	// }

	useEffect(() => {
		if (props.entity.updateSuccess) {
			props.getEntities(props.match.params.dappId);
			props.getEntity({ appId: props.match.params.dappId, id: entity._id })
		}
	}, [props.entity.updateSuccess])

	useEffect(() => {
		if (props.entities.deleteSuccess) {
			props.showAlert('Entity deleted successfully', { id: "entity", variant: 'success', icon: 'bell' });
		}
	}, [props.entities.deleteSuccess])

	const handleActionsClick = (action, entity) => {
		if (action === 'edit') {
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/entities/${entity.entityId}` });
		}
		if (action === 'delete') {
			setEntity(entity);
			setOpen(true);
		}
		if (action === 'inactive') {
			let obj = { ...entity };
			if (obj.status === "Active") {
				obj.status = "Inactive"
			} else {
				obj.status = "Active"
			}
			setEntity(obj);
			props.updateEntity(obj, props.user, props.match.params.dappId);
		}
	}
	return (
		<>
			<View
				getFilteredArray={Utils.getFilteredArray}
				handleDeleteDialog={handleDeleteDialog}
				deleteEntities={deleteEntities}
				isSelected={isSelected}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				handleChangePage={handleChangePage}
				handleCheck={handleCheck}
				handleClick={handleClick}
				handleAddClick={handleAddClick}
				handleSelectAllClick={handleSelectAllClick}
				handleRequestSort={handleRequestSort}
				handleActionsClick={handleActionsClick}
				{...props}
				{...state}
			/>
			<ConfirmDialog
				title="Delete"
				open={open}
				setOpen={setOpen}
				onConfirm={() => props.deletEntity(entity, props.match.params.dappId)}>
				entity {entity.name}
			</ConfirmDialog>
		</>
	)
}

export default hot(withRouter(Redux(Container)))