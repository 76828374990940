import Decision from './Decision';
import UserTask from './Process';
import BusinessTask from './Process';
import SendTask from './Process';
import WorkflowTask from './Process';
import SystemTask from './Process';
import DataPipelineTask from './Process';
import Start from './Start';
import End from './End';
import Pool from './Pool';
import Line from './Line';
import OptionalLine from './OptionalLine';
import Terminator from './Terminator';

export default {
  Start,
  End,
  Decision,
  Pool,
  UserTask,
  SendTask,
  SystemTask,
  BusinessTask,
  WorkflowTask,
  DataPipelineTask,
  // Terminator,,
  // OptionalLine,
  // Line,
};
