import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
	FormControl, InputLabel, Checkbox, FormControlLabel, FormLabel, TextField, Button, Chip,
	Paper, Grid, Typography, makeStyles, Box, AppBar, Tabs, Tab, Divider, Select, Input, MenuItem
} from '@material-ui/core'
import Loading from 'components/Loading'
import 'styles/styles.scss'
import RoleDetails from './RoleDetails';
import { TabPanel } from '../@tabpanel/tabpanel';
import rightarrow from 'images/rightarrow.svg'

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	}
}));

const View = (props) => {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<React.Fragment>
			<Box className="quickwizard-page">
				<Grid container>
					<Grid item xs={12} md={6}>
						<div className="backbtn">
							<div className="backbtn ptl-20">
								<Link to={`/builder/${props.match.params.dappId}/roles`}
									style={{ textDecoration: 'none', color: "#1B2A47" }}>
									<img src={rightarrow} alt="backicon" className="backicon" />
									<Box className="companyheadingtext">
										<Typography variant="h4">{"Roles List"}</Typography>
									</Box>
								</Link>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} md={6} className="savebtn-sec">
						{props.match.params.roleId === 'new'
							? <Button
								variant="contained" color="primary"
								className="addnewentrybtn mb5 whitespace-no-wrap"
								// disabled={!props.canBeSubmitted()}
								onClick={() => props.saveRole()}>
								{"Save"}
							</Button>
							: <Button
								variant="contained" color="primary"
								className="addnewentrybtn mb5 whitespace-no-wrap"
								// disabled={!props.canBeSubmitted()}
								onClick={() => props.updateRole()}>
								{"Update"}
							</Button>
						}
					</Grid>
				</Grid>
				<Box className="pxy20">
					<div className={classes.root} >
						<Box px="1.5rem">
							<AppBar position="static" className="bg-white">
								<Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className="quicktabs-sec">
									<Tab label="Basic Info" {...a11yProps(0)} />
								</Tabs>
								<Divider />
							</AppBar>
							<TabPanel value={value} index={0}>
								<RoleDetails {...props} classes={classes} />
							</TabPanel>
						</Box>
					</div>
				</Box>
			</Box>
		</React.Fragment>
	)
}

export default View