import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import _ from 'modules/lodash'
import {
	Button, Tab, Tabs, Typography, Box, Select, Input, MenuItem, AppBar, Grid,
	withStyles
} from '@material-ui/core'
import rightarrow from 'images/rightarrow.svg'
import { TabPanel } from 'components/@tabpanel/tabpanel'
import axios from 'axios'
import WorkflowsRenderer from './WorkflowsRenderer'
import * as Actions from 'actions'
import FormBuilder from './FormBuilder/index'
import EntryFormDetails from './EntryFormDetails'
import styles from './styles/EntryFormStyle'
import defaultState from './data/entryForm'
import generatePageData from './data/pageData'
import Utils from './utils/Utils'
import Redux from '../redux'
// import 'styles/styles.scss'

const APPRENDERER_URL = process.env.REACT_APP_APPRENDERER_URL
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const formNameValidation = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

class EntryForm extends Component {
	state = { ...defaultState, isSaveDisabled: false };

	savePossible() {
		this.setState({ savePossible: true })
	}
	static getDerivedStateFromProps(props, state) {
		let selectedForm = JSON.parse(JSON.stringify(props.selectedForm));
		if (!selectedForm || !selectedForm.name) {
			return null
		}
		if (state.editForm) {
			return null
		}
		let design = {
			rows: []
		}
		let modalData = {
			rows: []
		}
		let form = { ...state.form, name: selectedForm.name, description: selectedForm.description }
		try {
			if (selectedForm.workflowId) {
				if (!state.form.workflowId) {
					form = { ...form, workflowId: selectedForm.workflowId }
				}
			}
			if (selectedForm.readRoles && !state.form.readRoles.length) {
				form = { ...form, readRoles: selectedForm.readRoles }
			}
			if (selectedForm.createRoles && !state.form.createRoles.length) {
				form = { ...form, createRoles: selectedForm.createRoles }
			}
			if (selectedForm.deleteRoles && !state.form.deleteRoles.length) {
				form = { ...form, deleteRoles: selectedForm.deleteRoles }
			}
			if (selectedForm.design) {
				// design = data.selectedForm.design
				// modalData = data.selectedForm.modalData
			}
		} catch (error) {
			console.log("in - getDerivedStateFromProps Error - END");
		}
		console.log("in - getDerivedStateFromProps - END", 	selectedForm.isApiForm
		);
		return {
			form,
			// design,
			// modalData
		}
	}
	componentDidMount() {
		console.log("in - componentDidMount ");
		this.updateEntryFormState();
		this.props.getTemplates(this.props.match.params.dappId);
		const { dapp } = this.props
		if (dapp && dapp._id) {
			this.props.getDatasourceByName(dapp.title, dapp._id) // TODO:
		} else {
			this.props.getDapp({ dappId: this.props.match.params.dappId })
		}
		this.props.getRoles(this.props.match.params.dappId);
		console.log("in - componentDidMount end");
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		console.log("in - componentDidUpdate ");
		if (!_.isEqual(this.props.location, prevProps.location)) {
			this.updateEntryFormState();
		}
		if (
			(this.props.entryForm && !this.state.form) ||
			(this.props.entryForm && this.state.form && this.props.entryForm._id !== this.state.form._id)
		) {
			this.updateFormState();
		}

		const { dapp } = this.props
		if (!_.isEqual(this.props.dapp, prevProps.dapp) && dapp && dapp._id) {
			this.props.getDatasourceByName(dapp.title, dapp._id) // TODO:
		}
		if (this.props.saveSuccess && this.props.saveSuccess !== prevProps.saveSuccess
			&& !_.isEqual(this.props.entryForm, prevProps.entryForm)) {
			this.props.history.push(`/builder/${this.props.match.params.dappId}/forms/${this.props.entryForm._id}`);
		}
	}

	componentDidCatch(error, info) {
		console.log("in - componentDidCatch ");
		console.error("error, info", error, info)
	}

	updateFormState = () => {
		this.setState({ form: JSON.parse(JSON.stringify(this.props.entryForm)) })
		this.setState({ design: this.props.entryForm.design, modalData: this.props.entryForm.modalData })
		if (this.props.entryForm.pageId || this.state.form.pageId)
			this.props.getPage({
				pageId: this.props.entryForm.pageId ? this.props.entryForm.pageId : this.state.form.pageId,
				dappId: this.props.match.params.dappId
			});
	};

	updateEntryFormState = () => {
		const params = this.props.match.params;
		const { formId } = params;
		if (formId === 'new') {
			this.props.newEntryForm();
		}
		else {
			this.props.getEntryForm(this.props.match.params);
		}
	};

	handleChangeTab = (event, tabValue) => {
		if (this.child && this.child.state) {
			this.setState({ formState: this.child.state });
		}
		if (this.state.formExistsWithProvidedName) {
			return;
		}
		if (!this.state.form || !this.state.form.name || this.state.form.name.length == 0) {
			this.setState({ showMessage: true, tabValue: 0 })
			return;
		}
		this.setState({ tabValue });
	};

	handleChange = (event) => {
		let editModeForm = (this.props.selectedForm && this.props.selectedForm
			&& this.props.selectedForm.name) ? this.props.selectedForm.name : null;
		if (event.target.name == 'name' && event.target.value != editModeForm) {
			axios.get(`${API_BASE_URL}/formBuilder/getForm/${event.target.value}`, { headers: { 'appId': this.props.match.params.dappId } }).then((res) => {
				if (res.data && res.data.length > 0) {
					this.setState({ formExistsWithProvidedName: true });
				}
				else {
					this.setState({ formExistsWithProvidedName: false });
				}
			}).catch(() => {

			})
		}
		this.setState({ form: _.set({ ...this.state.form }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value) });
		this.setState({ editForm: true })
	};

	handleWorkFlowChanges = (value) => {
		this.setState({
			form: {
				...this.state.form,
				workflowId: value
			}
		})
	}

	handleAccessRolesChanges = (key, value) => {
		this.setState({
			form: {
				...this.state.form,
				[key]: value,
			}
		})
	}

	handleChipChange = (value, name) => {
		this.setState({ form: _.set({ ...this.state.form }, name, value.map(item => item.value)) });
	};

	setFeaturedImage = (id) => {
		this.setState({ form: _.set({ ...this.state.form }, 'featuredImageId', id) });
	};

	canBeSubmitted() {
		const { name } = this.state.form;
		return (
			name.length > 0 &&
			!_.isEqual(this.props.entryForm, this.state.form)
		);
	}

	async saveDataModel(dataModal) {
		let d = await axios.post(API_BASE_URL + '/dataModels/add', dataModal, {
			headers: { 'appId': this.props.match.params.dappId, orgId: this.props.user.orgId }
		});
		return d.data
		// return this.props.saveDataModelFromForm(dataModal, this.props.match.params.dappId, this.props.user.orgId).then(res => {
		// 	return res
		// });
	}

	async savePage(selectedDataElement, dataModalId) {
		const { form } = this.state
		const { appId, formBuilder } = this.props
		let page = generatePageData(form, this.props.match.params.dappId, selectedDataElement, dataModalId)
		let p = await axios.post(API_BASE_URL + '/pages', page, {
			headers: { 'appId': this.props.match.params.dappId }
		});
		return p.data
		// this.props.savePage(page, formBuilder.payload, dataModalId, this.props.match.params.dappId);
	}

	onClick = async () => {
		if (!this.state.form || !this.state.form.name || this.state.form.name.length == 0) {
			// this.setState({ showMessage: true })
			return;
		}
		const { user, appId, datasource } = this.props;
		const { orgId, id } = user;
		this.setState({ isSaveDisabled : true})

		const { form, design, modalData } = this.state
		const { name, description, workflowId, readRoles, createRoles, deleteRoles } = form;
		const { rows } = this.state.design;
		let { dataElements, selectedDataElement } = Utils.setDataElemenets(rows);
		let currentForm = {
			_id: "",
			type: "form",
			name,
			description,
			design: design,
			modalData: modalData,
			saveAsTemplate: form.saveAsTemplate,
			isApiForm: form.isApiForm,
			defaultEdit: form.defaultEdit || false,
			useLedgerTable: form.useLedgerTable,
			appId: this.props.match.params.dappId,
			orgId: orgId,
			createdBy: id,
			createdDateTime: new Date(),
			updatedBy: id,
			updatedDateTime: new Date(),
			workflowId,
			dataModalId: '',
			pageId: '',
			readRoles,
			createRoles,
			deleteRoles
		}
		let dataModalCollection = {
			"allowExternalElements": true,
			"dataFormat": "JSON",
			"name": form.name,
			"description": form.description,
			"dataElements": dataElements,
			"appId": this.props.match.params.dappId,
			// "datasourceId": datasource && datasource.data && datasource.data._id,
			"collectionName": form.name,
		}
		if (this.props.selectedForm && this.props.selectedForm) {
			console.log('this.props.selectedForm', this.props.selectedForm);
			console.log('this.props.entryForm', this.props.entryForm);
			const { dataModalId, pageId, _id } = this.props.selectedForm
			console.log("dataModalId, pageId, _id", dataModalId, pageId, _id);
			currentForm = { ...currentForm, _id, dataModalId, pageId }
			dataModalCollection = { ...dataModalCollection, _id: dataModalId }
			this.props.updateEntryForm(JSON.parse(JSON.stringify(currentForm)));
			await this.props.updateDataModel(dataModalCollection, this.props.match.params.dappId)
			let page = JSON.parse(JSON.stringify(this.props.page))
			if (page.chart && !page.chart[0]) {
				page.chart[0] = {}
				if (!page.chart[0].chartData)
					page.chart[0].chartData = {}
			}
			page.chart[0].chartData.selectedDataElement = selectedDataElement
			this.props.updatePage(page)
			this.setState({ isSaveDisabled : false})
		}
		else {
			let dataModal = await this.saveDataModel(dataModalCollection)
			console.log("dataModal === ", dataModal);
			let page = await this.savePage(selectedDataElement, dataModal._id)
			console.log("page === ", page);
			currentForm = { ...currentForm, dataModalId: dataModal._id, pageId: page._id }
			await this.props.saveEntryForm(currentForm);
			this.setState({ isSaveDisabled : false})
		}
	}
	hideDialog = () => {
		this.setState({ showMessage: false })
	}

	render() {
		console.log("in - render", this.state);
		const { classes, roles, appId } = this.props;
		const { tabValue, form } = this.state;
		return (<>
			<Box className="pxy20 quickwizard-page">
				<Grid container>
					<Grid item xs={12} md={6}>
						<div className="backbtn">
							<div className="backbtn">
								<Link to={`/builder/${this.props.match.params.dappId}/forms`}
									style={{ textDecoration: 'none', color: "#1B2A47" }}>
									<img src={rightarrow} alt="backicon" className="backicon" />
									<Box className="companyheadingtext">
										<Typography variant="h4">{"FORMS LIST"}</Typography>
									</Box>
								</Link>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} md={6} className="savebtn-sec">
						{this.props.match.params.formId !== "new" &&
							<Button variant="contained" color="primary" className="addnewentrybtn mb5"
								onClick={(event) => {
									event.preventDefault()
									console.log(`${APPRENDERER_URL}/apps/${this.props.match.params.dappId}/form/${this.state.form.name}/new`);
									window.open(`${APPRENDERER_URL}/apps/${this.props.match.params.dappId}/form/${this.state.form.name}/new`, "_blank")
								}}>
								{"VIEW FORM"}
							</Button>
						}
						<Button variant="contained" color="primary" className="addnewentrybtn mb5"
							disabled={this.state.isSaveDisabled}
							onClick={() => this.onClick()}>
							{this.props.match.params.formId === "new" ? "SAVE" : "UPDATE"}
						</Button>
					</Grid>
					<Grid item xs={12} md={6} className="savebtn-sec">
					</Grid>
					<Box className="bg-white pxy20 width-100" component="div">
						<Grid container spacing={2}>
							<Box className="bg-white pl-20 width-100 who-can-read-data">
								<AppBar position="static" className="bg-white">
									<Tabs
										value={tabValue}
										onChange={this.handleChangeTab}
										indicatorColor="secondary"
										textColor="secondary"
										variant="scrollable"
										scrollButtons="auto"
										classes={{ root: "w-full h-64" }}
										className="quicktabs-sec"
									>
										{/* <Tab className="h-64 normal-case" label="Basic Info" /> */}
										<Tab label="Basic Info" {...a11yProps(0)} />
										<Tab className="h-64 normal-case" label="Design" />
										<Tab className="h-64 normal-case" label="Workflows" />
										{/* <Tab className="h-64 normal-case" label="Access" /> */}
									</Tabs>
								</AppBar>

								<div className="p-16 sm:p-24">
									<form className="form who-can-read-frm" noValidate autoComplete="off" >
										<TabPanel value={tabValue} index={0}>
											<EntryFormDetails
												{...this.props}
												formNameValidation={formNameValidation}
												form={form}
												showMessage={this.state.showMessage}
												formExistsWithProvidedName={this.state.formExistsWithProvidedName}
												onTemplateClick={(e) => {
													// this.setState({ saveAsTemplate: e.target.checked })
													this.setState({
														...this.state,
														form: {
															...this.state.form,
															saveAsTemplate: e.target.checked
														}
													})
												}}
												onLedgerClick={(e) => {
													// this.setState({ useLedgerTable: e.target.checked })
													this.setState({
														...this.state,
														form: {
															...this.state.form,
															useLedgerTable: e.target.checked
														}
													})
												}}
												onSelectEditDefault={(e) => {
													this.setState({
														...this.state,
														form: {
															...this.state.form,
															defaultEdit: e.target.checked
														}
													})
												}}
												onSelectedTemplateChange={(e) => {
													this.setState({ selectedTemplate: e.target.value });
												}}
												onSelectedApiForm={(e) => {
													this.setState({
														...this.state,
														form: {
															...this.state.form,
															isApiForm: e.target.checked
														}
													})
												}}
												handleChange={this.handleChange}
											/>
										</TabPanel>
										{tabValue == 1 && <div>
											<FormBuilder onRef={ref => (this.child = ref)}
												basicInfo={this.state.form}
												formState={this.state.formState}
												saveAsTemplate={form.saveAsTemplate}
												changeSaveState={() => { this.savePossible() }}
												changeDesignAndModalData={(design, modalData) => {
													this.setState({ design, modalData })
												}}
											/>
										</div>}
										{tabValue == 2 && <div>
											<WorkflowsRenderer selectedWorkFlow={this.state.form.workflowId}
												selectWorkFlow={(value) => this.handleWorkFlowChanges(value)} />
										</div>}
										{/* {tabValue == 3 && <div>
											<label>{"Who can read data"}</label>
											<Select
												multiple
												fullWidth
												value={form.readRoles ? form.readRoles : []}
												onChange={(e) => { this.handleAccessRolesChanges('readRoles', e.target.value) }}
												input={<Input />}
											>
												{roles.data && roles.data.map(item => (
													<MenuItem key={item._id}
														value={item._id}>
														{item.name}
													</MenuItem>
												))}
											</Select>
											<label>{"Who can create data"}</label>
											<Select
												multiple
												fullWidth
												value={form.createRoles ? form.createRoles : []}
												onChange={(e) => { this.handleAccessRolesChanges('createRoles', e.target.value) }}
												input={<Input />}
											>
												{roles.data && roles.data.map(item => (
													<MenuItem key={item._id}
														value={item._id}>
														{item.name}
													</MenuItem>
												))}
											</Select>
											<label>{"Who can delete data"}</label>
											<Select
												multiple
												fullWidth
												value={form.deleteRoles ? form.deleteRoles : []}
												onChange={(e) => { this.handleAccessRolesChanges('deleteRoles', e.target.value) }}
												input={<Input />}
											>
												{roles.data && roles.data.map(item => (
													<MenuItem key={item._id}
														value={item._id}>
														{item.name}
													</MenuItem>
												))}
											</Select>
										</div>} */}
									</form>
								</div>
							</Box>
						</Grid>
					</Box>
				</Grid>
			</Box>
		</>)
	}
}

export default withStyles(styles, { withTheme: true })(
	withRouter(Redux(EntryForm))
)
