import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { WorkflowsService } from 'services/index'
import { ActionTypes } from 'constants/index'

async function fetchAsync(func, arg) {
	const response = arg ? await func(arg) : await func()
	return response
}

function* getWorkflows(action) {
	try {
		const workflows = yield call(fetchAsync, WorkflowsService.getWorkflows, action.payload)
		if (action.payload.type === 'flowchart') {
			yield put({ type: ActionTypes.GET_FLOWCHARTS_SUCCESS, payload: workflows })
		} else {
			yield put({ type: ActionTypes.GET_WORKFLOWS_SUCCESS, payload: workflows })
		}
	} catch (e) {
		yield put({ type: ActionTypes.GET_WORKFLOWS_FAILURE, error: e.message })
	}
}

function* getWorkflow(action) {
	try {
		const workflow = yield call(fetchAsync, WorkflowsService.getWorkflow, action.payload)
		yield put({ type: ActionTypes.GET_WORKFLOW_SUCCESS, payload: workflow })
	} catch (e) {
		yield put({ type: ActionTypes.GET_WORKFLOW_FAILURE, error: e.message })
	}
}

function* saveWorkflow(action) {
	try {
		const workflow = yield call(fetchAsync, WorkflowsService.saveWorkflow, action.payload);
		if (action.payload.type === 'flowchart') {
			yield put({ type: ActionTypes.SAVE_FLOWCHARTS_SUCCESS, payload: workflow })
		} else {
			yield put({ type: ActionTypes.SAVE_WORKFLOW_SUCCESS, payload: workflow })
		}
	} catch (e) {
		yield put({ type: ActionTypes.SAVE_WORKFLOW_FAILURE, error: e.message })
	}
}

function* updateWorkflow(action) {
	try {
		const workflow = yield call(fetchAsync, WorkflowsService.updateWorkflow, action.payload)
		yield put({ type: ActionTypes.UPDATE_WORKFLOW_SUCCESS, payload: workflow })
	} catch (e) {
		yield put({ type: ActionTypes.UPDATE_WORKFLOW_FAILURE, error: e.message })
	}
}

function* deleteWorkflow(action) {
	try {
		const workflow = yield call(fetchAsync, WorkflowsService.deleteWorkflow, action.payload)
		yield put({ type: ActionTypes.UPDATE_WORKFLOW_SUCCESS, payload: workflow })
	} catch (e) {
		yield put({ type: ActionTypes.UPDATE_WORKFLOW_FAILURE, error: e.message })
	}
}

function* getWorkFlowTasks(action) {
	try {
		const workflow = yield call(fetchAsync, WorkflowsService.getWorkflow, action.payload)
		yield put({ type: ActionTypes.GET_WORKFLOW_TASKS_SUCCESS, payload: workflow })
	} catch (e) {
		yield put({ type: ActionTypes.GET_WORKFLOW_TASKS_FAILURE, error: e.message })
	}
}

function* getWorkFlowEvents(action) {
	try {
		const workflow = yield call(fetchAsync, WorkflowsService.getWorkflow, action.payload)
		yield put({ type: ActionTypes.GET_WORKFLOW_EVENTS_SUCCESS, payload: workflow })
	} catch (e) {
		yield put({ type: ActionTypes.GET_WORKFLOW_EVENTS_FAILURE, error: e.message })
	}
}

export function* watchWorkflowsSaga() {
	yield takeEvery(ActionTypes.GET_WORKFLOWS, getWorkflows)
	yield takeEvery(ActionTypes.GET_WORKFLOW, getWorkflow)
	yield takeEvery(ActionTypes.SAVE_WORKFLOW, saveWorkflow)
	yield takeEvery(ActionTypes.UPDATE_WORKFLOW, updateWorkflow)
	yield takeEvery(ActionTypes.DELETE_WORKFLOW, deleteWorkflow)
	yield takeEvery(ActionTypes.GET_WORKFLOW_TASKS, getWorkFlowTasks)
	yield takeEvery(ActionTypes.GET_WORKFLOW_EVENTS, getWorkFlowEvents)
}

export default watchWorkflowsSaga;