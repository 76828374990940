import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Paper, Grid, TextField, Button, Box, Typography } from '@material-ui/core'

import templatesthumb from "images/templatesthumb.jpg"
import 'styles/styles.scss'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}))

let keywords = [
	"Keyword 1",
	"Keyword 2",
	"Keyword 3",
	"Keyword 4",
	"Keyword 5",
	"Keyword 6",
	"Keyword 7",
	"Keyword 8",
	"Keyword 9",
	"Keyword 10",
	"Keyword 11",
	"Keyword 12",
	"Keyword 13",
	"Keyword 14",
	"Keyword 15",
]

let templates = [
	"Template 1",
	"Template 2",
	"Template 3",
	"Template 4",
	"Template 5",
	"Template 6",
	"Template 7",
	"Template 8"
]

const SelectTemplate = () => {
	const classes = useStyles()
	const [showMore, setShowMore] = useState(false)

	return (<Box>
		<div className="recent-apps pxy20">
			<div className="container">
				<Grid container className={classes.root}>
					<Box className="select-temp-search">
						<Grid container >
							<Grid item xs={12} sm={4} md={3} className="mb2">
								<Typography className="ptfive" variant="h3">{"Select a Template"}</Typography>
							</Grid>
							<Grid item xs={12} sm={4} md={7} className="mb2">
								<Box>
									<TextField id="outlined-basic"
										type="text"
										className="d-block bg-white"
										variant="outlined"
										placeholder="Enter a key Word"
										size="small"
										width="100%" />
								</Box>
							</Grid>
							<Grid item xs={12} sm={4} md={2} className="mb2" align="right">
								<Button variant="contained" color="primary" className="addnewappbtn slct-srch-btn" >
									{"SEARCH"}
								</Button>
							</Grid>
						</Grid>
						<Grid container spacing={2} className="select-temp-keywords">
							{keywords.map((key, i) => {
								if (!showMore)
									return i < 7 ? <Grid key={i} item>
										<Button variant="contained" color="default"> {key} </Button>
									</Grid> : <></>
								else
									return <Grid key={i} item>
										<Button variant="contained" color="default"> {key} </Button>
									</Grid>
							})}
							{keywords.length > 7 && <Grid item>
								<Button variant="contained" color="info" className="btninfo"
									onClick={() => setShowMore(!showMore)}>
									{!showMore ? "more" : "less"}
								</Button>
							</Grid>}
						</Grid>
					</Box>
					<div className="select-template-card">
						<Grid container spacing={2}>
							{templates.map((template, i) => <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
								<Paper>
									<Typography className="brderpink" variant="h5">{template}</Typography>
									<img className="temselimg" src={templatesthumb} />
									<Box className="app-title">{"SELECT"}</Box>
								</Paper>
							</Grid>
							)}
						</Grid>
					</div>
				</Grid>
			</div>
		</div>
	</Box>)
}

export default SelectTemplate