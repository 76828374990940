import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
	Button, Grid, Typography, makeStyles, Box, AppBar, Tabs, Tab, Divider
} from '@material-ui/core'
import Loading from 'components/Loading'
import 'styles/styles.scss'
import BusinessRuleDetails from './BusinessRuleDetails'
import { RulesTab } from './RulesTab'
import { TabPanel } from '../@tabpanel/tabpanel'
import rightarrow from 'images/rightarrow.svg'
import RuleQueryBuilder from './RuleQuery'

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	}
}))

const View = (props) => {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const { form } = props.state;

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<React.Fragment>
			<Box className="quickwizard-page">
				<Grid container>
					<Grid item xs={12} md={6}>
						<div className="backbtn">
							<div className="backbtn ptl-20">
								<Link to={`/builder/${props.match.params.dappId}/businessrules`}
									style={{ textDecoration: 'none', color: "#1B2A47" }}>
									<img src={rightarrow} alt="backicon" className="backicon" />
									<Box component="h3" className="companyheadingtext">
										<Typography variant="h4">{"BUSINESS RULES"}</Typography>
									</Box>
								</Link>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} md={6} className="savebtn-sec">
						{/* <Button
							className="addnewentrybtn mb5"
							variant="contained"
							onClick={() => props.executeRule(true)}>
							{"Execute"}
						</Button> */}
						<Button
							variant="contained" color="primary" className="addnewentrybtn mb5"
							disabled={!props.canBeSubmitted()}
							onClick={() => props.match.params.businessRuleId === "new" ? props.saveDetails(true) : props.saveDetails(false)}>
							{props.match.params.businessRuleId === "new" ? "Save" : "Update"}
						</Button>
					</Grid>
				</Grid>
				<Box className="pxy20">
					<div className={classes.root} >
						<Box px="1.5rem">
							<AppBar position="static" className="bg-white">
								<Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className="quicktabs-sec">
									<Tab label="Basic Info" {...a11yProps(0)} />
									<Tab label="Business Rule" {...a11yProps(1)} />
									<Tab label="Rule Query" {...a11yProps(2)} />
								</Tabs>
								<Divider />
							</AppBar>
							<TabPanel value={value} index={0}>
								<BusinessRuleDetails {...props} classes={classes} />
							</TabPanel>
							<TabPanel value={value} index={1}>
								<RulesTab {...props} classes={classes} />
							</TabPanel>
							<TabPanel value={value} index={2}>
								<RuleQueryBuilder {...props} classes={classes} />
							</TabPanel>
						</Box>
					</div>
				</Box>
			</Box>
		</React.Fragment>
	)
}

export default withRouter(View)