export default {



	GET_ROLES: undefined,
	GET_ROLES_SUCCESS: undefined,
	GET_ROLES_FAILURE: undefined,

	GET_ROLE: undefined,
	GET_ROLE_SUCCESS: undefined,
	GET_ROLE_FAILURE: undefined,

	SAVE_ROLE: undefined,
	SAVE_ROLE_SUCCESS: undefined,
	SAVE_ROLE_FAILURE: undefined,

	UPDATE_ROLE: undefined,
	UPDATE_ROLE_SUCCESS: undefined,
	UPDATE_ROLE_FAILURE: undefined,

	DELETE_ROLE: undefined,
	DELETE_ROLE_SUCCESS: undefined,
	DELETE_ROLE_FAILURE: undefined,

	GET_PRIVILEGES: undefined,
	GET_PRIVILEGES_SUCCESS: undefined,
	GET_PRIVILEGES_FAILURE: undefined,

}