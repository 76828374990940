import React from 'react';
import * as Actions from 'actions';
import { connect } from 'react-redux';

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, formulas, formula }) => {
		return {
			formula,
			formulas,
			user: user,
			navigation: navigation
		}
	}

	const mapDispatchToProps = {
		getFormula: Actions.getFormula,
		showAlert: Actions.showAlert,
		getFormulas: Actions.getFormulas,
		newFormula: Actions.newFormula,
		saveFormula: Actions.saveFormula,
		deleteFormula: Actions.deleteFormula,
		updateFormula: Actions.updateFormula
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux;