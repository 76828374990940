import React, { Component } from 'react'
import { Grid, FormControl, FormLabel } from '@material-ui/core'
import RegexTextField from "../InputTextField/InputTextField";
import { emailRegex, phoneNumberRegex } from '../../utils/regex';

class Theme extends Component {
	render() {
		let { classes, form, handleChange, handleUIChange, error } = this.props;
		let s = {
			width: "25ch",
			margin: "8px"
		};
		return (<>
			<Grid item sm={12} md={6}>
				<Grid container className="">
					<form className="form" noValidate autoComplete="off">
						<FormControl>
							<FormLabel variant="h6" className="label-headings">
								{"Primary Contact Name *"}
            				</FormLabel>
							<RegexTextField
								id="primaryContactName"
								name="primaryContactName"
								variant="outlined"
								color="secondary"
								className="form-control"
								placeholder="Primary Contact"
								value={form.primaryContactName}
								onChange={handleUIChange}
								variant="outlined"
								fullWidth
								autoFocus
								helperText={error.primaryContactName && error.primaryContactName}
								error={Boolean(error.primaryContactName)}
								autoFocus={!!error.primaryContactName}
							/>
						</FormControl>

						<FormControl>
							<FormLabel variant="h6" className="label-headings">
								{"Primary Contact Email *"}
                            </FormLabel>
							<RegexTextField
								id="primaryContactEmail"
								name="primaryContactEmail"
								variant="outlined"
								color="secondary"
								className="form-control"
								placeholder="Primary Contact Email"
								value={form.primaryContactEmail}
								onChange={handleUIChange}
								variant="outlined"
								fullWidth
								helperText={error.primaryContactEmail && error.primaryContactEmail}
								error={Boolean(error.primaryContactEmail)}
								regex={emailRegex}
								autoFocus={!!error.primaryContactEmail}
							/>
						</FormControl>

						<FormControl>
							<FormLabel variant="h6" className="label-headings">
								{"Primary Contact Phone *"}
                            </FormLabel>
							<RegexTextField
								id="primaryContactPhone"
								name="primaryContactPhone"
								variant="outlined"
								color="secondary"
								className="form-control"
								placeholder="Primary Contact Phone"
								value={form.primaryContactPhone}
								onChange={handleUIChange}
								variant="outlined"
								fullWidth
								helperText={error.primaryContactPhone && error.primaryContactPhone}
								error={Boolean(error.primaryContactPhone)}
								regex={phoneNumberRegex}
								autoFocus={!!error.primaryContactPhone}
							/>
						</FormControl>

						<FormControl>
							<FormLabel variant="h6" className="label-headings">
								{"Secondary Contact Name"}
                            </FormLabel>
							<RegexTextField
								id="secContactName"
								name="secContactName"
								variant="outlined"
								color="secondary"
								className="form-control"
								placeholder="Primary Contact Phone"
								value={form.secContactName}
								onChange={handleUIChange}
								variant="outlined"
								fullWidth
							/>
						</FormControl>

						<FormControl>
							<FormLabel variant="h6" className="label-headings">
								{"Secondary Contact Email"}
                            </FormLabel>
							<RegexTextField
								id="secContactEmail"
								name="secContactEmail"
								variant="outlined"
								color="secondary"
								className="form-control"
								placeholder="Secondary Contact Email"
								value={form.secContactEmail}
								onChange={handleUIChange}
								variant="outlined"
								fullWidth
								regex={emailRegex}
							/>
						</FormControl>

						<FormControl>
							<FormLabel variant="h6" className="label-headings">
								{"Secondary Contact Phone"}
                            </FormLabel>
							<RegexTextField
								id="secContactPhone"
								name="secContactPhone"
								variant="outlined"
								color="secondary"
								className="form-control"
								placeholder="Secondary Contact Phone"
								value={form.secContactPhone}
								onChange={handleUIChange}
								variant="outlined"
								fullWidth
								regex={phoneNumberRegex}
							/>
						</FormControl>
					</form>
				</Grid>
			</Grid>
		</>
		)
	}
}
export default Theme
