import React from 'react'
import {
	Button, Checkbox, Icon, NativeSelect, Tab, Tabs, TextField, Typography, Box,
	Select, Input, MenuItem, AppBar, FormControl, FormLabel, FormHelperText,
	withStyles
} from '@material-ui/core'
const EntryFormDetails = (props) => {
	let { form } = props
	return (
		<form className="form save-frm-temp" noValidate autoComplete="off" >
			<FormControl error>
				<FormLabel variant="h6" className="label-headings">
					{"Name *"}
				</FormLabel>
				<TextField
					error={form.name === '' || props.formNameValidation.test(form.name)}
					id="name"
					aria-describedby="name-error-text"
					name="name"
					variant="outlined"
					color="secondary"
					className="form-control"
					placeholder="Name"
					value={form.name}
					onChange={props.handleChange}
					fullWidth
					autoFocus
					required
					disabled={props.match.params.formId !== "new"}
				/>
				{props.showMessage
					&& <FormHelperText id="name-error-text">
						{"Invalid form name"}
					</FormHelperText>}
			</FormControl>
			{props.formExistsWithProvidedName
				&& <Typography color="error">
					{"Form exists with the provided Name.Please provide a different name."}
				</Typography>
			}
			<FormControl>
				<FormLabel variant="h6" className="label-headings ">
					{"Description"}
				</FormLabel>
				<TextField
					className="form-control"
					id="description"
					name="description"
					onChange={props.handleChange}
					type="text"
					value={form.description}
					multiline
					rows={2}
					variant="outlined"
					fullWidth
				/>
			</FormControl>
			<label>Save Form as Template</label>
			<Checkbox
				checked={form.saveAsTemplate || false}
				onClick={(e) => { props.onTemplateClick(e) }}
			/>
			<br />
			<label>Use Ledger Table</label>
			<Checkbox
				checked={form.useLedgerTable || false}
				onClick={(e) => { props.onLedgerClick(e) }}
			/>
			<br />
			<NativeSelect style={{ "width": "100%", "paddingTop": "10px" }}
				onChange={(e) => {
					props.onSelectedTemplateChange(e)
					props.selectTemplate(props.savedTemplates[e.target.value])
				}}
			>
				<option selected disabled>Select a Template</option>
				{props.savedTemplates && props.savedTemplates.map((element, index) => {
					return <option value={index} selected={index == props.selectedTemplate} >{element.name}</option>
				})}
			</NativeSelect>
			
			<label>Use Ledger Table</label><br/>
			<label>API form</label>
			<Checkbox
				checked={form.isApiForm || false}
				onClick={(e) => { props.onSelectedApiForm(e) }}
			/>
			<br />
			<label>Default Edit Mode</label>
			<Checkbox
				checked={form.defaultEdit || false}
				onClick={(e) => { props.onSelectEditDefault(e) }}
			/>
			<br />
		</form>
	)
}
export default EntryFormDetails