import { ActionTypes } from '../constants/index';

const initialState = {
  steps: [],
  style: { backgroundColor: '#eee' },
  height: window.innerHeight - 100,
  width: window.innerWidth,
};

const flowchartReducer = function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SAVE_FLOWCHART_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ActionTypes.SAVE_FLOWCHART: {
      console.log('Reducer', action.payload);
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default flowchartReducer;
