export default {

	GET_WORKFLOWS: undefined,
	GET_WORKFLOWS_SUCCESS: undefined,
	GET_WORKFLOWS_FAILURE: undefined,

	GET_FLOWCHARTS: undefined,
	GET_FLOWCHARTS_SUCCESS: undefined,
	GET_FLOWCHARTS_FAILURE: undefined,

	GET_WORKFLOW: undefined,
	GET_WORKFLOW_SUCCESS: undefined,
	GET_WORKFLOW_FAILURE: undefined,
	CLEAR_WORKFLOW: undefined,

	SAVE_WORKFLOW: undefined,
	SAVE_WORKFLOW_SUCCESS: undefined,
	SAVE_WORKFLOW_FAILURE: undefined,

	UPDATE_WORKFLOW: undefined,
	UPDATE_WORKFLOW_SUCCESS: undefined,
	UPDATE_WORKFLOW_FAILURE: undefined,

	DELETE_WORKFLOW: undefined,
	DELETE_WORKFLOW_SUCCESS: undefined,
	DELETE_WORKFLOW_FAILURE: undefined,


	SAVE_FLOWCHARTS: undefined,
	SAVE_FLOWCHARTS_SUCCESS: undefined,
	SAVE_FLOWCHARTS_FAILURE: undefined,

	UPDATE_FLOWCHARTS: undefined,
	UPDATE_FLOWCHARTS_SUCCESS: undefined,
	UPDATE_FLOWCHARTS_FAILURE: undefined,

	DELETE_FLOWCHARTS: undefined,
	DELETE_FLOWCHARTS: undefined,
	DELETE_FLOWCHARTS_FAILURE: undefined,

	SET_WORKFLOWS_SEARCH_TEXT: undefined,

	GET_WORKFLOW_TASKS: undefined,
	GET_WORKFLOW_TASKS_SUCCESS: undefined,
	GET_WORKFLOW_TASKS_FAILURE: undefined,

	GET_WORKFLOW_EVENTS: undefined,
	GET_WORKFLOW_EVENTS_SUCCESS: undefined,
	GET_WORKFLOW_EVENTS_FAILURE: undefined,

}