import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {
	const mapStateToProps = ({ user, navigation, entities, roles, member, groups }) => {
		return {
			user: user,
			navigation: navigation,
			entities: entities,
			member: member,
			roles: roles,
			memberSuccess: member.memberSuccess,
			groups: groups.data,
		}
	}

	const mapDispatchToProps = {
		getEntities: Actions.getEntities,
		getMember: Actions.getMember,
		saveMember: Actions.saveMember,
		newMember: Actions.newMember,
		updateMember: Actions.updateMember,
		getRoles: Actions.getRoles,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert,
		getGroups: Actions.getGroups,
		disableMember: Actions.disableMember,
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux