import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Nav from './nav'
import Header from './header'
import Main from './main'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	}
}))

function ResponsiveDrawer(props) {
	const classes = useStyles()
	const [mobileOpen, setMobileOpen] = React.useState(false)

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	return (
		<div className={classes.root}>
			{/* <Header mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
			<Nav mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} /> */}
			<Main />
		</div>
	)
}

export default ResponsiveDrawer