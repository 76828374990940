import React from 'react'
import {
	Button, Checkbox, FormControl, FormControlLabel, FormLabel, NativeSelect, Radio,
	RadioGroup, Tab, Tabs, TextField, Typography, withStyles, Select, MenuItem, Input,
	AppBar, Dialog, DialogActions, DialogTitle, Grid
} from '@material-ui/core'

const BasicInfo = (props) => {
	let { elementsData, elementType, setBasic } = props
	return (<>
		<Grid container spacing={8} className="formPmodel">
			{elementType == 'HTML' && <Grid item xs={12}>
				<TextField
					label={"Enter HTML"}
					value={elementsData.basic.HTML}
					onChange={(e) => { setBasic("HTML", e) }}
					margin="normal"
					variant="filled"
					multiline
					fullWidth
					autoFocus
					inputProps={{ rows: 5 }}
				/>
			</Grid>}
			<Grid item xs={4} md={3}>
				<label>Label</label>
			</Grid>
			<Grid item xs={8}>
				<TextField
					label=""
					margin="dense"
					variant="filled"
					value={elementsData.basic.label}
					onChange={(e) => { setBasic("label", e) }}
					fullWidth
					autoFocus
				/>
			</Grid>

			<Grid item xs={4} md={3}>
				<label>Name (Required)</label>
			</Grid>
			<Grid item xs={8}>
				<TextField
					label=""
					margin="dense"
					variant="filled"
					fullWidth
					value={elementsData.basic.name}
					onChange={(e) => { setBasic("name", e) }}
				/>
			</Grid>

			<Grid item xs={4} md={3}>
				<label>Helptext</label>
			</Grid>
			<Grid item xs={8}>
				<TextField
					label=""
					margin="dense"
					variant="filled"
					fullWidth
					value={elementsData.basic.helpText}
					onChange={(e) => { setBasic("helpText", e) }}
				/>
			</Grid>
			<Grid item xs={4} md={3}>
				<label>Enter number of Columns (1-12)</label>
			</Grid>

			<Grid item xs={8}>
				<TextField
					label=""
					type="number"
					margin="dense"
					inputProps={{ min: 1, max: 12 }}
					variant="filled"
					fullWidth
					value={elementsData.basic.columns}
					onChange={(e) => { setBasic("columns", e) }}
				/>
			</Grid>
			{(elementType == "header" || elementType == "label") && <>
				<Grid item xs={4} md={3}>
					<label>Size</label>
				</Grid>
				<Grid item xs={8}>
					<input type="range" min="0" max="5"
						value={elementsData.basic.tag}
						onChange={(e) => { setBasic("tag", e.target.value) }}
						className="slider" id="myRange" />
				</Grid>
				<Grid item xs={4} md={3}>
					<label>Alignment</label>
				</Grid>

				<Grid item xs={8}>
					<RadioGroup name="align">
						<FormControlLabel value="Align" control={
							<Radio value={"left"}
								checked={elementsData.basic.align == 'left'}
								onClick={(e) => { setBasic('align', e) }} />} label="Left" />
						<FormControlLabel value="Align" control={
							<Radio value={"center"}
								checked={elementsData.basic.align == 'center'} />}
							onClick={(e) => { setBasic('align', e) }} label="Center" />
						<FormControlLabel value="Align" control={
							<Radio value={"right"}
								checked={elementsData.basic.align == 'right'}
								onClick={(e) => { setBasic('align', e) }} />} label="Right" />
					</RadioGroup>
				</Grid>
				<Grid item xs={4} md={3}>
					<label>Color</label>
				</Grid>

				<Grid item xs={8}>

					<input type="color" id="head" name="head"
						value={elementsData.basic.color}
						onChange={(e) => { setBasic('color', e) }} />
				</Grid>
			</>}

			{!props.editElement && <>
				<Grid item xs={4} md={3}>
					<label>Include Element in Same Row</label>
				</Grid>

				<Grid item xs={8}>
					<RadioGroup name="sameRow">
						<FormControlLabel control={<Radio value={true}
							checked={elementsData.basic.sameRow == true}
							onClick={(e) => {
								if (elementsData.basic.sameRow == true) {
									setBasic("sameRow", false)
								}
								else { setBasic("sameRow", true) }
							}} />} label="Yes" />

					</RadioGroup>
				</Grid>
			</>}

			{(elementType !== 'button' && elementType !== 'HTML' && elementType !== 'header'
				&& elementType !== 'label' && elementType !== 'tab' && elementType !== 'multiLine'
				&& elementType !== 'multiStepForm' && elementType !== "expansionPanels") && (<>
					<Grid item xs={4} md={3}>
						<label>Disabled</label>
					</Grid>
					<Grid item xs={8}>
						<Checkbox checked={elementsData.basic.disabled}
							value={true}
							onClick={(e) => { setBasic('disabled', e.target.checked) }}
						/>
					</Grid>
					<Grid item xs={4} md={3}>
						<label>Hidden</label>
					</Grid>
					<Grid item xs={8}>
						<Checkbox checked={elementsData.basic.hidden}
							value={true}
							onClick={(e) => { setBasic('hidden', e.target.checked) }}
						/>
					</Grid>
					<Grid item xs={4} md={3}>
						<label>Show in the page grid</label>
					</Grid>
					<Grid item xs={8}>
						<Checkbox checked={elementsData.basic.showInThePageGrid}
							value={true}
							onClick={(e) => { setBasic('showInThePageGrid', e.target.checked) }}
						/>
					</Grid>
					<Grid item xs={4} md={3}>
						<label>{"Unique Field"}</label>
					</Grid>
					<Grid item xs={8}>
						<Checkbox checked={elementsData.basic.unique}
							value={true}
							onClick={(e) => { setBasic('unique', e.target.checked) }}
						/>
					</Grid>
					{elementType === 'currency' && (
						<>
							<Grid item xs={4} md={3}>
								<label>Currency Type</label>
							</Grid>
							<Grid item xs={8}>
								<TextField
									label=""
									margin="dense"
									variant="filled"
									fullWidth
									value={elementsData.basic.currencySymbol}
									onChange={(e) => { setBasic("currencySymbol", e) }}
								/>
							</Grid>
						</>
					)}
					{elementType === 'pageOrFormPicker' && (
						<>
							<Grid item xs={4} md={3}>
								<label>List Type *</label>
							</Grid>
							<Grid item xs={8}>
								<RadioGroup name="listType" row>
									<FormControlLabel value="listType" control={
										<Radio value={"page"}
											checked={elementsData.basic.listType == 'page'}
											onClick={(e) => { setBasic('listType', e) }} />} label="Page" />
									<FormControlLabel value="listType" control={
										<Radio value={"form"}
											checked={elementsData.basic.listType == 'form'} />}
										onClick={(e) => { setBasic('listType', e) }} label="Form" />
								</RadioGroup>
							</Grid>
						</>
					)}

				</>)}
		</Grid>
	</>)
}

export default BasicInfo