import React, { Component } from 'react'
import { orange } from '@material-ui/core/colors'

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing(),
		marginRight: theme.spacing(),
		width: 200,
	},
	dense: {
		marginTop: 19,
	},
	menu: {
		width: 200,
	},
	entryFormImageFeaturedStar: {
		position: 'absolute',
		top: 0,
		right: 0,
		color: orange[400],
		opacity: 0
	},
	entryFormImageItem: {
		width: 128,
		height: 128,
		display: 'flex',
		alignItems: 'center',
		justifyItems: 'center',
		position: 'relative',
		borderRadius: 4,
		marginRight: 16,
		marginBottom: 16,
		overflow: 'hidden',
		boxShadow: theme.shadows[0],
		transitionProperty: 'box-shadow',
		transitionDuration: theme.transitions.duration.short,
		transitionTimingFunction: theme.transitions.easing.easeInOut,
		cursor: 'pointer',
		'&:hover': {
			boxShadow: theme.shadows[5],
			'& $entryFormImageFeaturedStar': {
				opacity: .8
			}
		},
		'&.featured': {
			pointerEvents: 'none',
			boxShadow: theme.shadows[3],
			'& $entryFormImageFeaturedStar': {
				opacity: 1
			},
			'&:hover $entryFormImageFeaturedStar': {
				opacity: 1
			}
		}
	}
});

export default styles