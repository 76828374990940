const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
import { request } from 'modules/client'

export class DatasourcesService {

	static getDatasources(appId) {
		const uri = API_BASE_URL + "/datasources"
		return request(uri, {
			method: 'GET',
			headers: { 'appId': appId }
		})
	}

	static getDatasource(params) {
		const uri = API_BASE_URL + "/datasources/" + params.datasourceId
		return request(uri, {
			method: 'GET',
			headers: { 'appId': params.dappId }
		})
	}

	static getDatasourceByName({ name, appId }) {
		const uri = API_BASE_URL + '/datasources/getByName/' + name
		return request(uri, {
			method: 'GET',
			headers: { 'appId': appId }
		})
	}

	static saveDatasource(data) {
		const uri = API_BASE_URL + '/datasources/add'
		return request(uri, {
			method: 'POST',
			payload: data,
			headers: { 'appId': data.appId }
		})
	}

	static updateDatasource(data) {
		const uri = API_BASE_URL + '/datasources/update/' + data._id
		return request(uri, {
			method: 'PATCH',
			payload: data,
			headers: { 'appId': data.appId }
		})
	}

	static deleteDatasource(data) {
		const uri = API_BASE_URL + '/datasources/delete/' + data._id
		return request(uri, {
			method: 'GET',
			// method: 'DELETE', TODO: Need to update API code and UI code
			payload: data,
			headers: { 'appId': data.appId }
		})
	}

	static testDatasource({ data, appId }) {
		const uri = API_BASE_URL + '/datasources/test'
		return request(uri, {
			method: 'POST',
			payload: data,
			headers: { appId }
		})
	}

	static testQLDBConnection({ data, appId }) {
		const uri = API_BASE_URL + '/ledger/test'
		return request(uri, {
			method: 'POST',
			payload: data,
			headers: { appId }
		})
	}

	static testS3Connection({ data, appId }) {
		const uri = API_BASE_URL + '/datasources/test'
		return request(uri, {
			method: 'POST',
			payload: data,
			headers: { appId }
		})
	}

	static getDataElements({ datasourceId, collectionName, appId }) {
		const uri = API_BASE_URL + '/datasources/dataelements/' + datasourceId + '?'
			+ (collectionName ? "collectionName=" + collectionName : "")
		return request(uri, {
			method: 'GET',
			headers: { appId }
		})
	}

}