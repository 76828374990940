import { ActionTypes } from 'constants/index'

const initialState = {
	data: [],
	searchText: '',
	loading: false,
	error: "",
	deleteSuccess: false
};

const rolesReducer = function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_ROLES_SUCCESS:
			{
				return {
					...state,
					data: action.payload,
					deleteSuccess: false
				};
			}
		case ActionTypes.GET_ROLES_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error
				};
			}
		case ActionTypes.GET_ROLES_BY_DATASOURCE:
			{
				return {
					...state,
					data: action.payload,
					deleteSuccess: false
				};
			}
		case ActionTypes.SET_ROLES_SEARCH_TEXT:
			{
				return {
					...state,
					searchText: action.searchText
				};
			}
		case ActionTypes.DELETE_ROLE_SUCCESS: {
			return {
				...state,
				loading: false,
				data: [...state.data.filter(item => item._id !== action.payload.id)],
				deleteSuccess: true
			}
		}
		case ActionTypes.DELETE_ROLE_FAILURE: {
			return {
				...state,
				error: action.error,
				loading: false
			}
		}
		default:
			{
				return state;
			}
	}
};

export default rolesReducer;
