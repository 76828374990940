import { ActionTypes } from 'constants/index'

const initialState = {
	data: [],
	searchText: "",
	error: "",
	loading: false,

	entryForm: {},

	allForms: {},
	allTemplates: [],
	selectedForm: {},
	selectedTemplate: {},
	submittedFormData: {},

	formElements: { data: {} },
	newElementType: { data: {} },

	formBuilder: { msg: "" },

	saveSuccess: false,
	updateSuccess: false

}

const formsReducer = function (state = initialState, action) {
	switch (action.type) {

		case ActionTypes.SET_ENTRYFORMS_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}

		case ActionTypes.GET_ENTRYFORMS_SUCCESS: {
			return {
				...state,
				data: action.payload
			}
		}
		case ActionTypes.GET_ENTRYFORMS_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error
			}
		}

		case ActionTypes.DELETE_ENTRYFORM_SUCCESS: {
			return {
				...state,
				loading: false,
				data: [...state.data.filter(item => item._id !== action.payload)]
			}
		}
		case ActionTypes.DELETE_ENTRYFORM_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error
			}
		}

		case ActionTypes.GET_ENTRYFORM_SUCCESS: {
			return {
				...state,
				entryForm: action.payload,
				selectedForm: action.payload,
				saveSuccess: false,
				updateSuccess: false
			};
		}
		case ActionTypes.GET_ENTRYFORM_FAILURE: {
			return {
				...state,
				// entryForm: action.payload,
				error: action.error
			};
		}
		// case ActionTypes.SAVE_ENTRYFORM: {
		// 	return {
		// 		...state,
		// 		formSuccess: false
		// 	}
		// }

		case ActionTypes.SAVE_ENTRYFORM_SUCCESS: {
			return {
				...state,
				entryForm: action.payload,
				saveSuccess: true
			};
		}
		case ActionTypes.SAVE_ENTRYFORM_FAILURE: {
			return {
				...state,
				// entryForm: action.payload,
				error: action.error
			};
		}

		case ActionTypes.UPDATE_ENTRYFORM_SUCCESS: {
			return {
				...state,
				updateSuccess: true
				// entryForm: action.payload
			};
		}
		case ActionTypes.UPDATE_ENTRYFORM_FAILURE: {
			return {
				...state,
				error: action.error
			};
		}

		// from allFormsreducer (savedForms)
		case ActionTypes.ALL_FORMS: {
			return {
				...state,
				allForms: {
					savedForms: action.payload
				}
			};
		}

		// from allTemplatesreducer (savedTemplates)
		case ActionTypes.FORM_TEMPLATES_SUCCESS: {
			return {
				...state,
				allTemplates: action.payload
			}
		}
		case ActionTypes.FORM_TEMPLATES_FAILURE: {
			return {
				...state,
				error: action.error
			}
		}

		// from selectedFormReducer
		case ActionTypes.FORM_TO_SUBMIT:
		case ActionTypes.EDIT_FORM: {
			return {
				...state,
				selectedForm: action.payload,
				entryForm: action.payload,
			}
		}

		// from selectedFormReducer
		case ActionTypes.SELECT_TEMPLATE: {
			return {
				...state,
				selectedTemplate: { ...action.payload }
			}
		}
		case ActionTypes.CLEAR_SELCTED_TEMPLATE: {
			return {
				...state,
				selectedTemplate: {}
			}
		}

		//from submittedFormData
		case ActionTypes.SUBMITTED_FORM_DATA: {
			return {
				...state,
				submittedFormData: action.payload
			}
		}

		// from formElements
		case ActionTypes.DRAGGED_ELEMENT: {
			return {
				...state,
				formElements: {
					data: action.payload
				}
			}
		}
		case ActionTypes.CLEAR_DRAGGED_ELEMENT: {
			return {
				...state,
				formElements: {
					data: action.payload
				}
			}
		}
		// from newElementType
		case ActionTypes.DRAGGED_ELEMENT_TYPE: {
			console.log("action.payload", action.payload);
			return {
				...state,
				newElementType: {
					data: action.payload
				}
			}
		}

		// from formBuilder
		case ActionTypes.SAVE_FORM: {
			if (action.payload == null) {
				return {
					...state,
					msg: null
				}
			}
			return {
				...state,
				msg: "Form saved successfully to Database",
				payload: action.payload
			}
		}

		default: {
			return { ...state };
		}
	}
};

export default formsReducer;
