import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button, TextField, FormControl, FormLabel, Typography } from '@material-ui/core'

const nosqlView = (props) => {
	let { form, classes, testDatasource, handleChange } = props
	console.log("form", form);
	return (<><br />
		<Typography variant="h5" className="pb-10">{"Mongo Database Configuration"}</Typography>
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Connection String"}</FormLabel>
			<TextField
				id="noSQLServerConnection"
				name="noSQLServerConnection"
				placeholder="Connection String"
				className={classes.textField}
				value={form.noSQLServerConnection}
				variant="outlined"
				onChange={handleChange}
				className="mt-8 mb-16"
			/>
		</FormControl>
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Database"}</FormLabel>
			<TextField
				id="noSQLServerDb"
				name="noSQLServerDb"
				placeholder="Database"
				className={classes.textField}
				value={form.noSQLServerDb}
				onChange={handleChange}
				className="mt-8 mb-16"
				variant="outlined"
			/>
		</FormControl>
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"UserName"}</FormLabel>
			<TextField
				id="noSQLUserName"
				name="noSQLUserName"
				placeholder="UserName"
				className={classes.textField}
				value={form.noSQLUserName}
				onChange={handleChange}
				variant="outlined"
				inputProps={{
					autocomplete: 'off',
					form: {
						autocomplete: 'off',
					}
				}}
			/>
		</FormControl>
		<FormControl row className="flex items-center  max-w-full">
			<FormLabel variant="h6" className="flex-1">{"Password"}</FormLabel>
			<TextField
				id="noSQLPassword"
				name="noSQLPassword"
				placeholder="password"
				type="password"
				className={classes.textField}
				value={form.noSQLPassword}
				onChange={handleChange}
				variant="outlined"
				inputProps={{
					autocomplete: 'new-password',
					form: {
						autocomplete: 'off',
					}
				}}
			/>
		</FormControl>
		<Button style={{ marginLeft: "10px" }}
			className="whitespace-no-wrap"
			variant="contained"
			onClick={() => testDatasource(form, props.match.params.dappId)}>
			{"Test"}
		</Button>
		<br />
	</>)
}

export default withRouter(nosqlView)