import React from 'react';

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={props.x}
      y={props.y}
      height={props.height}
      width={props.width}
      onClick={props.onClick}
      onDoubleClick={props.editElementStep}
      onMouseMove={props.moveArrow}
      enableBackground="new 0 0 477.867 477.867"
      version="1.1"
      viewBox="0 0 477.867 477.867"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M238.933 0C106.974 0 0 106.974 0 238.933s106.974 238.933 238.933 238.933 238.933-106.974 238.933-238.933C477.726 107.033 370.834.141 238.933 0zm100.624 246.546a17.068 17.068 0 01-7.662 7.662v.085L195.362 322.56c-8.432 4.213-18.682.794-22.896-7.638a17.061 17.061 0 01-1.8-7.722V170.667c-.004-9.426 7.633-17.07 17.059-17.075a17.068 17.068 0 017.637 1.8l136.533 68.267c8.436 4.204 11.867 14.451 7.662 22.887z"></path>
    </svg>
  );
}

export default Icon;
