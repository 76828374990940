import React, { useState, useEffect } from 'react';
import View from './View';
import Redux from './Redux';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
// import NewFormulaDialog from './new/NewFormulaDialog';

const Container = props => {
  const {
    user,
    saveGroup,
    updateGroup,
    group,
    showAlert,
    history,
    getGroup,
    getMembers,
    getRoles,
    roles,
    members,
    newGroup,
  } = props;
  const [state, setState] = useState({
    tabValue: 0,
    form: {},
    isSubmitButton: true,
  });

  useEffect(() => {
    if (group.saveSuccess) {
      showAlert('Group saved successfully', { id: 'group', variant: 'success', icon: 'bell' });
      history.push({
        pathname: `/builder/${props.match.params.dappId}/groups/${group.data._id}`,
      });
    }
    if (group.updateSuccess) {
      showAlert('Group updated successfully', { id: 'group', variant: 'success', icon: 'bell' });
      history.push({
        pathname: `/builder/${props.match.params.dappId}/groups/${group.data._id}`,
      });
    }
  }, [group.saveSuccess, group.updateSuccess]);


  useEffect(() => {
    newGroup();
    return () => {
      newGroup();
    };
  }, []);

  useEffect(() => {
    const groupData = JSON.parse(JSON.stringify(group.data));
    groupData && setState({ ...state, form: groupData });
  }, [JSON.stringify(group.data)]);

  useEffect(() => {
    updateGroupState();
  }, [props.location]);

  const updateGroupState = () => {
    const params = props.match.params;
    const { groupId, dappId } = params;

    if (groupId === 'new') {
      newGroup();
    } else {
      getGroup(params);
    }
    !members.data.length && getMembers(dappId);
    !roles.data.length && getRoles(dappId);
  };

  const handleChange = event => {
    setState({
      ...state,
      form: _.set(
        { ...state.form },
        event.target.name,
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      ),
    });
  };

  const handleChipChange = (type, data) => {
    console.log(type, 'here', data);
    setState({ ...state, form: { ...state.form, [type]: data } });
  };

  const saveGroupData = () => {
    const newState = JSON.parse(JSON.stringify(state.form));
    newState.createdBy = newState.updatedBy = user?.displayName;
    newState.appId = props.match.params.dappId;
    newState.owner= props.match.params.dappId,
    saveGroup(newState);
  };

  const updateGroupData = () => {
    const newState = JSON.parse(JSON.stringify(state.form));
    newState.updatedBy = user?.displayName;
    newState.appId = props.match.params.dappId;
    newState.owner= props.match.params.dappId,
    updateGroup(newState);
  };

  const canBeSubmitted = () => {
    const newState = JSON.parse(JSON.stringify(state.form));
    if (newState.name && newState.description && newState.members?.length) {
      return true;
    }
  };

  console.log(props, 'inGroup');
  return (
    <>
      <View
        {...props}
        state={state}
        handleChange={handleChange}
        handleChipChange={handleChipChange}
        saveGroupData={saveGroupData}
        updateGroupData={updateGroupData}
        canBeSubmitted={canBeSubmitted}
      />
    </>
  );
};

export default hot(withRouter(Redux(Container)));
