import { Chip, FormControl, Grid, Input, MenuItem, Select, TextField } from '@material-ui/core';
import React from 'react';

const BusinessRule = props => {
  const { selectedstep } = props;
  const [personName, setPersonName] = React.useState([]);
  const handleChange = event => {
    setPersonName(event.target.value);
  };
  const names = ['Rule: Credit Check', 'Rule: Calculate Interest'];
  function getStyles(name, personName) {
    return {
      fontWeight: personName.indexOf(name) === -1 ? 'regular' : 'bold',
    };
  }
  return (
    <>
      {props.value === props.index && (
        <Grid container className="formPmodel">
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <label>Select business rules</label>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  // placeholder="select from following"
                  value={personName}
                  onChange={handleChange}
                  input={<Input id="select-multiple-chip" />}
                  displayEmpty
                  renderValue={selected => (
                    <div
                      style={{
                        display: 'flex',
                        paddingLeft: 5,
                        flexWrap: 'wrap',
                      }}
                    >
                      {selected.map(value => (
                        <li style={{ listStyle: 'none', marginRight: 5, marginBottom: 5 }}>
                          <Chip key={value} label={value} onDelete={() => {}} />
                        </li>
                      ))}
                    </div>
                  )}
                  // MenuProps={MenuProps}
                >
                  <MenuItem value="" disabled>
                    Placeholder
                  </MenuItem>
                  {names.map(name => (
                    <MenuItem key={name} value={name} style={getStyles(name, personName)}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default BusinessRule;
