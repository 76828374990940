import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, pipelines, datasourceTypes }) => {
		return {
			user: user,
			navigation: navigation,
			pipelines: pipelines,
			datasourceTypes: datasourceTypes
		}
	}

	const mapDispatchToProps = {
		getPipelines: Actions.getPipelines,
		getDatasourceTypes: Actions.getDatasourceTypes
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux