import React from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, datasources }) => {
		return {
			user: user,
			navigation: navigation,
			datasources: datasources
		}
	}

	const mapDispatchToProps = {
		getDatasources: Actions.getDatasources,
		deleteDatasource: Actions.deleteDatasource,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux