import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import {
    Button, Checkbox, FormControl, FormControlLabel, FormLabel, NativeSelect, Radio,
    RadioGroup, Tab, Tabs, TextField, Typography, withStyles, Select, MenuItem, Input,
    AppBar, Dialog, DialogActions, DialogTitle, Grid
} from '@material-ui/core'
import * as Actions from 'actions'
import Visibility from './Visibility'
import UI from './UI'
import BasicInfo from './BasicInfo'
import Validation from './Validation'
import Data from './Data'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        "paddingRight": "10px",
    },
    textConditionalField: {
        "height": "30px",
        "marginRight": "30px"
    },
    button: {
        "marginTop": "12px",
    },
    primaryButton: {
        "marginRight": "10px"
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    selectCondition: {
        "width": "380px",
        "paddingTop": "13px"
    },
    tab: {
        "width": "20%"
    },
    modal: {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        position: 'absolute',
    },
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
});

class PropertiesModal extends Component {
    constructor(props) {
        super(props);
        this.elementType = this.props.editElement ? this.props.editElement.data.type : this.props.elementType;
        this.conditionValue = 0;
        this.condition = "";
        this.textField = false;
        if (this.elementType && (this.elementType == 'radio' || this.elementType.endsWith('select') || this.elementType == 'checkbox' || this.elementType == 'chip' || this.elementType == 'tab' ||
            this.elementType == 'multiLine' || this.elementType == "expansionPanels" || this.elementType == "multiStepForm" ||
            this.elementType == "table")) {
            this.showKeyValueOptions = true;
        }
        else {
            this.showKeyValueOptions = false;
        }
        this.key = "";
        this.value = "";
        this.headerValue = "";
        this.headerKey = "";
        if (this.props.editElement) {
            let dataElements = this.props.dataModels.find(dm => {
                return this.props.editElement.data.data.data.data.dataModel === dm._id
            })
            this.state = {
                tabValue: 0,
                updateProps: true,
                name: this.props.editElement.data.data.basic.name,
                elementsData: {
                    basic: this.props.editElement.data.data.basic,
                    data: { data: this.props.editElement.data.data.data.data },
                    headers: this.props.editElement.data.data.data.data,
                    validation: this.props.editElement.data.data.validation,
                    ui: this.props.editElement.data.data.ui ? this.props.editElement.data.data.ui : {},
                    conditionalRequired: this.props.editElement.data.data.conditionalRequired ? this.props.editElement.data.data.conditionalRequired : [],
                    visibility: this.props.editElement.data.data.visibility ? this.props.editElement.data.data.visibility : [],
                    panelVisibility: this.props.editElement.data.data.panelVisibility ? this.props.editElement.data.data.panelVisibility : [],

                },
                dataTabNotFilled: false,
                validationMessage: "",
                dataElements: dataElements?.dataElements,
                dataModel: this.props.editElement.data.data.data.data.dataModel
            }
        }
        else {
            this.state = {
                updateProps: false,
                tabValue: 0,
                clause: '',
                elementsData: {
                    basic: { name: "", label: "", className: "", helpText: "", tag: "1", align: 'left', color: "#120909" },
                    data: {
                        data: {
                            values: [
                            ],
                            defaultValue: "",
                            computed: "Editable",
                            formula: "",
                            functionName: "",
                            headers: [

                            ],
                            rows: [],
                            selectedFunction: "",
                            url: "",
                            dataType: "static",
                            dynamicDataAPI: "",
                            dataModel: null,
                            dataElement: null,
                            workflow: null,
                            dynamicDataFunction: null,
                            role: null,
                            showWhenWorkflowTask: null,
                            lookupDataModal: null,
                            lookupDataElement: null,
                            lookupDataKey: null,
                            lookupDataValue: null,
                            panelVisibility: [],
                        },
                    },
                    validation: { required: false },
                    conditionalRequired: [],
                    visibility: [],
                    ui: { variant: "outlined", labelPosition: "top", alignment: "normal", navigationStyle: 'Default' }
                },
                dataTabNotFilled: false,
                validationMessage: ""
            }
        }
        if (this.elementType == 'number' || this.elementType.includes('text') || this.elementType == 'currency' || this.elementType.includes('date') || this.elementType.includes('time') || this.elementType == 'file' || this.elementType.includes('tel')
            || this.elementType == 'email' || this.elementType == 'password') {
            this.textField = true;
        }
        console.log('this.elementType', this.elementType);
        if(this.elementType == 'file') {
            this.state.elementsData.data.data.fileSave = 'file'
        }
        if(this.elementType == 'pageOrFormPicker') {
            this.state.elementsData.basic.listType = 'page'
        }
        this.state.key = ""
        this.state.value = ""
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('componentDidUpdate', this.state.elementsData.data.data.dataModel);
        if (prevState.updateProps && prevProps.dataModels && prevProps.dataModels.length > 0) {
            let lookupDataModal = prevState.elementsData.data.data.lookupDataModal;
            let dataSelectorDataModal = prevState.elementsData.data.data.dataSelectorDataModal;
            let dataModel = prevState.elementsData.data.data.dataModel;
            this.setState({ updateProps: false })
            if (lookupDataModal) {
                this.handleLookupDataModalChange('lookupDataModal', lookupDataModal)
            }
            if (dataSelectorDataModal) {
                this.handleLookupDataModalChange('dataSelectorDataModal', dataSelectorDataModal)
            }
            if (dataModel) {
                this.handleLookupDataModalChange('dataModel', dataModel)
            }


        }
    }
    componentDidMount() {
        let formElements = [];
        this.props.design.map((row, outerIndex) => {
            row.columns.map((column, index) => {
                if (column.type != "html") {
                    formElements.push({ item: column.name, position: `${outerIndex}-${index}` })
                }
            })
        })
        this.setState({ formElements: formElements });
        let appId = this.props.match.params.dappId;
        this.props.getDataModels(appId);
        if (this.props.workflows.length === 0) {
            this.props.getWorkflows(appId, 'workflow');
        }
        if (this.props.basicInfo && this.props.basicInfo.workflowId) {
            let appId = this.props.match.params.dappId;
            this.props.getWorkFlowTasks(this.props.basicInfo.workflowId, appId)
            this.props.getWorkFlowEvents(this.props.basicInfo.workflowId, appId)
        }

    }
    removeKeyValuePair = (el, variable) => {
        let valCopy = JSON.parse(JSON.stringify(this.state.elementsData.data.data[variable]));
        let newValues = valCopy.filter((item, i) => {
            return el.label != item.label;
        });
        this.setState(prevState => ({
            ...prevState,
            elementsData: {
                ...prevState.elementsData,
                data: {
                    ...prevState.elementsData.data,
                    data: {
                        ...prevState.elementsData.data.data,
                        [variable]: newValues
                    }
                }
            }
        }))
    }
    pushValue = (variable) => {
        let valCopy = JSON.parse(JSON.stringify(this.state.elementsData.data.data[variable]));
        let key = variable == "values" ? this.state.key : this.headerKey;
        let value = variable == "values" ? this.state.value : this.headerValue;
        let exists = valCopy.filter((item, i) => {
            return item.label == key;
        });
        if (exists.length == 0 && key.length > 0 && value.length > 0) {
            valCopy.push({ label: key, value: value });

            this.setState(prevState => ({
                ...prevState,
                elementsData: {
                    ...prevState.elementsData,
                    data: {
                        ...prevState.elementsData.data,
                        data: {
                            ...prevState.elementsData.data.data,
                            [variable]: valCopy
                        }
                    }
                }
            }))
        }
        this.setState({ key: "", value: "" })
    }
    generateValues = () => {
        if (this.state.elementsData.data && this.state.elementsData.data.data
            && Array.isArray(this.state.elementsData.data.data.values)) {
            return this.state.elementsData.data.data.values.map((el, index) => {
                return (
                    <React.Fragment key={index}>
                        <Grid style={{ "padding": "10px" }} item xs={4}>
                            <FormLabel >{el.label}</FormLabel>
                        </Grid>
                        <Grid style={{ "padding": "10px" }} item xs={4}>
                            <FormLabel >{el.value}</FormLabel>
                        </Grid>
                        <Grid style={{ "padding": "10px" }} item xs={4}>
                            <Button variant="contained" color="secondary" onClick={() => {
                                // this.key = el.label;
                                this.state.key = el.label;
                                // this.value = el.value;
                                this.state.value = el.value;
                                this.removeKeyValuePair(el, "values");
                            }} >Edit</Button>
                            <Button variant="contained" color="secondary" onClick={() => {
                                this.removeKeyValuePair(el, "values");
                            }} >-</Button>
                        </Grid>
                    </React.Fragment>
                )
            })
        }
    }
    removeCondition = (item) => {
        let validationData = { ...this.state.elementsData.validation };
        delete validationData[item[0]];
        this.setState({ elementsData: { ...this.state.elementsData, validation: validationData } })
    }
    pushCondition = (e) => {
        if (e && e.target.name == 'allowed') {
            if (!e.target.value || e.target.value == '') {
                let validationData = { ...this.state.elementsData.validation };
                validationData.pattern = "";
                this.setState({ elementsData: { ...this.state.elementsData, validation: validationData } })
                return;
            }
            else {
                let validationData = { ...this.state.elementsData.validation };
                validationData.pattern = e.target.value;
                this.setState({ elementsData: { ...this.state.elementsData, validation: validationData } })
                return;
            }
        }
        if (e != null) {
            let validationData = { ...this.state.elementsData.validation };
            validationData.required = e.target.checked;
            this.setState({ elementsData: { ...this.state.elementsData, validation: validationData } })
            return;
        }
        if (this.condition == "" && this.conditionValue == "") {
            this.setState({ dataTabNotFilled: true, validationMessage: "Please select a validation type" })
            return;
        }
        let validationData = { ...this.state.elementsData.validation };
        validationData[this.condition] = this.conditionValue;
        this.setState({ elementsData: { ...this.state.elementsData, validation: validationData } })

    }
    setBasic = (property, e) => {
        let val = e.target ? (!e.target.value ? "" : e.target.value) : e;
        let basicData = this.state.elementsData.basic;
        let previousName = basicData.name;
        basicData = { ...basicData, [property]: val }
        if (property == 'label') {
            let nameNew = "";
            for (var i = 0; i < basicData.label.length; i++) {
                var ascii = basicData.label.charAt(i).charCodeAt(0);
                if ((ascii >= 65 && ascii <= 90) || (ascii >= 97 && ascii <= 122)) {
                    nameNew += basicData.label.charAt(i);
                }
            }
            basicData.name = nameNew;
        }
        else if (property == 'name') {

            let nameNew = "";
            for (var i = 0; i < basicData.name.length; i++) {
                var ascii = basicData.name.charAt(i).charCodeAt(0);
                if ((ascii >= 65 && ascii <= 90) || (ascii >= 97 && ascii <= 122) || (ascii >= 49 && ascii <= 57)) {
                    nameNew += basicData.name.charAt(i);
                }
                else {
                    this.setState({ dataTabNotFilled: true, validationMessage: "Name cannot contain any special characters!!!" })
                }
            }
            basicData.name = nameNew;
        }
        if (property == 'name' || property == 'label') {
            this.props.modifyValidations(previousName, basicData.name);
        }
        this.setState({ elementsData: { ...this.state.elementsData, basic: basicData } })
    }


    setUi = (property, e) => {
        let val = e.target ? (!e.target.value ? "" : e.target.value) : e;
        let uiData = this.state.elementsData.ui;
        uiData = { ...uiData, [property]: val }
        this.setState({ elementsData: { ...this.state.elementsData, ui: uiData } })
    }

    setData = (property, e) => {
        let val = (e && e.target) ? (e.target.type === 'checkbox' ? e.target.checked : (!e.target.value ? "" : e.target.value)) : e;
        console.log('val == ', val);
        this.setState(prevState => ({
            ...prevState,
            elementsData: {
                ...(JSON.parse(JSON.stringify(prevState.elementsData))),
                data: {
                    ...(JSON.parse(JSON.stringify(prevState.elementsData.data))),
                    data: {
                        ...(JSON.parse(JSON.stringify(prevState.elementsData.data.data))),
                        [property]: val
                    }
                }
            }
        }))
    }
    pushConditionalRequired = () => {
        let conditionalRequiredData = [...this.state.elementsData.conditionalRequired];
        let condition = { element: this.selectElement, operator: this.operator, value: this.conditionalRequiredValue }
        if (this.selectElement == null || this.operator == null || this.conditionalRequiredValue == null) {
            return;
        }
        conditionalRequiredData = conditionalRequiredData.filter((el) => {
            return !(el.element == condition.element && el.operator == condition.operator);
        })
        conditionalRequiredData.push(condition);
        this.setState({ elementsData: { ...this.state.elementsData, conditionalRequired: conditionalRequiredData } })
    }
    removeConditionalRequired = (item, variableName) => {
        let conditionalRequired = [...this.state.elementsData[variableName]];
        conditionalRequired = conditionalRequired.filter((el) => {
            return !(el.element == item.element && el.operator == item.operator && el.value == item.value)
        })
        this.setState({ elementsData: { ...this.state.elementsData, [variableName]: conditionalRequired } })
    }
    pushVisibilityRequired = () => {
        let visibilityData = [...this.state.elementsData.visibility];
        let condition;
        if (this.state.clause) {
            condition = {
                element: this.selectElement,
                operator: this.operator,
                value: this.conditionalRequiredValue,
                clause: this.state.clause,
                element1: this.selectElement1,
                operator1: this.operator1,
                value1: this.conditionalRequiredValue1,
            }
        } else {
            condition = { element: this.selectElement, operator: this.operator, value: this.conditionalRequiredValue }
        }

        if (this.selectElement == null || this.operator == null || this.conditionalRequiredValue == null) {
            return;
        }
        visibilityData = visibilityData.filter((el) => {
            return !(el.element == condition.element && el.operator == condition.operator);
        })
        visibilityData.push(condition);
        this.setState({ elementsData: { ...this.state.elementsData, visibility: visibilityData }, clause: '' })
    }
    pushPanelVisibilityRequired = () => {
        let visibilityData = [...this.state.elementsData.data.data.panelVisibility];
        let condition = { name: this.panelSelectElement, event: this.panelSelectEvent }
        if (this.panelSelectElement == null || this.panelSelectEvent == null) {
            return;
        }
        visibilityData = visibilityData.filter((el) => {
            return !(el.name == condition.name || el.event == condition.event);
        })
        visibilityData.push(condition);

        this.setState(prevState => ({
            ...prevState,
            elementsData: {
                ...prevState.elementsData,
                data: {
                    ...prevState.elementsData.data,
                    data: {
                        ...prevState.elementsData.data.data,
                        panelVisibility: visibilityData
                    }
                }
            }
        }))
    }
    removePanelConditionalRequired = (item, variableName) => {
        let conditionalRequired = [...this.state.elementsData[variableName]];
        conditionalRequired = conditionalRequired.filter((el) => {
            return !(el.name == item.name && el.event == item.event)
        })
        this.setState({ elementsData: { ...this.state.elementsData, [variableName]: conditionalRequired } })
    }
    handleDataModelChange = (event) => {
        let dataElements = this.props.dataModels.find(dm => {
            return event.target.value === dm._id
        })
        this.setState({
            dataElements: dataElements?.dataElements
        })
        this.setData("dataModel", event.target.value)
        this.setData("dataElement", null)
    }

    handleDataElementChange = (event) => {
        this.setData("dataElement", event.target.value)
    }

    handleLookupDataModalChange = (key, id) => {
        console.log('handleLookupDataModalChange', this.props.dataModels, key, id);
        let dataElements = this.props.dataModels.find(dm => {
            return id === dm._id
        })
        console.log('dataElements', dataElements);
        this.setState({
            lookupDataElements: dataElements?.dataElements
        })
        this.setData(key, id)
    }

    handleWorkflowChange = (event) => {
        this.setData("workflow", event.target.value);
        this.getWorkflowEvents(`${API_BASE_URL}/workflows/execute/${event.target.value}/events`)
    }

    getWorkflowEvents(url) {
        axios.post(url, {}, { headers: { 'appId': this.props.match.params.dappId } })
            .then(res => {
                let workflowTask = [];
                for (let i = 0; i < res.data.length; i++) {
                    workflowTask.push({
                        value: res.data[i].id,
                        label: res.data[i].label || res.data[i].name
                    })
                }
                this.setState(prevState => ({
                    ...prevState,
                    elementsData: {
                        ...prevState.elementsData,
                        data: {
                            ...prevState.elementsData.data,
                            data: {
                                ...prevState.elementsData.data.data,
                                values: workflowTask
                            }
                        }
                    }
                }))
            })
    }

    generatePanelVisibility = (conditions) => {
        return conditions && conditions.map((el) => {
            return (
                <>
                    <Grid style={{ "padding": "10px" }} item xs={4}>
                        <FormLabel >{el.event}</FormLabel>
                    </Grid>
                    <Grid style={{ "padding": "10px" }} item xs={4}>
                        <FormLabel >{el.name}</FormLabel>
                    </Grid>
                    <Grid style={{ "padding": "10px" }} item xs={4}>
                        <Button variant="contained" color="secondary" onClick={() => {
                            this.removePanelConditionalRequired(el, 'panelVisibility');
                        }} >-</Button>
                    </Grid>
                </>
            )
        })
    }
    render() {

        const { classes } = this.props;
        return (
            <div style={{
                // flexGrow: 1
            }}>
                <Dialog
                    open={this.state.dataTabNotFilled}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ paper: classes.dialogPaper }}
                    fullWidth={true}
                    maxWidth={'md'}
                >
                    <DialogTitle id="alert-dialog-title">{this.state.validationMessage}</DialogTitle>
                    <DialogActions>
                        <Button color="primary" onClick={() => { this.setState({ dataTabNotFilled: false }) }}>
                            {"Ok"}
                        </Button>
                    </DialogActions>
                </Dialog>
                <AppBar position="static"
                    className="tabPropertiesPanel" >
                    <Tabs
                        aria-label="wrapped label tabs example"
                        value={this.state.tabValue ? this.state.tabValue : 0}
                        onChange={(e, val) => {
                            this.setState({ tabValue: val });
                            this.operator = null;
                            this.conditionalRequiredValue = null;
                            this.selectElement = null
                        }} >
                        <Tab
                            label="Basic"
                            className="h-64 normal-case"
                        // className={classes.tab}
                        />

                        <Tab
                            label="Data"
                            style={this.elementType == 'HTML' ? { display: 'none' } : {}}
                            className="h-64 normal-case"
                        // className={classes.tab}
                        />

                        <Tab
                            label="Validation"
                            style={this.elementType == 'HTML' ? { display: 'none' } : {}}
                            className="h-64 normal-case"
                        // className={classes.tab}
                        />

                        <Tab
                            label="UI"
                            style={this.elementType == 'HTML' ? { display: 'none' } : {}}
                            className="h-64 normal-case"
                        // className={classes.tab}
                        />
                        <Tab
                            label="Visibility"
                            // style={this.elementType == 'HTML' ? { display: 'none' } : {}}
                            className="h-64 normal-case"
                        // className={classes.tab}
                        />
                    </Tabs>
                </AppBar>
                {/* <form className="form save-frm-temp" noValidate autoComplete="off" > */}
                    {this.getBasicTab()}
                    {this.getDataTab()}
                    {this.getValidationTab()}
                    {this.getUiTab()}
                    {this.getVisibilityTab()}
                {/* </form> */}
                <Grid container spacing={8}>
                    <Grid item xs={8}>
                    </Grid>
                    <Grid item xs={4}>
                        {!this.props.editElement && <>
                            <Button variant="contained"
                                className={classes.primaryButton} color="primary"
                                onClick={(e) => { this.handleClose() }}>
                                {"ok"}
                            </Button>
                            <Button variant="contained"
                                color="primary" onClick={(e) => { this.handleCancel() }}>{"cancel"}</Button>
                        </>}
                    </Grid>
                </Grid>
                <Grid container spacing={8}>
                    <Grid item xs={8}>
                    </Grid>
                    <Grid item xs={4}>
                        {this.props.editElement && <>
                            <Button className={classes.primaryButton} variant="contained" color="primary" onClick={(e) => { this.handleEdit() }}>ok</Button>
                            <Button variant="contained"
                                color="primary" onClick={(e) => { this.handleCancel() }}>cancel</Button>
                        </>}
                    </Grid>
                </Grid>
            </div>
        )
    }

    getVisibilityTab = () => {
        const { classes } = this.props;
        const { role, showWhenWorkflowTask } = this.state.elementsData.data.data

        return (<>
            { this.state.tabValue === 4 &&
                <Visibility
                    {...this.props}
                    elementsData={this.state.elementsData}
                    setData={this.setData}
                    pushVisibilityRequired={this.pushVisibilityRequired}
                    formElements={this.state.formElements}
                    clause={this.state.clause}
                    pushPanelVisibilityRequired={this.pushPanelVisibilityRequired}
                    generatePanelVisibility={this.generatePanelVisibility}
                    generateConditionRequired={this.generateConditionRequired}
                    changeSelectElement={(e) => { this.selectElement = e.target.value }}
                    changeOperator={(e) => { this.operator = e.target.value }}
                    changeConditionalRequiredValue={(e) => { this.conditionalRequiredValue = e.target.value }}
                    changeClause={(e) => { this.setState({ clause: e.target.value }) }}
                    changeSelectElement1={(e) => { this.selectElement1 = e.target.value }}
                    changeOperator1={(e) => { this.operator1 = e.target.value }}
                    changeConditionalRequiredValue1={(e) => { this.conditionalRequiredValue1 = e.target.value }}
                    changePanelSelectEvent={(e) => this.panelSelectEvent = e.target.value}
                    changePanelSelectElement={(e) => this.panelSelectElement = e.target.value}
                    removeConditionalRequired={this.removeConditionalRequired}
                />
            }
        </>
        )
    }

    getUiTab = () => {
        return (<>
            {this.state.tabValue === 3 &&
                <UI
                    {...this.props}
                    elementsData={this.state.elementsData}
                    setBasic={this.setBasic}
                    setUi={this.setUi}
                    elementType={this.elementType}
                    showKeyValueOptions={this.showKeyValueOptions}
                    textField={this.textField}
                />
            }
        </>
        )
    }

    getBasicTab = () => {
        return (<>
            {this.state.tabValue === 0 &&
                <BasicInfo
                    {...this.props}
                    elementsData={this.state.elementsData}
                    elementType={this.elementType}
                    setBasic={this.setBasic}
                />
            }
        </>)
    }

    getValidationTab = () => {
        const { classes } = this.props
        return (<>
            {this.state.tabValue === 2 &&
                <Validation
                    {...this.props}
                    elementsData={this.state.elementsData}
                    elementType={this.elementType}
                    pushCondition={this.pushCondition}
                    pushConditionalRequired={this.pushConditionalRequired}
                    textField={this.textField}
                    changeCondition={(e) => { this.condition = e.target.value }}
                    changeConditionValue={(e) => { this.conditionValue = e.target.value }}
                    changeSelectElement={(e) => { this.selectElement = e.target.value }}
                    formElements={this.state.formElements}
                    changeOperator={(e) => { this.operator = e.target.value }}
                    changeConditionalRequiredValue={(e) => { this.conditionalRequiredValue = e.target.value }}
                    changeSelectedFunction={(e) => {
                        this.state.elementsData.data.data.selectedFunction = e.target.value;
                        this.forceUpdate()
                    }}
                    setData={this.setData}
                    setHeaderKey={(e) => {
                        this.headerKey = e.target.value;
                        this.headerValue = e.target.value;
                        this.forceUpdate()
                    }}
                    headerKey={this.headerKey}
                    headerValue={this.headerValue}
                    setHeaderValue={(e) => {
                        this.headerValue = e.target.value;
                        this.forceUpdate()
                    }}
                    pushValue={this.pushValue}
                    removeKeyValuePair={this.removeKeyValuePair}
                    removeCondition={this.removeCondition}
                    removeConditionalRequired={this.removeConditionalRequired}
                />
            }
        </>
        )
    }

    getDataTab = () => {
        const { classes, dataModels } = this.props;
        const { dataElements, lookupDataElements, formElements, elementsData } = this.state;

        const { dataModel, workflow, dataElement, lookupDataModal, lookupDataElement, lookupDataKey,
            lookupDataValue, dataSelectorDataModal, dataSelectorElement, dataSelectorElementForSearch
        } = this.state.elementsData.data.data
        return (<>
            {this.state.tabValue === 1 &&
                <Data
                    {...this.props}
                    elementsData={elementsData}
                    dataElements={dataElements}
                    lookupDataElements={lookupDataElements}
                    formElements={formElements}
                    elementType={this.elementType}

                    setData={this.setData}
                    handleLookupDataModalChange={this.handleLookupDataModalChange}
                    handleDataModelChange={this.handleDataModelChange}
                    handleDataElementChange={this.handleDataElementChange}
                    handleWorkflowChange={this.handleWorkflowChange}
                    showKeyValueOptions={this.showKeyValueOptions}

                    setKey={(e) => {
                        // this.key = e.target.value;
                        // this.forceUpdate()
                        // this.value = e.target.value;
                        // this.forceUpdate()
                        this.setState({ key: e.target.value, value: e.target.value })
                    }}
                    // key={this.key}
                    vkey={this.state.key}
                    // value={this.value}
                    value={this.state.value}
                    setValue={(e) => {
                        this.setState({ value: e.target.value })
                        // this.value = e.target.value; this.forceUpdate() 
                    }}

                    pushValue={this.pushValue}
                    generateValues={this.generateValues}
                    changeSelectedFunction={(e) => {
                        this.state.elementsData.data.data.selectedFunction = e.target.value;
                        this.forceUpdate()
                    }}
                    headerKey={this.headerKey}
                    setHeaderKey={(e) => {
                        this.headerKey = e.target.value;
                        this.headerValue = e.target.value;
                        this.forceUpdate()
                    }}
                    headerValue={this.headerValue}
                    setHeaderValue={(e) => { this.headerValue = e.target.value; this.forceUpdate() }}
                    removeKeyValuePair={this.removeKeyValuePair}
                />
            }
        </>
        )
    }

    validate = () => {
        if (this.elementType == 'button' && this.state.elementsData.data.data.selectedFunction == "api" && this.state.elementsData.data.data.url == "") {
            this.setState({ dataTabNotFilled: true, validationMessage: "Please fill in the required fields in all tabs!!!" })
            return false;
        }
        if (this.elementType == 'button' && this.state.elementsData.data.data.selectedFunction == "function" && this.state.elementsData.data.data.functionName == "") {
            this.setState({ dataTabNotFilled: true, validationMessage: "Please fill in the required fields in all tabs!!!" })
            return false;
        }
        if (this.state.elementsData.basic.name.length == 0) {
            this.setState({ dataTabNotFilled: true, validationMessage: "Please fill in the required fields in all tabs!!!" })
            return false;
        }
        if (this.showKeyValueOptions && this.state.elementsData.data.data.dataType == "dynamic" && (!this.state.elementsData.data || !this.state.elementsData.data.data || !this.state.elementsData.data.data.dynamicDataAPI || this.state.elementsData.data.data.dynamicDataAPI.length == 0)) {
            if (!(this.state.elementsData.data.data.dynamicDataFunction && this.state.elementsData.data.data.dynamicDataFunction.length > 0)) {
                this.setState({ dataTabNotFilled: true, validationMessage: "Please provide api URL for fetching data" })
                return false;
            }
        }


        if (this.showKeyValueOptions && this.state.elementsData.data.data.dataType == "dynamic" && (!this.state.elementsData.data || !this.state.elementsData.data.data || !this.state.elementsData.data.data.dynamicDataFunction || this.state.elementsData.data.data.dynamicDataFunction.length == 0)) {
            if (!(this.state.elementsData.data.data.dynamicDataAPI && this.state.elementsData.data.data.dynamicDataAPI.length > 0)) {
                this.setState({ dataTabNotFilled: true, validationMessage: "Please provide api URL for fetching data" })
                return false;
            }
        }
        if (this.showKeyValueOptions && this.state.elementsData.data.data.dataType == "static" && (!this.state.elementsData.data.data.values || this.state.elementsData.data.data.values.length == 0)) {
            this.setState({ dataTabNotFilled: true, validationMessage: "No key/Value pairs provided" })
            return false;
        }

        let index = this.state.formElements.findIndex(data => data.item == this.state.elementsData.basic.name)

        if (index > -1 && this.state.elementsData.basic.name !== this.state.name) {
            this.setState({ dataTabNotFilled: true, validationMessage: "Form Element name is already used" })
            return false;
        }
        return true;
    }
    handleClose = (e) => {
        if (this.validate()) {
            this.props.handleClose(this.state.elementsData);
        }
    }
    handleCancel = (e) => {
        this.props.handleCancel();
    }
    handleEdit = () => {
        if (this.validate()) {
            this.props.editElementProperties(this.state.elementsData, this.props.editElement.data.type)
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getDataModels: Actions.getDataModels,
        getWorkflows: Actions.getWorkflows,
        getWorkFlowTasks: Actions.getWorkFlowTasks,
        getWorkFlowEvents: Actions.getWorkFlowEvents
    }, dispatch);
}

function mapStateToProps({ dataModels, workflows }) {
    return {
        dataModels: dataModels.data,
        workflow: workflows.workflow,
        workflows: workflows.data,
    }
}

export default withStyles(styles, { withTheme: true })(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(PropertiesModal))
)
