export default {

	GET_PIPELINES: undefined,
	GET_PIPELINES_SUCCESS: undefined,
	GET_PIPELINES_FAILURE: undefined,

	GET_PIPELINE: undefined,
	GET_PIPELINE_SUCCESS: undefined,
	GET_PIPELINE_FAILURE: undefined,

	SAVE_PIPELINE: undefined,
	SAVE_PIPELINE_SUCCESS: undefined,
	SAVE_PIPELINE_FAILURE: undefined,

	UPDATE_PIPELINE: undefined,
	UPDATE_PIPELINE_SUCCESS: undefined,
	UPDATE_PIPELINE_FAILURE: undefined,

	DELETE_PIPELINE: undefined,
	DELETE_PIPELINE_SUCCESS: undefined,
	DELETE_PIPELINE_FAILURE: undefined,

}