import React from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'

import RoutePublic from 'components/RoutePublic'
import RoutePrivate from 'components/RoutePrivate'
import NotFound from 'routes/NotFound'

import {
	Login, Dapps, Dapp, DappFiles, DappConfig, DappTemplates, Members, Member, Roles, Role, Pages, Page, Datasources, PingLogin,
	Datamodels, Datamodel, BusinessRules, Networks, Network, Pipelines, Notifications, Pipeline,
	Jobs, Job, Workflows, Workflow, Forms, Form, Notification, BusinessRule, Datasource, DataWizard, Flowcharts, Flowchart
} from 'routes'
import Entities from './components/Entities'
import Entity from './components/Entity'
import CustomPage from './components/CustomPage';
import Groups from './components/Groups';
import Group from './components/Group';
import FormulaEditor from 'routes/FormulaEditor';
import NewFormula from './components/Formulas/new/NewFormula';

const AppRoutes = (props) => {
	const { user } = props
	return (<Switch>
		<RoutePublic
			isAuthenticated={user.isAuthenticated}
			path="/"
			exact
			//component={PingLogin}
			component={Login}
		/>
		<Route exact path="/login">
		{/*<PingLogin />*/}
			 <Login />
		</Route>
		{/* <RoutePrivate
			isAuthenticated={user.isAuthenticated}
			path="/dapps"
			component={Dapps}
		/> */}
		<Route exact path="/p">
			<CustomPage />
		</Route>
		<Route exact path="/builder">
			<Redirect to="/builder/dapps" />
		</Route>
		<Route exact path="/builder/dapps">
			<Dapps />
		</Route>
		<Route exact path="/builder/dapps/addappconfig">
			<DappConfig />
		</Route>
		<Route exact path="/builder/dapps/addappfiles">
			<DappFiles />
		</Route>
		<Route exact path="/builder/dapps/addapptemplates">
			<DappTemplates />
		</Route>
		<Route exact path="/builder/dapps/:dappId">
			<Dapp />
		</Route>
		<Route exact path="/builder/:dappId/entities">
			<Entities />
		</Route>
		<Route exact path="/builder/:dappId/entities/:entityId">
			<Entity />
		</Route>
		<Route exact path="/builder/:dappId/members">
			<Members />
		</Route>
		<Route exact path="/builder/:dappId/members/:memberId">
			<Member />
		</Route>
		<Route exact path="/builder/:dappId/roles">
			<Roles />
		</Route>
		<Route exact path="/builder/:dappId/groups">
			<Groups />
		</Route>
		<Route exact path="/builder/:dappId/groups/:groupId">
			<Group />
		</Route>
		<Route exact path="/builder/:dappId/pages">
			<Pages />
		</Route>
		<Route exact path="/builder/:dappId/pages/:pageId">
			<Page />
		</Route>
		<Route exact path="/builder/:dappId/forms">
			<Forms />
		</Route>
		<Route exact path="/builder/:dappId/forms/:formId">
			<Form />
		</Route>
		<Route exact path="/builder/:dappId/roles/:roleId">
			<Role />
		</Route>
		<Route exact path="/builder/:dappId/datasources">
			<Datasources />
		</Route>
		<Route exact path="/builder/:dappId/datawizard">
			<DataWizard />
		</Route>
		<Route exact path="/builder/:dappId/datamodels">
			<Datamodels />
		</Route>
		<Route exact path="/builder/:dappId/businessrules">
			<BusinessRules />
		</Route>
		<Route exact path="/builder/:dappId/businessrules/:businessRuleId">
			<BusinessRule />
		</Route>
		<Route exact path="/builder/:dappId/networks">
			<Networks />
		</Route>
		<Route exact path="/builder/:dappId/pipelines">
			<Pipelines />
		</Route>
		<Route exact path="/builder/:dappId/pipelines/:pipelineId">
			<Pipeline />
		</Route>
		<Route exact path="/builder/:dappId/notifications">
			<Notifications />
		</Route>
		<Route exact path="/builder/:dappId/notifications/:notificationId">
			<Notification />
		</Route>
		<Route exact path="/builder/:dappId/networks/:networkId">
			<Network />
		</Route>
		<Route exact path="/builder/:dappId/jobs">
			<Jobs />
		</Route>
		<Route exact path="/builder/:dappId/workflows">
			<Workflows />
		</Route>
		<Route exact path="/builder/:dappId/workflows/new">
			<Workflow editable />
		</Route>
		{/* <Route exact path="/builder/:dappId/workflows/:id">
			<Workflow editable />
		</Route> */}
		<Route exact path="/builder/:dappId/flowcharts">
			<Flowcharts />
		</Route>
		<Route exact path="/builder/:dappId/flowcharts/new">
			<Flowchart editable />
		</Route>
		<Route exact path="/builder/:dappId/flowcharts/:id">
			<Flowchart editable />
		</Route>
		<Route exact path="/builder/:dappId/workflows/:id">
			<Flowchart editable />
		</Route>
		<Route exact path="/builder/:dappId/forms">
			<Forms />
		</Route>
		<Route exact path="/builder/:dappId/jobs/:jobId">
			<Job />
		</Route>
		<Route exact path="/builder/:dappId/datamodels/:datamodelId">
			<Datamodel />
		</Route>
		<Route exact path="/builder/:dappId/datasources/:datasourceId">
			<Datasource />
		</Route>
		<Route exact path="/builder/:dappId/formulas">
			<FormulaEditor />
		</Route>
		<Route exact path="/builder/:dappId/formula/:formulaid">
			<NewFormula {...props} />
		</Route>
		{/* <Route exact path="/selecttemplate">
			<SelectTemplate />
		</Route>
		<Route exact path="/selecttemplateless">
			<SelectTemplateLess />
		</Route>
		<Route exact path="/quickwizard">
			<Quickwizard />
		</Route>
		<Route exact path="/quickwizardbasicinfo">
			<QuickWizardBasicInfo />
		</Route>
		<Route exact path="/createnewapplication">
			<CreateNewApplication />
		</Route>
		<Route exact path="/abcuserinterfacetabs">
			<AbcUserInterfaceTabs />
		</Route>
		<Route exact path="/abcselecttablecollection">
			<AbcSelectTableCollection />
		</Route>
		<Route exact path="/abcselectpipelines">
			<AbcSelectPipelines />
		</Route>
		<Route exact path="/abcselectpipelinepagetwo">
			<AbcSelectPipelinepageTwo />
		</Route>
		<Route exact path="/abcselectedpipelinedetails">
			<AbcSelectedPipelinedetails />
		</Route>
		<Route exact path="/abcselectcolumnfields">
			<AbcSelectColumnFields />
		</Route>
		<Route exact path="/abcmysqlserver">
			<AbcMysqlServer />
		</Route>
		<Route exact path="/abccompanyuserinterface">
			<AbcCompanyUserInterface />
		</Route>
		<Route exact path="/abccompanydata">
			<AbcCompanyData />
		</Route>
		<Route exact path="/abccompanybusinesslogic">
			<AbcCompanyBusinessLogic />
		</Route>
		<Route exact path="/flowchartelements">
			<FlowchartElements />
		</Route>
		<Route exact path="/abcuserinterfaceaddnewpage">
			<AbcUserInterfaceAddNewPage />
		</Route> */}
		<Route component={NotFound} />
	</Switch>)
}
export default AppRoutes
