import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

export default function CollageType1(props) {
	return (
		<List className={props.classes.root} style={{height: "100%"}}>
			{props.groupingData.map((gData,i) => {
				return (<React.Fragment key={i}>
					<ListItem style={{
						textAlign: "center"
					}}>
						<ListItemText
							primary={gData[props.selectedGroupingDataElement[0].value]}
							secondary={gData[props.selectedDataElement[0].value]} />
					</ListItem>
					<Divider component="li" />
				</React.Fragment>)
			})}
		</List>
	);
}