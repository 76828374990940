import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './View'
import Redux from './Redux'
import { withRouter } from 'react-router-dom';

let initialDiagram = {
	steps: [{
		"id": "vyjr", "type": "pool", "x": 110, "y": 70, "inputConnectors": [], "outputConnectors": [],
		"height": 350, "width": 750,
		"d": "M35,0 L35 290", "x1": 110, "y1": 70, "x2": 660, "y2": 360
	}],
	style: { "backgroundColor": "#eee" },
	height: 570,
	width: '100%' //window.innerWidth - 325 //100px for drggable elements
}
const Container = (props) => {
	const [state, setState] = useState({
		tabValue: 0,
		dataModels: [], dataCleaningTools: [], dataQualityTools: [],
		diagram: initialDiagram,
		useCustom: true,
		isSubmitButton: true,
		pipelinejson: ''
	});


	const handleChange = (event) => setState({
		...state,
		[event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
	})

	const handleDiagramChange = (diagram) => setState({ ...state, diagram })

	const updatePipelineState = () => {
		const params = props.match.params;
		const { pipelineId, dappId } = params;
		if (pipelineId === 'new') {
			props.newPipeline();
		}
		else {
			props.getPipeline(props.match.params);
		}
	};

	const handleChipChange = (event, name) => {
		setState({ ...state, [name]: event.target.value });
	};

	const canBeSubmitted = () => {
		const { name } = state;
		// return (
		// 	!_.isEqual(props.pipeline.data, state) && state.isSubmitButton
		// );
		return true
	}

	useEffect(() => {
		updatePipelineState();
	}, [props.location])

	useEffect(() => {
		console.log('props.pipeline ==>', props.pipeline);
		// if (props.match.params.pipelineId !== 'new') {
		if (props.pipeline.data) {
			const { diagram } = props.pipeline.data
			console.log('diagram = == ', diagram);
			setState({
				...state,
				...props.pipeline.data,
				diagram: diagram ? diagram : initialDiagram
			})
		}
		// }
	}, [props.pipeline.data])

	useEffect(() => {
		if (props.saveSuccess) {
			props.showAlert('Pipeline saved successfully', { id: "pipeline", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/pipelines/${props.pipeline.data._id}` });
		}
		if (props.updateSuccess) {
			props.showAlert('Pipeline updated successfully', { id: "pipeline", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/${props.match.params.dappId}/pipelines/${props.pipeline.data._id}` });
		}
	}, [props.saveSuccess, props.updateSuccess])

	useEffect(() => {
		console.log('props.error', props.error);
		if (props.error && props.error.message)
			props.showAlert(props.error.message + ".  " + (props.error.errStack ? props.error.errStack : ''), { id: "pipeline", variant: 'danger' });
	}, [props.error])

	const savePipelineData = () => {
		// setState({ ...state, isSubmitButton: false });
		console.log('savePipelineData - state', state);
		props.savePipeline({ ...state, appId: props.match.params.dappId });
	}

	const updatePipelineData = () => {
		// setState({ ...state, isSubmitButton: false });
		console.log('savePipelineData - state', state);
		props.updatePipeline({ ...state, appId: props.match.params.dappId });
	}

	useEffect(() => {
		console.log('state ==== ', state);
	}, [state])

	return (
		<View
			{...props}
			state={state}
			handleChange={handleChange}
			handleChipChange={handleChipChange}
			canBeSubmitted={canBeSubmitted}
			savePipeline={() => savePipelineData()}
			updatePipeline={() => updatePipelineData()}
			diagramChange={handleDiagramChange}
		/>
	)
}

export default hot(withRouter(Redux(Container)))