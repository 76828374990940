export default {

	GET_DATASOURCES: undefined,
	GET_DATASOURCES_SUCCESS: undefined,
	GET_DATASOURCES_FAILURE: undefined,

	DELETE_DATASOURCE: undefined,
	DELETE_DATASOURCE_SUCCESS: undefined,
	DELETE_DATASOURCE_FAILURE: undefined,

	DELETE_DATASOURCES: undefined,
	DELETE_DATASOURCES_SUCCESS: undefined,
	DELETE_DATASOURCES_FAILURE: undefined,

	GET_DATASOURCE_BY_NAME: undefined,
	GET_DATASOURCE: undefined,
	GET_DATASOURCE_SUCCESS: undefined,
	GET_DATASOURCE_FAILURE: undefined,

	SAVE_DATASOURCE: undefined,
	SAVE_DATASOURCE_SUCCESS: undefined,
	SAVE_DATASOURCE_FAILURE: undefined,

	UPDATE_DATASOURCE: undefined,
	UPDATE_DATASOURCE_SUCCESS: undefined,
	UPDATE_DATASOURCE_FAILURE: undefined,

	TEST_DATASOURCE: undefined,
	TEST_DATASOURCE_SUCCESS: undefined,
	TEST_DATASOURCE_FAILURE: undefined,

	TEST_QLDB_CONNECTION: undefined,
	TEST_QLDB_CONNECTION_SUCCESS: undefined,
	TEST_QLDB_CONNECTION_FAILURE: undefined,

	TEST_S3_CONNECTION: undefined,
	TEST_S3_CONNECTION_SUCCESS: undefined,
	TEST_S3_CONNECTION_FAILURE: undefined,

	SET_DATASOURCES_SEARCH_TEXT: undefined,

}