const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
import { request } from 'modules/client'

export class JobsService {

    static getJobs(appId) {
        const uri = API_BASE_URL + "/jobs"
        return request(uri, {
            method: 'GET',
            headers: { 'appId': appId }
        })
    }

    static getJob(params) {
        const uri = API_BASE_URL + "/jobs/" + params.jobId
        return request(uri, {
            method: 'GET',
            headers: { 'appId': params.dappId }
        })
    }

    static saveJob(data) {
        const uri = API_BASE_URL + "/jobs/"
        return request(uri, {
            method: 'POST',
            headers: { 'appId': data.appId },
            payload: data
        })
    }

    static updateJob(data) {
        const uri = API_BASE_URL + "/jobs/" + data._id
        return request(uri, {
            method: 'PATCH',
            headers: { 'appId': data.appId },
            payload: data
        })
    }

    static deleteJob(data) {
        const uri = API_BASE_URL + "/jobs/" + data._id
        return request(uri, {
            method: 'DELETE',
            headers: { 'appId': data.appId },
        })
    }
}