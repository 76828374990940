import React from "react";

function DeleteIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      x={props.x}
      y={props.y}
      onClick={props.onClick}
      enableBackground="new 0 0 488.936 488.936"
      version="1.1"
      viewBox="0 0 488.936 488.936"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M381.16 111.948H107.376c-6.468 0-12.667 2.819-17.171 7.457-4.504 4.649-6.934 11.014-6.738 17.477l9.323 307.69c.39 12.92 10.972 23.312 23.903 23.312h20.136v-21.012c0-24.121 19.368-44.049 43.488-44.049h127.896c24.131 0 43.893 19.928 43.893 44.049v21.012h19.73c12.933 0 23.52-10.346 23.913-23.268l9.314-307.7c.195-6.462-2.234-12.863-6.738-17.513-4.504-4.636-10.691-7.455-17.165-7.455z"></path>
      <path d="M309.166 435.355H181.271c-6.163 0-11.915 4.383-11.915 11.516v30.969c0 6.672 5.342 11.096 11.915 11.096h127.895c6.323 0 11.366-4.773 11.366-11.096v-30.969c0-6.31-5.043-11.516-11.366-11.516zM427.696 27.106C427.696 12.138 415.563 0 400.591 0H88.344C73.372 0 61.239 12.138 61.239 27.106v30.946c0 14.973 12.133 27.106 27.105 27.106H400.59c14.973 0 27.105-12.133 27.105-27.106l.001-30.946z"></path>
    </svg>
  );
}

export default DeleteIcon;