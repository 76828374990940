import React from 'react';

const SendTask = props => {
  return (
    <svg
      id="forms"
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      viewBox="0 0 41 41"
      x={props.x}
      y={props.y}
      onClick={props.onClick}
    >
      <rect
        id="Rectangle_597"
        data-name="Rectangle 597"
        width="41"
        height="41"
        fill="#fff"
        opacity="0"
      />
      <path
        id="google-forms"
        d="M41.471,0H30.188A1.56,1.56,0,0,0,28.63,1.558v25.37a1.56,1.56,0,0,0,1.558,1.558H48.123a1.56,1.56,0,0,0,1.558-1.558V7.691a1.548,1.548,0,0,0-.456-1.1L42.555.439A1.547,1.547,0,0,0,41.471,0Zm6.263,26.538H30.578V1.948H39.9V7.172a2.094,2.094,0,0,0,2.091,2.092h5.745ZM41.988,7.317a.144.144,0,0,1-.143-.145v-4.1l4.541,4.245h-4.4ZM33.417,12.7a1.21,1.21,0,1,0,1.21,1.21A1.212,1.212,0,0,0,33.417,12.7Zm2.207.237H45.948v1.948H35.625ZM33.417,16.6a1.21,1.21,0,1,0,1.21,1.21A1.212,1.212,0,0,0,33.417,16.6Zm2.207.236H45.948V18.78H35.625Zm-2.207,3.659a1.21,1.21,0,1,0,1.21,1.21A1.212,1.212,0,0,0,33.417,20.491Zm2.207.236H45.948v1.948H35.625Z"
        transform="translate(-18.63 6)"
        fill="#000"
      />
    </svg>
  );
};

export default SendTask;
