import React, { Component } from 'react'
import { TextField, Typography } from '@material-ui/core'

class Theme extends Component {
	render() {
		let { classes, values, handleChange, handleUIChange } = this.props
		let s = {
			width: "30ch",
			margin: "8px"
		}
		return (<div className="dapp-theme">
			<form className="form" noValidate autoComplete="off" >
				<Typography className="text-16 sm:text-20 truncate fw-600">Header Configuration</Typography>
				<TextField
					className="mt-8 mb-16"
					label="Style Object"
					id="header-style"
					name="header-style"
					value={values.headerCSS ? values.headerCSS.style : ""}
					onChange={(e) => handleUIChange(e, "headerCSS")}
					variant="outlined"
					style={s}
				/>
				<TextField
					className="mt-8 mb-16"
					label="Class Name"
					id="header-className"
					name="header-className"
					value={values.headerCSS ? values.headerCSS.className : ""}
					onChange={(e) => handleUIChange(e, "headerCSS")}
					variant="outlined"
					style={s}
				/>
				<TextField
					className="mt-8 mb-16"
					label="BackGround Color"
					id="header-backgroundColor"
					name="header-backgroundColor"
					value={values.headerCSS ? values.headerCSS.backgroundColor : ""}
					onChange={(e) => handleUIChange(e, "headerCSS")}
					variant="outlined"
					style={s}
				/>
				<TextField
					id="header-fontColor"
					name="header-fontColor"
					label="Font Color"
					type="text"
					className={classes.formControl}
					value={values.headerCSS ? values.headerCSS.fontColor : ""}
					onChange={(e) => handleUIChange(e, "headerCSS")}
					variant="outlined"
					style={s}
				/>
				<Typography className="text-16 sm:text-20 truncate fw-600">Footer Configuration</Typography>
				<TextField
					className="mt-8 mb-16"
					label="Style Object"
					id="footer-style"
					name="footer-style"
					value={values.footerCSS ? values.footerCSS.style : ""}
					onChange={(e) => handleUIChange(e, "footerCSS")}
					variant="outlined"
					style={s}
				/>
				<TextField
					className="mt-8 mb-16"
					label="Class Name"
					id="footer-className"
					name="footer-className"
					value={values.footerCSS ? values.footerCSS.className : ""}
					onChange={(e) => handleUIChange(e, "footerCSS")}
					variant="outlined"
					style={s}
				/>
				<TextField
					className="mt-8 mb-16"
					label="BackGround Color"
					id="footer-backgroundColor"
					name="footer-backgroundColor"
					value={values.footerCSS ? values.footerCSS.backgroundColor : ""}
					onChange={(e) => handleUIChange(e, "footerCSS")}
					variant="outlined"
					style={s}
				/>
				<TextField
					id="footer-fontColor"
					name="footer-fontColor"
					label="Font Color"
					type="text"
					className={classes.formControl}
					value={values.footerCSS ? values.footerCSS.fontColor : ""}
					onChange={(e) => handleUIChange(e, "footerCSS")}
					variant="outlined"
					style={s}
				/>
			</form>
		</div>)
	}
}
export default Theme;