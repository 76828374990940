import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, network, entities }) => {
		return {
			user: user,
			navigation: navigation,
			entities: entities,
			network: network
		}
	}

	const mapDispatchToProps = {
		getEntities: Actions.getEntities,
		getNetwork: Actions.getNetwork,
		saveNetwork: Actions.saveNetwork,
		newNetwork: Actions.newNetwork,
		updateNetwork: Actions.updateNetwork,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux