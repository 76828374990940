import { ActionTypes } from 'constants/index'

const initialState = {
    data: null,
    searchText: "",
    error: "",
    loading: false,
    saveSuccess: false,
	updateSuccess: false
}

const pipelineReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_PIPELINE_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                saveSuccess: false,
	            updateSuccess: false
            }
        }
        case ActionTypes.GET_PIPELINE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.SAVE_PIPELINE_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload,
                saveSuccess: true
            }
        }
        case ActionTypes.SAVE_PIPELINE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.UPDATE_PIPELINE_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload,
                updateSuccess: true
            }
        }
        case ActionTypes.UPDATE_PIPELINE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        default: {
            return state;
        }
    }
};

export default pipelineReducer;
