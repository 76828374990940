import { ActionTypes } from 'constants/index'

const initialState = {
    data: [],
    searchText: "",
    error: "",
    loading: false,
    notification: {}
}

const notificationsReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_NOTIFICATIONS: {
            return {
                ...state,
                loading: true,
            }
        }
        case ActionTypes.GET_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                data: action.payload
            }
        }
        case ActionTypes.GET_NOTIFICATIONS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.DELETE_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: [...state.data.filter(item => item !== action.payload)] 
            }
        }
        case ActionTypes.DELETE_NOTIFICATION_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        default: {
            return state;
        }
    }
};

export default notificationsReducer;
