
import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const MessageDialog = (Msg, callback) => {
	return (<Dialog
		open={true}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description">
		<DialogTitle id="alert-dialog-title">{Msg}</DialogTitle>
		<DialogActions>
			<Button color="primary" onClick={() => { callback(); }}>
				{"Ok"}
			</Button>
		</DialogActions>
	</Dialog>)
}