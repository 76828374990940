import React, { Component, useState } from 'react'
import {
	TextField, FormControl, Select, Grid,
	MenuItem, FormGroup, FormLabel, Button
} from '@material-ui/core'
import 'styles/page-designer.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'modules/lodash'
import { withRouter } from 'react-router-dom'

const dataTypeNumList = [{ "value": "is_equal_to", "name": "Is equal to" }, { "value": "is_greater_than", "name": "Is greater than" }, { "value": "is_less_than", "name": "Is less than" }, , { "value": "in_between", "name": "In between" }]
const dataTypeStringList = [{ "value": "starts_with", "name": "Starts with" }, { "value": "ends_with", "name": "Ends with" }, , { "value": "matches", "name": "Matches" }, { "value": "includes", "name": "Includes" }, { "value": "is_equal_to", "name": "Is equal to" }, { "value": "is_greater_than", "name": "Is greater than" }, { "value": "is_less_than", "name": "Is less than" }, , { "value": "in_between", "name": "In between" }]

const Privileges = (props) => {
	let { classes, values, selectedComparatorList, dataModels } = props;

	console.log("value.dataElementss", values.dataElements);
	console.log("values.privilege", values.privilege);
	console.log("props.forms", props.forms);

	const [valueList, setValueList] = useState([])

	return (<>
		<FormGroup row className="items-center">
			<FormLabel component="legend">
				{"Privileges"}
			</FormLabel>
		</FormGroup>

		<FormGroup row className="items-center  max-w-full">
			<Grid container>
				<Grid item xs={2} style={{ "padding": "5px" }}>
					<FormControl fullWidth variant="outlined" className={classes.formControl}>
						<Select
							inputProps={{
								name: 'privilegeType',
								id: 'privilegeType',
							}}
							className="custom-select"
							onChange={(e) => {
								props.onPrivilegeChangeHandler(e)
								console.log("props.forms.data", props.forms);
								console.log("props.page.data", props.pages);
								setValueList(e.target.value === "Forms" ? props.forms : props.pages)
							}}
							value={values.privilege.privilegeType || ""}>
							<MenuItem value="">
								<em>{"Select Type"}</em>
							</MenuItem>
							<MenuItem value="Pages">{'Pages'}</MenuItem>
							<MenuItem value="Forms">{'Forms'}</MenuItem>
							<MenuItem value="DataModels">{'Data Model'}</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={2} style={{ "padding": "5px" }}>
					<FormControl fullWidth variant="outlined" className={classes.formControl}>
						<Select
							value={values.privilege.privilegeValue || ""}
							onChange={props.onPrivilegeChangeHandler}
							inputProps={{
								name: 'privilegeValue',
								id: 'privilegeValue',
							}}>
							<MenuItem value="">
								<em>{"Select Operator"}</em>
							</MenuItem>
							{/* values.privilege.privilegeType === "Forms" ? props.forms : props.pages */}
							{valueList.map((element, i) => {
								return <MenuItem key={i} value={element.name}>{element.name}</MenuItem>
							})}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={2} style={{ "padding": "5px" }}>
					<FormControl fullWidth variant="outlined" className={classes.formControl}>
						<Select
							value={values.privilege.privilegeAction || ""}
							onChange={props.onPrivilegeChangeHandler}
							inputProps={{
								name: 'privilegeAction',
								id: 'privilegeAction',
							}}>
							<MenuItem value="">
								<em>{"Select Action"}</em>
							</MenuItem>
							<MenuItem value="*">{'All'}</MenuItem>
							<MenuItem value="New">{'New'}</MenuItem>
							<MenuItem value="Read">{'Read'}</MenuItem>
							<MenuItem value="Edit">{'Edit'}</MenuItem>
							<MenuItem value="Delete">{'Delete'}</MenuItem>
							<MenuItem value="Admin">{'Admin'}</MenuItem>
							<MenuItem value="Manage">{'Manage'}</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={2} style={{ "padding": "5px 20px" }}>
					<Button
						color="secondary"
						variant="outlined"
						className={classes.formControl}
						onClick={() => {
							props.addPrivilege(values.privilege);
						}}
						// disabled={!this.canBeSubmitted()}
						fullWidth >
						{"Add"}
					</Button>
				</Grid>
			</Grid>
		</FormGroup>
	</>)
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}

function mapStateToProps({ dataModels }) {
	return {
		dataModels: dataModels,
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Privileges)
)