import { ActionTypes } from 'constants/index'
import Utils from 'packages/page/utils';

export const setDatasourcesSearchText = (value) => dispatch => {
	return dispatch({ type: ActionTypes.SET_DATASOURCES_SEARCH_TEXT, searchText: value })
}

export const getDatasources = (appId) => dispatch => {
	return dispatch({ type: ActionTypes.GET_DATASOURCES, payload: appId })
}


export const emptyDataElements = () => dispatch => {
	return dispatch({ type: ActionTypes.GET_DATAELEMENTS, payload: [] })
}

export const getDatasource = (params) => dispatch => {
	return dispatch({ type: ActionTypes.GET_DATASOURCE, payload: params })
}

export const getDatasourceByName = (name, appId) => dispatch => {
	return dispatch({ type: ActionTypes.GET_DATASOURCE_BY_NAME, payload: { name, appId } })
}

export const saveDatasource = (data) => dispatch => {
	return dispatch({ type: ActionTypes.SAVE_DATASOURCE, payload: data })
}

export const updateDatasource = (data) => dispatch => {
	if (data.dsType === 'NOSQL' && data.noSQLPassword) {
		data.noSQLPassword = btoa(data.noSQLPassword);
	}
	return dispatch({ type: ActionTypes.UPDATE_DATASOURCE, payload: data })
}

export const deleteDatasource = (data) => dispatch => {
	return dispatch({ type: ActionTypes.DELETE_DATASOURCE, payload: data })
}

export const testDatasource = (data, appId) => dispatch => {
	if (data.dsType === 'NOSQL' && data.noSQLPassword) {
		data.noSQLPassword = btoa(data.noSQLPassword);
	}
	return dispatch({ type: ActionTypes.TEST_DATASOURCE, payload: { data, appId } })
}

export const testQLDBConnection = (data, appId) => dispatch => {
	let ledger = { "name": "", "region": "" };
	ledger.name = data.qldbLedger;
	ledger.region = 'us-east-2';
	return dispatch({ type: ActionTypes.TEST_QLDB_CONNECTION, payload: { ledger, appId } })
}

export const testS3Connection = (data, appId) => dispatch => {
	return dispatch({ type: ActionTypes.TEST_S3_CONNECTION, payload: { data, appId } })
}

export const getDataElements = (datasourceId, collectionName, appId) => dispatch => {
	return dispatch({ type: ActionTypes.GET_DATAELEMENTS, payload: { datasourceId, collectionName, appId } })
}

export const newDatasource = () => dispatch => {
	const data = {
		id: Utils.generateGUID(),
		name: '',
		handle: '',
		description: '',
		dsType: '',
		active: true
	}
	return dispatch({ type: ActionTypes.GET_DATASOURCE_SUCCESS, payload: data })
}