import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { MemberService } from 'services/index'
import { ActionTypes } from 'constants/index'


async function fetchAsync(func, arg) {
	const response = arg ? await func(arg) : await func()
	return response
}

function* getMembers(action) {
	try {
		const roles = yield call(fetchAsync, MemberService.getMembers, action.payload)
		yield put({ type: ActionTypes.GET_MEMBERS_SUCCESS, payload: roles })
	} catch (e) {
		yield put({ type: ActionTypes.GET_MEMBERS_FAILURE, error: e.message })
	}
}

function* getMember(action) {
	try {
		const member = yield call(fetchAsync, MemberService.getMember, action.payload)
		yield put({ type: ActionTypes.GET_MEMBER_SUCCESS, payload: member })
	} catch (e) {
		yield put({ type: ActionTypes.GET_MEMBER_FAILURE, error: e.message })
	}
}

function* getMemberByUserId(action) {
	try {
		const member = yield call(fetchAsync, MemberService.getMemberByUserId, action.payload)
		yield put({ type: ActionTypes.GET_MEMBER_BY_USERID_SUCCESS, payload: member })
	} catch (e) {
		yield put({ type: ActionTypes.GET_MEMBER_BY_USERID_FAILURE, error: e.message })
	}
}

function* saveMember(action) {
	try {
		const member = yield call(fetchAsync, MemberService.saveMember, action.payload)
		yield put({ type: ActionTypes.SAVE_MEMBER_SUCCESS, payload: member })
	} catch (e) {
		let errObj = { firstName: false, lastName: false, email: false };
		if (e.response) {
			errObj = e.response.fields;
			errObj.errorMessage = e.response.message ? e.response.message : "";
			errObj.status = e.response.status ? e.response.status : ""
		}
		yield put({ type: ActionTypes.SAVE_MEMBER_FAILURE, error: errObj })
	}
}

function* updateMember(action) {
	try {
		const member = yield call(fetchAsync, MemberService.updateMember, action.payload)
		yield put({ type: ActionTypes.UPDATE_MEMBER_SUCCESS, payload: member })
	} catch (e) {
		let errObj = { firstName: false, lastName: false, email: false };
		if (e.response) {
			errObj = e.response.fields;
			errObj.errorMessage = e.response.message ? e.response.message : "";
			errObj.status = e.response.status ? e.response.status : ""
		}
		yield put({ type: ActionTypes.UPDATE_MEMBER_FAILURE, error: errObj })
	}
}

function* deleteMember(action) {
	try {
		const member = yield call(fetchAsync, MemberService.deleteMember, action.payload)
		yield put({ type: ActionTypes.DELETE_MEMBER_SUCCESS, payload: member })
	} catch (e) {
		yield put({ type: ActionTypes.DELETE_MEMBER_FAILURE, error: e.message })
	}
}
function* disableMember(action) {
	try {
		const member = yield call(fetchAsync, MemberService.disableMember, action.payload)
		yield put({ type: ActionTypes.DISABLE_MEMBER_SUCCESS, payload: member })
		yield put({ type: ActionTypes.SHOW_ALERT, payload: {
			id: 'member',
			message: member.message,
			position: 'top-center',
			variant: 'success',
		}})
	} catch (e) {
		yield put({ type: ActionTypes.DISABLE_MEMBER_FAILURE, error: e.message })
	}
}

export function* watchMembersSaga() {
	yield takeEvery(ActionTypes.GET_MEMBERS, getMembers)
	yield takeEvery(ActionTypes.GET_MEMBER, getMember)
	yield takeEvery(ActionTypes.GET_MEMBER_BY_USERID, getMemberByUserId)
	yield takeEvery(ActionTypes.SAVE_MEMBER, saveMember)
	yield takeEvery(ActionTypes.UPDATE_MEMBER, updateMember)
	yield takeEvery(ActionTypes.DELETE_MEMBER, deleteMember)
	yield takeEvery(ActionTypes.DISABLE_MEMBER, disableMember)
}

export default watchMembersSaga;