import {
	TextBoxJson, LabelJson, RadioGroupJson, ChipJson, TextAreaJson, HTMLJson,
	SwitchJson, ButtonJson, Slider, CheckboxesGroupJson, DateJson, SelectJson, HeaderJson,
	SpecificTextBoxJson, SliderJson, TabJson, ExpansionPanelJson, CardJson, MultiLineJson,
	MultiStepFormJson, TableJson, NamePickerJson, DataSelectorJson, DataTableJson, ReportConfiguratorJson,
	PageOrFormPickerJson, WorksheetJson
} from './JsonDataGenerator'
export const getJson = (draggedElement, data) => {

	if (draggedElement === 'textBox') {
		return TextBoxJson(data);
	}
	if (draggedElement === 'textArea') {
		return TextAreaJson(data);
	}
	else if (draggedElement === 'HTML') {
		return HTMLJson(data);
	}
	else if (draggedElement === 'header') {
		return HeaderJson(data);
	}
	else if (draggedElement === 'label') {
		return LabelJson(data);
	}
	else if (draggedElement === 'radio') {
		return RadioGroupJson(data);
	}

	else if (draggedElement === 'checkbox') {
		return CheckboxesGroupJson(data);
	}
	else if (draggedElement === 'select') {
		return SelectJson(data)
	}
	else if (draggedElement === 'namePicker') {
		return NamePickerJson(data)
	}
	else if (draggedElement === 'pageOrFormPicker') {
		return PageOrFormPickerJson(data)
	}
	else if (draggedElement === 'dataSelector') {
		return DataSelectorJson(data)
	}
	else if (draggedElement === 'dataTable') {
		return DataTableJson(data)
	}
	else if (draggedElement === 'reportConfigurator') {
		return ReportConfiguratorJson(data)
	}
	else if (draggedElement === 'multiselect') {
		data.basic.multi = true;
		data.basic.type = 'multiselect'
		return SelectJson(data)
	}
	else if (draggedElement === 'date') {
		return DateJson(data);
	}
	else if (draggedElement === 'button') {
		return ButtonJson(data);
	}

	else if (draggedElement === 'slider') {
		return SliderJson(data);
	}

	else if (draggedElement === 'switch') {
		return SwitchJson(data);
	}
	else if (draggedElement === 'chip') {
		return ChipJson(data);
	}
	else if (draggedElement === 'tab') {
		return TabJson(data);
	}
	else if (draggedElement === 'card') {
		return CardJson(data);
	}
	else if (draggedElement === 'multiLine') {
		return MultiLineJson(data);
	}
	else if (draggedElement === 'expansionPanels') {
		return ExpansionPanelJson(data);
	}
	else if (draggedElement === 'multiStepForm') {
		return MultiStepFormJson(data);
	}
	else if (draggedElement === 'table') {
		return TableJson(data);
	}
	else if (draggedElement === 'worksheet') {
		return WorksheetJson(data);
	}
	else if (draggedElement != null) {
		data.basic.typeOfText = draggedElement;
		if (data.basic.typeOfText == 'currency') {
			data.basic.step = "0.01";
		}
		return SpecificTextBoxJson(data);
	}

}