import axios from 'axios/index';
import Utils from 'packages/page/utils'
import { ActionTypes } from 'constants/index'
import { Query, Builder, BasicConfig, Utils as QbUtils } from 'react-awesome-query-builder';

export const getBusinessRules = (appId) => dispatch => {
	return dispatch({ type: ActionTypes.GET_BUSINESSRULES, payload: appId })
}

export const setBusinessRulesSearchText = (value) => dispatch => {
	return dispatch({ type: ActionTypes.SET_BUSINESSRULES_SEARCH_TEXT, searchText: value })
}


export const getBusinessRule = (params) => dispatch => {
	return dispatch({ type: ActionTypes.GET_BUSINESSRULE, payload: params })
}

export const saveBusinessRule = (data) => dispatch => {
	return dispatch({ type: ActionTypes.SAVE_BUSINESSRULE, payload: data })
}

export const updateBusinessRule = (data) => dispatch => {
	return dispatch({ type: ActionTypes.UPDATE_BUSINESSRULE, payload: data })
}

export const deleteBusinessRule = (data) => dispatch => {
	return dispatch({ type: ActionTypes.DELETE_BUSINESSRULE, payload: data })
}

export const executeRule = (id, appId) => dispatch => {
	return dispatch({ type: ActionTypes.EXECUTE_BUSINESSRULE, payload: { id, appId } })
}

export const newBusinessRule = () => dispatch => {
	const data = {
		id: Utils.generateGUID(),
		name: '',
		description: '',
		active: true,
		queryBuilder: { "id": QbUtils.uuid(), "type": "group" },
		queryDatamodel: ''
	}
	return dispatch({ type: ActionTypes.GET_BUSINESSRULE_SUCCESS, payload: data })
}