import React from 'react';
import ConnectionPoint from './ConnectionPoint';
import SuggestionRect from './SuggestionRect';
import Step from './Step';
import '../style/elementText.css';
import usertask from '../../../images/workflow/userTask.svg';
import businesstask from '../../../images/workflow/data models.svg';
import systemtask from '../../../images/workflow/page designer.svg';
import sendtask from '../../../images/workflow/forms.svg';
import workflowtask from '../../../images/workflow/workflow.svg';
import datapipelinetask from '../../../images/workflow/Datasources.svg';
export default class Process extends Step {
  render() {
    const {
      innerRectHeight = 0,
      innerRectWidth = 0,
      d = 'M0,0 L0 0',
      createArrow,
      height,
      width,
      x,
      y,
      rx = 6,
      multipleSelection,
      selected,
      selectStep,
      stopDragging,
      mouseOverConnection,
      setMouseOverConnection,
      mouseOverStep,
      setMouseOverStep,
      onClickSugessionElement,
      setOnClickConnection,
      setClickStep,
      clickStep,
      editElementStep,
      name,
      bgColor,
      onDragConnection,
      moveArrow,
      subType,
      label,
    } = this.props;

    // const showConnectionPoints =
    //   (selected && !multipleSelection) || mouseOverStep;
    const showConnectionPoints = clickStep;
    // const style = this.getStyle();
    const halfHeight = height / 2;
    const halfWidth = width / 2;

    return (
      <g
        onMouseOver={setMouseOverStep}
        onMouseLeave={setMouseOverStep}
        onMouseDown={selectStep}
        onMouseUp={stopDragging}
        transform={`translate(${x},${y})`}
        className="processText"
      >
        <rect
          className="processText"
          onClick={setClickStep}
          onDoubleClick={editElementStep}
          height={height}
          onMouseMove={moveArrow}
          // style={style}
          style={{
            fill: bgColor === undefined ? 'white' : bgColor,
            fontSize: 14,
            stroke: '#000',
            strokeWidth: 2,
          }}
          width={width}
          rx={rx}
        />

        <path
          d={d}
          style={{
            fill: 'white',
            fontSize: 14,
            stroke: '#000',
            strokeWidth: 2,
          }}
        />

        <foreignObject
          width={width}
          height="30"
          className="processText"
          onDoubleClick={editElementStep}
          onClick={setClickStep}
          onMouseMove={moveArrow}
        >
          {name ? (
            <>
              <div
                style={{
                  height: innerRectHeight,
                  display: 'flex',
                }}
              >
                {subType === 'usertask' && (
                  <>
                    <img src={usertask} className="innerRectImage" />
                    <label className="processType">{`User Task`}</label>
                  </>
                )}
                {subType === 'businesstask' && (
                  <>
                    <img src={businesstask} className="innerRectImage" />
                    <label className="processType">{`Business Task`}</label>
                  </>
                )}
                {subType === 'systemtask' && (
                  <>
                    <img src={systemtask} className="innerRectImage" />
                    <label className="processType">{`System Task`}</label>
                  </>
                )}
                {subType === 'sendtask' && (
                  <>
                    <img src={sendtask} className="innerRectImage" />
                    <label className="processType">{`Send Task`}</label>
                  </>
                )}
                {subType === 'workflowtask' && (
                  <>
                    <img src={workflowtask} className="innerRectImage" />
                    <label className="processType">{`Workflow Task`}</label>
                  </>
                )}
                {subType === 'datapipelinetask' && (
                  <>
                    <img src={datapipelinetask} className="innerRectImage" />
                    <label className="processType">{`Datapipeline Task`}</label>
                  </>
                )}
              </div>
              {/* <p className="processName">{name}</p> */}
            </>
          ) : (
            ''
          )}
        </foreignObject>

        <foreignObject
          height={height}
          width={width}
          onDoubleClick={editElementStep}
          onClick={setClickStep}
          onMouseMove={moveArrow}
        >
          {label ? (
            <div
              className="labelText"
              style={{
                height: height,
                width: width,
              }}
            >
              <p>{label}</p>
            </div>
          ) : null}
        </foreignObject>
        {showConnectionPoints && (
          <SuggestionRect
            subType={subType}
            x={width + 10}
            y={-10}
            height={100}
            width={50}
            onClickSugessionElement={onClickSugessionElement}
          />
        )}
        {showConnectionPoints ? (
          <ConnectionPoint
            onDragConnection={e => onDragConnection(e, 'left')}
            setMouseOverConnection={setMouseOverConnection}
            cx={0}
            cy={halfHeight}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            onDragConnection={e => onDragConnection(e, 'top')}
            setMouseOverConnection={setMouseOverConnection}
            cx={halfWidth}
            cy={0}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            // onDragConnection={e => onDragConnection(e, 'right')}
            setMouseOverConnection={setMouseOverConnection}
            cx={width}
            cy={halfHeight}
            // onMouseDown={e => console.log('mouse down')}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            onDragConnection={e => onDragConnection(e, 'bottom')}
            setMouseOverConnection={setMouseOverConnection}
            cx={halfWidth}
            cy={height}
            setOnClickConnection={setOnClickConnection}
          />
        ) : null}
      </g>
    );
  }
}

Process.defaultProps = Step.defaultProps;
