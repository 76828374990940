import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { FormControl } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import photoone from '../../images/photoone.svg';
import oracleimg from '../../images/oracleimg.svg';
import microsoftsqlserver from '../../images/microsoftsqlserver.svg';


const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	control: {
		padding: theme.spacing(2),
	},
}));

export default function AbcSelectPipelinepagetwo() {
	const classes = useStyles();
	const [spacing, setSpacing] = React.useState(2);

	const [tasks, setTasks] = React.useState([
        {name:"Data Standardization tool",category:"wip"},
        {name:"Remove duplicates tool", category:"wip"},
		{name:"Data Standardization tool2", category:"wip"},
		{name:"Data Standardization tool3", category:"wip"},
		{name:"Data Standardization tool4", category:"wip"},
		{name:"Data Standardization tool5", category:"wip"}
      ]);
    const onDragStart = (ev, id) => {
        ev.dataTransfer.setData("id", id);
    }

    const onDragOver = (ev) => {
        ev.preventDefault();
    }

    const onDrop = (ev, cat) => {
        let id = ev.dataTransfer.getData("id");
        let tasks3 = tasks.filter((task) => {
            if (task.name == id) {
                task.category = cat;
            }
            return task;
        });
        setTasks(tasks3);
     }
     const tasks2 = {
        wip: [],
        complete: []
    }
    tasks.forEach ((t) => {
        tasks2[t.category].push(
			<Grid item md={4}>
				<Box component="span" 
				className="draggable pipeToolbox"
				onDragStart = {(e) => onDragStart(e, t.name)}
				draggable
				>
				 {t.name}
				</Box>
			</Grid>
        );
    });

	return (
		<Box className="pxy20">
			<Grid container>
				<Box className="processtab-sec">
					<Grid container>
						<Grid item xs={12} className="savebtn-sec">
							<Button variant="contained" color="primary" className="savebtn btnwidth">
								SAVE
        </Button>
						</Grid>
					</Grid>
					<Grid item xs={12} className="selecteddata-grid">
						<Typography variant="h5" className="mb3 d-block font-bold">Select Data Source</Typography>
						<Box className="selecteddatasource-head" component="div">
							<img src={microsoftsqlserver} alt="sql server logo" className="serverlogo" />
							<Typography component="div" className="data-block">
								<Grid container className="mb2">
									<Grid item xs={12} md={2} >
										<Box component="label" className="connectionlabel">
											Connection Name:
						      </Box>
									</Grid>
									<Grid item xs={12} md={3} >
										<TextField
											variant="outlined"
											required
											fullWidth
											id="email"
											name="email"
											className="drmstextfield"
											autoComplete="email"
											placeholder="DRMS Server Connection"
											autoFocus
										/>
									</Grid>
								</Grid>
							</Typography>
						</Box>
					</Grid>
					<Grid className="my5" container>
						<Typography variant="h5" className="mb3 d-block font-bold">Selected Table</Typography>
						<Grid item xs={12} className="selecteddata-grid">
							<Box className="selecteddatasource-head" component="div">
								<img src={photoone} alt="selected logo" className="serverlogo" />
								<Typography component="div" className="data-block">
									<Box component="label" m={1}>
										Order Data Model
				      </Box>
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<Grid className="my5" container>
						<Typography variant="h5" className="mb3 d-block font-bold">Selected Pipeline</Typography>
						<Grid item xs={12} className="selecteddata-grid">
							<Box className="selecteddatasource-head" component="div">
								<img src={photoone} alt="selected logo" className="serverlogo" />
								<Typography component="div" className="data-block">
									<Box component="label" m={1}>
										Order Pipeline
				      </Box>
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<Grid item xs={12} className="my5">
						<Box className="orderbox-container">
							<Grid container spacing={2}>
								<Grid item sm={6} spacing={spacing}>
									<Typography component="div" className="orderbox wip"
										onDragOver={(e)=> onDragOver(e)}
										onDrop={(e)=> onDrop(e, "wip")}
									>
										<Grid container className="datatoolbox">
											{tasks2.wip}
										</Grid>
									</Typography>

								</Grid>
								<Grid item sm={6} spacing={spacing}>

									<Typography component="div" className="orderbox droppable"
									 onDragOver={(e)=> onDragOver(e)}
									 onDrop={(e)=> onDrop(e, "complete")}
									>
										<Typography className="mb3" component="h4">Drag and Drop here</Typography>
										<Grid container className="datatoolbox">
											{tasks2.complete}
										</Grid>
									</Typography>

								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Box>
			</Grid>
		</Box>
	);
}
