import React from 'react'
import { connect } from 'react-redux'
import { TextField, InputLabel, InputAdornment } from '@material-ui/core'
import classNames from 'classnames'

const TextElement = (props) => {
	let { classes, visibility, item } = props
	let value
	if (item.computed === 'Computed') {
		let formula = item.formula
		const { id, displayName, email } = props.user
		if (formula === '{CURRENT_USER_EMAIL}') {
			value = email
		} else if (formula === '{CURRENT_USER_FULLNAME}') {
			value = displayName
		} else if (formula === '{CURRENT_USER_ID}') {
			value = id
		} else if (formula === '{CURRENT_DATE}' || formula === '{CURRENT_DATETIME}') {
			value = new Date()
		} else {
			value = formula.replace(/\{(.*?)\}/g, (match, key) => props.filledData[key] && props.filledData[key])
		}
	} else if (item.computed === 'Lookup') {
		const { lookupDataElement, lookupDataKey, lookupDataValue } = item
		if (state.lookupDataModelValue && props.filledData[item.lookupDataValue]) {
			let filterData = state.lookupDataModelValue.filter(data => data[lookupDataKey] === props.filledData[item.lookupDataValue])
			value = filterData && filterData[0][lookupDataElement]
		}
	} else {
		value = props.filledData[item.name] || item.defaultValue
	}

	return (<>
		{item.labelPosition == 'top'
			&& <InputLabel className={classNames(classes.inputLabel)}>{item.label}</InputLabel>}
		<TextField className={classNames(classes.textField)}
			style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
			onChange={(e) => { props.onChange(item.name, e.target.value, item.type) }}
			variant={item.variant ? item.variant : "standard"}
			multiline
			fullWidth
			value={value}
			InputProps={item.labelPosition == 'end' ? {
				endAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
			} : (item.labelPosition == 'start' ? {
				startAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
			} : {})}
			required={item.required}
			disabled={item.disabled || item.computed !== 'Editable'}
			inputProps={{ minLength: item.minLength, maxLength: item.maxLength, rows: item.rows }}
		/>
	</>)
}

function mapStateToProps({ user }) {
	return {
		user
	}
}

export default connect(mapStateToProps, null)(TextElement)