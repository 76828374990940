import { ActionTypes } from 'constants/index'

const initialState = {
    data: null,
    loading: false,
    error: {
        name: false,
        errorMessage: "",
        status: ""
    },
    saveSuccess: false,
    updateSuccess: false
}

const networkReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_NETWORK_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload,
                saveSuccess: false,
                updateSuccess: false
            }
        }
        case ActionTypes.GET_NETWORK_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.SAVE_NETWORK_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload,
                saveSuccess: true,
            }
        }
        case ActionTypes.SAVE_NETWORK_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.UPDATE_NETWORK_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload,
                updateSuccess: true
            }
        }
        case ActionTypes.UPDATE_NETWORK_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ActionTypes.DELETE_NETWORK_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        }
        case ActionTypes.DELETE_NETWORK_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        default: {
            return state;
        }
    }
};

export default networkReducer;
