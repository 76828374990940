import { ActionTypes } from 'constants/index'
import Utils from 'packages/page/utils'

export const getWorkflows = (appId, type) => dispatch => {
	return dispatch({ type: ActionTypes.GET_WORKFLOWS, payload: {appId, type} })
}

export const setWorkflowsSearchText = (value) => dispatch => {
	return dispatch({ type: SET_WORKFLOWS_SEARCH_TEXT, searchText: value })
}

export const getWorkflow = (params) => dispatch => {
	return dispatch({ type: ActionTypes.GET_WORKFLOW, payload: params })
}

export const clearWorkflow = () => dispatch => {
	return dispatch({ type: ActionTypes.CLEAR_WORKFLOW })
}

export const saveWorkflow = (data) => dispatch => {
	return dispatch({ type: ActionTypes.SAVE_WORKFLOW, payload: data })
}

export const updateWorkflow = (data) => dispatch => {
	return dispatch({ type: ActionTypes.UPDATE_WORKFLOW, payload: data })
}

export const deleteWorkflow = (id, appId) => dispatch => {
	return dispatch({ type: ActionTypes.DELETE_WORKFLOW, payload: { id, appId } })
}

export const getWorkFlowTasks = (workflowId) => dispatch => {
	dispatch({ type: ActionTypes.GET_WORKFLOW_TASKS, workflowId })
}

export const getWorkFlowEvents = (workflowId) => dispatch => {
	dispatch({ type: ActionTypes.GET_WORKFLOW_EVENTS, workflowId })
}

export const newWorkflow = () => dispatch => {
	const data = {
		id: Utils.generateGUID(),
		name: '',
		handle: '',
		description: '',
		workflowType: '',
		categories: [],
		tags: [],
		primaryContactName: '',
		primaryContactEmail: '',
		primaryContactPhone: '',
		secContactName: '',
		secContactEmail: '',
		secContactPhone: '',
		natureofBusiness: '',
		services: '',
		active: true
	}
	return dispatch({ type: ActionTypes.GET_WORKFLOW, payload: data })
}
