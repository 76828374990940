import React, { useState, useEffect } from 'react'
import { hot } from 'react-hot-loader/root'
import {
	Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox,
} from '@material-ui/core'
import pencileditbutton from 'images/pencil-edit-button.svg';
import deleteblackicon from 'images/deleteblack-icon.svg';
import Redux from './Redux'
import { withRouter } from 'react-router-dom';

const PagesTable = props => {
	return (<TableContainer component={Paper}>
		<Table className="companydatatable" aria-label="Company Data">
			<TableHead>
				<TableRow>
					<TableCell>
						<Checkbox
							// defaultChecked
							color="primary"
						/>
					</TableCell>
					<TableCell align="left">{"Name"}</TableCell>
					<TableCell align="left">{"Description"}</TableCell>
					<TableCell align="left">{"Actions"}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{props.pages.map(page => (
					<TableRow key={page.name}>
						<TableCell component="th" scope="row">
							<Checkbox color="primary" />
						</TableCell>
						<TableCell align="left">{page.name}</TableCell>
						<TableCell align="left">{page.description}</TableCell>
						<TableCell align="left" className="actioncolumn">
							<Box
								className="paper1"
								component="label"
								onClick={() => props.handleEditClick(page)}
							>
								<img src={pencileditbutton} alt="edit icon" />
							</Box>
							<Box
								className="paper1 deleteicon"
								component="label"
								onClick={() => props.handleDelete(page)}
							>
								<img src={deleteblackicon} alt="delete icon" />
							</Box>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</TableContainer>)
}
export default hot(withRouter(Redux(PagesTable)))
