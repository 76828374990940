import { ActionTypes } from 'constants/index'

const initialState = {
	data: null,
	loading: false,
	error: {
		firstName: false,
		lastName: false,
		email: false,
		entityId: false,
		errorMessage: "",
		status: ""
	},
	saveSuccess: false,
	updateSuccess: false,
	memberByUserId: {
		data: null,
		loading: false,
	},
	memberByIdLoading: false,
};

const memberReducer = function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_MEMBER_SUCCESS: {
			return {
				...state,
				data: action.payload,
				saveSuccess: false,
				updateSuccess: false
			};
		}
		case ActionTypes.GET_MEMBER_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error,
					saveSuccess: false,
				};
			}
		case ActionTypes.GET_MEMBER_BY_USERID_SUCCESS: {
			return {
				...state,
				memberByUserId: {
					data: action.payload,
					success: true
				},
				memberByIdLoading: false,
			};
		}
		case ActionTypes.RESET_MEMBER_BY_USERID: {
			return {
				...state,
				memberByUserId: {
					data: null,
					success: false
				}
			};
		}
		case ActionTypes.GET_MEMBER_BY_USERID_FAILURE:
			{
				return {
					...state,
					memberByUserId: {
						data: action.payload,
						success: false
					},
					memberByIdLoading: false,
				};
			}
		case ActionTypes.SAVE_MEMBER_SUCCESS: {
			return {
				...state,
				data: action.payload,
				saveSuccess: true,
			};
		}
		case ActionTypes.SAVE_MEMBER_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error,
					saveSuccess: false,
				};
			}
		case ActionTypes.UPDATE_MEMBER_SUCCESS: {
			return {
				...state,
				data: action.payload,
				updateSuccess: true
			};
		}
		case ActionTypes.UPDATE_MEMBER_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error
				};
			}
		case ActionTypes.DELETE_MEMBER_SUCCESS: {
			return {
				...state,
				data: action.payload,
				memberSuccess: false,
			};
		}
		case ActionTypes.DELETE_MEMBER_FAILURE:
			{
				return {
					...state,
					loading: false,
					error: action.error,
					memberSuccess: false,
				};
			}
		case ActionTypes.GET_MEMBER_BY_USERID:
		{
			return {
				...state,
				memberByIdLoading: true,
			};
		}
		default: {
			return state;
		}
	}
};

export default memberReducer;
