import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import 'styles/page-designer.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'

const styles = theme => ({});

class PageDesignerBarChart extends Component {
	render() {
		let { data, width, height, error, orientation } = this.props.element.chartData;
		let { stacked } = this.props;
		let options = {
			maintainAspectRatio: false
		}
		if (stacked) {
			options.scales = {
				xAxes: [{ stacked: true }],
				yAxes: [{ stacked: true }]
			}
		}
		if (error) {
			return (
				<div className="errorDisplayCenter">{error}</div>
			);
		} else {
			return (
				<div
					id={`BarChartContainer${this.props.element.chartUniqueId}`}
					style={{ height: "inherit", width: "inherit" }}
				>
					{orientation && orientation === "horizontal" ? <HorizontalBar
						key={JSON.stringify(options)}
						id={`BarChartElement${this.props.element.chartUniqueId}`}
						className="text"
						width={"inherit"}
						height={"inherit"}
						data={data}
						options={options}
					/>
						: <Bar
							key={JSON.stringify(options)}
							id={`BarChartElement${this.props.element.chartUniqueId}`}
							className="text"
							width={"inherit"}
							height={"inherit"}
							data={data}
							options={options}
						/>
					}
				</div>
			);
		}
	}
}

function collect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource()
	};
}

const cardSource = {
	beginDrag(props) {
		const item = { id: props.element.chartUniqueId };
		return item;
	}
};

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(null, null)(PageDesignerBarChart)
	)
);
