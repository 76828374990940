import React, { Component } from 'react'
import {
	Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,
	withStyles
} from '@material-ui/core'
import axios from 'axios'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { MessageDialog } from 'components/@custom/messageDialog';
import * as Actions from 'actions';
import { ApiResponseModal } from './ApiResponseModal';
import { getJson } from './getJson';
import PropertiesModal from './Properties/index';
const styles = theme => ({
	modal: {
		top: `30%`,
		left: `30%`,
		transform: `translate(-30%, -30%)`,
		position: 'absolute',
	},
	dialogPaper: {
        minHeight: '80vh',
		maxHeight: '80vh',
        minWidth: '80%',
        maxWidth: '80%',
    },
});

class InnerForm extends Component {
	constructor(props) {
		super(props);
		let design = this.props.design;
		let modalData = this.props.modalData;
		this.state = {
			design: design,
			dragging: false,
			elementsData: {},
			elementType: "",
			open: false,
			modalData: modalData,
			editElement: null,
			dataFilled: {},
			showApiResponse: false,
			apiResponse: {},
			saveMessage: null,
			emptyGridToHighlight: null
		}
	}

	componentDidMount() {
		if (this.props.formState) {
			let formState = JSON.parse(JSON.stringify(this.props.formState));
			this.setState({ ...formState });
		}
	}
	componentWillUnmount() {
	}
	static getDerivedStateFromProps(props, state) {
		return {
			saveMessage: props.saveFormStatus.msg,
			elementType: props.newElementType.data,
			design: props.design,
			modalData: props.modalData
		}
	}
	deleteElement = (position) => {
		let elementNameTobeDeleted = null;
		let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
		let modalJsonCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));
		let transformedRow = jsonCopy[position.split("-")[0]].columns.filter((el, index) => {
			return index != position.split("-")[1];
		});
		let transformedRowModalData = modalJsonCopy[position.split("-")[0]].columns.filter((el, index) => {
			if (index == position.split("-")[1]) {
				elementNameTobeDeleted = el.data.basic.name;
			}
			return index != position.split("-")[1];
		});

		jsonCopy[position.split("-")[0]].columns = transformedRow;
		modalJsonCopy[position.split("-")[0]].columns = transformedRowModalData
		jsonCopy = jsonCopy.filter((el) => {
			return el.columns.length > 0
		})
		modalJsonCopy = modalJsonCopy.filter((el) => {
			return el.columns.length > 0
		})
		this.setState({ design: { rows: jsonCopy } })
		this.deleteValidations(elementNameTobeDeleted, modalJsonCopy, jsonCopy);
	}
	editElement = (item, index) => {
		let editElement = { index: index, data: this.state.modalData.rows[index.split("-")[0]].columns[index.split("-")[1]] }
		this.setState({ open: true, editElement: editElement });
	}
	removeEmptyRows = (variable) => {
		let jsonCopy = JSON.parse(JSON.stringify(variable));
		let filteredRows = jsonCopy.filter((item) => {
			return item.columns.length > 0;
		})
		return filteredRows;
	}
	createNewRowByRearrage(rowIndex) {
		let newrow = {
			columns: []
		}

		let newmodalDataRow = {
			columns: []
		}
		let rowDragged = this.props.draggedElement.data.split("-")[0];
		let columnDragged = this.props.draggedElement.data.split("-")[1];
		let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
		if (jsonCopy.length <= rowDragged || jsonCopy[rowDragged].columns.length <= columnDragged) {
			return;
		}
		let selectedElementJson = JSON.parse(JSON.stringify(jsonCopy[rowDragged].columns[columnDragged]));
		let transformedRow = jsonCopy[rowDragged].columns.filter((el, index) => {
			return index != columnDragged;
		});

		jsonCopy[rowDragged].columns = transformedRow;

		let modalDataCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));
		let modalDataElement = JSON.parse(JSON.stringify(modalDataCopy[rowDragged].columns[columnDragged]));

		let transformedRowModalData = modalDataCopy[rowDragged].columns.filter((el, index) => {
			return index != columnDragged;
		});

		modalDataCopy[rowDragged].columns = transformedRowModalData;

		if (rowDragged < rowIndex && jsonCopy[rowDragged].columns.length == 0) {
			rowIndex = rowIndex - 1;
		}
		jsonCopy = this.removeEmptyRows(jsonCopy);
		modalDataCopy = this.removeEmptyRows(modalDataCopy);

		newrow.columns.push(selectedElementJson);
		newmodalDataRow.columns.push(modalDataElement);
		jsonCopy = [...jsonCopy.slice(0, rowIndex), newrow, ...jsonCopy.slice(rowIndex)];
		modalDataCopy = [...modalDataCopy.slice(0, rowIndex), newmodalDataRow, ...modalDataCopy.slice(rowIndex)];
		this.setState({ design: { rows: jsonCopy }, modalData: { rows: modalDataCopy }, emptyGridToHighlight: null })
		this.props.innerFormUpdate(jsonCopy, modalDataCopy);
	}
	addElementAtDesiredPosition = (selectedPosition, data) => {
		if ((selectedPosition + "").includes("-")) {
			let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
			let rowDropped = selectedPosition.split("-")[0];
			let columnDropped = selectedPosition.split("-")[1];
			let droppedRowCopy = JSON.parse(JSON.stringify(jsonCopy[rowDropped].columns))


			let transformedDropRow = [...droppedRowCopy.slice(0, columnDropped), this.getJson(this.state.elementType, data), ...droppedRowCopy.slice(columnDropped)]
			jsonCopy[rowDropped].columns = transformedDropRow;

			let modalDataCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));

			let modalDataDroppedRowCopy = JSON.parse(JSON.stringify(modalDataCopy[rowDropped].columns));

			let transformedDropModalRow = [...modalDataDroppedRowCopy.slice(0, columnDropped), { data: data, type: this.state.elementType }, ...modalDataDroppedRowCopy.slice(columnDropped)]
			modalDataCopy[rowDropped].columns = transformedDropModalRow;
			jsonCopy = this.removeEmptyRows(jsonCopy);
			modalDataCopy = this.removeEmptyRows(modalDataCopy);


			this.setState({
				design: { rows: jsonCopy }, modalData: { rows: modalDataCopy }, emptyGridToHighlight: null
				, newElementPosition: null
			})
			this.props.innerFormUpdate(jsonCopy, modalDataCopy);
		}
		else {
			let newrow = {
				columns: []
			}

			let newmodalDataRow = {
				columns: []
			}
			let rowIndex = selectedPosition;
			let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
			let modalDataCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));
			newrow.columns.push(this.getJson(this.state.elementType, data));
			newmodalDataRow.columns.push({ data: data, type: this.state.elementType });
			jsonCopy = [...jsonCopy.slice(0, rowIndex), newrow, ...jsonCopy.slice(rowIndex)];
			modalDataCopy = [...modalDataCopy.slice(0, rowIndex), newmodalDataRow, ...modalDataCopy.slice(rowIndex)];
			this.setState({ design: { rows: jsonCopy }, modalData: { rows: modalDataCopy }, emptyGridToHighlight: null, newElementPosition: null })
			this.props.innerFormUpdate(jsonCopy, modalDataCopy);
		}

	}
	rearrangeElements(selectedElement, selectedPosition) {
		if (!this.props.draggedElement || !this.props.draggedElement.data || Object.getOwnPropertyNames(this.props.draggedElement.data).length == 0) {
			this.setState({ dragging: false, open: true })
			return;
		}
		let rowDragged = this.props.draggedElement.data.split("-")[0];
		let columnDragged = this.props.draggedElement.data.split("-")[1];
		let rowDropped = selectedPosition.split("-")[0];
		let columnDropped = selectedPosition.split("-")[1];
		let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
		if (rowDragged >= jsonCopy.length || columnDragged >= jsonCopy[rowDragged].columns.length) {
			return;
		}
		let selectedElementJson = JSON.parse(JSON.stringify(jsonCopy[rowDragged].columns[columnDragged]));

		let transformedRow = jsonCopy[rowDragged].columns.filter((el, index) => {
			return index != columnDragged;
		});

		jsonCopy[rowDragged].columns = transformedRow;
		let droppedRowCopy = JSON.parse(JSON.stringify(jsonCopy[rowDropped].columns))


		let transformedDropRow = [...droppedRowCopy.slice(0, columnDropped), selectedElementJson, ...droppedRowCopy.slice(columnDropped)]
		jsonCopy[rowDropped].columns = transformedDropRow;
		this.props.clearDraggedElement();

		let modalDataCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));

		if (rowDragged >= modalDataCopy.length || columnDragged >= modalDataCopy[rowDragged].columns.length) {
			return;
		}
		let modalDataElement = JSON.parse(JSON.stringify(modalDataCopy[rowDragged].columns[columnDragged]));

		let transformedRowModalData = modalDataCopy[rowDragged].columns.filter((el, index) => {
			return index != columnDragged;
		});

		modalDataCopy[rowDragged].columns = transformedRowModalData;

		let droppedRowCopyModalData = JSON.parse(JSON.stringify(modalDataCopy[rowDropped].columns))
		let transformedDropRowModalData = [...droppedRowCopyModalData.slice(0, columnDropped), modalDataElement, ...droppedRowCopyModalData.slice(columnDropped)]
		modalDataCopy[rowDropped].columns = transformedDropRowModalData;

		jsonCopy = this.removeEmptyRows(jsonCopy);
		modalDataCopy = this.removeEmptyRows(modalDataCopy);


		this.setState({ design: { rows: jsonCopy }, modalData: { rows: modalDataCopy }, emptyGridToHighlight: null })
		this.props.innerFormUpdate(jsonCopy, modalDataCopy);
	}


	useExistingRow(data) {
		let currentRow = this.state.design.rows[this.state.design.rows.length - 1];;
		let draggedElement = this.state.elementType
		currentRow.columns.push(this.getJson(draggedElement, data));
		let Rows = this.state.design.rows;
		Rows = Rows.slice(0, Rows.length - 1);
		Rows.push(currentRow);


		let modalData = JSON.parse(JSON.stringify(this.state.modalData));
		let modalDataRow = modalData.rows[this.state.design.rows.length - 1]
		modalDataRow.columns.push({ data: data, type: draggedElement });
		this.setState({ design: { rows: Rows }, modalData: modalData });

		this.props.innerFormUpdate(Rows, modalData);
	}
	buttonAction = (position) => {
		let row = position.split("-")[0];
		let column = position.split("-")[1];
		let buttonJson = JSON.parse(JSON.stringify(this.state.design.rows[row].columns[column]));
		let body = this.state.dataFilled;
		let headers = { 'appId': this.props.match.params.dappId };
		buttonJson.headers.map((el) => {
			headers[el.label] = el.value;
		});
		if (buttonJson.function == 'api') {
			axios.post(buttonJson.url, this.state.dataFilled, { headers: headers }).then(response => {
				this.setState({
					showApiResponse: true, apiResponse: {
						body: body,
						headers: headers,
						response: response,
						url: buttonJson.url,
						buttonType: buttonJson.function
					}
				});
			}).catch((exp) => { console.error(exp) })

		}
		else if (buttonJson.function == 'save') {
			this.setState({
				showApiResponse: true, apiResponse: {
					body: body,
					buttonType: buttonJson.function
				}
			});
		}
	}
	setData = (name, val, item) => {
		this.props.setData(name, val, item);
	}
	generateRow(Columns, outerIndex) {

		const { classes } = this.props;
		return Columns.map((item, index) => {
			let gridSize = 12 / Columns.length;
			let visible = this.checkVisibility(`${outerIndex}-${index}`);
			if (!visible && this.props.filledData[item.name] != null) {
				this.setData(item.name, null, null)
			}
			return (visible || !this.props.viewOnly) && <FormElements gridSize={gridSize} item={item}
				deleteElement={(position) => { this.deleteElement(position) }}
				editElement={(item, position) => { this.editElement(item, position) }}
				viewOnly={this.props.viewOnly}
				buttonAction={(position) => { this.buttonAction(position) }}
				onChange={(name, val, item) => { this.setData(name, val, item) }}
				setData={(name, val, item) => { this.setData(name, val, item) }}
				filledData={this.props.filledData}
				outerIndex={outerIndex} index={index}
				rearrangeElements={(selectedElement, selectedPosition) => {
					if (selectedElement == null) {
						this.setState({ newElementPosition: selectedPosition })
					}
					; this.rearrangeElements(selectedElement, selectedPosition)
				}} />
		})
	}
	dropBetweenRows = (e, outerIndex) => {
		this.setState({ emptyGridToHighlight: null });
		if (!this.props.draggedElement.data) {
			this.setState({ newElementPosition: outerIndex });
			return;
		}
		if (!this.state.dragging) { e.stopPropagation() };
		this.createNewRowByRearrage(outerIndex);
	}
	generateFormFromJson() {
		const Jsondata = this.state.design.rows;
		return Jsondata.map((row, outerIndex) => {
			const Columns = row.columns
			return (
				<React.Fragment key={outerIndex}>
					<Grid
						draggable="true"
						onDragStart={(e) => {
						}}
						onDragOver={(event) => {//if(!this.props.draggedElement.data){return};
							this.setState({ emptyGridToHighlight: outerIndex }); event.preventDefault()
						}}
						onDrop={(e) => { this.dropBetweenRows(e, outerIndex) }}
						xs={12}
						style={this.state.emptyGridToHighlight == outerIndex
							? { "border": "3px solid gray", 'paddingBottom': '45px' }
							: { 'paddingBottom': '3px' }}></Grid>
					{this.generateRow(Columns, outerIndex)}
				</React.Fragment>
			)
		})
	}

	getJson(draggedElement, data) {
		return getJson(draggedElement, data);
	}

	createNewRow(data) {
		let row = {
			columns: []
		}
		let modalDataRow = {
			columns: []
		}
		let draggedElement = this.state.elementType
		row.columns.push(this.getJson(draggedElement, data));
		let Rows = this.state.design.rows;
		Rows.push(row);

		let modalData = JSON.parse(JSON.stringify(this.state.modalData));
		modalDataRow.columns.push({ data: data, type: draggedElement });
		let ModalDatarows = modalData.rows;
		ModalDatarows.push(modalDataRow);

		this.setState({ design: { rows: Rows }, modalData: { rows: ModalDatarows } });

		this.setState({ design: { rows: Rows } });

		this.props.innerFormUpdate(Rows, ModalDatarows);
	}

	drop = (e) => {
		if (e.dataTransfer.getData("inputType")) {
			this.setState({ elementType: e.dataTransfer.getData("inputType"), dragging: false, open: true })
		}
	}
	elementDropped = (e, selectedElement) => {
	}
	dragEnd = (event, inputType) => {
		this.setState({ dragging: false });
	}
	handleCancel = () => {
		this.setState({ open: false, editElement: null });
	}
	handleClose = (data) => {
		this.setState({ open: false, elementsData: data, editElement: null });
		if (this.state.newElementPosition) {
			this.addElementAtDesiredPosition(this.state.newElementPosition, data);
		}
		else if (this.state.design.rows.length == 0) {
			this.createNewRow(data);
		}
		else if (!data.basic.sameRow) {
			this.createNewRow(data);
		}
		else {
			this.useExistingRow(data);
		}
	};
	editElementProperties = (data) => {
		let edittedJson = this.getJson(this.state.editElement.data.type, data);
		let rowToBeEditted = this.state.editElement.index.split("-")[0];
		let columnToBeEditted = parseInt(this.state.editElement.index.split("-")[1]);
		let currentRows = JSON.parse(JSON.stringify(this.state.design.rows));
		let rowToTransform = JSON.parse(JSON.stringify(this.state.design.rows[rowToBeEditted].columns));
		let rowAfterTrasformation = [...rowToTransform.slice(0, columnToBeEditted), edittedJson, ...rowToTransform.slice(columnToBeEditted + 1)]
		currentRows[rowToBeEditted].columns = rowAfterTrasformation;

		let currentRowsModalData = JSON.parse(JSON.stringify(this.state.modalData.rows));
		let rowToTransformModalData = JSON.parse(JSON.stringify(this.state.modalData.rows[rowToBeEditted].columns));
		let rowAfterTrasformationModalData = [...rowToTransformModalData.slice(0, columnToBeEditted), { data: data, type: this.state.editElement.data.type }, ...rowToTransformModalData.slice(columnToBeEditted + 1)]
		currentRowsModalData[rowToBeEditted].columns = rowAfterTrasformationModalData;

		this.setState({
			open: false, elementsData: data, editElement: null,
			design: { rows: currentRows },
			modalData: { rows: currentRowsModalData }
		});
		this.props.innerFormUpdate(currentRows, currentRowsModalData);
	};
	hideDialog = () => {
		this.props.clrMessage();
	}
	modifyValidations = (previousName, newName) => {
		let stateCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));
		let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
		stateCopy.map((item, index) => {
			item.columns.map((column, columnIndex) => {
				let conditionalRequiredcopy = column.data.conditionalRequired && column.data.conditionalRequired.map((item) => {
					if (item.element == previousName) {
						return { ...item, element: newName };
					}
					else {
						return item;
					}
				});

				let visibilitycopy = column.data.visibility && column.data.visibility.map((item) => {
					if (item.element == previousName) {
						return { ...item, element: newName };
					}
					else {
						return item;
					}
				});
				stateCopy[index].columns[columnIndex].data = { ...stateCopy[index].columns[columnIndex].data, visibility: visibilitycopy, conditionalRequired: conditionalRequiredcopy };
			})
		})
		this.setState({ modalData: { ...this.state.modalData, rows: stateCopy } })
		this.props.innerFormUpdate(jsonCopy, stateCopy);
	}


	deleteValidations = (elementName, jsonRows, jsonCopy) => {
		let stateCopy = JSON.parse(JSON.stringify(jsonRows));
		stateCopy.map((item, index) => {
			item.columns.map((column, columnIndex) => {
				let conditionalRequiredcopy = column.data.conditionalRequired.filter((item) => {
					if (item.element == elementName) {
						return false;
					}
					else {
						return true;
					}
				});

				let visibilitycopy = column.data.visibility.filter((item) => {
					if (item.element == elementName) {
						return false;
					}
					else {
						return true;
					}
				});
				stateCopy[index].columns[columnIndex].data = { ...stateCopy[index].columns[columnIndex].data, visibility: visibilitycopy, conditionalRequired: conditionalRequiredcopy };
			})
		})
		this.setState({ modalData: { ...this.state.modalData, rows: stateCopy } })
		this.props.innerFormUpdate(jsonCopy, stateCopy);
	}

	checkVisibility = (position) => {

		if (this.state.modalData.rows.length <= position.split("-")[0] || this.state.modalData.rows[position.split("-")[0]].columns.length <= position.split("-")[1]) {
			return true;
		}
		let visibilityConditions = this.state.modalData.rows[position.split("-")[0]].columns[position.split("-")[1]].data.visibility;
		let visibility = true;
		visibilityConditions.map((el) => {
			if (this.isConditionInvalid(el)) {
				visibility = false;
			}
		})
		return visibility;
	}
	isConditionInvalid = (el, action) => {
		let element = el;
		let testValidationsData = this.props.filledData;
		if (el.operator == "<") {
			if (!eval(`parseInt(testValidationsData[el.element]) < parseInt(element.value) `)) {
				action != null && alert("condition required Failed :- elements [" + el.element + "] <" + element.value)
				return true;
			}
		}
		else if (el.operator == ">") {
			if (!eval(`parseInt(testValidationsData[el.element]) > parseInt(element.value) `)) {
				action != null && alert("condition required Failed :- elements [" + el.element + "] >" + element.value)
				return true;
			}

		}
		else if (el.operator == "==") {
			if (!eval(`testValidationsData[el.element] == element.value `)) {
				action != null && alert("condition required Failed :- elements [" + el.element + "] ==" + element.value)
				return true;
			}
		}
		else if (el.operator == "!=") {
			if (!eval(`testValidationsData[el.element] != element.value `)) {
				action != null && alert("condition required Failed :- elements [" + el.element + "] !=" + element.value)
				return true;
			}
		}
	}


	render() {
		const { classes } = this.props;
		return (
			<div >
				<Dialog
					disableBackdropClick
					disableEscapeKeyDown
					aria-labelledby="confirmation-dialog-title"
					open={this.state.open}
					classes={{ paper: classes.dialogPaper }}
				>
					<DialogTitle id="confirmation-dialog-title"></DialogTitle>
					<DialogContent >
						{this.state.open && <PropertiesModal
							handleClose={(data) => { this.handleClose(data) }}
							handleCancel={() => { this.handleCancel() }}
							editElement={this.state.editElement}
							elementType={this.state.elementType}
							design={this.state.design.rows}
							modifyValidations={(previousName, newName) => { this.modifyValidations(previousName, newName) }}
							editElementProperties={(data) => { this.editElementProperties(data) }} />}
					</DialogContent>
				</Dialog>
				<Dialog
					open={this.state.showApiResponse}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					{ApiResponseModal(this.state)}
					<DialogActions>
						<Button color="primary" onClick={() => { this.setState({ showApiResponse: false }) }}>
							Ok
                        </Button>
					</DialogActions>
				</Dialog>
				{this.state.saveMessage &&
					MessageDialog(this.state.saveMessage, () => { this.hideDialog() })}
				<Grid container>
					<Grid item xs={12}>
						<div className style={{ padding: "10px", border: "1px solid lightgrey", minHeight: 75 }}
							onDrop={(e) => { e.stopPropagation(); this.drop(e) }}
							onDragOver={(event) => event.preventDefault()}
						>
							<Grid container>
								{this.generateFormFromJson()}
							</Grid>
						</div>
					</Grid>
				</Grid>
			</div>
		)
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		editEntryForm: Actions.editForm,
		clearDraggedElement: Actions.clearDraggedElement,
		clrMessage: Actions.clearSaveMessage
	}, dispatch);
}

function mapStateToProps({ forms }) {
	return {
		saveFormStatus: forms.formBuilder,
		draggedElement: forms.formElements,
		selectedForm: forms.selectedForm,
		newElementType: forms.newElementType
	}
}

export default withStyles(styles, { withTheme: true })(
	withRouter(connect(mapStateToProps, mapDispatchToProps)(InnerForm))
)

import FormElements from './FormBuilder/FormElements';