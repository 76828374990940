import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TableContainer, Button, Paper, Grid, Typography } from '@material-ui/core';

import CustomTable from '../@table/Table'
import rightarrow from 'images/rightarrow.svg';

const exportArray = [
    { key: 'xls', name: "Export as Excel", isVisible: true },
    { key: 'csv', name: "Export as csv", isVisible: true },
    { key: 'txt', name: "Export as text", isVisible: true }];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function View(props) {
    return (
        <Box className="abcCompany-page">
            <Box className="pxy20">
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <div className="backbtn">
                            <div className="backbtn">
                                <img src={rightarrow} alt="backicon" className="backicon" />
                                <Box className="companyheadingtext">
                                    <Typography variant="h4">{"Formulas"}</Typography>
                                </Box>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} className="savebtn-sec">
                        <Button variant="contained" color="primary" className="addnewentrybtn mb5"
                            onClick={() => props.handleAddClick()} >
                            {"ADD FORMULA"}
                        </Button>
                    </Grid>

                    <TableContainer component={Paper}>
                        <CustomTable
                            {...props}
                            isExport={true}
                            isSearch={true}
                            isFilter={true}
                            {...props.state}
                            name={"Formulas"}
                            isTableHead={true}
                            hasCheckbox={false}
                            isViewCoulmns={true}
                            isTablePagination={true}
                            isTableSubHeader={false}
                            exportArray={exportArray}
                            onRowClick={props.onRowClick}
                            handleCheck={props.handleCheck}
                            resetFilters={props.resetFilters}
                            searchHandler={props.searchHandler}
                            handleChangePage={props.handleChangePage}
                            handleInputChange={props.handleInputChange}
                            handleRequestSort={props.handleRequestSort}
                            tableCrudOperations={props.tableCrudOperations}
                            handleDropdownChange={props.handleDropdownChange}
                            handleSelectAllClick={props.handleSelectAllClick}
                            handleExoprtItemClick={props.handleExoprtItemClick}
                            handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                            actions={{ edit: true, delete: true }}
                            headerstyle={{
                                fontSize: "14px", color: "rgba(0, 0, 0, 0.87)",
                                backgroundColor: "#FFF", textAlign: "center"
                            }}
                            subHeaderstyle={{
                                fontSize: "14px", color: "rgba(0, 0, 0, 0.87)",
                                backgroundColor: "#FFF", textAlign: "center"
                            }}
                            rowStyle={{
                                fontSize: "14px", color: "rgba(0, 0, 0, 0.87)",
                                backgroundColor: "#FFF", row1BackgroundColor: "#FFF", row2BackgroundColor: "#FFF"
                            }} />
                    </TableContainer>
                </Grid>
            </Box>

        </Box>
    );
}
