import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}))

function Wrapper(props) {
    const classes = useStyles();
    return (<main className={classes.content}>
        {/* <div className={classes.toolbar} /> */}
        {props.children}
    </main>)
}
export default withRouter(Wrapper)