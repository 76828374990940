import { ActionTypes } from 'constants/index';

const initialState = {
	success: false,
	isLoading: false,
	error: {
		username: false,
		password: false,
		email: false
	},
	token: '',
	reset: {
		isLoading: false,
		success: false,
		user: {},
		error: {}
	},
	loading: false,
    isUserExist: null,
    orgNotFound: null,
};

const login = function (state = initialState, action) {

	switch (action.type) {
		case ActionTypes.USER_LOGIN: {
			return {
				...state,
				isLoading: true,
				error: ''
			};
		}
		case ActionTypes.SET_USER_DATA:
			{
				return {
					...initialState,
					...action.payload
				};
			}
		case ActionTypes.USER_LOGIN_SUCCESS:
			{
				return {
					...initialState,
					success: true,
					isLoading: false
				};
			}
		case ActionTypes.USER_LOGIN_FAILURE:
			{
				console.log("USER_LOGIN_FAILURE = action.error", action.error);
				return {
					success: false,
					error: action.error,
					isLoading: false
				};
			}
		case ActionTypes.RESET_PASSWORD: {
			return {
				...state,
				reset: {
					isLoading: true,
				}
			};
		}
		case ActionTypes.RESET_PASSWORD_SUCCESS:
			{
				return {
					...state,
					reset: {
						isLoading: false,
						success: true,
						user: action.payload
					}
				};
			}
		case ActionTypes.RESET_PASSWORD_ERROR:
			{
				return {
					...state,
					reset: {
						isLoading: false,
						success: false,
						error: action.payload
					}
				};
			}
		case ActionTypes.IS_USER_EXIST: {
			return {
				...state,
				loading: true,
			};
		}
		case ActionTypes.IS_USER_EXIST_SUCCESS:
			{
				return {
					...state,
					loading: false,
					isUserExist: action.payload,
					orgNotFound: null,
				};
			}
		case ActionTypes.IS_USER_EXIST_FAILURE:
			{
				return {
					...state,
					loading: false,
					orgNotFound: action.error,
					isUserExist: null,
				};
			}
		default:
			{
				return state
			}
	}
};

export default login;