import React from 'react';
import ConnectionPoint from './ConnectionPoint';
import Step from './Step';
import SuggestionRect from './SuggestionRect';

export default class End extends Step {
  render() {
    const {
      createArrow,
      height,
      width,
      x,
      y,
      multipleSelection,
      selected,
      selectStep,
      stopDragging,
      mouseOverConnection,
      setMouseOverConnection,
      onClickSugessionElement,
      mouseOverStep,
      setMouseOverStep,
      setClickStep,
      clickStep,
      editElementStep,
      name,
      bgColor,
      moveArrow,
    } = this.props;

    // const showConnectionPoints =
    //   (selected && !multipleSelection) || mouseOverStep;

    const showConnectionPoints = clickStep;

    const style = this.getStyle();

    const halfHeight = height / 2;
    const halfWidth = width / 2;

    return (
      <g
        onMouseOver={setMouseOverStep}
        onMouseLeave={setMouseOverStep}
        onMouseDown={selectStep}
        onMouseUp={stopDragging}
        transform={`translate(${x},${y})`}
      >
        <circle
          r={height / 2}
          // style={style}
          cx={halfWidth}
          cy={halfHeight}
          onClick={setClickStep}
          onDoubleClick={editElementStep}
          onMouseMove={moveArrow}
          style={{
            fill: bgColor === undefined ? 'white' : bgColor,
            fontSize: 14,
            stroke: '#000',
            strokeWidth: 2,
          }}
        />
        <circle
          r={height / 2.5}
          style={{
            fill: bgColor === undefined ? 'white' : bgColor,
            fontSize: 14,
            stroke: '#000',
            strokeWidth: 2,
          }}
          cx={halfWidth}
          cy={halfHeight}
          onClick={setClickStep}
          onDoubleClick={editElementStep}
          onMouseMove={moveArrow}
        ></circle>

        <foreignObject
          width="40"
          height="50"
          x="30"
          y="-10"
          className="processText"
          onDoubleClick={editElementStep}
          onClick={setClickStep}
          onMouseMove={moveArrow}
        >
          <p>{name}</p>
        </foreignObject>
        {/* {showConnectionPoints ? (
          <ConnectionPoint
            setMouseOverConnection={setMouseOverConnection}
            cx={halfWidth - 20}
            cy={halfHeight}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            setMouseOverConnection={setMouseOverConnection}
            cx={halfWidth}
            cy={0}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            setMouseOverConnection={setMouseOverConnection}
            cx={halfWidth + 20}
            cy={halfHeight}
          />
        ) : null}

        {showConnectionPoints ? (
          <ConnectionPoint
            setMouseOverConnection={setMouseOverConnection}
            cx={halfWidth}
            cy={height}
          />
        ) : null} */}

        {showConnectionPoints && (
          <SuggestionRect
            type={'circle'}
            x={width - 20}
            y={-10}
            height={100}
            width={50}
            onClickSugessionElement={onClickSugessionElement}
          />
        )}
      </g>
    );
  }
}

End.defaultProps = Step.defaultProps;
