import { ActionTypes } from 'constants/index'
import Utils from 'packages/page/utils'

export const getDapps = () => dispatch => {
	dispatch({ type: ActionTypes.GET_DAPPS });
}

export const getCategories = () => dispatch => {
	dispatch({ type: ActionTypes.GET_CATEGORIES });
}

export const setDappsSearchText = (event) => {
	return {
		type: ActionTypes.SET_DAPPS_SEARCH_TEXT,
		searchText: event.target.value
	}
}

export const setCategoryFilter = (event) => {
	return {
		type: ActionTypes.SET_DAPPS_CATEGORY_FILTER,
		category: event.target.value
	}
}

export const getDapp = (params) => dispatch => {
	return dispatch({ type: ActionTypes.GET_DAPP, payload: params.dappId })
}

export const getDappFiles = (params) => dispatch => {
	return dispatch({ type: ActionTypes.GET_DAPP_FILES, payload: params })
}

export const saveDapp = (data, user) => dispatch => {
	let dapp = data;
	dapp.orgId = user.orgId;
	dapp.createdBy = user.id;
	dapp.createdDateTime = new Date();
	return dispatch({ type: ActionTypes.SAVE_DAPP, payload: dapp });
}

export const updateDapp = (data) => dispatch => {
	return dispatch({ type: ActionTypes.UPDATE_DAPP, payload: data });
}

export const deleteDapp = (dappId) => dispatch => {
	return dispatch({ type: ActionTypes.DELETE_DAPP, payload: dappId });
}

export const newDapp = () => {
	const data = {
		id: Utils.generateGUID(),
		title: '',
		slug: '',
		category: '',
		dappType: '',
		categories: [],
		tags: [],
		length: '',
		totalSteps: '',
		activeStep: '',
		updated: '',
		active: true
	};

	return {
		type: ActionTypes.GET_DAPP_SUCCESS,
		payload: data
	}
}

export const currentDappId = (appId) => {
	return {
		type: ActionTypes.UPDATE_CURRENT_DAPPID,
		payload: appId
	}
}

export const currentDapp = (app) => {
	return {
		type: ActionTypes.UPDATE_CURRENT_DAPP,
		payload: app
	}
}