export default {

	USER_LOGIN: undefined,
	USER_LOGIN_SUCCESS: undefined,
	USER_LOGIN_FAILURE: undefined,

	USER_LOGOUT: undefined,
	USER_LOGOUT_SUCCESS: undefined,
	USER_LOGOUT_FAILURE: undefined,

	RESET_PASSWORD: undefined,
	RESET_PASSWORD_SUCCESS: undefined,
	RESET_PASSWORD_FAILURE: undefined,

	SET_USER_DATA: undefined,
	REMOVE_USER_DATA: undefined,
	  
	IS_USER_EXIST: undefined,
	IS_USER_EXIST_SUCCESS: undefined,
	IS_USER_EXIST_FAILURE: undefined,

}