import React from 'react';
import { Button, Grid, FormLabel } from '@material-ui/core';

const PrivilegesTable = (props) => {
	console.log("props", props, props.privileges)
	console.log("props", Array.isArray(props.privileges));
	if (props.privileges
		&& Array.isArray(props.privileges)) {
		return props.privileges.map((priv, index) => {
			if (!priv.editMode)
				return (
					<Grid container key={index}>
						<Grid item style={{ "padding": "10px" }} xs={3}>
							<FormLabel >{priv.privilegeType}</FormLabel>
						</Grid>
						<Grid item style={{ "padding": "10px" }} xs={3}>
							<FormLabel >{priv.privilegeValue}</FormLabel>
						</Grid>
						<Grid item style={{ "padding": "10px" }} xs={2}>
							<FormLabel >{priv.privilegeAction}</FormLabel>
						</Grid>
						{/* <Grid item style={{ "padding": "10px" }} xs={2}>
							<FormLabel >{priv.privilegeAction}</FormLabel>
						</Grid> */}
						<Grid item style={{ "padding": "10px" }} xs={2}>
							<Button variant="contained" color="secondary" onClick={() => {
								props.editPrivilege(priv, index);
							}} >Edit</Button>
							<Button variant="contained" color="secondary" onClick={() => {
								props.deletePrivilege(priv, index);
							}} >-</Button>
						</Grid>
					</Grid>
				)
			else return ''
		})
	} else return ''
}
export default PrivilegesTable;