import { ActionTypes } from 'constants/index'
import _ from 'modules/lodash';
let Actions;
// const addChart = ()

const initialState = {
	data: [],
	searchText: '',
	pageDialog: {
		open: false
	},
	page: {
		currentChartDataToEdit: '',
		chartType: 0,
		showDataToEditComponent: false,
		showListComponent: false,
		chart: [],
		add: false
	},
	saveSuccess: false,
	updateSuccess: false
}

const pages = function (state = initialState, action) {

	switch (action.type) {

		case ActionTypes.OPEN_NEW_PAGE_DIALOG: {
			return {
				...state,
				pageDialog: {
					open: true
				}
			}
		}
		case ActionTypes.CLOSE_NEW_PAGE_DIALOG: {
			return {
				...state,
				pageDialog: {
					open: false
				}
			}
		}
		case ActionTypes.GET_PAGES_SUCCESS: {
			return {
				...state,
				data: action.payload,
				saveSuccess: false,
				updateSuccess: false
			}
		}
		case ActionTypes.EMPTY_PAGE: {
			return {
				...initialState
			};
		}
		case ActionTypes.GET_PAGE_SUCCESS: {
			console.log("action.payload GET_PAGE_SUCCESS", action.payload);
			let page = { ...state.page, ...action.payload }
			return {
				...state,
				page,
				// pageSuccess: true
			}
		}
		case ActionTypes.SAVE_PAGE_SUCCESS: {
			console.log("action.payload SAVE_PAGE_SUCCESS", action.payload);
			return {
				...state,
				page: action.payload,
				saveSuccess: true
			}
		}
		case ActionTypes.UPDATE_PAGE_SUCCESS: {
			console.log("action.payload UPDATE_PAGE_SUCCESS", action.payload)
			return {
				...state,
				page: action.payload,
				updateSuccess: true
			}
		}
		case ActionTypes.SAVE_RESIZED_CHART_DATA: {
			let chart = [...state.page.chart]
			chart[action.payload.index] = action.payload.chart
			let page = { ...state.page, chart }
			return {
				...state,
				page,
				// pageOptions: action.payload
			}
		}
		case ActionTypes.TOGGLE_CHART_LIST_COMPONENT: {
			let page = { ...state.page, showListComponent: action.data }
			return {
				...state,
				page
			};
		}

		/* charts */
		case ActionTypes.ADD_BAR_CHART_DATA:
		case ActionTypes.ADD_STACKED_BAR_CHART_DATA:
		case ActionTypes.ADD_LINE_CHART_DATA:
		case ActionTypes.ADD_PIE_CHART_DATA:
		case ActionTypes.ADD_MIXED_CHART_DATA:
		case ActionTypes.ADD_BUBBLE_CHART_DATA:
		case ActionTypes.ADD_DOUGHNUT_CHART_DATA:
		case ActionTypes.ADD_RADAR_CHART_DATA:
		case ActionTypes.ADD_POLAR_CHART_DATA:
		case ActionTypes.ADD_LIST_CHART_DATA:
		case ActionTypes.ADD_SIMPLE_TABLE_DATA:
		case ActionTypes.ADD_CARD_DATA:
		case ActionTypes.ADD_HTML_DATA:
		case ActionTypes.ADD_SMIPLELIST_DATA:
		case ActionTypes.ADD_SUMMARYTABLE_DATA:
		case ActionTypes.ADD_BULLET_CHART_DATA:
		case ActionTypes.ADD_LINKS_DATA: {
			console.log("action.payload====", action.payload);
			let chart = [...state.page.chart, action?.payload?.chart || action?.data?.chart]
			let page = {
				...state.page,
				chart,
				add: true,
				showDataToEditComponent: true,
				currentChartIndexToEdit: chart.length - 1,
				chartType: action.payload.id,
				currentChartDataToEdit: action.payload.chart,
			}
			return {
				...state,
				page,
			}
		}


		case ActionTypes.CURRENT_CHART_TO_EDIT: {
			let page = {
				...state.page,
				chartType: action.payload.chartType,
				currentChartDataToEdit: action.payload.currentChartDataToEdit,
				showDataToEditComponent: true,
			}
			return {
				...state,
				page,
			}
		}

		case ActionTypes.DELETE_CHART_DATA: {
			let chart = JSON.parse(JSON.stringify(state.page.chart));
			let index = _.findIndex(chart, {
				chartUniqueId: action.payload
			});
			chart.splice(index, 1);
			let page = {
				...state.page,
				chart,
			}
			return {
				...state,
				page,
			}
		}

		case ActionTypes.CLOSE_CHART_TO_EDIT: {
			let page = {
				...state.page,
				currentChartDataToEdit: '',
				chartType: 0,
				showDataToEditComponent: false,
			}
			return {
				...state,
				page,
			}
		}

		case ActionTypes.SAVE_CHART_DATA: {
			let chart = JSON.parse(JSON.stringify(state.page.chart));
			let index = _.findIndex(chart, {
				chartUniqueId: action.payload.chartData.chartUniqueId
			});
			chart[index].chartData = action.payload.chartData.chartData;

			let page = {
				...state.page,
				chart,
				chartType: action.payload.chartType,
				currentChartDataToEdit: chart[index],
				showDataToEditComponent: action.payload.showDataToEditComponent
			}

			return {
				...state,
				page,
			};
		}

		case ActionTypes.DELETE_PAGE_SUCCESS: {
			return {
				...state,
				loading: false,
				data: [...state.data.filter(item => item._id !== action.payload)]
			}
		}
		case ActionTypes.DELETE_PAGE_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error
			}
		}


		default: {
			return state;
		}
	}
}

export default pages
