import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import 'styles/page-designer.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Highcharts from 'highcharts';
import Utils from 'packages/page/utils';
import _ from 'modules/lodash'
import {
	getApiDataFromDataUrl
} from 'services/page.service';
import {
	getPieChartConfig,
	getDefaultPieChart,
	generatePieData
} from 'packages/page/highcharts/pie.module';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const styles = theme => ({});

class PageDesignerPieChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...(getDefaultPieChart()),
			id: `piechart-${Utils.generateGUID()}`,
			setColor: true
		}
	}

	highChartsRender() {
		let pieConfig = getPieChartConfig(this.state.series, this.state.id);
		// if (this.state.setColor) {
		// 	this.setState({ setColor: false })
		// }
		Highcharts.chart(pieConfig);
	}

	componentDidMount() {
		this.initiateGetApi();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(this.props.element, prevProps.element)
			&& JSON.stringify(this.props.element) !== JSON.stringify(prevProps.element)) {
			// this.highChartsSeries();
			this.initiateGetApi();
		}
	}

	highChartsSeries() {
		let series = generatePieData(this.props.element.chartData.data, this.state.series)
		this.setState({ series: series }, () => {
			this.highChartsRender();
		})
	}

	setDatafromApi(res, data) {
		let d = { ...data };
		let { chartData } = this.props.element;
		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			d.labels = res.labels;
			if (!d.datasets) {
				d.datasets = []
				if (!d.datasets[0])
					d.datasets[0] = {}
			}
			d.datasets[0].data = res.data;
			d.datasets[0].label = res.label;
		} else {
			d.labels = res.data.labels;
			d.datasets[0].data = res.data.datasets[0].data;
			d.datasets[0].label = res.data.datasets[0].label;
		}
		let series = generatePieData(d, this.state.series)
		this.setState({ series }, () => {
			this.highChartsRender();
		})
	}

	getApiDataFromDataUrl = (url, data) => {
		getApiDataFromDataUrl(url, this.props.match.params.dappId)
			.then(res => {
				this.setDatafromApi(res.data, data)
			})
	}

	initiateGetApi() {
		let { chartData } = this.props.element;
		let props = this.props
		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			let targetURL = API_BASE_URL + "/dataconnect/chart/" + chartData.selectedDataModel + "?";
			const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
			targetURL += chartData.selectedDataElement.reduce(reducer, '&measure=');
			targetURL += chartData.selectedDataLabels.reduce(reducer, '&label=');
			targetURL += '&metric=' + chartData.metric;
			if (props.queryString) {
				targetURL += '&' + props.queryString;
			}
			if (chartData.datelabeltype) {
				targetURL += '&labeltype=date'
				targetURL += chartData.labelformat ? `&format=${chartData.labelformat}` : '&format=%Y-%m-%d';
			}

			if (chartData.conditions && chartData.conditions.length > 0) {
				let cond = JSON.stringify(chartData.conditions);
				targetURL += "&conditions=" + cond;
			}
			if (props.page && props.page.conditions && props.page.conditions.length > 0) {
				let cond = JSON.stringify(props.page.conditions);
				targetURL += "&pConditions=" + cond;
			}
			this.getApiDataFromDataUrl(targetURL, props.data);
		} else if (props.dataUrl) {
			this.getApiDataFromDataUrl(props.dataUrl, props.data);
		} else {
			console.log('====================================');
			console.log('props', props.element.chartData.data);
			console.log('this.state.series', this.state.series);
			console.log('====================================');
			let series = generatePieData(props.element.chartData.data, this.state.series)
			this.setState({ series }, () => {
				this.highChartsRender();
			})
		}
	}

	render() {
		console.log("PIE render PIE");
		return (
			<div id={this.state.id} style={{ height: "inherit", width: "inherit" }}></div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(null, null)(PageDesignerPieChart)
	)
);