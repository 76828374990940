const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
import { request } from 'modules/client'

let route = `${API_BASE_URL}/workflows`

export class WorkflowsService {

	static getWorkflows(payload) {
		const {appId, type} = payload
		const uri = `${route}?appId=${appId}&type=${type}`
		return request(uri, {
			method: 'GET',
			headers: { 'appId': appId }
		})
	}

	static getWorkflow(params) {
		const uri = `${route}/${params.id}`
		return request(uri, {
			method: 'GET',
			headers: { 'appId': params.dappId }
		})
	}

	static saveWorkflow(data) {
		return request(route, {
			method: 'POST',
			headers: { 'appId': data.appId },
			payload: data
		})
	}

	static updateWorkflow(data) {
		const uri = `${route}/${data.workflowId}`
		return request(uri, {
			method: 'PUT',
			headers: { 'appId': data.appId },
			payload: data
		})
	}

	static deleteWorkflow(data) {
		const uri = `${route}/${data._id}`
		return request(uri, {
			method: 'DELETE',
			headers: { 'appId': data.appId },
		})
	}
}