import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './view'
import Redux from './redux'
import { withRouter } from 'react-router-dom'

const HeaderContainer = (props) => {
	const [state, setState] = useState({ userMenu: null })

	const userMenuClick = event => setState({ ...state, userMenu: event.currentTarget })

	const userMenuClose = () => setState({ ...state, userMenu: null })

	return (
		<View
			userMenuClick={userMenuClick}
			userMenuClose={userMenuClose}
			state={state}
			{...props} />
	)
}

export default hot(withRouter(Redux(HeaderContainer)))