import React, { useState, useEffect } from 'react';
import View from './View';
import Redux from './Redux';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import sharedJSON from 'packages/sharedJSON';
import {
  getFilteredArray,
  handleSelectedRow,
  isRowselected,
  requestSort,
  handleTableSelectAllClick,
} from 'modules/common';
import { updateTableData, setDatafromApi } from 'packages/common';
import ConfirmDialog from '../confirmDialog';

const Container = props => {
  const { groups, deleteGroup } = props;

  const [state, setState] = useState({
    ...sharedJSON,
  });
  const [open, setOpen] = useState(false);
  const [groupState, setgroupState] = useState({});
  const AddGroupHandle = () => {
    props.history.push({ pathname: `/builder/${props.match.params.dappId}/groups/new` });
  };
  const resetFilters = event => setState({ ...state, filterList: [] });
  const handleInputChange = event => {
    let obj = state.filterTableData;
    let list = obj.columns;
    list.map((item, index) => {
      if (item.name === event.target.value) list[index].checked = event.target.checked;
    });
    obj.columns = [...list];
    setState({ ...state, filterTableData: obj });
    setState({ ...updateTableData(state) });
  };
  const onRowClick = (rowData, rowMeta) => {
    console.log('onRowClick');
  };

  const handleDropdownChange = (event, index, column) => {
    const value = event.target.value === 'All' ? [] : [event.target.value];
    // filterUpdate(index, value, column, 'dropdown');
    let list = state.filterList;
    list[index] = event.target.value;
    setState({ ...state, filterList: [...list] });
    setState({ ...updateTableData(state) });
  };
  const handleRequestSort = (event, property) => {
    let obj = requestSort(event, property, state);
    setState({ ...state, ...obj });
  };
  const handleSelectAllClick = event => {
    setState({
      ...state,
      selected: handleTableSelectAllClick(event, state.filterTableData.datasets),
    });
  };
  const tableCrudOperations = (item, type) => {
    if (type === 'edit') {
      props.history.push({ pathname: `/builder/${props.match.params.dappId}/groups/${item._id}` });
    } else if (type === 'delete') {
      setgroupState(item);
      setOpen(true);
    }
  };
  const handleCheck = (event, id) => {
    const { selected } = state;
    let newSelected = handleSelectedRow(event, id, selected);
    setState({ ...state, selected: newSelected });
  };

  const handleChangePage = (event, page) => setState({ ...state, page: page });
  const handleChangeRowsPerPage = event =>
    setState({ ...state, rowsPerPage: event.target.value, page: 0 });

  useEffect(() => {
    const params = props.match.params;
    const { dappId } = params;
    props.getGroups(dappId);
  }, []);

  useEffect(() => {
		if (groups.deleteSuccess) {
			props.showAlert('Group deleted successfully', { id: "group", variant: 'success', icon: 'bell' });
		}
	}, [groups.deleteSuccess])

  useEffect(() => {
    const rows = [
      {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
        sort: true,
      },
      {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description',
        sort: true,
      },
      // {
      // 	id: 'roles',
      // 	numeric: false,
      // 	disablePadding: false,
      // 	label: 'Description',
      // 	sort: true
      // },
    ];
    let keysArr = [
      '_id',
      'members',
      'createdAt',
      'updatedAt',
      'assignedRoles',
      'owner',
      'createdBy',
      'updatedBy',
      '__v',
    ];
    setState({ ...setDatafromApi(groups.data, keysArr, state, rows) });
  }, [groups.data]);

  return (
    <>
      <View
        {...props}
        state={state}
        AddGroupHandle={AddGroupHandle}
        handleInputChange={handleInputChange}
        resetFilters={resetFilters}
        onRowClick={onRowClick}
        handleDropdownChange={handleDropdownChange}
        handleRequestSort={handleRequestSort}
        handleSelectAllClick={handleSelectAllClick}
        tableCrudOperations={tableCrudOperations}
        handleCheck={handleCheck}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <ConfirmDialog
        title="Delete"
        open={open}
        setOpen={setOpen}
        onConfirm={() => deleteGroup({ _id: groupState._id, appId: props.match.params.dappId })}
      >
        Groups {groupState.name}
      </ConfirmDialog>
    </>
  );
};

export default hot(withRouter(Redux(Container)));
