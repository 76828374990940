import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, networks }) => {
		return {
			user: user,
			navigation: navigation,
			networks: networks
		}
	}

	const mapDispatchToProps = {
		getNetworks: Actions.getNetworks,
		deleteNetwork: Actions.deleteNetwork,
		showAlert: Actions.showAlert,
		hideAlert: Actions.hideAlert
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux