import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'

const Redux = (PassedComponent) => {

	const mapStateToProps = ({ user, navigation, businessRules }) => {
		return {
			user: user,
			navigation: navigation,
			businessRules: businessRules
		}
	}

	const mapDispatchToProps = {
		getBusinessRules: Actions.getBusinessRules,
		deleteBusinessRule: Actions.deleteBusinessRule
	}

	return connect(mapStateToProps, mapDispatchToProps)(
		({ ...props }) =>
			<PassedComponent {...props} />
	)
}

export default Redux