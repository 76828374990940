export default {

	GET_NETWORKS: undefined,
	GET_NETWORKS_SUCCESS: undefined,
	GET_NETWORKS_FAILURE: undefined,

	GET_NETWORK: undefined,
	GET_NETWORK_SUCCESS: undefined,
	GET_NETWORK_FAILURE: undefined,

	SAVE_NETWORK: undefined,
	SAVE_NETWORK_SUCCESS: undefined,
	SAVE_NETWORK_FAILURE: undefined,

	UPDATE_NETWORK: undefined,
	UPDATE_NETWORK_SUCCESS: undefined,
	UPDATE_NETWORK_FAILURE: undefined,

	DELETE_NETWORK: undefined,
	DELETE_NETWORK_SUCCESS: undefined,
	DELETE_NETWORK_FAILURE: undefined,

}