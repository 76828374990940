export const TextBoxJson = (data) => {
	return {
		name: data.basic.name,
		type: "text",
		label: data.basic.label,
		columns: data.basic.columns,
		labelPosition: "top",
		labelStyle: "",
		className: data.basic.className,
		style: data.basic.style,
		defaultValue: data.data.data.defaultValue,
		minLength: data.validation.minLength ? data.validation.minLength : "",
		maxLength: data.validation.maxLength ? data.validation.maxLength : "",
		required: data.validation.required ? data.validation.required : false,
		pattern: data.validation.pattern,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		variant: data.ui.variant ? data.ui.variant : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden,
		labelPosition: data.ui.labelPosition ? data.ui.labelPosition : null,
		computed: data.data.data.computed,
		lookupDataModal: data.data.data.lookupDataModal,
		lookupDataElement: data.data.data.lookupDataElement,
		lookupDataKey: data.data.data.lookupDataKey,
		lookupDataValue: data.data.data.lookupDataValue,
		formula: data.data.data.formula,
	}
}

export const TextAreaJson = (data) => {
	return {
		name: data.basic.name,
		type: "text",
		label: data.basic.label,
		labelPosition: "top",
		labelStyle: "",
		columns: data.basic.columns,
		className: data.basic.className,
		style: data.basic.style,
		defaultValue: data.data.data.defaultValue,
		minLength: data.validation.minLength ? data.validation.minLength : "",
		maxLength: data.validation.maxLength ? data.validation.maxLength : "",
		required: data.validation.required ? data.validation.required : false,
		pattern: data.validation.pattern,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		rows: 2,
		variant: data.ui.variant ? data.ui.variant : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden,
		labelPosition: data.ui.labelPosition ? data.ui.labelPosition : null,
		computed: data.data.data.computed,
		lookupDataModal: data.data.data.lookupDataModal,
		lookupDataElement: data.data.data.lookupDataElement,
		lookupDataKey: data.data.data.lookupDataKey,
		lookupDataValue: data.data.data.lookupDataValue,
		formula: data.data.data.formula
	}
}

export const LabelJson = (data) => {
	return {
		type: "html",
		content: data.basic.label,
		tag: "label",
		className: data.basic.className,
		columns: data.basic.columns,
		style: data.basic.style,
		align: data.basic.align,
		color: data.basic.color,
		size: parseInt(data.basic.tag) * 1.5 + "rem",
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		hidden: data.basic.hidden
	}
}

export const HeaderJson = (data) => {
	return {
		type: "html",
		content: data.basic.label,
		tag: "h" + (6 - parseInt(data.basic.tag)),
		align: data.basic.align,
		columns: data.basic.columns,
		color: data.basic.color,
		className: data.basic.className,
		style: data.basic.style,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		hidden: data.basic.hidden
	}
}

export const RadioGroupJson = (data) => {
	return {
		style: data.basic.style,
		name: data.basic.name,
		id: data.basic.name,
		columns: data.basic.columns,
		type: "radio",
		label: {
			"labelText": data.basic.label,
			"labelPosition": "top",
			"labelStyle": ""
		},
		ui: {
			"className": data.basic.className,
			"style": ""
		},
		data: {
			values: data.data.data.values ? data.data.data.values : []
			,
			defaultValue: "ONETIME"

		},
		events: {
			onLoad: "",
			onChange: "",
			onBlur: ""
		},
		validation: {
			required: data.validation.required ? data.validation.required : false

		},
		visibility: {
			hiddenWhen: {
				fieldName: "",
				fiedlValue: "",
				opeartion: "eq"
			},
			disabledWhen: {
				fieldName: "",
				fiedlValue: "",
				opeartion: "eq"
			}
		},
		security: {
			read: ["Authenticated"],
			edit: ["Administrator"]
		},
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		alignment: data.ui.alignment ? data.ui.alignment : 'normal',
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden,
		dataModel: data.data.data.dataModel,
		workflow: data.data.data.workflow,
		dataElement: data.data.data.dataElement,
		dataType: data.data.data.dataType,
		dynamicDataAPI: data.data.data.dynamicDataAPI,
		dynamicDataFunction: data.data.data.dynamicDataFunction
	}

}
export const CheckboxesGroupJson = (data) => {
	return {
		name: data.basic.name,
		id: data.basic.name,
		type: "checkbox",
		columns: data.basic.columns,
		label: {
			"labelText": data.basic.label,
		},
		ui: {
			"className": data.basic.className,
		},
		data: {

			values: data.data.data.values ? data.data.data.values : [],
			defaultValue: "GROUND"
		},
		validation: {
			required: data.validation.required ? data.validation.required : false
		},
		style: data.basic.style,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden,
		dataModel: data.data.data.dataModel,
		workflow: data.data.data.workflow,
		dataElement: data.data.data.dataElement,
		dataType: data.data.data.dataType,
		dynamicDataAPI: data.data.data.dynamicDataAPI,
		alignment: data.ui.alignment ? data.ui.alignment : 'normal',
		dynamicDataFunction: data.data.data.dynamicDataFunction
	}
}

export const SelectJson = (data) => {
	return {
		name: data.basic.name,
		type: data.basic.type ? data.basic.type : "select",
		label: data.basic.label,
		labelPosition: "top",
		columns: data.basic.columns,
		labelStyle: "",
		className: data.basic.className,
		style: data.basic.style,
		defaultValue: data.data.data.defaultValue,
		// values: getOptions(data.data.data.values),
		values: data.data.data.values,
		required: data.validation.required ? data.validation.required : false,
		multi: data.basic.multi ? data.basic.multi : false,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		disabled: data.basic.disabled,
		dataModel: data.data.data.dataModel,
		workflow: data.data.data.workflow,
		dataElement: data.data.data.dataElement,
		dataType: data.data.data.dataType,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden,
		dynamicDataAPI: data.data.data.dynamicDataAPI,
		dynamicDataFunction: data.data.data.dynamicDataFunction
	}
}
export const NamePickerJson = (data) => {
	return {
		name: data.basic.name,
		type: 'namePicker',
		label: data.basic.label,
		labelPosition: "top",
		columns: data.basic.columns,
		labelStyle: "",
		className: data.basic.className,
		style: data.basic.style,
		defaultValue: data.data.data.defaultValue,
		values: getOptions(data.data.data.values),
		required: data.validation.required ? data.validation.required : false,
		multi: data.basic.multi ? data.basic.multi : false,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden,
	}
}

export const PageOrFormPickerJson = (data) => {
	return {
		name: data.basic.name,
		type: 'pageOrFormPicker',
		label: data.basic.label,
		labelPosition: "top",
		columns: data.basic.columns,
		labelStyle: "",
		listType: data.basic.listType || "page",
		className: data.basic.className,
		style: data.basic.style,
		defaultValue: data.data.data.defaultValue,
		values: getOptions(data.data.data.values),
		required: data.validation.required ? data.validation.required : false,
		multi: data.basic.multi ? data.basic.multi : false,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden,
	}
}

export const DataSelectorJson = (data) => {
	return {
		name: data.basic.name,
		type: 'dataSelector',
		label: data.basic.label,
		labelPosition: "top",
		columns: data.basic.columns,
		labelStyle: "",
		className: data.basic.className,
		style: data.basic.style,
		defaultValue: data.data.data.defaultValue,
		values: getOptions(data.data.data.values),
		required: data.validation.required ? data.validation.required : false,
		multi: data.basic.multi ? data.basic.multi : false,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden,
		dataSelectorDataModal: data.data.data.dataSelectorDataModal,
		dataSelectorElement: data.data.data.dataSelectorElement,
		dataSelectorElementForSearch: data.data.data.dataSelectorElementForSearch,
	}
}
export const DataTableJson = (data) => {
	return {
		name: data.basic.name,
		type: 'dataTable',
		label: data.basic.label,
		labelPosition: "top",
		columns: data.basic.columns,
		labelStyle: "",
		className: data.basic.className,
		style: data.basic.style,
		defaultValue: data.data.data.defaultValue,
		values: getOptions(data.data.data.values),
		required: data.validation.required ? data.validation.required : false,
		multi: data.basic.multi ? data.basic.multi : false,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden,
		dataModel: data.data.data.dataModel,
		dataElement: data.data.data.dataElement,
	}
}
export const ReportConfiguratorJson = (data) => {
	return {
		name: data.basic.name,
		type: 'reportConfigurator',
		label: data.basic.label,
		labelPosition: "top",
		columns: data.basic.columns,
		labelStyle: "",
		className: data.basic.className,
		style: data.basic.style,
		defaultValue: data.data.data.defaultValue,
		values: getOptions(data.data.data.values),
		required: data.validation.required ? data.validation.required : false,
		multi: data.basic.multi ? data.basic.multi : false,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden,
		dataModel: data.data.data.dataModel,
		dataElement: data.data.data.dataElement,
	}
}

const getOptions = (Object) => {
	let arr = [];
	Object.map((el) => {
		arr.push(el.value);
	})
	return arr;
}

export const DateJson = (data) => {
	return {
		name: data.basic.name,
		type: "date",
		label: data.basic.label,
		columns: data.basic.columns,
		labelStyle: "",
		className: data.basic.className,
		style: data.basic.style,
		defaultValue: data.data.data.defaultValue,
		required: data.validation.required ? data.validation.required : false,
		min: data.validation.min != null ? data.validation.min : "",
		max: data.validation.max ? data.validation.max : "",
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		variant: data.ui.variant ? data.ui.variant : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden,
		labelPosition: data.ui.labelPosition ? data.ui.labelPosition : null
	}
}

export const ButtonJson = (data) => {
	return {
		type: "button",
		name: data.basic.name,
		style: data.basic.style,
		label: data.basic.label,
		columns: data.basic.columns,
		action: data.data.data,
		function: data.data.data.selectedFunction,
		url: data.data.data.url,
		headers: data.data.data.headers,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null

	}
}

export const SpecificTextBoxJson = (data) => {
	return {
		name: data.basic.name,
		typeOfText: data.basic.typeOfText == 'currency' ? "number" : data.basic.typeOfText,
		label: data.basic.label,
		columns: data.basic.columns,
		labelStyle: "",
		className: data.basic.className,
		style: data.basic.style,
		min: data.validation.min != null ? data.validation.min : "",
		max: data.validation.max ? data.validation.max : "",
		defaultValue: data.data.data.defaultValue,
		step: data.basic.step ? data.basic.step : 1,
		pattern: data.validation.pattern,
		required: data.validation.required ? data.validation.required : false,
		currencySymbol: data.basic.currencySymbol,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		variant: data.ui.variant ? data.ui.variant : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden,
		labelPosition: data.ui.labelPosition ? data.ui.labelPosition : null,
	}

}

export const SliderJson = (data) => {
	return {
		name: data.basic.name,
		id: data.basic.name,
		columns: data.basic.columns,
		style: data.basic.style,
		type: "slider",
		label: {
			"labelText": data.basic.label,
			"labelPosition": "top",
			"labelStyle": ""
		},
		data: {
			values: data.data.data.values
		},
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null

	}

}
export const SwitchJson = (data) => {
	return {
		name: data.basic.name,
		type: "switch",
		label: data.basic.label,
		columns: data.basic.columns,
		className: data.basic.className,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden,
		style: data.basic.style,

	}
}
export const ChipJson = (data) => {
	return {
		name: data.basic.name,
		id: data.basic.name,
		columns: data.basic.columns,
		type: "chip",
		values: data.data.data.values,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		style: data.basic.style,

	}

}

export const HTMLJson = (data) => {
	return {
		type: "HTML_content",
		content: data.basic.HTML ? data.basic.HTML : "",
		name: data.basic.name,
		columns: data.basic.columns,
		label: data.basic.label,
		className: data.basic.className,
		hidden: data.basic.hidden,
		style: data.basic.style,
	}
}

export const TabJson = (data) => {

	return {
		name: data.basic.name,
		id: data.basic.name,
		columns: data.basic.columns,
		type: "tab",
		label: data.basic.label,
		ui: {
			"className": data.basic.className,
		},
		data: {

			values: data.data.data.values ? data.data.data.values : []
		},
		innerForms: getFormsForEachTab(data.data.data.values),
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden,
		dynamicDataFunction: data.data.data.dynamicDataFunction,
		style: data.basic.style,

	}
}
const getFormsForEachTab = (data) => {
	return data.map((value) => {
		return {
			innerForm: {
				"rows": []
			},
			innerFormModalData: {
				"rows": []
			},
			value: value.value
		}
	})
}

export const CardJson = (data) => {

	return {
		name: data.basic.name,
		id: data.basic.name,
		columns: data.basic.columns,
		type: "card",
		label: data.basic.label,
		ui: {
			"className": data.basic.className,
		},
		data: {

			values: data.data.data.values ? data.data.data.values : []
		},
		style: data.basic.style,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden,
		innerForm: {
			"rows": []
		},
		innerFormModalData: {
			"rows": []
		},
		computed: data.data.data.computed,
		lookupDataModal: data.data.data.lookupDataModal,
		lookupDataElement: data.data.data.lookupDataElement,
		lookupDataKey: data.data.data.lookupDataKey,
		lookupDataValue: data.data.data.lookupDataValue,
	}
}

export const MultiLineJson = (data) => {

	return {
		name: data.basic.name,
		id: data.basic.name,
		type: "multiLine",
		label: data.basic.label,
		columns: data.basic.columns,
		className: data.basic.className,
		data: {

			values: data.data.data.values ? data.data.data.values : []
		},
		style: data.basic.style,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden
	}
}

export const ExpansionPanelJson = (data) => {

	return {
		name: data.basic.name,
		id: data.basic.name,
		type: "expansionPanels",
		label: data.basic.label,
		columns: data.basic.columns,
		ui: {
			"className": data.basic.className,
		},
		data: {
			values: data.data.data.values,
		},
		style: data.basic.style,
		innerForms: getFormsForEachTab(data.data.data.values),
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		showExpandPanel: data.basic.showExpandPanel,
		role: data.basic.role,
		hidden: data.basic.hidden,
		dynamicDataFunction: data.data.data.dynamicDataFunction,
		panelVisibility: data.data.data.panelVisibility
	}
}


export const MultiStepFormJson = (data) => {
	return {
		name: data.basic.name,
		id: data.basic.name,
		type: "multiStepForm",
		label: data.basic.label,
		columns: data.basic.columns,
		ui: {
			"className": data.basic.className,
		},
		data: {
			values: data.data.data.values,
		},
		style: data.basic.style,
		navigationStyle: data.ui.navigationStyle || 'Default',
		innerForms: getFormsForEachTab(data.data.data.values),
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden,
		dynamicDataFunction: data.data.data.dynamicDataFunction,
		dataModel: data.data.data.dataModel,
		workflow: data.data.data.workflow,
		dataElement: data.data.data.dataElement,
		dataType: data.data.data.dataType,
		dynamicDataAPI: data.data.data.dynamicDataAPI,
	}
}

export const TableJson = (data) => {

	return {
		name: data.basic.name,
		id: data.basic.name,
		type: "table",
		label: data.basic.label,
		className: data.basic.className,
		columns: data.basic.columns,
		data: {

			values: data.data.data.values ? data.data.data.values : [],
			rows: data.data.data.rows
		},
		style: data.basic.style,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden
	}
}

export const WorksheetJson = (data) => {

	return {
		name: data.basic.name,
		id: data.basic.name,
		type: "worksheet",
		label: data.basic.label,
		className: data.basic.className,
		columns: data.basic.columns,
		data: {

			values: data.data.data.values ? data.data.data.values : [],
			rows: data.data.data.rows
		},
		style: data.basic.style,
		backgroundColor: data.ui.backgroundColor ? data.ui.backgroundColor : null,
		disabled: data.basic.disabled,
		showInThePageGrid: data.basic.showInThePageGrid,
		unique: data.basic.unique,
		role: data.basic.role,
		hidden: data.basic.hidden
	}
}