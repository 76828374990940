import React, { useState, useEffect } from 'react'
import {
	Box, Grid, Typography, Button
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { withStyles, makeStyles } from '@material-ui/core/styles';
// import 'styles/styles.scss'
import rightarrow from 'images/rightarrow.svg';
import Row from './Row'

export default function View(props) {
	return (<Box className="abcCompany-page">
		<Box className="pxy20">
			<Grid container>
				<Grid item xs={12} md={6}>
					<div className="backbtn">
						<div className="backbtn">
							<img src={rightarrow} alt="backicon" className="backicon" />
							<Box className="companyheadingtext">
								<Typography variant="h4">Entities</Typography>
							</Box>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} md={6} className="savebtn-sec">
					<Button variant="contained" color="primary" className="addnewentrybtn mb5"
						onClick={() => props.handleAddClick()}>
						{"ADD ENTITY"}
					</Button>
				</Grid>
				<Box className="processtab-sec">
					<Grid item xs={12} md={12} className="abcuserinterface-sec">
						{props.entities.data.map((entity, i) => {
							return <Row key={i} entity={entity} handleActionsClick={props.handleActionsClick} />
						})}
					</Grid>
				</Box>
			</Grid>
		</Box>
	</Box>)
}