import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './View'
import Redux from './Redux'
import ConfirmDialog from '../confirmDialog'
import { withRouter } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	loaderContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 'calc(100vh - 75px)',
		width: '100%',
	},
}));


const Container = (props) => {
	const [open, setOpen] = React.useState(false)
	const [state, setState] = useState({
		form: {},
		isSubmitButton: true,
		files: {}
	})

	const classes = useStyles()

	const [error, setError] = useState({ title: false, dappCode: false })

	const dappValidation = (error) => {
		const { title, dappCode, footer, description } = state.form
		error && setState({ ...state, isSubmitButton: false })
		if (!title || title && title.length === 0) {
			error && setError({ ...error, title: "Please enter title for the app" })
			// } else if (!dappCode || dappCode && dappCode.length === 0) {
			// 	error && setError({ ...error, dappCode: "Please enter dappCode for the app" })
			// } else if (!footer || footer && footer.length === 0) {
			// 	error && setError({ ...error, footer: "Please enter Footer for the app" })
		} else if (!description || description && description.length === 0) {
			error && setError({ ...error, description: "Please enter Description for the app" })
		} else {
			return false
		}
		return true
	}

	const handleUIChange = (event, key) => {
		let d = { ...state.form[key] }
		d[event.target.name] = event.target.value
		setState({ ...state, form: _.set({ ...state.form }, key, d) })
	}

	const canBeSubmitted = () => {
		// const { title, dappCode, footer, description } = state.form
		// return title && title.length > 0 && dappCode && dappCode.length > 0 && footer && footer.length > 0 && description
		// 	&& description.length > 0 && !_.isEqual(props.dapp.data, state.form) && state.isSubmitButton

		return !dappValidation(false) && !_.isEqual(props.dapp.data, state.form) && state.isSubmitButton
	}

	const handleChange = (event) => {
		setState({
			...state,
			form: _.set({ ...state.form }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value)
		})
	}

	const handleFileChange = (file) => setState({ ...state, form: _.set({ ...state.form }, 'logoId', file.id) })

	// const handleChipChange = (event, name) => {
	// 	setState({ ...state, form: _.set({ ...state.form }, name, event.target.value) })
	// }

	const handleChipChange = (value, name) => setState({ form: _.set({ ...state.form }, name, value.map(item => item.value)) })

	const updateFormState = () => setState({ ...state, form: { ...props.dapp.data } })

	const handleError = (error, refresh) => {
		if (typeof error === "object" && error.status) {
			if (error.status == 401) {
				props.history.push({ pathname: "/login" })
				props.showAlert(error.message, { id: "dapp", variant: 'danger' })
			} else {
				refresh && setState({ ...state, isSubmitButton: true })
				if (error.message) {
					props.showAlert(error.message, { id: "dapp", variant: 'danger' })
				}
			}
		}
	}

	const updateDappState = () => {
		const { dappId } = props.match.params

		if (dappId === 'new' || dappId === 'addappconfig' || dappId === 'addappfiles' || dappId === 'addappquickwizard' || dappId === 'addapptemplates') {
			props.newDapp()
		}
		else {
			props.getDapp(props.match.params)
			props.getRoles(dappId)
		}
	}

	const saveDappData = () => {
		let q = queryStringToJSON() || {}
		let form = { ...state.form, ...state.files, mode: q.mode }
		if (!dappValidation(true)) {
			setState({ ...state, isSubmitButton: false })
			props.saveDapp(form, props.user)
		}
	}

	const updateDappData = () => {
		setState({ ...state, isSubmitButton: false })
		props.updateDapp(state.form)
	}

	useEffect(() => {
		updateDappState()
	}, [])

	useEffect(() => {
		if (props.user.id || props.user.email)
			props.getMemberByUserId({ ...props.user, ...props.match.params })
	}, [props.user])

	useEffect(() => {
		updateDappState()
	}, [props.location])

	useEffect(() => {
		updateFormState()
	}, [props.dapp.data])

	const deleteDappData = () => {
		props.hideAlert("dapp");
		setOpen(true);
	}

	useEffect(() => {
		if (props.dapp.saveSuccess) {
			props.showAlert('Dapp saved successfully', { id: "dapp", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/dapps/${props.dapp.data._id}` })
			props.currentDappId(props.dapp.data._id)
			props.currentDapp(props.dapp.data)
		}
	}, [props.dapp.saveSuccess])

	useEffect(() => {
		if (props.dapp.updateSuccess) {
			props.showAlert('Dapp updated successfully', { id: "dapp", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/dapps/${props.dapp.data._id}` })
			props.currentDappId(props.dapp.data._id)
			props.currentDapp(props.dapp.data)
		}
	}, [props.dapp.updateSuccess])

	useEffect(() => {
		if (props.dapp.deleteSuccess) {
			props.showAlert('Dapp deleted successfully', { id: "dapp", variant: 'success', icon: 'bell' });
			props.history.push({ pathname: `/builder/dapps` })
		}
	}, [props.dapp.deleteSuccess])

	useEffect(() => {
		if (!_.isEqual(props.dapp.data, state.form)) {
			setState({ ...state, isSubmitButton: true })
		}
	}, [state.form])

	useEffect(() => {
		let error = props.dapp.error
		handleError(error, true)
		if (error) {
			error.title
				// && error.dappCode
				&& props.showAlert("NETWORK ERROR: Please verify your internet connection", {
					id: "dapp",
					variant: 'danger',
					icon: 'times-circle'
				})
			// if (error.dappCode) {
			// 	setError({ ...error, dappCode: error.dappCode })
			// } else
			if (error.title) {
				setError({ ...error, title: error.title })
			}
		}
	}, [props.dapp.error])

	useEffect(() => {
		handleError(props.roles.error)
	}, [props.roles.error])

	useEffect(() => {
		setState({
			...state,
			roles: props.roles && props.roles.data.map(role => ({
				value: role._id,
				label: role.name
			}))
		})
	}, [props.roles.data])

	useEffect(() => {
		console.log("title GONE")
	}, [state.form.title])

	const queryStringToJSON = (qs) => {
		qs = qs || props.location.search.slice(1);
		var pairs = qs.split('&');
		var result = {};
		pairs.forEach(function (p) {
			var pair = p.split('=');
			var key = pair[0];
			var value = decodeURIComponent(pair[1] || '');
			if (result[key]) {
				if (Object.prototype.toString.call(result[key]) === '[object Array]') {
					result[key].push(value);
				} else {
					result[key] = [result[key], value];
				}
			} else {
				result[key] = value;
			}
		})
		return JSON.parse(JSON.stringify(result));
	};

	useEffect(() => {
		let q = queryStringToJSON(props.location.search.substring(1))
		if (props.dapp.files && props.dapp.files.status === "success" && q && q.mode) {
			setState({ ...state, files: props.dapp.files })
		}
	}, [props.dapp.files])

	if (props.member?.memberByIdLoading) {
		return <div className={classes.loaderContainer}><CircularProgress /></div>
	}

	return (<>
		<View form={state.form} handleUIChange={handleUIChange}
			dappId={props.match.params.dappId}
			handleChange={handleChange}
			handleChipChange={handleChipChange}
			saveDapp={() => saveDappData()}
			roles={state.roles}
			error={error}
			updateDapp={() => updateDappData()}
			deleteDapp={() => deleteDappData()}
			canBeSubmitted={canBeSubmitted}
			handleFileChange={handleFileChange} />
		<ConfirmDialog
			title="Delete"
			open={open}
			setOpen={setOpen}
			onConfirm={() => props.deleteDapp(props.dapp.data._id)}>
			{"application"} {props?.dapp?.data?.title}
		</ConfirmDialog>
	</>)
}

export default hot(withRouter(Redux(Container)))