import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import View from './view'

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}))

function Main(props) {
    const { handleDrawerToggle } = props;
    const classes = useStyles();
    const theme = useTheme();
    return (<main className={classes.content}>
        <div className={classes.toolbar} />
            <View />
        </main>)
}
export default Main