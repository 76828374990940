import React from 'react'
import { DialogContent, DialogTitle, TextField, Grid } from '@material-ui/core'

export const ApiResponseModal = (state) => {
	return (<>
		<DialogTitle id="alert-dialog-title">
			{state.apiResponse.buttonType == "api" ? "Api Response Received" : "Saved Data"}
		</DialogTitle>
		<DialogContent >
			<Grid container spacing={8}>
				{state.apiResponse.buttonType == "api" && <>
					<Grid item xs={2}><label>{"API URL"}</label></Grid>
					<Grid item xs={10}>
						<TextField
							label=""
							margin="dense"
							variant="filled"
							fullWidth
							value={state.apiResponse.url}
							multiline={true}
							disabled={true}
						/>
					</Grid>
					<Grid item xs={2}><label>{"Headers"}</label></Grid>
					<Grid item xs={10}>
						<TextField
							label=""
							margin="dense"
							variant="filled"
							fullWidth
							multiline={true}
							disabled={true}
							value={JSON.stringify(state.apiResponse.headers)}
						/>
					</Grid>
				</>}
				<Grid item xs={2}><label>{"Body"}</label></Grid>
				<Grid item xs={10}>
					<TextField
						label=""
						margin="dense"
						variant="filled"
						fullWidth
						multiline={true}
						disabled={true}
						value={JSON.stringify(state.dataFilled)}
					/>
				</Grid>
				{state.apiResponse.buttonType == "api" && <>
					<Grid item xs={2}><label>{"Response"}</label></Grid>
					<Grid item xs={10}>
						<TextField
							label=""
							margin="dense"
							variant="filled"
							fullWidth
							multiline={true}
							disabled={true}
							value={JSON.stringify(state.apiResponse.response)}
						/>
					</Grid>
				</>}
			</Grid>
		</DialogContent>
	</>)
}