import React, { Component } from 'react';
import {
	TextField, FormControlLabel, Checkbox, Radio, FormControl,
	RadioGroup, FormLabel, TextareaAutosize
} from '@material-ui/core';
import * as Actions from 'actions';

class BasicInfo extends Component {
	render() {
		let props = this.props;
		return (<>
			<div className="pro-basic">
				<FormControl>
					<FormLabel variant="h6" className="label-headings">{"Name *"}</FormLabel>
					<TextField
						className="mt-8 mb-16"
						id="name"
						name="name"
						value={props.values.name}
						onChange={props.onChangeHandler()}
						variant="outlined"
						fullWidth
					/>
				</FormControl>
				<FormControl>
					<FormLabel variant="h6" className="label-headings">{"Description"}</FormLabel>
					<TextareaAutosize
						className={props.classes.formControl + " mt-8 mb-16"}
						id="description"
						type="text"
						name="description"
						rowsMin={3}
						value={props.values.description}
						onChange={props.onChangeHandler()}
						multiline={true}
						rows={5}
						variant="outlined"
						fullWidth
					/>
				</FormControl>
				<FormControl>
					<FormLabel variant="h6" className="label-headings">{"Title"}</FormLabel>
					<TextField
						className="mt-8 mb-16"
						id="title"
						name="title"
						value={props.values.title}
						onChange={props.onChangeHandler()}
						variant="outlined"
						fullWidth
					/>
				</FormControl>
				{/* TODO - Need to discuss regard this field */}
				{/* <FormControl fullWidth className="mt-8 mb-16">
                    <Select
                        value={props.values.componentType}
                        onChange={props.onChangeHandler()}
                        inputProps={{
                            name: 'componentType',
                            id: 'componentType',
                        }}>
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="bar">
                            <em>Bar</em>
                        </MenuItem>
                    </Select>
                </FormControl> */}

				<FormControl>
					<FormControlLabel
						control={
							<Checkbox
								checked={props.values.componentLibrary}
								color="primary"
								value="true"
								onChange={props.handleCheckboxChange('')}
							/>
						}
						label="Save to component library"
					/>

				</FormControl>

				<FormControl>
					<FormLabel component="legend">{"Share with"}</FormLabel>
					<RadioGroup
						aria-label="Share with"
						name="share"
						className={props.classes.group}
						value={props.values.share}
						onChange={props.onChangeHandler()}
						row
					>
						<FormControlLabel
							value="private"
							control={<Radio />}
							label="Private" />
						<FormControlLabel
							value="entity"
							control={<Radio />}
							label="Share within same entity" />
						<FormControlLabel
							value="network"
							control={<Radio />}
							label="Share within network" />
						<FormControlLabel
							value="public"
							control={<Radio />}
							label="Public" />
					</RadioGroup>

				</FormControl>
				{
					this.props.chartType === Actions.lineChart &&
					<FormControl>
						<FormControlLabel
							control={
								<Checkbox
									checked={props.values.isGrouping}
									onChange={props.onChangeHandler()}
									name="isGrouping"
									value='true'
								/>
							}
							label="Grouping"
						/>
					</FormControl>
				}
				{this.props.chartType === Actions.barChart && <FormControl>
					<FormLabel component="legend">{"Orientation"}</FormLabel>
					<RadioGroup
						aria-label="Orientation"
						name="orientation"
						className={props.classes.group}
						value={props.values.orientation}
						onChange={props.onChangeHandler()}
						row
					>
						<FormControlLabel
							value="column"
							control={<Radio />}
							label="Vertical" />
						<FormControlLabel
							value="bar"
							control={<Radio />}
							label="Horizontal" />
					</RadioGroup>
					<FormControlLabel
						control={
							<Checkbox
								checked={props.values.stacked}
								onChange={props.onChangeHandler()}
								name="stacked"
								value='true'
							/>
						}
						label="Stacked bar"
					/>
				</FormControl>}
			</div>
		</>)
	}
}
export default BasicInfo;
