import React, { Component } from 'react';
import { TextField, FormControl, FormLabel, FormControlLabel, Checkbox } from '@material-ui/core';

class BasicInfo extends Component {
	render() {
		let { classes, values, handleChange, handleCheckboxChange } = this.props;
		return (<>
			<form className="form" noValidate autoComplete="off" >
				<FormControl>
					<FormLabel variant="h6" className="label-headings">Name *</FormLabel>
					<TextField
						id="name"
						name="name"
						variant="outlined"
						color="secondary"
						className="form-control"
						placeholder="Name"
						value={values.name}
						onChange={handleChange}
					/>
				</FormControl>
				<FormControl>
					<FormLabel variant="h6" className="label-headings">URL *</FormLabel>
					<TextField
						id="url"
						name="url"
						variant="outlined"
						color="secondary"
						className="form-control"
						placeholder="URL"
						value={values.url}
						onChange={handleChange}
					/>
				</FormControl>
				<FormControl>
					<FormLabel variant="h6" className="label-headings">Title *</FormLabel>
					<TextField
						id="title"
						name="title"
						variant="outlined"
						color="secondary"
						className="form-control"
						placeholder="Title"
						value={values.title}
						onChange={handleChange}
					/>
				</FormControl>
				<FormControl>
					<FormLabel variant="h6" className="label-headings">Description *</FormLabel>
					<TextField
						id="description"
						name="description"
						variant="outlined"
						color="secondary"
						className="form-control"
						placeholder="Description"
						value={values.description}
						onChange={handleChange}
					/>
				</FormControl>
				<FormControlLabel
					control={
						<Checkbox
							checked={values.isCustom || false}
							onChange={handleCheckboxChange('isCustom')}
							value="isCustom"
							color="primary"
						/>
					}
					label="Redirect to custom page"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={values.isDisplayHeading || false}
							onChange={handleCheckboxChange('isDisplayHeading')}
							value="isDisplayHeading"
							color="primary"
						/>
					}
					label="Show Heading"
				/>
			</form>
		</>)
	}
}
export default BasicInfo;
