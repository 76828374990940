import React, {Component} from "react";
import {withStyles} from '@material-ui/core/styles';
import "styles/page-designer.css";
import {withRouter} from 'react-router-dom';
import * as Actions from 'actions';
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux';
import _ from 'modules/lodash';
import MUIDataTable from "mui-datatables";

const styles = theme => ({
    closeIconContainer: {
        display: "flex",
        flexDirection : "row"
    }
});

const options = {
    filterType: 'dropdown',
    responsive: "scrollMaxHeight"
};

class PageDesignerTableChart extends Component {

    getTableHeader = (data) => {
        console.log("getTableHeader data is " + JSON.stringify(data));
        let tableHeader = [];
        let { chartData } = this.props.element;
		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			chartData.selectedDataElement.map(element => {
				let {label, value} = element;
				if(value != '_id') {
					let headerItem = {
						name : value,
						label: label
					}
					tableHeader.push(headerItem)
				}
			})
		} else {
            if (data.length > 0) {
                for (let key in data[0]) {
                    if(key != '_id') {
                        let headerItem = {
                            name : key,
                            label: key
                        }
                        tableHeader.push(headerItem)
                    }
                }
            }
        }
        return tableHeader;
    };

    render()
    {
        console.log("this.props.element.chartData ==> " + JSON.stringify(this.props.element.chartData));
        let {data, width, height, pageSize, error, columns} = this.props.element.chartData;
        let chartType = this.props.element.chartType;
        if (chartType === "simpletable") {
            options.selectableRows = 'none';
            options.viewColumns = false;
            options.download = false;
            options.filter = false;
            options.search = false;
            options.print = false;
            options.sort = false;
        }
        if(data && data.data && Array.isArray(data.data) && !data.data.columns && !data.data.datasets) {
            let tableHeader = this.getTableHeader(data.data);
            console.log("tableHeader", tableHeader);
            data = { 
                columns: tableHeader,
                datasets: data.data
            }
        }
        
        console.log("data", JSON.stringify(data.datasets));
        console.log("columns", JSON.stringify(data.columns));
        
        if (error) {
            return (
                    <div className="errorDisplayCenter">{error}</div>
            );
        }
        else {
            return (
                    <div id={`TableContainer${this.props.element.chartUniqueId}`} style={{height: "inherit", width: "inherit"}}>
                        <MUIDataTable
                            data={data.datasets}
                            columns={data.columns}
                            options={options}
                        />
                    </div>
            );
        }
    }
}

const cardSource = {
    beginDrag(props, monitor, component) {
      const item = { id: props.element.chartUniqueId };
      return item;
    }
};

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        // changeRowSizeForTableChartComponent: Actions.changeRowSizeForTableChartComponent
    }, dispatch);
}

export default withStyles(styles, {withTheme: true})(
    withRouter(connect(null, mapDispatchToProps)(PageDesignerTableChart))
);
