import React from 'react'
import classNames from 'classnames'

import ChipSelect from 'components/@custom/ChipSelect'
import CardElement from '../elements/CardElement'
import MultiStepForm from '../elements/MultiStepForm'
import ExpansionPanels from '../elements/ExpansionPanels'
import TabElement from '../elements/TabElement'
import TextElement from '../elements/TextElement'

import {
	Button, Checkbox, Chip, FormControl, FormControlLabel, FormLabel, InputAdornment, InputLabel,
	Radio, RadioGroup, Step, StepLabel, Stepper, Switch, Tab, Tabs, TextField, Typography, AppBar,
	Card, CardHeader, CardContent, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary,
	FormGroup, Grid, NativeSelect, withStyles
} from '@material-ui/core'

const GenerateComplexElements = (props) => {
	const { classes } = props;
	const { item, outerIndex, gridSize, index } = props;
	let itemPosition = `${outerIndex}-${index}`;
	let visibility = item.hidden ? "hidden" : "visible"
	if (item.validation && item.validation.required && !item.label.labelText.endsWith("*")) {
		item.label.labelText += "*";
	} else if ((item.type == 'select' || item.type == 'text' || item.type == 'multiselect'
		|| item.type == 'date' || item.typeOfText == 'number' || item.typeOfText == 'time'
		|| item.typeOfText == 'datetime-local' || item.typeOfText == 'password'
		|| item.typeOfText == 'email' || item.typeOfText == 'tel')
		&& item.required && !item.label.endsWith("*")) {
		item.label += "*";
	}

	if (item.type == 'expansionPanels') {
		return (<ExpansionPanels
			filledData={props.filledData}
			viewOnly={props.viewOnly}
			innerFormUpdateMultiple={props.innerFormUpdateMultiple}
			setData={props.setData}
			itemPosition={itemPosition}
			visibility={visibility}
			item={item}
			classes={classes}
		/>)
	}
	else if (item.type == 'tab') {
		return (<TabElement
			filledData={props.filledData}
			viewOnly={props.viewOnly}
			innerFormUpdateMultiple={props.innerFormUpdateMultiple}
			onChange={props.onChange}
			setData={props.setData}
			itemPosition={itemPosition}
			item={item}
			classes={classes}
			visibility={visibility}
		/>)
	}
	else if (item.type == 'card') {
		return (<CardElement
			filledData={props.filledData}
			viewOnly={props.viewOnly}
			innerFormUpdate={props.innerFormUpdate}
			setData={props.setData}
			itemPosition={itemPosition}
			item={item}
			classes={classes}
			visibility={visibility}
		/>)
	}
	else if (item.type == 'multiStepForm') {
		return (<MultiStepForm
			filledData={props.filledData}
			viewOnly={props.viewOnly}
			innerFormUpdateMultiple={props.innerFormUpdateMultiple}
			setData={props.setData}
			itemPosition={itemPosition}
			item={item}
			classes={classes}
			visibility={visibility}
			activeStep={props.activeStep}
			incrementActiveStep={props.incrementActiveStep}
			decrementActiveStep={props.decrementActiveStep}
		/>)
	}
	else {
		return (<></>)
	}
}

export default GenerateComplexElements