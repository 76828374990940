import React from "react";
import components from "../components";
import DraggableToolbarIcon from "./DraggableToolbarIcon";

const HEIGHT = 50;
const WIDTH = 50;
export default class Toolbar extends React.Component {
  render() {
    const { dropToolbarIcon, dragToolbarIcon } = this.props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          // marginTop: 100,
          backgroundColor:'white',
          // paddingTop:50
        }}
     
      >
        <div>
          {Object.keys(components).map((itemType) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DraggableToolbarIcon
                key={itemType}
                dragToolbarIcon={dragToolbarIcon}
                dropToolbarIcon={dropToolbarIcon}
                height={HEIGHT}
                Item={components[itemType]}
                width={itemType === "Terminator" ? HEIGHT * 1.5 : HEIGHT}
              />
              <p style={{ color: "#000" }}>{itemType}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}