import bots from 'images/sidemenu-bots.svg'
import businessrules from 'images/sidemenu-businessrules.svg'
import datamodels from 'images/sidemenu-datamodels.svg'
import datapipelines from 'images/sidemenu-datapipelines.svg'
import datasources from 'images/sidemenu-datasources.svg'
import entities from 'images/sidemenu-entities.svg'
import forms from 'images/sidemenu-forms.svg'
import jobs from 'images/sidemenu-jobs.svg'
import members from 'images/sidemenu-members.svg'
import networks from 'images/sidemenu-networks.svg'
import notifications from 'images/sidemenu-notifications.svg'
import pages from 'images/sidemenu-pages.svg'
import roles from 'images/sidemenu-roles.svg'
import workflows from 'images/sidemenu-workflows.svg'
import botIcon from 'images/sidemenu-bots.svg'

export const navigationConfig = [
	// {
	//     'id': 'dapp',
	//     'title': 'Apps',
	//     'type': 'item',
	//     'url': '/builder/dapps',
	//     'exact': true
	// },
	{
		'id': 'participants',
		'title': 'PARTICIPANTS',
		'type': 'group',
		'url': '/builder/:appid/entities',
		'exact': true,
		'children': [
			{
				'id': 'entity',
				'title': 'Entities',
				'type': 'item',
				// 'icon': 'today',
				'icon': entities,
				'url': '/builder/:appid/entities',
				'exact': true
			},
			{
				'id': 'networks',
				'title': 'Networks',
				'type': 'item',
				// 'icon': 'today',
				'icon': networks,
				'url': '/builder/:appid/networks',
				'exact': true
			},
			{
				'id': 'roles',
				'title': 'Roles',
				'type': 'item',
				// 'icon': 'today',
				'icon': roles,
				'url': '/builder/:appid/roles',
				'exact': true
			},
			{
				'id': 'members',
				'title': 'Members',
				'type': 'item',
				// 'icon': 'today',
				'icon': members,
				'url': '/builder/:appid/members',
				'exact': true
			},
			{
				'id': 'groups',
				'title': 'Groups',
				'type': 'item',
				// 'icon': 'today',
				'icon': roles,
				'url': '/builder/:appid/groups',
				'exact': true
			}
		]
	},
	{
		'id': 'data',
		'title': 'Data',
		'type': 'group',
		'url': '/builder/:appid/datasources',
		'exact': true,
		'children': [
			{
				'id': 'datasources',
				'title': 'Data Sources',
				'type': 'item',
				// 'icon': 'data_usage',
				'icon': datasources,
				'url': '/builder/:appid/datasources',
				'exact': true
			},
			{
				'id': 'datamodels',
				'title': 'Data Models',
				'type': 'item',
				// 'icon': 'today',
				'icon': datamodels,
				'url': '/builder/:appid/dataModels',
				'exact': true
			},
			{
				'exact': true,
				'type': 'item',
				'icon': botIcon,
				'title': 'Formulas',
				'id': 'formulaeditor',
				'url': '/builder/:appid/formulas',
			},
			{
				'id': 'datapipelines',
				'title': 'Data Pipelines',
				'type': 'item',
				// 'icon': 'today',
				'icon': datapipelines,
				'url': '/builder/:appid/pipelines',
				'exact': true
			}
		]
	},
	{
		'id': 'logic',
		'title': 'Business Logic',
		'type': 'group',
		'url': '/builder/:appid/workflows',
		'exact': true,
		'children': [
			{
				'id': 'workflows',
				'title': 'Workflows',
				'type': 'item',
				// 'icon': 'today',
				'icon': workflows,
				// 'url': '/builder/:appid/workflows'
				'url': '/builder/:appid/flowcharts',
			},
			{
				'id': 'businessrules',
				'title': 'Business Rules',
				'type': 'item',
				// 'icon': 'today',
				'icon': businessrules,
				'url': '/builder/:appid/businessRules'
			},
			{
				'id': 'notifications',
				'title': 'Notifications',
				'type': 'item',
				// 'icon': 'today',
				'icon': notifications,
				'url': '/builder/:appid/notifications',
				'exact': true
			}
		]
	},
	{
		'id': 'ui',
		'title': 'User Interface',
		'type': 'group',
		'url': '/builder/:appid/forms',
		'exact': true,
		'children': [
			{
				'id': 'forms',
				'title': 'Forms',
				'type': 'item',
				// 'icon': 'pages',
				'icon': forms,
				'url': '/builder/:appid/forms',
				'exact': true
			},
			{
				'id': 'pages',
				'title': 'Pages',
				'type': 'item',
				// 'icon': 'dashboard',
				'icon': pages,
				'url': '/builder/:appid/pages',
				'exact': true
			}
		]
	},
	{
		'id': 'automation',
		'title': 'Intelligent Automation',
		'type': 'group',
		'url': '/builder/:appid/automation',
		'exact': true,
		'children': [
			{
				'id': 'jobs',
				'title': 'Jobs',
				'type': 'item',
				// 'icon': 'today',
				'icon': jobs,
				'url': '/builder/:appid/jobs',
				'exact': true
			},
			{
				'id': 'bots',
				'title': 'Bots',
				'type': 'item',
				// 'icon': 'today',
				'icon': bots,
				'url': '/builder/:appid/bots',
				'exact': true
			}
		]
	}
]

export const navigationAppAdminConfig = [
	{
		'id': 'participants',
		'title': 'PARTICIPANTS',
		'type': 'group',
		'url': '/builder/:appid/roles',
		'exact': true,
		'children': [
			{
				'id': 'roles',
				'title': 'Roles',
				'type': 'item',
				// 'icon': 'today',
				'icon': roles,
				'url': '/builder/:appid/roles',
				'exact': true
			},
			{
				'id': 'members',
				'title': 'Members',
				'type': 'item',
				// 'icon': 'today',
				'icon': members,
				'url': '/builder/:appid/members',
				'exact': true
			},
			{
				'id': 'groups',
				'title': 'Groups',
				'type': 'item',
				// 'icon': 'today',
				'icon': roles,
				'url': '/builder/:appid/groups',
				'exact': true
			}
		]
	},
	{
		'id': 'data',
		'title': 'Data',
		'type': 'group',
		'url': '/builder/:appid/formulas',
		'exact': true,
		'children': [
			{
				'exact': true,
				'type': 'item',
				'icon': botIcon,
				'title': 'Formulas',
				'id': 'formulaeditor',
				'url': '/builder/:appid/formulas',
			}
		]
	},
	{
		'id': 'logic',
		'title': 'Business Logic',
		'type': 'group',
		'url': '/builder/:appid/notifications',
		'exact': true,
		'children': [
			{
				'id': 'notifications',
				'title': 'Notifications',
				'type': 'item',
				// 'icon': 'today',
				'icon': notifications,
				'url': '/builder/:appid/notifications',
				'exact': true
			}
		]
	},
	{
		'id': 'ui',
		'title': 'User Interface',
		'type': 'group',
		'url': '/builder/:appid/forms',
		'exact': true,
		'children': [
			{
				'id': 'forms',
				'title': 'Forms',
				'type': 'item',
				// 'icon': 'pages',
				'icon': forms,
				'url': '/builder/:appid/forms',
				'exact': true
			},
			{
				'id': 'pages',
				'title': 'Pages',
				'type': 'item',
				// 'icon': 'dashboard',
				'icon': pages,
				'url': '/builder/:appid/pages',
				'exact': true
			}
		]
	}
]

