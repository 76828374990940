import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { withRouter } from 'react-router-dom';
import View from './view'
import Redux from './redux'
import history from 'modules/history'
import { updateTableData, setDatafromApi } from 'packages/common'
import sharedJSON from 'packages/sharedJSON'

import {
	getFilteredArray,
	handleSelectedRow,
	isRowselected,
	requestSort,
	handleTableSelectAllClick,
	getTableRowStyles
} from 'modules/common';

const Container = (props) => {
	const [state, setState] = useState({
		...sharedJSON
	})

	const handleChangePage = (event, page) => {
		setState({ ...state, page: page });
	};

	const handleChangeRowsPerPage = event => {
		setState({ ...state, rowsPerPage: event.target.value, page: 0 });
	};

	const onRowClick = (rowData, rowMeta) => {
		console.log('onRowClick');
		// let { extendedTablePage, associatedFormName } = props.chartData;
		// if (associatedFormName && rowData._id) {
		// 	history.push({
		// 		pathname: `/apps/${props.appId}/form/${associatedFormName}/${rowData._id}`
		// 	});
		// }
	}

	const searchHandler = (e) => {
		setState({ ...state, searchText: e ? e.target.value : "" });
	}

	const handleInputChange = (event) => {
		console.log("value:" + event.target.value + " checked: " + event.target.checked);
		let obj = state.filterTableData;
		let list = obj.columns;
		list.map((item, index) => {
			if (item.name === event.target.value) list[index].checked = event.target.checked
		})
		obj.columns = [...list]
		setState({ ...state, filterTableData: obj });
		setState({ ...updateTableData(state) });
	}

	const resetFilters = (event) => {
		setState({ ...state, filterList: [] });
	}

	const handleDropdownChange = (event, index, column) => {
		const value = event.target.value === "All" ? [] : [event.target.value];
		// filterUpdate(index, value, column, 'dropdown');
		let list = state.filterList;
		list[index] = event.target.value;
		setState({ ...state, filterList: [...list] });
		setState({ ...updateTableData(state) });
	};

	const handleRequestSort = (event, property) => {
		let obj = requestSort(event, property, state);
		console.log("obj", obj);
		setState({ ...state, ...obj });
	};

	const handleSelectAllClick = event => {
		setState({ ...state, selected: handleTableSelectAllClick(event, state.filterTableData.datasets) });
	};


	const tableCrudOperations = (item, type) => {
		console.log("CRUD operations==>", item + "    Type===>" + type);
		if (type === "edit") {
			history.push({ pathname: `/builder/${props.match.params.dappId}/workflows/${item._id}` });
		}
		if (type === "delete") {
			props.deleteWorkflow({ _id: item._id, appId: props.match.params.dappId });
			props.getWorkflows(props.match.params.dappId, 'workflow');
		}
	}

	const handleAddClick = () => {
		props.clearWorkflow()
		history.push({ pathname: `/builder/${props.match.params.dappId}/workflows/new` });
	}

	const handleCheck = (event, id) => {
		const { selected } = state;
		let newSelected = handleSelectedRow(event, id, selected)
		setState({ ...state, selected: newSelected });
	};

	const onCellClick = () => {

	}

	useEffect(() => {
		setState({ ...updateTableData(state) });
	}, [state.searchText, state.filterList]);


	useEffect(() => {
		const params = props.match.params;
		const { dappId } = params;
		props.getWorkflows(dappId, 'workflow');
	}, []);

	useEffect(() => {
		const rows = [
			{
				id: 'name',
				numeric: false,
				disablePadding: false,
				label: 'Name',
				sort: true
			},
			{
				id: 'description',
				numeric: false,
				disablePadding: false,
				label: 'Description',
				sort: true
			}
		];
		let keysArr = ['_id', 'diagram', 'appId', 'orgId', '__v', 'createdBy', 'createdDateTime', 'updatedBy', 'updatedDateTime', 'active'];
		setState({ ...setDatafromApi(props.workflows.data, keysArr, state, rows) });
	}, [props.workflows.data]);

	return (
		<View
			{...props}
			state={state}
			handleChangePage={handleChangePage}
			handleChangeRowsPerPage={handleChangeRowsPerPage}
			onRowClick={onRowClick}
			searchHandler={searchHandler}
			handleCheck={handleCheck}
			handleInputChange={handleInputChange}
			resetFilters={resetFilters}
			// handleDropdownChange={handleDropdownChange}
			handleRequestSort={handleRequestSort}
			handleSelectAllClick={handleSelectAllClick}
			tableCrudOperations={tableCrudOperations}
			handleAddClick={handleAddClick}
			onCellClick={onCellClick}
		/>
	)
}

export default hot(withRouter(Redux(Container)))